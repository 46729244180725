/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_USER_LOGIN_INFO_REQUEST,
  UPDATE_USER_LOGIN_INFO_SUCCESS,
  UPDATE_USER_LOGIN_INFO_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "./loginTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;
let clientId = Config.clientId;

let userManagementUrl = Config.userManagementUrl;

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const loginSuccess = (userData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userData,
  };
};

const loginFaliure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

const login = (loginDetails) => {
  return (dispatch) => {
    let payload = {
      email: loginDetails.email,
      password: loginDetails.password,
      isMobile: false,
    };
    dispatch(loginRequest());
    // let url = `${baseUrl}/login`;
    let url = `${userManagementUrl}/user/login`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(loginSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(loginFaliure(err.message));
        return { status: false, data: err.response.data.message };
      });
  };
};

const updateUserLoginInfoRequest = () => {
  return {
    type: UPDATE_USER_LOGIN_INFO_REQUEST,
  };
};

const updateUserLoginInfoSuccess = (userData) => {
  return {
    type: UPDATE_USER_LOGIN_INFO_SUCCESS,
    payload: userData,
  };
};

const updateUserLoginInfoFaliure = (error) => {
  return {
    type: UPDATE_USER_LOGIN_INFO_FAILURE,
    payload: error,
  };
};

const updateUserLoginInfo = (loginDetails) => {
  return (dispatch) => {
    let payload = {
      email: loginDetails.email,
      isMobile: false,
      // clientId: clientId,
    };
    dispatch(updateUserLoginInfoRequest());
    // let url = `${baseUrl}/login`;
    let url = `${userManagementUrl}/user/user_login_info_update`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(updateUserLoginInfoSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserLoginInfoFaliure(err.message));
        return { status: false };
      });
  };
};

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const logoutSuccess = (userData) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: userData,
  };
};

const logoutFaliure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    payload: error,
  };
};

const logout = (loginDetails) => {
  return (dispatch) => {
    let payload = {
      email: loginDetails.email,
      isMobile: false,
    };
    dispatch(logoutRequest());
    // let url = `${baseUrl}/login`;
    let url = `${userManagementUrl}/user/logout`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(logoutSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(logoutFaliure(err.message));
        return { status: false };
      });
  };
};

export { login, updateUserLoginInfo, logout };
