import {
  GET_ACTIVECYCLECOUNT_REQUEST,
  GET_ACTIVECYCLECOUNT_SUCCESS,
  GET_ACTIVECYCLECOUNT_FAILURE,
  GET_CYCLECOUNTTYPE_REQUEST,
  GET_CYCLECOUNTTYPE_SUCCESS,
  GET_CYCLECOUNTTYPE_FAILURE,
  GET_COMPLETEDCYCLECOUNT_REQUEST,
  GET_COMPLETEDCYCLECOUNT_SUCCESS,
  GET_COMPLETEDCYCLECOUNT_FAILURE,
  GET_WAREHOUSESBYITEMID_REQUEST,
  GET_WAREHOUSESBYITEMID_SUCCESS,
  GET_WAREHOUSESBYITEMID_FAILURE,
} from "./cycleCountTypes";

const initialStateForActive = {
  loading: false,
  activeCycleCount: [],
  error: "",
};

const getActiveCycleCountReducer = (state = initialStateForActive, action) => {
  switch (action.type) {
    case GET_ACTIVECYCLECOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVECYCLECOUNT_SUCCESS:
      return {
        loading: false,
        activeCycleCount: action.payload,
        error: "",
      };
    case GET_ACTIVECYCLECOUNT_FAILURE:
      return {
        loading: false,
        activeCycleCount: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCompleted = {
  loading: false,
  completedCycleCount: [],
  error: "",
};

const getCompletedCycleCountReducer = (
  state = initialStateForCompleted,
  action
) => {
  switch (action.type) {
    case GET_COMPLETEDCYCLECOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLETEDCYCLECOUNT_SUCCESS:
      return {
        loading: false,
        completedCycleCount: action.payload,
        error: "",
      };
    case GET_COMPLETEDCYCLECOUNT_FAILURE:
      return {
        loading: false,
        completedCycleCount: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCountType = {
  loading: false,
  cycleCountType: [],
  error: "",
};

const getCycleCountTypeReducer = (state = initialStateForCountType, action) => {
  switch (action.type) {
    case GET_CYCLECOUNTTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CYCLECOUNTTYPE_SUCCESS:
      return {
        loading: false,
        cycleCountType: action.payload,
        error: "",
      };
    case GET_CYCLECOUNTTYPE_FAILURE:
      return {
        loading: false,
        cycleCountType: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForWarehousesByItem = {
  loading: false,
  warehouses: [],
  error: "",
};

const getWarehousesByItemReducer = (
  state = initialStateForWarehousesByItem,
  action
) => {
  switch (action.type) {
    case GET_WAREHOUSESBYITEMID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WAREHOUSESBYITEMID_SUCCESS:
      return {
        loading: false,
        warehouses: action.payload,
        error: "",
      };
    case GET_WAREHOUSESBYITEMID_FAILURE:
      return {
        loading: false,
        warehouses: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getActiveCycleCountReducer,
  getCompletedCycleCountReducer,
  getCycleCountTypeReducer,
  getWarehousesByItemReducer,
};
