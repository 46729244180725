import {
  GET_ALLOCATED_ACTIVITY_REQUEST,
  GET_ALLOCATED_ACTIVITY_SUCCESS,
  GET_ALLOCATED_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  GET_CREATEDACTIVITY_FAILURE,
  GET_CREATEDACTIVITY_REQUEST,
  GET_CREATEDACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
} from "./createdActivityTypes";

const initialStateForAllocatedActivity = {
  loading: false,
  allocatedActivity: [],
  error: "",
};

const getAllocatedActivityReducer = (
  state = initialStateForAllocatedActivity,
  action
) => {
  switch (action.type) {
    case GET_ALLOCATED_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLOCATED_ACTIVITY_SUCCESS:
      return {
        loading: false,
        allocatedActivity: action.payload,
        error: "",
      };
    case GET_ALLOCATED_ACTIVITY_FAILURE:
      return {
        loading: false,
        allocatedActivity: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  activity: [],
  error: "",
};

const getCreatedActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREATEDACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CREATEDACTIVITY_SUCCESS:
      return {
        loading: false,
        activity: action.payload,
        error: "",
      };
    case GET_CREATEDACTIVITY_FAILURE:
      return {
        loading: false,
        activity: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreateActivity = {
  loading: false,
  activities: {},
  error: "",
};

const createActivityReducer = (
  state = initialStateForCreateActivity,
  action
) => {
  switch (action.type) {
    case CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activities: action.payload,
        error: "",
      };
    case CREATE_ACTIVITY_FAILURE:
      return {
        loading: false,
        activities: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForDelete = {
  loading: false,
  deleteActivity: {},
  error: "",
};

const deleteActivityReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        loading: true,
        deleteActivity: action.payload,
        error: "",
      };
    case DELETE_ACTIVITY_FAILURE:
      return {
        loading: false,
        deleteActivity: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdate = {
  loading: false,
  activity: {},
  error: "",
};

const updateActivityReducer = (state = initialStateForUpdate, action) => {
  switch (action.type) {
    case UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        loading: true,
        activity: action.payload,
        error: "",
      };
    case UPDATE_ACTIVITY_FAILURE:
      return {
        loading: false,
        activity: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
export {
  getAllocatedActivityReducer,
  getCreatedActivityReducer,
  createActivityReducer,
  deleteActivityReducer,
  updateActivityReducer,
};
