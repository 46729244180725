export const GET_VENDOR_MASTER_MAPPING_REQUEST = "GET_VENDOR_MASTER_MAPPING_REQUEST";
export const GET_VENDOR_MASTER_MAPPING_SUCCESS = "GET_VENDOR_MASTER_MAPPING_SUCCESS";
export const GET_VENDOR_MASTER_MAPPING_FAILURE = "GET_VENDOR_MASTER_MAPPING_FAILURE";

export const GET_LIST_OF_BIN_TYPES_REQUEST = "GET_LIST_OF_BIN_TYPES_REQUEST";
export const GET_LIST_OF_BIN_TYPES_SUCCESS = "GET_LIST_OF_BIN_TYPES_SUCCESS";
export const GET_LIST_OF_BIN_TYPES_FAILURE = "GET_LIST_OF_BIN_TYPES_FAILURE";

export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "UPDATE_EMAIL_FAILURE";

export const CREATE_VENDOR_REQUEST = "CREATE_VENDOR_REQUEST";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE";

export const UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_REQUEST = "UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_REQUEST";
export const UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_SUCCESS = "UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_SUCCESS";
export const UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_FAILURE = "UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_FAILURE";

export const UPDATE_VENDOR_MATERIAL_MAPPING_REQUEST = "UPDATE_VENDOR_MATERIAL_MAPPING_REQUEST";
export const UPDATE_VENDOR_MATERIAL_MAPPING_SUCCESS = "UPDATE_VENDOR_MATERIAL_MAPPING_SUCCESS";
export const UPDATE_VENDOR_MATERIAL_MAPPING_FAILURE = "UPDATE_VENDOR_MATERIAL_MAPPING_FAILURE";