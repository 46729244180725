/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAllPrinters, printerConfig, getProfile } from "../../store";

import Select from "react-select";
import "../../index.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";

import Cookies from "universal-cookie";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

const cookies = new Cookies();

function PrinterConfigurator({
  getAllPrinters,
  printers,
  printerConfig,
  profileData,
  getProfile,
}) {
  const navigate = useNavigate();

  let data = cookies.get("loginDetailsForWMS");

  let userId = data?.data?.userId;
  let jwtToken = data?.jwt;
  let email = data?.data?.email;

  useEffect(() => {
    getProfile(email, jwtToken);
  }, []);

  useEffect(() => {
    getAllPrinters(jwtToken);
  }, []);

  const [printerName, setPrinterName] = useState("");
  useEffect(() => {
    setPrinterName(profileData?.profile?.printerName);
  }, [profileData]);

  const [printerSelected, setPrinterSelected] = useState("");
  const handlePrinterSelected = (selectedOption) => {
    setPrinterSelected(selectedOption);
  };

  const [printersOptions, setPrintersOptions] = useState([]);

  useEffect(() => {
    let tempPrinters = [];
    let data = printers.printers.data ? printers.printers.data : [];
    data?.map((printer) => {
      const printerObject = {
        label: printer.name,
        value: printer.printerId,
      };
      tempPrinters.push(printerObject);
    });
    setPrintersOptions(tempPrinters);
  }, [printers]);

  const [isSuccessPrinterConfig, setIsSuccessPrinterConfig] = useState(false);
  const [isErrorPrinterConfig, setIsErrorPrinterConfig] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  useEffect(() => {
    if (isErrorPrinterConfig) {
      setTimeout(() => {
        setIsErrorPrinterConfig(false);
      }, 3000);
    }
  }, [isErrorPrinterConfig]);

  useEffect(() => {
    if (isSuccessPrinterConfig) {
      setTimeout(() => {
        setIsSuccessPrinterConfig(false);
      }, 3000);
    }
  }, [isSuccessPrinterConfig]);

  const initialValues = {
    selectPrinter: "",
  };

  const validationSchema = Yup.object({
    selectPrinter: Yup.object().required("Enter a  printer"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let payload = {
      userId: userId,
      printerId: printerSelected?.value ? String(printerSelected.value) : "",
    };
    let res = await printerConfig(payload, jwtToken);
    if (res.status) {
      setIsSuccessPrinterConfig(true);
      cookies.set(
        "printerIdForWarehouse",
        JSON.stringify(printerSelected?.value),
        {
          path: "/",
        }
      );
      cookies.set(
        "printerConfigForWarehouse",
        JSON.stringify(res.data.data[0].isDefaultPrinterSet),
        {
          path: "/",
        }
      );
      getAllPrinters(jwtToken);
      getProfile(email, jwtToken);

      setPrinterSelected("");
      // setTimeout(() => {
      //   navigate("/user-profile");
      // }, 2000);
    }
    if (!res.status) {
      setErrorMsg(res.message.data.msg);
      setIsErrorPrinterConfig(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "60%",
            lg: "50%",
            xl: "50%",
          },
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "50px",
        }}
      >
        <MDBox ml={2} mt={2}>
          <MDTypography variant="h4" fontWeight="medium">
            Printer Configurator
          </MDTypography>
        </MDBox>

        <hr style={{ marginTop: "0.1rem" }} />

        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
          <MDBox p={1} mt={1}>
            <MDTypography
              variant="h6"
              flexGrow={1}
              style={{ fontSize: 14, fontWeight: 500 }}
            >
              Configured Printer : {printerName}
            </MDTypography>
          </MDBox>
          <MDBox mb={1} p={1}>
            <MDTypography
              variant="h6"
              flexGrow={1}
              style={{ fontSize: 14, fontWeight: 500 }}
            >
              Select Printer
            </MDTypography>
            <Select
              className="select-css"
              maxMenuHeight={130}
              isClearable
              onBlur={() => {
                formik.handleBlur({
                  target: { name: "selectPrinter" },
                });
              }}
              name="selectPrinter"
              value={printerSelected}
              options={printersOptions}
              onChange={(selectedOption) => {
                handlePrinterSelected(selectedOption);
                formik.setFieldValue("selectPrinter", selectedOption);
              }}
              inputProps={{
                style: {
                  height: "10px",
                },
              }}
            />

            {formik.touched.selectPrinter && formik.errors.selectPrinter && (
              <TextError msg={formik.errors.selectPrinter} />
            )}
          </MDBox>
          <MDBox className="mt-3">
            <Collapse in={isErrorPrinterConfig}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setIsErrorPrinterConfig(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {/* Could not configure the printer! */}
                {errorMsg}
              </Alert>
            </Collapse>
            <Collapse in={isSuccessPrinterConfig}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setIsSuccessPrinterConfig(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Printer configured!
              </Alert>
            </Collapse>
          </MDBox>

          <MDBox style={{ float: "right" }} p={1}>
            <MDButton
              color="success"
              type="submit"
              size="small"
              disabled={!formik.isValid}
            >
              Confirm
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <br />
      <br />
      <br />
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    printers: state.allPrintersReducer,
    profileData: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPrinters: (jwtToken) => dispatch(getAllPrinters(jwtToken)),
    printerConfig: (payload, jwtToken) =>
      dispatch(printerConfig(payload, jwtToken)),
    getProfile: (email, jwtToken) => dispatch(getProfile(email, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrinterConfigurator);
