import {
  GET_ITEMMASTER_REQUEST,
  GET_ITEMMASTER_SUCCESS,
  GET_ITEMMASTER_FAILURE,
  EDIT_ITEMMASTER_REQUEST,
  EDIT_ITEMMASTER_SUCCESS,
  EDIT_ITEMMASTER_FAILURE,
  GET_BINSBYITEMCODE_REQUEST,
  GET_BINSBYITEMCODE_SUCCESS,
  GET_BINSBYITEMCODE_FAILURE,
  UPLOADCSV_REQUEST,
  UPLOADCSV_SUCCESS,
  UPLOADCSV_FAILURE,
  GET_ITEMS_DATA_FOR_DOWNLOAD_REQUEST,
  GET_ITEMS_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_ITEMS_DATA_FOR_DOWNLOAD_FAILURE,
  GET_ITEMTYPE_REQUEST,
  GET_ITEMTYPE_SUCCESS,
  GET_ITEMTYPE_FAILURE
} from "./itemMasterTypes";

const initialState = {
  loading: false,
  itemMaster: [],
  error: "",
};

const getItemMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEMMASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMMASTER_SUCCESS:
      return {
        loading: false,
        itemMaster: action.payload,
        error: "",
      };
    case GET_ITEMMASTER_FAILURE:
      return {
        loading: false,
        itemMaster: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForItemTypes = {
  loading: false,
  itemTypes: [],
  error: "",
};

const getItemTypesReducer = (state = initialStateForItemTypes, action) => {
  switch (action.type) {
    case GET_ITEMTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMTYPE_SUCCESS:
      return {
        loading: false,
        itemTypes: action.payload,
        error: "",
      };
    case GET_ITEMTYPE_FAILURE:
      return {
        loading: false,
        itemTypes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEditItemMaster = {
  loading: false,
  itemMaster: [],
  error: "",
};

const editItemMasterReducer = (
  state = initialStateForEditItemMaster,
  action
) => {
  switch (action.type) {
    case EDIT_ITEMMASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ITEMMASTER_SUCCESS:
      return {
        loading: false,
        itemMaster: action.payload,
        error: "",
      };
    case EDIT_ITEMMASTER_FAILURE:
      return {
        loading: false,
        itemMaster: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForBins = {
  loading: false,
  bins: [],
  error: "",
};

const getBinsByItemCodeReducer = (state = initialStateForBins, action) => {
  switch (action.type) {
    case GET_BINSBYITEMCODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BINSBYITEMCODE_SUCCESS:
      return {
        loading: false,
        bins: action.payload,
        error: "",
      };
    case GET_BINSBYITEMCODE_FAILURE:
      return {
        loading: false,
        bins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUploadCsv = {
  loading: false,
  file: {},
  error: "",
};

const uploadCsvReducer = (state = initialStateForUploadCsv, action) => {
  switch (action.type) {
    case UPLOADCSV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOADCSV_SUCCESS:
      return {
        loading: false,
        file: action.payload,
        error: "",
      };
    case UPLOADCSV_FAILURE:
      return {
        loading: false,
        file: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDataForDownload = {
  loading: false,
  ItemsDataForDownload: [],
  error: "",
};

const getItemsDataForDownloadReducer = (state = initialStateForDataForDownload, action) => {
  switch (action.type) {
    case GET_ITEMS_DATA_FOR_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMS_DATA_FOR_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        ItemsDataForDownload: action.payload,
        error: "",
      };
    case GET_ITEMS_DATA_FOR_DOWNLOAD_FAILURE:
      return {
        loading: false,
        ItemsDataForDownload: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getItemMasterReducer,
  editItemMasterReducer,
  getBinsByItemCodeReducer,
  uploadCsvReducer,
  getItemsDataForDownloadReducer,
  getItemTypesReducer
};
