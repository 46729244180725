import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik & Yup
import { Field } from "formik";
import * as Yup from "yup";

// Country & State components
import { Country, State } from "country-state-city";

// React-Select component and styling
import Select from "react-select";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";

// Utils component
import FormTextField from "./utils/FormTextField";
import MultiStepForm, { FormStep } from "./utils/MultiStepForm";
import FormFileUpload from "./utils/FormFileUpload";
import FormSelect from "./utils/FormSelect";
import TextError from "utils/TextError";
import "../../utils/input.css";

// Router components
import { useNavigate } from "react-router-dom";

// Store
import {
  getVendorByVendorId,
  createVendorDetails,
  rejectedVendorDetails,
} from "../../store";

// Cookies
import Cookies from "universal-cookie";

import Config from "../../config/validationSchema";
const cookies = new Cookies();

const submitModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const StandardVendorForm = ({
  createVendorDetails,
  rejectedVendorDetails,
  getRejectedVendorDetails,
  rejectedVendorReducer,
  vendorIdReducer,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      return (event.returnValue = "");
    };
    window.addEventListener("beforeunload", handleBeforeUnload, {
      capture: true,
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  let token = cookies.get("vendor-portal-jwtToken");

  const [loading, setLoading] = useState(true);

  const [rejectedVendorData, setRejectedVendorData] = useState({});

  const [vendorId, setVendorId] = useState("");

  useEffect(() => {
    setVendorId(vendorIdReducer.vendorId);
  }, [vendorIdReducer]);

  useEffect(() => {
    getRejectedVendorDetails(vendorId);
  }, [vendorId]);

  useEffect(() => {
    setRejectedVendorData(rejectedVendorReducer.getVendor);
  }, [rejectedVendorReducer]);

  useEffect(() => {
    if (rejectedVendorData.email) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [rejectedVendorData]);

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [currenciesOptions, setCurrenciesOptions] = useState([]);

  const lineOfBusinessOptions = [
    { label: "Direct Material", value: "Direct Material" },
    { label: "Indirect Material", value: "Indirect Material" },
  ];

  const industrialTypeOptions = [
    { label: "Dealer", value: "Dealer" },
    { label: "Manufacturer", value: "Manufacturer" },
    { label: "Service", value: "Service" },
    { label: "Sub-Contractor", value: "Sub-Contractor" },
    { label: "Trader", value: "Trader" },
  ];

  const accountTypeOptions = [
    { label: "Current Account", value: "Current Account" },
    { label: "Savings Account", value: "Savings Account" },
  ];

  const sezOptions = [
    { label: "SEZ", value: "SEZ" },
    { label: "Non-SEZ", value: "Non-SEZ" },
  ];

  const categoryOfSupplyOptions = [
    { label: "Material", value: "Material" },
    { label: "Services", value: "Services" },
    { label: "Man Power", value: "Man Power" },
  ];

  useEffect(() => {
    let countries = Country.getAllCountries();

    let tempCountries = [];
    let tempCurrencies = [];
    countries.map((country) => {
      let countryObj = {
        label: country.name,
        value: country.name,
        isoCode: country.isoCode,
        phoneCode: country.phonecode,
      };
      tempCountries.push(countryObj);
    });
    setCountriesOptions(tempCountries);

    countries.map((country) => {
      let currencyObj = {
        label: country.currency,
        value: country.currency,
      };
      tempCurrencies.push(currencyObj);
    });
    setCurrenciesOptions(tempCurrencies);
  }, []);

  const [countrySelected, setCountrySelected] = useState({});
  const [countryCodeSelected, setCountryCodeSelected] = useState("");
  const [touched, setTouched] = useState(false);

  const [state, setState] = useState({});

  const stateChangeHandler = (values) => {
    setState(values);
  };

  useEffect(() => {
    let states = State.getStatesOfCountry(countrySelected?.isoCode);

    let arr = [];
    states.map((state) => {
      let stateObj = {
        label: state.name,
        value: state.name,
      };
      arr.push(stateObj);
    });
    setStateOptions(arr);
  }, [countrySelected]);

  useEffect(() => {
    if (rejectedVendorData.email) {
      setCountryCodeSelected(rejectedVendorData.contactDetails?.countryCode);
      setCountrySelected({
        label: rejectedVendorData.address?.country,
        value: rejectedVendorData.address?.country,
      });
    }
  }, [rejectedVendorData]);

  const initialValues = {
    // step 1 initial values
    lineOfBusiness: rejectedVendorData.lineOfBusiness
      ? rejectedVendorData.lineOfBusiness
      : "",
    currency: rejectedVendorData.currency ? rejectedVendorData.currency : "",
    supplierName: rejectedVendorData.supplierName
      ? rejectedVendorData.supplierName
      : "",
    buildingNoName: rejectedVendorData.address?.buildingNoName
      ? rejectedVendorData.address?.buildingNoName
      : "",
    floor: rejectedVendorData.address?.floor
      ? rejectedVendorData.address?.floor
      : "",
    street: rejectedVendorData.address?.street
      ? rejectedVendorData.address?.street
      : "",
    country: rejectedVendorData.address?.country
      ? rejectedVendorData.address?.country
      : "",
    state: rejectedVendorData.address?.state
      ? rejectedVendorData.address?.state
      : "",
    city: rejectedVendorData.address?.city
      ? rejectedVendorData.address?.city
      : "",
    pinCode: rejectedVendorData.address?.pinCode
      ? rejectedVendorData.address?.pinCode
      : "",
    contactPerson: rejectedVendorData.contactPerson
      ? rejectedVendorData.contactPerson
      : "",
    countryCode: rejectedVendorData.contactDetails?.countryCode
      ? rejectedVendorData.contactDetails?.countryCode
      : "",
    contactNo: rejectedVendorData.contactDetails?.contactNo
      ? rejectedVendorData.contactDetails?.contactNo
      : "",
    industrialType: rejectedVendorData.industrialType
      ? rejectedVendorData.industrialType
      : [],
    email: rejectedVendorData.email ? rejectedVendorData.email : "",

    // step 2 initial values
    pan: rejectedVendorData.pan ? rejectedVendorData.pan : "",
    gstNo: rejectedVendorData.gstNo ? rejectedVendorData.gstNo : "",
    sezNonsez: rejectedVendorData.sezNonsez ? rejectedVendorData.sezNonsez : "",
    rcmApplicable: rejectedVendorData.rcmApplicable
      ? rejectedVendorData.rcmApplicable
      : "",
    lutNo: rejectedVendorData.lutNo ? rejectedVendorData.lutNo : "",
    lutDate: rejectedVendorData.lutDate ? rejectedVendorData.lutDate : "",
    paymentTerms: rejectedVendorData.paymentTerms
      ? rejectedVendorData.paymentTerms
      : "",
    cinNumber: rejectedVendorData.cinNumber ? rejectedVendorData.cinNumber : "",
    msmedAct: rejectedVendorData.msmedAct ? rejectedVendorData.msmedAct : "",
    tdsLowerDeduction: rejectedVendorData.tdsLowerDeduction
      ? rejectedVendorData.tdsLowerDeduction
      : "",
    categoryOfSupply: rejectedVendorData.categoryOfSupply
      ? rejectedVendorData.categoryOfSupply
      : [],
    pfAccountNumber: rejectedVendorData.pfAccountNumber
      ? rejectedVendorData.pfAccountNumber
      : "",
    esiAccountNumber: rejectedVendorData.esiAccountNumber
      ? rejectedVendorData.esiAccountNumber
      : "",

    // step 3 initial values
    beneficiaryBankAndAddress: rejectedVendorData.bankDetails
      ?.beneficiaryBankAndAddress
      ? rejectedVendorData.bankDetails?.beneficiaryBankAndAddress
      : "",
    accountNumber: rejectedVendorData.bankDetails?.accountNumber
      ? rejectedVendorData.bankDetails?.accountNumber
      : "",
    accountType: rejectedVendorData.bankDetails?.accountType
      ? rejectedVendorData.bankDetails?.accountType
      : "",
    accountName: rejectedVendorData.bankDetails?.accountName
      ? rejectedVendorData.bankDetails?.accountName
      : "",
    ifscCode: rejectedVendorData.bankDetails?.ifscCode
      ? rejectedVendorData.bankDetails?.ifscCode
      : "",
    swiftCodeIban: rejectedVendorData.bankDetails?.swiftCodeIban
      ? rejectedVendorData.bankDetails?.swiftCodeIban
      : "",
    intermediaryRoutingBank: rejectedVendorData.bankDetails
      ?.intermediaryRoutingBank
      ? rejectedVendorData.bankDetails?.intermediaryRoutingBank
      : "",
    swiftCodeOfIntermediaryRoutingBank: rejectedVendorData.bankDetails
      ?.swiftCodeOfIntermediaryRoutingBank
      ? rejectedVendorData.bankDetails?.swiftCodeOfIntermediaryRoutingBank
      : "",

    // step 4 inital values
    incorporationCertificate: rejectedVendorData.attachments
      ?.incorporationCertificate
      ? rejectedVendorData.attachments?.incorporationCertificate
      : "",
    cancelledCheque: rejectedVendorData.attachments?.cancelledCheque
      ? rejectedVendorData.attachments?.cancelledCheque
      : "",
    panCard: rejectedVendorData.attachments?.panCard
      ? rejectedVendorData.attachments?.panCard
      : "",
    gstRegistrationCertificate: rejectedVendorData.attachments
      ?.gstRegistrationCertificate
      ? rejectedVendorData.attachments?.gstRegistrationCertificate
      : "",
    validLut: rejectedVendorData.attachments?.validLut
      ? rejectedVendorData.attachments?.validLut
      : "",
    sezApproval: rejectedVendorData.attachments?.sezApproval
      ? rejectedVendorData.attachments?.sezApproval
      : "",
    pfCertificate: rejectedVendorData.attachments?.pfCertificate
      ? rejectedVendorData.attachments?.pfCertificate
      : "",
    esiCertificate: rejectedVendorData.attachments?.esiCertificate
      ? rejectedVendorData.attachments?.esiCertificate
      : "",
    tdsLowerDeductionCertificate: rejectedVendorData.attachments
      ?.tdsLowerDeductionCertificate
      ? rejectedVendorData.attachments?.tdsLowerDeductionCertificate
      : "",
    msmeCertificate: rejectedVendorData.attachments?.msmeCertificate
      ? rejectedVendorData.attachments?.msmeCertificate
      : "",
  };

  const validationSchemaForStepOne = Yup.object(
    Config.validationSchemaForStepOne
  );

  const validationSchemaForStepTwo = Yup.object(
    Config.validationSchemaForStepTwo
  );

  const validationSchemaForStepThree = Yup.object(
    Config.validationSchemaForStepThree
  );

  const validationSchemaForStepFour = Yup.object(
    Config.validationSchemaForStepFour
  );

  const [errorMsgFormSubmit, setErrorMsgFormSubmit] = useState("");
  const [dataForSubmit, setDataForSubmit] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitFormHandler = async () => {
    dataForSubmit.country = countrySelected.value;
    dataForSubmit.countryCode = countryCodeSelected;

    let payload = new FormData();
    for (let value in dataForSubmit) {
      payload.append(value, dataForSubmit[value]);
    }

    let res;

    if (rejectedVendorData.email) {
      res = await rejectedVendorDetails(payload, token);
    } else {
      res = await createVendorDetails(payload, token);
    }

    if (res.status) {
      setIsSuccessFormSubmit(true);
      setTimeout(() => {
        handleCloseSubmitModal();
        setFormSubmitted(true);
      }, 3000);

      cookies.remove("vendor-portal-jwtToken");
    }
    if (!res.status) {
      setErrorMsgFormSubmit(res.data.data.msg);
      setIsErrorFormSubmit(true);
    }
  };

  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const handleOpenSubmitModal = (values) => {
    setDataForSubmit(values);
    setOpenSubmitModal(true);
  };
  const handleCloseSubmitModal = () => {
    setOpenSubmitModal(false);
  };

  const [isErrorFormSubmit, setIsErrorFormSubmit] = useState(false);
  const [isSuccessFormSubmit, setIsSuccessFormSubmit] = useState(false);

  useEffect(() => {
    if (isErrorFormSubmit) {
      setTimeout(() => {
        setIsErrorFormSubmit(false);
      }, 3000);
    }
  }, [isErrorFormSubmit]);

  useEffect(() => {
    if (isSuccessFormSubmit) {
      setTimeout(() => {
        setIsSuccessFormSubmit(false);
      }, 3000);
    }
  }, [isSuccessFormSubmit]);

  return (
    <div>
      <BasicLayout position="relative">
        {loading ? (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : formSubmitted ? (
          <Card className="vendor-form">
            <CardContent align="center">
              <MDTypography>
                Form submitted successfully. You can close this tab!
              </MDTypography>
            </CardContent>
          </Card>
        ) : (
          <div>
            <Card className="vendor-form">
              <CardContent align="center">
                <MDBox>
                  <MDTypography
                    mb={5}
                    variant="h2"
                    fontWeight="medium"
                    style={{ display: "grid", justifyContent: "center" }}
                  >
                    Vendor Form
                  </MDTypography>
                  <MDBox>
                    <MultiStepForm
                      initialValues={initialValues}
                      onSubmit={handleOpenSubmitModal}
                      onStateChange={stateChangeHandler}
                    >
                      {/*  */}
                      {/* Step 1 form */}
                      <FormStep
                        stepName="Business Partner Details"
                        validationSchema={validationSchemaForStepOne}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={lineOfBusinessOptions}
                              label="Line of Business"
                              name="lineOfBusiness"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={currenciesOptions}
                              label="Currency"
                              name="currency"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Supplier Name"
                              name="supplierName"
                              inputProps={{
                                maxLength: 45,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Building No/Name"
                              name="buildingNoName"
                              inputProps={{
                                maxLength: 45,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="Floor" name="floor" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Street"
                              name="street"
                              inputProps={{
                                maxLength: 45,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <>
                              <MDTypography variant="h6" color="inherit">
                                Country <span style={{ color: "red" }}>*</span>
                              </MDTypography>
                              <Select
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: 5,
                                    minHeight: "44px",
                                    height: "auto",
                                  }),
                                }}
                                name="country"
                                className="select-css"
                                maxMenuHeight={130}
                                placeholder="Select..."
                                options={countriesOptions}
                                value={countrySelected}
                                onChange={(option) => {
                                  setCountryCodeSelected(option.phoneCode);
                                  setCountrySelected(option);
                                }}
                                onFocus={() => {
                                  setTouched(true);
                                }}
                              />
                              {touched && !countrySelected.value && (
                                <TextError msg="Choose a Country" />
                              )}
                            </>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={stateOptions}
                              label="State"
                              name="state"
                              isDisabled={!countrySelected?.value}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="City" name="city" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="Pincode" name="pinCode" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Contact Person"
                              name="contactPerson"
                              inputProps={{
                                maxLength: 45,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <MDTypography variant="h6" color="inherit">
                              Contact No <span style={{ color: "red" }}>*</span>
                            </MDTypography>
                            <span style={{ display: "flex" }}>
                              <FormTextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                style={{
                                  width: "auto",
                                  marginRight: "0.5rem",
                                }}
                                value={countryCodeSelected}
                                name="countryCode"
                                notRequired
                              />
                              <span>
                                <FormTextField
                                  name="contactNo"
                                  type="number"
                                  style={{
                                    width: "auto",
                                  }}
                                  disabled={!countrySelected?.value}
                                  notRequired
                                />
                              </span>
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="Email" name="email" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={industrialTypeOptions}
                              label="Industrial Type"
                              name="industrialType"
                              isMulti
                              placeholder="Select all that apply"
                            />
                          </Grid>
                        </Grid>
                      </FormStep>
                      {/*  */}
                      {/*  */}
                      {/* Step 2 form */}
                      <FormStep
                        stepName="Statutory Information"
                        validationSchema={validationSchemaForStepTwo}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="PAN" name="pan" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="GST No" name="gstNo" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={sezOptions}
                              label="Whether SEZ/Non-SEZ"
                              name="sezNonsez"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="RCM Applicable"
                              name="rcmApplicable"
                              notRequired
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="LUT No"
                              name="lutNo"
                              notRequired
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="LUT Date"
                              name="lutDate"
                              type="date"
                              notRequired
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Payment Terms"
                              name="paymentTerms"
                              notRequired
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="CIN Number"
                              name="cinNumber"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={categoryOfSupplyOptions}
                              label="Category of Supply"
                              name="categoryOfSupply"
                              isMulti
                            />
                          </Grid>
                          {state.categoryOfSupply?.includes("Man Power") && (
                            <Grid item xs={12} md={6} lg={3}>
                              <FormTextField
                                label="PF Account Number"
                                name="pfAccountNumber"
                              />
                            </Grid>
                          )}
                          {state.categoryOfSupply?.includes("Man Power") && (
                            <Grid item xs={12} md={6} lg={3}>
                              <FormTextField
                                label="ESI Account Number"
                                name="esiAccountNumber"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} md={12} lg={12}>
                            <MDTypography>
                              Are you registered under MSMED Act, if yes please
                              share valid self attested certificate{" "}
                              <span style={{ color: "red" }}>*</span>
                            </MDTypography>
                            <div>
                              <label style={{ marginRight: "10px" }}>
                                <Field
                                  type="radio"
                                  value="Yes"
                                  name="msmedAct"
                                  style={{ marginRight: "5px" }}
                                />
                                Yes
                              </label>
                              <span />
                              <label>
                                <Field
                                  type="radio"
                                  value="No"
                                  name="msmedAct"
                                  style={{ marginRight: "5px" }}
                                />
                                No
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <MDTypography>
                              Do you have any TDS lower deduction certificate,
                              if yes provide self attested copy{" "}
                              <span style={{ color: "red" }}>*</span>
                            </MDTypography>
                            <div>
                              <label style={{ marginRight: "10px" }}>
                                <Field
                                  type="radio"
                                  value="Yes"
                                  name="tdsLowerDeduction"
                                  style={{ marginRight: "5px" }}
                                />
                                Yes
                              </label>
                              <span />
                              <label>
                                <Field
                                  type="radio"
                                  value="No"
                                  name="tdsLowerDeduction"
                                  style={{ marginRight: "5px" }}
                                />
                                No
                              </label>
                            </div>
                          </Grid>
                        </Grid>
                      </FormStep>
                      {/*  */}
                      {/*  */}
                      {/* Step 3 form */}
                      <FormStep
                        stepName="Bank Details"
                        validationSchema={validationSchemaForStepThree}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Beneficiary Bank And Address"
                              name="beneficiaryBankAndAddress"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Account Number"
                              name="accountNumber"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormSelect
                              options={accountTypeOptions}
                              label="Account Type"
                              name="accountType"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Account Name"
                              name="accountName"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField label="IFSC Code" name="ifscCode" />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Swift Code/IBAN"
                              name="swiftCodeIban"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Swift Code Of Intermediary/RoutingBank"
                              name="swiftCodeOfIntermediaryRoutingBank"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <FormTextField
                              label="Intermediary/Routing Bank"
                              name="intermediaryRoutingBank"
                            />
                          </Grid>
                        </Grid>
                      </FormStep>
                      {/*  */}
                      {/*  */}
                      {/* Step 4 form */}
                      <FormStep
                        stepName="Documents"
                        validationSchema={validationSchemaForStepFour}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormFileUpload
                              label="Incorporation Certificate"
                              name="incorporationCertificate"
                              email={rejectedVendorData?.email}
                              isRejected={
                                rejectedVendorData?.attachments
                                  ?.incorporationCertificate
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormFileUpload
                              label="Cancelled Cheque"
                              name="cancelledCheque"
                              email={rejectedVendorData?.email}
                              isRejected={
                                rejectedVendorData?.attachments?.cancelledCheque
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormFileUpload
                              label="PAN Card"
                              name="panCard"
                              email={rejectedVendorData?.email}
                              isRejected={
                                rejectedVendorData?.attachments?.panCard
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormFileUpload
                              label="GST Registration Certificate"
                              name="gstRegistrationCertificate"
                              email={rejectedVendorData?.email}
                              isRejected={
                                rejectedVendorData?.attachments
                                  ?.gstRegistrationCertificate
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormFileUpload
                              label="Valid LUT"
                              name="validLut"
                              email={rejectedVendorData?.email}
                              isRejected={
                                rejectedVendorData?.attachments?.validLut
                              }
                            />
                          </Grid>
                          {state.sezNonsez === "SEZ" && (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <FormFileUpload
                                label="SEZ Approval"
                                name="sezApproval"
                                email={rejectedVendorData?.email}
                                isRejected={
                                  rejectedVendorData?.attachments?.sezApproval
                                }
                              />
                            </Grid>
                          )}
                          {state.pfAccountNumber && (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <FormFileUpload
                                label="PF Certificate"
                                name="pfCertificate"
                                email={rejectedVendorData?.email}
                                isRejected={
                                  rejectedVendorData?.attachments?.pfCertificate
                                }
                              />
                            </Grid>
                          )}
                          {state.esiAccountNumber && (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <FormFileUpload
                                label="ESI Certificate"
                                name="esiCertificate"
                                email={rejectedVendorData?.email}
                                isRejected={
                                  rejectedVendorData?.attachments
                                    ?.esiCertificate
                                }
                              />
                            </Grid>
                          )}
                          {state.tdsLowerDeduction == "Yes" && (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <FormFileUpload
                                label="TDS Lower Deduction Certificate"
                                name="tdsLowerDeductionCertificate"
                                email={rejectedVendorData?.email}
                                isRejected={
                                  rejectedVendorData?.attachments
                                    ?.tdsLowerDeductionCertificate
                                }
                              />
                            </Grid>
                          )}
                          {state.msmedAct === "Yes" && (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <FormFileUpload
                                label="MSME Certificate"
                                name="msmeCertificate"
                                email={rejectedVendorData?.email}
                                isRejected={
                                  rejectedVendorData?.attachments
                                    ?.msmeCertificate
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </FormStep>
                    </MultiStepForm>
                  </MDBox>
                </MDBox>
              </CardContent>
            </Card>
          </div>
        )}

        <Modal
          open={openSubmitModal}
          onClose={handleCloseSubmitModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={submitModalStyle}>
            <MDTypography>Are you sure you want to Submit?</MDTypography>
            <MDBox mt={2} display="flex">
              <div>
                <MDButton
                  style={{ width: "10rem", marginRight: "1rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleCloseSubmitModal}
                >
                  Cancel
                </MDButton>
              </div>
              <div style={{ float: "right" }}>
                <MDButton
                  style={{ width: "10rem" }}
                  color="success"
                  onClick={submitFormHandler}
                >
                  Yes
                </MDButton>
              </div>
            </MDBox>
            <MDBox mt={2}>
              <Collapse in={isSuccessFormSubmit}>
                <Alert
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsSuccessFormSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Form submitted successfully!
                </Alert>
              </Collapse>
              <Collapse in={isErrorFormSubmit}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsErrorFormSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errorMsgFormSubmit}
                </Alert>
              </Collapse>
            </MDBox>
          </MDCard>
        </Modal>
      </BasicLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rejectedVendorReducer: state.getVendorByVendorIdReducer,
    vendorIdReducer: state.getVendorIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    createVendorDetails: (payload, token) =>
      dispatch(createVendorDetails(payload, token)),
    rejectedVendorDetails: (payload, token) =>
      dispatch(rejectedVendorDetails(payload, token)),
    getRejectedVendorDetails: (vendorId) =>
      dispatch(getVendorByVendorId(vendorId)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(StandardVendorForm);
