/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

import { getSubModulesByRoleId } from "../../store";

import Cookies from "universal-cookie";
import Rejections from "layouts/rejections/rejection-1";
import AcceptedRejections from "layouts/rejections/rejection-2";

const cookies = new Cookies();

const RejectionsParent = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let rejectionsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "e5ef2125-589b-4e0f-8b2c-b55863a57c01"
  );

  let acceptedListModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "08756963-3131-4682-bbc8-67c1d4c5b810"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {rejectionsModule && acceptedListModule ? (
              <TabView>
                <TabPanel header="Rejections">
                  <Rejections />
                </TabPanel>
                <TabPanel header="Accepted List">
                  <AcceptedRejections />
                </TabPanel>
              </TabView>
            ) : !rejectionsModule && acceptedListModule ? (
              <TabView>
                <TabPanel header="Accepted List">
                  <AcceptedRejections />
                </TabPanel>
              </TabView>
            ) : rejectionsModule && !acceptedListModule ? (
              <TabView>
                <TabPanel header="Rejections">
                  <Rejections />
                </TabPanel>
              </TabView>
            ) : (
              <>
                <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                  No Rejections Access
                </h4>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(RejectionsParent);
