/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getTransactions,
  createTransaction,
} from "../../../store";

// React-Select component and styling

import "../../../index.css";

// config file

import Config from "../../../config/index";
// Cookies
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const styleForCreatePicklist = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const Transaction = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  createTransaction,
  getTransactions,
  getTransactionsReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let transactionModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d267d04a-879e-409b-92f2-4f0c4941b742"
  );
  let createAccess = transactionModule
    ? transactionModule.actionId.includes(
        "2ae3dbff-15a2-427c-b33e-7f6de47d8e8c"
      )
    : null;
  let viewAccess = transactionModule
    ? transactionModule.actionId.includes(
        "39f08fad-e40f-443c-a502-9a2bc1f8dfe5"
      )
    : null;

  useEffect(() => {
    getTransactions(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Transaction code", accessor: "transactionCode" },
      { Header: "Item code/Des", accessor: "itemCodeDes" },
      { Header: "Request Qty", accessor: "requestQty" },
      { Header: "Available Qty", accessor: "balanceQty" },
      { Header: "To be Picked Qty ", accessor: "pckedQty" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [isErrorMsgForCreate, setIsErrorMsgForCreate] = useState(false);
  const [isErrorForPicklist, setIsErrorPicklist] = useState(false);
  const [isSuccessForPicklist, setIsSuccessPicklist] = useState(false);

  useEffect(() => {
    if (isErrorForPicklist) {
      setTimeout(() => {
        setIsErrorPicklist(false);
      }, 3000);
    }
  }, [isErrorForPicklist]);

  useEffect(() => {
    if (isSuccessForPicklist) {
      setTimeout(() => {
        setIsSuccessPicklist(false);
      }, 3000);
    }
  }, [isSuccessForPicklist]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempAsset = [];
    let data = getTransactionsReducer.transactions.data
      ? getTransactionsReducer.transactions.data
      : [];
    setLoading(getTransactionsReducer.loading);
    data.map((purchase) => {
      const formatDate = new Date(purchase.cd);
      let len = desLength;
      let des = purchase?.itemDescription;
      const purchaseObject = {
        privateKey: purchase.transactionId,
        transactionCode: purchase.transactionCode,
        itemCodeDes: `${purchase.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        requestQty: `${purchase.requestQty}`,
        balanceQty: `${purchase.balanceQty}`,
        transactionId: purchase.transactionId,
        pckedQty: `${purchase.toBePickedQty}`,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        itemId: purchase.itemId,
        // isEditable: purchase.isEditable,
      };
      viewAccess && tempAsset.push(purchaseObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [getTransactionsReducer]);

  const [openCreatePicklistModal, setOpenCreatePicklistModal] = useState(false);

  const handleOpenCreatePicklistModal = (data = {}) => {
    setOpenCreatePicklistModal(true);
  };

  const handleCloseCreatePicklistModal = () => {
    setOpenCreatePicklistModal(false);
  };

  const createPicklistHandler = (event, data) => {
    handleOpenCreatePicklistModal(data);
  };

  const [uncheck, setUncheck] = useState(false);

  const [selectedCreatePicklistData, setSelectedCreatePicklistData] = useState(
    []
  );

  const createPicklistForData = (data) => {
    setSelectedCreatePicklistData(data);
  };
  const onClickCreateReleaseList = async () => {
    let tempArray = [];
    selectedCreatePicklistData.map((data) => {
      const tempPayload = {
        itemId: data.itemId,
        balanceQty: data.balanceQty,
        qty: data.requestQty,
        transactionId: data.transactionId,
      };
      tempArray.push(tempPayload);
    });

    let payload = {
      mvtId: 9,
      items: tempArray,
    };
    let res = await createTransaction(payload, jwtToken);
    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessPicklist(true);
      getTransactions(jwtToken);

      setTimeout(() => {
        handleCloseCreatePicklistModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorPicklist(true);
      setIsErrorMsgForCreate(res.data.data.msg);
    }
  };

  return (
    <div>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* {createAssetAccess && ( */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              // mb={3}
            ></MDBox>

            <MDBox mt={-9}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  selection={true}
                  uncheckProp={uncheck}
                  selectionButtonClick={createPicklistForData}
                  selectionButtonComponent={
                    <Tooltip title="Create Picklist">
                      {createAccess && (
                        <MDButton
                          color="white"
                          type="button"
                          style={{
                            marginTop: "0.5rem",
                            color: "white",
                            border: "1px solid #0275d8",
                            background: "#0275d8",
                            marginLeft: "1rem",
                            marginBottom: "0.8rem",
                          }}
                          onClick={(evt, data) =>
                            createPicklistHandler(evt, data)
                          }
                        >
                          Create Picklist
                        </MDButton>
                      )}
                    </Tooltip>
                  }
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>

            <Modal
              open={openCreatePicklistModal}
              onClose={handleCloseCreatePicklistModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreatePicklist}>
                <MDTypography>
                  Are you sure you want to Create Picklist?
                </MDTypography>
                <MDBox mt={2} ml={3}>
                  <MDButton
                    style={{ width: "10rem", marginRight: "0.5rem" }}
                    color="info"
                    variant="outlined"
                    onClick={handleCloseCreatePicklistModal}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    style={{ width: "10rem", marginLeft: "0.5rem" }}
                    color="info"
                    onClick={onClickCreateReleaseList}
                  >
                    Create
                  </MDButton>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessForPicklist}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessPicklist(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Created successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorForPicklist}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorPicklist(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {isErrorMsgForCreate}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getTransactionsReducer: state.getTransactionsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getTransactions: (jwtToken) => dispatch(getTransactions(jwtToken)),
    createTransaction: (payload, jwtToken) =>
      dispatch(createTransaction(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
