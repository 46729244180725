import MDButton from "components/MDButton";

const FormNavigation = (props) => {
  return (
    <div
      style={{
        display: props.hasPrevious && "flex",
        marginTop: 50,
        justifyContent: props.hasPrevious && "space-between",
      }}
    >
      {props.hasPrevious && (
        <MDButton
          color="info"
          variant="contained"
          type="button"
          onClick={props.onBackClick}
        >
          Back
        </MDButton>
      )}
      <span>
        {props.isLastStep && (
          <MDButton
            color="info"
            variant="contained"
            type="button"
            onClick={props.onReviewClick}
          >
            Review
          </MDButton>
        )}
        <MDButton
          color={props.isLastStep ? "success" : "info"}
          variant="contained"
          type="submit"
          style={{
            marginLeft: "1rem",
            float: !props.hasPrevious && "right",
          }}
        >
          {props.isLastStep ? "Submit" : "Next"}
        </MDButton>
      </span>
    </div>
  );
};

export default FormNavigation;
