import {
  GET_FINISHEDGOODS_REQUEST,
  GET_FINISHEDGOODS_SUCCESS,
  GET_FINISHEDGOODS_FAILURE,
  GET_GROUPED_FINISHEDGOODS_REQUEST,
  GET_GROUPED_FINISHEDGOODS_SUCCESS,
  GET_GROUPED_FINISHEDGOODS_FAILURE,
  GET_VINBYFGCODE_REQUEST,
  GET_VINBYFGCODE_SUCCESS,
  GET_VINBYFGCODE_FAILURE,
  GET_SUIDS_FOR_FG_REQUEST,
  GET_SUIDS_FOR_FG_SUCCESS,
  GET_SUIDS_FOR_FG_FAILURE,
  GET_ITEMS_FOR_FG_REQUEST,
  GET_ITEMS_FOR_FG_SUCCESS,
  GET_ITEMS_FOR_FG_FAILURE,
  GET_FG_CODE_FOR_DOCUMENT_REQUEST,
  GET_FG_CODE_FOR_DOCUMENT_SUCCESS,
  GET_FG_CODE_FOR_DOCUMENT_FAILURE,
} from "./fgTransactionTypes";

const initialState = {
  loading: false,
  finishedGoods: [],
  error: "",
};

const getFinishedGoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINISHEDGOODS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FINISHEDGOODS_SUCCESS:
      return {
        loading: false,
        finishedGoods: action.payload,
        error: "",
      };
    case GET_FINISHEDGOODS_FAILURE:
      return {
        loading: false,
        finishedGoods: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForGrouped = {
  loading: false,
  groupedFinishedGoods: [],
  error: "",
};

const getGroupedFinishedGoodsReducer = (
  state = initialStateForGrouped,
  action
) => {
  switch (action.type) {
    case GET_GROUPED_FINISHEDGOODS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUPED_FINISHEDGOODS_SUCCESS:
      return {
        loading: false,
        groupedFinishedGoods: action.payload,
        error: "",
      };
    case GET_GROUPED_FINISHEDGOODS_FAILURE:
      return {
        loading: false,
        groupedFinishedGoods: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVin = {
  loading: false,
  vin: [],
  error: "",
};

const getVinByFgCodeReducer = (state = initialStateForVin, action) => {
  switch (action.type) {
    case GET_VINBYFGCODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VINBYFGCODE_SUCCESS:
      return {
        loading: false,
        vin: action.payload,
        error: "",
      };
    case GET_VINBYFGCODE_FAILURE:
      return {
        loading: false,
        vin: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSuidsForFg = {
  loading: false,
  suidsForFg: [],
  error: "",
};

const getSuidsForFgReducer = (state = initialStateForSuidsForFg, action) => {
  switch (action.type) {
    case GET_SUIDS_FOR_FG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUIDS_FOR_FG_SUCCESS:
      return {
        loading: false,
        suidsForFg: action.payload,
        error: "",
      };
    case GET_SUIDS_FOR_FG_FAILURE:
      return {
        loading: false,
        suidsForFg: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForFgItems = {
  loading: false,
  fgItems: [],
  error: "",
};

const getFgItemsReducer = (state = initialStateForFgItems, action) => {
  switch (action.type) {
    case GET_ITEMS_FOR_FG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMS_FOR_FG_SUCCESS:
      return {
        loading: false,
        fgItems: action.payload,
        error: "",
      };
    case GET_ITEMS_FOR_FG_FAILURE:
      return {
        loading: false,
        fgItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForFgCodeDocument = {
  loading: false,
  fgCodeDocument: [],
  error: "",
};

const getFgCodeDocumentReducer = (
  state = initialStateForFgCodeDocument,
  action
) => {
  switch (action.type) {
    case GET_FG_CODE_FOR_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FG_CODE_FOR_DOCUMENT_SUCCESS:
      return {
        loading: false,
        fgCodeDocument: action.payload,
        error: "",
      };
    case GET_FG_CODE_FOR_DOCUMENT_FAILURE:
      return {
        loading: false,
        fgCodeDocument: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getFinishedGoodsReducer,
  getGroupedFinishedGoodsReducer,
  getVinByFgCodeReducer,
  getSuidsForFgReducer,
  getFgItemsReducer,
  getFgCodeDocumentReducer,
};
