/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import MDCard from "components/MDCard";

import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";

import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Functions from store
import { getProfile } from "../../store";

// Cookies
import Cookies from "universal-cookie";
import { useFormik } from "formik";
const cookies = new Cookies();

function BannerAndLogo({ profileData, getProfile }) {
  let user = cookies.get("loginDetailsForWMS");
  let jwtToken = user?.jwt;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    boxShadow: 24,
    p: 0,
    pt: 3,
  };

  let email = user.data.email;

  useEffect(() => {
    getProfile(email, jwtToken);
  }, [getProfile]);

  const [profileInfo, setProfileInfo] = useState({});

  useEffect(() => {
    let temp = profileData.profile ? profileData.profile : {};
    setProfileInfo(temp);
  }, [profileData]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedMobileImage, setSelectedMobileImage] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  useEffect(() => {
    const storedImage = localStorage.getItem("uploadedImage");
    const storedLogo = localStorage.getItem("uploadedLogo");
    const storedMobileImage = localStorage.getItem("uploadedMobileImage");
    
    if (storedImage) {
      setSelectedImage(storedImage);
    }
    if (storedLogo) {
      setSelectedLogo(storedLogo);
    }
    if (storedMobileImage) {
      setSelectedMobileImage(storedMobileImage);
    }
  }, []);

  const [typeOfImageSelected, setTypeOfImageSelected] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleTypeOfImageChange = (selectedOption) => {
    setTypeOfImageSelected(selectedOption);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleSubmit = (values) => {
    if (file && typeOfImageSelected) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        if (typeOfImageSelected.value === 1) {
          setSelectedImage(imageData);
          localStorage.setItem("uploadedImage", imageData);
        } else if (typeOfImageSelected.value === 2) {
          setSelectedLogo(imageData);
          localStorage.setItem("uploadedLogo", imageData);
        } else if (typeOfImageSelected.value === 3) {
          setSelectedMobileImage(imageData);
          localStorage.setItem("uploadedMobileImage", imageData);
        }
        setShowConfirmation(true);
      };
      reader.readAsDataURL(file);
      handleAddImageCloseModal();
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const [addImageModal, setAddImageModal] = useState(false);

  const handleAddImageModal = () => {
    setAddImageModal(true);
  };

  const handleAddImageCloseModal = () => {
    setAddImageModal(false);
    setTypeOfImageSelected("");
    setFile(null);
    setFileName("");
  };

  const formik = useFormik({
    initialValues: {
      ImageId: "",
    },
    onSubmit: handleSubmit,
  });

  const uploadOptions = [
    {
      label: "Banner",
      value: 1,
    },
    {
      label: "Logo",
      value: 2,
    },
    {
      label: "Mobile Logo",
      value: 3,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          width: { xs: "100%", sm: "100%", md: "0%", lg: "100%", xl: "100%" },
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "50px",
          marginBottom: "100px",
        }}
      >
        <MDBox ml={2} mt={2}>
          <Tooltip title="Upload Image">
            <MDButton
              type="button"
              variant="outlined"
              color="info"
              onClick={handleAddImageModal}
              iconOnly
            >
              <Icon>file_upload</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
        <Divider />

        <Grid container spacing={3} alignItems="center">
          <Modal
            open={addImageModal}
            onClose={handleAddImageCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                  Upload
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleAddImageCloseModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />
              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formik.handleSubmit}
                >
                  <MDBox mb={1}>
                    <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                      Select Type
                    </MDTypography>
                    <Select
                      isClearable
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Choose one..."
                      name="ImageId"
                      onBlur={() => {
                        formik.handleBlur({ target: { name: "ImageId" } });
                      }}
                      value={typeOfImageSelected}
                      options={uploadOptions}
                      onChange={(selectedOption) => {
                        handleTypeOfImageChange(selectedOption);
                        formik.setFieldValue("ImageId", selectedOption);
                      }}
                    />
                  </MDBox>
                  <MDBox mt={2}>
                    <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                      Choose an Image
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      component="label"
                      color="info"
                      style={{ width: "19rem" }}
                    >
                      Choose File
                      <input type="file" hidden onChange={handleFileChange} />
                    </MDButton>
                    {fileName && (
                      <MDTypography
                        variant="subtitle1"
                        style={{ marginTop: "0.5rem" }}
                      >
                        {fileName}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton color="success" fullWidth type="submit">
                      Submit
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
        </Grid>
        {/* <Divider /> */}
        <Grid container spacing={1} ml={2} mt={2}>
          {selectedImage && (
            <Grid item xs={12} sm={6} md={4}>
              <MDTypography variant="h3">Banner</MDTypography>
              <img src={selectedImage} alt="Banner" style={{ width: "80%",height: "20vh"  }} />
            </Grid>
          )}
          {selectedLogo && (
            <Grid item xs={12} sm={6} md={4}>
              <MDTypography variant="h3">Logo</MDTypography>
              <img
                src={selectedLogo}
                alt="Logo"
                style={{ width: "80%", height: "20vh" }}
              />
            </Grid>
          )}
          {selectedMobileImage && (
            <Grid item xs={12} sm={6} md={4}>
              <MDTypography variant="h3">Mobile Logo</MDTypography>
              <img
                src={selectedMobileImage}
                alt="Mobile"
                style={{ width: "80%", height: "20vh" }}
              />
            </Grid>
          )}
        </Grid>
      </Card>

      <Footer />

      <Snackbar
        open={showConfirmation}
        autoHideDuration={3000}
        onClose={handleCloseConfirmation}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginTop: "1rem" }}
      >
        <MuiAlert
          onClose={handleCloseConfirmation}
          severity="success"
          sx={{ width: "100%" }}
        >
          Image uploaded successfully!
        </MuiAlert>
      </Snackbar>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (email, jwtToken) => dispatch(getProfile(email, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerAndLogo);
