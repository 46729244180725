/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const GET_ALL_ROLES_REQUEST = "GET_ALL_ROLES_REQUEST";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const DELETE_ROLES_REQUEST = "DELETE_ROLES_REQUEST";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILURE = "DELETE_ROLES_FAILURE";

export const CREATE_ROLES_REQUEST = "CREATE_ROLES_REQUEST";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAILURE = "CREATE_ROLES_FAILURE";

export const EDIT_ROLES_REQUEST = "EDIT_ROLES_REQUEST";
export const EDIT_ROLES_SUCCESS = "EDIT_ROLES_SUCCESS";
export const EDIT_ROLES_FAILURE = "EDIT_ROLES_FAILURE";

export const GET_USER_ROLES_BY_DEPARTMENT_REQUEST =
  "GET_USER_ROLES_BY_DEPARTMENT_REQUEST";
export const GET_USER_ROLES_BY_DEPARTMENT_SUCCESS =
  "GET_USER_ROLES_BY_DEPARTMENT_SUCCESS";
export const GET_USER_ROLES_BY_DEPARTMENT_FAILURE =
  "GET_USER_ROLES_BY_DEPARTMENT_FAILURE";
