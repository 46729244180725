import {
  GET_ASNDETAILS_REQUEST,
  GET_ASNDETAILS_SUCCESS,
  GET_ASNDETAILS_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  GET_BINS_REQUEST,
  GET_BINS_SUCCESS,
  GET_BINS_FAILURE,
  GET_LAST_ASN_DETAILS_REQUEST,
  GET_LAST_ASN_DETAILS_SUCCESS,
  GET_LAST_ASN_DETAILS_FAILURE,
  POST_UPDATEASN_REQUEST,
  POST_UPDATEASN_SUCCESS,
  POST_UPDATEASN_FAILURE,
  POST_CONFIRM_VIRTUAL_BINS_REQUEST,
  POST_CONFIRM_VIRTUAL_BINS_SUCCESS,
  POST_CONFIRM_VIRTUAL_BINS_FAILURE,
} from "./asnDetailsTypes";

import Config from "../../config/index";

import axios from "axios";

const URL = Config.baseUrl;

let baseUrl = Config.baseUrl;

const getAsnDetailsRequest = () => {
  return {
    type: GET_ASNDETAILS_REQUEST,
  };
};

const getAsnDetailsSuccess = (payload) => {
  return {
    type: GET_ASNDETAILS_SUCCESS,
    payload: payload,
  };
};

const getAsnDetailsFailure = (error) => {
  return {
    type: GET_ASNDETAILS_FAILURE,
    payload: error,
  };
};

const getAsnDetails = (userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAsnDetailsRequest());
    let url = `${baseUrl}/asn/get_all?vendorId=${userId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAsnDetailsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAsnDetailsFailure(err.message));
      });
  };
};

const updateVehicleDetailsRequest = () => {
  return {
    type: UPDATE_VEHICLE_REQUEST,
  };
};

const updateVehicleDetailsSuccess = (payload) => {
  return {
    type: UPDATE_VEHICLE_SUCCESS,
    payload: payload,
  };
};

const updateVehicleDetailsFailure = (error) => {
  return {
    type: UPDATE_VEHICLE_FAILURE,
    payload: error,
  };
};

const updateVehicleDetails = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateVehicleDetailsRequest());
    let url = `${baseUrl}/asn/update_vehicle_details`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateVehicleDetailsSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateVehicleDetailsFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const printAsnRequest = () => {
  return {
    type: UPDATE_VEHICLE_REQUEST,
  };
};

const printAsnSuccess = (payload) => {
  return {
    type: UPDATE_VEHICLE_SUCCESS,
    payload: payload,
  };
};

const printAsnFailure = (error) => {
  return {
    type: UPDATE_VEHICLE_FAILURE,
    payload: error,
  };
};

const printAsn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  let data = {
    asnCode: payload,
  };
  return (dispatch) => {
    dispatch(printAsnRequest());
    let url = `${baseUrl}/asn/print`;
    return axios
      .post(url, data, headers)
      .then((response) => {
        dispatch(printAsnSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printAsnFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const getBinsDataRequest = () => {
  return {
    type: GET_BINS_REQUEST,
  };
};

const getBinsDataSuccess = (payload) => {
  return {
    type: GET_BINS_SUCCESS,
    payload: payload,
  };
};

const getBinsDataFailure = (error) => {
  return {
    type: GET_BINS_FAILURE,
    payload: error,
  };
};

const getBinsData = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getBinsDataRequest());
    let url = `${baseUrl}/asn/get_&_verify_bins`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(getBinsDataSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getBinsDataFailure(err.message));
        return { status: false };
      });
  };
};

const getLastAsnDetailsRequest = () => {
  return {
    type: GET_LAST_ASN_DETAILS_REQUEST,
  };
};

const getLastAsnDetailsSuccess = (payload) => {
  return {
    type: GET_LAST_ASN_DETAILS_SUCCESS,
    payload: payload,
  };
};

const getLastAsnDetailsFailure = (error) => {
  return {
    type: GET_LAST_ASN_DETAILS_FAILURE,
    payload: error,
  };
};

const getLastAsnDetails = (userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLastAsnDetailsRequest());
    let url = `${baseUrl}/asn/get_last_asn_details?vendorId=${userId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLastAsnDetailsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLastAsnDetailsFailure(err.message));
      });
  };
};

const updateAsnRequest = () => {
  return {
    type: POST_UPDATEASN_REQUEST,
  };
};

const updateAsnSuccess = (payload) => {
  return {
    type: POST_UPDATEASN_SUCCESS,
    payload: payload,
  };
};

const updateAsnFaliure = (error) => {
  return {
    type: POST_UPDATEASN_FAILURE,
    payload: error,
  };
};
const updateAsn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  let data = new FormData();

  data.append("testCertificate", payload.testCertificate);
  data.append("invoice", payload.invoice);
  data.append("invoiceNo", payload.invoiceNo);
  data.append("invoiceDate", payload.invoiceDate);
  data.append("planDate", payload.planDate);
  data.append("demandId", payload.demandId);
  data.append("itemCodeDes", payload.partNoDes);
  data.append("poNoLineNo", payload.poNoLineNo);
  data.append("asnCode", payload.asnCode);
  data.append("asnQty", payload.asnQty);
  data.append("packageType", payload.packageType);
  data.append("packetNo", payload.packetNo);
  data.append("packetWeight", payload.packetWeight);
  data.append("unloadingPoint", payload.unloadingPoint);
  data.append("deliveryPlant", payload.deliveryPlant);
  data.append("vendorId", payload.vendorId);

  return (dispatch) => {
    dispatch(updateAsnRequest());
    let url = `${baseUrl}/asn/update`;
    return axios
      .post(url, data, headers)
      .then((response) => {
        dispatch(updateAsnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateAsnFaliure(err.message));
        return { status: false };
      });
  };
};

const confirmVirtualBinsRequest = () => {
  return {
    type: POST_CONFIRM_VIRTUAL_BINS_REQUEST,
  };
};

const confirmVirtualBinsSuccess = (payload) => {
  return {
    type: POST_CONFIRM_VIRTUAL_BINS_SUCCESS,
    payload: payload,
  };
};

const confirmVirtualBinsFaliure = (error) => {
  return {
    type: POST_CONFIRM_VIRTUAL_BINS_FAILURE,
    payload: error,
  };
};
const confirmVirtualBins = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(confirmVirtualBinsRequest());
    let url = `${baseUrl}/asn/verify_virtual_bins`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(confirmVirtualBinsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(confirmVirtualBinsFaliure(err.message));
        return { status: false };
      });
  };
};

export {
  getAsnDetails,
  updateVehicleDetails,
  printAsn,
  getBinsData,
  getLastAsnDetails,
  updateAsn,
  confirmVirtualBins,
};
