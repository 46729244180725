/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

const config = {
  // baseUrl: process.env.REACT_APP_baseUrl,
  mobillorUrl: process.env.REACT_APP_MOBILLORURL,
  licensesUrl: process.env.REACT_APP_LICENSESURL,
  productName: process.env.REACT_APP_PRODUCTNAME,
  dashboardUrl: process.env.REACT_APP_DASHBOARDURL,
  environmentType: process.env.REACT_APP_ENVIRONMENTTYPE,
  // userManagementUrl: "http://3.7.96.0:3000",
  userManagementUrl: process.env.REACT_APP_USERMANAGEMENTURL,
  scenariosUrl: process.env.REACT_APP_SCENARIOSURL,
  clientId: process.env.REACT_APP_CLIENTID,
  // baseUrl: "http://3.7.96.0:1880/api/v3",
  baseUrl: process.env.REACT_APP_BASEURL,
  descriptionLength: process.env.REACT_APP_DESCRIPTIONLENGTH,
  spaceManagementUrl: process.env.REACT_APP_SPACEMANAGEMENTURL,
};

export default config;
