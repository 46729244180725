import {
  GET_WAREHOUSEOCCUPANCY_REQUEST,
  GET_WAREHOUSEOCCUPANCY_SUCCESS,
  GET_WAREHOUSEOCCUPANCY_FAILURE,
  DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST,
  DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS,
  DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE,
} from "./warehouseOccupancyTypes";

const initialStateForWarehouseOccupancy = {
  loading: false,
  warehouseOccupancy: [],
  error: "",
};

const getWarehouseOccupancyReducer = (
  state = initialStateForWarehouseOccupancy,
  action
) => {
  switch (action.type) {
    case GET_WAREHOUSEOCCUPANCY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WAREHOUSEOCCUPANCY_SUCCESS:
      return {
        loading: false,
        warehouseOccupancy: action.payload,
        error: "",
      };
    case GET_WAREHOUSEOCCUPANCY_FAILURE:
      return {
        loading: false,
        warehouseOccupancy: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDownload = {
  loading: false,
  warehouseOccupancy: [],
  error: "",
};

const downloadWarehouseOccupancyReducer = (
  state = initialStateForDownload,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS:
      return {
        loading: false,
        warehouseOccupancy: action.payload,
        error: "",
      };
    case DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE:
      return {
        loading: false,
        warehouseOccupancy: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getWarehouseOccupancyReducer, downloadWarehouseOccupancyReducer };
