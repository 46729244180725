/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getAssets,
  createNewAsset,
  getAssetTypes,
  deleteAsset,
  updateAsset,
  getBins,
  mapAsset,
  getUnmappedBins,
  getUnmappedPallets,
  uploadAssetMaster,
  getMappedBinsByRfid,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
import DeleteButton from "components/standardized-components/DeleteButton";
import EditButton from "components/standardized-components/EditButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import StandardButton from "components/standardized-components/StandardButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};
const styleForPicklistViewModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  width: { xs: 350, lg: 1000 },
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const AssetMaster = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAssets,
  getAssetsReducer,
  createNewAsset,
  getAssetTypes,
  assetTypes,
  deleteAsset,
  updateAsset,

  getUnmappedBins,
  getUnmappedPallets,
  getUnmappedBinsReducer,
  getUnmappedPalletsReducer,
  mapAsset,
  getMappedBinsByRifdReducer,
  uploadAssetMaster,
  getMappedBinsByRfid,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let assetMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "fd7da1c7-7f06-4e50-837a-f7b6a08bbd3b"
  );
  let createAccess = assetMasterModule
    ? assetMasterModule.actionId.includes(
        "59b9fa57-e87d-4d30-854e-b91a266b19b0"
      )
    : null;
  let downloadAccess = assetMasterModule
    ? assetMasterModule.actionId.includes(
        "2073762b-afd5-4c9e-8255-c80f77fa45ad"
      )
    : null;
  let uploadAccess = assetMasterModule
    ? assetMasterModule.actionId.includes(
        "41729bb2-5d3a-4fa9-a595-f98d9307a7e0"
      )
    : null;
  let editAccess = assetMasterModule
    ? assetMasterModule.actionId.includes(
        "21dfefbe-24e5-4f9f-9086-288518a58f7a"
      )
    : null;
  let deleteAccess = assetMasterModule
    ? assetMasterModule.actionId.includes(
        "347b52f2-6539-4c52-bbdd-1d8299368022"
      )
    : null;
  let mapAccess = assetMasterModule
    ? assetMasterModule.actionId.includes(
        "a550b76f-e63b-4f8c-b192-e94d239dd4fb"
      )
    : null;

  useEffect(() => {
    getAssets(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Asset Code", accessor: "assetCode" },

      { Header: "Asset Description", accessor: "assetName" },
      { Header: "Asset Type", accessor: "assetType" },
      { Header: "Printer Ip ", accessor: "printerIp" },
      { Header: "Printer Port", accessor: "printerPort" },

      {
        Header: "action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorMsgForDelete, setErrorMsgForDelete] = useState("");
  const [errorMsgForMapAsset, setErrorMsgForMapAsset] = useState("");

  const [isErrorCreateNewAsset, setIsErrorCreateNewAsset] = useState(false);
  const [isSuccessCreateNewAsset, setIsSuccessCreateNewAsset] = useState(false);

  useEffect(() => {
    if (isErrorCreateNewAsset) {
      setTimeout(() => {
        setIsErrorCreateNewAsset(false);
      }, 3000);
    }
  }, [isErrorCreateNewAsset]);

  useEffect(() => {
    if (isSuccessCreateNewAsset) {
      setTimeout(() => {
        setIsSuccessCreateNewAsset(false);
      }, 3000);
    }
  }, [isSuccessCreateNewAsset]);

  const [assetTypeSelected, setAssetTypeSelected] = useState("");
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);

  useEffect(() => {
    let tempAssetTypeOptions = [];
    let data = assetTypes.assetTypes.data ? assetTypes.assetTypes.data : [];

    data.map((asset) => {
      tempAssetTypeOptions.push({
        label: asset.assetTypeCode,
        value: asset.assetTypeId,
      });
    });

    setAssetTypeOptions(tempAssetTypeOptions);
  }, [assetTypes]);

  const [openBinViewModal, setOpenBinViewModal] = useState(false);

  const handleOpenViewBinModal = (assets) => {
    setOpenBinViewModal(true);
  };

  const handleCloseViewBinModal = () => {
    setOpenBinViewModal(false);
  };

  const [suidTable, setSuidTable] = useState({
    columns: [
      { Header: "Bin/Pallet Code", accessor: "binCode", align: "left" },
      { Header: "Bin/Pallet Name", accessor: "binName" },
      // { Header: "location Code", accessor: "locationCode", align: "left" },
      // { Header: "QTY", accessor: "suqty", align: "left" },
    ],
    rows: [],
  });

  const [loading2, setLoading2] = useState(false);

  const { columns: binColumns, rows: binRows } = suidTable;
  useEffect(() => {
    let tempGRN = [];
    let data = getMappedBinsByRifdReducer.mappedBins.data
      ? getMappedBinsByRifdReducer.mappedBins.data
      : [];
    setLoading2(getMappedBinsByRifdReducer.loading);
    data.map((singleSuid) => {
      const singleUser = {
        binCode: singleSuid.code,
        binName: singleSuid.name,
        lotSerialNo: `${singleSuid.lotNumber ? singleSuid.lotNumber : "NA"} / ${
          singleSuid.serialNumber ? singleSuid.serialNumber : "NA"
        }`,
        locationCode: singleSuid.locationCode,
      };
      tempGRN.push(singleUser);
    });
    setSuidTable({ ...suidTable, rows: tempGRN });
  }, [getMappedBinsByRifdReducer]);
  const [loading, setLoading] = useState(true);
  const [rfid, setRfid] = useState("");
  useEffect(() => {
    getMappedBinsByRfid(rfid, jwtToken);
  }, [rfid]);

  useEffect(() => {
    let tempAsset = [];
    let data = getAssetsReducer.assets.data ? getAssetsReducer.assets.data : [];
    setLoading(getAssetsReducer.loading);
    data.map((assets) => {
      const assetsObject = {
        assetCode: assets.assetCode,
        assetName: assets.assetName,
        assetType: assets.assetTypeCode,
        printerIp: assets.printerIp,
        printerPort: assets.printerPort,
        isEditable: assets.isEditable,
        action: (
          <div>
            {editAccess && (
              <EditButton
                tooltip="Edit Asset"
                disabled={assets.isEditable === 0}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateAssetModal(assets)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}

            {deleteAccess && (
              <DeleteButton
                tooltip="Delete Asset"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteAssetModal(assets)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}

            {mapAccess &&
            assets.assetTypeCode == "RFID" &&
            assets.isEditable === 1 ? (
              <StandardButton
                tooltip="Map Asset"
                // disabled={assets.isEditable === 0}
                variant="gradient"
                color="success"
                iconOnly
                icon="map"
                type="button"
                onClick={() => {
                  handleOpenMapAssetModal(assets);
                  getUnmappedBins(jwtToken);
                  getUnmappedPallets(jwtToken);
                }}
                style={{
                  marginRight: "5px",
                  marginLeft: assets.isEditable === 0 ? "0rem" : undefined,
                  background: assets.isEditable === 0 ? "grey" : undefined,
                  color: assets.isEditable === 0 ? "white" : undefined,
                  opacity: assets.isEditable === 0 ? 0.5 : undefined,
                }}
              >
                <Icon>map</Icon>
              </StandardButton>
            ) : (
              <StandardButton
                tooltip="Map Asset"
                disabled
                variant="gradient"
                color="success"
                iconOnly
                icon="map"
                type="button"
                onClick={() => {
                  handleOpenMapAssetModal(assets);
                  getUnmappedBins(jwtToken);
                  getUnmappedPallets(jwtToken);
                }}
                style={{
                  marginRight: "5px",
                  marginLeft: assets.isEditable === 0 ? "0rem" : undefined,
                  background: assets.isEditable === 0 ? "grey" : undefined,
                  color: assets.isEditable === 0 ? "white" : undefined,
                  opacity: assets.isEditable === 0 ? 0.5 : undefined,
                }}
              >
                <Icon>map</Icon>
              </StandardButton>
            )}
            {assets.assetTypeCode == "RFID" && assets.isEditable === 0 ? (
              <Tooltip title="View">
                <MDButton
                  variant="gradient"
                  color="info"
                  // disabled={assets.isEditable === 1}
                  // disabled={!viewGRN}
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewBinModal(assets);
                    setRfid(assets.assetCode);
                  }}
                  style={{
                    marginRight: "5px",
                    marginLeft: assets.isEditable === 1 ? "0rem" : undefined,
                    background: assets.isEditable === 1 ? "grey" : undefined,
                    color: assets.isEditable === 1 ? "white" : undefined,
                    opacity: assets.isEditable === 1 ? 0.5 : undefined,
                  }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
            ) : (
              <Tooltip title="View">
                <MDButton
                  variant="gradient"
                  color="info"
                  // disabled={assets.isEditable === 1}
                  disabled
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewBinModal(assets);
                    setRfid(assets.assetCode);
                  }}
                  style={{
                    marginRight: "5px",
                    marginLeft: assets.isEditable === 1 ? "0rem" : undefined,
                    background: assets.isEditable === 1 ? "grey" : undefined,
                    color: assets.isEditable === 1 ? "white" : undefined,
                    opacity: assets.isEditable === 1 ? 0.5 : undefined,
                  }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
            )}
          </div>
        ),
      };
      tempAsset.push(assetsObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [getAssetsReducer]);

  const handleAssetTypeChange = (selectedZone) => {
    setAssetTypeSelected(selectedZone);
  };

  let typeOptions = [
    {
      label: "Bin",
      value: "bin",
    },
    {
      label: "Pallet",
      value: "pallet",
    },
  ];

  const [masterOptions, setMasterTypeOptions] = useState([]);

  const [selectedType, setSelectedType] = useState("");

  const handleTypeChange = (selectedMaster) => {
    setSelectedType(selectedMaster);
    setSelectedMaster("");
  };

  useEffect(() => {
    let tempBinPalletOptions = [];
    let data;
    if (selectedType?.value == "bin") {
      data = getUnmappedBinsReducer.unBins.data
        ? getUnmappedBinsReducer.unBins.data
        : [];
    }

    if (selectedType?.value == "pallet") {
      data = getUnmappedPalletsReducer.unPallets.data
        ? getUnmappedPalletsReducer.unPallets.data
        : [];
    }
    data?.map((asset) => {
      tempBinPalletOptions.push({
        label: selectedType?.value == "bin" ? asset.binCode : asset.palletCode,
        value: selectedType?.value == "bin" ? asset.binId : asset.palletId,
      });
    });

    setMasterTypeOptions(tempBinPalletOptions);
  }, [assetTypes, selectedType]);

  const [selectedMaster, setSelectedMaster] = useState("");

  const handleMasterChange = (selectedMaster) => {
    setSelectedMaster(selectedMaster);
  };

  const [openCreateAssetModal, setOpenCreateAssetModal] = useState(false);

  const handleOpenCreateAssetModal = () => {
    getAssetTypes(jwtToken);
    setOpenCreateAssetModal(true);
    // formikForCreateAsset.setValues(initialValueForCreateAsset);
    // formikForCreateAsset.setTouched({});
    // formikForCreateAsset.setErrors({});
    setAssetTypeSelected("");
  };

  const handleCloseCreateAssetModal = () => {
    setOpenCreateAssetModal(false);
    setAssetTypeSelected("");
  };

  const initialValueForCreateAsset = {
    assetType: "",
    assetName: "",
    ip: "",
    port: "",
  };

  let isRequired = true;

  const validationSchema = Yup.object({
    // assetName: isRequired
    //   ? Yup.string().required("Enter the Asset Name")
    //   : Yup.string().notRequired(),
    assetCode: Yup.string().required("Enter the Asset Code"),
  });

  const onSubmitForCreateAssetMaster = async (values, { resetForm }) => {
    let payload = {
      assetTypeId: assetTypeSelected?.value,
      assetName: values.assetName,
      assetCode: values.assetCode,
      printerIp: values.ip,
      printerPort: values.port,
      userId: userId,
    };

    let res = await createNewAsset(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreateNewAsset(true);
      getAssets(jwtToken);
      setAssetTypeSelected("");

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForCreate(res.data.data.msg);
      setIsErrorCreateNewAsset(true);
    }
  };

  const formikForCreateAsset = useFormik({
    initialValues: initialValueForCreateAsset,
    validationSchema: validationSchema,
    onSubmit: onSubmitForCreateAssetMaster,
  });

  const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false);
  const [singleAssetDataForDelete, setSingleAssetDataForDelete] = useState({});

  const handleOpenDeleteAssetModal = (asset = {}) => {
    setSingleAssetDataForDelete(asset);
    setOpenDeleteAssetModal(true);
  };
  const handleCloseDeleteAssetModal = () => {
    setOpenDeleteAssetModal(false);
    setAssetTypeSelected("");
  };

  const [isErrorDeleteAsset, setIsErrorDeleteAsset] = useState(false);
  const [isSuccessDeleteAsset, setIsSuccessDeleteAsset] = useState(false);

  useEffect(() => {
    if (isErrorDeleteAsset) {
      setTimeout(() => {
        setIsErrorDeleteAsset(false);
      }, 3000);
    }
  }, [isErrorDeleteAsset]);

  useEffect(() => {
    if (isSuccessDeleteAsset) {
      setTimeout(() => {
        setIsSuccessDeleteAsset(false);
      }, 3000);
    }
  }, [isSuccessDeleteAsset]);

  const deleteAssetData = async () => {
    let assetId = singleAssetDataForDelete.assetId;

    let response = await deleteAsset(assetId, userId, jwtToken);

    if (response.status) {
      setIsSuccessDeleteAsset(true);
      getAssets(jwtToken);

      setTimeout(() => {
        handleCloseDeleteAssetModal();
      }, 2250);
    }
    if (!response.status) {
      setErrorMsgForDelete(response.data.data.msg);
      setIsErrorDeleteAsset(true);
    }
  };

  const [openUpdateAssetModal, setOpenUpdateAssetModal] = useState(false);
  const [singleAssetDataForUpdate, setAssetDataForUpdate] = useState({});
  const handleOpenUpdateAssetModal = (Asset = {}) => {
    setAssetDataForUpdate(Asset);
    setOpenUpdateAssetModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateAssetModal = () => {
    setOpenUpdateAssetModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    assetName: singleAssetDataForUpdate.assetName,
    printerIp: singleAssetDataForUpdate.printerIp,
    printerPort: singleAssetDataForUpdate.printerPort,
  };

  const validationSchemaForUpdate = Yup.object({
    assetName: Yup.string().required("Enter the asset name!"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      assetName: values.assetName,
      assetId: singleAssetDataForUpdate.assetId,
      userId: userId,
      printerIp: values.printerIp ? values.printerIp : "",
      printerPort: values.printerPort ? values.printerPort : "",
    };
    let res = await updateAsset(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getAssets(jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [openMapAssetModal, setOpenMapAssetModal] = useState(false);
  const [singleAssetDataForMapAsset, setSingleAssetDataForMapAsset] = useState(
    {}
  );

  const handleOpenMapAssetModal = (Asset = {}) => {
    setOpenMapAssetModal(true);
    setSingleAssetDataForMapAsset(Asset);
    setSelectedType("");
    setSelectedMaster("");
  };

  const handleCloseMapAssetModal = () => {
    setOpenMapAssetModal(false);
    setAssetTypeSelected("");
  };

  const [isErrorForMapAsset, setIsErrorForMapAsset] = useState(false);
  const [isSuccessForMapAsset, setIsSuccessForMapAsset] = useState(false);

  useEffect(() => {
    if (isErrorForMapAsset) {
      setTimeout(() => {
        setIsErrorForMapAsset(false);
      }, 3000);
    }
  }, [isErrorForMapAsset]);

  useEffect(() => {
    if (isSuccessForMapAsset) {
      setTimeout(() => {
        setIsSuccessForMapAsset(false);
      }, 3000);
    }
  }, [isSuccessForMapAsset]);

  const initialValueForMapAsset = {
    assetType: "",
  };

  const validationSchemaForMapAsset = Yup.object({});

  const onSubmitForForMapAsset = async (values, { resetForm }) => {
    let payload;

    if (selectedType?.value == "bin") {
      payload = {
        binId: selectedMaster.value,

        assetId: singleAssetDataForMapAsset.assetId,
        userId: userId,
      };
    }

    if (selectedType?.value == "pallet") {
      payload = {
        palletId: selectedMaster.value,

        assetId: singleAssetDataForMapAsset.assetId,
        userId: userId,
      };
    }

    let res = await mapAsset(payload, selectedType?.value, jwtToken);

    if (res.status) {
      setIsSuccessForMapAsset(true);
      getAssets(jwtToken);
      setAssetTypeSelected("");
      setSelectedType("");
      setSelectedMaster("");
      resetForm();
    }
    if (!res.status) {
      setErrorMsgForMapAsset(res.data.data.msg);
      setIsErrorForMapAsset(true);
    }
  };

  const formikForMapAsset = useFormik({
    initialValues: initialValueForMapAsset,
    validationSchema: validationSchemaForMapAsset,
    onSubmit: onSubmitForForMapAsset,
  });

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("asset", uploadCsvData);

    let res = await uploadAssetMaster(data, jwtToken);

    if (res.status) {
      getAssets(jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification();
    }
  };

  const headers = [
    {
      label: "assetCode",
      key: "assetCode",
    },
    {
      label: "assetName",
      key: "assetName",
    },
    { label: "assetTypeCode", key: "assetTypeCode" },
    { label: "printerIp", key: "printerIp" },
    { label: "printerPort", key: "printerPort" },
  ];

  const csvLink = {
    filename: "Assets List.csv",
    headers: headers,
    data: rows,
  };

  const csvLink2 = {
    filename: "Asset Template.csv",
    headers: headers,
    data: [],
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {/* {createAssetAccess && ( */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={3}
                >
                  <MDBox color="text" px={2} ml={-2} mb={1}>
                    {createAccess && (
                      <CreateButton
                        tooltip="Create Asset"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateAssetModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    )}

                    <CSVLink {...csvLink2}>
                      <DownloadButton
                        tooltip="Download Template"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadButton>
                    </CSVLink>

                    {uploadAccess && (
                      <UploadButton
                        tooltip="Upload Asset Master"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="info"
                        iconOnly
                        onClick={uploadCsvFilterHandler}
                      >
                        <Icon>upload</Icon>
                      </UploadButton>
                    )}

                    {downloadAccess && (
                      <CSVLink {...csvLink}>
                        <DownloadDataButton
                          tooltip="Download Asset List"
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="success"
                          iconOnly
                        >
                          <Icon>download</Icon>
                        </DownloadDataButton>
                      </CSVLink>
                    )}
                  </MDBox>
                </MDBox>
                {uploadCsvFilter && (
                  <div>
                    <div>
                      <input
                        className="choose_file"
                        style={{
                          // marginTop: "1.5rem",
                          marginLeft: "1.5rem",
                          // marginBottom: "-9rem",
                        }}
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          setUploadCsvData(e.target.files[0]);
                        }}
                      />

                      <MDButton
                        disabled={
                          !uploadCsvData.name ||
                          uploadCsvData.type !== "text/csv"
                        }
                        variant="contained"
                        color="info"
                        style={{
                          marginLeft: { xs: "1.5rem", lg: "0" },
                          marginTop: "0rem",
                        }}
                        type="button"
                        onClick={uploadCsvHandler}
                      >
                        UPLOAD
                      </MDButton>
                    </div>
                    <div>
                      <span
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontStyle: "italic",
                        }}
                      >
                        <strong>*</strong> Please upload
                        <strong>".csv"</strong> file only
                      </span>
                    </div>
                  </div>
                )}

                <MDBox mt={0}>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                      hideColumns={["", "printerIp", "printerPort"]}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
                <Modal
                  open={openCreateAssetModal}
                  onClose={handleCloseCreateAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Asset
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateAssetModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreateAsset.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Select the Asset Type
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Select Asset Type..."
                              name="assetType"
                              value={assetTypeSelected}
                              options={assetTypeOptions}
                              onChange={(selectedOption) => {
                                handleAssetTypeChange(selectedOption);
                                formikForCreateAsset.setFieldValue(
                                  "assetType",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForCreateAsset.errors.assetType && (
                              <TextError
                                msg={formikForCreateAsset.errors.assetType}
                              />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Asset Code
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="assetCode"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateAsset.handleBlur}
                              onChange={formikForCreateAsset.handleChange}
                              value={formikForCreateAsset.values.assetCode}
                              error={
                                formikForCreateAsset.touched.assetCode &&
                                formikForCreateAsset.errors.assetCode &&
                                true
                              }
                            />
                            {formikForCreateAsset.errors.assetCode && (
                              <TextError
                                msg={formikForCreateAsset.errors.assetCode}
                              />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Asset Description (Optional)
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="assetName"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateAsset.handleBlur}
                              onChange={formikForCreateAsset.handleChange}
                              value={formikForCreateAsset.values.assetName}
                              error={
                                formikForCreateAsset.touched.assetName &&
                                formikForCreateAsset.errors.assetName &&
                                true
                              }
                            />
                            {formikForCreateAsset.errors.assetName && (
                              <TextError
                                msg={formikForCreateAsset.errors.assetName}
                              />
                            )}
                          </MDBox>
                          {assetTypeSelected?.value === 5 && (
                            <MDBox mb={2}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                IP
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="ip"
                                fullWidth
                                autoComplete="off"
                                onBlur={formikForCreateAsset.handleBlur}
                                onChange={formikForCreateAsset.handleChange}
                                value={formikForCreateAsset.values.ip}
                                error={
                                  formikForCreateAsset.touched.ip &&
                                  formikForCreateAsset.errors.ip &&
                                  true
                                }
                              />
                              {formikForCreateAsset.errors.ip && (
                                <TextError
                                  msg={formikForCreateAsset.errors.ip}
                                />
                              )}
                            </MDBox>
                          )}
                          {assetTypeSelected?.value === 5 && (
                            <MDBox mb={2}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                Port
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="port"
                                fullWidth
                                autoComplete="off"
                                onBlur={formikForCreateAsset.handleBlur}
                                onChange={formikForCreateAsset.handleChange}
                                value={formikForCreateAsset.values.port}
                                error={
                                  formikForCreateAsset.touched.port &&
                                  formikForCreateAsset.errors.port &&
                                  true
                                }
                              />
                              {formikForCreateAsset.errors.port && (
                                <TextError
                                  msg={formikForCreateAsset.errors.port}
                                />
                              )}
                            </MDBox>
                          )}
                          <MDBox mt={2}>
                            <Collapse in={isSuccessCreateNewAsset}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateNewAsset(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Asset created successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorCreateNewAsset}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateNewAsset(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not create the Asset! */}
                                {errorMsgForCreate}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={
                                !formikForCreateAsset.isValid ||
                                (assetTypeSelected?.value === 5 &&
                                  formikForCreateAsset.values.ip == "") ||
                                (assetTypeSelected?.value === 5 &&
                                  formikForCreateAsset.values.port == "")
                              }
                            >
                              Create Asset
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openMapAssetModal}
                  onClose={handleCloseMapAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Map
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseMapAssetModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForMapAsset.handleSubmit}
                      >
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Select Type
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="type"
                            value={selectedType}
                            options={typeOptions}
                            onChange={(selectedOption) => {
                              handleTypeChange(selectedOption);

                              formikForMapAsset.setFieldValue(
                                "type",
                                selectedOption
                              );
                            }}
                          />
                        </MDBox>
                        {selectedType && (
                          <MDBox mb={1}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: "500" }}
                            >
                              {selectedType?.label}
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Choose one..."
                              name="master"
                              value={selectedMaster}
                              options={masterOptions}
                              onChange={(selectedOption) => {
                                handleMasterChange(selectedOption);
                                // formikForMapAsset.setFieldValue(
                                //   "master",
                                //   selectedOption
                                // );
                              }}
                            />
                          </MDBox>
                        )}
                        <MDBox>
                          <Collapse in={isErrorForMapAsset}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForMapAsset(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForMapAsset}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForMapAsset}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForMapAsset(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Asset mapped successfully
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <MDBox mt={3}>
                          <MDButton
                            color="warning"
                            fullWidth
                            type="submit"
                            disabled={!formikForMapAsset.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openUpdateAssetModal}
                  onClose={handleCloseUpdateAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Update
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseUpdateAssetModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForUpdate.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Asset Description
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="assetName"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.assetName}
                            error={
                              formikForUpdate.touched.assetName &&
                              formikForUpdate.errors.assetName &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.assetName &&
                            formikForUpdate.errors.assetName && (
                              <TextError
                                msg={formikForUpdate.errors.assetName}
                              />
                            )}
                        </MDBox>
                        {singleAssetDataForUpdate?.assetTypeId === 5 && (
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              IP
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="printerIp"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForUpdate.handleBlur}
                              onChange={formikForUpdate.handleChange}
                              value={formikForUpdate.values.printerIp}
                              error={
                                formikForUpdate.touched.printerIp &&
                                formikForUpdate.errors.printerIp &&
                                true
                              }
                            />
                            {formikForUpdate.errors.printerIp && (
                              <TextError
                                msg={formikForUpdate.errors.printerIp}
                              />
                            )}
                          </MDBox>
                        )}
                        {singleAssetDataForUpdate?.assetTypeId === 5 && (
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Port
                            </MDTypography>
                            <MDInput
                              type="number"
                              name="printerPort"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForUpdate.handleBlur}
                              onChange={formikForUpdate.handleChange}
                              value={formikForUpdate.values.printerPort}
                              error={
                                formikForUpdate.touched.printerPort &&
                                formikForUpdate.errors.printerPort &&
                                true
                              }
                            />
                            {formikForUpdate.errors.printerPort && (
                              <TextError
                                msg={formikForUpdate.errors.printerPort}
                              />
                            )}
                          </MDBox>
                        )}
                        <MDBox>
                          <Collapse in={isErrorForUpdate}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForUpdate}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForUpdate}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Asset updated successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formikForUpdate.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openDeleteAssetModal}
                  onClose={handleCloseDeleteAssetModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to delete?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseDeleteAssetModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deleteAssetData}
                        >
                          Delete
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDeleteAsset}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDeleteAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Asset deleted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDeleteAsset}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDeleteAsset(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openBinViewModal}
                  onClose={handleCloseViewBinModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForPicklistViewModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        {rfid}
                      </MDTypography>

                      <MDBox>
                        <span>
                          <Tooltip title="Close">
                            <IconButton
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                                marginBottom: "5px",
                              }}
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseViewBinModal}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      </MDBox>
                    </MDBox>
                    <MDBox>
                      {!loading2 ? (
                        <DataTable
                          table={{ columns: binColumns, rows: binRows }}
                          isSorted={true}
                          tableSearch={true}
                          entriesPerPage={{
                            defaultValue: 5,
                            entries: [5, 10, 15, 20, 25, 30],
                          }}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "100px",
                            marginBottom: "100px",
                          }}
                        >
                          <CircularProgress color="info" />
                        </div>
                      )}
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getAssetsReducer: state.getAssetsReducer,
    getUnmappedBinsReducer: state.getUnmappedBinsReducer,
    getUnmappedPalletsReducer: state.getUnmappedPalletsReducer,
    assetTypes: state.getAssetTypesReducer,
    getMappedBinsByRifdReducer: state.getMappedBinsByRifdReducer,
    profileData: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAssets: (jwtToken) => dispatch(getAssets(jwtToken)),
    deleteAsset: (assetId, userId, jwtToken) =>
      dispatch(deleteAsset(assetId, userId, jwtToken)),
    createNewAsset: (payload, jwtToken) =>
      dispatch(createNewAsset(payload, jwtToken)),
    updateAsset: (payload, jwtToken) =>
      dispatch(updateAsset(payload, jwtToken)),
    mapAsset: (payload, type, jwtToken) =>
      dispatch(mapAsset(payload, type, jwtToken)),
    getAssetTypes: (jwtToken) => dispatch(getAssetTypes(jwtToken)),
    getBins: (jwtToken) => dispatch(getBins(jwtToken)),
    getUnmappedBins: (jwtToken) => dispatch(getUnmappedBins(jwtToken)),
    getUnmappedPallets: (jwtToken) => dispatch(getUnmappedPallets(jwtToken)),
    uploadAssetMaster: (payload, jwtToken) =>
      dispatch(uploadAssetMaster(payload, jwtToken)),
    getMappedBinsByRfid: (rfid, jwtToken) =>
      dispatch(getMappedBinsByRfid(rfid, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMaster);
