import axios from "axios";
import Config from "../../../config/index";
import {
  GET_AGEING_REPORT_FAILURE,
  GET_AGEING_REPORT_REQUEST,
  GET_AGEING_REPORT_SUCCESS,
  GET_LOCATION_BY_AGEING_FAILURE,
  GET_LOCATION_BY_AGEING_REQUEST,
  GET_LOCATION_BY_AGEING_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
} from "./ageingReportTypes";

let baseUrl = Config.baseUrl;

const getAgeingReportRequest = () => {
  return {
    type: GET_AGEING_REPORT_REQUEST,
  };
};

const getAgeingReportSuccess = (payload) => {
  return {
    type: GET_AGEING_REPORT_SUCCESS,
    payload: payload,
  };
};

const getAgeingReportFailure = (error) => {
  return {
    type: GET_AGEING_REPORT_FAILURE,
    payload: error,
  };
};

const getAgeingReport = (selectedItemType,selectedLocation,parameterSelected,selectedAgeingType,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAgeingReportRequest());
    let url = `${baseUrl}/reports/ageing_report?itemTypeId=${selectedItemType}&locationId=${selectedLocation}&ageingValue=${parameterSelected}&ageingType=${selectedAgeingType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAgeingReportSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAgeingReportFailure(err.message));
      });
  };
};

const getLocationsRequest = () => {
  return {
    type: GET_LOCATION_REQUEST,
  };
};

const getLocationsSuccess = (payload) => {
  return {
    type: GET_LOCATION_SUCCESS,
    payload: payload,
  };
};

const getLocationsFailure = (error) => {
  return {
    type: GET_LOCATION_FAILURE,
    payload: error,
  };
};

const getLocations = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsRequest());
    let url = `${baseUrl}/location/get_locations`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsFailure(err.message));
      });
  };
};
const getLocationsByAgeingRequest = () => {
  return {
    type: GET_LOCATION_BY_AGEING_REQUEST,
  };
};

const getLocationsByAgeingSuccess = (payload) => {
  return {
    type: GET_LOCATION_BY_AGEING_SUCCESS,
    payload: payload,
  };
};

const getLocationsByAgeingFailure = (error) => {
  return {
    type: GET_LOCATION_BY_AGEING_FAILURE,
    payload: error,
  };
};

const getLocationsByAgeing = (selectedAgeingType,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsByAgeingRequest());
    let url = `${baseUrl}/location/get_loc_by_ageing_type?ageingType=${selectedAgeingType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsByAgeingSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByAgeingFailure(err.message));
      });
  };
};

export { getAgeingReport,getLocations,getLocationsByAgeing};
