/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
// Formik and Yup

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Functions from store
import {
  getAgeingParameter,
  getAgeingReport,
  getItemTypesForStatus,
  getLocations,
  getLocationsByAgeing,
  getSubModulesByRoleId,
} from "../../../store";

import { CSVLink } from "react-csv";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
const cookies = new Cookies();

const desLength = Config.descriptionLength;

const AgeingReports = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getItemTypeReducer,
  getAgeingParameterReducer,
  getItemTypesForStatus,
  getAgeingReportReducer,
  getAgeingParameter,
  getAgeingReport,
  getLocationsByAgeingReducer,
  getLocationsByAgeing,
  getLocations,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getItemTypesForStatus(jwtToken);
  }, []);

  useEffect(() => {
    getAgeingParameter(jwtToken);
  }, []);
  const [selectedAgeingType, setSelectedAgeingType] = useState("");
  const [selectedItemType, setSelectedItemType] = useState("");
  const [parameterSelected, setparameterSelected] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    getAgeingReport(
      selectedItemType ? selectedItemType.value : "",
      selectedLocation ? selectedLocation.value : "",
      parameterSelected ? parameterSelected.label : "",
      selectedAgeingType ? selectedAgeingType.value : "",
      jwtToken
    );
  }, [
    selectedItemType,
    selectedLocation,
    parameterSelected,
    selectedAgeingType,
  ]);

  useEffect(() => {
    getLocationsByAgeing(
      selectedAgeingType ? selectedAgeingType.value : "",
      jwtToken
    );
  }, [selectedAgeingType]);
  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);
  
  let ageingReportModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "80442bed-11bb-4717-a4d9-3b652c177a0e"
  );

  let viewAccess = ageingReportModule
    ? ageingReportModule.actionId.includes(
        "934f663b-39f3-4c9b-96ed-324ae1b4ae7d"
      )
    : null;

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Location", accessor: "location" },
      { Header: "Document Number", accessor: "documentNumber" },
      { Header: "QTY", accessor: "qty" },
      { Header: "SKU", accessor: "sku" },
      { Header: "SUID", accessor: "suid" },
      { Header: "Ageing", accessor: "ageing" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "location",
      key: "location",
    },
    { label: "documentNumber", key: "documentNumber" },
    { label: "qty", key: "qty" },
    { label: "sku", key: "sku" },
    { label: "suid", key: "suid" },
    { label: "ageing", key: "ageing" },
  ];

  const csvLink = {
    filename: "Age-ing Report.csv",
    headers: headers,
    data: rows,
  };

  const handleAgeingChange = (selectedOption) => {
    setSelectedAgeingType(selectedOption);
    setSelectedItemType("");
    setSelectedLocation("");
    setparameterSelected("");
  };

  const ageingOptions = [
    { label: "Inventory Ageing", value: "RMW" },
    { label: "FG Ageing", value: "FG" },
    { label: "Rejection Ageing", value: "REJ" },
  ];

  const [itemTypeOptions, setItemTypeOptions] = useState([]);

  const handleItemTypeChange = (selectedItemCode) => {
    setSelectedItemType(selectedItemCode);
    setSelectedLocation("");
    setparameterSelected("");
  };

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getItemTypeReducer.itemTypes.data
      ? getItemTypeReducer.itemTypes.data
      : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: item.itemType,
        value: item.itemTypeId,
        // uom: item.uom,
      });
    });
    setItemTypeOptions(tempItemCodeOptions);
  }, [getItemTypeReducer]);

  const handleParameterChange = (selectedOption) => {
    setparameterSelected(selectedOption);
  };

  const [parameterOptions, setParameterOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getAgeingParameterReducer.ageingParameter
      ? getAgeingParameterReducer.ageingParameter
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.ageingValue,
        value: activity.ageingValue,
      });
    });
    setParameterOptions(tempCreatedActivityOptions);
  }, [getAgeingParameterReducer]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getAgeingReportReducer.ageing
      ? getAgeingReportReducer.ageing
      : [];
    setLoading(getAgeingReportReducer.loading);
    data?.map((finishedGoods) => {
      const finishedGoodsObject = {
        itemCode: finishedGoods.itemCode,
        location: finishedGoods.locationCode,
        documentNumber: finishedGoods.documentNumber,
        qty: `${finishedGoods.qty} ${finishedGoods.uom}`,
        sku: finishedGoods.sku,
        ageing: finishedGoods.ageing,
        suid: finishedGoods.suid,
        status: (
          <button
            className={
              (finishedGoods.documentStatus === 1 && `status-success`) ||
              (finishedGoods.documentStatus === 2 && `status-info`) ||
              (finishedGoods.documentStatus === 3 && `status-warning`)
            }
            style={{ width: "5.5rem" }}
          >
            {(finishedGoods.documentStatus === 1 && `Open`) ||
              (finishedGoods.documentStatus === 2 && `In-Progress`) ||
              (finishedGoods.documentStatus === 3 && `Closed`)}
          </button>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewAccess &&
    setState({ ...state, rows: tempFinishedGoods });
  }, [getAgeingReportReducer]);

  const handleLocationChange = (selectedItemCode) => {
    setSelectedLocation(selectedItemCode);
    setparameterSelected("");
  };
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = getLocationsByAgeingReducer.locations
      ? getLocationsByAgeingReducer.locations
      : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        label: item.locationCode,
        value: item.locationId,
      });
    });
    setLocationOptions(tempItemCodeOptions);
  }, [getLocationsByAgeingReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-4}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <MDBox>
                      <MDBox
                        display="flex"
                        mt={2}
                        flexDirection={{ xs: "column", lg: "row" }}
                        alignItems="center"
                      >
                        <MDBox
                          width={{ xs: "120px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Ageing Type
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css-for-filter"
                            maxMenuHeight={130}
                            placeholder="Choose One..."
                            value={selectedAgeingType}
                            options={ageingOptions}
                            onChange={handleAgeingChange}
                          />
                        </MDBox>

                        <MDBox
                          width={{ xs: "120px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Item Type
                          </MDTypography>

                          <Select
                            isClearable
                            placeholder="Choose One..."
                            className="select-css-for-filter"
                            maxMenuHeight={130}
                            autoComplete="off"
                            options={itemTypeOptions}
                            value={selectedItemType}
                            onChange={handleItemTypeChange}
                          />
                        </MDBox>

                        <MDBox
                          width={{ xs: "120px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Locations
                          </MDTypography>

                          <Select
                            isClearable
                            placeholder="Choose One..."
                            className="select-css-for-filter"
                            maxMenuHeight={130}
                            autoComplete="off"
                            options={locationOptions}
                            value={selectedLocation}
                            onChange={handleLocationChange}
                          />
                        </MDBox>

                        <MDBox
                          width={{ xs: "120px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Ageing Parameter
                          </MDTypography>

                          <Select
                            isClearable
                            placeholder="Choose One..."
                            className="select-css-for-filter"
                            maxMenuHeight={130}
                            autoComplete="off"
                            value={parameterSelected}
                            options={parameterOptions}
                            onChange={handleParameterChange}
                          />
                        </MDBox>
                        <MDBox style={{ marginTop: "25px", marginLeft: "8px" }}>
                          <MDBox>
                            <CSVLink {...csvLink}>
                              <DownloadDataButton
                                tooltip="Download All Data"
                                type="button"
                                variant="outlined"
                                color="success"
                                iconOnly
                              >
                                <Icon>download</Icon>
                              </DownloadDataButton>
                            </CSVLink>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    hideColumns={["", "asnCode", "vendorCodeName"]}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getItemTypeReducer: state.getItemTypeReducer,
    getAgeingParameterReducer: state.getAgeingParameterReducer,
    getAgeingReportReducer: state.getAgeingReportReducer,
    getLocationsByAgeingReducer: state.getLocationsByAgeingReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemTypesForStatus: (jwtToken) =>
      dispatch(getItemTypesForStatus(jwtToken)),
    getAgeingParameter: (jwtToken) => dispatch(getAgeingParameter(jwtToken)),
    getAgeingReport: (
      selectedItemType,
      selectedLocation,
      parameterSelected,
      selectedAgeingType,
      jwtToken
    ) =>
      dispatch(
        getAgeingReport(
          selectedItemType,
          selectedLocation,
          parameterSelected,
          selectedAgeingType,
          jwtToken
        )
      ),
    getLocations: (jwtToken) => dispatch(getLocations(jwtToken)),
    getLocationsByAgeing: (selectedAgeingType, jwtToken) =>
      dispatch(getLocationsByAgeing(selectedAgeingType, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(AgeingReports);
