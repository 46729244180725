import axios from "axios";
import Config from "../../config/index";
import {
  CREATE_DIRECT_DEMAND_FAILURE,
  CREATE_DIRECT_DEMAND_REQUEST,
  CREATE_DIRECT_DEMAND_SUCCESS,
  GET_PACKAGE_TYPE_REQUEST,
  GET_PACKAGE_TYPE_SUCCESS,
  GET_PACKAGE_TYPE_FAILURE,
  GET_PARTNO_REQUEST,
  GET_PARTNO_SUCCESS,
  GET_PARTNO_FAILURE,
  GET_PLANT_REQUEST,
  GET_PLANT_SUCCESS,
  GET_PLANT_FAILURE,
  POST_CREATEASN_FAILURE,
  POST_CREATEASN_SUCCESS,
  POST_CREATEASN_REQUEST,
  POST_CALCULATE_PACKAGE_DETAILS_REQUEST,
  POST_CALCULATE_PACKAGE_DETAILS_SUCCESS,
  POST_CALCULATE_PACKAGE_DETAILS_FAILURE,
  GET_DELIVERY_PLANT_REQUEST,
  GET_DELIVERY_PLANT_SUCCESS,
  GET_DELIVERY_PLANT_FAILURE,
  GET_UNLOADING_POINT_REQUEST,
  GET_UNLOADING_POINT_SUCCESS,
  GET_UNLOADING_POINT_FAILURE,
  GET_CALCULATE_PACKAGE_WEIGHT_REQUEST,
  GET_CALCULATE_PACKAGE_WEIGHT_SUCCESS,
  GET_CALCULATE_PACKAGE_WEIGHT_FAILURE,
  GET_BINS_FOR_PRINT_REQUEST,
  GET_BINS_FOR_PRINT_SUCCESS,
  GET_BINS_FOR_PRINT_FAILURE,
  GET_DIRECT_DEMAND_FAILURE,
  GET_DIRECT_DEMAND_REQUEST,
  GET_DIRECT_DEMAND_SUCCESS,
  UPLOAD_EXCEL_FOR_DEMAND_FAILURE,
  UPLOAD_EXCEL_FOR_DEMAND_REQUEST,
  UPLOAD_EXCEL_FOR_DEMAND_SUCCESS,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  GET_PARTNO_FOR_PLANNER_REQUEST,
  GET_PARTNO_FOR_PLANNER_SUCCESS,
  GET_PARTNO_FOR_PLANNER_FAILURE,
} from "./directDemandTypes";

let baseUrl = Config.baseUrl;

const getDirectDemandRequest = () => {
  return {
    type: GET_DIRECT_DEMAND_REQUEST,
  };
};

const getDirectDemandSuccess = (payload) => {
  return {
    type: GET_DIRECT_DEMAND_SUCCESS,
    payload: payload,
  };
};

const getDirectDemandFailure = (error) => {
  return {
    type: GET_DIRECT_DEMAND_FAILURE,
    payload: error,
  };
};

const getDirectDemand = (
  userId,
  partNo,
  plant,
  vendorCode,
  roleName,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDirectDemandRequest());
    let url;
    if (roleName === "vendor") {
      url = `${baseUrl}/demand/get_by_vendor?vendorId=${userId}&itemCode=${partNo}&plantCode=${plant}`;
    } else {
      url = `${baseUrl}/demand/get_by_planner?vendorCode=${vendorCode}&itemCode=${partNo}&plantCode=${plant}`;
    }

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDirectDemandSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDirectDemandFailure(err.message));
      });
  };
};

const createDirectDemandRequest = () => {
  return {
    type: CREATE_DIRECT_DEMAND_REQUEST,
  };
};

const createDirectDemandSuccess = (payload) => {
  return {
    type: CREATE_DIRECT_DEMAND_SUCCESS,
    payload: payload,
  };
};

const createDirectDemandFailure = (error) => {
  return {
    type: CREATE_DIRECT_DEMAND_FAILURE,
    payload: error,
  };
};

const createDirectDemand = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createDirectDemandRequest());
    let url = `${baseUrl}/demand/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createDirectDemandSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createDirectDemandFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadForDirectDemandRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_DEMAND_REQUEST,
  };
};

const uploadForDirectDemandSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_DEMAND_SUCCESS,
    payload: payload,
  };
};

const uploadForDirectDemandFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_DEMAND_FAILURE,
    payload: error,
  };
};

const uploadForDirectDemand = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadForDirectDemandRequest());
    let url = `${baseUrl}/direct_demand/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadForDirectDemandSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadForDirectDemandFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};
const getPartNoForPlannerRequest = () => {
  return {
    type: GET_PARTNO_FOR_PLANNER_REQUEST,
  };
};

const getPartNoForPlannerSuccess = (payload) => {
  return {
    type: GET_PARTNO_FOR_PLANNER_SUCCESS,
    payload: payload,
  };
};

const getPartNoForPlannerFailure = (error) => {
  return {
    type: GET_PARTNO_FOR_PLANNER_FAILURE,
    payload: error,
  };
};

const getPartNoForPlanner = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPartNoForPlannerRequest());
    let url = `${baseUrl}/item`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPartNoForPlannerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPartNoForPlannerFailure(err.message));
      });
  };
};

const getVendorsListForPlannerRequest = () => {
  return {
    type: GET_VENDORS_REQUEST,
  };
};

const getVendorsListForPlannerSuccess = (payload) => {
  return {
    type: GET_VENDORS_SUCCESS,
    payload: payload,
  };
};

const getVendorsListForPlannerFailure = (error) => {
  return {
    type: GET_VENDORS_FAILURE,
    payload: error,
  };
};

const getVendorsListForPlanner = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getVendorsListForPlannerRequest());
    let url = `${baseUrl}/vendor/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getVendorsListForPlannerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getVendorsListForPlannerFailure(err.message));
      });
  };
};
const getPartNoRequest = () => {
  return {
    type: GET_PARTNO_REQUEST,
  };
};

const getPartNoSuccess = (payload) => {
  return {
    type: GET_PARTNO_SUCCESS,
    payload: payload,
  };
};

const getPartNoFailure = (error) => {
  return {
    type: GET_PARTNO_FAILURE,
    payload: error,
  };
};

const getPartNo = (userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPartNoRequest());
    let url = `${baseUrl}/vendor_part_mapping/get_items_by_vendor?vendorId=${userId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPartNoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPartNoFailure(err.message));
      });
  };
};

const getPlantRequest = () => {
  return {
    type: GET_PLANT_REQUEST,
  };
};

const getPlantSuccess = (payload) => {
  return {
    type: GET_PLANT_SUCCESS,
    payload: payload,
  };
};

const getPlantFailure = (error) => {
  return {
    type: GET_PLANT_FAILURE,
    payload: error,
  };
};

const getPlant = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPlantRequest());
    let url = `${baseUrl}/plant/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPlantSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPlantFailure(err.message));
      });
  };
};

const getPackageTypeRequest = () => {
  return {
    type: GET_PACKAGE_TYPE_REQUEST,
  };
};

const getPackageTypeSuccess = (payload) => {
  return {
    type: GET_PACKAGE_TYPE_SUCCESS,
    payload: payload,
  };
};

const getPackageTypeFailure = (error) => {
  return {
    type: GET_PACKAGE_TYPE_FAILURE,
    payload: error,
  };
};

const getPackageType = (partCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPackageTypeRequest());
    let url = `${baseUrl}/asn/get_bin_type?itemCode=${partCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPackageTypeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPackageTypeFailure(err.message));
      });
  };
};

const getUnloadingPointRequest = () => {
  return {
    type: GET_UNLOADING_POINT_REQUEST,
  };
};

const getUnloadingPointSuccess = (payload) => {
  return {
    type: GET_UNLOADING_POINT_SUCCESS,
    payload: payload,
  };
};

const getUnloadingPointFailure = (error) => {
  return {
    type: GET_UNLOADING_POINT_FAILURE,
    payload: error,
  };
};

const getUnloadingPoint = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUnloadingPointRequest());
    let url = `${baseUrl}/asn/get_unloading_point?demandId=${id}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnloadingPointSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnloadingPointFailure(err.message));
      });
  };
};

const getDeliveryPlantRequest = () => {
  return {
    type: GET_DELIVERY_PLANT_REQUEST,
  };
};

const getDeliveryPlantSuccess = (payload) => {
  return {
    type: GET_DELIVERY_PLANT_SUCCESS,
    payload: payload,
  };
};

const getDeliveryPlantFailure = (error) => {
  return {
    type: GET_DELIVERY_PLANT_FAILURE,
    payload: error,
  };
};

const getDeliveryPlant = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDeliveryPlantRequest());
    let url = `${baseUrl}/asn/get_delivery_plant?demandId=${id}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDeliveryPlantSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDeliveryPlantFailure(err.message));
      });
  };
};

const calculatePacketDetailsRequest = () => {
  return {
    type: POST_CALCULATE_PACKAGE_DETAILS_REQUEST,
  };
};

const calculatePacketDetailsSuccess = (payload) => {
  return {
    type: POST_CALCULATE_PACKAGE_DETAILS_SUCCESS,
    payload: payload,
  };
};

const calculatePacketDetailsFaliure = (error) => {
  return {
    type: POST_CALCULATE_PACKAGE_DETAILS_FAILURE,
    payload: error,
  };
};
const calculatePacketDetails = (asnQty, partCode, userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  let payload = {
    asnQty: asnQty,
    itemCode: partCode,
    vendorId: userId,
  };

  return (dispatch) => {
    dispatch(calculatePacketDetailsRequest());
    let url = `${baseUrl}/asn/calculate_qty_of_packets`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(calculatePacketDetailsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(calculatePacketDetailsFaliure(err.message));
        return { status: false };
      });
  };
};

const calculatePacketWeightRequest = () => {
  return {
    type: GET_CALCULATE_PACKAGE_WEIGHT_REQUEST,
  };
};

const calculatePacketWeightSuccess = (payload) => {
  return {
    type: GET_CALCULATE_PACKAGE_WEIGHT_SUCCESS,
    payload: payload,
  };
};

const calculatePacketWeightFaliure = (error) => {
  return {
    type: GET_CALCULATE_PACKAGE_WEIGHT_FAILURE,
    payload: error,
  };
};

const calculatePacketWeight = (partCode, asnQty, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(calculatePacketWeightRequest());
    let url = `${baseUrl}/asn/calculate_weight?itemCode=${partCode}&asnQty=${asnQty}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(calculatePacketWeightSuccess(response.data));
      })
      .catch((err) => {
        dispatch(calculatePacketWeightFaliure(err.message));
      });
  };
};

const createAsnRequest = () => {
  return {
    type: POST_CREATEASN_REQUEST,
  };
};

const createAsnSuccess = (payload) => {
  return {
    type: POST_CREATEASN_SUCCESS,
    payload: payload,
  };
};

const createAsnFaliure = (error) => {
  return {
    type: POST_CREATEASN_FAILURE,
    payload: error,
  };
};
const createAsn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  let data = new FormData();

  data.append("testCertificate", payload.testCertificate);
  data.append("invoice", payload.invoice);
  data.append("invoiceNo", payload.invoiceNo);
  data.append("invoiceDate", payload.invoiceDate);
  data.append("planDate", payload.planDate);
  data.append("demandId", payload.demandId);
  data.append("itemCodeDes", payload.itemCodeDes);
  data.append("poNoLineNo", payload.poNoLineNo);
  data.append("asnQty", payload.asnQty);
  data.append("packageType", payload.packageType);
  data.append("packetNo", payload.packetNo);
  data.append("packetWeight", payload.packetWeight);
  data.append("unloadingPoint", payload.unloadingPoint);
  data.append("deliveryPlant", payload.deliveryPlant);
  data.append("vendorId", payload.vendorId);

  return (dispatch) => {
    dispatch(createAsnRequest());
    let url = `${baseUrl}/asn/create`;
    return axios
      .post(url, data, headers)
      .then((response) => {
        dispatch(createAsnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createAsnFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getBinsForPrintRequest = () => {
  return {
    type: GET_BINS_FOR_PRINT_REQUEST,
  };
};

const getBinsForPrintSuccess = (payload) => {
  return {
    type: GET_BINS_FOR_PRINT_SUCCESS,
    payload: payload,
  };
};

const getBinsForPrintFailure = (error) => {
  return {
    type: GET_BINS_FOR_PRINT_FAILURE,
    payload: error,
  };
};

const getBinsForPrint = (asnCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getBinsForPrintRequest());

    let url = `${baseUrl}/bin/get_temp_bin?asnCode=${asnCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getBinsForPrintSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getBinsForPrintFailure(err.message));
      });
  };
};

// const updateActualQtyRequest = () => {
//   return {
//     type: UPDATE_ACTUAL_QTY_REQUEST,
//   };
// };

// const updateActualQtySuccess = (payload) => {
//   return {
//     type: UPDATE_ACTUAL_QTY_SUCCESS,
//     payload: payload,
//   };
// };

// const updateActualQtyFaliure = (error) => {
//   return {
//     type: UPDATE_ACTUAL_QTY_FAILURE,
//     payload: error,
//   };
// };

// const updateActualQty = (payload, jwtToken) => {
//   let headers = {
//     headers: {
//       Authorization: `Bearer ${jwtToken}`,
//     },
//   };
//   return (dispatch) => {
//     dispatch(updateActualQtyRequest());
//     let url = `${baseUrl}/production_plan/update`;
//     return axios
//       .put(url, payload, headers)
//       .then((response) => {
//         dispatch(updateActualQtySuccess(response.data));
//         return { status: true, data: response.data };
//       })
//       .catch((err) => {
//         dispatch(updateActualQtyFaliure(err.response));
//         return { status: false, data: err.response };
//       });
//   };
// };

export {
  getDirectDemand,
  createDirectDemand,
  uploadForDirectDemand,
  getVendorsListForPlanner,
  getPartNoForPlanner,
  getPartNo,
  getPlant,
  createAsn,
  getPackageType,
  calculatePacketDetails,
  getUnloadingPoint,
  getDeliveryPlant,
  calculatePacketWeight,
  getBinsForPrint,
};
