import {
  GET_ITEMSTATUS_REQUEST,
  GET_ITEMSTATUS_SUCCESS,
  GET_ITEMSTATUS_FAILURE,
  GET_ITEM_TYPE_REQUEST,
  GET_ITEM_TYPE_SUCCESS,
  GET_ITEM_TYPE_FAILURE,
} from "./itemStatusTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getItemStatusRequest = () => {
  return {
    type: GET_ITEMSTATUS_REQUEST,
  };
};

const getItemStatusSuccess = (payload) => {
  return {
    type: GET_ITEMSTATUS_SUCCESS,
    payload: payload,
  };
};

const getItemStatusFailure = (error) => {
  return {
    type: GET_ITEMSTATUS_FAILURE,
    payload: error,
  };
};

const getItemStatus = (selectedItemType,selectedItemCode,selectedLocation,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemStatusRequest());
    let url = `${baseUrl}/reports/item_status?type=${selectedItemType}&itemCode=${selectedItemCode}&locationId=${selectedLocation}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemStatusFailure(err.response));
      });
  };
};
const getItemTypesForStatusRequest = () => {
  return {
    type: GET_ITEM_TYPE_REQUEST,
  };
};

const getItemTypesForStatusSuccess = (payload) => {
  return {
    type: GET_ITEM_TYPE_SUCCESS,
    payload: payload,
  };
};

const getItemTypesForStatusFailure = (error) => {
  return {
    type: GET_ITEM_TYPE_FAILURE,
    payload: error,
  };
};

const getItemTypesForStatus = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemTypesForStatusRequest());
    let url = `${baseUrl}/itemtype`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemTypesForStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemTypesForStatusFailure(err.response));
      });
  };
};

export { getItemStatus,getItemTypesForStatus};
