/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Formik and Yup
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Functions from store
import {
  getSubModulesByRoleId,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getActiveCycleCount,
  getCycleCountType,
  createCycleCount,
  getWarehousesByItem,
  getCategoryItemMapping,
} from "../../../store";

//  some date function
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 380, md: 800, lg: 1200, xl: 1200 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const ActiveCycleCount = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getActiveCycleCount,
  activeCycleCountReducer,
  getCycleCountType,
  cycleCountTypeReducer,
  getWarehousesByItem,
  warehousesByItemReducer,
  createCycleCount,
  getCategoryItemMapping,
  categoryItemMappingReducer,

  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevelReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let cycleCountModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "0b410349-54a0-4a3c-b304-882d282133f4"
  );

  let viewAccess = cycleCountModule
    ? cycleCountModule.actionId.includes("2ac02e12-7c8d-4990-ab94-71b6590c7807")
    : null;

  let createAccess = cycleCountModule
    ? cycleCountModule.actionId.includes("51c36ecc-cd3c-49cc-b33b-18673fba1e28")
    : null;

  useEffect(() => {
    getActiveCycleCount();
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Cycle Count Code", accessor: "cycleCountCode" },
      { Header: "Cycle Count Type", accessor: "cycleCountType" },
      {
        Header: "Start Date",
        accessor: "startDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      { Header: "Category", accessor: "category" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Location", accessor: "location" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempActiveCycleCount = [];
    let data = activeCycleCountReducer.activeCycleCount
      ? activeCycleCountReducer.activeCycleCount
      : [];
    setLoading(activeCycleCountReducer.loading);

    data.map((count) => {
      const obj = {
        cycleCountCode: count.cycleCountCode,
        cycleCountType: count.cycleCountType,
        category: count.categoryName,
        itemCode: count.itemCode,
        location: count.locationCode,
        startDate: count.startDate,
        endDate: count.endDate,
      };
      tempActiveCycleCount.push(obj);
    });
    viewAccess && setState({ ...state, rows: tempActiveCycleCount });
  }, [activeCycleCountReducer]);

  useEffect(() => {
    getCycleCountType();
  }, []);

  const [cycleCountTypeOptions, setCycleCountOptions] = useState([]);
  const [selectedCycleCountType, setSelectedCycleCountType] = useState("");
  const handleCyleCountTypeChange = (selectedOption) => {
    setSelectedCycleCountType(selectedOption);
  };

  useEffect(() => {
    let tempCountTypeOptions = [];
    let data = cycleCountTypeReducer.cycleCountType
      ? cycleCountTypeReducer.cycleCountType
      : [];

    data.map((type) => {
      tempCountTypeOptions.push({
        label: type.type,
        value: type.cycleCountTypeId,
      });
    });
    setCycleCountOptions(tempCountTypeOptions);
  }, [cycleCountTypeReducer]);

  useEffect(() => {
    getCategoryItemMapping(jwtToken);
  }, []);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  useEffect(() => {
    let tempCategoryOptions = [];
    let data = categoryItemMappingReducer?.categoryItemMapping?.data
      ? categoryItemMappingReducer?.categoryItemMapping?.data
      : [];

    data.map((category) => {
      tempCategoryOptions.push({
        label: category.categoryName,
        value: category.items,
      });
    });
    setCategoryOptions(tempCategoryOptions);
  }, [categoryItemMappingReducer]);

  const [itemCodeData, setItemCodeData] = useState([]);
  const [itemCodeOptions, setItemCodeOptions] = useState([]);
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemCodeData ? itemCodeData : [];

    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemCodeData]);

  const initialValues = {
    cycleCountCode: "",
    cycleCountTypeId: "",
    category: "",
    itemId: "",
    startDate: "",
    endDate: "",
    locations: [],
    dWarehouse: "",
  };

  const validationSchema = Yup.object({
    cycleCountCode: Yup.string().required("Enter the Cycle Count Code!"),
    cycleCountTypeId: Yup.object().required("Select the Count Type!"),
    category: Yup.object().required("Select the Category!"),
    itemId: Yup.object().required("Select the Item Code!"),
    startDate: Yup.string().required("Select the Start Date!"),
    endDate: Yup.string().required("Select the End Date!"),
    dWarehouse: Yup.array().required("Select the Warehouse!"),
  });

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [isSuccessCreateCycleCount, setIsSuccessCreateCycleCount] =
    useState(false);
  const [isErrorCreateCycleCount, setIsErrorCreateCycleCount] = useState(false);

  useEffect(() => {
    if (isErrorCreateCycleCount) {
      setTimeout(() => {
        setIsErrorCreateCycleCount(false);
      }, 3000);
    }
  }, [isErrorCreateCycleCount]);

  useEffect(() => {
    if (isSuccessCreateCycleCount) {
      setTimeout(() => {
        setIsSuccessCreateCycleCount(false);
      }, 3000);
    }
  }, [isSuccessCreateCycleCount]);

  const [locationSelected, setLocationSelected] = useState([]);

  const onSubmitForCreateCycleCount = async (values, { resetForm }) => {
    let payload = {
      userId,
      cycleCountCode: values.cycleCountCode,
      cycleCountTypeId: selectedCycleCountType.value,
      endDate: values.endDate,
      startDate: values.startDate,
      itemId: selectedItemCode.value,
      locations: locationSelected,
    };

    let res = await createCycleCount(payload, jwtToken);

    if (res.status) {
      resetForm();
      setIsSuccessCreateCycleCount(true);
      getActiveCycleCount(jwtToken);

      setDwarehouseSelected("");
      setDzoneSelected("");
      setDsectionSelected("");
      setDrackSelected("");
      setDlocationSelected("");
      setSelectedItemCode("");
      setSelectedCategory("");
      setSelectedCycleCountType("");

      setTimeout(() => {
        handleCloseCreateCycleCountModal();
      }, 2500);
    }

    if (!res.status) {
      setIsErrorCreateCycleCount(true);
      setErrorMsgForCreate(res.data.data.msg);
    }
  };

  const [createCycleCountModal, setCreateCycleCountModal] = useState(false);

  const handleOpenCreateCycleCountModal = () => {
    setCreateCycleCountModal(true);
  };

  const handleCloseCreateCycleCountModal = () => {
    setCreateCycleCountModal(false);
    setDwarehouseSelected("");
    setDzoneSelected("");
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
    setSelectedItemCode("");
    setSelectedCategory("");
    setSelectedCycleCountType("");
  };

  const [dWarehouseSelected, setDwarehouseSelected] = useState("");
  const [dZoneSelected, setDzoneSelected] = useState("");
  const [dSectionSelected, setDsectionSelected] = useState("");
  const [dRackSelected, setDrackSelected] = useState("");
  const [dLocationSelected, setDlocationSelected] = useState("");

  const handleDwarehouseChange = (selectedData) => {
    setDwarehouseSelected(selectedData);
    setDzoneSelected("");
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDzoneChange = (selectedRack) => {
    setDzoneSelected(selectedRack);
    setDsectionSelected("");
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDsectionChange = (selectedRack) => {
    setDsectionSelected(selectedRack);
    setDrackSelected("");
    setDlocationSelected("");
  };

  const handleDrackChange = (selectedRack) => {
    setDrackSelected(selectedRack);
    setDlocationSelected("");
  };

  const handleDlocationChange = (selectedLocation) => {
    setDlocationSelected(selectedLocation);
  };

  const [warehousesByItemOptions, setWarehousesByItemOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehousesByItemReducer.warehouses
      ? warehousesByItemReducer.warehouses
      : [];

    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
        rltId: warehouse.rltId,
      });
    });
    setWarehousesByItemOptions(tempWarehouseOptions);
  }, [warehousesByItemReducer]);

  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        rltId: level.rltId,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
      ? getLevel2ByLevelReducer.level2ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        rltId: level.rltId,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        rltId: level.rltId,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
        rltId: level.rltId,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              {createAccess && (
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <CreateButton
                    tooltip="Create Cycle Count"
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => handleOpenCreateCycleCountModal()}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
              )}
            </MDBox>
            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>

            <Modal
              open={createCycleCountModal}
              onClose={handleCloseCreateCycleCountModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Cycle Count
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateCycleCountModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmitForCreateCycleCount}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div>
                                  <MDBox>
                                    <Grid container>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Cycle Count Code
                                          </MDTypography>
                                          <MDInput
                                            type="text"
                                            name="cycleCountCode"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            inputProps={{
                                              style: { height: "14px" },
                                              maxLength: 45,
                                            }}
                                            value={formik.values.cycleCountCode}
                                            error={
                                              formik.touched.cycleCountCode &&
                                              formik.errors.cycleCountCode &&
                                              true
                                            }
                                          />
                                          {formik.touched.cycleCountCode &&
                                            formik.errors.cycleCountCode && (
                                              <TextError
                                                msg={
                                                  formik.errors.cycleCountCode
                                                }
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Cycle Count Type
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="cycleCountTypeId"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "cycleCountTypeId",
                                                },
                                              });
                                            }}
                                            value={selectedCycleCountType}
                                            options={cycleCountTypeOptions}
                                            onChange={(selectedOption) => {
                                              handleCyleCountTypeChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "cycleCountTypeId",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.cycleCountTypeId &&
                                            formik.errors.cycleCountTypeId && (
                                              <TextError
                                                msg={
                                                  formik.errors.cycleCountTypeId
                                                }
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Category
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="category"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "category",
                                                },
                                              });
                                            }}
                                            value={selectedCategory}
                                            options={categoryOptions}
                                            onChange={(selectedOption) => {
                                              handleCategoryChange(
                                                selectedOption
                                              );
                                              setItemCodeData(
                                                selectedOption.value
                                              );
                                              formik.setFieldValue(
                                                "category",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.errors.category && (
                                            <TextError
                                              msg={formik.errors.category}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Item Code
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={!selectedCategory}
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="itemId"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "itemId",
                                                },
                                              });
                                            }}
                                            value={selectedItemCode}
                                            options={itemCodeOptions}
                                            onChange={(selectedOption) => {
                                              handleItemCodeChange(
                                                selectedOption
                                              );

                                              formik.setFieldValue(
                                                "itemId",
                                                selectedOption
                                              );

                                              getWarehousesByItem(
                                                selectedOption.value
                                              );
                                            }}
                                          />
                                          {formik.errors.itemId && (
                                            <TextError
                                              msg={formik.errors.itemId}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Start Date
                                          </MDTypography>
                                          <MDInput
                                            type="date"
                                            name="startDate"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.startDate}
                                            inputProps={{
                                              style: { height: "15px" },
                                            }}
                                            error={
                                              formik.touched.startDate &&
                                              formik.errors.startDate &&
                                              true
                                            }
                                          />
                                          {formik.touched.startDate &&
                                            formik.errors.startDate && (
                                              <TextError
                                                msg={formik.errors.startDate}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            End Date
                                          </MDTypography>
                                          <MDInput
                                            type="date"
                                            name="endDate"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.endDate}
                                            inputProps={{
                                              style: { height: "15px" },
                                            }}
                                            error={
                                              formik.touched.endDate &&
                                              formik.errors.endDate &&
                                              true
                                            }
                                          />
                                          {formik.touched.endDate &&
                                            formik.errors.endDate && (
                                              <TextError
                                                msg={formik.errors.endDate}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  {/* <hr style={{ marginBottom: "10px" }} /> */}

                                  <MDBox mt={2}>
                                    <MDTypography variant="h5">
                                      Location
                                    </MDTypography>
                                    <hr style={{ marginBottom: "10px" }} />
                                    <Grid container>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Warehouse Code
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isMulti
                                            isDisabled={!selectedItemCode}
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose one..."
                                            name="dWarehouse"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dWarehouse",
                                                },
                                              });
                                            }}
                                            value={dWarehouseSelected}
                                            options={warehousesByItemOptions}
                                            onChange={(selectedOption) => {
                                              let tempWarehouse = [];
                                              selectedOption.map((option) => {
                                                tempWarehouse.push({
                                                  locationId: option.value,
                                                  rltId: option.rltId,
                                                });
                                              });
                                              setLocationSelected(
                                                tempWarehouse
                                              );
                                              handleDwarehouseChange(
                                                selectedOption
                                              );
                                              getLevel1ByLevel(
                                                selectedOption[0]?.value,
                                                0,
                                                jwtToken
                                              );
                                              formik.setFieldValue(
                                                "dWarehouse",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.dWarehouse &&
                                            formik.errors.dWarehouse && (
                                              <TextError
                                                msg={formik.errors.dWarehouse}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 1
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dWarehouseSelected.length !== 1
                                            }
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose one..."
                                            name="dZone"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dZone",
                                                },
                                              });
                                            }}
                                            value={dZoneSelected}
                                            options={level1Options}
                                            onChange={(selectedOption) => {
                                              handleDzoneChange(selectedOption);
                                              getLevel2ByLevel(
                                                dWarehouseSelected[0]?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );

                                              setLocationSelected([
                                                {
                                                  locationId:
                                                    selectedOption?.value,
                                                  rltId: selectedOption?.rltId,
                                                },
                                              ]);
                                            }}
                                          />
                                          {formik.touched.dZone &&
                                            formik.errors.dZone && (
                                              <TextError
                                                msg={formik.errors.dZone}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 2
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dZoneSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose one..."
                                            name="dSection"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dSection",
                                                },
                                              });
                                            }}
                                            value={dSectionSelected}
                                            options={level2Options}
                                            onChange={(selectedOption) => {
                                              handleDsectionChange(
                                                selectedOption
                                              );
                                              getLevel3ByLevel(
                                                dWarehouseSelected[0]?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );

                                              setLocationSelected([
                                                {
                                                  locationId:
                                                    selectedOption?.value,
                                                  rltId: selectedOption?.rltId,
                                                },
                                              ]);
                                            }}
                                          />
                                          {formik.touched.dSection &&
                                            formik.errors.dSection && (
                                              <TextError
                                                msg={formik.errors.dSection}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          // ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Level 3
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dSectionSelected ? false : true
                                            }
                                            className="select-css"
                                            maxMenuHeight={130}
                                            placeholder="Choose One..."
                                            name="dRack"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dRack",
                                                },
                                              });
                                            }}
                                            value={dRackSelected}
                                            options={level3Options}
                                            onChange={(selectedOption) => {
                                              handleDrackChange(selectedOption);
                                              getLevel4ByLevel(
                                                dWarehouseSelected[0]?.value,
                                                selectedOption?.value,
                                                jwtToken
                                              );

                                              setLocationSelected([
                                                {
                                                  locationId:
                                                    selectedOption?.value,
                                                  rltId: selectedOption?.rltId,
                                                },
                                              ]);
                                            }}
                                          />
                                          {formik.touched.dRack &&
                                            formik.errors.dRack && (
                                              <TextError
                                                msg={formik.errors.dRack}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={4}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            Location
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            isDisabled={
                                              dRackSelected ? false : true
                                            }
                                            className="select-css"
                                            placeholder="Choose one..."
                                            name="dLocation"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "dLocation",
                                                },
                                              });
                                            }}
                                            value={dLocationSelected}
                                            options={level4Options}
                                            onChange={(selectedOption) => {
                                              handleDlocationChange(
                                                selectedOption
                                              );

                                              setLocationSelected([
                                                {
                                                  locationId:
                                                    selectedOption?.value,
                                                  rltId: selectedOption?.rltId,
                                                },
                                              ]);
                                            }}
                                          />
                                          {formik.touched.dLocation &&
                                            formik.errors.dLocation && (
                                              <TextError
                                                msg={formik.errors.dLocation}
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  <div style={{ background: "white" }}>
                                    <MDButton
                                      style={{
                                        position: "sticky",
                                        bottom: "0",
                                      }}
                                      color="info"
                                      fullWidth
                                      type="submit"
                                      disabled={!formik.isValid}
                                    >
                                      Submit
                                    </MDButton>
                                  </div>
                                  <MDBox>
                                    <Collapse in={isErrorCreateCycleCount}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorCreateCycleCount(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        {errorMsgForCreate}
                                      </Alert>
                                    </Collapse>
                                    <Collapse in={isSuccessCreateCycleCount}>
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessCreateCycleCount(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Cycle Count Created Successfully!
                                      </Alert>
                                    </Collapse>
                                  </MDBox>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    activeCycleCountReducer: state.getActiveCycleCountReducer,
    cycleCountTypeReducer: state.getCycleCountTypeReducer,
    warehousesByItemReducer: state.getWarehousesByItemReducer,
    categoryItemMappingReducer: state.getCategoryItemMappingReducer,

    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getActiveCycleCount: (jwtToken) => dispatch(getActiveCycleCount(jwtToken)),
    getCycleCountType: (jwtToken) => dispatch(getCycleCountType(jwtToken)),
    createCycleCount: (payload, jwtToken) =>
      dispatch(createCycleCount(payload, jwtToken)),
    getWarehousesByItem: (itemId, jwtToken) =>
      dispatch(getWarehousesByItem(itemId, jwtToken)),
    getCategoryItemMapping: (jwtToken) =>
      dispatch(getCategoryItemMapping(jwtToken)),

    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCycleCount);
