/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import {
  GET_PRINTERS_REQUEST,
  GET_PRINTERS_SUCCESS,
  GET_PRINTERS_FAILURE,
} from "./printerOnboardingTypes";
import axios from "axios";
import Config from "../../../config/index";
const userManagementUrl = Config.userManagementUrl;
let baseUrl = Config.baseUrl;

const getPrintersRequest = () => {
  return {
    type: GET_PRINTERS_REQUEST,
  };
};

const getPrintersSuccess = (payload) => {
  return {
    type: GET_PRINTERS_SUCCESS,
    payload: payload,
  };
};

const getPrintersFaliure = (error) => {
  return {
    type: GET_PRINTERS_FAILURE,
    payload: error,
  };
};

const getPrinters = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPrintersRequest());
    let url = `${baseUrl}/printer/get`;
    axios
      .get(
        url,

        headers
      )
      .then((response) => {
        dispatch(getPrintersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPrintersFaliure(err.message));
      });
  };
};

const createPrinter = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/printer/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        return { status: true, data: response.data };
      })
      .catch((err) => {
        return { status: false, data: err.message };
      });
  };
};

const updatePrinter = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/printer/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        return { status: true, data: response.data };
      })
      .catch((err) => {
        return { status: false, data: err.message };
      });
  };
};

const deletePrinter = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/printer/delete?printer_id=${id}`;
    return axios
      .delete(url, headers)
      .then((response) => {
        return { status: true, data: response.data };
      })
      .catch((err) => {
        return { status: false, data: err.message };
      });
  };
};

export { getPrinters, createPrinter, updatePrinter, deletePrinter };
