
import { GET_RELATIONLOCATION_FAILURE, GET_RELATIONLOCATION_REQUEST, GET_RELATIONLOCATION_SUCCESS } from "./relationLocationTypes";
  
  const initialState = {
    loading: false,
    locations: [],
    error: "",
  };
  
  const getRelationLocationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_RELATIONLOCATION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_RELATIONLOCATION_SUCCESS:
        return {
          loading: false,
          locations: action.payload,
          error: "",
        };
      case GET_RELATIONLOCATION_FAILURE:
        return {
          loading: false,
          locations: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export {getRelationLocationReducer}
  