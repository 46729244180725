/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Router Components
import { useNavigate } from "react-router-dom";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getWarehouseByType,
  updateWarehouse,
  createWarehouse,
  uploadWarehouse,
  getSubModulesByRoleId,
} from "../../store";

// Custom css
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
import EditButton from "components/standardized-components/EditButton";
import ViewButton from "components/standardized-components/ViewButton";
import StandardSwitch from "components/standardized-components/StandardSwitch";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForStatus = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 500 },
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const SfWarehouse = ({
  getWarehouseByType,
  getWarehouseByTypeReducer,
  updateWarehouse,
  createWarehouse,
  uploadWarehouse,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: "white",
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: "rgb(25, 118, 210)",
            },
          },
          track: {
            opacity: 0.5,
            backgroundColor: "grey",
            ".Mui-checked.Mui-checked + &": {
              opacity: 0.6,
              backgroundColor: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
  });

  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let sfwarehouseModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "2a68a9c9-508f-4956-aa23-8df351219219"
  );

  let updateSFWarehouseAccess = sfwarehouseModule
    ? sfwarehouseModule.actionId.includes(
        "5a1e1a95-4bc0-48c7-8966-0e726f0135d7"
      )
    : null;
  let viewLocationsAccess = sfwarehouseModule
    ? sfwarehouseModule.actionId.includes(
        "259f74c3-a8da-48fb-9bbd-6a9460124942"
      )
    : null;
  let addLocationsAccess = sfwarehouseModule
    ? sfwarehouseModule.actionId.includes(
        "b0afeca7-28ce-4ee1-9772-308fe2b4093e"
      )
    : null;
  let deleteLocationsAccess = sfwarehouseModule
    ? sfwarehouseModule.actionId.includes(
        "9c6e53b0-a5bd-4b04-9e78-4e392511a260"
      )
    : null;
  let uploadLocationsAccess = sfwarehouseModule
    ? sfwarehouseModule.actionId.includes(
        "247ec04f-c851-4381-99aa-78fd06aadfed"
      )
    : null;
  let downloadLocationsAccess = sfwarehouseModule
    ? sfwarehouseModule.actionId.includes(
        "dd8c04dc-6f02-49bb-9811-61f128214469"
      )
    : null;

  useEffect(() => {
    getWarehouseByType(1, jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Warehouse Code", accessor: "warehouseCode", align: "left" },
      { Header: "Warehouse Name", accessor: "warehouse", align: "left" },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const navigate = useNavigate();

  const [openWarehouseStatusModal, setOpenWarehouseStatusModal] =
    useState(false);

  const handleOpenWarehouseStatusModal = () => {
    setOpenWarehouseStatusModal(true);
  };
  const handleCloseWarehouseStatusModal = () => {
    setOpenWarehouseStatusModal(false);
  };

  const [singleWarehouseDate, setSingleWarehouseDate] = useState({});

  const updateStatus = async () => {
    singleWarehouseDate["isActive"] = !singleWarehouseDate.isActive;

    let payload = {
      id: singleWarehouseDate.warehouseId,
      warehouseCode: singleWarehouseDate.warehouseCode,
      warehouseName: singleWarehouseDate.warehouseName,
      isActive: singleWarehouseDate.isActive,
    };
    let res = await updateWarehouse(payload);

    if (res.status) {
      await getWarehouseByType(1, jwtToken);
      handleCloseWarehouseStatusModal();
    }
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempWarehouses = [];
    let data = getWarehouseByTypeReducer.warehouseByType
      ? getWarehouseByTypeReducer.warehouseByType
      : [];
    setLoading(getWarehouseByTypeReducer.loading);
    data.map((warehouse) => {
      warehouse["warehouseCode"] = warehouse.warehouseCode;
      warehouse["warehouse"] = warehouse.warehouseName;
      warehouse["status"] = (
        <ThemeProvider theme={theme}>
          <StandardSwitch
            checked={warehouse.isActive === 1 ? true : false}
            onChange={() => {
              handleOpenWarehouseStatusModal();
              setSingleWarehouseDate(warehouse);
            }}
          />
        </ThemeProvider>
      );
      warehouse["action"] = (
        <div>
          {warehouse.isActive === 1 && viewLocationsAccess ? (
            <ViewButton
              tooltip="View locations"
              type="button"
              variant="gradient"
              color="info"
              iconOnly
              style={{
                marginRight: 5,
              }}
              onClick={() => {
                navigate(`/${warehouse.warehouseId}`);
              }}
            >
              <Icon>visibility_icon</Icon>
            </ViewButton>
          ) : (
            <ViewButton
              tooltip="Disabled"
              type="button"
              variant="gradient"
              style={{
                background: "grey",
                color: "white",
                opacity: 0.5,
                marginRight: 5,
              }}
              disabled
              iconOnly
            >
              <Icon>visibility_icon</Icon>
            </ViewButton>
          )}

          {updateSFWarehouseAccess && (
            <EditButton
              tooltip="Edit"
              // disabled={!editLocation}
              variant="gradient"
              color="success"
              iconOnly
              type="button"
              onClick={() => handleOpenUpdateWarehouseModal(warehouse)}
              style={{ marginRight: "5px" }}
            >
              <Icon>edit_sharp</Icon>
            </EditButton>
          )}
        </div>
      );
      tempWarehouses.push(warehouse);
    });
    setState({ ...state, rows: tempWarehouses });
  }, [getWarehouseByTypeReducer]);

  const warehouseUploadNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [isSuccessCreateWarehouse, setIsSuccessCreateWarehouse] =
    useState(false);
  const [isErrorCreateWarehouse, setIsErrorCreateWarehouse] = useState(false);

  useEffect(() => {
    if (isErrorCreateWarehouse) {
      setTimeout(() => {
        setIsErrorCreateWarehouse(false);
      }, 3000);
    }
  }, [isErrorCreateWarehouse]);

  useEffect(() => {
    if (isSuccessCreateWarehouse) {
      setTimeout(() => {
        setIsSuccessCreateWarehouse(false);
      }, 3000);
    }
  }, [isSuccessCreateWarehouse]);

  const [createWarehouseModal, setCreateWarehouseModal] = useState(false);
  const handleCreateWarehouseModal = () => {
    setCreateWarehouseModal(!createWarehouseModal);
    formik.setValues(initialValueForCreateWarehouse);
    formik.setTouched({});
    formik.setErrors({});
  };

  const initialValueForCreateWarehouse = {
    plantId: 6,
    warehouseCode: "",
    warehouseName: "",
  };

  const validationSchemaForCreateWarehouse = Yup.object({
    warehouseCode: Yup.string().required("Enter the Warehouse Code"),
    warehouseName: Yup.string().required("Enter the Warehouse Name"),
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");

  const onSubmitForCreateWarehouse = async (values, { resetForm }) => {
    let payload = {
      warehouseName: values.warehouseName,
      warehouseCode: values.warehouseCode,
      plantId: 6,
      sfWarehouse: 1,
    };

    let res = await createWarehouse(payload, jwtToken);
    if (res.status) {
      setIsSuccessCreateWarehouse(true);
      getWarehouseByType(1, jwtToken);
      resetForm();
    }
    if (!res.status) {
      setErrorMsg(res.data.data.msg);
      setIsErrorCreateWarehouse(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValueForCreateWarehouse,
    onSubmit: onSubmitForCreateWarehouse,
    validationSchema: validationSchemaForCreateWarehouse,
  });

  const [openUpdateWarehouseModal, setOpenUpdateWarehouseModal] =
    useState(false);
  const [singleWarehouseDataForUpdate, setWarehouseDataForUpdate] = useState(
    {}
  );
  const handleOpenUpdateWarehouseModal = (Warehouse = {}) => {
    setWarehouseDataForUpdate(Warehouse);
    setOpenUpdateWarehouseModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateWarehouseModal = () => {
    setOpenUpdateWarehouseModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    warehouseId: singleWarehouseDataForUpdate.warehouseId,
    warehouseName: singleWarehouseDataForUpdate.warehouseName,
  };

  const validationSchemaForUpdate = Yup.object({
    warehouseName: Yup.string().required("Enter the name!"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      id: singleWarehouseDataForUpdate.warehouseId,
      warehouseName: values.warehouseName,
      warehouseCode: singleWarehouseDataForUpdate.warehouseCode,
      isActive: singleWarehouseDataForUpdate.isActive,
    };

    let res = await updateWarehouse(payload, jwtToken);

    if (res.status) {
      setIsSuccessForUpdate(true);
      getWarehouseByType(1, jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("files", uploadCsvData);
    let res = await uploadWarehouse(data, jwtToken);

    if (res.status) {
      setUploadCsvFilter(false);
      getWarehouseByType(1, jwtToken);
      warehouseUploadNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification();
    }
  };

  const headers = [
    {
      label: "warehouseCode",
      key: "warehouseCode",
    },
    {
      label: "warehouseName",
      key: "warehouseName",
    },
  ];

  const csvLink = {
    filename: "Warehouse List.csv",
    headers: headers,
    data: rows,
  };

  const csvLink2 = {
    filename: "Warehouse Template.csv",
    headers: headers,
    data: [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
                mt={-3}
              >
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <Tooltip title="Create Warehouse">
                    <MDButton
                      disabled={!createNewWarehouse}
                      style={{ marginRight: "1rem" }}
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                      onClick={handleCreateWarehouseModal}
                    >
                      <Icon>add</Icon>
                    </MDButton>
                  </Tooltip>

                  {downloadNewWarehouse ? (
                    <CSVLink {...csvLink2}>
                      <Tooltip title="Download Template">
                        <MDButton
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="error"
                          iconOnly
                        >
                          <Icon>download</Icon>
                        </MDButton>
                      </Tooltip>
                    </CSVLink>
                  ) : (
                    ""
                  )}
                 
                  <Tooltip title="Upload Warehouse">
                    <MDButton
                      disabled={!uploadNewWarehouse}
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="info"
                      iconOnly
                      onClick={uploadCsvFilterHandler}
                    >
                      <Icon>upload</Icon>
                    </MDButton>
                  </Tooltip>
                 

                  {downloadNewWarehouse ? (
                    <CSVLink {...csvLink}>
                      <Tooltip title="Download warehouse list">
                        <MDButton
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="success"
                          iconOnly
                        >
                          <Icon>download</Icon>
                        </MDButton>
                      </Tooltip>
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </MDBox>
              </MDBox> */}

              {/* {uploadCsvFilter && (
                <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                  <div>
                    <input
                      className="choose_file"
                      style={{
                        marginTop: "1.5rem",
                        marginLeft: "1.5rem",
                        // marginBottom: "-9rem",
                      }}
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setUploadCsvData(e.target.files[0]);
                      }}
                    />
                    <MDButton
                      disabled={
                        !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                      }
                      variant="contained"
                      color="info"
                      style={{
                        marginLeft: { xs: "1.5rem", lg: "1" },
                        marginTop: "0rem",
                      }}
                      type="button"
                      onClick={uploadCsvHandler}
                    >
                      UPLOAD
                    </MDButton>
                  </div>
                  <div style={{ marginBottom: "-20px" }}>
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "30px",
                        fontStyle: "italic",
                      }}
                    >
                      <strong>*</strong> Please upload
                      <strong>".csv"</strong> file only
                    </span>
                  </div>
                </div>
              )} */}

              <MDBox pt={-1}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
              <Modal
                open={openUpdateWarehouseModal}
                onClose={handleCloseUpdateWarehouseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateWarehouseModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Warehouse Name</MDTypography>
                        <MDInput
                          type="text"
                          name="warehouseName"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.warehouseName}
                          error={
                            formikForUpdate.touched.warehouseName &&
                            formikForUpdate.errors.warehouseName &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.warehouseName &&
                          formikForUpdate.errors.warehouseName && (
                            <TextError
                              msg={formikForUpdate.errors.warehouseName}
                            />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsgForUpdate}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Warehouse updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formikForUpdate.isValid}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openWarehouseStatusModal}
                onClose={handleCloseWarehouseStatusModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForStatus}>
                  <MDTypography>
                    Are you sure you want to change the status of this
                    warehouse?
                  </MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{
                        width: { xs: "100%", lg: "48%" },
                        marginRight: { xs: "10px", lg: "0.5rem" },
                      }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseWarehouseStatusModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{
                        width: { xs: "100%", lg: "48%" },
                        marginLeft: "1rem",
                      }}
                      color="success"
                      onClick={updateStatus}
                    >
                      Yes
                    </MDButton>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={createWarehouseModal}
                onClose={handleCreateWarehouseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Create Warehouse
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCreateWarehouseModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Warehouse Code
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="warehouseCode"
                          fullWidth
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.warehouseCode}
                          error={
                            formik.touched.warehouseCode &&
                            formik.errors.warehouseCode &&
                            true
                          }
                        />
                        {formik.touched.warehouseCode &&
                          formik.errors.warehouseCode && (
                            <TextError msg={formik.errors.warehouseCode} />
                          )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          Warehouse Name
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="warehouseName"
                          fullWidth
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.warehouseName}
                          error={
                            formik.touched.warehouseName &&
                            formik.errors.warehouseName &&
                            true
                          }
                        />
                        {formik.touched.warehouseName &&
                          formik.errors.warehouseName && (
                            <TextError msg={formik.errors.warehouseName} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorCreateWarehouse}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorCreateWarehouse(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessCreateWarehouse}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessCreateWarehouse(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Warehouse created successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Create Warehouse
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getWarehouseByTypeReducer: state.getWarehouseByTypeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouseByType: (type, jwtToken) =>
      dispatch(getWarehouseByType(type, jwtToken)),
    updateWarehouse: (payload) => dispatch(updateWarehouse(payload)),
    createWarehouse: (payload, jwtToken) =>
      dispatch(createWarehouse(payload, jwtToken)),
    uploadWarehouse: (payload, jwtToken) =>
      dispatch(uploadWarehouse(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(SfWarehouse);
