import {
  CREATE_BIN_FAILURE,
  CREATE_BIN_REQUEST,
  CREATE_BIN_SUCCESS,
  CREATE_BIN_TYPE_FAILURE,
  CREATE_BIN_TYPE_REQUEST,
  CREATE_BIN_TYPE_SUCCESS,
  DELETE_BIN_FAILURE,
  DELETE_BIN_REQUEST,
  DELETE_BIN_SUCCESS,
  EDIT_BIN_FAILURE,
  EDIT_BIN_REQUEST,
  EDIT_BIN_SUCCESS,
  GET_BINS_FAILURE,
  GET_BINS_REQUEST,
  GET_BINS_SUCCESS,
  GET_BIN_TYPES_FAILURE,
  GET_BIN_TYPES_REQUEST,
  GET_BIN_TYPES_SUCCESS,
  UPLOAD_EXCEL_FOR_BINMASTER_FAILURE,
  UPLOAD_EXCEL_FOR_BINMASTER_REQUEST,
  UPLOAD_EXCEL_FOR_BINMASTER_SUCCESS,
  PRINT_BIN_REQUEST,
  PRINT_BIN_FAILURE,
  PRINT_BIN_SUCCESS,
} from "./binMasterTypes";
import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getBinTypesRequest = () => {
  return {
    type: GET_BIN_TYPES_REQUEST,
  };
};

const getBinTypesSuccess = (payload) => {
  return {
    type: GET_BIN_TYPES_SUCCESS,
    payload: payload,
  };
};

const getBinTypesFailure = (error) => {
  return {
    type: GET_BIN_TYPES_FAILURE,
    payload: error,
  };
};

const getBinTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getBinTypesRequest());
    let url = `${baseUrl}/bin_type/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getBinTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getBinTypesFailure(err.message));
      });
  };
};

const createBinTypeRequest = () => {
  return {
    type: CREATE_BIN_TYPE_REQUEST,
  };
};

const createBinTypeSuccess = (payload) => {
  return {
    type: CREATE_BIN_TYPE_SUCCESS,
    payload: payload,
  };
};

const createBinTypeFailure = (error) => {
  return {
    type: CREATE_BIN_TYPE_FAILURE,
    payload: error,
  };
};

const createBinType = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createBinTypeRequest());
    let url = `${baseUrl}/bin_type/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createBinTypeSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createBinTypeFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteBinRequest = () => {
  return {
    type: DELETE_BIN_REQUEST,
  };
};

const deleteBinSuccess = (payload) => {
  return {
    type: DELETE_BIN_SUCCESS,
    payload: payload,
  };
};

const deleteBinFaliure = (error) => {
  return {
    type: DELETE_BIN_FAILURE,
    payload: error,
  };
};

const deleteBin = (binCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteBinRequest());
    let url = `${baseUrl}/bin/delete?binCode=${binCode}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteBinSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteBinFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editBinRequest = () => {
  return {
    type: EDIT_BIN_REQUEST,
  };
};

const editBinSuccess = (payload) => {
  return {
    type: EDIT_BIN_SUCCESS,
    payload: payload,
  };
};

const editBinFaliure = (error) => {
  return {
    type: EDIT_BIN_FAILURE,
    payload: error,
  };
};

const editBin = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editBinRequest());
    let url = `${baseUrl}/bin/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editBinSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editBinFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getBinsRequest = () => {
  return {
    type: GET_BINS_REQUEST,
  };
};

const getBinsSuccess = (payload) => {
  return {
    type: GET_BINS_SUCCESS,
    payload: payload,
  };
};

const getBinsFailure = (error) => {
  return {
    type: GET_BINS_FAILURE,
    payload: error,
  };
};

const getBins = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getBinsRequest());
    let url = `${baseUrl}/bin/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getBinsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getBinsFailure(err.message));
      });
  };
};

const uploadBinMasterRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_BINMASTER_REQUEST,
  };
};

const uploadBinMasterSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_BINMASTER_SUCCESS,
    payload: payload,
  };
};

const uploadBinMasterFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_BINMASTER_FAILURE,
    payload: error,
  };
};

const uploadBinMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadBinMasterRequest());
    let url = `${baseUrl}/bin/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadBinMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadBinMasterFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const createBinMasterRequest = () => {
  return {
    type: CREATE_BIN_REQUEST,
  };
};

const createBinMasterSuccess = (payload) => {
  return {
    type: CREATE_BIN_SUCCESS,
    payload: payload,
  };
};

const createBinMasterFailure = (error) => {
  return {
    type: CREATE_BIN_FAILURE,
    payload: error,
  };
};

const createBinMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createBinMasterRequest());
    let url = `${baseUrl}/bin/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createBinMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createBinMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const printBinReq = () => {
  return {
    type: PRINT_BIN_REQUEST,
  };
};

const printBinSuc = (payload) => {
  return {
    type: PRINT_BIN_SUCCESS,
    payload: payload,
  };
};

const printBinFal = (error) => {
  return {
    type: PRINT_BIN_FAILURE,
    payload: error,
  };
};

const printBin = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(printBinReq());
    let url = `${baseUrl}/bin/print_bin`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(printBinSuc(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printBinFal(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getBinTypes,
  createBinType,
  deleteBin,
  editBin,
  getBins,
  createBinMaster,
  uploadBinMaster,
  printBin,
};
