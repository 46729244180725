export const GET_QUARANTINE_REQUEST = "GET_QUARANTINE_REQUEST";
export const GET_QUARANTINE_SUCCESS = "GET_QUARANTINE_SUCCESS";
export const GET_QUARANTINE_FAILURE = "GET_QUARANTINE_FAILURE";

export const GET_ACN_TYPES_REQUEST = "GET_ACN_TYPES_REQUEST";
export const GET_ACN_TYPES_SUCCESS = "GET_ACN_TYPES_SUCCESS";
export const GET_ACN_TYPES_FAILURE = "GET_ACN_TYPES_FAILURE";

export const GET_ACCEPTED_QUARANTINES_REQUEST =
  "GET_ACCEPTED_QUARANTINES_REQUEST";
export const GET_ACCEPTED_QUARANTINES_SUCCESS =
  "GET_ACCEPTED_QUARANTINES_SUCCESS";
export const GET_ACCEPTED_QUARANTINES_FAILURE =
  "GET_ACCEPTED_QUARANTINES_FAILURE";

export const PUT_ACCEPT_QUARANTINE_REQUEST = "PUT_ACCEPT_QUARANTINE_REQUEST";
export const PUT_ACCEPT_QUARANTINE_SUCCESS = "PUT_ACCEPT_QUARANTINE_SUCCESS";
export const PUT_ACCEPT_QUARANTINE_FAILURE = "PUT_ACCEPT_QUARANTINE_FAILURE";

export const PUT_REJECT_QUARANTINE_REQUEST = "PUT_REJECT_QUARANTINE_REQUEST";
export const PUT_REJECT_QUARANTINE_SUCCESS = "PUT_REJECT_QUARANTINE_SUCCESS";
export const PUT_REJECT_QUARANTINE_FAILURE = "PUT_REJECT_QUARANTINE_FAILURE";

export const PUT_RELEASE_QUARANTINE_REQUEST = "PUT_RELEASE_QUARANTINE_REQUEST";
export const PUT_RELEASE_QUARANTINE_SUCCESS = "PUT_RELEASE_QUARANTINE_SUCCESS";
export const PUT_RELEASE_QUARANTINE_FAILURE = "PUT_RELEASE_QUARANTINE_FAILURE";

export const GET_LOCATIONS_FOR_QUARANTINE_REQUEST = "GET_LOCATIONS_FOR_QUARANTINE_REQUEST";
export const GET_LOCATIONS_FOR_QUARANTINE_SUCCESS = "GET_LOCATIONS_FOR_QUARANTINE_SUCCESS";
export const GET_LOCATIONS_FOR_QUARANTINE_FAILURE = "GET_LOCATIONS_FOR_QUARANTINE_FAILURE";

export const GET_ITEMCODE_BYWAREHOUSE_REQUEST = "GET_ITEMCODE_BYWAREHOUSE_REQUEST";
export const GET_ITEMCODE_BYWAREHOUSE_SUCCESS = "GET_ITEMCODE_BYWAREHOUSE_SUCCESS";
export const GET_ITEMCODE_BYWAREHOUSE_FAILURE = "GET_ITEMCODE_BYWAREHOUSE_FAILURE";

export const GET_SKU_BYSKUSTATUS_REQUEST = "GET_SKU_BYSKUSTATUS_REQUEST";
export const GET_SKU_BYSKUSTATUS_SUCCESS = "GET_SKU_BYSKUSTATUS_SUCCESS";
export const GET_SKU_BYSKUSTATUS_FAILURE = "GET_SKU_BYSKUSTATUS_FAILURE";

export const GET_SKUSTATUS_BYLOCATION_REQUEST = "GET_SKUSTATUS_BYLOCATION_REQUEST";
export const GET_SKUSTATUS_BYLOCATION_SUCCESS = "GET_SKUSTATUS_BYLOCATION_SUCCESS";
export const GET_SKUSTATUS_BYLOCATION_FAILURE = "GET_SKUSTATUS_BYLOCATION_FAILURE";

export const GET_SKU_BYDOCUMENTNO_REQUEST = "GET_SKU_BYDOCUMENTNO_REQUEST";
export const GET_SKU_BYDOCUMENTNO_SUCCESS = "GET_SKU_BYDOCUMENTNO_SUCCESS";
export const GET_SKU_BYDOCUMENTNO_FAILURE = "GET_SKU_BYDOCUMENTNO_FAILURE";

export const GET_SKU_BY_ITEMCODE_REQUEST = "GET_SKU_BY_ITEMCODE_REQUEST";
export const GET_SKU_BY_ITEMCODE_SUCCESS = "GET_SKU_BY_ITEMCODE_SUCCESS";
export const GET_SKU_BY_ITEMCODE_FAILURE = "GET_SKU_BY_ITEMCODE_FAILURE";

export const CREATE_QUARANTINE_REQUEST = "CREATE_QUARANTINE_REQUEST";
export const CREATE_QUARANTINE_SUCCESS = "CREATE_QUARANTINE_SUCCESS";
export const CREATE_QUARANTINE_FAILURE = "CREATE_QUARANTINE_FAILURE";


