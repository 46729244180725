import {
  GET_WORKORDER_REQUEST,
  GET_WORKORDER_SUCCESS,
  GET_WORKORDER_FAILURE,
  GET_ITEMSFORWORKORDER_REQUEST,
  GET_ITEMSFORWORKORDER_SUCCESS,
  GET_ITEMSFORWORKORDER_FAILURE,
} from "./workOrderTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getWorkOrderRequest = () => {
  return {
    type: GET_WORKORDER_REQUEST,
  };
};

const getWorkOrderSuccess = (payload) => {
  return {
    type: GET_WORKORDER_SUCCESS,
    payload: payload,
  };
};

const getWorkOrderFailure = (error) => {
  return {
    type: GET_WORKORDER_FAILURE,
    payload: error,
  };
};

const getWorkOrder = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWorkOrderRequest());
    let url = `${baseUrl}/work_order`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWorkOrderSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWorkOrderFailure(err.message));
      });
  };
};

const getItemsForWorkOrderRequest = () => {
  return {
    type: GET_ITEMSFORWORKORDER_REQUEST,
  };
};

const getItemsForWorkOrderSuccess = (payload) => {
  return {
    type: GET_ITEMSFORWORKORDER_SUCCESS,
    payload: payload,
  };
};

const getItemsForWorkOrderFailure = (error) => {
  return {
    type: GET_ITEMSFORWORKORDER_FAILURE,
    payload: error,
  };
};

const getItemsForWorkOrder = (serialNo, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsForWorkOrderRequest());
    let url = `${baseUrl}/wk_by_serialno?serial_No=${serialNo}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsForWorkOrderSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemsForWorkOrderFailure(err.message));
      });
  };
};

export { getWorkOrder, getItemsForWorkOrder };
