/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

// Layouts
import Department from "./department";
import UserRoles from "./user-roles";
import UserDetails from "./user-details";

import { getSubModulesByRoleId } from "../../store";

import Cookies from "universal-cookie";
import ViewAccess from "./ViewAccess";

const cookies = new Cookies();

const UserManagement = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let departmentModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "5cb29527-adf2-440f-938f-42937a6c9fab"
  );

  let rolesModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d9339c6e-dbde-4d8b-a26f-f5c91c632048"
  );

  let userModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "23fcfb3a-a2da-4630-af00-79cf88872c72"
  );

  let tempUserModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "200789cc-5ce3-44da-b185-bbc25f90dccd"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {departmentModule && rolesModule && userModule ? (
              <>
                <TabView>
                  <TabPanel header="Department">
                    <Department />
                  </TabPanel>

                  <TabPanel header="User Roles">
                    <UserRoles />
                  </TabPanel>
                  <TabPanel header="User Details">
                    <UserDetails />
                  </TabPanel>
                  <TabPanel header="View Access">
                    <ViewAccess />
                  </TabPanel>
                </TabView>
              </>
            ) : !departmentModule && rolesModule && userModule ? (
              <>
                <TabView>
                  <TabPanel header="User Roles">
                    <UserRoles />
                  </TabPanel>
                  <TabPanel header="User Details">
                    <UserDetails />
                  </TabPanel>
                </TabView>
              </>
            ) : !departmentModule && !rolesModule && userModule ? (
              <>
                <TabView>
                  <TabPanel header="User Details">
                    <UserDetails />
                  </TabPanel>
                </TabView>
              </>
            ) : !departmentModule && rolesModule && !userModule ? (
              <>
                <TabView>
                  <TabPanel header="User Roles">
                    <UserRoles />
                  </TabPanel>
                </TabView>
              </>
            ) : departmentModule && !rolesModule && !userModule ? (
              <>
                <TabView>
                  <TabPanel header="Department">
                    <Department />
                  </TabPanel>
                </TabView>
              </>
            ) : departmentModule && !rolesModule && userModule ? (
              <>
                <TabView>
                  <TabPanel header="Department">
                    <Department />
                  </TabPanel>

                  <TabPanel header="User Details">
                    <UserDetails />
                  </TabPanel>
                </TabView>
              </>
            ) : (
              <>
                <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                  No User Management Access
                </h4>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(UserManagement);
