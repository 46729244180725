/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDCard from "components/MDCard";

// @mui material components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mui/material/Icon";

// React-Select component and styling
import Select from "react-select";

//  config file
import Config from "../../../config/index";

// Functions from store
import {
  getWarehouses,
  getMappedActivity,
  getItemMaster,
  getZonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
  getSubModulesByRoleId,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getAcn,
  createActivityMapping,
  uploadCsvForItemMapping,
  getRelationLocation,
  getWarehousesByMovement,
  deleteItems,
  editLocation,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import EditButton from "components/standardized-components/EditButton";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 750 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
  overflow: "scroll",
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ItemLocationMapping = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getWarehouses,
  deleteItems,
  warehouses,
  getMappedActivity,
  getMappedActivityReducer,
  getItemMaster,
  itemMaster,
  getZonesByWarehouse,
  zonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
  getRelationLocationReducer,
  getLevel1ByLevel,
  getWarehouseByMovementReducer,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevel,
  getLevel4ByLevelReducer,
  editLocation,
  getAcn,
  getAcnReducer,
  getRelationLocation,
  createActivityMapping,
  getWarehousesByMovement,
  uploadCsvForItemMapping,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let ItemLocationMapping = modulesData.find(
    (moduleId) => moduleId.moduleId == "7876f9ad-8eb2-4b40-b3dd-5a773c290a90"
  );

  let allocateAccess = ItemLocationMapping
    ? ItemLocationMapping.actionId.includes(
        "b0fd67c1-3cba-4d9c-8956-d02534adfede"
      )
    : null;

  let viewAccess = ItemLocationMapping
    ? ItemLocationMapping.actionId.includes(
        "59f41701-a0f1-45d9-9b8d-3f9f50cc436a"
      )
    : null;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [zoneIdSelected, setZoneIdSelected] = useState("");
  const [sectionIdSelected, setSectionIdSelected] = useState("");
  const [rackIdSelected, setRackIdSelected] = useState("");
  const [locationIdSelected, setLocationIdSelected] = useState("");
  const [activityIdSelected, setActivityIdSelected] = useState("");

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
    setWarehouseIdSelected("");
  };

  const handleChangeWarehouse = (selectedData) => {
    setWarehouseIdSelected(selectedData);
    setZoneIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const handleZoneIDChange = (selectedRack) => {
    setZoneIdSelected(selectedRack);
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };

  const handleSectionIdChange = (selectedRack) => {
    setSectionIdSelected(selectedRack);
    setRackIdSelected("");
    setLocationIdSelected("");
  };
  const handleRackIdChange = (selectedRack) => {
    setRackIdSelected(selectedRack);
    setLocationIdSelected("");
  };

  const handleLocationIdChange = (selectedLocation) => {
    setLocationIdSelected(selectedLocation);
  };

  useEffect(() => {
    getMappedActivity(jwtToken);
  }, []);

  const [selectedZoneName, setSelectedZoneName] = useState("");

  const handleZoneNameChange = (selectedZoneName) => {
    setSelectedZoneName(selectedZoneName);
    setRackIdSelected("");
  };

  const [state, setState] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },

      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Zone", accessor: "zone" },
      { Header: "Section", accessor: "section" },
      { Header: "Rack", accessor: "rack" },
      { Header: "Location", accessor: "location" },
      { Header: "Acn Code", accessor: "acnCode" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    let tempActivityOptions = [];
    let data = getAcnReducer.acn.data ? getAcnReducer.acn.data : [];
    data.map((activity) => {
      tempActivityOptions.push({
        label: activity.acnName,
        value: activity.acnId,
      });
    });
    setActivityOptions(tempActivityOptions);
  }, [getAcnReducer]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [warehouseOptionsByMovement, setWarehouseOptionsByMovement] = useState(
    []
  );

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = getWarehouseByMovementReducer.warehouses.data
      ? getWarehouseByMovementReducer.warehouses.data
      : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseId,
      });
    });
    setWarehouseOptionsByMovement(tempWarehouseOptions);
  }, [getWarehouseByMovementReducer]);
  // useEffect(() => {
  //   let tempLevelOptions = [];
  //   let data = getRelationLocationReducer.locations.data
  //     ? getRelationLocationReducer.locations.data
  //     : [];

  //   data.map((level) => {
  //     tempLevelOptions.push({
  //       label: level.rltName,
  //       value: level.rltLevel,
  //     });
  //   });
  //   setLevelOptions(tempLevelOptions);
  // }, [getRelationLocationReducer]);

  // let level1Name = getRelationLocationReducer.locations.data

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [zoneOptions, setZoneOptions] = useState([]);

  useEffect(() => {
    let tempZonesByWarehouse = [];
    let data = zonesByWarehouse.zones.data ? zonesByWarehouse.zones.data : [];
    data.map((zone) => {
      tempZonesByWarehouse.push({
        label: zone.zoneName,
        value: zone.id,
      });
    });
    setZoneOptions(tempZonesByWarehouse);
  }, [zonesByWarehouse]);

  const [level1Options, setLevel1Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel1ByLevelReducer.level1ByLevel.data
      ? getLevel1ByLevelReducer.level1ByLevel.data
      : [];
      data.map((level) => {
        tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel1Options(tempLevelOptions);
  }, [getLevel1ByLevelReducer]);

  const [level2Options, setLevel2Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel2ByLevelReducer.level2ByLevel.data
    ? getLevel2ByLevelReducer.level2ByLevel.data
    : [];
    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel2Options(tempLevelOptions);
  }, [getLevel2ByLevelReducer]);

  const [level3Options, setLevel3Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel3ByLevelReducer.level3ByLevel.data
      ? getLevel3ByLevelReducer.level3ByLevel.data
      : [];
      
      data.map((level) => {
        tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel3Options(tempLevelOptions);
  }, [getLevel3ByLevelReducer]);

  const [level4Options, setLevel4Options] = useState([]);

  useEffect(() => {
    let tempLevelOptions = [];
    let data = getLevel4ByLevelReducer.level4ByLevel.data
      ? getLevel4ByLevelReducer.level4ByLevel.data
      : [];

    data.map((level) => {
      tempLevelOptions.push({
        label: level.locationName,
        value: level.locationId,
      });
    });

    setLevel4Options(tempLevelOptions);
  }, [getLevel4ByLevelReducer]);

  const [openBinsOptions, setOpenBinsOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempMappedActivity = [];
    let data = getMappedActivityReducer.mappedActivity
      ? getMappedActivityReducer.mappedActivity
      : [];
    setLoading(getMappedActivityReducer.loading);

    data.map((item) => {
      let des = item?.itemName;
      let len = desLength;
      const itemObject = {
        privateKey: item.itemCode,
        itemCode: item.itemCode,
        id: item.id,
        warehouse: item.warehouseCode,
        zone: item.zone,
        section: item.section,
        rack: item.rack,
        location: item.location,
        itemName: des ? des.substring(0, len) : "",
        acnCode: item.acnCode,
        action: (
          <>
            <DeleteButton
              tooltip="Delete"
              variant="gradient"
              color="error"
              iconOnly
              type="button"
              style={{ marginRight: "5px" }}
              onClick={() => handleOpenDeleteModal(item)}
            >
              <Icon>delete</Icon>
            </DeleteButton>

            <EditButton
              tooltip="Edit"
              variant="gradient"
              color="success"
              iconOnly
              type="button"
              onClick={() => {
                handleOpenUpdateModal(item);
              }}
              style={{ marginRight: "5px" }}
            >
              <Icon>edit_sharp</Icon>
            </EditButton>
          </>
        ),
      };
      tempMappedActivity.push(itemObject);
    });

    viewAccess && setState({ ...state, rows: tempMappedActivity });
  }, [getMappedActivityReducer]);

  const [isSuccessAllocateItem, setIsSuccessAllocateItem] = useState(false);
  const [isErrorAllocateItem, setIsErrorAllocateItem] = useState(false);

  useEffect(() => {
    if (isSuccessAllocateItem) {
      setTimeout(() => {
        setIsSuccessAllocateItem(false);
      }, 3000);
    }
  }, [isSuccessAllocateItem]);

  useEffect(() => {
    if (isErrorAllocateItem) {
      setTimeout(() => {
        setIsErrorAllocateItem(false);
      }, 3000);
    }
  }, [isErrorAllocateItem]);

  const [allocateItemModal, setAllocateItemModal] = useState(false);
  const handleAllocateItemModal = () => {
    setAllocateItemModal(!allocateItemModal);
    formik.setErrors({});
    setZoneIdSelected("");
    setWarehouseIdSelected("");
    setSectionIdSelected("");
    setRackIdSelected("");
    setLocationIdSelected("");
  };
  const handleCloseAllocateItemModal = () => {
    setAllocateItemModal(!allocateItemModal);
    formik.setErrors({});
    setWarehouseIdSelected("");
    setLocationIdSelected("");
    setActivityIdSelected("");
    setSelectedItemCode("");
  };

  const [ActivityMappingData, setActivityMappingData] = useState({});

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [selectedBin, setSelectedBin] = useState("");

  const handleBinChange = (selectedBin) => {
    setSelectedBin(selectedBin);
  };
  const [selectedBinForAdd, setSelectedBinForAdd] = useState("");

  const handleBinChangeForAdd = (selectedBinForAdd) => {
    setSelectedBinForAdd(selectedBinForAdd);
  };

  const [selectedOpenBin, setSelectedOpenBin] = useState(
    ActivityMappingData?.locationName
  );

  useEffect(() => {
    if (ActivityMappingData) {
      setSelectedOpenBin({
        label: ActivityMappingData?.locationName,
        value: ActivityMappingData?.locationName,
      });
    }
  }, [ActivityMappingData]);

  const handleOpenBinChange = (selectedOpenBin) => {
    setSelectedOpenBin(selectedOpenBin);
  };

  const initialValues = {
    itemCode: "",
    acn: "",
    warehouseCode: "",
    level1: "",
    level2: "",
    level3: "",
    location: "",
    // rackName: "",
    // bin: "",
  };

  const validationSchema = Yup.object({
    itemCode: Yup.object().required("Select item Code"),
    acn: Yup.object().required("Select movement type"),
    warehouseCode: Yup.object().required("Select a warehouse"),
    level1: Yup.object().required("Select a level"),
    // level2: Yup.object().required("Select a level"),
    // level3: Yup.object().required("Select a level"),
    // location: Yup.object().required("Select a location"),
  });

  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseIdSelected?.value,
      itemId: selectedItemCode.value,
      zoneId: zoneIdSelected.value,
      sectionId: sectionIdSelected.value,
      rackId: rackIdSelected.value,
      locationId: locationIdSelected.value,
      acnId: activityIdSelected.value,
    };

    let res = await createActivityMapping(payload, jwtToken);
    if (res.status) {
      setIsSuccessAllocateItem(true);
      getOpenBinsByWarehouseAndZone(warehouseIdSelected?.value, zoneIdSelected);
      getMappedActivity(warehouseIdSelected?.value);
      formik.setErrors({});
      setActivityIdSelected("");
      setWarehouseIdSelected("");
      setZoneIdSelected("");
      setSectionIdSelected("");
      setRackIdSelected("");
      setLocationIdSelected("");
    }
    if (!res.status) {
      setErrorMsg(res.data.data.msg);
      setIsErrorAllocateItem(true);
    }
    setSelectedItemCode("");
    setSelectedZoneName("");
    setSelectedBin("");
    resetForm();
  };

  const [singleDataForDelete, setSingleDataForDelete] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (data) => {
    setOpenDeleteModal(true);
    setSingleDataForDelete(data);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [isErrorDeleteAssembly, setIsErrorDeleteAssembly] = useState(false);
  const [errorMsgForDelete, setErrorMsgForDelete] = useState(false);
  const [isSuccessForAssembly, setIsSuccessForAssembly] = useState(false);

  useEffect(() => {
    if (isErrorDeleteAssembly) {
      setTimeout(() => {
        setIsErrorDeleteAssembly(false);
      }, 3000);
    }
  }, [isErrorDeleteAssembly]);
  useEffect(() => {
    if (isSuccessForAssembly) {
      setTimeout(() => {
        setIsSuccessForAssembly(false);
      }, 2000);
    }
  }, [isSuccessForAssembly]);

  const deleteTripNo = async () => {
    let itemLocAcnMapId = singleDataForDelete.itemLocAcnMapId;
    // let userId = singleDataForDelete.userId;

    let res = await deleteItems(itemLocAcnMapId);

    if (res.status) {
      await getMappedActivity();
      setTimeout(() => {
        handleCloseDeleteModal();
      }, 3000);
      setIsSuccessForAssembly(true);
    }

    if (!res.status) {
      setIsErrorDeleteAssembly(true);
      setErrorMsgForDelete(res.data.data.msg);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [isSuccessAddActivityMapping, setIsSuccessAddActivityMapping] =
    useState(false);
  const [isErrorAddActivityMapping, setIsErrorAddActivityMapping] =
    useState(false);

  useEffect(() => {
    if (isSuccessAddActivityMapping) {
      setTimeout(() => {
        setIsSuccessAddActivityMapping(false);
      }, 3000);
    }
  }, [isSuccessAddActivityMapping]);

  useEffect(() => {
    if (isErrorAddActivityMapping) {
      setTimeout(() => {
        setIsErrorAddActivityMapping(false);
      }, 3000);
    }
  }, [isErrorAddActivityMapping]);

  const [addActivityMappingModal, setAddActivityMappingModal] = useState(false);
  const handleAddActivityMappingModal = () => {
    setAddActivityMappingModal(!addActivityMappingModal);
    formikForAdd.setErrors({});
  };
  const handleCloseAddActivityMappingModal = () => {
    setAddActivityMappingModal(!addActivityMappingModal);
    formikForAdd.setErrors({});
  };

  const initialValueForAddActivityMapping = {
    bin: "",
  };

  const validationSchemaForAddActivityMapping = Yup.object({
    bin: Yup.string().required("Select the BIN"),
  });

  const onSubmitForAddActivityMapping = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseIdSelected?.value,
      itemId: ActivityMappingData.itemId,
      zoneId: ActivityMappingData.zoneId,
      locationId: selectedBinForAdd.value,
    };

    setSelectedBinForAdd("");
    resetForm();
  };

  const formikForAdd = useFormik({
    initialValues: initialValueForAddActivityMapping,
    validationSchema: validationSchemaForAddActivityMapping,
    onSubmit: onSubmitForAddActivityMapping,
  });

  const [isSuccessEditActivityMapping, setIsSuccessEditActivityMapping] =
    useState(false);
  const [isErrorEditActivityMapping, setIsErrorEditActivityMapping] =
    useState(false);

  useEffect(() => {
    if (isSuccessEditActivityMapping) {
      setTimeout(() => {
        setIsSuccessEditActivityMapping(false);
      }, 3000);
    }
  }, [isSuccessEditActivityMapping]);

  useEffect(() => {
    if (isErrorEditActivityMapping) {
      setTimeout(() => {
        setIsErrorEditActivityMapping(false);
      }, 3000);
    }
  }, [isErrorEditActivityMapping]);

  const [editActivityMappingModal, setEditActivityMappingModal] =
    useState(false);
  const handleEditActivityMappingModal = () => {
    setEditActivityMappingModal(!editActivityMappingModal);
    formikForEdit.setErrors({});
  };

  const handleCloseEditActivityMappingModal = () => {
    setEditActivityMappingModal(!editActivityMappingModal);
    formikForEdit.setErrors({});
  };
  const initialValueForEditActivityMapping = {
    bin: "",
  };

  const validationSchemaForEditActivityMapping = Yup.object({
    bin: Yup.string().required("Select the BIN"),
  });

  const onSubmitForEditActivityMapping = async (values, reset) => {
    const payload = {
      itemId: ActivityMappingData.itemId,
      warehouseId: warehouseIdSelected?.value,
      zoneId: ActivityMappingData.zoneId,
      locationId: selectedOpenBin?.value,
    };

    // let res = await editBinAllocation(payload, jwtToken);

    // if (res.status) {
    //   setIsSuccessEditActivityMapping(true);
    //   getMappedActivity(warehouseIdSelected?.value, jwtToken);
    // }
    // if (!res.status) {
    //   setIsErrorEditActivityMapping(true);
    // }
    // setSelectedOpenBin("");
  };
  const formikForEdit = useFormik({
    initialValues: initialValueForEditActivityMapping,
    validationSchema: validationSchemaForEditActivityMapping,
    onSubmit: onSubmitForEditActivityMapping,
    enableReinitialize: true,
  });

  const [warehouseTypesSelectedForEdit, setWarehouseTypesSelectedForEdit] =
    useState("");
  const [itemCodeSelectedForEdit, setItemCodeSelectedForEdit] = useState("");
  const [acnTypeSelectedForEdit, setAcnTypeSelectedForEdit] = useState("");
  const [zoneSelectedForEdit, setZoneSelectedForEdit] = useState("");
  const [sectionSelectedForEdit, setSectionSelectedForEdit] = useState("");
  const [rackSelectedForEdit, setRackSelectedForEdit] = useState("");
  const [locationSelectedForEdit, setLocationSelectedForEdit] = useState("");

  const [singleFgDataForUpdate, setSingleFgDataForUpdate] = useState({});
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleOpenUpdateModal = (singleData) => {
    getWarehousesByMovement(singleData.acnId, jwtToken);
    // getZonesByWarehouse(singleData.zoneId, jwtToken);
    getLevel1ByLevel(singleData.warehouseId,0, jwtToken);
    getLevel2ByLevel(singleData.warehouseId,singleData.zoneId, jwtToken);
    getLevel3ByLevel(singleData.warehouseId,singleData.sectionId, jwtToken);
    getLevel4ByLevel(singleData.warehouseId,singleData.rackId, jwtToken);
    setWarehouseTypesSelectedForEdit({
      label: singleData.warehouseCode,
      value: singleData.warehouseId,
    });
    setItemCodeSelectedForEdit({
      label: singleData.itemCode,
      value: singleData.itemId,
    });
    setAcnTypeSelectedForEdit({
      label: singleData.acnCode,
      value: singleData.acnId,
    });
    setZoneSelectedForEdit({
      label: singleData.zone,
      value: singleData.zoneId,
    });
    setSectionSelectedForEdit({
      label: singleData.section,
      value: singleData.sectionId,
    });
    setRackSelectedForEdit({
      label: singleData.rack,
      value: singleData.rackId,
    });
    setLocationSelectedForEdit({
      label: singleData.location,
      value: singleData.locationId,
    });
    setSingleFgDataForUpdate(singleData);
    setOpenUpdateModal(true);
    

    // getWarehousesByMovement(
    //   acnTypeSelectedForEdit?.value,

    //   jwtToken
    // );
  }

  const handleChangeWarehouseForEdit = (selectedOption) => {
    setWarehouseTypesSelectedForEdit(selectedOption);
    setZoneSelectedForEdit("");
  };
  const handleChangeAcnForEdit = (selectedOption) => {
    setAcnTypeSelectedForEdit(selectedOption);
  };
  const handleChangeZoneForEdit = (selectedOption) => {
    setZoneSelectedForEdit(selectedOption);
    setSectionSelectedForEdit("");
  };
  const handleChangeSectionForEdit = (selectedOption) => {
    setSectionSelectedForEdit(selectedOption);
    setRackSelectedForEdit("");
  };
  const handleChangeRackForEdit = (selectedOption) => {
    setRackSelectedForEdit(selectedOption);
    setLocationSelectedForEdit("");
  };
  const handleChangeLocationForEdit = (selectedOption) => {
    setLocationSelectedForEdit(selectedOption);
  };

  const initialValueForUpdate = {
    warehouseCode: "",
  };

  const handleCloseModal = () => {
    setOpenUpdateModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };
  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const onSubmitForUpdate = async (values) => {
    let payload = {
      id: singleFgDataForUpdate.itemLocAcnMapId,
      itemId: itemCodeSelectedForEdit?.value,
      acnId:singleFgDataForUpdate.acnId,
      mvtId: acnTypeSelectedForEdit?.value,
      warehouseId: warehouseTypesSelectedForEdit?.value,
      zoneId: zoneSelectedForEdit?.value,
      sectionId: sectionSelectedForEdit?.value,
      rackId: rackSelectedForEdit?.value,
      locationId: locationSelectedForEdit?.value,
    };
    let res = await editLocation(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getMappedActivity(jwtToken);
      setTimeout(() => {
        handleCloseModal();
      }, 3000);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForAssembly,
    enableReinitialize: true,
  });
  const [isErrorDeleteActivityMapping, setIsErrorDeleteActivityMapping] =
    useState(false);
  useEffect(() => {
    if (isErrorDeleteActivityMapping) {
      setTimeout(() => {
        setIsErrorDeleteActivityMapping(false);
      }, 3000);
    }
  }, [isErrorDeleteActivityMapping]);

  const [openDeleteActivityMappingModal, setOpenDeleteActivityMappingModal] =
    useState(false);

  const handleOpenDeleteActivityMappingModal = (data = {}) => {
    setActivityMappingData(data);
    setOpenDeleteActivityMappingModal(true);
  };

  const handleCloseDeleteActivityMappingModal = () => {
    setOpenDeleteActivityMappingModal(false);
  };

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const onClickDeleteActivityMapping = async () => {
    // let res = await removeBinAllocation(
    //   warehouseIdSelected?.value,
    //   ActivityMappingData.itemId,
    //   ActivityMappingData.zoneId,
    //   ActivityMappingData.locationId,
    //   jwtToken
    // );
    // if (res.status) {
    //   getMappedActivity(warehouseIdSelected?.value, jwtToken);
    //   setOpenDeleteActivityMappingModal(false);
    // }
    // if (!res.status) {
    //   setDeleteErrorMsg(res.data.response.data.msg);
    //   setIsErrorDeleteActivityMapping(true);
    // }
  };

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);
  const data = getRelationLocationReducer.locations?.data || [];
  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };
  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("item_mapping", uploadCsvData);

    // let res = await uploadCsvForItemMapping(data);

    // if (res.status) {
    //   getMappedActivity(jwtToken);
    //   setUploadCsvFilter(false);
    //   DataUploadSuccessNotification();
    // }

    // if (!res.status) {
    //   DataUploadFailureNotification();
    // }
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <div>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mb={-6}
            >
              <MDBox
                color="text"
                px={{ xs: 0.5, sm: 0.5, md: 2, lg: 2, xl: 2 }}
                style={{ marginLeft: "-15px" }}
              >
                {allocateAccess ? (
                  <Tooltip>
                    <CreateButton
                      tooltip="Allocate Location"
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                      onClick={(event) => {
                        handleAllocateItemModal();
                        getWarehouses(jwtToken);
                        getItemMaster(jwtToken);
                        getAcn(jwtToken);
                        getRelationLocation(jwtToken);
                      }}
                    >
                      <Icon>add</Icon>
                    </CreateButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Allocate Location">
                    <CreateButton
                      tooltip="Allocate Location"
                      disabled
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                    >
                      <Icon>add</Icon>
                    </CreateButton>
                  </Tooltip>
                )}
                {/* <Tooltip title="Upload CSV">
                    <MDButton
                      style={{ marginLeft: "1rem" }}
                      // disabled={!uploadItem}
                      type="button"
                      variant="outlined"
                      color="info"
                      iconOnly
                      onClick={uploadCsvFilterHandler}
                    >
                      <Icon>upload</Icon>
                    </MDButton>
                  </Tooltip> */}
              </MDBox>
            </MDBox>

            {uploadCsvFilter && (
              <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                <div>
                  <input
                    className="choose_file"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  />
                  <MDButton
                    disabled={
                      !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                    }
                    variant="contained"
                    color="info"
                    style={{
                      marginLeft: { xs: "1.5rem", lg: "1" },
                      marginTop: "0rem",
                    }}
                    type="button"
                    onClick={uploadCsvHandler}
                  >
                    UPLOAD
                  </MDButton>
                </div>
                <div style={{ marginBottom: "-20px" }}>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    <strong>*</strong> Please upload
                    <strong>".csv"</strong> file only
                  </span>
                </div>
              </div>
            )}
            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={deleteStyle}>
                <MDTypography>Are you sure you want to delete?</MDTypography>
                <MDBox mt={2}>
                  <MDButton
                    color="info"
                    variant="outlined"
                    onClick={handleCloseDeleteModal}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    style={{ marginLeft: "0.5rem" }}
                    color="error"
                    onClick={deleteTripNo}
                  >
                    Delete
                  </MDButton>
                  <MDBox mt={2}>
                    <Collapse in={isErrorDeleteAssembly}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorDeleteAssembly(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForDelete}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessForAssembly}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessForAssembly(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Deleted Successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openUpdateModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForEdit}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Update
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForUpdate.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Item Code
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        isDisabled={true}
                        name="itemCode"
                        options={itemCodeOptions}
                        value={itemCodeSelectedForEdit} // Ensure this matches the shape of options
                        // onChange={(selectedOption) => {
                        //   handleChangeWarehouseForsEdits(selectedOption);
                        //   formikForUpdate.setFieldValue(
                        //     "assemblyType",
                        //     selectedOption
                        //   );
                        // }}
                      />
                      {formikForUpdate.touched.itemCode &&
                        formikForUpdate.errors.itemCode && (
                          <TextError msg={formikForUpdate.errors.itemCode} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        ACN Code
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        isDisabled={true}
                        name="acn"
                        options={activityOptions}
                        value={acnTypeSelectedForEdit} // Ensure this matches the shape of options
                        onChange={(selectedOption) => {
                          handleChangeAcnForEdit(selectedOption);
                          formikForUpdate.setFieldValue("acn", selectedOption);
                        }}
                      />
                      {formikForUpdate.touched.itemCode &&
                        formikForUpdate.errors.itemCode && (
                          <TextError msg={formikForUpdate.errors.itemCode} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        WarehouseCode
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        name="warehouseCode"
                        options={warehouseOptionsByMovement}
                        value={warehouseTypesSelectedForEdit} // Ensure this matches the shape of options
                        onChange={(selectedOption) => {
                          handleChangeWarehouseForEdit(selectedOption);
                          getZonesByWarehouse(selectedOption?.value);

                          formikForUpdate.setFieldValue(
                            "warehouseCode",
                            selectedOption
                          );
                          getLevel1ByLevel(selectedOption?.value, 0, jwtToken);
                        }}
                      />
                      {formikForUpdate.touched.warehouseCode &&
                        formikForUpdate.errors.warehouseCode && (
                          <TextError
                            msg={formikForUpdate.errors.warehouseCode}
                          />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Zone
                      </MDTypography>
                      <Select
                        isClearable
                        isDisabled={
                          warehouseTypesSelectedForEdit ? false : true
                        }
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        name="zone"
                        options={level1Options}
                        value={zoneSelectedForEdit} // Ensure this matches the shape of options
                        onChange={(selectedOption) => {
                          handleChangeZoneForEdit(selectedOption);
                          formikForUpdate.setFieldValue("zone", selectedOption);
                          getLevel2ByLevel(
                            warehouseTypesSelectedForEdit?.value,
                            selectedOption?.value,
                            jwtToken
                          );
                        }}
                      />
                      {formikForUpdate.touched.zone &&
                        formikForUpdate.errors.zone && (
                          <TextError msg={formikForUpdate.errors.zone} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Section
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        name="section"
                        options={level2Options}
                        value={sectionSelectedForEdit} // Ensure this matches the shape of options
                        onChange={(selectedOption) => {
                          handleChangeSectionForEdit(selectedOption);
                          formikForUpdate.setFieldValue(
                            "section",
                            selectedOption
                          );
                          getLevel3ByLevel(
                            warehouseTypesSelectedForEdit?.value,
                            selectedOption?.value,
                            jwtToken
                          );
                        }}
                      />
                      {formikForUpdate.touched.section &&
                        formikForUpdate.errors.section && (
                          <TextError msg={formikForUpdate.errors.section} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Rack
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        name="rack"
                        options={level3Options}
                        value={rackSelectedForEdit} // Ensure this matches the shape of options
                        onChange={(selectedOption) => {
                          handleChangeRackForEdit(selectedOption);
                          formikForUpdate.setFieldValue("rack", selectedOption);
                          getLevel4ByLevel(
                            warehouseTypesSelectedForEdit?.value,
                            selectedOption?.value,
                            jwtToken
                          );
                        }}
                      />
                      {formikForUpdate.touched.rack &&
                        formikForUpdate.errors.rack && (
                          <TextError msg={formikForUpdate.errors.rack} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                        Location
                      </MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Select..."
                        name="location"
                        options={level4Options}
                        value={locationSelectedForEdit} // Ensure this matches the shape of options
                        onChange={(selectedOption) => {
                          handleChangeLocationForEdit(selectedOption);
                          formikForUpdate.setFieldValue(
                            "location",
                            selectedOption
                          );
                        }}
                      />
                      {formikForUpdate.touched.location &&
                        formikForUpdate.errors.location && (
                          <TextError msg={formikForUpdate.errors.location} />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForUpdate}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForUpdate}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForUpdate}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Updated successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        variant="contained"
                        color="success"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={allocateItemModal}
              onClose={handleCloseAllocateItemModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Allocate
                  </MDTypography>
                  <MDBox>
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAllocateItemModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <MDBox ml={-0.8}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Item Code/Des
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css-for-itemcode"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="itemCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "itemCode" },
                              });
                            }}
                            value={selectedItemCode}
                            options={itemCodeOptions}
                            onChange={(selectedOption) => {
                              handleItemCodeChange(selectedOption);
                              formik.setFieldValue("itemCode", selectedOption);
                            }}
                          />

                          {formik.touched.itemCode &&
                            formik.errors.itemCode && (
                              <TextError msg={formik.errors.itemCode} />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1} ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Movement{" "}
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="acn"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "acn" },
                              });
                            }}
                            value={activityIdSelected}
                            options={activityOptions}
                            onChange={(selectedOption) => {
                              handleActivityIdChange(selectedOption);
                              formik.setFieldValue("acn", selectedOption);
                              getWarehousesByMovement(
                                selectedOption?.value,

                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.acn && formik.errors.acn && (
                            <TextError msg={formik.errors.acn} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Warehouse Code{" "}
                          </MDTypography>

                          <Select
                            isDisabled={activityIdSelected ? false : true}
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="warehouseCode"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "warehouseCode" },
                              });
                            }}
                            value={warehouseIdSelected}
                            options={warehouseOptionsByMovement}
                            onChange={(selectedOption) => {
                              handleChangeWarehouse(selectedOption);
                              getZonesByWarehouse(selectedOption?.value);
                              formik.setFieldValue(
                                "warehouseCode",
                                selectedOption
                              );
                              getLevel1ByLevel(
                                selectedOption?.value,
                                0,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.warehouseCode &&
                            formik.errors.warehouseCode && (
                              <TextError msg={formik.errors.warehouseCode} />
                            )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            Level 1:-
                            {data.length > 0
                              ? data[0].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={warehouseIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="level1"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "level1" },
                              });
                            }}
                            value={zoneIdSelected}
                            options={level1Options}
                            onChange={(selectedOption) => {
                              handleZoneIDChange(selectedOption);
                              formik.setFieldValue("level1", selectedOption);
                              getLevel2ByLevel(
                                warehouseIdSelected?.value,
                                selectedOption?.value,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.level1 && formik.errors.level1 && (
                            <TextError msg={formik.errors.level1} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography variant="h6">
                            {" "}
                            Level 2:-
                            {data.length > 0
                              ? data[1].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={zoneIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="level2"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "level2" },
                              });
                            }}
                            value={sectionIdSelected}
                            options={level2Options}
                            onChange={(selectedOption) => {
                              handleSectionIdChange(selectedOption);
                              formik.setFieldValue("level2", selectedOption);
                              getLevel3ByLevel(
                                warehouseIdSelected?.value,
                                selectedOption?.value,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.level2 && formik.errors.level2 && (
                            <TextError msg={formik.errors.level2} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox ml={{ xs: 0, lg: 1 }}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            Level 3:-
                            {data.length > 0
                              ? data[2].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={sectionIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose One..."
                            name="level3"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "level3" },
                              });
                            }}
                            value={rackIdSelected}
                            options={level3Options}
                            onChange={(selectedOption) => {
                              handleRackIdChange(selectedOption);
                              formik.setFieldValue("level3", selectedOption);
                              getLevel4ByLevel(
                                warehouseIdSelected?.value,
                                selectedOption?.value,
                                jwtToken
                              );
                            }}
                          />
                          {formik.touched.level3 && formik.errors.level3 && (
                            <TextError msg={formik.errors.level3} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MDBox mb={1}>
                          <MDTypography variant="h6">
                            {data.length > 0
                              ? data[3].rltName
                              : "No data available"}
                          </MDTypography>

                          <Select
                            isClearable
                            isDisabled={rackIdSelected ? false : true}
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Choose one..."
                            name="location"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "location" },
                              });
                            }}
                            value={locationIdSelected}
                            options={level4Options}
                            onChange={(selectedOption) => {
                              handleLocationIdChange(selectedOption);
                              formik.setFieldValue("location", selectedOption);
                            }}
                          />
                          {formik.touched.location &&
                            formik.errors.location && (
                              <TextError msg={formik.errors.location} />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12}>
                        <Collapse in={isErrorAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessAllocateItem}>
                          <Alert
                            style={{ marginBottom: "10px" }}
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAllocateItem(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Location Allocated successfully
                          </Alert>
                        </Collapse>
                      </Grid>

                      <Grid item xs={12}>
                        <MDBox mt={1}>
                          <MDButton
                            color="info"
                            fullWidth
                            type="submit"
                            disabled={
                              !formik.isValid ||
                              selectedItemCode == "" ||
                              activityIdSelected == "" ||
                              warehouseIdSelected == "" ||
                              zoneIdSelected == ""
                            }
                          >
                            Allocate
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
      {/* <Footer /> */}
    </div>
    // </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    warehouses: state.warehouses,
    getMappedActivityReducer: state.getMappedActivityReducer,
    itemMaster: state.itemMaster,
    zonesByWarehouse: state.zonesByWarehouse,
    getLevel1ByLevelReducer: state.getLevel1ByLevelReducer,
    getLevel2ByLevelReducer: state.getLevel2ByLevelReducer,
    getLevel3ByLevelReducer: state.getLevel3ByLevelReducer,
    getLevel4ByLevelReducer: state.getLevel4ByLevelReducer,
    getAcnReducer: state.getAcnReducer,
    openBinsByWarehouse: state.openBinsByWarehouse,
    getRelationLocationReducer: state.getRelationLocationReducer,
    getWarehouseByMovementReducer: state.getWarehouseByMovementReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getMappedActivity: (jwtToken) => dispatch(getMappedActivity(jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getZonesByWarehouse: (id, jwtToken) =>
      dispatch(getZonesByWarehouse(id, jwtToken)),
    getLevel1ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel1ByLevel(warehouseId, parentId, jwtToken)),
    getLevel2ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel2ByLevel(warehouseId, parentId, jwtToken)),
    getLevel3ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel3ByLevel(warehouseId, parentId, jwtToken)),
    getLevel4ByLevel: (warehouseId, parentId, jwtToken) =>
      dispatch(getLevel4ByLevel(warehouseId, parentId, jwtToken)),
    getAcn: (jwtToken) => dispatch(getAcn(jwtToken)),
    getOpenBinsByWarehouseAndZone: (id, zoneId, jwtToken) =>
      dispatch(getOpenBinsByWarehouseAndZone(id, zoneId, jwtToken)),
    createActivityMapping: (payload, jwtToken) =>
      dispatch(createActivityMapping(payload, jwtToken)),
    deleteItems: (itemLocAcnMapId, jwtToken) =>
      dispatch(deleteItems(itemLocAcnMapId, jwtToken)),
    uploadCsvForItemMapping: (payload, jwtToken) =>
      dispatch(uploadCsvForItemMapping(payload, jwtToken)),
    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
    getWarehousesByMovement: (activityIdSelected, jwtToken) =>
      dispatch(getWarehousesByMovement(activityIdSelected, jwtToken)),
    editLocation: (payload, jwtToken) =>
      dispatch(editLocation(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemLocationMapping);
