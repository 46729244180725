/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

// Functions from store
import { getSubModulesByRoleId, getCompletedCycleCount } from "../../../store";

//  some date function
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../../config/index";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const CompletedCycleCount = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getCompletedCycleCount,
  completedCycleCountReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let cycleCountModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ae5d7837-c16d-4005-ac6d-00cf1f48cb31"
  );

  let viewAccess = cycleCountModule
    ? cycleCountModule.actionId.includes("b43e6fd2-816f-41a8-ad32-757530a52053")
    : null;

  useEffect(() => {
    getCompletedCycleCount();
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Cycle Count Code", accessor: "cycleCountCode" },
      { Header: "Cycle Count Type", accessor: "cycleCountType" },
      {
        Header: "Start Date",
        accessor: "startDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy"
              )
            : "";
        },
      },
      { Header: "Category", accessor: "category" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Location", accessor: "location" },
      { Header: "WMS Qty", accessor: "availableQty" },
      { Header: "Reported Qty", accessor: "actualQty" },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempCompletedCycleCount = [];
    let data = completedCycleCountReducer.completedCycleCount
      ? completedCycleCountReducer.completedCycleCount
      : [];
    setLoading(completedCycleCountReducer.loading);

    data.map((count) => {
      const obj = {
        cycleCountCode: count.cycleCountCode,
        cycleCountType: count.cycleCountType,
        category: count.categoryName,
        itemCode: count.itemCode,
        location: count.locationCode,
        startDate: count.startDate,
        endDate: count.endDate,
        availableQty: count.availableQty,
        actualQty: count.actualQty,
      };
      tempCompletedCycleCount.push(obj);
    });
    viewAccess && setState({ ...state, rows: tempCompletedCycleCount });
  }, [completedCycleCountReducer]);

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    completedCycleCountReducer: state.getCompletedCycleCountReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getCompletedCycleCount: (jwtToken) =>
      dispatch(getCompletedCycleCount(jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedCycleCount);
