export const GET_PALLET_TYPES_REQUEST = "GET_PALLET_TYPES_REQUEST";
export const GET_PALLET_TYPES_SUCCESS = "GET_PALLET_TYPES_SUCCESS";
export const GET_PALLET_TYPES_FAILURE = "GET_PALLET_TYPES_FAILURE";

export const CREATE_PALLET_TYPE_REQUEST = "CREATE_PALLET_TYPE_REQUEST";
export const CREATE_PALLET_TYPE_SUCCESS = "CREATE_PALLET_TYPE_SUCCESS";
export const CREATE_PALLET_TYPE_FAILURE = "CREATE_PALLET_TYPE_FAILURE";

export const DELETE_PALLET_REQUEST = "DELETE_PALLET_REQUEST";
export const DELETE_PALLET_SUCCESS = "DELETE_PALLET_SUCCESS";
export const DELETE_PALLET_FAILURE = "DELETE_PALLET_FAILURE";

export const EDIT_PALLET_REQUEST = "EDIT_PALLET_REQUEST";
export const EDIT_PALLET_SUCCESS = "EDIT_PALLET_SUCCESS";
export const EDIT_PALLET_FAILURE = "EDIT_PALLET_FAILURE";

export const GET_PALLETS_REQUEST = "GET_PALLETS_REQUEST";
export const GET_PALLETS_SUCCESS = "GET_PALLETS_SUCCESS";
export const GET_PALLETS_FAILURE = "GET_PALLETS_FAILURE";

export const CREATE_PALLET_REQUEST = "CREATE_PALLET_REQUEST";
export const CREATE_PALLET_SUCCESS = "CREATE_PALLET_SUCCESS";
export const CREATE_PALLET_FAILURE = "CREATE_PALLET_FAILURE";

export const UPLOAD_CSV_FOR_PALLET_REQUEST = "UPLOAD_CSV_FOR_PALLET_REQUEST";
export const UPLOAD_CSV_FOR_PALLET_SUCCESS = "UPLOAD_CSV_FOR_PALLET_SUCCESS";
export const UPLOAD_CSV_FOR_PALLET_FAILURE = "UPLOAD_CSV_FOR_PALLET_FAILURE";

export const PRINT_PALLET_REQUEST = "PRINT_PALLET_REQUEST";
export const PRINT_PALLET_SUCCESS = "PRINT_PALLET_SUCCESS";
export const PRINT_PALLET_FAILURE = "PRINT_PALLET_FAILURE";
