export const GET_PICKLIST_AGEING_REQUEST = "GET_PICKLIST_AGEING_REQUEST";
export const GET_PICKLIST_AGEING_SUCCESS = "GET_PICKLIST_AGEING_SUCCESS";
export const GET_PICKLIST_AGEING_FAILURE = "GET_PICKLIST_AGEING_FAILURE";

export const GET_AGEING_PARAMETER_REQUEST = "GET_AGEING_PARAMETER_REQUEST";
export const GET_AGEING_PARAMETER_SUCCESS = "GET_AGEING_PARAMETER_SUCCESS";
export const GET_AGEING_PARAMETER_FAILURE = "GET_AGEING_PARAMETER_FAILURE";


export const GET_MVT_BY_DOC_REQUEST = "GET_MVT_BY_DOC_REQUEST";
export const GET_MVT_BY_DOC_SUCCESS = "GET_MVT_BY_DOC_SUCCESS";
export const GET_MVT_BY_DOC_FAILURE = "GET_MVT_BY_DOC_FAILURE";
