import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useFormik, Formik, Form, Field } from "formik";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// @mui material components
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import { TextField } from "@mui/material";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Functions from store
import {
  getVendorByVendorId,
  getVendorsNotApproved,
  acceptVendorHandler,
  rejectVendorHandler,
} from "../../store/vendor-details/vendorDetailsActions";

import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const acceptStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const DashboardForm = ({
  getVendorByVendorId,
  getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer,
  getVendorsNotApproved,
  acceptVendorHandlerReducer,
  acceptVendorHandler,
  rejectVendorHandler,
  getRejectHandler,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let baseUrl = Config.baseUrl;

  const location = useLocation();
  const navigate = useNavigate();
  const { vendorUserId } = useParams();

  const [values, setValues] = useState({});
  useEffect(() => {
    getVendorByVendorId(vendorUserId, jwtToken);
  }, [vendorUserId]);

  let loading = getVendorByVendorIdReducer.loading;

  const documentEmail = values.email;
  const getDocument = (filename) => {
    return (
      <a
        rel="noopener noreferrer"
        href={`${baseUrl}/vendor/get_document?email=${documentEmail}&file_name=${filename}`}
        target="_blank"
        className="btn"
        style={{ color: "white" }}
      >
        View
      </a>
    );
  };

  const [openAcceptModal, setOpenAcceptModal] = useState(false);

  const handleOpenAcceptModal = (asset = {}) => {
    setOpenAcceptModal(true);
  };
  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };

  const [isErrorAccept, setIsErrorAccept] = useState(false);
  const [isSuccessAccept, setIsSuccessAccept] = useState(false);

  useEffect(() => {
    if (isErrorAccept) {
      setTimeout(() => {
        setIsErrorAccept(false);
      }, 3000);
    }
  }, [isErrorAccept]);

  useEffect(() => {
    if (isSuccessAccept) {
      setTimeout(() => {
        setIsSuccessAccept(false);
      }, 3000);
    }
  }, [isSuccessAccept]);

  const [openRejectModal, setOpenRejectModal] = useState(false);

  const handleOpenRejectModal = (asset = {}) => {
    setOpenRejectModal(true);
  };
  const handleCloseRejectModal = () => {
    setOpenRejectModal(false);
  };

  const [isErrorReject, setIsErrorReject] = useState(false);
  const [isSuccessReject, setIsSuccessReject] = useState(false);

  useEffect(() => {
    if (isErrorReject) {
      setTimeout(() => {
        setIsErrorReject(false);
      }, 3000);
    }
  }, [isErrorReject]);

  useEffect(() => {
    if (isSuccessReject) {
      setTimeout(() => {
        setIsSuccessReject(false);
      }, 3000);
    }
  }, [isSuccessReject]);

  const [comment, setComment] = useState("");
  const [statusVendorName, setStatusVendorName] = useState(false);
  const [statusGST, setStatusGST] = useState(false);
  const [statusPAN, setStatusPAN] = useState(false);
  const [statusGSTIN, setStatusGSTIN] = useState(false);
  const [statusVendorNameGSTIN, setStatusVendorNameGSTIN] = useState(false);
  const [statusPincodeGSTIN, setStatusPincodeGSTIN] = useState(false);

  const clickHandler = () => {
    if (location.pathname === `/rejected-vendors/${vendorUserId}`) {
      navigate("/rejected-vendors");
    }
    if (location.pathname === `/registered-vendors/${vendorUserId}`) {
      navigate("/registered-vendors");
    }
    if (location.pathname === `/status/${vendorUserId}`) {
      navigate("/status");
    }
  };

  const [errorMsgForAccept, setErrorMsgForAccept] = useState("");

  const acceptHandler = async () => {
    let payload = {
      email: values.email,
      companyCode: companyCode.value,
      supplierGroup: supplierGroup.value,
      remarks,
    };
    let response = await acceptVendorHandler(payload, jwtToken);

    if (response.status) {
      setIsSuccessAccept(true);
      getVendorsNotApproved();
      navigate("/status");
    }
    if (!response.status) {
      setErrorMsgForAccept(response.data.data.msg);
      setIsErrorAccept(true);
    }
  };

  const [errorMsgForReject, setErrorMsgForReject] = useState("");

  const rejectHandler = async () => {
    let payload = {
      rejectReason: comment,
      email: values.email,
    };
    let response = await rejectVendorHandler(payload, jwtToken);

    if (response.status) {
      setIsSuccessReject(true);
      getVendorsNotApproved();
      navigate("/status");
    }
    if (!response.status) {
      setIsErrorReject(true);
      setErrorMsgForReject(response.data.data.msg);
    }
  };

  useEffect(() => {
    getVendorsNotApproved(jwtToken);
  }, []);

  useEffect(() => {
    let data = getVendorByVendorIdReducer.getVendor
      ? getVendorByVendorIdReducer.getVendor
      : {};

    setValues(data);
  }, [getVendorByVendorIdReducer]);

  const valuesCompanyCode = [
    { label: "300", value: "300" },
    { label: "400", value: "400" },
    { label: "500", value: "500" },
  ];

  const valuesSupplierGroup = [
    { label: "Raw Material", value: "Raw Material" },
    { label: "Capital(Supply)", value: "Capital(Supply)" },
    { label: "Capital(Service)", value: "Capital(Service)" },
    { label: "Non-Capital(Supply)", value: "Non-Capital(Supply)" },
    { label: "Non-Capital(Service)", value: "Non-Capital(Service)" },
  ];

  const [companyCode, setCompanyCode] = useState("");
  const [supplierGroup, setSupplierGroup] = useState("");
  const [remarks, setRemarks] = useState("");

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        {!loading ? (
          <MDCard>
            <MDBox mt={4}>
              <MDTypography
                variant="h3"
                fontWeight="medium"
                style={{ display: "grid", justifyContent: "center" }}
              >
                Vendor Form
              </MDTypography>
            </MDBox>

            <MDBox pt={1} pb={3} px={3}>
              <Formik>
                {(formik) => (
                  <Form>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        // aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Business Partner Details
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          style={{ color: "black" }}
                        >
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // variant="inherit"
                                  // variant="subtitle2"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Line of Business
                                </MDTypography>
                                <input
                                  fullWidth
                                  name="name"
                                  // color="inherit"
                                  // variant="inherit"
                                  value={values.lineOfBusiness}
                                  style={{
                                    color: "black",
                                    font: "inherit",
                                    fontSize: "14px",
                                    letterSpacing: "inherit",

                                    padding: "4px 0 5px",
                                    border: "0.2px solid #DCDCDC",
                                    borderRadius: 5,
                                    boxSizing: "content-box",
                                    background: "none",
                                    height: "1.5em",
                                    margin: 0,

                                    display: "block",
                                    minWidth: 0,
                                    width: "80%",

                                    padding: "16.5px 14px",

                                    padding: "0.75rem",
                                    backgroundColor: "transparent",
                                    // style={{ width: "420px" }}
                                  }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Industrial Type
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.industrialType}
                                  // style={{ width: "420px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Currency
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.currency}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  E-mail
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.email}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Supplier Name
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.supplierName}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Contact Person
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.contactPerson}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Contact No
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.contactDetails
                                      ? `${values.contactDetails.countryCode}-${values.contactDetails.contactNo}`
                                      : ""
                                  }
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Address
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Building No/Name
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address
                                      ? values.address.buildingNoName
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Floor
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.floor : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Street
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.street : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Country
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.country : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  State
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.state : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  City
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.city : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Pincode
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.pinCode : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          mt={5}
                          color="inherit"
                        >
                          Statutory Information
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  PAN
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.pan}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  GST No
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.gstNo}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Whether SEZ/Non-SEZ
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.sezNonsez}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  RCM Applicable
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.rcmApplicable}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>

                          {values.lutNo && (
                            <>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      LUT No
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="name"
                                      value={values.lutNo}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </>
                          )}
                          {values.lutDate && (
                            <>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      LUT Date
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="name"
                                      value={values.lutDate}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </>
                          )}
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Payment Terms
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.paymentTerms}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  CIN Number
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.cinNumber}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Registered under MSMED Act?
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.msmedAct}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Have TDS lower deduction certificate?
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.tdsLowerDeduction}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Category Of Supply
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.categoryOfSupply}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>

                          {values.pfAccountNumber && (
                            <>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      PF Account Number
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="name"
                                      value={values.pfAccountNumber}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              {values.esiAccountNumber && (
                                <>
                                  <Grid item xl={3} lg={4} md={6} xs={12}>
                                    <MDBox component="form" role="form">
                                      <MDBox>
                                        <MDTypography
                                          variant="h6"
                                          color="inherit"
                                          // style={{
                                          //   color: "grey",
                                          //   textAlign: "left",
                                          // }}
                                        >
                                          ESI Account Number
                                        </MDTypography>
                                        <MDInput
                                          fullWidth
                                          name="name"
                                          value={values.esiAccountNumber}
                                          // style={{ width: "310px" }}
                                        />
                                      </MDBox>
                                    </MDBox>
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          mt={5}
                          color="inherit"
                        >
                          Bank Details
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Beneficiary Bank And Address
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails
                                          .beneficiaryBankAndAddress
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Account Number
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.accountNumber
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Account Type
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.accountType
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Account Name
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.accountName
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  IFSC Code
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.ifscCode
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Swift Code/IBAN
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.swiftCodeIban
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Intermediary/Routing Bank
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  // value={values.contactDetails.contactNo}
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails
                                          .intermediaryRoutingBank
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Swift Code Of Intermediary/RoutingBank
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  // value={values.contactDetails.contactNo}
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails
                                          .swiftCodeOfIntermediaryRoutingBank
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          mt={5}
                          color="inherit"
                        >
                          Documents
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid
                            item
                            lg={2}
                            style={{ width: "310px", color: "white" }}
                          >
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Incorporation Certificate{" "}
                                </MDTypography>
                                <MDButton
                                  style={{ color: "white" }}
                                  color="primary"
                                  variant="contained"
                                >
                                  {" "}
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments
                                          .incorporationCertificate.name
                                      : ""
                                  )}{" "}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item lg={2} style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Pan Card
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments.panCard.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid lg={2} item style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  color="inherit"
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Cancelled Cheque
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments.cancelledCheque.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid lg={2} item style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Valid LUT
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments.validLut.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item lg={2} style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  mt={0}
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  GST Registration Certificate
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments
                                          .gstRegistrationCertificate.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          {values.attachments?.sezApproval && (
                            <>
                              <Grid item lg={2} style={{ width: "310px" }}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      {" "}
                                      SEZ Approval
                                    </MDTypography>
                                    <MDButton
                                      color="primary"
                                      variant="contained"
                                    >
                                      {getDocument(
                                        values.attachments
                                          ? values.attachments.sezApproval.name
                                          : ""
                                      )}
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </>
                          )}
                          {values.attachments?.pfCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    PF Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments.pfCertificate.name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                          {values.attachments?.esiCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    mt={0}
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    ESI Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments.esiCertificate.name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                          {values.attachments?.tdsLowerDeductionCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    TDS Lower Deduction Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments
                                            .tdsLowerDeductionCertificate.name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                          {values.attachments?.msmeCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    MSME Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments.msmeCertificate
                                            .name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {location.pathname === `/status/${vendorUserId}` && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography variant="h5" mt={5} color="inherit">
                            Validation checks
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="gridbox">
                            <Grid container spacing={2}>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Similar Name Check in ERP
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="similarNameCheckInERP"
                                      value={`Status: ${
                                        statusVendorName ? "Match found" : "OK"
                                      }`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      GST Validation in ERP
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: ${
                                        statusGST ? "Match found" : "OK"
                                      }`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      PAN Validation in ERP
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: ${
                                        statusPAN ? "Match found" : "OK"
                                      }`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      GST Validation in GSTIN
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: OK`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Vendor Name Validation in GSTIN
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: OK`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography variant="h6" color="inherit">
                                      Pincode Validation in GSTIN
                                    </MDTypography>
                                    <MDInput fullWidth value={`Status: OK`} />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {location.pathname === `/status/${vendorUserId}` && (
                      <Accordion expanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography variant="h5" mt={5} color="inherit">
                            Company Additions
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            <Grid item xl={4} lg={4} md={6} xs={12}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography variant="h6" color="inherit">
                                    Applicable Company Code
                                  </MDTypography>
                                  <Select
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        borderRadius: 5,
                                        minHeight: "44px",
                                        height: "auto",
                                      }),
                                    }}
                                    isClearable
                                    className="select-css"
                                    maxMenuHeight={130}
                                    placeholder="Choose one..."
                                    name="companyCode"
                                    value={companyCode}
                                    options={valuesCompanyCode}
                                    onChange={(selectedOption) => {
                                      setCompanyCode(selectedOption);
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>

                            <Grid item xl={4} lg={4} md={6} xs={12}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography variant="h6" color="inherit">
                                    Supplier Group
                                  </MDTypography>
                                  <Select
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        borderRadius: 5,
                                        minHeight: "44px",
                                        height: "auto",
                                      }),
                                    }}
                                    isClearable
                                    className="select-css"
                                    maxMenuHeight={130}
                                    placeholder="Choose one..."
                                    name="supplierGroup"
                                    value={supplierGroup}
                                    options={valuesSupplierGroup}
                                    onChange={(selectedOption) => {
                                      setSupplierGroup(selectedOption);
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>

                            <Grid item xl={4} lg={4} md={6} xs={12}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography variant="h6" color="inherit">
                                    Remarks
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    name="remarks"
                                    value={remarks}
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setRemarks(e.target.value);
                                    }}
                                    style={{ width: "300px" }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {location.pathname ===
                      `/registered-vendors/${vendorUserId}` && (
                      <>
                        <Accordion expanded={true}>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <MDTypography variant="h5" mt={5} color="inherit">
                              Company Additions
                            </MDTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography variant="h6" color="inherit">
                                      Applicable Company Code
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      value={values.companyCode}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography variant="h6" color="inherit">
                                      Supplier Group
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      value={values.supplierGroup}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography variant="h6" color="inherit">
                                      Remarks
                                    </MDTypography>
                                    <MDInput fullWidth value={values.remarks} />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                    {location.pathname ===
                      `/rejected-vendors/${vendorUserId}` && (
                      <Accordion expanded={true}>
                        <AccordionDetails>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    Rejection Reason
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    value={values.rejectReason}
                                    style={{ width: "310px" }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignSelf: "center",
                        marginTop: "10px",
                      }}
                    >
                      <pre>
                        {location.pathname === `/status/${vendorUserId}` && (
                          <MDButton
                            type="button"
                            color="secondary"
                            variant="contained"
                            onClick={handleOpenAcceptModal}
                            style={{ backgroundColor: "green" }}
                          >
                            Accept
                          </MDButton>
                        )}
                        <MDButton
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={clickHandler}
                          style={{ margin: "5px" }}
                        >
                          Go Back
                        </MDButton>
                        {location.pathname === `/status/${vendorUserId}` && (
                          <MDButton
                            type="button"
                            color="error"
                            variant="contained"
                            onClick={handleOpenRejectModal}
                            style={{ maigin: "5px" }}
                          >
                            Reject
                          </MDButton>
                        )}
                      </pre>
                    </div>
                  </Form>
                )}
              </Formik>

              <Modal
                open={openAcceptModal}
                onClose={handleCloseAcceptModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={acceptStyle}>
                  <MDTypography>
                    Are you sure you want to Accept the Vendor?
                  </MDTypography>
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "1.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseAcceptModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="success"
                        onClick={acceptHandler}
                      >
                        Yes
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessAccept}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessAccept(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Vendor accepted successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorAccept}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorAccept(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForAccept}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openRejectModal}
                onClose={handleCloseRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={acceptStyle}>
                  <MDTypography>
                    Are you sure you want to Reject the Vendor?
                  </MDTypography>
                  <TextField
                    fullWidth
                    label="Comment"
                    variant="outlined"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "1.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseRejectModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ width: "100%", marginLeft: "0.5rem" }}>
                      <MDButton
                        style={{ width: "12rem" }}
                        color="error"
                        onClick={rejectHandler}
                      >
                        Reject
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessReject}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessReject(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Vendor rejected successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorReject}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorReject(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForReject}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </MDBox>
          </MDCard>
        ) : (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getVendorByVendorIdReducer: state.getVendorByVendorIdReducer,
    getVendorsNotApprovedReducer: state.getVendorsNotApprovedReducer,
    acceptVendorHandlerReducer: state.acceptVendorHandlerReducer,
    rejectVendorHandlerReducer: state.rejectVendorHandlerReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getVendorByVendorId: (vendorUserId, jwtToken) =>
      dispatch(getVendorByVendorId(vendorUserId, jwtToken)),
    getVendorsNotApproved: (payload) =>
      dispatch(getVendorsNotApproved(payload)),
    acceptVendorHandler: (payload) => dispatch(acceptVendorHandler(payload)),
    rejectVendorHandler: (payload) => dispatch(rejectVendorHandler(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DashboardForm);
