import {
  GET_WAREHOUSE_REQUEST,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_FAILURE,
  GET_YARDOCCUPANCY_REQUEST,
  GET_YARDOCCUPANCY_SUCCESS,
  GET_YARDOCCUPANCY_FAILURE,
  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,
  GET_PICKUP_REQUEST,
  GET_PICKUP_SUCCESS,
  GET_PICKUP_FAILURE,
  GET_FINISHEDGOODSHP_REQUEST,
  GET_FINISHEDGOODSHP_SUCCESS,
  GET_FINISHEDGOODSHP_FAILURE,
  GET_PDI_REQUEST,
  GET_PDI_SUCCESS,
  GET_PDI_FAILURE,
  GET_REJECTION_REQUEST,
  GET_REJECTION_SUCCESS,
  GET_REJECTION_FAILURE,
} from "./homeTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseRequest = () => {
  return {
    type: GET_WAREHOUSE_REQUEST,
  };
};

const getWarehouseSuccess = (payload) => {
  return {
    type: GET_WAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getWarehouseFailure = (error) => {
  return {
    type: GET_WAREHOUSE_FAILURE,
    payload: error,
  };
};

const getWarehouse = (id) => {
  return (dispatch) => {
    dispatch(getWarehouseRequest());
    let url = `${baseUrl}/homepage/warehouses?warehouse_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseFailure(err.message));
      });
  };
};

const getYardOccupancyRequest = () => {
  return {
    type: GET_YARDOCCUPANCY_REQUEST,
  };
};

const getYardOccupancySuccess = (payload) => {
  return {
    type: GET_YARDOCCUPANCY_SUCCESS,
    payload: payload,
  };
};

const getYardOccupancyFailure = (error) => {
  return {
    type: GET_YARDOCCUPANCY_FAILURE,
    payload: error,
  };
};

const getYard = (id) => {
  return (dispatch) => {
    dispatch(getYardOccupancyRequest());
    let url = `${baseUrl}/homepage/yard_occupancy?warehouse_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getYardOccupancySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getYardOccupancyFailure(err.message));
      });
  };
};

const getInventoryRequest = () => {
  return {
    type: GET_INVENTORY_REQUEST,
  };
};

const getInventorySuccess = (payload) => {
  return {
    type: GET_INVENTORY_SUCCESS,
    payload: payload,
  };
};

const getInventoryFailure = (error) => {
  return {
    type: GET_INVENTORY_FAILURE,
    payload: error,
  };
};

const getInventory = () => {
  return (dispatch) => {
    dispatch(getInventoryRequest());
    let url = `${baseUrl}/homepage/inventory`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getInventorySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getInventoryFailure(err.message));
      });
  };
};

const getPickupRequest = () => {
  return {
    type: GET_PICKUP_REQUEST,
  };
};

const getPickupSuccess = (payload) => {
  return {
    type: GET_PICKUP_SUCCESS,
    payload: payload,
  };
};

const getPickupFailure = (error) => {
  return {
    type: GET_PICKUP_FAILURE,
    payload: error,
  };
};

const getPickup = () => {
  return (dispatch) => {
    dispatch(getPickupRequest());
    let url = `${baseUrl}/homepage/pickup_list_status`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getPickupSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getPickupFailure(err.message));
      });
  };
};

const getFinishedGoodsRequest = () => {
  return {
    type: GET_FINISHEDGOODSHP_REQUEST,
  };
};

const getFinishedGoodsSuccess = (payload) => {
  return {
    type: GET_FINISHEDGOODSHP_SUCCESS,
    payload: payload,
  };
};

const getFinishedGoodsFailure = (error) => {
  return {
    type: GET_FINISHEDGOODSHP_FAILURE,
    payload: error,
  };
};

const getFg = () => {
  return (dispatch) => {
    dispatch(getFinishedGoodsRequest());
    let url = `${baseUrl}/homepage/fg_status`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getFinishedGoodsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFinishedGoodsFailure(err.message));
      });
  };
};

const getPdiRequest = () => {
  return {
    type: GET_PDI_REQUEST,
  };
};

const getPdiSuccess = (payload) => {
  return {
    type: GET_PDI_SUCCESS,
    payload: payload,
  };
};

const getPdiFailure = (error) => {
  return {
    type: GET_PDI_FAILURE,
    payload: error,
  };
};

const getPdi = () => {
  return (dispatch) => {
    dispatch(getPdiRequest());
    let url = `${baseUrl}/homepage/dispatch_status`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getPdiSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getPdiFailure(err.message));
      });
  };
};

const getRejectionRequest = () => {
  return {
    type: GET_REJECTION_REQUEST,
  };
};

const getRejectionSuccess = (payload) => {
  return {
    type: GET_REJECTION_SUCCESS,
    payload: payload,
  };
};

const getRejectionFailure = (error) => {
  return {
    type: GET_REJECTION_FAILURE,
    payload: error,
  };
};

const getRejection = () => {
  return (dispatch) => {
    dispatch(getRejectionRequest());
    let url = `${baseUrl}/homepage/rejection_status`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRejectionSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getRejectionFailure(err.message));
      });
  };
};

export {
  getWarehouse,
  getYard,
  getInventory,
  getPickup,
  getFg,
  getPdi,
  getRejection,
};
