/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const CREATE_USER_DETAILS_REQUEST = "CREATE_USER_DETAILS_REQUEST";
export const CREATE_USER_DETAILS_SUCCESS = "CREATE_USER_DETAILS_SUCCESS";
export const CREATE_USER_DETAILS_FAILURE = "CREATE_USER_DETAILS_FAILURE";

export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const DELETE_USER_DETAILS_REQUEST = "DELETE_USER_DETAILS_REQUEST";
export const DELETE_USER_DETAILS_SUCCESS = "DELETE_USER_DETAILS_SUCCESS";
export const DELETE_USER_DETAILS_FAILURE = "DELETE_USER_DETAILS_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
