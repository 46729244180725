import {
  GET_WAREHOUSEOCCUPANCY_REQUEST,
  GET_WAREHOUSEOCCUPANCY_SUCCESS,
  GET_WAREHOUSEOCCUPANCY_FAILURE,
  DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST,
  DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS,
  DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE,
} from "./warehouseOccupancyTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseOccupancyRequest = () => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_REQUEST,
  };
};

const getWarehouseOccupancySuccess = (payload) => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_SUCCESS,
    payload: payload,
  };
};

const getWarehouseOccupancyFailure = (error) => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_FAILURE,
    payload: error,
  };
};


const getWarehouseOccupancy = (locationCode,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehouseOccupancyRequest());
    let url = `${baseUrl}/reports/warehouse_occupancy?locationCode=${locationCode}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehouseOccupancySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getWarehouseOccupancyFailure(err.message));
      });
  };
};

export { getWarehouseOccupancy };
