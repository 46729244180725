import {
  GETLOCATION_BYWAREHOUSE_REQUEST,
  GETLOCATION_BYWAREHOUSE_SUCCESS,
  GETLOCATION_BYWAREHOUSE_FAILURE,
  ADDLOCATION_TOWAREHOUSE_REQUEST,
  ADDLOCATION_TOWAREHOUSE_SUCCESS,
  ADDLOCATION_TOWAREHOUSE_FAILURE,
  ADD_RACK_TO_WAREHOUSE_REQUEST,
  ADD_RACK_TO_WAREHOUSE_SUCCESS,
  ADD_RACK_TO_WAREHOUSE_FAILURE,
  GETITEM_BYLOCATION_REQUEST,
  GETITEM_BYLOCATION_SUCCESS,
  GETITEM_BYLOCATION_FAILURE,
  GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
  GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  DELETE_RACK_REQUEST,
  DELETE_RACK_SUCCESS,
  DELETE_RACK_FAILURE,
  GET_RACKS_BY_ZONE_AND_CATEGORY_REQUEST,
  GET_RACKS_BY_ZONE_AND_CATEGORY_SUCCESS,
  GET_RACKS_BY_ZONE_AND_CATEGORY_FAILURE,
  REORDER_RACKS_AND_ZONES_REQUEST,
  REORDER_RACKS_AND_ZONES_SUCCESS,
  REORDER_RACKS_AND_ZONES_FAILURE,
  UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_FAILURE,
  UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_SUCCESS,
  UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_WARNING,
  UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_REQUEST,
  DOWNLOAD_WAREHOUSE_FAILURE,
  DOWNLOAD_WAREHOUSE_SUCCESS,
  DOWNLOAD_WAREHOUSE_REQUEST,
  DOWNLOAD_ZONE_RACK_LOCATION_REQUEST,
  DOWNLOAD_ZONE_RACK_LOCATION_SUCCESS,
  DOWNLOAD_ZONE_RACK_LOCATION_FAILURE,
} from "./viewLocationTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getLocationsByWarehouseRequest = () => {
  return {
    type: GETLOCATION_BYWAREHOUSE_REQUEST,
  };
};

const getLocationsByWarehouseSuccess = (payload) => {
  return {
    type: GETLOCATION_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getLocationsByWarehouseFailure = (error) => {
  return {
    type: GETLOCATION_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getLocationsByWarehouse = (warehouseId) => {
  // let headers = {
  //   headers: {
  //     Authorization: `Bearer ${jwtToken}`,
  //   },
  // };
  return (dispatch) => {
    dispatch(getLocationsByWarehouseRequest());
    let url = `${baseUrl}/location?warehouse_id=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getLocationsByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByWarehouseFailure(err.message));
      });
  };
};

const getRacksByZoneAndCategoryRequest = () => {
  return {
    type: GET_RACKS_BY_ZONE_AND_CATEGORY_REQUEST,
  };
};

const getRacksByZoneAndCategorySuccess = (payload) => {
  return {
    type: GET_RACKS_BY_ZONE_AND_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const getRacksByZoneAndCategoryFailure = (error) => {
  return {
    type: GET_RACKS_BY_ZONE_AND_CATEGORY_FAILURE,
    payload: error,
  };
};

const getRacksByZoneAndCategory = (categoryId, zoneId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRacksByZoneAndCategoryRequest());
    let url = `${baseUrl}/get_rack_by_category_and_zone_id?category_id=${categoryId}&zone_id=${zoneId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRacksByZoneAndCategorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRacksByZoneAndCategoryFailure(err.message));
      });
  };
};

const addLocationToWarehouseRequest = () => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_REQUEST,
  };
};

const addLocationToWarehouseSuccess = (payload) => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const addLocationToWarehouseFailure = (error) => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_FAILURE,
    payload: error,
  };
};

const addLocationToWarehouse = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(addLocationToWarehouseRequest());
    let url = `${baseUrl}/location`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addLocationToWarehouseSuccess(response.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(addLocationToWarehouseFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const addRackToWarehouseRequest = () => {
  return {
    type: ADD_RACK_TO_WAREHOUSE_REQUEST,
  };
};

const addRackToWarehouseSuccess = (payload) => {
  return {
    type: ADD_RACK_TO_WAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const addRackToWarehouseFailure = (error) => {
  return {
    type: ADD_RACK_TO_WAREHOUSE_FAILURE,
    payload: error,
  };
};

const addRackToWarehouse = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(addRackToWarehouseRequest());
    let url = `${baseUrl}/rack/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addRackToWarehouseSuccess(response.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(addRackToWarehouseFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getItemByLocationRequest = () => {
  return {
    type: GETITEM_BYLOCATION_REQUEST,
  };
};

const getItemByLocationSuccess = (payload) => {
  return {
    type: GETITEM_BYLOCATION_SUCCESS,
    payload: payload,
  };
};

const getItemByLocationFailure = (error) => {
  return {
    type: GETITEM_BYLOCATION_FAILURE,
    payload: error,
  };
};

const getItemByLocation = (locationId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemByLocationRequest());

    let url = `${baseUrl}/suid/by_location_id?location_id=${locationId}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemByLocationSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemByLocationFailure(err.message));
      });
  };
};

const getLocationsByWarehouseAndZoneRequest = () => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  };
};

const getLocationsByWarehouseAndZoneSuccess = (payload) => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
    payload: payload,
  };
};

const getLocationsByWarehouseAndZoneFailure = (error) => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
    payload: error,
  };
};

const getLocationsByWarehouseAndZone = (
  rackId,
  warehouseId,
  zoneId,
  jwtToken
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsByWarehouseAndZoneRequest());

    let url = `${baseUrl}/get_location_by_rack_id?rack_id=${rackId}&warehouse_id=${warehouseId}&zone_id=${zoneId}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsByWarehouseAndZoneSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByWarehouseAndZoneFailure(err.message));
      });
  };
};

const deleteLocationRequest = () => {
  return {
    type: DELETE_LOCATION_REQUEST,
  };
};

const deleteLocationSuccess = (payload) => {
  return {
    type: DELETE_LOCATION_SUCCESS,
    payload: payload,
  };
};

const deleteLocationFaliure = (error) => {
  return {
    type: DELETE_LOCATION_FAILURE,
    payload: error,
  };
};

const deleteLocation = (locationId, warehouseId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/location/delete?location_id=${locationId}&warehouse_id=${warehouseId}`;
    dispatch(deleteLocationRequest());
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteLocationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteRackRequest = () => {
  return {
    type: DELETE_RACK_REQUEST,
  };
};

const deleteRackSuccess = (payload) => {
  return {
    type: DELETE_RACK_SUCCESS,
    payload: payload,
  };
};

const deleteRackFaliure = (error) => {
  return {
    type: DELETE_RACK_FAILURE,
    payload: error,
  };
};

const deleteRack = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/rack/delete?id=${id}`;
    dispatch(deleteRackRequest());
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteRackSuccess(response.data));
        return { status: true };
      })
      .catch((error) => {
        dispatch(deleteRackFaliure(error.message));
        return { status: false };
      });
  };
};

const reOrderRacksAndZonesRequest = () => {
  return {
    type: REORDER_RACKS_AND_ZONES_REQUEST,
  };
};

const reOrderRacksAndZonesSuccess = (payload) => {
  return {
    type: REORDER_RACKS_AND_ZONES_SUCCESS,
    payload: payload,
  };
};

const reOrderRacksAndZonesFailure = (error) => {
  return {
    type: REORDER_RACKS_AND_ZONES_FAILURE,
    payload: error,
  };
};

const reOrderRacksAndZones = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(reOrderRacksAndZonesRequest());
    let url = `${baseUrl}/update_order`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(reOrderRacksAndZonesSuccess(response.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(reOrderRacksAndZonesFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const uploadExcelForCreatingZoneRackLocationRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_REQUEST,
  };
};

const uploadExcelForCreatingZoneRackLocationSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_SUCCESS,
    payload: payload,
  };
};

const uploadExcelForCreatingZoneRackLocationWarning = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_WARNING,
    payload: payload,
  };
};

const uploadExcelForCreatingZoneRackLocationFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_FAILURE,
    payload: error,
  };
};

const uploadExcelForCreatingZoneRackLocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadExcelForCreatingZoneRackLocationRequest());
    let url = `${baseUrl}/location/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        if (response.data.statusCode == 406) {
          dispatch(
            uploadExcelForCreatingZoneRackLocationWarning(response.data)
          );
          return {
            status: true,
            statusCode: response.data.statusCode,
            data: response.data.data,
          };
        }

        dispatch(uploadExcelForCreatingZoneRackLocationSuccess(response.data));
        return {
          status: true,
          statusCode: response.data.statusCode,
          data: response.data.data,
        };
      })
      .catch((err) => {
        dispatch(uploadExcelForCreatingZoneRackLocationFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const downloadWarehouseRequest = () => {
  return {
    type: DOWNLOAD_WAREHOUSE_REQUEST,
  };
};

const downloadWarehouseSuccess = (payload) => {
  return {
    type: DOWNLOAD_WAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const downloadWarehouseFailure = (error) => {
  return {
    type: DOWNLOAD_WAREHOUSE_FAILURE,
    payload: error,
  };
};

const downloadWarehouse = (type, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadWarehouseRequest());
    let url = `${baseUrl}/download_warehouses?type=${type}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(downloadWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(downloadWarehouseFailure(err.message));
      });
  };
};

const downloadZoneRackLocationRequest = () => {
  return {
    type: DOWNLOAD_ZONE_RACK_LOCATION_REQUEST,
  };
};

const downloadZoneRackLocationSuccess = (payload) => {
  return {
    type: DOWNLOAD_ZONE_RACK_LOCATION_SUCCESS,
    payload: payload,
  };
};

const downloadZoneRackLocationFailure = (error) => {
  return {
    type: DOWNLOAD_ZONE_RACK_LOCATION_FAILURE,
    payload: error,
  };
};

const downloadZoneRackLocation = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadZoneRackLocationRequest());
    let url = `${baseUrl}/download_excel_for_rack_location_creation`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(downloadZoneRackLocationSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(downloadZoneRackLocationFailure(err.message));
      });
  };
};

export {
  getLocationsByWarehouse,
  addLocationToWarehouse,
  getItemByLocation,
  getLocationsByWarehouseAndZone,
  deleteLocation,
  addRackToWarehouse,
  deleteRack,
  getRacksByZoneAndCategory,
  reOrderRacksAndZones,
  uploadExcelForCreatingZoneRackLocation,
  downloadWarehouse,
  downloadZoneRackLocation,
};
