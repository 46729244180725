import {
  GET_ACTIVECYCLECOUNT_REQUEST,
  GET_ACTIVECYCLECOUNT_SUCCESS,
  GET_ACTIVECYCLECOUNT_FAILURE,
  GET_CYCLECOUNTTYPE_REQUEST,
  GET_CYCLECOUNTTYPE_SUCCESS,
  GET_CYCLECOUNTTYPE_FAILURE,
  GET_COMPLETEDCYCLECOUNT_REQUEST,
  GET_COMPLETEDCYCLECOUNT_SUCCESS,
  GET_COMPLETEDCYCLECOUNT_FAILURE,
  CREATE_CYCLECOUNT_REQUEST,
  CREATE_CYCLECOUNT_SUCCESS,
  CREATE_CYCLECOUNT_FAILURE,
  GET_WAREHOUSESBYITEMID_REQUEST,
  GET_WAREHOUSESBYITEMID_SUCCESS,
  GET_WAREHOUSESBYITEMID_FAILURE,
} from "./cycleCountTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getActiveCycleCountRequest = () => {
  return {
    type: GET_ACTIVECYCLECOUNT_REQUEST,
  };
};

const getActiveCycleCountSuccess = (payload) => {
  return {
    type: GET_ACTIVECYCLECOUNT_SUCCESS,
    payload: payload,
  };
};

const getActiveCycleCountFailure = (error) => {
  return {
    type: GET_ACTIVECYCLECOUNT_FAILURE,
    payload: error,
  };
};

const getActiveCycleCount = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getActiveCycleCountRequest());
    let url = `${baseUrl}/cycle_count/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getActiveCycleCountSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getActiveCycleCountFailure(err.message));
      });
  };
};

const getCompletedCycleCountRequest = () => {
  return {
    type: GET_COMPLETEDCYCLECOUNT_REQUEST,
  };
};

const getCompletedCycleCountSuccess = (payload) => {
  return {
    type: GET_COMPLETEDCYCLECOUNT_SUCCESS,
    payload: payload,
  };
};

const getCompletedCycleCountFailure = (error) => {
  return {
    type: GET_COMPLETEDCYCLECOUNT_FAILURE,
    payload: error,
  };
};

const getCompletedCycleCount = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCompletedCycleCountRequest());
    let url = `${baseUrl}/cycle_count/get_completed`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCompletedCycleCountSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getCompletedCycleCountFailure(err.message));
      });
  };
};

const getCycleCountTypeRequest = () => {
  return {
    type: GET_CYCLECOUNTTYPE_REQUEST,
  };
};

const getCycleCountTypeSuccess = (payload) => {
  return {
    type: GET_CYCLECOUNTTYPE_SUCCESS,
    payload: payload,
  };
};

const getCycleCountTypeFailure = (error) => {
  return {
    type: GET_CYCLECOUNTTYPE_FAILURE,
    payload: error,
  };
};

const getCycleCountType = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCycleCountTypeRequest());
    let url = `${baseUrl}/cycle_count/get_cycle_count_type`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCycleCountTypeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getCycleCountTypeFailure(err.message));
      });
  };
};

const createCycleCountRequest = () => {
  return {
    type: CREATE_CYCLECOUNT_REQUEST,
  };
};

const createCycleCountSuccess = (payload) => {
  return {
    type: CREATE_CYCLECOUNT_SUCCESS,
    payload: payload,
  };
};

const createCycleCountFailure = (error) => {
  return {
    type: CREATE_CYCLECOUNT_FAILURE,
    payload: error,
  };
};

const createCycleCount = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createCycleCountRequest());
    let url = `${baseUrl}/cycle_count/create`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createCycleCountSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createCycleCountFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getWarehousesByItemRequest = () => {
  return {
    type: GET_WAREHOUSESBYITEMID_REQUEST,
  };
};

const getWarehousesByItemSuccess = (payload) => {
  return {
    type: GET_WAREHOUSESBYITEMID_SUCCESS,
    payload: payload,
  };
};

const getWarehousesByItemFailure = (error) => {
  return {
    type: GET_WAREHOUSESBYITEMID_FAILURE,
    payload: error,
  };
};

const getWarehousesByItem = (itemId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehousesByItemRequest());
    let url = `${baseUrl}/warehouse/get_by_item?itemId=${itemId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehousesByItemSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehousesByItemFailure(err.message));
      });
  };
};

export {
  getActiveCycleCount,
  getCompletedCycleCount,
  getCycleCountType,
  createCycleCount,
  getWarehousesByItem,
};
