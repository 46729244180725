/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Cookies from "universal-cookie";

// @mui material components;
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard base styles
import typography from "assets/theme/base/typography";

// Functions from store
import { createVendorRegistrationLink } from "../../store/vendor-details/vendorDetailsActions";
import { getSubModulesByRoleId } from "store";

const VendorDetails = ({
  createVendorRegistrationLink,
  vendorRegistrationLinkReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  const cookies = new Cookies();

  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let vendorDetailsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d71efda6-caa3-4fb0-b38d-2a4dc5683b70"
  );
  let createAccess = vendorDetailsModule
    ? vendorDetailsModule.actionId.includes(
        "61a45440-0ac8-4bd9-98dc-9b59ef682925"
      )
    : null;

  const { size } = typography;

  const [errorMsg, setErrorMsg] = useState("");

  const initialValues = {
    vendorName: "",
    vendorCode: "",
    email: "",
  };

  const validationSchema = Yup.object({
    vendorName: Yup.string().required("Enter the name!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-Mail!"),
    vendorCode: Yup.string().required("Enter the vendor code !"),
  });

  const [isErrorCreateUser, setIsErrorCreateUser] = useState(false);
  const [isSuccessCreateUser, setIsSuccessCreateUser] = useState(false);
  const [isErrorForEmail, setIsErrorForEmail] = useState(false);

  useEffect(() => {
    if (isSuccessCreateUser) {
      setTimeout(() => {
        setIsSuccessCreateUser(false);
      }, 3000);
    }
  }, [isSuccessCreateUser]);

  useEffect(() => {
    if (isErrorCreateUser) {
      setTimeout(() => {
        setIsErrorCreateUser(false);
      }, 3000);
    }
  }, [isErrorCreateUser]);

  const onSubmit = async (values, { resetForm }) => {
    const res = await createVendorRegistrationLink(values);

    if (res.status) {
      setIsSuccessCreateUser(true);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreateUser(true);
      setErrorMsg(res.data.data.msg);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 500);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card style={{ width: 500, marginTop: "50px" }}>
            <MDBox mt={2}>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                style={{ textAlign: "center" }}
              >
                New Vendor Request
              </MDTypography>
            </MDBox>

            <MDBox pt={1} pb={3} px={3}>
              <MDBox
                component="form"
                role="form"
                onSubmit={formik.handleSubmit}
              >
                <MDBox mb={2}>
                  <MDInput
                    inputProps={{
                      maxLength: 45,
                    }}
                    label="Vendor Name"
                    type="text"
                    name="vendorName"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.vendorName}
                    error={
                      formik.touched.vendorName &&
                      formik.errors.vendorName &&
                      true
                    }
                  />
                  {formik.touched.vendorName && formik.errors.vendorName && (
                    <TextError msg={formik.errors.vendorName} />
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    inputProps={{
                      maxLength: 45,
                    }}
                    label="Vendor Code"
                    type="text"
                    name="vendorCode"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.vendorCode}
                    error={
                      formik.touched.vendorCode &&
                      formik.errors.vendorCode &&
                      true
                    }
                  />
                  {formik.touched.vendorCode && formik.errors.vendorCode && (
                    <TextError msg={formik.errors.vendorCode} />
                  )}
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    label="E-Mail"
                    type="email"
                    name="email"
                    fullWidth
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email && true}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <TextError msg={formik.errors.email} />
                  )}
                </MDBox>

                <MDBox>
                  <Collapse in={isErrorCreateUser}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorCreateUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg}
                    </Alert>
                  </Collapse>
                  <Collapse in={isSuccessCreateUser}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessCreateUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      New Vendor Request Submitted Successfully
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorForEmail}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorForEmail(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Please check your email domain!
                    </Alert>
                  </Collapse>
                </MDBox>
                {createAccess && (
                  <MDBox mt={2}>
                    <MDButton
                      // fullWidth
                      style={{ backgroundColor: "red", float: "left" }}
                      color="info"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      submit
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          </Card>
        </div>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!loading ? (
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
          position="fixed"
          bottom={1}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="text"
            fontSize={size.sm}
            px={1.5}
          >
            <p className=" text-center text-md-left">
              Developed and maintained by :
              <a className="link" href="http://mobillor.com/">
                <strong style={{ color: "red" }}>
                  {" "}
                  Mobillor Technologies Pvt. Ltd.
                </strong>
              </a>
            </p>
          </MDBox>
        </MDBox>
      ) : null}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    vendorRegistrationLinkReducer: state.vendorRegistrationLinkReducer,
    drawerState: state.drawerState,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    createVendorRegistrationLink: (payload) =>
      dispatch(createVendorRegistrationLink(payload)),
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(VendorDetails);
