/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components

import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextError from "utils/TextError";
// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

//  some date function

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CancelIcon from "@mui/icons-material/Clear";
// Functions from store
import {
  getSubModulesByRoleId,
  getDocumentType,
  getCreatedActivity,
  getWarehouses,
  getSuids,
  getItemCodeByWarehouse,
  getLocationsForQuarantine,
  getSkuStatusByLocationAndItemCode,
  getSkuByItemCode,
  createQuarantineForAcceptedList,
  getSkuByDocumentNo,
  releaseQuarantine,
} from "../../../store";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import { getAcceptedQuarantines } from "store/quarantine/quarantineActions";
import ReleaseButton from "components/standardized-components/ReleaseButton";
import ViewButton from "components/standardized-components/ViewButton";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 380, md: 800, lg: 1200, xl: 1100 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};

const styleForStationModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1200 },
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};

const AcceptedQuarantines = ({
  getAcceptedQuarantines,
  createQuarantineForAcceptedList,
  acceptedQuarantinesReducer,
  getSkuStatusByLocationAndItemCode,
  getSkuStatusByLocationAndItemCodeReducer,
  getSkuByItemCodeReducer,
  getSkuByDocumentNoReducer,
  itemCodeByWarehouseReducer,
  getDocumentType,
  locationReducer,
  getCreatedActivity,
  getLocationsForQuarantine,
  getSkuByItemCode,
  getWarehouses,
  releaseQuarantine,
  warehouses,
  getItemCodeByWarehouse,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getSkuByDocumentNo,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let acceptedquarantineModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "1a4ae114-8f53-4735-b698-e13432617b83"
  );

  let viewQuarantine = acceptedquarantineModule
    ? acceptedquarantineModule.actionId.includes(
        "b28ded88-0b97-4b23-b37f-29927562f2ec"
      )
    : null;
  let createQuarantine = acceptedquarantineModule
    ? acceptedquarantineModule.actionId.includes(
        "12dbf924-5695-49ec-8bf2-c93231b4bb05"
      )
    : null;
  let releaseQuarantineAccess = acceptedquarantineModule
    ? acceptedquarantineModule.actionId.includes(
        "b7af02d0-fe25-46ff-9167-6e3b8d39b543"
      )
    : null;
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [documentNo, setDocumentNo] = useState("");

  useEffect(() => {
    getAcceptedQuarantines(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Document No", accessor: "documentNumber" },
      { Header: "Total Qty", accessor: "totalQty" },
      { Header: "Item Code/Des", accessor: "itemCode" },
      {
        Header: "action",
        accessor: "action",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  const [stationModal, setOpenStationModal] = useState(false);
  const [documentNumber, setdocumentNumber] = useState("");

  const handleOpenStationModal = () => {
    setOpenStationModal(true);
  };
  const handleCloseStationModal = () => {
    setOpenStationModal(false);
  };
  useEffect(() => {
    let tempQuarantineSku = [];
    let data = acceptedQuarantinesReducer.acceptedQuarantines.data
      ? acceptedQuarantinesReducer.acceptedQuarantines.data
      : [];
    setLoading(acceptedQuarantinesReducer.loading);
    data.map((quarantine) => {
      let des = quarantine?.itemDescription;
      let len = desLength;
      const obj = {
        documentNumber: quarantine.documentNumber,
        totalQty: `${quarantine.totalQty}`,
        itemCode: quarantine.itemCode
          ? `${quarantine.itemCode} / ${des ? des.substring(0, len) : ""}`
          : "",
        sku: `${quarantine.sku ? quarantine.sku : "NA"}`,
        location: quarantine.locationCode,
        qty: `${quarantine.qty} ${quarantine.uom}`,
        lotSerialNo: `${quarantine.lotNumber ? quarantine.lotNumber : "NA"} / ${
          quarantine.serialNumber ? quarantine.serialNumber : "NA"
        }`,
        uom: `${quarantine.uom}`,
        action: (
          <>
            {releaseQuarantineAccess && (
              <ReleaseButton
                tooltip="Release"
                style={{
                  marginLeft: "0.5rem",
                }}
                disabled={quarantine.isDisabled === 1}
                type="button"
                variant="gradient"
                color="success"
                iconOnly
                onClick={() => {
                  setdocumentNumber(quarantine.documentNumber);
                  handleOpenAllocateUserModal(quarantine);
                  getCreatedActivity("", jwtToken);
                }}
              >
                {" "}
                <Icon>forward</Icon>
              </ReleaseButton>
            )}

            <ViewButton
              tooltip="View SKU"
              style={{
                marginLeft: "0.5rem",
              }}
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => {
                setDocumentNo(quarantine?.documentNumber);
                handleOpenStationModal(quarantine);
                getSkuByDocumentNo(quarantine?.documentTrId, jwtToken);
              }}
            >
              <Icon> visibility_icon </Icon>
            </ViewButton>
          </>
        ),
      };
      tempQuarantineSku.push(obj);
    });
    viewQuarantine && setState({ ...state, rows: tempQuarantineSku });
  }, [acceptedQuarantinesReducer]);

  const [allocateUserModal, setAllocateUserModal] = useState(false);

  const handleOpenAllocateUserModal = () => {
    setAllocateUserModal(true);
    formikForRelease.setValues(initialValuesForUser);
    formikForRelease.setTouched({});
    formikForRelease.setErrors({});
  };
  const handleCloseAllocateUserModal = () => {
    setAllocateUserModal(false);
  };
  const [isErrorForAllocate, setIsErrorForAllocate] = useState(false);
  const [isSuccessForAllocate, setIsSuccessForAllocate] = useState(false);
  
  const initialValuesForUser = {
    user: "",
  };

  const submitForUser = async (values) => {

    let payload = {
      mvtId: 3,
      documentNumber: documentNumber,
    };

    let res = await releaseQuarantine(payload, jwtToken);
    if (res.status) {
      setIsSuccessForAllocate(true);
      getAcceptedQuarantines(jwtToken);
      setTimeout(() => {
        setIsSuccessForAllocate(false);
      }, 2000);
      setTimeout(() => {
        handleCloseAllocateUserModal(false);
      }, 3000);
    }
    if (!res.status) {
      setErrorMsg(res.data.data.msg);
      setIsErrorForAllocate(true);
      setTimeout(() => {
        setIsErrorForAllocate(false);
      }, 3000);
    }
  };

  const formikForRelease = useFormik({
    initialValues: initialValuesForUser,
    onSubmit: submitForUser,
  });

  const skuStatusOptionsForQuar = [{ label: "Open", value: "0" }];

  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    let tempSuidOptions = [];
    let data = locationReducer.locations.data
      ? locationReducer.locations.data
      : [];
    data.map((suid) => {
      tempSuidOptions.push({
        label: suid.locationName,
        value: suid.locationId,
        // sLocation: suid.locationCode,
      });
    });
    setLocationOptions(tempSuidOptions);
  }, [locationReducer]);

  const [activityIdSelected, setActivityIdSelected] = useState("");

  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [selectedSkuStatus, setSelectedSkuStatus] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  useEffect(() => {
    getItemCodeByWarehouse(
      activityIdSelected?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [activityIdSelected, selectedSkuStatus]);
  useEffect(() => {
    getLocationsForQuarantine(
      selectedItemCode?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [selectedItemCode, selectedSkuStatus]);

  useEffect(() => {
    getSkuStatusByLocationAndItemCode(
      selectedLocationId?.value,
      selectedItemCode?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [selectedLocationId, selectedItemCode, selectedSkuStatus]);
  useEffect(() => {
    getSkuByItemCode(
      selectedItemCode?.value,
      selectedSkuStatus?.value,
      jwtToken
    );
  }, [selectedItemCode, selectedSkuStatus]);

  const handleActivityIdChange = (selectedActivity) => {
    setActivityIdSelected(selectedActivity);
  };

  const [itemCodeForWarehouseOptions, setItemCodeForWarehouseOptions] =
    useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = itemCodeByWarehouseReducer.itemCodes
      ? itemCodeByWarehouseReducer.itemCodes
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.itemCode,
        value: activity.itemId,
      });
    });
    setItemCodeForWarehouseOptions(tempCreatedActivityOptions);
  }, [itemCodeByWarehouseReducer]);

  const [skuStatusOptions, setSkuStatusOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getSkuStatusByLocationAndItemCodeReducer.skuStatus.data
      ? getSkuStatusByLocationAndItemCodeReducer.skuStatus.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.sku,
        value: activity.suid,
        qty: activity.qty,
      });
    });
    setSkuStatusOptions(tempCreatedActivityOptions);
  }, [getSkuStatusByLocationAndItemCodeReducer]);
  const [skuOptions, setSkuOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getSkuByItemCodeReducer.sku.data
      ? getSkuByItemCodeReducer.sku.data
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.sku,
        value: activity.suid,
        qty: activity.qty,
        location: activity.locationName,
      });
    });
    setSkuOptions(tempCreatedActivityOptions);
  }, [getSkuByItemCodeReducer]);

  const [stationTable, setStationTable] = useState({
    columns: [
      { Header: "SKU", accessor: "sku" },
      { Header: "Item Code/Des", accessor: "itemCode" },
      { Header: "Qty", accessor: "qty" },
      { Header: "LOT/Serial No", accessor: "lotSerialNo" },
      { Header: "Location", accessor: "location" },
    ],
    rows: [],
  });

  const [loading2, setLoading2] = useState(true);

  const { columns: stationColumns, rows: stationRows } = stationTable;

  useEffect(() => {
    let tempStations = [];
    let data = getSkuByDocumentNoReducer.sku
      ? getSkuByDocumentNoReducer.sku
      : [];
    setLoading2(getSkuByDocumentNoReducer.loading);
    data.map((station) => {
      let des = station?.itemDescription;
      let len = desLength;
      const stationObj = {
        sku: station.sku,
        itemCode: `${station.itemCode} / ${des ? des.substring(0, len) : ""}`,
        location: station.locationCode,
        qty: `${station.qty} ${station.uom}`,
        lotSerialNo: `${station.lotNumber ? station.lotNumber : "NA"} / ${
          station.serialNumber ? station.serialNumber : "NA"
        }`,
      };
      tempStations.push(stationObj);
    });

    // viewStations &&
    setStationTable({ ...stationTable, rows: tempStations });
  }, [getSkuByDocumentNoReducer]);

  const initialValues = {
    rejectionFrom: "",
    documentNumber: "",
    rejectionType: "itemCode",
    itemsForLocation: [
      {
        itemId: "",
        skuStatus: "",
        location: "",
        sku: "",
      },
    ],
    itemsForItemCode: [
      {
        itemId: "",
        sku: "",
        skuStatus: "",
      },
    ],
  };

  const onSubmitForCreateQuarantine = async (values, { resetForm }) => {
    if (values.rejectionType === "location") {
      let payload = {
        documentNumber: values.documentNumber,
        suids: values.itemsForLocation.map((item) => ({
          suid: item.sku,
          itemId: item.itemId,
          qty: item.qty,
        })),
      };
      let res = await createQuarantineForAcceptedList(payload, jwtToken);

      if (res.status) {
        setIsSuccessCreatedQuarantine(true);

        getAcceptedQuarantines(jwtToken);
        resetForm();

        setActivityIdSelected("");
        setTimeout(() => {
          handleCloseCreateQuarantineModal();
        }, 2500);
      }

      if (!res.status) {
        setIsErrorCreatedQuarantine(true);
        setErrorMsgForCreate(res.data.data.msg);
      }
    }
    if (values.rejectionType === "itemCode") {
      let payload = {
        documentNumber: values.documentNumber,
        suids: values.itemsForItemCode.map((item) => ({
          suid: item.sku,
          itemId: item.itemId,
          qty: item.qty,
        })),
      };

      let res = await createQuarantineForAcceptedList(payload, jwtToken);

      if (res.status) {
        setIsSuccessCreatedQuarantine(true);
        getAcceptedQuarantines(jwtToken);
        resetForm();
        setActivityIdSelected("");
        setTimeout(() => {
          handleCloseCreateQuarantineModal();
        }, 2500);
      }

      if (!res.status) {
        setIsErrorCreatedQuarantine(true);
        setErrorMsgForCreate(res.data.data.msg);
      }
    }
  };

  const [isSuccessCreatedQuarantine, setIsSuccessCreatedQuarantine] =
    useState(false);
  const [isErrorCreatedQuarantine, setIsErrorCreatedQuarantine] =
    useState(false);

  useEffect(() => {
    if (isErrorCreatedQuarantine) {
      setTimeout(() => {
        setIsErrorCreatedQuarantine(false);
      }, 3000);
    }
  }, [isErrorCreatedQuarantine]);

  useEffect(() => {
    if (isSuccessCreatedQuarantine) {
      setTimeout(() => {
        setIsSuccessCreatedQuarantine(false);
      }, 3000);
    }
  }, [isSuccessCreatedQuarantine]);

  const [createQuarantineModal, setCreateQuarantineModal] = useState(false);

  const handleCreateQuarantineModal = () => {
    setCreateQuarantineModal(true);
  };
  const handleCloseCreateQuarantineModal = () => {
    setActivityIdSelected("");
    setCreateQuarantineModal(false);
  };

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseCode,
        // key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  return (
    <>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {createQuarantine && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                {/* {createNewTransferOrder && ( */}

                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <CreateButton
                    tooltip="Create Quarantine"
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleCreateQuarantineModal();
                      getDocumentType(jwtToken);
                      getCreatedActivity(16, jwtToken);
                      getWarehouses(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
                {/* )} */}
              </MDBox>
            )}

            <MDBox pt={3}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  hideColumns={["", "asnCode", "vendorCodeName"]}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            <Modal
              open={stationModal}
              onClose={handleCloseStationModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForStationModal}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Document No:{documentNo}
                  </MDTypography>

                  <MDBox display="flex" marginLeft="auto">
                    <span>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            display: "flex",
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "auto",
                            marginBottom: "5px",
                            marginRight: "10px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseStationModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </MDBox>
                </MDBox>
                <hr style={{ marginBottom: "1rem" }} />
                <MDBox>
                  {!loading2 ? (
                    <DataTable
                      table={{ columns: stationColumns, rows: stationRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={createQuarantineModal}
              onClose={handleCloseCreateQuarantineModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreate}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Create Quarantine
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateQuarantineModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container>
                        <Formik
                          initialValues={initialValues}
                          // validationSchema={validationSchema}
                          onSubmit={onSubmitForCreateQuarantine}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div>
                                  <MDBox
                                  // style={{
                                  //   // position: "sticky",
                                  //   top: "0",
                                  //   width: "100%",
                                  //   // zIndex: 999,
                                  // }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} lg={6}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Quarantine From
                                          </MDTypography>
                                          <Select
                                            isClearable
                                            className="select-css"
                                            maxMenuHeight={90}
                                            // placeholder="Choose one..."
                                            name="rejectionFrom"
                                            onBlur={() => {
                                              formik.handleBlur({
                                                target: {
                                                  name: "rejectionFrom",
                                                },
                                              });
                                            }}
                                            value={activityIdSelected}
                                            options={warehouseOptions}
                                            onChange={(selectedOption) => {
                                              handleActivityIdChange(
                                                selectedOption
                                              );
                                              formik.setFieldValue(
                                                "rejectionFrom",
                                                selectedOption
                                              );
                                            }}
                                          />
                                          {formik.touched.rejectionFrom &&
                                            formik.errors.rejectionFrom && (
                                              <TextError
                                                msg={
                                                  formik.errors.rejectionFrom
                                                }
                                              />
                                            )}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        <MDBox
                                          mb={1}
                                          ml={1}
                                          style={{ width: "370px" }}
                                        >
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontWeight: "500" }}
                                          >
                                            Document Number
                                          </MDTypography>
                                          <Field
                                            className="form-control"
                                            type="text"
                                            name="documentNumber"
                                            fullWidth
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.documentNumber}
                                            error={
                                              formik.touched.documentNumber &&
                                              formik.errors.documentNumber &&
                                              true
                                            }
                                            inputProps={{
                                              style: {
                                                height: "14px",
                                              },
                                              maxLength: 45,
                                            }}
                                          />
                                          {formik.errors.documentNumber && (
                                            <TextError
                                              msg={formik.errors.documentNumber}
                                            />
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>

                                  <hr style={{ marginBottom: "10px" }} />

                                  <MDBox>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div>
                                        <Field
                                          type="radio"
                                          name="rejectionType"
                                          value="itemCode"
                                          className="radio"
                                        />
                                        <span>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            style={{
                                              marginLeft: "1.5rem",
                                              marginRight: "10px",
                                              fontSize: "15px",
                                              marginTop: "-1.77rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Item Code
                                          </MDTypography>
                                        </span>
                                      </div>
                                      <div>
                                        <Field
                                          type="radio"
                                          name="rejectionType"
                                          value="location"
                                          className="radio"
                                        />
                                        <span>
                                          <MDTypography
                                            variant="h6"
                                            fontWeight="medium"
                                            style={{
                                              marginLeft: "1.5rem",
                                              fontSize: "15px",
                                              marginTop: "-1.77rem",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Location
                                          </MDTypography>
                                        </span>
                                      </div>
                                    </div>
                                  </MDBox>

                                  {formik.values.rejectionType ===
                                    "itemCode" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForItemCode">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForItemCode } = values;

                                          return (
                                            <div>
                                              {itemsForItemCode.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        {/* SKU Status */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={3}
                                                        >
                                                          <MDBox>
                                                            <MDTypography variant="h6">
                                                              SKU Status
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-forSku"
                                                              maxMenuHeight={90}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "170px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                activityIdSelected
                                                                  ? false
                                                                  : true
                                                              }
                                                              options={
                                                                skuStatusOptionsForQuar
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedSkuStatus(
                                                                  selectedOption
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.skuStatus`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={skuStatusOptionsForQuar.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  selectedSkuStatus
                                                              )}
                                                              // value={itemsForItemCode.skuStatus}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Item Code */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={3}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: -10,
                                                            }}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Item Code
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-itemcode"
                                                              maxMenuHeight={75}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForItemCode[
                                                                  index
                                                                ].skuStatus
                                                              }
                                                              options={
                                                                itemCodeForWarehouseOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedItemCode(
                                                                  selectedOption
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.itemId`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={
                                                                itemsForItemCode.itemId
                                                              }
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Location */}
                                                        <Grid
                                                          item
                                                          xs={9}
                                                          sm={6}
                                                          md={4}
                                                          lg={2}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: 3,
                                                            }}
                                                            mb={1}
                                                          >
                                                            <MDTypography variant="h6">
                                                              SKU
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-sku"
                                                              maxMenuHeight={70}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "270px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForItemCode[
                                                                  index
                                                                ].itemId
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.sku`,
                                                                  selectedOption?.value
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.sLocation`,
                                                                  selectedOption?.sLocation
                                                                );
                                                                const selectedItem =
                                                                  skuOptions.find(
                                                                    (item) =>
                                                                      item.value ===
                                                                      selectedOption?.value
                                                                  );
                                                                const location =
                                                                  selectedItem
                                                                    ? selectedItem.location
                                                                    : "";
                                                                const qty =
                                                                  selectedItem
                                                                    ? selectedItem.qty
                                                                    : 0; // Get qty

                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.location`,
                                                                  location
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForItemCode.${index}.qty`,
                                                                  qty // Set qty
                                                                );
                                                              }}
                                                              options={
                                                                skuOptions
                                                              }
                                                              value={skuOptions.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  itemsForItemCode.sku
                                                              )}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* SKU */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={3}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: 17,
                                                            }}
                                                            mb={2}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Location
                                                            </MDTypography>
                                                            <Field
                                                              disabled
                                                              className="form-control"
                                                              style={{
                                                                borderRadius: 5,
                                                                height: "36px",
                                                                paddingLeft:
                                                                  "15px",
                                                                width: "155px",
                                                              }}
                                                              type="text"
                                                              name={`itemsForItemCode.${index}.location`}
                                                              value={
                                                                itemsForItemCode[
                                                                  index
                                                                ]?.location
                                                              }
                                                              fullWidth
                                                              autoComplete="off"
                                                              onChange={
                                                                formik.handleChange
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "36px",
                                                                },
                                                                maxLength: 45,
                                                                readOnly: true,
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .location &&
                                                              formik.errors
                                                                .location && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .location
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Action buttons */}
                                                        <Grid
                                                          item
                                                          xs={3}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                        >
                                                          <MDBox
                                                            display="flex"
                                                            alignItems="center"
                                                            mt={3}
                                                            ml={5}
                                                          >
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                            {index ===
                                                              itemsForItemCode.length -
                                                                1 && (
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      sku: "",
                                                                      skuStatus:
                                                                        "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            )}
                                                          </MDBox>
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>
                                  )}

                                  {formik.values.rejectionType ===
                                    "location" && (
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForLocation">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForLocation } = values;

                                          return (
                                            <div>
                                              {itemsForLocation.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={2.6}
                                                        >
                                                          <MDBox>
                                                            <MDTypography variant="h6">
                                                              SKU Status
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-forSku"
                                                              maxMenuHeight={90}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "170px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                activityIdSelected
                                                                  ? false
                                                                  : true
                                                              }
                                                              options={
                                                                skuStatusOptionsForQuar
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedSkuStatus(
                                                                  selectedOption
                                                                );

                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.skuStatus`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={skuStatusOptionsForQuar.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  selectedSkuStatus
                                                              )}
                                                            />
                                                            {formik.touched
                                                              .skuStatus &&
                                                              formik.errors
                                                                .skuStatus && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .skuStatus
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>
                                                        {/* Item Code Field */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={3.2}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: -4,
                                                              sm: -6,
                                                            }}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Item Code
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-itemcode"
                                                              maxMenuHeight={75}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForLocation[
                                                                  index
                                                                ].skuStatus
                                                              }
                                                              options={
                                                                itemCodeForWarehouseOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedItemCode(
                                                                  selectedOption
                                                                );
                                                                // getLocationsForQuarantine(selectedOption?.value, jwtToken);
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.itemId`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                              value={
                                                                itemsForLocation.itemId
                                                              }
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Location Field */}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={2.6}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: 4.5,
                                                            }}
                                                            mb={2}
                                                          >
                                                            <MDTypography variant="h6">
                                                              Location
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-forSku"
                                                              maxMenuHeight={75}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "170px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForLocation[
                                                                  index
                                                                ].itemId
                                                              }
                                                              // isDisabled={!formik.values.itemsForLocation[index].itemId}
                                                              options={
                                                                locationOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                setSelectedLocationId(
                                                                  selectedOption
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.location`,
                                                                  selectedOption?.value
                                                                );
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* SKU Field */}
                                                        <Grid
                                                          item
                                                          xs={9}
                                                          sm={6}
                                                          md={4}
                                                          lg={2.6}
                                                        >
                                                          <MDBox
                                                            ml={{
                                                              xs: 0,
                                                              sm: -1,
                                                            }}
                                                          >
                                                            <MDTypography variant="h6">
                                                              SKU
                                                            </MDTypography>
                                                            <Select
                                                              className="select-css-for-sku"
                                                              maxMenuHeight={70}
                                                              isClearable
                                                              styles={{
                                                                control: (
                                                                  provided
                                                                ) => ({
                                                                  ...provided,
                                                                  borderRadius: 5,
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "260px",
                                                                  fontSize:
                                                                    "15px",
                                                                }),
                                                              }}
                                                              isDisabled={
                                                                !formik.values
                                                                  .itemsForLocation[
                                                                  index
                                                                ].location
                                                              }
                                                              options={
                                                                skuStatusOptions
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                const selectedSku =
                                                                  skuStatusOptions.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      selectedOption?.value
                                                                  );
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.sku`,
                                                                  selectedOption?.value
                                                                );
                                                                formik.setFieldValue(
                                                                  `itemsForLocation.${index}.qty`,
                                                                  selectedSku?.qty ||
                                                                    0
                                                                ); // Update qty
                                                              }}
                                                              value={skuStatusOptions.find(
                                                                (option) =>
                                                                  option.value ===
                                                                  itemsForLocation.sku
                                                              )}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        {/* Cancel/Add Buttons */}
                                                        <Grid
                                                          item
                                                          xs={3}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                        >
                                                          <MDBox
                                                            display="flex"
                                                            alignItems="center"
                                                            mt={3}
                                                            ml={5}
                                                          >
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                            {index ===
                                                              itemsForLocation.length -
                                                                1 && (
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      itemId:
                                                                        "",
                                                                      location:
                                                                        "",
                                                                      skuStatus:
                                                                        "",
                                                                      sku: "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            )}
                                                          </MDBox>
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>
                                  )}

                                  <div
                                    style={{
                                      background: "white",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <MDButton
                                      style={{
                                        position: "sticky",
                                        bottom: "0",
                                      }}
                                      color="info"
                                      fullWidth
                                      type="submit"
                                      disabled={!formik.isValid}
                                    >
                                      Submit
                                    </MDButton>
                                  </div>
                                  <MDBox>
                                    <Collapse in={isErrorCreatedQuarantine}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorCreatedQuarantine(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        {errorMsgForCreate}
                                      </Alert>
                                    </Collapse>
                                    <Collapse in={isSuccessCreatedQuarantine}>
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessCreatedQuarantine(
                                                false
                                              );
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Quarantine Created Successfully
                                      </Alert>
                                    </Collapse>
                                  </MDBox>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={allocateUserModal}
              onClose={handleCloseAllocateUserModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                    Select The Type
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAllocateUserModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForRelease.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6" style={{ fontWeight: "500" }}>
                        Document Number:-{" "}
                        <span style={{ fontWeight: 400 }}>
                          {documentNumber}
                        </span>
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForAllocate}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForAllocate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForAllocate}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForAllocate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Picklist released Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>

                    <MDBox mt={1}>
                      <MDButton
                        color="success"
                        fullWidth
                        type="submit"
                        disabled={!formikForRelease.isValid}
                      >
                        Release
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    warehouses: state.warehouses,
    acceptedQuarantinesReducer: state.acceptedQuarantinesReducer,
    itemCodeByWarehouseReducer: state.itemCodeByWarehouseReducer,
    locationReducer: state.locationReducer,
    getSkuStatusByLocationAndItemCodeReducer:
      state.getSkuStatusByLocationAndItemCodeReducer,
    getSkuByItemCodeReducer: state.getSkuByItemCodeReducer,
    getSkuByDocumentNoReducer: state.getSkuByDocumentNoReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    createQuarantineForAcceptedList: (payload, jwtToken) =>
      dispatch(createQuarantineForAcceptedList(payload, jwtToken)),
    getAcceptedQuarantines: (jwtToken) =>
      dispatch(getAcceptedQuarantines(jwtToken)),
    getSkuStatusByLocationAndItemCode: (locationId, itemId, status, jwtToken) =>
      dispatch(
        getSkuStatusByLocationAndItemCode(locationId, itemId, status, jwtToken)
      ),
    releaseQuarantine: (payload, jwtToken) =>
      dispatch(releaseQuarantine(payload, jwtToken)),
    getItemCodeByWarehouse: (warehouseIdSelected, status, jwtToken) =>
      dispatch(getItemCodeByWarehouse(warehouseIdSelected, status, jwtToken)),
    getSkuByItemCode: (itemId, status, jwtToken) =>
      dispatch(getSkuByItemCode(itemId, status, jwtToken)),
    getLocationsForQuarantine: (itemId, status, jwtToken) =>
      dispatch(getLocationsForQuarantine(itemId, status, jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getCreatedActivity: (documentTypeSelected, jwtToken) =>
      dispatch(getCreatedActivity(documentTypeSelected, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getSkuByDocumentNo: (documentTrId, jwtToken) =>
      dispatch(getSkuByDocumentNo(documentTrId, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(AcceptedQuarantines);
