export const GET_ALLGRN_REQUEST = "GET_ALLGRN_REQUEST";
export const GET_ALLGRN_SUCCESS = "GET_ALLGRN_SUCCESS";
export const GET_ALLGRN_FAILURE = "GET_ALLGRN_FAILURE";

export const GET_LINEITEMSBYGRN_REQUEST = "GET_LINEITEMSBYGRN_REQUEST";
export const GET_LINEITEMSBYGRN_SUCCESS = "GET_LINEITEMSBYGRN_SUCCESS";
export const GET_LINEITEMSBYGRN_FAILURE = "GET_LINEITEMSBYGRN_FAILURE";

export const GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST = "GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST";
export const GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS = "GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS";
export const GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE = "GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE";

export const UPLOADCSV_FOR_GRN_REQUEST = "UPLOADCSV_FOR_GRN_REQUEST";
export const UPLOADCSV_FOR_GRN_SUCCESS = "UPLOADCSV_FOR_GRN_SUCCESS";
export const UPLOADCSV_FOR_GRN_FAILURE = "UPLOADCSV_FOR_GRN_FAILURE";


export const CREATE_GRN_REQUEST = "CREATE_GRN_REQUEST";
export const CREATE_GRN_SUCCESS = "CREATE_GRN_SUCCESS";
export const CREATE_GRN_FAILURE = "CREATE_GRN_FAILURE";

export const GET_GRNITEMS_REQUEST = "GET_GRNITEMS_REQUEST";
export const GET_GRNITEMS_SUCCESS = "GET_GRNITEMS_SUCCESS";
export const GET_GRNITEMS_FAILURE = "GET_GRNITEMS_FAILURE";
