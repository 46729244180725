import {
  GET_BOM_REQUEST,
  GET_BOM_SUCCESS,
  GET_BOM_FAILURE,
  GET_ITEMSBYFGCODE_REQUEST,
  GET_ITEMSBYFGCODE_SUCCESS,
  GET_ITEMSBYFGCODE_FAILURE,
  GET_ITEMSFORBOM_REQUEST,
  GET_ITEMSFORBOM_SUCCESS,
  GET_ITEMSFORBOM_FAILURE,
  GET_FG_BY_ASSEMBLYTYPE_REQUEST,
  GET_FG_BY_ASSEMBLYTYPE_SUCCESS,
  GET_FG_BY_ASSEMBLYTYPE_FAILURE,
  GET_ASSEMBLY_BY_ASSEMBLYTYPE_REQUEST,
  GET_ASSEMBLY_BY_ASSEMBLYTYPE_SUCCESS,
  GET_ASSEMBLY_BY_ASSEMBLYTYPE_FAILURE,
} from "./bomTypes";

const initialState = {
  loading: false,
  bom: [],
  error: "",
};

const getBomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BOM_SUCCESS:
      return {
        loading: false,
        bom: action.payload,
        error: "",
      };
    case GET_BOM_FAILURE:
      return {
        loading: false,
        bom: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForBom = {
  loading: false,
  items: [],
  error: "",
};

const getItemForBomReducer = (state = initialStateForBom, action) => {
  switch (action.type) {
    case GET_ITEMSFORBOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMSFORBOM_SUCCESS:
      return {
        loading: false,
        items: action.payload,
        error: "",
      };
    case GET_ITEMSFORBOM_FAILURE:
      return {
        loading: false,
        items: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForItem = {
  loading: false,
  items: [],
  error: "",
};

const getItemsByFgCodeReducer = (state = initialStateForItem, action) => {
  switch (action.type) {
    case GET_ITEMSBYFGCODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMSBYFGCODE_SUCCESS:
      return {
        loading: false,
        items: action.payload,
        error: "",
      };
    case GET_ITEMSBYFGCODE_FAILURE:
      return {
        loading: false,
        items: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForFG = {
  loading: false,
  fgCodes: [],
  error: "",
};
const getFgCodeByAssemblyTypeReducer = (state = initialStateForFG, action) => {
  switch (action.type) {
    case GET_FG_BY_ASSEMBLYTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FG_BY_ASSEMBLYTYPE_SUCCESS:
      return {
        loading: false,
        fgCodes: action.payload,
        error: "",
      };
    case GET_FG_BY_ASSEMBLYTYPE_FAILURE:
      return {
        loading: false,
        fgCodes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForAssembly = {
  loading: false,
  assembly: [],
  error: "",
};
const getAssemblyByAssemblyTypeReducer = (
  state = initialStateForAssembly,
  action
) => {
  switch (action.type) {
    case GET_ASSEMBLY_BY_ASSEMBLYTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSEMBLY_BY_ASSEMBLYTYPE_SUCCESS:
      return {
        loading: false,
        assembly: action.payload,
        error: "",
      };
    case GET_ASSEMBLY_BY_ASSEMBLYTYPE_FAILURE:
      return {
        loading: false,
        assembly: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getBomReducer,
  getItemsByFgCodeReducer,
  getItemForBomReducer,
  getFgCodeByAssemblyTypeReducer,
  getAssemblyByAssemblyTypeReducer
};
