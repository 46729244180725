import {
  
  GET_VENDOR_MASTER_MAPPING_REQUEST,
  GET_VENDOR_MASTER_MAPPING_SUCCESS,
  GET_VENDOR_MASTER_MAPPING_FAILURE,
  GET_LIST_OF_BIN_TYPES_REQUEST,
  GET_LIST_OF_BIN_TYPES_SUCCESS,
  GET_LIST_OF_BIN_TYPES_FAILURE

} from "./supplierPortalMastersTypes";

const initialStateForVendorMasterMapping = {
  loading: false,
  vendorMasterMappingData: {},
  error: "",
};

const vendorMasterMappingReducer = (state = initialStateForVendorMasterMapping, action) => {
  switch (action.type) {
    case GET_VENDOR_MASTER_MAPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VENDOR_MASTER_MAPPING_SUCCESS:
      return {
        loading: false,
        vendorMasterMappingData: action.payload,
        error: "",
      };
    case GET_VENDOR_MASTER_MAPPING_FAILURE:
      return {
        loading: false,
        vendorMasterMappingData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForListOfBinTypes = {
  loading: false,
  listOfBinTypesData: {},
  error: "",
};

const listOfBinTypesReducer = (state = initialStateForListOfBinTypes, action) => {
  switch (action.type) {
    case GET_LIST_OF_BIN_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_OF_BIN_TYPES_SUCCESS:
      return {
        loading: false,
        listOfBinTypesData: action.payload,
        error: "",
      };
    case GET_LIST_OF_BIN_TYPES_FAILURE:
      return {
        loading: false,
        listOfBinTypesData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};


export { vendorMasterMappingReducer, listOfBinTypesReducer };
