import {
  GET_PICKLISTTRACKING_REQUEST,
  GET_PICKLISTTRACKING_SUCCESS,
  GET_PICKLISTTRACKING_FAILURE,
  GET_GROUPEDPICKLIST_REQUEST,
  GET_GROUPEDPICKLIST_SUCCESS,
  GET_GROUPEDPICKLIST_FAILURE,
  GET_ITEMSBYPICKLIST_REQUEST,
  GET_ITEMSBYPICKLIST_SUCCESS,
  GET_ITEMSBYPICKLIST_FAILURE,
  CREATE_PICKLIST__REQUEST,
  CREATE_PICKLIST__SUCCESS,
  CREATE_PICKLIST__FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_ALLOCATE_USER_REQUEST,
  UPDATE_ALLOCATE_USER_SUCCESS,
  UPDATE_ALLOCATE_USER_FAILURE,
  GET_DOCUMENT_NUMBER_REQUEST,
  GET_DOCUMENT_NUMBER_SUCCESS,
  GET_DOCUMENT_NUMBER_FAILURE,
  GET_ITEM_BY_DOCUMENTNO_REQUEST,
  GET_ITEM_BY_DOCUMENTNO_SUCCESS,
  GET_ITEM_BY_DOCUMENTNO_FAILURE,
  GET_AVAILABLE_QTY_REQUEST,
  GET_AVAILABLE_QTY_SUCCESS,
  GET_AVAILABLE_QTY_FAILURE,
} from "./picklistTrackingTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getPickListTrackingRequest = () => {
  return {
    type: GET_PICKLISTTRACKING_REQUEST,
  };
};

const getPickListTrackingSuccess = (payload) => {
  return {
    type: GET_PICKLISTTRACKING_SUCCESS,
    payload: payload,
  };
};

const getPickListTrackingFailure = (error) => {
  return {
    type: GET_PICKLISTTRACKING_FAILURE,
    payload: error,
  };
};

const getPickListTracking = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPickListTrackingRequest());
    let url = `${baseUrl}/picklist`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPickListTrackingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPickListTrackingFailure(err.response));
      });
  };
};

const getGroupedPickListRequest = () => {
  return {
    type: GET_GROUPEDPICKLIST_REQUEST,
  };
};

const getGroupedPickListSuccess = (payload) => {
  return {
    type: GET_GROUPEDPICKLIST_SUCCESS,
    payload: payload,
  };
};

const getGroupedPickListFailure = (error) => {
  return {
    type: GET_GROUPEDPICKLIST_FAILURE,
    payload: error,
  };
};

const getGroupedPickList = (pageNo, rowsPerPage, search, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getGroupedPickListRequest());
    let url = `${baseUrl}/picklist/grouped`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getGroupedPickListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getGroupedPickListFailure(err.response));
      });
  };
};

const getItemsByPickListRequest = () => {
  return {
    type: GET_ITEMSBYPICKLIST_REQUEST,
  };
};

const getItemsByPickListSuccess = (payload) => {
  return {
    type: GET_ITEMSBYPICKLIST_SUCCESS,
    payload: payload,
  };
};

const getItemsByPickListFailure = (error) => {
  return {
    type: GET_ITEMSBYPICKLIST_FAILURE,
    payload: error,
  };
};

const getItemsByPickList = (picklistId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsByPickListRequest());
    let url = `${baseUrl}/picklist/item?picklistId=${picklistId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsByPickListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsByPickListFailure(err.message));
      });
  };
};

const createPickListRequest = () => {
  return {
    type: CREATE_PICKLIST__REQUEST,
  };
};

const createPickListSuccess = (payload) => {
  return {
    type: CREATE_PICKLIST__SUCCESS,
    payload: payload,
  };
};

const createPickListFailure = (error) => {
  return {
    type: CREATE_PICKLIST__FAILURE,
    payload: error,
  };
};

const createPickList = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPickListRequest());
    let url = `${baseUrl}/picklist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPickListSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPickListFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getUserRequest = () => {
  return {
    type: GET_USER_REQUEST,
  };
};

const getUserSuccess = (payload) => {
  return {
    type: GET_USER_SUCCESS,
    payload: payload,
  };
};

const getUserFailure = (error) => {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  };
};

const getUser = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUserRequest());
    let url = `${baseUrl}/user/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUserSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUserFailure(err.message));
      });
  };
};

const releaseUserRequest = () => {
  return {
    type: UPDATE_ALLOCATE_USER_REQUEST,
  };
};

const releaseUserSuccess = (payload) => {
  return {
    type: UPDATE_ALLOCATE_USER_SUCCESS,
    payload: payload,
  };
};

const releaseUserFaliure = (error) => {
  return {
    type: UPDATE_ALLOCATE_USER_FAILURE,
    payload: error,
  };
};

const releaseUser = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(releaseUserRequest());
    let url = `${baseUrl}/picklist/allocate_user`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(releaseUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(releaseUserFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getDocumentNumberRequest = () => {
  return {
    type: GET_DOCUMENT_NUMBER_REQUEST,
  };
};

const getDocumentNumberSuccess = (payload) => {
  return {
    type: GET_DOCUMENT_NUMBER_SUCCESS,
    payload: payload,
  };
};

const getDocumentNumberFailure = (error) => {
  return {
    type: GET_DOCUMENT_NUMBER_FAILURE,
    payload: error,
  };
};

const getDocumentNumber = (documentType, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDocumentNumberRequest());
    let url = `${baseUrl}/document_tr?document_type_id=${documentType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDocumentNumberSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDocumentNumberFailure(err.message));
      });
  };
};
const getItemByDocumentNoRequest = () => {
  return {
    type: GET_ITEM_BY_DOCUMENTNO_REQUEST,
  };
};

const getItemByDocumentNoSuccess = (payload) => {
  return {
    type: GET_ITEM_BY_DOCUMENTNO_SUCCESS,
    payload: payload,
  };
};

const getItemByDocumentNoFailure = (error) => {
  return {
    type: GET_ITEM_BY_DOCUMENTNO_FAILURE,
    payload: error,
  };
};

const getItemByDocumentNo = (documentNo, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemByDocumentNoRequest());
    let url = `${baseUrl}/document_tr/get_by_document_no?document_no=${documentNo}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemByDocumentNoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemByDocumentNoFailure(err.message));
      });
  };
};

const getAvailableQtyRequest = () => {
  return {
    type: GET_AVAILABLE_QTY_REQUEST,
  };
};

const getAvailableQtySuccess = (payload) => {
  return {
    type: GET_AVAILABLE_QTY_SUCCESS,
    payload: payload,
  };
};

const getAvailableQtyFailure = (error) => {
  return {
    type: GET_AVAILABLE_QTY_FAILURE,
    payload: error,
  };
};

const getAvailableQty = (itemId, mvtId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAvailableQtyRequest());
    let url = `${baseUrl}/picklist/available_qty?item_id=${itemId}&mvt_id=${mvtId}&status=1`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAvailableQtySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAvailableQtyFailure(err.response));
      });
  };
};

export {
  getPickListTracking,
  getGroupedPickList,
  getItemsByPickList,
  createPickList,
  getUser,
  releaseUser,
  getDocumentNumber,
  getItemByDocumentNo,
  getAvailableQty,
};
