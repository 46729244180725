export const GET_MAPPED_ACTIVITY_REQUEST = "GET_MAPPED_ACTIVITY_REQUEST";
export const GET_MAPPED_ACTIVITY_SUCCESS = "GET_MAPPED_ACTIVITY_SUCCESS";
export const GET_MAPPED_ACTIVITY_FAILURE = "GET_MAPPED_ACTIVITY_FAILURE";

export const CREATE_ACTIVITY_MAPPING_REQUEST =
  "CREATE_ACTIVITY_MAPPING_REQUEST";
export const CREATE_ACTIVITY_MAPPING_SUCCESS =
  "CREATE_ACTIVITY_MAPPING_SUCCESS";
export const CREATE_ACTIVITY_MAPPING_FAILURE =
  "CREATE_ACTIVITY_MAPPING_FAILURE";

export const REMOVE_ITEMALLOCATION_REQUEST = "REMOVE_ITEMALLOCATION_REQUEST";
export const REMOVE_ITEMALLOCATION_SUCCESS = "REMOVE_ITEMALLOCATION_SUCCESS";
export const REMOVE_ITEMALLOCATION_FAILURE = "REMOVE_ITEMALLOCATION_FAILURE";

export const DELETE_ITEMS_REQUEST = "DELETE_ITEMS_REQUEST";
export const DELETE_ITEMS_SUCCESS = "DELETE_ITEMS_SUCCESS";
export const DELETE_ITEMS_FAILURE = "DELETE_ITEMS_FAILURE";

export const EDIT_ITEMALLOCATION_REQUEST = "EDIT_ITEMALLOCATION_REQUEST";
export const EDIT_ITEMALLOCATION_SUCCESS = "EDIT_ITEMALLOCATION_SUCCESS";
export const EDIT_ITEMALLOCATION_FAILURE = "EDIT_ITEMALLOCATION_FAILURE";

export const EDIT_ITEMS_REQUEST = "EDIT_ITEMS_REQUEST";
export const EDIT_ITEMS_SUCCESS = "EDIT_ITEMS_SUCCESS";
export const EDIT_ITEMS_FAILURE = "EDIT_ITEMS_FAILURE";

export const CREATE_UNPLANNED_SUCCESS = "CREATE_UNPLANNED_SUCCESS";
export const CREATE_UNPLANNED_REQUEST = "CREATE_UNPLANNED_REQUEST";
export const CREATE_UNPLANNED_FAILURE = "CREATE_UNPLANNED_FAILURE";

export const GET_LEVEL1_BY_LEVEL_REQUEST = "GET_LEVEL1_BY_LEVEL_REQUEST";
export const GET_LEVEL1_BY_LEVEL_SUCCESS = "GET_LEVEL1_BY_LEVEL_SUCCESS";
export const GET_LEVEL1_BY_LEVEL_FAILURE = "GET_LEVEL1_BY_LEVEL_FAILURE";

export const GET_LEVEL2_BY_LEVEL_REQUEST = "GET_LEVEL2_BY_LEVEL_REQUEST";
export const GET_LEVEL2_BY_LEVEL_SUCCESS = "GET_LEVEL2_BY_LEVEL_SUCCESS";
export const GET_LEVEL2_BY_LEVEL_FAILURE = "GET_LEVEL2_BY_LEVEL_FAILURE";

export const GET_LEVEL3_BY_LEVEL_REQUEST = "GET_LEVEL3_BY_LEVEL_REQUEST";
export const GET_LEVEL3_BY_LEVEL_SUCCESS = "GET_LEVEL3_BY_LEVEL_SUCCESS"; 
export const GET_LEVEL3_BY_LEVEL_FAILURE = "GET_LEVEL3_BY_LEVEL_FAILURE";

export const GET_LEVEL4_BY_LEVEL_REQUEST = "GET_LEVEL4_BY_LEVEL_REQUEST";
export const GET_LEVEL4_BY_LEVEL_SUCCESS = "GET_LEVEL4_BY_LEVEL_SUCCESS";
export const GET_LEVEL4_BY_LEVEL_FAILURE = "GET_LEVEL4_BY_LEVEL_FAILURE";

export const GET_LEVEL1_BY_LEVELSRC_REQUEST = "GET_LEVEL1_BY_LEVELSRC_REQUEST";
export const GET_LEVEL1_BY_LEVELSRC_SUCCESS = "GET_LEVEL1_BY_LEVELSRC_SUCCESS";
export const GET_LEVEL1_BY_LEVELSRC_FAILURE = "GET_LEVEL1_BY_LEVELSRC_FAILURE";

export const GET_LEVEL2_BY_LEVELSRC_REQUEST = "GET_LEVEL2_BY_LEVELSRC_REQUEST";
export const GET_LEVEL2_BY_LEVELSRC_SUCCESS = "GET_LEVEL2_BY_LEVELSRC_SUCCESS";
export const GET_LEVEL2_BY_LEVELSRC_FAILURE = "GET_LEVEL2_BY_LEVELSRC_FAILURE";

export const GET_LEVEL3_BY_LEVELSRC_REQUEST = "GET_LEVEL3_BY_LEVELSRC_REQUEST";
export const GET_LEVEL3_BY_LEVELSRC_SUCCESS = "GET_LEVEL3_BY_LEVELSRC_SUCCESS";
export const GET_LEVEL3_BY_LEVELSRC_FAILURE = "GET_LEVEL3_BY_LEVELSRC_FAILURE";

export const GET_LEVEL4_BY_LEVELSRC_REQUEST = "GET_LEVEL4_BY_LEVELSRC_REQUEST";
export const GET_LEVEL4_BY_LEVELSRC_SUCCESS = "GET_LEVEL4_BY_LEVELSRC_SUCCESS";
export const GET_LEVEL4_BY_LEVELSRC_FAILURE = "GET_LEVEL4_BY_LEVELSRC_FAILURE";

export const GET_ACN_REQUEST = "GET_ACN_REQUEST";
export const GET_ACN_SUCCESS = "GET_ACN_SUCCESS";
export const GET_ACN_FAILURE = "GET_ACN_FAILURE";

export const UPLOADCSV_ITEM_REQUEST = "UPLOADCSV_ITEM_REQUEST";
export const UPLOADCSV_ITEM_SUCCESS = "UPLOADCSV_ITEM_SUCCESS";
export const UPLOADCSV_ITEM_FAILURE = "UPLOADCSV_ITEM_FAILURE";
