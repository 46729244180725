import {
  GET_COMPLETED_TRANSACTION_FAILURE,
  GET_COMPLETED_TRANSACTION_REQUEST,
  GET_COMPLETED_TRANSACTION_SUCCESS,
  GET_REJECTED_TRANSACTION_FAILURE,
  GET_REJECTED_TRANSACTION_REQUEST,
  GET_REJECTED_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
} from "./transactionTypes";

const initialStateForGetTransaction = {
  loading: false,
  transactions: [],
  error: "",
};

const getTransactionsReducer = (
  state = initialStateForGetTransaction,
  action
) => {
  switch (action.type) {
    case GET_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactions: action.payload,
        error: "",
      };
    case GET_TRANSACTION_FAILURE:
      return {
        loading: false,
        transactions: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForGetCompletedTransaction = {
  loading: false,
  transactions: [],
  error: "",
};

const getCompletedTransactionsReducer = (
  state = initialStateForGetCompletedTransaction,
  action
) => {
  switch (action.type) {
    case GET_COMPLETED_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLETED_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactions: action.payload,
        error: "",
      };
    case GET_COMPLETED_TRANSACTION_FAILURE:
      return {
        loading: false,
        transactions: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRejectedTransactions = {
  loading: false,
  rejectedTransactions: [],
  error: "",
};

const getRejectedTransactionsReducer = (
  state = initialStateForRejectedTransactions,
  action
) => {
  switch (action.type) {
    case GET_REJECTED_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REJECTED_TRANSACTION_SUCCESS:
      return {
        loading: false,
        rejectedTransactions: action.payload,
        error: "",
      };
    case GET_REJECTED_TRANSACTION_FAILURE:
      return {
        loading: false,
        rejectedTransactions: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  purchase: {},
  error: "",
};

// const createPurchaseOrderReducer = (state = initialStateForCreate, action) => {
//   switch (action.type) {
//     case CREATE_PURCHASE_ORDER_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATE_PURCHASE_ORDER_SUCCESS:
//       return {
//         loading: false,
//         purchase: action.payload,
//         error: "",
//       };
//     case CREATE_PURCHASE_ORDER_FAILURE:
//       return {
//         loading: false,
//         purchase: {},
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

export {
  getTransactionsReducer,
//   createPurchaseOrderReducer,
getCompletedTransactionsReducer,
  getRejectedTransactionsReducer,
};
