export const GET_BIN_TYPES_REQUEST = "GET_BIN_TYPES_REQUEST";
export const GET_BIN_TYPES_SUCCESS = "GET_BIN_TYPES_SUCCESS";
export const GET_BIN_TYPES_FAILURE = "GET_BIN_TYPES_FAILURE";

export const CREATE_BIN_TYPE_REQUEST = "CREATE_BIN_TYPE_REQUEST";
export const CREATE_BIN_TYPE_SUCCESS = "CREATE_BIN_TYPE_SUCCESS";
export const CREATE_BIN_TYPE_FAILURE = "CREATE_BIN_TYPE_FAILURE";

export const DELETE_BIN_REQUEST = "DELETE_BIN_REQUEST";
export const DELETE_BIN_SUCCESS = "DELETE_BIN_SUCCESS";
export const DELETE_BIN_FAILURE = "DELETE_BIN_FAILURE";

export const EDIT_BIN_REQUEST = "EDIT_BIN_REQUEST";
export const EDIT_BIN_SUCCESS = "EDIT_BIN_SUCCESS";
export const EDIT_BIN_FAILURE = "EDIT_BIN_FAILURE";

export const GET_BINS_REQUEST = "GET_BINS_REQUEST";
export const GET_BINS_SUCCESS = "GET_BINS_SUCCESS";
export const GET_BINS_FAILURE = "GET_BINS_FAILURE";

export const CREATE_BIN_REQUEST = "CREATE_BINS_REQUEST";
export const CREATE_BIN_SUCCESS = "CREATE_BINS_SUCCESS";
export const CREATE_BIN_FAILURE = "CREATE_BINS_FAILURE";

export const UPLOAD_EXCEL_FOR_BINMASTER_REQUEST =
  "UPLOAD_EXCEL_FOR_BINMASTER_REQUEST";
export const UPLOAD_EXCEL_FOR_BINMASTER_SUCCESS =
  "UPLOAD_EXCEL_FOR_BINMASTER_SUCCESS";
export const UPLOAD_EXCEL_FOR_BINMASTER_FAILURE =
  "UPLOAD_EXCEL_FOR_BINMASTER_FAILURE";

export const PRINT_BIN_REQUEST = "PRINT_BIN_REQUEST";
export const PRINT_BIN_SUCCESS = "PRINT_BIN_SUCCESS";
export const PRINT_BIN_FAILURE = "PRINT_BIN_FAILURE";
