/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import AddCircleIcon from "@mui/icons-material/AddCircle";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// React-Select component and styling
import Select from "react-select";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSalesOrder,
  getVinStatusByTripNo,
  approveVin,
  releaseTrip,
  getAccessoriesByVin,
  getVinByFgCode,
  changeVin,
  getSubModulesByRoleId,
  createDispatch,
  getFgCode,
} from "../../store";

//  some date function
import { format, addMinutes } from "date-fns";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";
// Formik and Yup
import { Formik, Form, Field, FieldArray } from "formik";
// import { useFormik } from "formik";
// import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Clear";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import ReleaseButton from "components/standardized-components/ReleaseButton";

const cookies = new Cookies();

const desLength = Config.descriptionLength;
const styleForCreate = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 920 },
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
  overflow: "scroll",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1200 },
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForChangeVin = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 2,
};

const releaseStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const Dispatch = ({
  getSalesOrder,
  getFgCode,
  salesOrder,
  getVinStatusByTripNo,
  vinStatus,
  releaseTrip,
  getVinByFgCode,
  getVinByFgCodeReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getFgCodeReducer,
  createDispatch,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let dispatchModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "4bab8b02-b040-488f-bbb7-b692184a7b34"
  );

  let viewAccess = dispatchModule
    ? dispatchModule.actionId.includes("d95c7815-09bb-4614-aaca-c272f9715f87")
    : null;

  let createAccess = dispatchModule
    ? dispatchModule.actionId.includes("16690d8d-4fd7-4d2e-ae17-8892f99aceb8")
    : null;

  let releaseAccess = dispatchModule
    ? dispatchModule.actionId.includes("57736bae-a714-4b82-b6e9-1cce12333d87")
    : null;

  let replaceVinAccess = dispatchModule
    ? dispatchModule.actionId.includes("427fb28b-460f-41f4-93a0-bb2485ed4998")
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Sales Order No", accessor: "salesOrderNumber" },
      { Header: "Trip No", accessor: "tripNo" },

      { Header: "Status", accessor: "status" },
      { Header: "Qty", accessor: "qty" },
      { Header: "UOM", accessor: "uom" },
      {
        Header: "Date & Time",
        accessor: "dateTime",
        align: "left",
        searchType: "date",
        Cell: ({ value }) => {
          return value
            ? format(
                addMinutes(
                  new Date(value),
                  new Date(value).getTimezoneOffset()
                ),
                "dd/MM/yyyy, HH:mm"
              )
            : "";
        },
      },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [vinTable, setVinTable] = useState({
    columns: [
      { Header: "VIN", accessor: "vin" },
      { Header: "FG Code", accessor: "fgCode" },
      { Header: "Trip Line No", accessor: "tripLineNo" },
      { Header: "SO/Line", accessor: "soLine" },
      { Header: "Customer Name", accessor: "customerName" },

      { Header: "Status", accessor: "status" },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = vinTable;

  useEffect(() => {
    getSalesOrder(jwtToken);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempSalesOrder = [];
    let data = salesOrder.salesOrder ? salesOrder.salesOrder : [];
    setLoading(salesOrder.loading);
    data.map((order) => {
      const formatDate = new Date(order.cd);
      const orderData = {
        salesOrderId: order.salesOrderId,
        salesOrderNumber: order.salesOrderNumber,
        tripNo: order.tripNo,
        status: (
          <button
            type="button"
            className={
              (order.status === 0 && `status-created`) ||
              (order.status === 1 && `status-error`) ||
              (order.status === 3 && `status-success`) ||
              (order.status === 2 && `status-warning`)
            }
            style={{ width: "7rem", cursor: "pointer" }}
            onClick={() => {
              setSalesOrderId(order.salesOrderId);
              setSalesOrderNo(order.salesOrderNumber);
              setSalesOrderDetails(order);
              handleOpenVinModal(order);
            }}
          >
            {(order.status === 0 && `Created`) ||
              (order.status === 1 && `Not Picked`) ||
              (order.status === 2 && `Picked`) ||
              (order.status === 3 && `Delivered`)}
          </button>
        ),
        qty: `${order.qty}`,
        uom: `${order.uom}`,
        dateTime: order.cd,
        action: (
          <div>
            {releaseAccess && (
              <ReleaseButton
                tooltip="Release"
                disabled={
                  (order.isReleasable === 0 && order.releaseStatus === 0) ||
                  (order.isReleasable === 1 && order.releaseStatus === 1)
                }
                variant="gradient"
                color="info"
                iconOnly
                type="button"
                onClick={() => handleOpenReleaseModal(order)}
                style={{ marginRight: "5px" }}
              >
                <Icon>forward</Icon>
              </ReleaseButton>
            )}
          </div>
        ),
      };
      tempSalesOrder.push(orderData);
    });
    viewAccess && setState({ ...state, rows: tempSalesOrder });
  }, [salesOrder]);

  const [vinModal, setOpenVinModal] = useState(false);

  const handleOpenVinModal = (order) => {
    getVinStatusByTripNo(order.salesOrderId, jwtToken);
    setTimeout(() => {
      setOpenVinModal(true);
    }, 200);
  };
  const handleCloseVinModal = () => {
    setOpenVinModal(false);
  };

  const [salesOrderId, setSalesOrderId] = useState("");
  const [salesOrderNo, setSalesOrderNo] = useState("");
  const [salesOrderDetails, setSalesOrderDetails] = useState("");

  useEffect(() => {
    let tempVin = [];
    let data = vinStatus.vins ? vinStatus.vins : [];

    data.map((vin) => {
      let des = vin?.description;
      let len = desLength;
      const vinInfo = {
        vin: vin.vin,
        fgCode: vin.fgCode,
        tripLineNo: vin.tripNo,
        soLine: `${vin.salesOrderNumber} / ${vin.salesOrderLineNumber}`,
        customerName: vin.customerName,
        action: (
          <Tooltip title="Replace Vin">
            <MDButton
              disabled={vin.status > 1 || !replaceVinAccess}
              style={{ marginLeft: "0.5rem" }}
              type="button"
              variant="gradient"
              color="success"
              iconOnly
              onClick={() => {
                setFgCode(vin.fgCode);
                handleOpenChangeVinModal(vin);
                getVinByFgCode(vin.fgCode, jwtToken);
              }}
            >
              {" "}
              <Icon>find_replace</Icon>
            </MDButton>
          </Tooltip>
        ),
        status: (
          <button
            className={
              (vin.status === 0 && `status-created`) ||
              (vin.status === 1 && `status-error`) ||
              (vin.status === 3 && `status-success`) ||
              (vin.status === 2 && `status-warning`)
            }
            style={{ width: "5.5rem" }}
          >
            {(vin.status === 0 && `Created`) ||
              (vin.status === 1 && `Not Picked`) ||
              (vin.status === 2 && `Picked`) ||
              (vin.status === 3 && `Delivered`)}
          </button>
        ),
      };
      tempVin.push(vinInfo);
    });
    setVinTable({
      ...vinTable,
      rows: tempVin,
    });
  }, [vinStatus]);

  const [fgCode, setFgCode] = useState("");

  useEffect(() => {}, [fgCode]);

  const [errorMsg, setErrorMsg] = useState("");

  const [pickListId, setPickListId] = useState("");

  const [changeVinModal, setChangeVinModal] = useState(false);
  const [suidData, setSuidData] = useState(false);
  const [selectedVin, setSelectedVin] = useState("");

  const handleVinChange = (selectedUser) => {
    setSelectedVin(selectedUser);
  };
  const [vinsOptions, setVinsOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getVinByFgCodeReducer.vin ? getVinByFgCodeReducer.vin : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.vin,
        value: document.vin,
      });
    });
    setVinsOptions(tempDocumentTypeOptions);
  }, [getVinByFgCodeReducer]);

  const handleOpenChangeVinModal = (vin) => {
    setSelectedVin({
      label: vin.vin,
      value: vin.vin,
    });

    setChangeVinModal(true);
    formik.setValues(initialValuesForChangeVin);
    formik.setTouched({});
    formik.setErrors({});
  };
  const handleCloseChangeVinModal = () => {
    setChangeVinModal(false);
    setSelectedVin("");
  };

  const [isErrorForChangeVin, setIsErrorForChangeVin] = useState(false);
  const [isSuccessForChangeVin, setIsSuccessForChangeVin] = useState(false);

  const initialValuesForChangeVin = {
    vin: "",
  };

  const submitForChangeVin = async (values) => {
    let payload = {
      salesOrderId: singleReleaseData.salesOrderId,
      vin: selectedVin?.value,
    };

    let res = await changeVin(payload, jwtToken);
    if (res.status) {
      setIsSuccessForChangeVin(true);
      getSalesOrder(jwtToken);
      setTimeout(() => {
        setIsSuccessForChangeVin(false);
      }, 3000);
    }
    if (!res.status) {
      setErrorMsg(res.data.data.msg);
      setIsErrorForChangeVin(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValuesForChangeVin,
    onSubmit: submitForChangeVin,
    enableReinitialize: true,
  });
  const [isErrorRelease, setIsErrorRelease] = useState(false);
  const [isSuccessRelease, setIsSuccessRelease] = useState(false);

  useEffect(() => {
    if (isErrorRelease) {
      setTimeout(() => {
        setIsErrorRelease(false);
      }, 3000);
    }
  }, [isErrorRelease]);

  useEffect(() => {
    if (isSuccessRelease) {
      setTimeout(() => {
        setIsSuccessRelease(false);
      }, 3000);
    }
  }, [isSuccessRelease]);

  const [singleReleaseData, setSingleReleaseData] = useState({});

  const [openReleaseModal, setOpenReleaseModal] = useState(false);

  const handleOpenReleaseModal = (asset = {}) => {
    setSingleReleaseData(asset);
    setOpenReleaseModal(true);
  };
  const handleCloseReleaseModal = () => {
    setOpenReleaseModal(false);
  };
  const [isErrorMsgForRelease, setIsErrorMsgForRelease] = useState(false);

  const releaseData = async () => {
    let payload = {
      salesOrderId: singleReleaseData.salesOrderId,
    };

    let response = await releaseTrip(payload, jwtToken);

    if (response.status) {
      setIsSuccessRelease(true);
      getSalesOrder(jwtToken);

      setTimeout(() => {
        handleCloseReleaseModal();
      }, 2250);
    }
    if (!response.status) {
      setIsErrorRelease(true);
      setIsErrorMsgForRelease(
        response.data.data.msg
          ? response.data.data.msg
          : response.data.data.message
      );
    }
  };
  const [createDispatchModal, setCreateDispatchModal] = useState(false);
  const handleCreateDispatchModal = () => {
    setCreateDispatchModal(!createDispatchModal);
  };

  const initialValues = {
    tripNumber: "",
    tripLineNumber: "",
    itemsForDispatch: [
      {
        salesOrderNumber: "",
        salesOrderLineNumber: "",
        qty: "",
        uom: "",
        customerName: "",
        fgModelId: "",
      },
    ],
  };
  const validationSchema = Yup.object({
    tripNumber: Yup.string().required("Enter the trip no!"),
    tripLineNumber: Yup.string().required("Enter the tripLine no!"),
    itemsForDispatch: Yup.array().of(
      Yup.object({
        qty: Yup.number()
          .min(0, "Quantity cannot be negative")
          .required("Quantity is required"),
      })
    ),
  });
  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const [isSuccessCreatedDispatch, setIsSuccessCreatedDispatch] =
    useState(false);
  const [isErrorCreatedDispatch, setIsErrorCreatedDispatch] = useState(false);

  useEffect(() => {
    if (isErrorCreatedDispatch) {
      setTimeout(() => {
        setIsErrorCreatedDispatch(false);
      }, 3000);
    }
  }, [isErrorCreatedDispatch]);

  useEffect(() => {
    if (isSuccessCreatedDispatch) {
      setTimeout(() => {
        setIsSuccessCreatedDispatch(false);
      }, 3000);
    }
  }, [isSuccessCreatedDispatch]);

  const [fgCodeOptions, setFgCodeOptions] = useState([]);
  useEffect(() => {
    let tempfgCodeOptions = [];
    let data = getFgCodeReducer.fgCodes ? getFgCodeReducer.fgCodes : [];

    data.map((warehouse) => {
      tempfgCodeOptions.push({
        label: warehouse.fgModelCode,
        value: warehouse.fgModelId,
        uom: warehouse.uom,
      });
    });
    setFgCodeOptions(tempfgCodeOptions);
  }, [getFgCodeReducer]);
  const [sWarehouseSelected, setSwarehouseSelected] = useState("");

  const handleSwarehouseChange = (selectedOption) => {
    setSwarehouseSelected(selectedOption);
  };
  const onSubmitForCreateDispatch = async (values, { resetForm }) => {
    let payload = {
      tripNumber: values.tripNumber,
      tripLineNumber: values.tripLineNumber,
      items: values.itemsForDispatch,
    };

    let res = await createDispatch(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreatedDispatch(true);

      getSalesOrder(jwtToken);
      resetForm();
      setTimeout(() => {
        handleCreateDispatchModal();
      }, 2500);
    }

    if (!res.status) {
      setIsErrorCreatedDispatch(true);
      setErrorMsgForCreate(res.data.data.msg);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <CreateButton
                    tooltip="Create Dispatch"
                    disabled={!createAccess}
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={(event) => {
                      handleCreateDispatchModal();
                      getFgCode(jwtToken);
                    }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
                {/* } */}
              </MDBox>
              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
              <Modal
                open={vinModal}
                onClose={handleCloseVinModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", lg: "row" }}
                      spacing={10}
                      mb={2}
                    >
                      <MDBox display="flex" flexDirection="column">
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                          fontSize={{ xs: "14px", lg: "18px" }}
                        >
                          Sales Order No:-{" "}
                          <span style={{ fontWeight: 400 }}>
                            {salesOrderNo}
                          </span>
                        </MDTypography>
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                          fontSize={{ xs: "14px", lg: "18px" }}
                        >
                          Customer Name:-{" "}
                          <span style={{ fontWeight: 400 }}>
                            {salesOrderDetails?.customerName}
                          </span>
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginLeft={{ xs: "-0.5px", lg: "auto" }}
                        marginRight={{ xs: "0px", lg: "-800px" }}
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                          fontSize={{ xs: "14px", lg: "18px" }}
                        >
                          Fg Code:-{" "}
                          <span style={{ fontWeight: 400 }}>
                            {salesOrderDetails?.fgCode}
                          </span>
                        </MDTypography>
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                          fontSize={{ xs: "14px", lg: "18px" }}
                        >
                          Trip Line No:-{" "}
                          <span style={{ fontWeight: 400 }}>
                            {salesOrderDetails?.tripNo}
                          </span>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox></MDBox>
                    <MDBox display="flex" marginLeft="auto">
                      <span>
                        <Tooltip title="Close">
                          <IconButton
                            style={{
                              display: "flex",
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "auto",
                              marginBottom: "5px",
                              marginRight: "10px",
                            }}
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseVinModal}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginBottom: "1rem" }} />
                  <MDBox>
                    <DataTable
                      table={{ columns: itemColumns, rows: itemRows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={changeVinModal}
                onClose={handleCloseChangeVinModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForChangeVin}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Replace Vin
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseChangeVinModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={1}>
                        <MDTypography
                          variant="h6"
                          style={{ fontWeight: "500" }}
                        >
                          {" "}
                          Vin
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="vin"
                          onBlur={() => {
                            formik.handleBlur({
                              target: { name: "vin" },
                            });
                          }}
                          value={selectedVin}
                          options={vinsOptions}
                          onChange={(selectedOption) => {
                            handleVinChange(selectedOption);
                            formik.setFieldValue("vin", selectedOption);
                          }}
                        />
                        {formik.touched.vin && formik.errors.vin && (
                          <TextError msg={formik.errors.vin} />
                        )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForChangeVin}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForChangeVin(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForChangeVin}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForChangeVin(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Vin Changed Successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={3}>
                        <MDButton
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openReleaseModal}
                onClose={handleCloseReleaseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={releaseStyle}>
                  <MDTypography>Are you sure you want to Release?</MDTypography>
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "1.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseReleaseModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="info"
                        onClick={releaseData}
                      >
                        Release
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessRelease}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessRelease(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Trip Releaseed successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorRelease}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorRelease(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {isErrorMsgForRelease}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={createDispatchModal}
                onClose={handleCreateDispatchModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForCreate}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create Trip
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCreateDispatchModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox>
                      <MDBox>
                        <Grid container>
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmitForCreateDispatch}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  <div>
                                    <MDBox>
                                      <Grid container>
                                        <Grid item xs={10} lg={6}>
                                          <MDBox
                                            mb={1}
                                            ml={1}
                                            style={{
                                              width: {
                                                xs: "200px",
                                                lg: "370px",
                                              },
                                            }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{ fontWeight: "500" }}
                                            >
                                              Trip No
                                            </MDTypography>
                                            <Field
                                              className="form-control"
                                              type="text"
                                              name="tripNumber"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={formik.handleChange}
                                              value={formik.values.tripNumber}
                                              error={
                                                formik.touched.tripNumber &&
                                                formik.errors.tripNumber &&
                                                true
                                              }
                                              inputProps={{
                                                style: { height: "14px" },
                                                maxLength: 45,
                                              }}
                                            />
                                            {formik.touched.tripNumber &&
                                              formik.errors.tripNumber && (
                                                <TextError
                                                  msg={formik.errors.tripNumber}
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={10} lg={6}>
                                          <MDBox
                                            mb={1}
                                            ml={1}
                                            style={{
                                              width: {
                                                xs: "200px",
                                                lg: "370px",
                                              },
                                            }}
                                          >
                                            <MDTypography
                                              variant="h6"
                                              style={{ fontWeight: "500" }}
                                            >
                                              Trip LineNumberNo
                                            </MDTypography>
                                            <Field
                                              className="form-control"
                                              type="text"
                                              name="tripLineNumber"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={formik.handleChange}
                                              value={
                                                formik.values.tripLineNumber
                                              }
                                              error={
                                                formik.touched.tripLineNumber &&
                                                formik.errors.tripLineNumber &&
                                                true
                                              }
                                              inputProps={{
                                                style: {
                                                  height: "14px",
                                                },
                                                maxLength: 45,
                                              }}
                                            />
                                            {formik.touched.tripLineNumber &&
                                              formik.errors.tripLineNumber && (
                                                <TextError
                                                  msg={
                                                    formik.errors.tripLineNumber
                                                  }
                                                />
                                              )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </MDBox>
                                    <MDBox>
                                      <MDTypography variant="h5">
                                        Items
                                      </MDTypography>
                                      <hr style={{ marginBottom: "10px" }} />

                                      <FieldArray name="itemsForDispatch">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { itemsForDispatch } = values;

                                          return (
                                            <div>
                                              {itemsForDispatch.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          xs={10}
                                                          lg={3}
                                                        >
                                                          <MDBox mb={1} ml={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Sales Order No
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="text"
                                                              name={`itemsForDispatch[${index}].salesOrderNumber`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                itemsForDispatch.salesOrderNumber
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={10}
                                                          lg={3}
                                                        >
                                                          <MDBox mb={1} ml={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Sales Order Line
                                                              No
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="text"
                                                              name={`itemsForDispatch[${index}].salesOrderLineNumber`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                itemsForDispatch.salesOrderLineNumber
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={10}
                                                          lg={3}
                                                        >
                                                          <MDBox mb={1} ml={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Customer Name
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="text"
                                                              name={`itemsForDispatch[${index}].customerName`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                itemsForDispatch.customerName
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={10}
                                                          lg={3}
                                                        >
                                                          <MDBox mb={1} ml={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              Qty
                                                            </MDTypography>
                                                            <Field
                                                              className="form-control"
                                                              type="number"
                                                              name={`itemsForDispatch[${index}].qty`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                formik.values
                                                                  .itemsForDispatch[
                                                                  index
                                                                ].qty
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForDispatch &&
                                                              formik.touched
                                                                .itemsForDispatch[
                                                                index
                                                              ] &&
                                                              formik.touched
                                                                .itemsForDispatch[
                                                                index
                                                              ].qty &&
                                                              formik.errors
                                                                .itemsForDispatch &&
                                                              formik.errors
                                                                .itemsForDispatch[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForDispatch[
                                                                index
                                                              ].qty && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForDispatch[
                                                                      index
                                                                    ].qty
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={10}
                                                          lg={3}
                                                        >
                                                          <MDBox
                                                            mb={1}
                                                            ml={1}
                                                            style={{
                                                              width: "210px",
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              FG Code
                                                            </MDTypography>
                                                            <Select
                                                              isClearable
                                                              className="select-css"
                                                              maxMenuHeight={
                                                                130
                                                              }
                                                              placeholder="Choose one..."
                                                              name={`itemsForDispatch[${index}].fgModelId`}
                                                              onBlur={() => {
                                                                formik.handleBlur(
                                                                  {
                                                                    target: {
                                                                      name: `itemsForDispatch[${index}].fgModelId`,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              options={
                                                                fgCodeOptions
                                                              }
                                                              value={
                                                                itemsForDispatch.fgModelId
                                                              }
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                if (
                                                                  selectedOption
                                                                ) {
                                                                  const selectedItem =
                                                                    fgCodeOptions.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.value ===
                                                                        selectedOption.value
                                                                    );
                                                                  if (
                                                                    selectedItem
                                                                  ) {
                                                                    formik.setFieldValue(
                                                                      `itemsForDispatch.${index}.fgModelId`,
                                                                      selectedOption.value
                                                                    );
                                                                    formik.setFieldValue(
                                                                      `itemsForDispatch.${index}.uom`,
                                                                      selectedItem.uom
                                                                    );
                                                                  }
                                                                } else {
                                                                  formik.setFieldValue(
                                                                    `itemsForDispatch.${index}.uom`,
                                                                    ""
                                                                  );
                                                                }
                                                              }}
                                                            />
                                                            {formik.touched
                                                              .itemsForDispatch &&
                                                              formik.errors
                                                                .itemsForDispatch &&
                                                              formik.errors
                                                                .itemsForDispatch[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .itemsForDispatch[
                                                                index
                                                              ].fgModelId && (
                                                                <TextError
                                                                  msg={
                                                                    formik
                                                                      .errors
                                                                      .itemsForDispatch[
                                                                      index
                                                                    ].fgModelId
                                                                  }
                                                                />
                                                              )}
                                                          </MDBox>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={10}
                                                          lg={3}
                                                        >
                                                          <MDBox mb={1} ml={1}>
                                                            <MDTypography
                                                              variant="h6"
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                              }}
                                                            >
                                                              UOM
                                                            </MDTypography>
                                                            <Field
                                                              disabled
                                                              className="form-control"
                                                              type="text"
                                                              name={`itemsForDispatch[${index}].uom`}
                                                              fullWidth
                                                              autoComplete="off"
                                                              value={
                                                                formik.values
                                                                  .itemsForDispatch[
                                                                  index
                                                                ]?.uom
                                                              }
                                                              inputProps={{
                                                                style: {
                                                                  height:
                                                                    "14px",
                                                                  readOnly: true,
                                                                },
                                                                maxLength: 45,
                                                              }}
                                                            />
                                                          </MDBox>
                                                        </Grid>

                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            marginTop: "1.5rem",
                                                            marginLeft: "2rem",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {index > 0 && (
                                                              <CancelIcon
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                type="button"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                color="error"
                                                              />
                                                            )}
                                                          </div>
                                                          {index ===
                                                            itemsForDispatch.length -
                                                              1 && (
                                                            <div>
                                                              <Tooltip title="Add Items">
                                                                <AddCircleIcon
                                                                  fontSize="large"
                                                                  onClick={() =>
                                                                    push({
                                                                      salesOrderNumber:
                                                                        "",
                                                                      salesOrderLineNumber:
                                                                        "",
                                                                      qty: "",
                                                                      uom: "",
                                                                      customerName:
                                                                        "",
                                                                      fgModelId:
                                                                        "",
                                                                    })
                                                                  }
                                                                  color="info"
                                                                  style={{
                                                                    width:
                                                                      "30px",
                                                                    marginLeft:
                                                                      "0rem",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            </div>
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </MDBox>

                                    <div style={{ background: "white" }}>
                                      <MDButton
                                        style={{
                                          position: "sticky",
                                          bottom: "0",
                                        }}
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        disabled={
                                          !formik.isValid ||
                                          formik.values.itemsForDispatch.some(
                                            (item) => item.fgModelId === ""
                                          )
                                        }
                                      >
                                        Submit
                                      </MDButton>
                                    </div>
                                    <MDBox>
                                      <Collapse in={isErrorCreatedDispatch}>
                                        <Alert
                                          severity="error"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsErrorCreatedDispatch(
                                                  false
                                                );
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          {errorMsgForCreate}
                                        </Alert>
                                      </Collapse>
                                      <Collapse in={isSuccessCreatedDispatch}>
                                        <Alert
                                          severity="success"
                                          action={
                                            <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                setIsSuccessCreatedDispatch(
                                                  false
                                                );
                                              }}
                                            >
                                              <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                          }
                                        >
                                          Created Successfully
                                        </Alert>
                                      </Collapse>
                                    </MDBox>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    salesOrder: state.salesOrder,
    vinStatus: state.vinStatus,
    accessoriesByVin: state.accessoriesByVin,
    getVinByFgCodeReducer: state.getVinByFgCodeReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getFgCodeReducer: state.getFgCodeReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getSalesOrder: (jwtToken) => dispatch(getSalesOrder(jwtToken)),
    getFgCode: (jwtToken) => dispatch(getFgCode(jwtToken)),
    getVinStatusByTripNo: (salesOrder, jwtToken) =>
      dispatch(getVinStatusByTripNo(salesOrder, jwtToken)),
    approveVin: (payload) => dispatch(approveVin(payload)),
    releaseTrip: (payload, jwtToken) =>
      dispatch(releaseTrip(payload, jwtToken)),
    createDispatch: (payload, jwtToken) =>
      dispatch(createDispatch(payload, jwtToken)),
    changeVin: (payload, jwtToken) => dispatch(changeVin(payload, jwtToken)),
    getAccessoriesByVin: (vin, jwtToken) =>
      dispatch(getAccessoriesByVin(vin, jwtToken)),
    getVinByFgCode: (fgCode, jwtToken) =>
      dispatch(getVinByFgCode(fgCode, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dispatch);
