/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  acceptPo,
  getAcceptedPurchaseOrders,
  publishPo,
} from "../../../store";

// React-Select component and styling

import "../../../index.css";

// Cookies
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();
const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const AcceptedPurchaseOrders = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getAcceptedPurchaseOrders,
  acceptedPoReducer,

  publishPo,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let acceptedPOModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "6efc7768-8b96-453b-989a-c861a5ae265a"
  );
  let publishAccess = acceptedPOModule
    ? acceptedPOModule.actionId.includes("42b2a60c-1720-49b9-894c-397bdf91b5c5")
    : null;
  let viewAccess = acceptedPOModule
    ? acceptedPOModule.actionId.includes("ba0d9f5a-1c8e-4fe3-9e11-1f45ec81ef3f")
    : null;

  useEffect(() => {
    getAcceptedPurchaseOrders(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "PO No.", accessor: "purchaseOrderNumber" },
      { Header: "PO LineNo.", accessor: "purchaseOrderLineNumber" },
      { Header: "Vendor Code", accessor: "vendorCode" },
      { Header: "Item code", accessor: "itemCode" },
      { Header: "Po Date", accessor: "date" },
      { Header: "Qty", accessor: "qty" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [isErrorAccept, setIsErrorAccept] = useState(false);
  const [isSuccessAccept, setIsSuccessAccept] = useState(false);

  useEffect(() => {
    if (isErrorAccept) {
      setTimeout(() => {
        setIsErrorAccept(false);
      }, 3000);
    }
  }, [isErrorAccept]);

  useEffect(() => {
    if (isSuccessAccept) {
      setTimeout(() => {
        setIsSuccessAccept(false);
      }, 3000);
    }
  }, [isSuccessAccept]);

  const [singleAcceptData, setSingleAcceptData] = useState({});

  const [openAcceptModal, setOpenAcceptModal] = useState(false);

  const handleOpenAcceptModal = (asset = {}) => {
    setSingleAcceptData(asset);
    setOpenAcceptModal(true);
  };
  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };

  const [isErrorPublish, setIsErrorPublish] = useState(false);
  const [isSuccessPublish, setIsSuccessPublish] = useState(false);

  useEffect(() => {
    if (isErrorPublish) {
      setTimeout(() => {
        setIsErrorPublish(false);
      }, 3000);
    }
  }, [isErrorPublish]);

  useEffect(() => {
    if (isSuccessPublish) {
      setTimeout(() => {
        setIsSuccessPublish(false);
      }, 3000);
    }
  }, [isSuccessPublish]);

  const [singlePublishData, setSinglePublishData] = useState({});

  const [openPublishModal, setOpenPublishModal] = useState(false);

  const handleOpenPublishModal = (asset = {}) => {
    setSinglePublishData(asset);
    setOpenPublishModal(true);
  };
  const handleClosePublishModal = () => {
    setOpenPublishModal(false);
  };

  const publishData = async () => {
    let payload = [
      {
        purchaseOrderId: singlePublishData.purchaseOrderId,
      },
    ];

    let response = await publishPo(payload, jwtToken);

    if (response.status) {
      setIsSuccessPublish(true);
      getAcceptedPurchaseOrders(jwtToken);

      setTimeout(() => {
        handleClosePublishModal();
      }, 2250);
    }
    if (!response.status) {
      setIsErrorPublish(true);
      setIsErrorMsgForPublish(response.data.data.msg);
    }
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempAsset = [];
    let data = acceptedPoReducer.accepted.data
      ? acceptedPoReducer.accepted.data
      : [];
    setLoading(acceptedPoReducer.loading);
    data.map((purchase) => {
      const formatDate = new Date(purchase.date);
      const purchaseObject = {
        privateKey: purchase.purchaseOrderId,
        purchaseOrderNumber: purchase.purchaseOrderNumber,
        purchaseOrderLineNumber: purchase.purchaseOrderLineNumber,
        vendorCode: purchase.vendorCode,
        itemCode: purchase.itemCode,
        purchaseOrderId: purchase.purchaseOrderId,
        date: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: purchase.qty,
        // isEditable: purchase.isEditable,
        action: (
          <div>
            {publishAccess && (
              <Tooltip title="Publish">
                <MDButton
                  disabled={purchase.isPublished === 1}
                  style={{ marginLeft: "0.5rem" }}
                  type="button"
                  variant="gradient"
                  color="success"
                  iconOnly
                  onClick={() => {
                    handleOpenPublishModal(purchase);
                  }}
                >
                  {" "}
                  <Icon>forward</Icon>
                </MDButton>
              </Tooltip>
            )}
          </div>
        ),
      };
      viewAccess && tempAsset.push(purchaseObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [acceptedPoReducer]);

  const [isErrorMsgForAccept, setIsErrorMsgForAccept] = useState(false);
  const [isErrorMsgForPublish, setIsErrorMsgForPublish] = useState(false);

  const acceptData = async () => {
    let payload = {
      purchaseOrderId: singleAcceptData.purchaseOrderId,
      userId: userId,
    };

    let response = await acceptPo(payload, jwtToken);

    if (response.status) {
      setIsSuccessAccept(true);
      getAcceptedPurchaseOrders(jwtToken);

      setTimeout(() => {
        handleCloseAcceptModal();
      }, 2250);
    }
    if (!response.status) {
      setIsErrorAccept(true);
      setIsErrorMsgForAccept(response.data.data.msg);
    }
  };

  return (
    <div>
      {/* <DashboardLayout>
        <DashboardNavbar /> */}

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
              {/* {createAssetAccess && ( */}

              <MDBox mt={0}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    options={{ draggable: false }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>

              <Modal
                open={openAcceptModal}
                onClose={handleCloseAcceptModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to Accept?</MDTypography>
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "1.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseAcceptModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="success"
                        onClick={acceptData}
                      >
                        Accept
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessAccept}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessAccept(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Purchase Order Accepted successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorAccept}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorAccept(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {isErrorMsgForAccept}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openPublishModal}
                onClose={handleClosePublishModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={deleteStyle}>
                  <MDTypography>Are you sure you want to Publish?</MDTypography>
                  <MDBox mt={2} display="flex">
                    <div>
                      <MDButton
                        style={{ width: "100%", marginRight: "1.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleClosePublishModal}
                      >
                        Cancel
                      </MDButton>
                    </div>
                    <div style={{ float: "right" }}>
                      <MDButton
                        style={{ width: "100%", marginLeft: "0.5rem" }}
                        color="success"
                        onClick={publishData}
                      >
                        Publish
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isSuccessPublish}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessPublish(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Purchase Order Published successfully!
                      </Alert>
                    </Collapse>
                    <Collapse in={isErrorPublish}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorPublish(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {isErrorMsgForPublish}
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
      {/* <Footer /> */}
      {/* </DashboardLayout> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    acceptedPoReducer: state.acceptedPoReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAcceptedPurchaseOrders: (jwtToken) =>
      dispatch(getAcceptedPurchaseOrders(jwtToken)),
    acceptPo: (payload, jwtToken) => dispatch(acceptPo(payload, jwtToken)),
    publishPo: (payload, jwtToken) => dispatch(publishPo(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptedPurchaseOrders);
