/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  GET_USER_ROLES_BY_DEPARTMENT_REQUEST,
  GET_USER_ROLES_BY_DEPARTMENT_SUCCESS,
  GET_USER_ROLES_BY_DEPARTMENT_FAILURE,
} from "./userRolesTypes";
 
const initialStateForRoles = {
  loading: false,
  rolesData: [],
  error: "",
};

const getAllRolesReducer = (state = initialStateForRoles, action) => {
  switch (action.type) {
    case GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        loading: false,
        rolesData: action.payload,
        error: "",
      };
    case GET_ALL_ROLES_FAILURE:
      return {
        loading: false,
        rolesData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUserRolesByDepartment = {
  loading: false,
  roles: {},
  error: "",
};

const getUserRolesByDepartment = (
  state = initialStateForUserRolesByDepartment,
  action
) => {
  switch (action.type) {
    case GET_USER_ROLES_BY_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_ROLES_BY_DEPARTMENT_SUCCESS:
      return {
        loading: true,
        roles: action.payload,
        error: "",
      };
    case GET_USER_ROLES_BY_DEPARTMENT_FAILURE:
      return {
        loading: false,
        roles: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAllRolesReducer,
  getUserRolesByDepartment
}; 

