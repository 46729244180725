/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard standardized components
import CreateButton from "components/standardized-components/CreateButton";

// Functions from store
import {
  getItemMaster,
  editItemMaster,
  getBinsByItemCode,
  uploadCsv,
  getItemsDataForDownload,
  createItem,
  getWarehouses,
  getSubModulesByRoleId,
  getItemTypes,
} from "../../store";

// React-Select component and styling
import Select from "react-select";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Config file
import Config from "../../config/index";
import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import EditButton from "components/standardized-components/EditButton";
const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "fit-content",
  maxHeight: "90%",
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const ItemMaster = ({
  getItemMaster,
  itemMaster,
  getBinsByItemCode,

  editItemMaster,
  uploadCsv,
  getItemsDataForDownload,

  createItem,

  getItemTypes,
  getItemTypesReducer,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let itemModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b496ec6f-d8d8-4047-990a-d357587027b9"
  );

  let createAccess = itemModule
    ? itemModule.actionId.includes("bd0ff26b-060e-4ed4-af61-01468c0205d3")
    : null;

  let viewAccess = itemModule
    ? itemModule.actionId.includes("f139822c-6f3e-4525-9c91-0f7cdc9ac62f")
    : null;

  let uploadItem = itemModule
    ? itemModule.actionId.includes("1fa09da4-d42e-44c6-b2ca-b3ef663bddf3")
    : null;
  let editItem = itemModule
    ? itemModule.actionId.includes("1614175c-e0bf-4910-8033-79f0bb3f77fa")
    : null;
  let downloadItem = itemModule
    ? itemModule.actionId.includes("aa106995-31d7-45a4-a0a3-d14e6204ad2c")
    : null;

  const [itemId, setItemId] = useState("");
  const [binId, setBinId] = useState("");
  const [tableHeading, setTableHeading] = useState("");

  useEffect(() => {
    getItemMaster();
  }, []);

  const [state, setState] = useState({
    columns: [
      {
        Header: "Item Code/Des",
        accessor: "itemCodeDes",
        align: "left",
      },

      {
        Header: "Category Description",
        accessor: "categoryDescription",
        align: "left",
      },
      { Header: "Category Code", accessor: "categoryCode", align: "left" },
      { Header: "UOM", accessor: "uom", align: "left" },
      { Header: "Control Type", accessor: "controlType", align: "left" },
      { Header: "Movement Type", accessor: "movementType", align: "left" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempItemMaster = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    setLoading(itemMaster.loading);
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      const itemObject = {
        itemCodeDes: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        itemDescription: item.itemDescription,
        itemCode: item.itemCode,
        categoryCode: item.categoryCode,
        categoryDescription: item.categoryName,

        categoryCodeDes: `${item.categoryCode} / ${item.categoryDescription}`,
        uom: item.uom,
        controlType:
          (item.controlType === 0 && `No Control`) ||
          (item.controlType === 1 && `Serial Control`) ||
          (item.controlType === 2 && `Lot Control`),
        movementType: item.movementType,
        bin: item.locationName,
        thresholdQty: item.thresholdQty,
        status: item.status,
        action: (
          <EditButton
            tooltip="Edit"
            disabled={!editItem || item.isEditable === 0 ? true : false}
            type="button"
            variant="gradient"
            color="success"
            iconOnly
            onClick={() => {
              setItemId(item.id);

              setBinId(item.locationId);
              handleOpenEditSingleItemMasterModal(item);
            }}
          >
            <Icon>edit</Icon>
          </EditButton>
        ),
      };
      tempItemMaster.push(itemObject);
    });

    viewAccess && setState({ ...state, rows: tempItemMaster });
  }, [itemMaster]);

  const [isSuccessEditThreshold, setIsSuccessEditThreshold] = useState(false);
  const [isErrorEditThreshold, setIsErrorEditThreshold] = useState(false);
  const [isErrorMsgEditThreshold, setIsErrorMsgEditThreshold] = useState("");
  useEffect(() => {
    if (isSuccessEditThreshold) {
      setTimeout(() => {
        setIsSuccessEditThreshold(false);
      }, 3000);
    }
  }, [isSuccessEditThreshold]);
  useEffect(() => {
    if (isErrorEditThreshold) {
      setTimeout(() => {
        setIsErrorEditThreshold(false);
      }, 3000);
    }
  }, [isErrorEditThreshold]);

  const [editItemModal, setEditItemModal] = useState(false);
  const handleEditItemModal = () => {
    setEditItemModal(!editItemModal);
    formik.setErrors({});
  };

  const [singleItemMasterDataForEdit, setSingleItemMasterDataForEdit] =
    useState({});

  const handleOpenEditSingleItemMasterModal = (data = {}) => {
    // formik.setValues(singleItemMasterDataForEdit);

    setMovementTypeSelectedForEdit({
      label: data.movementType,
      value: data.movementType,
    });
    setControlTypeSelectedForEdit({
      label:
        (data.controlType === 0 && "No Control") ||
        (data.controlType === 1 && "Serial Control") ||
        (data.controlType === 2 && "Lot Control"),
      value: data.controlType,
    });
    setSingleItemMasterDataForEdit(data);
    setEditItemModal(true);
  };

  const initialValueForEdit = {
    itemDescription: singleItemMasterDataForEdit.itemDescription
      ? singleItemMasterDataForEdit.itemDescription
      : "",
    controlType: singleItemMasterDataForEdit.controlType
      ? singleItemMasterDataForEdit.controlType
      : "",
    movementType: singleItemMasterDataForEdit.movementType
      ? singleItemMasterDataForEdit.movementType
      : "",
  };

  const validationSchemaForEdit = Yup.object({
    itemDescription: Yup.string().required("Enter the item description"),
    // controlType: Yup.object().required("Enter the control type"),
    // movementType: Yup.object().required("Enter the movement type"),
  });

  const onSubmitForEdit = async (values, { resetForm }) => {
    const payload = {
      id: singleItemMasterDataForEdit.itemId,
      itemCode: singleItemMasterDataForEdit.itemCode,
      itemTypeId: singleItemMasterDataForEdit.itemTypeId,
      uom: singleItemMasterDataForEdit.uom,
      itemDescription: values.itemDescription,
      movementType: movementTypeSelectedForEdit.value,
      controlType: controlTypeSelectedForEdit.value,
    };

    let res = await editItemMaster(payload);

    if (res.status) {
      setIsSuccessEditThreshold(true);
      getItemMaster(jwtToken);
    }
    if (!res.status) {
      setIsErrorEditThreshold(true);
      setIsErrorMsgEditThreshold(res.message.data.msg);
    }
    // resetForm();
  };

  const formik = useFormik({
    initialValues: initialValueForEdit,
    onSubmit: onSubmitForEdit,
    validationSchema: validationSchemaForEdit,
    enableReinitialize: true,
  });

  const [uomSelected, setUomSelected] = useState("");

  const handleChangeUom = (selectedOption) => {
    setUomSelected(selectedOption);
  };

  const uomOptions = [
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "NOS",
      value: "Nos",
    },
  ];

  const [movementTypeSelected, setMovementTypeSelected] = useState("");

  const handleChangeMovementType = (selectedOption) => {
    setMovementTypeSelected(selectedOption);
  };

  const [movementTypeSelectedForEdit, setMovementTypeSelectedForEdit] =
    useState("");

  const handleChangeMovementTypeForEdit = (selectedOption) => {
    setMovementTypeSelectedForEdit(selectedOption);
  };

  const movementTypeOptions = [
    {
      label: "FIFO",
      value: "FIFO",
    },
    {
      label: "FEFO",
      value: "FEFO",
    },
  ];

  const [controlTypeSelected, setControlTypeSelected] = useState("");

  const handleChangeControlType = (selectedOption) => {
    setControlTypeSelected(selectedOption);
  };

  const [controlTypeSelectedForEdit, setControlTypeSelectedForEdit] =
    useState("");

  const handleChangeControlTypeForEdit = (selectedOption) => {
    setControlTypeSelectedForEdit(selectedOption);
  };

  const controlTypeOptions = [
    {
      label: "No Control",
      value: 0,
    },
    {
      label: "Serial Control",
      value: 1,
    },
    {
      label: "Lot Control",
      value: 2,
    },
  ];

  const [itemTypeSelected, setItemTypeSelected] = useState("");

  const handleChangeItemType = (selectedOption) => {
    setItemTypeSelected(selectedOption);
  };

  const [itemTypeOptions, setItemTypeOptions] = useState([]);

  useEffect(() => {
    let tempItemTypeOptions = [];
    let data = getItemTypesReducer.itemTypes.data
      ? getItemTypesReducer.itemTypes.data
      : [];
    data.map((itemType) => {
      tempItemTypeOptions.push({
        label: itemType.itemType,
        value: itemType.itemTypeId,
      });
    });
    setItemTypeOptions(tempItemTypeOptions);
  }, [getItemTypesReducer]);

  const [openCreateItemModal, setOpenCreateItemModal] = useState(false);

  const handleOpenCreateItemModal = () => {
    getItemTypes(jwtToken);
    setOpenCreateItemModal(true);
    formikForCreateItem.setValues(initialValues);
    formikForCreateItem.setTouched({});
    // formikForCreateItem.setErrors({});
    setItemTypeSelected("");
    setMovementTypeSelected("");
    setControlTypeSelected("");
    setUomSelected("");
  };

  const handleCloseCreateItemModal = () => {
    setOpenCreateItemModal(false);
    // formikForCreateItem.setErrors({});
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  const initialValues = {
    itemCode: "",
    itemDes: "",
    itemType: "",
    movementType: "",
    controlType: "",
    uom: "",
  };

  const validationSchema = Yup.object({
    itemCode: Yup.string().required("Please enter the item code"),
    itemDes: Yup.string().required("Please enter the item description"),
    itemType: Yup.object().required("Select the Item Type"),
    movementType: Yup.object().required("Select the Movement Type"),
    controlType: Yup.object().required("Select the Control Type"),
    uom: Yup.object().required("Select the uom"),
  });

  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      itemCode: values.itemCode,
      itemDescription: values.itemDes,
      itemTypeId: itemTypeSelected.value,
      controlType: controlTypeSelected.value,
      uom: uomSelected.value,
      movementType: movementTypeSelected.value,
    };

    let res = await createItem(payload, jwtToken);
    if (res.status) {
      getItemMaster();
      setIsSuccess(true);
      resetForm();
      setItemTypeSelected("");
      setMovementTypeSelected("");
      setControlTypeSelected("");
      setUomSelected("");
    }
    if (!res.status) {
      setErrorMsg(res.message.data.msg);
      setIsError(true);
    }
  };

  const formikForCreateItem = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("item", uploadCsvData);

    let res = await uploadCsv(data);

    if (res.status) {
      getItemMaster();
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }

    if (!res.status) {
      DataUploadFailureNotification();
    }
  };

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    { label: "itemDescription", key: "itemDescription" },
    {
      label: "itemTypeId",
      key: "itemTypeId",
    },
    {
      label: "controlType",
      key: "controlType",
    },
    {
      label: "movementType",
      key: "movementType",
    },
    { label: "uom", key: "uom" },
  ];

  const csvLink = {
    filename: "Item List.csv",
    headers: headers,
    data: rows,
  };

  const csvLink2 = {
    filename: "Item Template.csv",
    headers: headers,
    data: [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
                mt={-1}
              >
                <MDBox color="text" px={2} ml={-2} mb={1}>
                  <CreateButton
                    tooltip="Create Item"
                    disabled={!createAccess}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={handleOpenCreateItemModal}
                    style={{ marginRight: "1rem" }}
                  >
                    <Icon>add</Icon>
                  </CreateButton>

                  {downloadItem ? (
                    <CSVLink {...csvLink2}>
                      <DownloadButton
                        tooltip="Download Template"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadButton>
                    </CSVLink>
                  ) : (
                    ""
                  )}

                  <UploadButton
                    tooltip="Upload CSV"
                    disabled={!uploadItem}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </UploadButton>

                  {downloadItem ? (
                    <CSVLink {...csvLink}>
                      <DownloadDataButton
                        tooltip="Download list Of Items"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="success"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadDataButton>
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </MDBox>
              </MDBox>
              {uploadCsvFilter && (
                <div style={{ marginLeft: "1", marginTop: "0.5rem" }}>
                  <div>
                    <input
                      className="choose_file"
                      style={{
                        marginTop: "1.5rem",
                        marginLeft: "1.5rem",
                        // marginBottom: "-9rem",
                      }}
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setUploadCsvData(e.target.files[0]);
                      }}
                    />
                    <MDButton
                      disabled={
                        !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                      }
                      variant="contained"
                      color="info"
                      style={{
                        marginLeft: { xs: "1.5rem", lg: "1" },
                        marginTop: "0rem",
                      }}
                      type="button"
                      onClick={uploadCsvHandler}
                    >
                      UPLOAD
                    </MDButton>
                  </div>
                  <div style={{ marginBottom: "-20px" }}>
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "30px",
                        fontStyle: "italic",
                      }}
                    >
                      <strong>*</strong> Please upload
                      <strong>".csv"</strong> file only
                    </span>
                  </div>
                </div>
              )}

              <MDBox pt={3} mt={0}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
              <Modal
                open={openCreateItemModal}
                onClose={handleCloseCreateItemModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create Item
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateItemModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3} overflow="auto">
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForCreateItem.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Item Code/Des
                        </MDTypography>

                        <MDInput
                          type="text"
                          name="itemCode"
                          className="select-css-for-itemcode"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForCreateItem.handleBlur}
                          onChange={formikForCreateItem.handleChange}
                          value={formikForCreateItem.values.itemCode}
                          error={
                            formikForCreateItem.touched.itemCode &&
                            formikForCreateItem.errors.itemCode &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                        {formikForCreateItem.touched.itemCode &&
                          formikForCreateItem.errors.itemCode && (
                            <TextError
                              msg={formikForCreateItem.errors.itemCode}
                            />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Item Description
                        </MDTypography>

                        <MDInput
                          multiline
                          rows={2}
                          type="text"
                          name="itemDes"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForCreateItem.handleBlur}
                          onChange={formikForCreateItem.handleChange}
                          value={formikForCreateItem.values.itemDes}
                          error={
                            formikForCreateItem.touched.itemDes &&
                            formikForCreateItem.errors.itemDes &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />
                        {formikForCreateItem.touched.itemDes &&
                          formikForCreateItem.errors.itemDes && (
                            <TextError
                              msg={formikForCreateItem.errors.itemDes}
                            />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Item Type
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="itemType"
                          onBlur={() => {
                            formik.handleBlur({
                              target: { name: "itemType" },
                            });
                          }}
                          value={itemTypeSelected}
                          options={itemTypeOptions}
                          onChange={(selectedOption) => {
                            handleChangeItemType(selectedOption);
                            formikForCreateItem.setFieldValue(
                              "itemType",
                              selectedOption
                            );
                          }}
                        />
                        {formikForCreateItem.touched.itemType &&
                          formikForCreateItem.errors.itemType && (
                            <TextError
                              msg={formikForCreateItem.errors.itemType}
                            />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Control Type
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="controlType"
                          value={controlTypeSelected}
                          options={controlTypeOptions}
                          onBlur={formikForCreateItem.handleBlur}
                          onChange={(selectedOption) => {
                            handleChangeControlType(selectedOption);
                            formikForCreateItem.setFieldValue(
                              "controlType",
                              selectedOption
                            );
                          }}
                        />
                        {formikForCreateItem.touched.itemType &&
                          formikForCreateItem.errors.controlType && (
                            <TextError
                              msg={formikForCreateItem.errors.controlType}
                            />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Movement Type
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="movementType"
                          value={movementTypeSelected}
                          options={movementTypeOptions}
                          onBlur={formikForCreateItem.handleBlur}
                          onChange={(selectedOption) => {
                            handleChangeMovementType(selectedOption);
                            formikForCreateItem.setFieldValue(
                              "movementType",
                              selectedOption
                            );
                          }}
                        />
                        {formikForCreateItem.touched.itemType &&
                          formikForCreateItem.errors.movementType && (
                            <TextError
                              msg={formikForCreateItem.errors.movementType}
                            />
                          )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          UOM
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="uom"
                          onBlur={formikForCreateItem.handleBlur}
                          value={uomSelected}
                          options={uomOptions}
                          onChange={(selectedOption) => {
                            handleChangeUom(selectedOption);
                            formikForCreateItem.setFieldValue(
                              "uom",
                              selectedOption
                            );
                          }}
                        />
                        {formikForCreateItem.touched.uom &&
                          formikForCreateItem.errors.uom && (
                            <TextError msg={formikForCreateItem.errors.uom} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isError}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsError(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccess}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccess(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Item Created successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={!formikForCreateItem.isValid}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={editItemModal}
                onClose={handleEditItemModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleEditItemModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            // marginLeft: "-55px",
                            // marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Item Description
                        </MDTypography>

                        <MDInput
                          multiline
                          rows={2}
                          type="text"
                          name="itemDescription"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.itemDescription}
                          error={
                            formik.touched.itemDescription &&
                            formik.errors.itemDescription &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                          }}
                        />
                        {formik.touched.itemDescription &&
                          formik.errors.itemDescription && (
                            <TextError msg={formik.errors.itemDescription} />
                          )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Control Type
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="controlType"
                          value={controlTypeSelectedForEdit}
                          options={controlTypeOptions}
                          onChange={(selectedOption) => {
                            handleChangeControlTypeForEdit(selectedOption);
                            formik.setFieldValue("controlType", selectedOption);
                          }}
                        />
                        {formik.errors.controlType && (
                          <TextError msg={formik.errors.controlType} />
                        )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          style={{ fontWeight: "500" }}
                        >
                          Movement Type
                        </MDTypography>

                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="movementType"
                          value={movementTypeSelectedForEdit}
                          options={movementTypeOptions}
                          onChange={(selectedOption) => {
                            handleChangeMovementTypeForEdit(selectedOption);
                            formik.setFieldValue(
                              "movementType",
                              selectedOption
                            );
                          }}
                        />
                        {formik.errors.movementType && (
                          <TextError msg={formik.errors.movementType} />
                        )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorEditThreshold}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorEditThreshold(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {isErrorMsgEditThreshold}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessEditThreshold}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessEditThreshold(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Updated successfully!
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={2}>
                        <MDButton
                          fullWidth
                          variant="gradient"
                          color="success"
                          type="submit"
                          disabled={
                            !formik.isValid ||
                            !movementTypeSelectedForEdit ||
                            !controlTypeSelectedForEdit
                          }
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    itemMaster: state.itemMaster,
    getItemTypesReducer: state.getItemTypesReducer,
    binsByItemCode: state.binsByItemCode,
    getItemsDataForDownloadReducer: state.getItemsDataForDownloadReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
    getItemTypes: (jwtToken) => dispatch(getItemTypes(jwtToken)),
    editItemMaster: (payload, jwtToken) =>
      dispatch(editItemMaster(payload, jwtToken)),

    uploadCsv: (payload, jwtToken) => dispatch(uploadCsv(payload, jwtToken)),

    createItem: (payload, jwtToken) => dispatch(createItem(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemMaster);
