import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import FormNavigation from "./FormNavigation";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const MultiStepForm = ({
  children,
  initialValues,
  onSubmit,
  onStateChange,
}) => {
  const theme = createTheme({});

  const stepStyle = {
    boxShadow: 2,
    backgroundColor: "#EEEDEB",
    borderRadius: 3,
    padding: 2,
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "red",
        fontSize: "2rem",
      },
      "& .MuiStepConnector-line": {
        borderColor: "red",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "green",
        fontSize: "2rem",
      },
      "& .MuiStepConnector-line": {
        borderColor: "green",
      },
    },
  };

  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);

  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  const reviewButtonHandler = () => {
    setStepNumber(0);
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
      enableReinitialize={true}
    >
      {(formik) => {
        return (
          <Form>
            <ThemeProvider theme={theme}>
              <Stepper activeStep={stepNumber} alternativeLabel sx={stepStyle}>
                {steps.map((currentStep, index) => {
                  const label = currentStep.props.stepName;
                  onStateChange(formik.values);

                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </ThemeProvider>

            {step}

            <FormNavigation
              isLastStep={isLastStep}
              hasPrevious={stepNumber > 0}
              onBackClick={() => previous(formik.values)}
              onReviewClick={() => reviewButtonHandler(formik.values)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default MultiStepForm;

export const FormStep = ({ stepName = "", children }) => children;
