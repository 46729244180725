import {
  CREATE_STATION_FAILURE,
  CREATE_STATION_REQUEST,
  CREATE_STATION_SUCCESS,
  DELETE_STATION_FAILURE,
  DELETE_STATION_REQUEST,
  DELETE_STATION_SUCCESS,
  EDIT_STATION_FAILURE,
  EDIT_STATION_REQUEST,
  EDIT_STATION_SUCCESS,
  GET_STATION_MASTER_FAILURE,
  GET_STATION_MASTER_REQUEST,
  GET_STATION_MASTER_SUCCESS,
  GET_STATIONS_BY_ASSEMBLYID_FAILURE,
  GET_STATIONS_BY_ASSEMBLYID_REQUEST,
  GET_STATIONS_BY_ASSEMBLYID_SUCCESS,
} from "./stationMasterTypes";
import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getStationsByAssemblyRequest = () => {
  return {
    type: GET_STATION_MASTER_REQUEST,
  };
};

const getStationsByAssemblySuccess = (payload) => {
  return {
    type: GET_STATION_MASTER_SUCCESS,
    payload: payload,
  };
};

const getStationsByAssemblyFailure = (error) => {
  return {
    type: GET_STATION_MASTER_FAILURE,
    payload: error,
  };
};

const getStationsByAssembly = (assemblyId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getStationsByAssemblyRequest());
    let url = `${baseUrl}/station/get_by_assembly_id?assemblyId=${assemblyId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getStationsByAssemblySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getStationsByAssemblyFailure(err.message));
      });
  };
};

const getStationsByAssemblyIdForBomRequest = () => {
  return {
    type: GET_STATIONS_BY_ASSEMBLYID_REQUEST,
  };
};

const getStationsByAssemblyIdForBomSuccess = (payload) => {
  return {
    type: GET_STATIONS_BY_ASSEMBLYID_SUCCESS,
    payload: payload,
  };
};

const getStationsByAssemblyIdForBomFailure = (error) => {
  return {
    type: GET_STATIONS_BY_ASSEMBLYID_FAILURE,
    payload: error,
  };
};

const getStationsByAssemblyIdForBom = (assemblyId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getStationsByAssemblyIdForBomRequest());
    // let url = `${baseUrl}/station/get_by_assembly_id?assemblyId=${assemblyId}`;
    let url = `${baseUrl}/station/get_mapped_station_by_assembly_id?assemblyId=${assemblyId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getStationsByAssemblyIdForBomSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getStationsByAssemblyIdForBomFailure(err.message));
      });
  };
};

const deleteStationRequest = () => {
  return {
    type: DELETE_STATION_REQUEST,
  };
};

const deleteStationSuccess = (payload) => {
  return {
    type: DELETE_STATION_SUCCESS,
    payload: payload,
  };
};

const deleteStationFaliure = (error) => {
  return {
    type: DELETE_STATION_FAILURE,
    payload: error,
  };
};

const deleteStation = (stationId, userIdForStation, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteStationRequest());
    let url = `${baseUrl}/station/delete?stationId=${stationId}&userId=${userIdForStation}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteStationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteStationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editStationRequest = () => {
  return {
    type: EDIT_STATION_REQUEST,
  };
};

const editStationSuccess = (payload) => {
  return {
    type: EDIT_STATION_SUCCESS,
    payload: payload,
  };
};

const editStationFaliure = (error) => {
  return {
    type: EDIT_STATION_FAILURE,
    payload: error,
  };
};

const editStation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editStationRequest());
    let url = `${baseUrl}/station/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editStationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editStationFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createStationMasterRequest = () => {
  return {
    type: CREATE_STATION_REQUEST,
  };
};

const createStationMasterSuccess = (payload) => {
  return {
    type: CREATE_STATION_SUCCESS,
    payload: payload,
  };
};

const createStationMasterFailure = (error) => {
  return {
    type: CREATE_STATION_FAILURE,
    payload: error,
  };
};

const createStationMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createStationMasterRequest());
    let url = `${baseUrl}/bin/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createStationMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createStationMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getStationsByAssembly,
  deleteStation,
  editStation,
  createStationMaster,
  getStationsByAssemblyIdForBom,
};
