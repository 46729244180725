import {
  GET_FINISHEDGOODS_REQUEST,
  GET_FINISHEDGOODS_SUCCESS,
  GET_FINISHEDGOODS_FAILURE,
  GET_GROUPED_FINISHEDGOODS_REQUEST,
  GET_GROUPED_FINISHEDGOODS_SUCCESS,
  GET_GROUPED_FINISHEDGOODS_FAILURE,
  GET_VINBYFGCODE_REQUEST,
  GET_VINBYFGCODE_SUCCESS,
  GET_VINBYFGCODE_FAILURE,
  CREATE_PICKLIST_FOR_SUID_REQUEST,
  CREATE_PICKLIST_FOR_SUID_SUCCESS,
  CREATE_PICKLIST_FOR_SUID_FAILURE,
  CREATE_PICKLIST_FOR_QTY_REQUEST,
  CREATE_PICKLIST_FOR_QTY_SUCCESS,
  CREATE_PICKLIST_FOR_QTY_FAILURE,
  GET_SUIDS_FOR_FG_REQUEST,
  GET_SUIDS_FOR_FG_SUCCESS,
  GET_SUIDS_FOR_FG_FAILURE,
  GET_ITEMS_FOR_FG_REQUEST,
  GET_ITEMS_FOR_FG_SUCCESS,
  GET_ITEMS_FOR_FG_FAILURE,
  GET_FG_CODE_FOR_DOCUMENT_REQUEST,
  GET_FG_CODE_FOR_DOCUMENT_SUCCESS,
  GET_FG_CODE_FOR_DOCUMENT_FAILURE,
} from "./fgTransactionTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getFinishedGoodsRequest = () => {
  return {
    type: GET_FINISHEDGOODS_REQUEST,
  };
};

const getFinishedGoodsSuccess = (payload) => {
  return {
    type: GET_FINISHEDGOODS_SUCCESS,
    payload: payload,
  };
};

const getFinishedGoodsFailure = (error) => {
  return {
    type: GET_FINISHEDGOODS_FAILURE,
    payload: error,
  };
};

const getFinishedGoods = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getFinishedGoodsRequest());
    let url = `${baseUrl}/fg_transaction/get_all_without_grp`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getFinishedGoodsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFinishedGoodsFailure(err.message));
      });
  };
};

const getGroupedFinishedGoodsRequest = () => {
  return {
    type: GET_GROUPED_FINISHEDGOODS_REQUEST,
  };
};

const getGroupedFinishedGoodsSuccess = (payload) => {
  return {
    type: GET_GROUPED_FINISHEDGOODS_SUCCESS,
    payload: payload,
  };
};

const getGroupedFinishedGoodsFailure = (error) => {
  return {
    type: GET_GROUPED_FINISHEDGOODS_FAILURE,
    payload: error,
  };
};

const getGroupedFinishedGoods = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getGroupedFinishedGoodsRequest());
    let url = `${baseUrl}/fg_transaction/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getGroupedFinishedGoodsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getGroupedFinishedGoodsFailure(err.message));
      });
  };
};

const getVinByFgcodeRequest = () => {
  return {
    type: GET_VINBYFGCODE_REQUEST,
  };
};

const getVinByFgcodeSuccess = (payload) => {
  return {
    type: GET_VINBYFGCODE_SUCCESS,
    payload: payload,
  };
};

const getVinByFgcodeFailure = (error) => {
  return {
    type: GET_VINBYFGCODE_FAILURE,
    payload: error,
  };
};

const getVinByFgCode = (fgCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getVinByFgcodeRequest());
    let url = `${baseUrl}/fg_transaction/get_vin_by_fgcode?fgCode=${fgCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getVinByFgcodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getVinByFgcodeFailure(err.message));
      });
  };
};

const createPicklistForSuidRequest = () => {
  return {
    type: CREATE_PICKLIST_FOR_SUID_REQUEST,
  };
};

const createPicklistForSuidSuccess = (payload) => {
  return {
    type: CREATE_PICKLIST_FOR_SUID_SUCCESS,
    payload: payload,
  };
};

const createPicklistForSuidFailure = (error) => {
  return {
    type: CREATE_PICKLIST_FOR_SUID_FAILURE,
    payload: error,
  };
};

const createPicklistForSuid = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPicklistForSuidRequest());
    let url = `${baseUrl}/fg/picklist/create_with_suids`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPicklistForSuidSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPicklistForSuidFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createPicklistForQtyRequest = () => {
  return {
    type: CREATE_PICKLIST_FOR_QTY_REQUEST,
  };
};

const createPicklistForQtySuccess = (payload) => {
  return {
    type: CREATE_PICKLIST_FOR_QTY_SUCCESS,
    payload: payload,
  };
};

const createPicklistForQtyFailure = (error) => {
  return {
    type: CREATE_PICKLIST_FOR_QTY_FAILURE,
    payload: error,
  };
};

const createPicklistForQty = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPicklistForQtyRequest());
    let url = `${baseUrl}/fg/picklist/create_with_qty`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPicklistForQtySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPicklistForQtyFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getSuidsForFgRequest = () => {
  return {
    type: GET_SUIDS_FOR_FG_REQUEST,
  };
};

const getSuidsForFgSuccess = (payload) => {
  return {
    type: GET_SUIDS_FOR_FG_SUCCESS,
    payload: payload,
  };
};

const getSuidsForFgFailure = (error) => {
  return {
    type: GET_SUIDS_FOR_FG_FAILURE,
    payload: error,
  };
};

const getSuidsForFg = (itemId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSuidsForFgRequest());
    let url = `${baseUrl}/fg/picklist/get_suids?fgCode=${itemId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSuidsForFgSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSuidsForFgFailure(err.message));
      });
  };
};

const getItemsForFgRequest = () => {
  return {
    type: GET_ITEMS_FOR_FG_REQUEST,
  };
};

const getItemsForFgSuccess = (payload) => {
  return {
    type: GET_ITEMS_FOR_FG_SUCCESS,
    payload: payload,
  };
};

const getItemsForFgFailure = (error) => {
  return {
    type: GET_ITEMS_FOR_FG_FAILURE,
    payload: error,
  };
};

const getItemsForFg = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsForFgRequest());
    let url = `${baseUrl}/fg/get_items`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsForFgSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsForFgFailure(err.message));
      });
  };
};

const getFgCodeForDocumentRequest = () => {
  return {
    type: GET_FG_CODE_FOR_DOCUMENT_REQUEST,
  };
};

const getFgCodeForDocumentSuccess = (payload) => {
  return {
    type: GET_FG_CODE_FOR_DOCUMENT_SUCCESS,
    payload: payload,
  };
};

const getFgCodeForDocumentFailure = (error) => {
  return {
    type: GET_FG_CODE_FOR_DOCUMENT_FAILURE,
    payload: error,
  };
};

const getFgCodeForDocument = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getFgCodeForDocumentRequest());
    let url = `${baseUrl}/fg/get_fgCodes`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getFgCodeForDocumentSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFgCodeForDocumentFailure(err.message));
      });
  };
};

export {
  getFinishedGoods,
  getGroupedFinishedGoods,
  getVinByFgCode,
  createPicklistForSuid,
  createPicklistForQty,
  getSuidsForFg,
  getItemsForFg,
  getFgCodeForDocument,
};
