export const GET_ASNDETAILS_REQUEST = "GET_ASNDETAILS_REQUEST";
export const GET_ASNDETAILS_SUCCESS = "GET_ASNDETAILS_SUCCESS";
export const GET_ASNDETAILS_FAILURE = "GET_ASNDETAILS_FAILURE";


export const GET_ASNITEMSDETAILS_REQUEST = "GET_ASNITEMSDETAILS_REQUEST";
export const GET_ASNITEMSDETAILS_SUCCESS = "GET_ASNITEMSDETAILS_SUCCESS";
export const GET_ASNITEMSDETAILS_FAILURE = "GET_ASNITEMSDETAILS_FAILURE";

export const UPDATE_VEHICLE_REQUEST = "UPDATE_VEHICLE_REQUEST";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAILURE = "UPDATE_VEHICLE_FAILURE";

export const GET_BINS_REQUEST = "GET_BINS_REQUEST";
export const GET_BINS_SUCCESS = "GET_BINS_SUCCESS";
export const GET_BINS_FAILURE = "GET_BINS_FAILURE";


export const GET_LAST_ASN_DETAILS_REQUEST = "GET_LAST_ASN_DETAILS_REQUEST";
export const GET_LAST_ASN_DETAILS_SUCCESS = "GET_LAST_ASN_DETAILS_SUCCESS";
export const GET_LAST_ASN_DETAILS_FAILURE = "GET_LAST_ASN_DETAILS_FAILURE";

export const POST_UPDATEASN_REQUEST = "POST_UPDATEASN_REQUEST";
export const POST_UPDATEASN_SUCCESS = "POST_UPDATEASN_SUCCESS";
export const POST_UPDATEASN_FAILURE = "POST_UPDATEASN_FAILURE";

export const POST_CONFIRM_VIRTUAL_BINS_REQUEST = "POST_CONFIRM_VIRTUAL_BINS_REQUEST";
export const POST_CONFIRM_VIRTUAL_BINS_SUCCESS = "POST_CONFIRM_VIRTUAL_BINS_SUCCESS";
export const POST_CONFIRM_VIRTUAL_BINS_FAILURE = "POST_CONFIRM_VIRTUAL_BINS_FAILURE";