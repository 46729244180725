/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

// Functions from store
import { getPickup } from "../../store";

const PickupHomePageCard = ({ getPickup, pickupList }) => {
  useEffect(() => {
    getPickup();
  }, []);

  const [started, setStarted] = useState(0);
  const [notStarted, setNotStarted] = useState(0);

  useEffect(() => {
    setStarted(pickupList.pickupList.started);
    setNotStarted(pickupList.pickupList.notStarted);
  }, [pickupList]);

  const chartData = {
    labels: ["Not started", "Started"],
    datasets: {
      label: "Pickup List",
      data: [notStarted, started],
    },
  };

  return (
    <ReportsBarChart color="secondary" title="Pickup List" chart={chartData} />
  );
};

const mapStateToProps = (state) => {
  return {
    pickupList: state.getPickupListHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickup: () => dispatch(getPickup()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupHomePageCard);
