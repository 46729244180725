export const GETLOCATION_BYWAREHOUSE_REQUEST =
  "GETLOCATION_BYWAREHOUSE_REQUEST";
export const GETLOCATION_BYWAREHOUSE_SUCCESS =
  "GETLOCATION_BYWAREHOUSE_SUCCESS";
export const GETLOCATION_BYWAREHOUSE_FAILURE =
  "GETLOCATION_BYWAREHOUSE_FAILURE";

export const ADDLOCATION_TOWAREHOUSE_REQUEST =
  "ADDLOCATION_TOWAREHOUSE_REQUEST";
export const ADDLOCATION_TOWAREHOUSE_SUCCESS =
  "ADDLOCATION_TOWAREHOUSE_SUCCESS";
export const ADDLOCATION_TOWAREHOUSE_FAILURE =
  "ADDLOCATION_TOWAREHOUSE_FAILURE";

export const ADD_RACK_TO_WAREHOUSE_REQUEST = "ADD_RACK_TO_WAREHOUSE_REQUEST";
export const ADD_RACK_TO_WAREHOUSE_SUCCESS = "ADD_RACK_TO_WAREHOUSE_SUCCESS";
export const ADD_RACK_TO_WAREHOUSE_FAILURE = "ADD_RACK_TO_WAREHOUSE_FAILURE";

export const GETITEM_BYLOCATION_REQUEST = "GETITEM_BYLOCATION_REQUEST";
export const GETITEM_BYLOCATION_SUCCESS = "GETITEM_BYLOCATION_SUCCESS";
export const GETITEM_BYLOCATION_FAILURE = "GETITEM_BYLOCATION_FAILURE";

export const GETLOCATION_BYWAREHOUSEANDZONE_REQUEST =
  "GETLOCATION_BYWAREHOUSEANDZONE_REQUEST";
export const GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS =
  "GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS";
export const GETLOCATION_BYWAREHOUSEANDZONE_FAILURE =
  "GETLOCATION_BYWAREHOUSEANDZONE_FAILURE";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILURE = "DELETE_LOCATION_FAILURE";

export const DELETE_RACK_REQUEST = "DELETE_RACK_REQUEST";
export const DELETE_RACK_SUCCESS = "DELETE_RACK_SUCCESS";
export const DELETE_RACK_FAILURE = "DELETE_RACK_FAILURE";

export const GET_RACKS_BY_ZONE_AND_CATEGORY_REQUEST =
  "GET_RACKS_BY_ZONE_AND_CATEGORY_REQUEST";
export const GET_RACKS_BY_ZONE_AND_CATEGORY_SUCCESS =
  "GET_RACKS_BY_ZONE_AND_CATEGORY_SUCCESS";
export const GET_RACKS_BY_ZONE_AND_CATEGORY_FAILURE =
  "GET_RACKS_BY_ZONE_AND_CATEGORY_FAILURE";

export const REORDER_RACKS_AND_ZONES_REQUEST =
  "REORDER_RACKS_AND_ZONES_REQUEST";
export const REORDER_RACKS_AND_ZONES_SUCCESS =
  "REORDER_RACKS_AND_ZONES_SUCCESS";
export const REORDER_RACKS_AND_ZONES_FAILURE =
  "REORDER_RACKS_AND_ZONES_FAILURE";

export const UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_REQUEST =
  "UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_REQUEST";
export const UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_SUCCESS =
  "UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_SUCCESS";
export const UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_WARNING =
  "UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_WARNING";
export const UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_FAILURE =
  "UPLOAD_EXCEL_FOR_CREATE_ZONE_RACK_LOCATION_FAILURE";

export const DOWNLOAD_WAREHOUSE_REQUEST = "DOWNLOAD_WAREHOUSE_REQUEST";
export const DOWNLOAD_WAREHOUSE_SUCCESS = "DOWNLOAD_WAREHOUSE_SUCCESS";
export const DOWNLOAD_WAREHOUSE_FAILURE = "DOWNLOAD_WAREHOUSE_FAILURE";

export const DOWNLOAD_ZONE_RACK_LOCATION_REQUEST = "DOWNLOAD_ZONE_RACK_LOCATION_REQUEST";
export const DOWNLOAD_ZONE_RACK_LOCATION_SUCCESS = "DOWNLOAD_ZONE_RACK_LOCATION_SUCCESS";
export const DOWNLOAD_ZONE_RACK_LOCATION_FAILURE = "DOWNLOAD_ZONE_RACK_LOCATION_FAILURE";
