import {
    GET_MATERIAL_RETURN_ORDER_REQUEST,
    GET_MATERIAL_RETURN_ORDER_SUCCESS,
    GET_MATERIAL_RETURN_ORDER_FAILURE,
  } from "./materialReturnOrderTypes";
  import axios from "axios";                               
  import Config from "../../../config/index";
  let baseUrl = Config.baseUrl;
  
  const getMaterialReturnOrderRequest = () => {
    return {
      type: GET_MATERIAL_RETURN_ORDER_REQUEST,
    };
  }; 
   
  
  const getMaterialReturnOrderSuccess = (payload) => {
    return {
      type: GET_MATERIAL_RETURN_ORDER_SUCCESS,
      payload: payload,
    };
  };
  
  const getMaterialReturnOrderFailure = (error) => {
    return {
      type: GET_MATERIAL_RETURN_ORDER_FAILURE,
      payload: error,
    };
  };
  
  const getMaterialReturnOrder = (jwtToken) => {
    let headers = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    return (dispatch) => {
      dispatch(getMaterialReturnOrderRequest());
      let url = `${baseUrl}/material_return_order/get_mrt`; 
      axios
        .get(url, headers)
        .then((response) => {
          dispatch(getMaterialReturnOrderSuccess(response.data));
        })
        .catch((err) => {
          dispatch(getMaterialReturnOrderFailure(err.message));
        });
    };
  };
  
  export { getMaterialReturnOrder };
  