// Dashboard components
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

const StandardButton = ({
  onClick,
  disabled,
  tooltip,
  icon,
  color,
  ...props
}) => {
  return (
    <Tooltip title={tooltip}>
      <MDButton
        onClick={onClick}
        disabled={disabled}
        variant="contained"
        iconOnly
        type="button"
        {...props}
        color={color}
      >
        <Icon>{icon}</Icon>
      </MDButton>
    </Tooltip>
  );
};

export default StandardButton;
