export const GET_FGMASTER_REQUEST = "GET_FGMASTER_REQUEST";
export const GET_FGMASTER_SUCCESS = "GET_FGMASTER_SUCCESS";
export const GET_FGMASTER_FAILURE = "GET_FGMASTER_FAILURE";

export const EDIT_FGMASTER_REQUEST = "EDIT_FGMASTER_REQUEST";
export const EDIT_FGMASTER_SUCCESS = "EDIT_FGMASTER_SUCCESS";
export const EDIT_FGMASTER_FAILURE = "EDIT_FGMASTER_FAILURE";

export const CREATE_FGMASTER_REQUEST = "CREATE_FGMASTER_REQUEST";
export const CREATE_FGMASTER_SUCCESS = "CREATE_FGMASTER_SUCCESS";
export const CREATE_FGMASTER_FAILURE = "CREATE_FGMASTER_FAILURE";

export const UPLOAD_CSV_FOR_FG_REQUEST = "UPLOAD_CSV_FOR_FG_REQUEST";
export const UPLOAD_CSV_FOR_FG_SUCCESS = "UPLOAD_CSV_FOR_FG_SUCCESS";
export const UPLOAD_CSV_FOR_FG_FAILURE = "UPLOAD_CSV_FOR_FG_FAILURE";

