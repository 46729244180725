/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import MDSnackbar from "components/MDSnackbar";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable/index";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getAsnDetails,
  updateVehicleDetails,
  getBinsData,
  printAsn,
  getLastAsnDetails,
  updateAsn,
  getPackageType,
  calculatePacketDetails,
  getDeliveryPlant,
  getUnloadingPoint,
  calculatePacketWeight,
  getBinsForPrint,
  getSubModulesByRoleId,
  confirmVirtualBins,
} from "../../store";

// Custom css
import "../../index.css";
import { CSVLink } from "react-csv";
import QRCode from "react-qr-code";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { useReactToPrint } from "react-to-print";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";

const cookies = new Cookies();

const styleForModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "80%", lg: "60%", xl: "60%" },
  height: "fit-content",
  maxHight: "60%",
  overflow: "auto",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForVerify = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForConfirmModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "80%", lg: "60%", xl: "60%" },
  height: "fit-content",
  maxHight: "60%",
  overflow: "auto",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForDocument = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "80%", lg: "60%", xl: "60%" },
  height: "fit-content",
  maxHight: "60%",
  overflow: "auto",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const printStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 370,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForQR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 196,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const styleForEditASN = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "80%", lg: "90%", xl: "90%" },
  height: "fit-content",
  maxHight: "60%",
  overflow: "auto",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const styleForGRN = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const styles = StyleSheet.create({
  page: {
    width: "100%",
    height: "100%",
    display: "flex",
    // flexDirection: "row",
    marginBottom: "50px",
    marginTop: "20px",
    // backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
  },
});

const styles2 = StyleSheet.create({
  page: {
    width: "100%",
    height: "100%",
    // display: "flex",
    // flexDirection: "row",
    marginBottom: "50px",
    marginTop: "20px",
    // marginLeft: "20px",

    // backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
  },
});

const AsnDetails = ({
  getAsnDetails,
  asnDetailsReducer,
  updateVehicleDetails,
  getBinsData,
  binsReducer,
  printAsn,
  getLastAsnDetails,
  lastAsnDetailsReducer,
  updateAsn,
  getPackageType,
  packageTypeReducer,
  calculatePacketDetails,
  packageDetailsReducer,
  getDeliveryPlant,
  getUnloadingPoint,
  deliveryPlantReducer,
  unloadingPointReducer,
  calculatePacketWeight,
  packetWeightReducer,
  getBinsForPrint,
  binsForPrintReducer,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  confirmVirtualBins,
}) => {
  let user = cookies.get("loginDetailsForWMS");
  let roleId = user.data.roleId;
  let userId = user.data.userId;
  let jwtToken = user?.jwt;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let asnDetailsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "8ec6366f-d5bd-4938-8900-e7fa44bbcaba"
  );

  let updateVehicleDetailsAccess = asnDetailsModule
    ? asnDetailsModule.actionId.includes("4ba8c87b-18cf-4300-a0d3-3d91441a358c")
    : null;
  // let createAccess = asnDetailsModule
  //   ? asnDetailsModule.actionId.includes("e1245a6d-2e1e-4fc6-97b2-64b71709103e")
  //   : null;

  // let uploadAccess = asnDetailsModule
  //   ? asnDetailsModule.actionId.includes("9f61c7dc-1e45-4bb6-8a25-6a1ca0aa2687")
  //   : null;

  let viewAccess = asnDetailsModule
    ? asnDetailsModule.actionId.includes("611265f2-60b8-4753-afc7-f8a7f39afcf9")
    : null;

  let updateAccess = asnDetailsModule
    ? asnDetailsModule.actionId.includes("33b78d26-329d-46e6-876f-701147a64d1c")
    : null;

  let printAsnAccess = asnDetailsModule
    ? asnDetailsModule.actionId.includes("585fa99d-6f21-49d7-a51b-9ebd9f0ad2ed")
    : null;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const component2Ref = useRef();

  const handlePrint2 = useReactToPrint({
    content: () => component2Ref.current,
  });

  const [binType, setBinType] = useState("");

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");

  const [state, setState] = useState({
    columns: [
      { Header: "ASN Code", accessor: "asnCode", align: "left" },
      { Header: "ASN Qty", accessor: "asnQty", align: "left" },
      { Header: "Part No", accessor: "partCode", align: "left" },
      { Header: "Invoice No", accessor: "invoiceNo", align: "left" },
      { Header: "Grn No", accessor: "grnNumber", align: "left" },

      { Header: "Created Date", accessor: "cd", align: "left" },
      { Header: "Shipped Date", accessor: "shippedDate", align: "left" },

      { Header: "Status", accessor: "status", align: "left" },
      // { Header: "View", accessor: "view", align: "left" },
      // { Header: "Edit", accessor: "edit", align: "left" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },

      // { Header: "QR Code", accessor: "action", align: "center" },
    ],

    rows: [],
  });

  const { columns, rows } = state;

  const [binsTable, setBinsTable] = useState({
    columns: [
      { Header: "Bin No", accessor: "binCode", align: "left" },
      { Header: "Shipped Qty", accessor: "qty", align: "left" },
      { Header: "Received Qty", accessor: "receivedQty", align: "left" },
    ],
    rows: [],
  });

  const [virtualBinsTable, setVirtualBinsTable] = useState({
    columns: [
      { Header: "Bin No", accessor: "binCode", align: "left" },
      { Header: "Shipped Qty", accessor: "qty", align: "left" },
      { Header: "Received Qty", accessor: "receivedQty", align: "left" },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
      {
        Header: "Verify",
        accessor: "verify",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns: binsColumns, rows: binsRows } =
    binType === 1 ? virtualBinsTable : binsTable;

  const [asnId, setAsnId] = useState("");

  const [singleASNDataForEdit, setSingleASNDataForEdit] = useState({});

  const [asnQtyChange, setAsnQtyChange] = useState("");

  const handleOpenEditASNModal = (data = {}) => {
    setSingleASNData(data);
    setSingleASNDataForEdit(data);
    setAsnQtyChange(data.asnQty);
    setSingleAsnPackageWeightData(data.packetWeight);
    setSingleAsnPackageDetailsData(data);
    setOpenEditASNModal(true);
    setIsErrorEditASN(false);
    setIsSuccessEditASN(false);
  };

  const [packageType, setPackageType] = useState("");

  const [partCode, setPartCode] = useState("");

  const [demandId, setDemandId] = useState("");

  const [asnCode, setAsnCode] = useState("");

  let today = new Date();

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  let currentDate = yyyy + "-" + mm + "-" + dd;

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  const [fileForTestCertificate, setFileForTestCertificate] = useState({});

  const handleChangeForTestCertificate = (event) => {
    setFileForTestCertificate(event.target.files[0]);
  };

  const [fileForInvoiceFile, setFileForInvoiceFile] = useState({});

  const handleChangeForInvoiceFile = (event) => {
    setFileForInvoiceFile(event.target.files[0]);
  };

  useEffect(() => {
    calculatePacketWeight(partCode, asnQtyChange, jwtToken);
  }, [partCode, asnQtyChange]);

  useEffect(() => {
    getDeliveryPlant(demandId, jwtToken);
  }, [demandId]);

  useEffect(() => {
    getUnloadingPoint(demandId, jwtToken);
  }, [demandId]);

  useEffect(() => {
    getBinsForPrint(asnCode, jwtToken);
  }, [asnCode]);

  const [singleDeliveryPlantData, setSingleDeliveryPlantData] = useState("");
  const [singleUnloadingPointData, setSingleUnloadingPointData] = useState("");

  const [singleAsnPackageTypeData, setSingleAsnPackageTypeData] = useState("");

  const [singleAsnPackageWeightData, setSingleAsnPackageWeightData] =
    useState("");

  const [singleAsnPackageDetailsData, setSingleAsnPackageDetailsData] =
    useState({});

  useEffect(() => {
    let data = packageTypeReducer.packageTypeData.data
      ? packageTypeReducer.packageTypeData.data
      : [];

    setSingleAsnPackageTypeData(data.packaging);
  }, [packageTypeReducer]);

  useEffect(() => {
    let data = packetWeightReducer.packetWeightData.data
      ? packetWeightReducer.packetWeightData.data
      : [];

    setSingleAsnPackageWeightData(data.packWeight);
  }, [packetWeightReducer]);

  useEffect(() => {
    let data = deliveryPlantReducer.deliveryPlantData.data
      ? deliveryPlantReducer.deliveryPlantData.data
      : [];

    setSingleDeliveryPlantData(data.deliveryPlant);
  }, [deliveryPlantReducer]);

  useEffect(() => {
    let data = unloadingPointReducer.unloadingPointData.data
      ? unloadingPointReducer.unloadingPointData.data
      : [];

    setSingleUnloadingPointData(data.unloadingPoint);
  }, [unloadingPointReducer]);

  useEffect(() => {
    let data = packageDetailsReducer.packageDetailsData.data
      ? packageDetailsReducer.packageDetailsData.data
      : [];
    setSingleAsnPackageDetailsData(data);
  }, [packageDetailsReducer]);

  useEffect(() => {
    getAsnDetails(userId, jwtToken);
  }, [userId]);

  const [printStatus, setPrintStatus] = useState("");
  const [virtualBinsData, setVirtualBinsData] = useState([]);

  let payload = {
    asnCode: asnCode,
    bins: [],
  };
  useEffect(() => {
    getBinsData(payload, jwtToken);
  }, [asnCode]);

  const [openPrintQRModal, setOpenPrintQRModal] = useState(false);

  const [singleDataForPrintQRModal, setSingleDataForPrintQRModal] = useState(
    {}
  );

  const handleOpenPrintQRModal = (qrData = {}) => {
    setSingleDataForPrintQRModal(qrData);
    setOpenPrintQRModal(true);
  };

  const handleClosePrintQRModal = () => {
    setOpenPrintQRModal(false);
  };

  const [openGrnDetailsModal, setOpenGrnDetailsModal] = useState(false);

  const [singleDataForGrnDetails, setSingleDataForGrnDetails] = useState({});

  const handleOpenGrnDetailsModal = (grnDetails = {}) => {
    setSingleDataForGrnDetails(grnDetails);
    setOpenGrnDetailsModal(true);
  };

  const handleCloseGrnDetailsModal = () => {
    setOpenGrnDetailsModal(false);
  };

  const [printData, setPrintData] = useState([]);

  let tempAsnDetailsData = [];

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let asnDetailsData = asnDetailsReducer.asnDetails.data
      ? asnDetailsReducer.asnDetails.data
      : [];
    setLoading(asnDetailsReducer.loading);
    asnDetailsData.map((data) => {
      // const formatDateForShippedDate = new Date(data.shippedDate);
      const formatDateForCD = new Date(data.cd);

      const singleData = {
        // privateKey: data.asnCode,
        asnCode: data.asnCode,
        asnQty: data.asnQty,

        partCode: data.itemCode,
        grnNumber: data.grnNumber,
        invoiceNo: data.invoiceNo,

        packageType: data.packageType,
        status: (
          <h4>
            <button
              className={
                (data.status == "Discrepancy" && `status-error`) ||
                (data.status == "GRN Created" && `status-success`) ||
                (data.status == "Created" && `status-created`) ||
                (data.status == "Loading Pending" && `status-warning`) ||
                (data.status == "Loading Done" && `status-done`) ||
                (data.status == "Shipped" && `status-warning`)
              }
              style={{ width: "5.5rem" }}
            >
              {data.status}
            </button>
          </h4>
        ),
        transporterName: data.transporterName,
        transporterType: data.transporterType,
        lrDocketno: data.lrDocketno,
        vehicleNamePlate: data.vehicleNamePlate,
        phoneNumber: data.phoneNumber,

        cd: data.cd,
        shippedDate: data.shippedDate,

        action: (
          <div>
            <div style={{ display: "flex" }}>
              {updateAccess && (
                <Tooltip title="ASN Update">
                  <MDButton
                    disabled={
                      data.status == "Shipped" ||
                      data.status == "GRN Created" ||
                      data.status == "Discrepancy" ||
                      data.status == "Loading Done"
                    }
                    variant="gradient"
                    color="success"
                    type="button"
                    iconOnly
                    onClick={() => {
                      setPartCode(data.itemCode);
                      setAsnCode(data.binCode);
                      getPackageType(data.itemCode, jwtToken);
                      // setDemandId(data.id);

                      handleOpenEditASNModal(data);
                    }}
                    style={{ marginRight: "5px", padding: "2%" }}
                  >
                    <Icon style={{ marginRight: "2px" }}>edit</Icon>
                  </MDButton>
                </Tooltip>
              )}

              {/* {viewAccess && ( */}
              <Tooltip title="View">
                <MDButton
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={() => {
                    setPartCode(data.itemCode);
                    setAsnCode(data.asnCode);
                    setBinType(data.isVirtualBin);
                    handleOpenViewASNModal(data);
                  }}
                  iconOnly
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
              {/* )} */}
            </div>

            <div style={{ display: "flex" }}>
              {data.status == "Created" ? (
                updateVehicleDetailsAccess && (
                  <Tooltip title="Confirm Details">
                    <MDButton
                      className={data.status == "Created" && `blink_me`}
                      disabled={
                        data.status == "Shipped" ||
                        data.status == "GRN Created" ||
                        data.status == "Discrepancy"
                      }
                      variant="gradient"
                      color="warning"
                      type="button"
                      onClick={() => {
                        setAsnCode(data.binCode);
                        handleOpenConfirmVehicleDetailsModal(data);
                      }}
                      iconOnly
                      style={{ marginRight: "5px", marginBottom: "5px" }}
                    >
                      <Icon> local_shipping </Icon>
                    </MDButton>
                  </Tooltip>
                )
              ) : (
                <>
                  {data.status == "Shipped" ||
                  data.status == "GRN Created" ||
                  data.status == "Discrepancy"
                    ? updateVehicleDetailsAccess && (
                        <MDButton
                          // className={data.status == "Loading Pending" && `blink_me`}
                          disabled={
                            data.status == "Shipped" ||
                            data.status == "GRN Created" ||
                            data.status == "Discrepancy"
                          }
                          variant="gradient"
                          color="white"
                          type="button"
                          onClick={() => {
                            setAsnCode(data.binCode);
                            handleOpenConfirmVehicleDetailsModal(data);
                          }}
                          iconOnly
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            background: "#BCBCBC",
                            color: "white",
                          }}
                        >
                          <Icon> local_shipping </Icon>
                        </MDButton>
                      )
                    : updateVehicleDetailsAccess && (
                        <Tooltip title="Confirm Details">
                          <MDButton
                            // className={data.status == "Loading Pending" && `blink_me`}
                            disabled={
                              data.status == "Shipped" ||
                              data.status == "GRN Created" ||
                              data.status == "Discrepancy"
                            }
                            variant="gradient"
                            color="warning"
                            type="button"
                            onClick={() => {
                              setAsnCode(data.binCode);
                              handleOpenConfirmVehicleDetailsModal(data);
                            }}
                            iconOnly
                            style={{ marginRight: "5px", marginBottom: "5px" }}
                          >
                            <Icon> local_shipping </Icon>
                          </MDButton>
                        </Tooltip>
                      )}
                </>
              )}

              {data.status == "Shipped" ||
              data.status == "GRN Created" ||
              data.status == "Discrepancy"
                ? printAsnAccess && (
                    <Tooltip title="Reprint ASN">
                      <MDButton
                        // disabled={data.status !== "Loading Done"}
                        variant="gradient"
                        // color="white"
                        style={{ background: "#0BB9B3", color: "white" }}
                        type="button"
                        onClick={() => {
                          setAsnCode(data.asnCode);
                          setPrintStatus(data.status);
                          // handlePrintAsn();
                          // printAsn(data.asnCode, jwtToken);
                          setPrintAsnDetailsPartCode(data.itemCode);
                          setPrintAsnDetailsPartDescription(
                            data.partDescription
                          );
                          setPrintAsnDetailsGrnNumber(data.grnNumber);
                          setPrintAsnDetailsLRNumber(data.lrDocketNo);
                          setPrintAsnDetailsTransporterName(
                            data.transporterName
                          );
                          setPrintAsnDetailsTransporterType(
                            data.transporterType
                          );
                          setPrintAsnDetailsVehicleNameplate(
                            data.vehicleNamePlate
                          );
                          setPrintAsnDetailsPhoneNumber(data.phoneNumber);
                          setPrintAsnDetailsInvoiceNo(data.invoiceNo);
                          setPrintAsnDetailsVendorCode(data.vendorCode);
                          setPrintAsnDetailsVendorName(data.vendorName);
                          setPrintAsnDetailsShippedDate(data.shippedDate);
                          setPrintAsnDetailsCreatedDate(data.cd);
                          setPrintAsnDetailsAsnQty(data.asnQty);
                          setPrintAsnDetailsPacketQty(data.packetQty);
                          setPrintAsnDetailsPacketWeight(data.packetWeight);
                          setPrintAsnDetailsInvoiceDate(data.invoiceDate);
                          handleOpenPrintASNModal(data);
                        }}
                        iconOnly
                        // style={{ padding: "2%", width: "100px" }}
                      >
                        {/* Print ASN */}
                        <Icon> print </Icon>
                      </MDButton>
                    </Tooltip>
                  )
                : data.status == "Loading Done"
                ? printAsnAccess && (
                    <Tooltip title="Print ASN">
                      <MDButton
                        disabled={data.status !== "Loading Done"}
                        className={data.status == "Loading Done" && `blink_me`}
                        variant="gradient"
                        style={{ background: "#0BB9B3", color: "white" }}
                        type="button"
                        onClick={() => {
                          setAsnCode(data.asnCode);
                          // handlePrintAsn();
                          printAsn(data.asnCode, jwtToken);
                          setPrintAsnDetailsPartCode(data.itemCode);
                          setPrintAsnDetailsPartDescription(
                            data.partDescription
                          );
                          setPrintAsnDetailsGrnNumber(data.grnNumber);
                          setPrintAsnDetailsLRNumber(data.lrDocketNo);
                          setPrintAsnDetailsTransporterName(
                            data.transporterName
                          );
                          setPrintAsnDetailsTransporterType(
                            data.transporterType
                          );
                          setPrintAsnDetailsVehicleNameplate(
                            data.vehicleNamePlate
                          );
                          setPrintAsnDetailsPhoneNumber(data.phoneNumber);
                          setPrintAsnDetailsInvoiceNo(data.invoiceNo);
                          setPrintAsnDetailsVendorCode(data.vendorCode);
                          setPrintAsnDetailsVendorName(data.vendorName);
                          setPrintAsnDetailsShippedDate(data.shippedDate);
                          setPrintAsnDetailsCreatedDate(data.cd);
                          setPrintAsnDetailsAsnQty(data.asnQty);
                          setPrintAsnDetailsPacketQty(data.packetQty);
                          setPrintAsnDetailsPacketWeight(data.packetWeight);
                          setPrintAsnDetailsInvoiceDate(data.invoiceDate);
                          handleOpenPrintASNModal(data);
                        }}
                        iconOnly
                        // style={{ padding: "2%", width: "100px" }}
                      >
                        {/* Print ASN */}
                        <Icon> print </Icon>
                      </MDButton>
                    </Tooltip>
                  )
                : printAsnAccess && (
                    <MDButton
                      disabled={data.status !== "Loading Done"}
                      className={data.status == "Loading Done" && `blink_me`}
                      variant="gradient"
                      style={{ background: "#BCBCBC", color: "white" }}
                      type="button"
                      iconOnly
                    >
                      <Icon> print </Icon>
                    </MDButton>
                  )}
            </div>
          </div>
        ),
        grnNumber: data.grnNumber,

        ebn: data.ebn,
        totalAsnQty: data.totalAsnQty,
        totalAmount: data.totalAmount,
        isPrinted: data.isPrinted,
      };

      tempAsnDetailsData.push(singleData);
    });

    viewAccess && setState({ ...state, rows: tempAsnDetailsData });
  }, [asnDetailsReducer]);

  useEffect(() => {
    let tempBins = [];
    let data = binsForPrintReducer.binsForPrintData.data
      ? binsForPrintReducer.binsForPrintData.data
      : [];

    setPrintData(data);
  }, [binsForPrintReducer]);

  let tempBinsData = [];

  useEffect(() => {
    let binsData = binsReducer.bins.data ? binsReducer.bins.data : [];
    setVirtualBinsData(binsData);
    binsData.map((data) => {
      // const formatDateForShippedDate = new Date(data.shippedDate);

      const singleData = {
        privateKey: data.binCode,
        binCode: data.binCode,
        qty: data.qty,
        status:
          data.isVerified === true ? (
            <Tooltip title="Verified">
              <Icon
                color="success"
                size="large"
                style={{ marginRight: "2px", fontSize: "15px" }}
              >
                check_circle
              </Icon>
            </Tooltip>
          ) : (
            <Tooltip title="Not Verified">
              <Icon
                color="error"
                size="large"
                style={{ marginRight: "2px", fontSize: "15px" }}
              >
                check_circle
              </Icon>
            </Tooltip>
          ),
        receivedQty: data.receivedQty,
        // abc: data.isVerified === true && setYY(data),
        verify: !data.isVerified ? (
          <Tooltip title="Verify">
            <MDButton
              disabled={
                data.status !== "Loading Pending" || data.status !== "Created"
              }
              variant="gradient"
              color="success"
              type="button"
              iconOnly
              onClick={() => {
                setPartCode(data.itemCode);
                setAsnCode(data.asnCode);
                // setDemandId(data.id);

                handleOpenUpdateCNFQTYModal(data);
              }}
              style={{ marginRight: "5px", padding: "2%" }}
            >
              <Icon style={{ marginRight: "2px" }}>edit</Icon>
            </MDButton>
          </Tooltip>
        ) : (
          <Tooltip title="Verified">
            <MDButton
              disabled
              variant="gradient"
              color="success"
              type="button"
              iconOnly
              style={{ marginRight: "5px", padding: "2%" }}
            >
              <Icon style={{ marginRight: "2px" }}>edit</Icon>
            </MDButton>
          </Tooltip>
        ),
      };

      tempBinsData.push(singleData);
    });

    binType === 1
      ? setVirtualBinsTable({ ...virtualBinsTable, rows: tempBinsData })
      : setBinsTable({ ...binsTable, rows: tempBinsData });
  }, [binsReducer]);

  const [flag, setFlag] = useState(false);
  const [lastAsnSingleData, setLastAsnSingleData] = useState("");

  const [printAsnDetailsPartCode, setPrintAsnDetailsPartCode] = useState("");
  const [printAsnDetailsInvoiceNo, setPrintAsnDetailsInvoiceNo] = useState("");
  const [printAsnDetailsTransporterName, setPrintAsnDetailsTransporterName] =
    useState("");
  const [printAsnDetailsVehicleNameplate, setPrintAsnDetailsVehicleNameplate] =
    useState("");
  const [printAsnDetailsTransporterType, setPrintAsnDetailsTransporterType] =
    useState("");
  const [printAsnDetailsLRNumber, setPrintAsnDetailsLRNumber] = useState("");
  const [printAsnDetailsPhoneNumber, setPrintAsnDetailsPhoneNumber] =
    useState("");

  const [printAsnDetailsPartDescription, setPrintAsnDetailsPartDescription] =
    useState("");
  const [printAsnDetailsAsnQty, setPrintAsnDetailsAsnQty] = useState("");
  const [printAsnDetailsGrnNumber, setPrintAsnDetailsGrnNumber] = useState("");
  const [printAsnDetailsInvoiceDate, setPrintAsnDetailsInvoiceDate] =
    useState("");
  const [printAsnDetailsPacketQty, setPrintAsnDetailsPacketQty] = useState("");
  const [printAsnDetailsPacketWeight, setPrintAsnDetailsPacketWeight] =
    useState("");
  const [printAsnDetailsShippedDate, setPrintAsnDetailsShippedDate] =
    useState("");

  const [printAsnDetailsCreatedDate, setPrintAsnDetailsCreatedDate] =
    useState("");
  const [printAsnDetailsVendorCode, setPrintAsnDetailsVendorCode] =
    useState("");
  const [printAsnDetailsVendorName, setPrintAsnDetailsVendorName] =
    useState("");

  const handleLastAsnData = () => {
    getLastAsnDetails(userId, jwtToken);
    setLastAsnSingleData(lastAsnData);
    setFlag(true);
  };

  let lastAsnData = lastAsnDetailsReducer.lastAsnDetails.data
    ? lastAsnDetailsReducer.lastAsnDetails.data[0]
    : [];

  // useEffect(() => {
  //   getAsnItemsDetails(asnId);
  // }, [asnId]);

  // useEffect(() => {
  //   let tempAsnItemsDetailsData = [];
  //   let data = asnItemsDetailsReducer.asnItemsDetails.data
  //     ? asnItemsDetailsReducer.asnItemsDetails.data
  //     : [];

  //   data.map((data) => {
  //     const asnItemlistObject = {
  //       privateKey: data.itemId,
  //       orderNumber: data.orderNumber,

  //       totalAmount: data.totalAmount,

  //       lineItemNumber: data.lineItemNumber,
  //       itemCode: data.itemCode,
  //       item: data.item,
  //       itemQty: data.itemQty,
  //       asnQty: data.asnQty,

  //       // remainingQty: data.remainingQty,
  //       amount: data.amount,
  //       receivingDate: data.receivingDate,
  //     };
  //     tempAsnItemsDetailsData.push(asnItemlistObject);
  //   });
  //   setListOfItemsState({ ...listOfItemsState, rows: tempAsnItemsDetailsData });
  // }, [asnItemsDetailsReducer]);

  // const clickHandler = () => {
  //   navigate("/purchase-orders");
  // };

  // const confirmVehicleDetailsAlertNotification = () =>
  // toast.warning('🦄 Wow so easy!', {
  //   position: "top-right",
  //   autoClose: false,
  //   hideProgressBar: false,
  //   closeOnClick: false,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "colored",
  //   });

  // useEffect(() => {
  //   confirmVehicleDetailsAlertNotification();
  // }, [third])

  const confirmVehicleDetailsNotification = () =>
    toast.success("Vehicle Details Submitted Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const virtualBinUpdateNotification = () =>
    toast.success("Virtual Bins Confirmed Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const [isErrorAcceptPo, setIsErrorAcceptPo] = useState(false);

  useEffect(() => {
    if (isErrorAcceptPo) {
      setTimeout(() => {
        setIsErrorAcceptPo(false);
      }, 3000);
    }
  }, [isErrorAcceptPo]);

  const asnPrintNotification = () =>
    toast.success("ASN Printed Successfully", {
      position: "top-center",
    });

  // const printQRHandler = async () => {
  //   let payload = {
  //     asnCode: singleDataForPrintQRModal.asnCode,
  //     totalAmount: singleDataForPrintQRModal.totalAmount,
  //     receivingDate: singleDataForPrintQRModal.receivingDate,
  //     ebn: singleDataForPrintQRModal.ebn,
  //     // isPrinted: singleDataForPrintQRModal.isPrinted
  //   };

  //   let response = await createPrintQR(payload);

  //   if (response.status) {
  //     handleClosePrintQRModal();

  //     setTimeout(() => {
  //       getAsnDetails(emailId);
  //     }, 0);

  //     acceptedPoNotification();
  //   }
  //   if (response.status == false) {
  //     setIsErrorAcceptPo(true);
  //   }
  // };

  const [openConfirmASNModal, setOpenConfirmASNModal] = useState(false);

  const handleOpenConfirmASNModal = () => {
    setOpenConfirmASNModal(!openConfirmASNModal);
  };

  const [openViewASNModal, setOpenViewASNModal] = useState(false);

  const [singleASNData, setSingleASNData] = useState({});
  const handleOpenViewASNModal = (asnData = {}) => {
    setSingleASNData(asnData);
    setOpenViewASNModal(true);
    cookies.set("detailsForVirtualBin", JSON.stringify([]), {
      path: "/",
    });
  };
  const handleCloseViewASNModal = () => {
    setOpenViewASNModal(false);
    // formikForUpdate.setErrors({});
  };

  const [document, setDocument] = useState("");

  const [openPrintASNModal, setOpenPrintASNModal] = useState(false);
  const handleOpenPrintASNModal = (data = {}) => {
    setDocument(data.url);
    setOpenPrintASNModal(true);
  };
  const handleClosePrintASNModal = () => {
    setOpenPrintASNModal(false);
    getAsnDetails(userId, jwtToken);
  };

  const [isErrorConfirmVehicleDetails, setIsErrorConfirmVehicleDetails] =
    useState(false);
  const [isSuccessConfirmVehicleDetails, setIsSuccessConfirmVehicleDetails] =
    useState(false);

  useEffect(() => {
    if (isErrorConfirmVehicleDetails) {
      setTimeout(() => {
        setIsErrorConfirmVehicleDetails(false);
      }, 3000);
    }
  }, [isErrorConfirmVehicleDetails]);

  useEffect(() => {
    if (isSuccessConfirmVehicleDetails) {
      setTimeout(() => {
        setIsSuccessConfirmVehicleDetails(false);
      }, 3000);
    }
  }, [isSuccessConfirmVehicleDetails]);

  const [openConfirmVehicleDetailsModal, setOpenConfirmVehicleDetailsModal] =
    useState(false);

  const handleOpenConfirmVehicleDetailsModal = (data = {}) => {
    setSingleASNData(data);
    setOpenConfirmVehicleDetailsModal(true);
    setIsErrorConfirmVehicleDetails(false);
    setIsSuccessConfirmVehicleDetails(false);
  };
  const handleCloseConfirmVehicleDetailsModal = () => {
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
    setOpenConfirmVehicleDetailsModal(false);
    setFlag(false);
    setLastAsnSingleData("");
    getAsnDetails(userId, jwtToken);
  };

  const initialValues = {
    asnCode: singleASNData ? singleASNData.asnCode : "",
    invoiceNo: singleASNData ? singleASNData.invoiceNo : "",
    partCode: singleASNData ? singleASNData.itemCode : "",

    transporterName: lastAsnData ? lastAsnData.transporterName : "",
    transporterType: lastAsnData ? lastAsnData.transporterType : "",
    vehicleNamePlate: lastAsnData ? lastAsnData.vehicleNamePlate : "",
    phoneNumber: lastAsnData ? lastAsnData.phoneNumber : "",
    lrDocketNo: lastAsnData ? lastAsnData.lrDocketNo : "",
  };

  const validationSchema = Yup.object({
    transporterName: Yup.string().required("Enter Transporter Name"),
    transporterType: Yup.string().required("Enter Transporter Type"),
    vehicleNamePlate: Yup.string().required("Enter Vehicle NamePlate"),
    phoneNumber: Yup.string().required("Enter a valid Phone Number"),
    lrDocketNo: Yup.string().required("Enter LR Docket No"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let payload = {
      asnCode: values.asnCode,
      transporterName: values.transporterName,
      transporterType: values.transporterType,
      vehicleNamePlate: values.vehicleNamePlate,
      phoneNumber: values.phoneNumber,
      lrDocketNo: values.lrDocketNo,
    };

    let response = await updateVehicleDetails(payload, jwtToken);
    if (response.status === true) {
      setIsSuccessConfirmVehicleDetails(true);
      confirmVehicleDetailsNotification();
      handleCloseConfirmVehicleDetailsModal();

      getAsnDetails(userId, jwtToken);

      resetForm();
    }
    if (response.status === false) {
      setIsErrorConfirmVehicleDetails(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handlePrintAsn = async () => {
    let payload = {
      asnCode: asnCode,
    };

    const res = await printAsn(payload, jwtToken);

    // const res = await createProductionOrder(payload);

    if (res.status) {
      asnPrintNotification();
      setOpenPrintASNModal(false);
      getAsnDetails(userId, jwtToken);
      // setOpenBomItemModal(!bomItemModal);
    }
    // if (!res.status) {
    //   setIsErrorCreateOrder(true);
    // }
  };

  const AsnUpdatedNotification = () =>
    toast.success("Asn Updated Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const [openEditASNModal, setOpenEditASNModal] = useState(false);

  const handleCloseEditASNModal = () => {
    setOpenEditASNModal(false);
    setSingleASNDataForEdit({});
    setAsnQtyChange("");
    setSingleAsnPackageWeightData("");
    setSingleAsnPackageDetailsData({});

    formikForEdit.setValues(initialValuesForEditAsn);
    formikForEdit.setTouched({});
    formikForEdit.setErrors({});
  };

  const [isErrorEditASN, setIsErrorEditASN] = useState(false);
  const [isSuccessEditASN, setIsSuccessEditASN] = useState(false);

  useEffect(() => {
    if (isErrorEditASN) {
      setTimeout(() => {
        setIsErrorEditASN(false);
      }, 3000);
    }
  }, [isErrorEditASN]);

  useEffect(() => {
    if (isSuccessEditASN) {
      setTimeout(() => {
        setIsSuccessEditASN(false);
      }, 3000);
    }
  }, [isSuccessEditASN]);

  const initialValuesForEditAsn = {
    demandId: singleASNDataForEdit ? singleASNDataForEdit.id : "",
    partNoDes: `${singleASNDataForEdit?.itemCode}/${singleASNDataForEdit?.itemDescription}`,
    poNoLineNo: `${
      singleASNDataForEdit ? singleASNDataForEdit.purchaseOrderNumber : ""
    }/${
      singleASNDataForEdit ? singleASNDataForEdit.purchaseOrderLineNumber : ""
    }`,
    scheduleQuantity: singleASNDataForEdit
      ? singleASNDataForEdit.scheduleQty
      : "",
    asnQty: asnQtyChange ? asnQtyChange : "",
    balanceQty: asnQtyChange
      ? singleASNDataForEdit.balanceQty +
        singleASNDataForEdit.asnQty -
        asnQtyChange
      : singleASNDataForEdit.balanceQty,
    unloadingPoint: singleUnloadingPointData ? singleUnloadingPointData : "",
    deliveryPlant: singleDeliveryPlantData ? singleDeliveryPlantData : "",
    packageType: singleAsnPackageTypeData ? singleAsnPackageTypeData : "",
    packetNo: singleAsnPackageDetailsData
      ? singleAsnPackageDetailsData.packetQty
      : singleASNDataForEdit.packetQty,
    unitPackQty: singleAsnPackageDetailsData
      ? singleAsnPackageDetailsData.unitPackQty
      : singleASNDataForEdit.unitPackQty,

    packetWeight: singleAsnPackageWeightData ? singleAsnPackageWeightData : "",
    invoiceNo: singleASNDataForEdit ? singleASNDataForEdit.invoiceNo : "",
    invoiceDate: singleASNDataForEdit ? singleASNDataForEdit.invoiceDate : "",
    // invoiceFile: "",
    planDate: "",
  };

  const validationSchemaForEdit = Yup.object({
    asnQty: Yup.number()
      .min(1)
      .max(singleASNDataForEdit.balanceQty)
      .required("Enter ASN Quantity"),
    invoiceNo: Yup.string().required("Enter Invoice No"),
    invoiceDate: Yup.string().required("Enter Invoice Date"),
  });

  const onSubmitForEdit = async (values, { resetForm }) => {
    let payload = {
      testCertificate: fileForTestCertificate,
      invoice: fileForInvoiceFile,
      invoiceNo: values.invoiceNo,
      invoiceDate: values.invoiceDate,
      planDate: singleASNDataForEdit ? singleASNDataForEdit.planDate : "",
      partNoDes: values.partNoDes,
      poNoLineNo: values.poNoLineNo,
      asnCode: asnCode,
      asnQty: asnQtyChange,
      packageType: singleAsnPackageTypeData,
      packetNo: singleAsnPackageDetailsData.packetQty,
      packetWeight: singleAsnPackageWeightData,
      unloadingPoint: singleASNDataForEdit.unloadingPoint,
      deliveryPlant: singleASNDataForEdit.deliveryPlant,
      // demandId: singleASNData ? singleASNData.id : "",
      vendorId: userId,
    };

    let response = await updateAsn(payload, jwtToken);

    if (response.data.status == true) {
      setAsnCode(response.data.data.asnCode);
      // setPackageType(response.data.data.packageType);

      setIsSuccessEditASN(true);
      AsnUpdatedNotification();
      setOpenEditASNModal(false);

      getAsnDetails(userId, jwtToken);
      resetForm();
    }
    if (response.status === false) {
      setIsErrorEditASN(true);
    }
  };

  const formikForEdit = useFormik({
    initialValues: initialValuesForEditAsn,
    onSubmit: onSubmitForEdit,
    validationSchema: validationSchemaForEdit,
    enableReinitialize: true,
  });

  const [openPrintMultipleBinsOfASNModal, setOpenPrintMultipleBinsOfASNModal] =
    useState(false);

  const handleClosePrintMultipleBinsOfASNModal = () => {
    setOpenPrintMultipleBinsOfASNModal(false);
  };

  const handleOpenPrintMultipleBinsOfASNModal = () => {
    setOpenPrintMultipleBinsOfASNModal(true);
  };

  const [selectedConfirmVirtualBinsData, setSelectedConfirmVirtualBinsData] =
    useState([]);

  const [validationErrorMsg, setValidationErrorMsg] = useState("");

  useEffect(() => {
    setValidationErrorMsg("");
  }, [selectedConfirmVirtualBinsData]);

  const [resourceCategoryValidation, setResourceCategoryValidation] =
    useState(false);

  const [uncheck, setUncheck] = useState(false);
  const [binvalue, setbinvalue] = useState("");

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let value = virtualBinsData.some((data) => data.isVerified == false);

    setDisableButton(value);
  }, [virtualBinsData]);

  const pivDataSelectionHandler = (data) => {
    setSelectedConfirmVirtualBinsData(data);
  };

  const [isErrorConfirmVirtualBins, setIsErrorConfirmVirtualBins] =
    useState(false);
  const [isSuccessConfirmVirtualBins, setIsSuccessConfirmVirtualBins] =
    useState(false);

  useEffect(() => {
    if (isErrorConfirmVirtualBins) {
      setTimeout(() => {
        setIsErrorConfirmVirtualBins(false);
      }, 3000);
    }
  }, [isErrorConfirmVirtualBins]);

  useEffect(() => {
    if (isSuccessConfirmVirtualBins) {
      setTimeout(() => {
        setIsSuccessConfirmVirtualBins(false);
      }, 3000);
    }
  }, [isSuccessConfirmVirtualBins]);

  const [verificationStatus, setVerificationStatus] = useState(false);

  const [openEditFromLocModal, setOpenEditFromLocModal] = useState(false);

  const handleOpenEditFromLocModal = () => {
    setOpenEditFromLocModal(true);
  };

  const handleCloseEditFromLocModal = () => {
    setOpenEditFromLocModal(false);
  };

  const [resourceSelected, setResourceSelected] = useState("");
  const handleResourceChange = (selectedResource) => {
    setResourceSelected(selectedResource);
  };

  const [openConfirmVirtualBinsModal, setOpenConfirmVirtualBinsModal] =
    useState(false);

  const handleOpenConfirmVirtualBinsModal = () => {
    setOpenConfirmVirtualBinsModal(true);
  };

  const handleCloseConfirmVirtualBinsModal = () => {
    setOpenConfirmVirtualBinsModal(false);
  };

  const releaseValidationHandler = async (values) => {
    let finalPayload = {
      asnCode: asnCode,
      bins: virtual,
    };

    let res = await confirmVirtualBins(finalPayload, jwtToken);

    if (res.status) {
      setIsSuccessConfirmVirtualBins(true);
      virtualBinUpdateNotification();
      handleCloseViewASNModal();
      getAsnDetails(userId, jwtToken);
      // resetForm();

      setTimeout(() => {
        handleCloseConfirmVirtualBinsModal();
      }, 2250);
    }
    if (!res.status) {
      setValidationErrorMsg("Could not confirm");
    }
  };

  const [openUpdateCNFQTYModal, setOpenUpdateCNFQTYModal] = useState(false);
  const [
    singleMonthlyDemandDataForUpdate,
    setSingleMonthlyDemandDataForUpdate,
  ] = useState({});
  const handleOpenUpdateCNFQTYModal = (CNFQTY = {}) => {
    setSingleMonthlyDemandDataForUpdate(CNFQTY);
    setOpenUpdateCNFQTYModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateCNFQTYModal = () => {
    setOpenUpdateCNFQTYModal(false);
    formikForVerify.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  let virtual = cookies.get("detailsForVirtualBin");

  const initialValueForUpdate = {
    // departmentId: singleDepartmentDataForUpdate.departmentId,

    remark: singleMonthlyDemandDataForUpdate.remark,
  };

  const validationSchemaForUpdate = Yup.object({
    // remark: Yup.string().required("Enter a valid remark"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = [];

    let payload2 = {
      asnCode: asnCode,
      bins: virtual,
    };

    if (values.remark === singleMonthlyDemandDataForUpdate.binCode) {
      // let finalVirtualBin = {values.remark}

      const tempPayload = {
        binCode: values.remark,
      };

      virtual.push(tempPayload);
      cookies.set("detailsForVirtualBin", JSON.stringify(virtual), {
        path: "/",
      });

      // payload.push({
      //   binCode : values.remark
      // })
      getBinsData(payload2, jwtToken);
      setTimeout(() => {
        handleCloseUpdateCNFQTYModal();
      }, 2000);

      setIsSuccessForUpdate(true);
      setVerificationStatus(true);
    } else {
      setIsErrorForUpdate(true);
    }
  };

  const formikForVerify = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    // enableReinitialize: true,
  });

  const headers = [
    {
      label: "asnCode",
      key: "asnCode",
    },
    {
      label: "asnQty",
      key: "asnQty",
    },
    {
      label: "partCode",
      key: "partCode",
    },
    {
      label: "grnNumber",
      key: "grnNumber",
    },
    {
      label: "invoiceNo",
      key: "invoiceNo",
    },
    {
      label: "createdDate",
      key: "cd",
    },
    {
      label: "shippedDate",
      key: "shippedDate",
    },
  ];

  const csvLink = {
    filename: "Asn List.csv",
    headers: headers,
    data: rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-4}
                mt={-4}
              >
                <MDBox display="flex" marginTop="30px">
                  <CSVLink {...csvLink}>
                    <DownloadDataButton
                      tooltip="Download Asn List"
                      style={{ marginRight: "0.5rem" }}
                      type="button"
                      variant="outlined"
                      color="success"
                      iconOnly
                      title="Download Asn List"
                    >
                      <Icon>download</Icon>
                    </DownloadDataButton>
                  </CSVLink>
                </MDBox>
              </MDBox>

              <MDBox pt={1}>
                {/* {role === "user" ? ( */}
                <>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      totalRecords={rows?.length}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </>
                {/* ) : null} */}
              </MDBox>

              {/* <Modal
                  open={openPrintQRModal}
                  onClose={handleClosePrintQRModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={styleForQR}>
                    <div
                      onClick={handleClosePrintQRModal}
                      style={{
                        float: "right",
                        textAlign: "right",
                        marginTop: "-15px",
                        marginRight: "-13px",
                        cursor: "pointer",
                      }}
                    >
                      <MDTypography>
                        <Tooltip title="Close">
                          <Icon>cancel</Icon>
                        </Tooltip>
                      </MDTypography>
                    </div>
                    <MDTypography variant="h5" mb={2} ml={2.5}>
                      Print QR Code
                    </MDTypography>
                    <MDCard
                      mt={2}
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        border: "1px solid #4caf50",
                        paddingTop: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{ textAlign: "center" }}
                        onClick={printQRHandler}
                      >
                        <Tooltip title="Print">
                          <Icon fontSize="large" color="success">
                            print
                          </Icon>
                        </Tooltip>
                      </div>
                    </MDCard>
                    <MDCard mt={2}>
                      <Collapse in={isErrorAcceptPo}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAcceptPo(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not remove item from asn !
                        </Alert>
                      </Collapse>
                    </MDCard>
                  </Card>
                </Modal> */}
              <Modal
                open={openViewASNModal}
                onClose={handleCloseViewASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForModal}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      View ASN
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseViewASNModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pb={3} px={1} overflow="auto" height="500px">
                    <MDBox pt={1} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        // onSubmit={formik.handleSubmit}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={4}>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Vendor Code
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.vendorCode}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Vendor Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.vendorName}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                ASN No
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.asnCode}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                ASN Qty
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.asnQty}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice No
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.invoiceNo}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice Date
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData?.invoiceDate?.slice(0, 10)}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Part No
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.partCode}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                GRN No
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.grnNumber}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Created Date
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.cd}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Shipped Date
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.shippedDate}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Transporter Type
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.transporterType}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Transporter Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.transporterName}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Vehicle Nameplate
                              </MDTypography>
                              <MDInput
                                type="text"
                                fullWidth
                                value={singleASNData.vehicleNamePlate}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                LR Number
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="lrDocketNo"
                                fullWidth
                                value={singleASNData.lrDocketNo}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={1} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Phone Number
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="phoneNumber"
                                fullWidth
                                value={singleASNData.phoneNumber}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 10,
                                }}
                              />
                            </MDBox>
                            <MDBox style={{ display: "flex", float: "right" }}>
                              <MDButton
                                color="success"
                                type="button"
                                marginLeft="20px"
                                onClick={handleOpenPrintMultipleBinsOfASNModal}
                                disabled={
                                  !singleAsnPackageTypeData?.includes("Carton")
                                }
                              >
                                Reprint PKT QTY
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>

                        <MDBox pt={-1} overflow="auto" maxHight="90%">
                          {binType === 1 && (
                            <Tooltip title="Confirm">
                              <MDButton
                                disabled={disableButton}
                                color="white"
                                type="button"
                                style={{
                                  width: "10rem",
                                  marginTop: "0.5rem",
                                  color: "white",
                                  border: "1px solid #0275d8",
                                  background: "#0275d8",
                                  // marginLeft: 5,
                                }}
                                onClick={() => {
                                  releaseValidationHandler();
                                }}
                              >
                                Confirm
                              </MDButton>
                            </Tooltip>
                          )}
                          {/* {role === "user" ? ( */}
                          <>
                            <MDBox marginRight="-800px">
                              <DataTable
                                table={{ columns: binsColumns, rows: binsRows }}
                                isSorted={true}
                                // selection={true}
                                // selectionButtonClick={pivDataSelectionHandler}
                                // selectionButtonComponent={
                                //   <span
                                //     style={{
                                //       display: "flex",
                                //       alignItems: "center",
                                //     }}
                                //   >
                                //     <span>
                                //       <Tooltip title="Confirm">
                                //         <MDButton
                                //           disabled={disableButton}
                                //           color="white"
                                //           type="button"
                                //           style={{
                                //             width: "10rem",
                                //             marginTop: "0.5rem",
                                //             color: "white",
                                //             border: "1px solid #0275d8",
                                //             background: "#0275d8",
                                //             // marginLeft: 5,
                                //           }}
                                //           onClick={() => {
                                //             releaseValidationHandler();
                                //           }}
                                //         >
                                //           Confirm
                                //         </MDButton>
                                //       </Tooltip>
                                //     </span>
                                //     <span>
                                //       {validationErrorMsg && (
                                //         <>
                                //           <MDTypography
                                //             color="error"
                                //             variant="p"
                                //             style={{
                                //               // textAlign: "center",
                                //               marginLeft: "1rem",
                                //               marginTop: "1rem",
                                //             }}
                                //           >
                                //             {validationErrorMsg}
                                //           </MDTypography>
                                //         </>
                                //       )}
                                //       {/* {disableButton && (
                                //         <MDTypography
                                //           color="error"
                                //           variant="p"
                                //           style={{
                                //             // textAlign: "center",
                                //             marginLeft: "1rem",
                                //             marginTop: "1rem",
                                //           }}
                                //         >
                                //           Please Verify and select all bins!
                                //         </MDTypography>
                                //       )} */}
                                //       {/* {resourceCategoryValidation && (
                                //         <>
                                //           <br />
                                //           <MDTypography
                                //             color="error"
                                //             variant="p"
                                //             style={{
                                //               // textAlign: "center",
                                //               marginLeft: "1rem",
                                //               // marginTop: "1rem",
                                //             }}
                                //           >
                                //             Select records with same "Resource
                                //             Category"!
                                //           </MDTypography>
                                //         </>
                                //       )} */}
                                //     </span>
                                //   </span>
                                // }
                                // canSearch={true}
                                // entriesPerPage={{
                                //   defaultValue: 2,
                                //   // entries: [5, 10, 15, 20, 25, 30],
                                // }}

                                showTotalEntries={true}
                                noEndBorder
                              />
                            </MDBox>
                          </>

                          {/* ) : null} */}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openConfirmVehicleDetailsModal}
                onClose={handleCloseConfirmVehicleDetailsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForConfirmModal}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Confirm Details
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseConfirmVehicleDetailsModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pb={3} px={1} height="500px" overflow="auto">
                    <MDBox pt={1} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                ASN No
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="asnCode"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.asnCode}
                                error={
                                  formik.touched.asnCode &&
                                  formik.errors.asnCode &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.asnCode &&
                                formik.errors.asnCode && (
                                  <TextError msg={formik.errors.asnCode} />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice No{" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="invoiceNo"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.invoiceNo}
                                error={
                                  formik.touched.invoiceNo &&
                                  formik.errors.invoiceNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "14px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.invoiceNo &&
                                formik.errors.invoiceNo && (
                                  <TextError msg={formik.errors.invoiceNo} />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Part No{" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="partCode"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.partCode}
                                error={
                                  formik.touched.partCode &&
                                  formik.errors.partCode &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.partCode &&
                                formik.errors.partCode && (
                                  <TextError msg={formik.errors.partCode} />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Transporter Type{" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="transporterType"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.transporterType}
                                error={
                                  formik.touched.transporterType &&
                                  formik.errors.transporterType &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.transporterType &&
                                formik.errors.transporterType && (
                                  <TextError
                                    msg={formik.errors.transporterType}
                                  />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Transporter Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="transporterName"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.transporterName}
                                error={
                                  formik.touched.transporterName &&
                                  formik.errors.transporterName &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.transporterName &&
                                formik.errors.transporterName && (
                                  <TextError
                                    msg={formik.errors.transporterName}
                                  />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Vehicle NamePlate{" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="vehicleNamePlate"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.vehicleNamePlate}
                                error={
                                  formik.touched.vehicleNamePlate &&
                                  formik.errors.vehicleNamePlate &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.vehicleNamePlate &&
                                formik.errors.vehicleNamePlate && (
                                  <TextError
                                    msg={formik.errors.vehicleNamePlate}
                                  />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                LR Number{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="lrDocketNo"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lrDocketNo}
                                error={
                                  formik.touched.lrDocketNo &&
                                  formik.errors.lrDocketNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  maxLength: 45,
                                }}
                              />
                              {formik.touched.lrDocketNo &&
                                formik.errors.lrDocketNo && (
                                  <TextError msg={formik.errors.lrDocketNo} />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <MDBox mb={1} mt={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Phone Number{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="phoneNumber"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phoneNumber}
                                error={
                                  formik.touched.phoneNumber &&
                                  formik.errors.phoneNumber &&
                                  true
                                }
                                inputProps={{
                                  maxLength: 10,
                                  style: {
                                    height: "13px",
                                  },
                                }}
                              />
                              {formik.touched.phoneNumber &&
                                formik.errors.phoneNumber && (
                                  <TextError msg={formik.errors.phoneNumber} />
                                )}
                            </MDBox>
                          </Grid>
                        </Grid>

                        <MDBox>
                          <Collapse in={isErrorConfirmVehicleDetails}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorConfirmVehicleDetails(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check the details. Server error!
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessConfirmVehicleDetails}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessConfirmVehicleDetails(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Details confirmed successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox
                          mt={2}
                          display="flex"
                          flexDirection={{ xs: "column", lg: "row" }}
                        >
                          <MDBox mr={2}>
                            <MDButton
                              color="success"
                              type="button"
                              marginRight="10px"
                              // disabled={!formik.isValid}
                              onClick={handleLastAsnData}
                            >
                              Copy from last ASN
                            </MDButton>
                          </MDBox>
                          <MDBox mr={2}>
                            <MDButton
                              color="info"
                              type="submit"
                              marginRight="10px"
                              disabled={!formik.isValid}
                            >
                              Submit & Scan BIN/QR On Handheld Application
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openPrintASNModal}
                onClose={handleClosePrintASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDocument}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    {/* <MDTypography>Print ASN</MDTypography> */}

                    {/* <hr /> */}

                    <MDBox pt={1} pb={3} px={5} height="500px" overflow="auto">
                      {/* <iframe
                        style={{
                          height: "100%",
                          width: "100%",
                          overflow: "auto",
                        }}
                        src={ 
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value="hey"
                        viewBox={`0 0 256 256`}
                        />
                    }
                      >
                       
                      </iframe> */}

                      <div ref={componentRef}>
                        <Document>
                          <Page size="A4" style={styles2.page}>
                            {/* {qrs.map((qrdata, categoryIndex) => {
                            <> */}
                            <>
                              <View
                                style={{
                                  marginBottom: "50px",
                                  marginRight: "20px",
                                  marginTop: "20px",
                                  marginLeft: "20px",
                                }}
                              >
                                <MDBox ml={3} mr={3}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} lg={4}>
                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Vendor Code
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsVendorCode}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>
                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Vendor Name
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsVendorName}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>
                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          ASN No
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={asnCode}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>
                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          ASN Qty
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsAsnQty}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          No.of packets/bins
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsPacketQty}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Packet Weight
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsPacketWeight}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} lg={4}>
                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Invoice No
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsInvoiceNo}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Invoice Date
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsInvoiceDate.slice(
                                            0,
                                            10
                                          )}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Part No
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsPartCode}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Part Description
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsPartDescription}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      {/* <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          GRN No
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsGrnNumber}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox> */}

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Created Date
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsCreatedDate}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Shipped Date
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsShippedDate}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} lg={4} marginBottom={2}>
                                      {/* <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Invoice No
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsInvoiceNo}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox> */}

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Transporter Type
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsTransporterType}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Transporter Name
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={printAsnDetailsTransporterName}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Vehicle Nameplate
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          fullWidth
                                          value={
                                            printAsnDetailsVehicleNameplate
                                          }
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          LR Number
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          name="lrDocketNo"
                                          fullWidth
                                          value={printAsnDetailsLRNumber}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>

                                      <MDBox mb={1} mt={2}>
                                        <MDTypography
                                          variant="h6"
                                          fontWeight="medium"
                                          flexGrow={1}
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 14,
                                          }}
                                        >
                                          Phone Number
                                        </MDTypography>
                                        <MDInput
                                          type="text"
                                          name="phoneNumber"
                                          fullWidth
                                          value={printAsnDetailsPhoneNumber}
                                          inputProps={{
                                            style: {
                                              height: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                        />
                                      </MDBox>
                                    </Grid>
                                  </Grid>
                                </MDBox>
                              </View>
                              <View
                                style={{
                                  display: "flex",
                                  // float: "right",
                                  marginTop: "20px",
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  justifyContent: "center",
                                }}
                              >
                                <QRCode
                                  size={256}
                                  style={{
                                    height: "auto",
                                    maxWidth: "60%",
                                    width: "60%",
                                  }}
                                  value={asnCode}
                                  viewBox={`0 0 256 256`}
                                />
                              </View>
                            </>
                          </Page>
                        </Document>
                      </div>

                      <MDButton
                        color="success"
                        type="button"
                        onClick={handlePrint}
                        style={{
                          marginLeft: "15px",
                          marginTop: "10px",
                          background: "#0BB9B3",
                          color: "white",
                        }}
                      >
                        Print
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openEditASNModal}
                onClose={handleCloseEditASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEditASN}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Update ASN
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseEditASNModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pb={3} px={1} overflow="auto">
                    <MDBox pt={1} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForEdit.handleSubmit}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={4}>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Part No/Description
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="partNoDes"
                                fullWidth
                                autoComplete="off"
                                value={
                                  singleASNDataForEdit.partCode +
                                  "/" +
                                  singleASNDataForEdit.partDescription
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Po No/Line Number{" "}
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="poNoLineNo"
                                fullWidth
                                autoComplete="off"
                                value={
                                  singleASNDataForEdit.poNumber +
                                  "/" +
                                  singleASNDataForEdit.poLineNumber
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Type of Packet{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="packageType"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={singleAsnPackageTypeData}
                                error={
                                  formikForEdit.touched.packageType &&
                                  formikForEdit.errors.packageType &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                              {formikForEdit.touched.packageType &&
                                formikForEdit.errors.packageType && (
                                  <TextError
                                    msg={formikForEdit.errors.packageType}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Unloading Point{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="unloadingPoint"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={singleASNDataForEdit.unloadingPoint}
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Delivery Plant{" "}
                              </MDTypography>

                              <MDInput
                                type="text"
                                name="deliveryPlant"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={singleASNDataForEdit.deliveryPlant}
                                error={
                                  formikForEdit.touched.deliveryPlant &&
                                  formikForEdit.errors.deliveryPlant &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "14px",
                                    marginTop: "1px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />

                              {formikForEdit.touched.deliveryPlant &&
                                formikForEdit.errors.deliveryPlant && (
                                  <TextError
                                    msg={formikForEdit.errors.deliveryPlant}
                                  />
                                )}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Schedule Quantity{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="scheduleQuantity"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.scheduleQuantity}
                                error={
                                  formikForEdit.touched.scheduleQuantity &&
                                  formikForEdit.errors.scheduleQuantity &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                }}
                              />
                              {formikForEdit.touched.scheduleQuantity &&
                                formikForEdit.errors.scheduleQuantity && (
                                  <TextError
                                    msg={formikForEdit.errors.scheduleQuantity}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Balance Quantity
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="balanceQty"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.balanceQty}
                                error={
                                  formikForEdit.touched.balanceQty &&
                                  formikForEdit.errors.balanceQty &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                }}
                              />
                              {formikForEdit.touched.balanceQty &&
                                formikForEdit.errors.balanceQty && (
                                  <TextError
                                    msg={formikForEdit.errors.balanceQty}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                ASN Quantity{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="asnQty"
                                fullWidth
                                autoComplete="off"
                                onChange={(e) => {
                                  setAsnQtyChange(e.target.value);
                                  calculatePacketDetails(
                                    e.target.value,
                                    partCode,
                                    userId,
                                    jwtToken
                                  );
                                }}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.asnQty}
                                error={
                                  formikForEdit.touched.asnQty &&
                                  formikForEdit.errors.asnQty &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                }}
                              />
                              {formikForEdit.touched.asnQty &&
                                formikForEdit.errors.asnQty && (
                                  <MDBox mb={-3}>
                                    <TextError
                                      msg={formikForEdit.errors.asnQty}
                                    />
                                  </MDBox>
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                No of Packet/Bin{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="packetNo"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.packetNo}
                                error={
                                  formikForEdit.touched.packetNo &&
                                  formikForEdit.errors.packetNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                }}
                              />
                              {formikForEdit.touched.packetNo &&
                                formikForEdit.errors.packetNo && (
                                  <TextError
                                    msg={formikForEdit.errors.packetNo}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Unit Pack Quantity{" "}
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="unitPackQty"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.unitPackQty}
                                error={
                                  formikForEdit.touched.unitPackQty &&
                                  formikForEdit.errors.unitPackQty &&
                                  true
                                }
                                // maxLength="4"
                                inputProps={{
                                  // maxLength: 4,
                                  style: {
                                    height: "14px",
                                    marginTop: "2px",
                                  },
                                  readOnly: true,
                                }}
                              />
                              {formikForEdit.touched.unitPackQty &&
                                formikForEdit.errors.unitPackQty && (
                                  <TextError
                                    msg={formikForEdit.errors.unitPackQty}
                                  />
                                )}
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Unit Packet Weight (kg)
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="packetWeight"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={singleAsnPackageWeightData}
                                error={
                                  formikForEdit.touched.packetWeight &&
                                  formikForEdit.errors.packetWeight &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "13px",
                                  },
                                  readOnly: true,
                                  maxLength: 45,
                                }}
                              />
                              {formikForEdit.touched.packetWeight &&
                                formikForEdit.errors.packetWeight && (
                                  <TextError
                                    msg={formikForEdit.errors.packetWeight}
                                  />
                                )}
                            </MDBox>
                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice No{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="invoiceNo"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.invoiceNo}
                                error={
                                  formikForEdit.touched.invoiceNo &&
                                  formikForEdit.errors.invoiceNo &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "12px",
                                  },
                                  maxLength: 45,
                                }}
                              />
                              {formikForEdit.touched.invoiceNo &&
                                formikForEdit.errors.invoiceNo && (
                                  <MDBox mb={-3}>
                                    <TextError
                                      msg={formikForEdit.errors.invoiceNo}
                                    />
                                  </MDBox>
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  // marginBottom: "2px",
                                }}
                              >
                                Invoice Date{" "}
                                <Tooltip title="Required" placement="right">
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      // marginBottom: 5,
                                    }}
                                  >
                                    *
                                  </span>
                                </Tooltip>
                              </MDTypography>

                              <MDInput
                                type="date"
                                name="invoiceDate"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForEdit.handleChange}
                                onBlur={formikForEdit.handleBlur}
                                value={formikForEdit.values.invoiceDate}
                                error={
                                  formikForEdit.touched.invoiceDate &&
                                  formikForEdit.errors.invoiceDate &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "12.5px",
                                  },

                                  max: currentDate,
                                }}
                              />

                              {formikForEdit.touched.invoiceDate &&
                                formikForEdit.errors.invoiceDate && (
                                  <MDBox mb={-3}>
                                    <TextError
                                      msg={formikForEdit.errors.invoiceDate}
                                    />
                                  </MDBox>
                                )}
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Invoice (PDF Only)
                                <Tooltip title="Required" placement="right">
                                  {/* <span
                                    style={{
                                      color: "red",
                                      marginLeft: 5,
                                      // marginBottom: 15,
                                    }}
                                  >
                                    *
                                  </span> */}
                                </Tooltip>
                              </MDTypography>
                              <input
                                name="invoiceFile"
                                style={{
                                  borders: "none",
                                  marginTop: "0px",
                                  width: "100%",
                                }}
                                className="choose_file"
                                type="file"
                                autoComplete="off"
                                accept=".pdf"
                                id="chooseFile"
                                onChange={(event) =>
                                  handleChangeForInvoiceFile(event)
                                }
                              />
                            </MDBox>

                            <MDBox mb={3} mt={2}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Test Certificate (PDF Only)
                                {/* <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    // marginBottom: 15,
                                  }}
                                >
                                  *
                                </span> */}
                              </MDTypography>
                              <input
                                name="testCertificate"
                                style={{
                                  borders: "none",
                                  marginTop: "0px",
                                  width: "100%",
                                  // height:"15px"
                                }}
                                className="choose_file"
                                type="file"
                                autoComplete="off"
                                accept=".pdf"
                                id="chooseFile"
                                onChange={(event) =>
                                  handleChangeForTestCertificate(event)
                                }
                              />
                            </MDBox>
                          </Grid>
                        </Grid>

                        <MDBox>
                          <Collapse in={isErrorEditASN}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorEditASN(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check the details. Server error!
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessEditASN}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessEditASN(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              ASN updated successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={2} display="flex">
                          <MDBox mr={2}>
                            <MDButton
                              color="success"
                              type="submit"
                              marginRight="10px"
                              disabled={!formikForEdit.isValid}
                            >
                              Update ASN
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateCNFQTYModal}
                onClose={handleCloseUpdateCNFQTYModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForVerify}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Verify
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateCNFQTYModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForVerify.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Virtual Bin</MDTypography>
                        <MDInput
                          type="text"
                          name="remark"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForVerify.handleBlur}
                          onChange={formikForVerify.handleChange}
                          value={formikForVerify.values.remark}
                          error={
                            formikForVerify.touched.remark &&
                            formikForVerify.errors.remark &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForVerify.touched.remark &&
                          formikForVerify.errors.remark && (
                            <TextError msg={formikForVerify.errors.remark} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Could not verify the virtual bin!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Virtual bin verified successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formikForVerify.isValid}
                        >
                          Verify
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openPrintMultipleBinsOfASNModal}
                onClose={handleClosePrintMultipleBinsOfASNModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForDocument}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Print
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleClosePrintMultipleBinsOfASNModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDBox pt={1} pb={3} height="100%">
                      <div ref={component2Ref}>
                        {printData.map((person) => {
                          return (
                            <Document>
                              <Page size="A5" style={styles.page}>
                                <View
                                  style={{
                                    marginBottom: "20px",
                                    marginRight: "0px",
                                    marginTop: "50px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <table style={{ width: "120%" }}>
                                    <thead
                                      style={{
                                        borderSpacing: "5px",
                                        border: "1px solid grey",
                                      }}
                                    >
                                      <tr style={{ padding: "10px" }}>
                                        <th style={{ padding: "10px" }}>
                                          ASN Code
                                        </th>
                                        <th style={{ padding: "10px" }}>
                                          Bin Code
                                        </th>

                                        <th style={{ padding: "20px" }}>QTY</th>
                                        <th style={{ padding: "20px" }}>
                                          QR Code
                                        </th>
                                      </tr>
                                    </thead>
                                    <hr style={{ width: "453%" }} />
                                    <tbody>
                                      <tr
                                        style={{
                                          padding: "10px",
                                          border: "1px solid grey",
                                        }}
                                      >
                                        <td style={{ padding: "10px" }}>
                                          {person.asnCode}
                                        </td>
                                        <td style={{ padding: "10px" }}>
                                          {person.binCode}
                                        </td>
                                        <td style={{ padding: "20px" }}>
                                          {person.qty}
                                        </td>
                                        <td
                                          style={{
                                            padding: "20px",
                                            marginLeft: "50px",
                                          }}
                                        >
                                          <QRCode
                                            size={200}
                                            style={{
                                              height: "auto",
                                              maxWidth: "100%",
                                              width: "100%",
                                            }}
                                            value={person.binCode}
                                            viewBox={`0 0 256 256`}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </View>
                              </Page>
                            </Document>
                          );
                        })}
                      </div>

                      <MDButton
                        color="success"
                        type="button"
                        onClick={handlePrint2}
                        style={{ marginLeft: "10px" }}
                      >
                        Print
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />

      {!loading ? <Footer /> : null}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    asnDetailsReducer: state.asnDetailsReducer,
    lastAsnDetailsReducer: state.lastAsnDetailsReducer,
    binsReducer: state.binsReducer,
    packageTypeReducer: state.packageTypeReducer,
    packageDetailsReducer: state.packageDetailsReducer,
    deliveryPlantReducer: state.deliveryPlantReducer,
    unloadingPointReducer: state.unloadingPointReducer,
    packetWeightReducer: state.packetWeightReducer,
    binsForPrintReducer: state.binsForPrintReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getAsnDetails: (userId, jwtToken) =>
      dispatch(getAsnDetails(userId, jwtToken)),
    getBinsData: (payload, jwtToken) =>
      dispatch(getBinsData(payload, jwtToken)),
    getBinsForPrint: (asnCode, jwtToken) =>
      dispatch(getBinsForPrint(asnCode, jwtToken)),
    updateVehicleDetails: (payload, jwtToken) =>
      dispatch(updateVehicleDetails(payload, jwtToken)),
    printAsn: (payload, jwtToken) => dispatch(printAsn(payload, jwtToken)),
    getLastAsnDetails: (userId, jwtToken) =>
      dispatch(getLastAsnDetails(userId, jwtToken)),
    updateAsn: (payload, jwtToken) => dispatch(updateAsn(payload, jwtToken)),
    calculatePacketWeight: (partCode, asnQtyChange, jwtToken) =>
      dispatch(calculatePacketWeight(partCode, asnQtyChange, jwtToken)),
    getUnloadingPoint: (id, jwtToken) =>
      dispatch(getUnloadingPoint(id, jwtToken)),
    getDeliveryPlant: (id, jwtToken) =>
      dispatch(getDeliveryPlant(id, jwtToken)),
    calculatePacketDetails: (asnQty, partCode, userId, jwtToken) =>
      dispatch(calculatePacketDetails(asnQty, partCode, userId, jwtToken)),
    getPackageType: (partCode, jwtToken) =>
      dispatch(getPackageType(partCode, jwtToken)),
    confirmVirtualBins: (payload, jwtToken) =>
      dispatch(confirmVirtualBins(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(AsnDetails);
