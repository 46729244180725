/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

// Store
import { getSubModulesByRoleId } from "../../store";

// Layouts
import BinMaster from "./bin-master";
import PalletMaster from "./pallet-master";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const BinPalletMasters = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let binMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "f8e511fa-5d70-4590-b58b-443007340e0a"
  );

  let palletMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "c2ba4c5e-9df6-4a64-8d33-b7128252b2d1"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {binMasterModule && palletMasterModule ? (
              <TabView>
                <TabPanel header="Bin Master">
                  <BinMaster />
                </TabPanel>

                <TabPanel header="Pallet Master">
                  <PalletMaster />
                </TabPanel>
              </TabView>
            ) : !binMasterModule && palletMasterModule ? (
              <TabView>
                <TabPanel header="Pallet Master">
                  <PalletMaster />
                </TabPanel>
              </TabView>
            ) : binMasterModule && !palletMasterModule ? (
              <TabView>
                <TabPanel header="Bin Master">
                  <BinMaster />
                </TabPanel>
              </TabView>
            ) : (
              <>
                <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                  No BinMaster Access
                </h4>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(BinPalletMasters);
