// // /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import React from "react";
import { useEffect, useState } from "react";

import { connect } from "react-redux";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

import { Tree } from "primereact/tree";

// Functions from store
import { getSubModulesByRoleId, getModuleForView } from "../../../store";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const ViewAccess = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getModuleForView,
  getModulesForViewReducer,
}) => {
  let data = cookies.get("loginDetailsForWMS");

  let jwtToken = data?.jwt;
  let role = data?.data?.roleName;
  let roleId = data.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesAccessData, setModulesAccessData] = useState([]);

  useEffect(() => {
    setModulesAccessData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  useEffect(() => {
    getModuleForView(jwtToken, roleId);
  }, []);

  const [listOfModules, setListOfModules] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(getModulesForViewReducer?.loading);
    let data = getModulesForViewReducer?.moduleData?.data
      ? getModulesForViewReducer?.moduleData?.data
      : [];
    setListOfModules(data);
  }, [getModulesForViewReducer]);

  return (
    <>
      <Grid container spacing={1}>
        {listOfModules.map((parent) => {
          return (
            <>
              <Grid
                item
                xs={5}
                mt={3}
                mr={1}
                style={{
                  border: "1px solid red",
                  borderRadius: "10px",
                }}
              >
                <FormControlLabel
                  label={<h1>Module: {parent.label}</h1>}
                  control={
                    <Checkbox
                      disabled
                      value={parent.key}
                      checked={parent.checked}
                    />
                  }
                />
                <Grid container>
                  {parent.children.map((child) => {
                    return (
                      <Grid item xs={12} sx={{ marginLeft: "2rem" }}>
                        <FormControlLabel
                          label={<h2>Sub-module: {child.label}</h2>}
                          control={
                            <Checkbox
                              disabled
                              value={child.key}
                              checked={child.checked}
                            />
                          }
                        />
                        <Grid container sx={{ marginLeft: "2rem" }}>
                          {child.children.map((subChild) => {
                            return (
                              <Grid item xs={6}>
                                <FormControlLabel
                                  label={<h4>{subChild.label}</h4>}
                                  control={
                                    <Checkbox
                                      disabled
                                      value={subChild.key}
                                      checked={subChild.checked}
                                    />
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>

      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getModulesForViewReducer: state.getModulesForViewReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getModuleForView: (jwtToken, roleId) =>
      dispatch(getModuleForView(jwtToken, roleId)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ViewAccess);
