import {
  GET_REGISTEREDVENDORS_REQUEST,
  GET_REGISTEREDVENDORS_SUCCESS,
  GET_REGISTEREDVENDORS_FAILURE,
} from "./registeredVendorsTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;
// const URL = Config.baseUrl;

const getRegisteredVendorsRequest = () => {
  return {
    type: GET_REGISTEREDVENDORS_REQUEST,
  };
};

const getRegisteredVendorsSuccess = (payload) => {
  return {
    type: GET_REGISTEREDVENDORS_SUCCESS,
    payload: payload,
  };
};

const getRegisteredVendorsFailure = (error) => {
  return {
    type: GET_REGISTEREDVENDORS_FAILURE,
    payload: error,
  };
};

const getRegisteredVendors = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRegisteredVendorsRequest());
    let url = `${baseUrl}/vendor/get_approved`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRegisteredVendorsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRegisteredVendorsFailure(err.message));
      });
  };
};

export { getRegisteredVendors };
