/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "./loginTypes";

const initialState = {
  isAuth: false,
  userData: {},
  error: "",
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuth: true,
      };
    case LOGIN_SUCCESS:
      return {
        isAuth: true,
        userData: action.payload,
        error: "",
      };
    case LOGIN_FAILURE:
      return {
        isAuth: false,
        userData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLogout = {
  isAuth: false,
  userData: {},
  error: "",
};

const logoutReducer = (state = initialStateForLogout, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return {
        ...state,
        isAuth: true,
      };
    case LOGOUT_SUCCESS:
      return {
        isAuth: true,
        userData: action.payload,
        error: "",
      };
    case LOGOUT_FAILURE:
      return {
        isAuth: false,
        userData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { loginReducer, logoutReducer };
