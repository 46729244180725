import {
  CREATE_STATION_FAILURE,
  CREATE_STATION_REQUEST,
  CREATE_STATION_SUCCESS,
  DELETE_STATION_FAILURE,
  DELETE_STATION_REQUEST,
  DELETE_STATION_SUCCESS,
  EDIT_STATION_FAILURE,
  EDIT_STATION_REQUEST,
  EDIT_STATION_SUCCESS,
  GET_STATION_MASTER_FAILURE,
  GET_STATION_MASTER_REQUEST,
  GET_STATION_MASTER_SUCCESS,
  GET_STATIONS_BY_ASSEMBLYID_FAILURE,
  GET_STATIONS_BY_ASSEMBLYID_REQUEST,
  GET_STATIONS_BY_ASSEMBLYID_SUCCESS,
} from "./stationMasterTypes";

const initialStateForStation = {
  loading: false,
  stations: [],
  error: "",
};

const getStationMasterReducer = (state = initialStateForStation, action) => {
  switch (action.type) {
    case GET_STATION_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STATION_MASTER_SUCCESS:
      return {
        loading: false,
        stations: action.payload,
        error: "",
      };
    case GET_STATION_MASTER_FAILURE:
      return {
        loading: false,
        stations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForStationForBom = {
  loading: false,
  stations: [],
  error: "",
};

const getStationsByAssemblyIdForBomReducer = (state = initialStateForStationForBom, action) => {
  switch (action.type) {
    case GET_STATIONS_BY_ASSEMBLYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STATIONS_BY_ASSEMBLYID_SUCCESS:
      return {
        loading: false,
        stations: action.payload,
        error: "",
      };
    case GET_STATIONS_BY_ASSEMBLYID_FAILURE:
      return {
        loading: false,
        stations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  create: {},
  error: "",
};

const createStationReducer = (state = initialStateForCreate, action) => {
  switch (action.type) {
    case CREATE_STATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_STATION_SUCCESS:
      return {
        loading: false,
        create: action.payload,
        error: "",
      };
    case CREATE_STATION_FAILURE:
      return {
        loading: false,
        create: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  delete: {},
  error: "",
};

const deleteStationReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_STATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_STATION_SUCCESS:
      return {
        loading: true,
        delete: action.payload,
        error: "",
      };
    case DELETE_STATION_FAILURE:
      return {
        loading: false,
        delete: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEdit = {
  loading: false,
  edit: {},
  error: "",
};

const editStationReducer = (state = initialStateForEdit, action) => {
  switch (action.type) {
    case EDIT_STATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_STATION_SUCCESS:
      return {
        loading: true,
        edit: action.payload,
        error: "",
      };
    case EDIT_STATION_FAILURE:
      return {
        loading: false,
        edit: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getStationMasterReducer,
  createStationReducer,
  deleteStationReducer,
  getStationsByAssemblyIdForBomReducer,
  editStationReducer,
};
