/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAILURE,
  GET_SUB_MODULES_FAILURE,
  GET_SUB_MODULES_SUCCESS,
  GET_SUB_MODULES_REQUEST,
  GET_MODULES_FOR_VIEW_REQUEST ,
  GET_MODULES_FOR_VIEW_SUCCESS ,
  GET_MODULES_FOR_VIEW_FAILURE , 
  GET_MODULES_FOR_EDIT_REQUEST,
  GET_MODULES_FOR_EDIT_SUCCESS,
  GET_MODULES_FOR_EDIT_FAILURE,
  GET_SUB_MODULES_FOR_VIEW_REQUEST ,
  GET_SUB_MODULES_FOR_VIEW_SUCCESS ,
  GET_SUB_MODULES_FOR_VIEW_FAILURE   ,
  GET_SUB_MODULES_FOR_CREATE_ROLE_FAILURE ,
  GET_SUB_MODULES_FOR_CREATE_ROLE_SUCCESS ,
  GET_SUB_MODULES_FOR_CREATE_ROLE_REQUEST
} from "./modulesTypes";

const initialState = {
  loading: false,
  modules: {},
  error: "",
};

const getModulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MODULES_SUCCESS:
      return {
        loading: true,
        modules: action.payload,
        error: "",
      };
    case GET_MODULES_FAILURE:
      return {
        loading: false,
        modules: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSubModule = {
  loading: false,
  subModulesData: {},
  error: "",
};

const getSubModulesReducer = (state = initialStateForSubModule, action) => {
  switch (action.type) {
    case GET_SUB_MODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUB_MODULES_SUCCESS:
      return {
        loading: true,
        subModulesData: action.payload,
        error: "",
      };
    case GET_SUB_MODULES_FAILURE:
      return {
        loading: false,
        subModulesData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};


const initialStateForviewModule = {
  loading: false,
  moduleData: {},
  error: "",
};

const getModulesForViewReducer = (state = initialStateForviewModule, action) => {
  switch (action.type) {
    case GET_MODULES_FOR_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case  GET_MODULES_FOR_VIEW_SUCCESS:
      return {
        loading: false,
        moduleData: action.payload,
        error: "",
      }; 
    case   GET_MODULES_FOR_VIEW_FAILURE:
      return {
        loading: false, 
        moduleData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEditModule = {
  loading: false,
  moduleData: {},
  error: "",
};

const getModulesForEditReducer = (state = initialStateForEditModule, action) => {
  switch (action.type) {
    case GET_MODULES_FOR_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case  GET_MODULES_FOR_EDIT_SUCCESS:
      return {
        loading: false,
        moduleData: action.payload,
        error: "",
      }; 
    case   GET_MODULES_FOR_EDIT_FAILURE:
      return {
        loading: false, 
        moduleData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForviewSubModule = {
  loading: false,
  subModuleData: {},
  error: "",
};

const getSubModulesForViewReducer = (state = initialStateForviewSubModule, action) => {
  switch (action.type) {
    case GET_SUB_MODULES_FOR_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case  GET_SUB_MODULES_FOR_VIEW_SUCCESS:
      return {
        loading: true,
        subModuleData: action.payload,
        error: "",
      }; 
    case   GET_SUB_MODULES_FOR_VIEW_FAILURE:
      return {
        loading: false,
        subModuleData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};



const initialStateForGetSubModule = {
  loading: false,
  subModulesDataForCreateRole : [],
  error: "",
};

const getSubModulesForCreateRoleReducer = (state = initialStateForGetSubModule, action) => {
  switch (action.type) {
    case GET_SUB_MODULES_FOR_CREATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUB_MODULES_FOR_CREATE_ROLE_SUCCESS:
      return {
        loading: true,
        subModulesDataForCreateRole: action.payload,
        error: "",
      };
    case GET_SUB_MODULES_FOR_CREATE_ROLE_FAILURE:
      return {
        loading: false,
        subModulesDataForCreateRole: [],
        error: action.payload,
      };
    default:
      return state;
  }
};





export { getModulesReducer, getSubModulesReducer ,  getModulesForViewReducer , getSubModulesForViewReducer , getSubModulesForCreateRoleReducer, getModulesForEditReducer };
