/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  
  GET_MODULES_BY_ROLE_ID_REQUEST,
  GET_MODULES_BY_ROLE_ID_SUCCESS,
  GET_MODULES_BY_ROLE_ID_FAILURE,
  GET_SUB_MODULES_BY_ROLE_ID_REQUEST,
  GET_SUB_MODULES_BY_ROLE_ID_SUCCESS,
  GET_SUB_MODULES_BY_ROLE_ID_FAILURE
 
} from "./userModulesTypes";



const initialStateForModulesByRoleId = {
  loading: false,
  modulesByRoleData: [],
  error: "",
};

const getModulesByRoleIdReducer = (state = initialStateForModulesByRoleId, action) => {
  switch (action.type) {
    case GET_MODULES_BY_ROLE_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MODULES_BY_ROLE_ID_SUCCESS:
      return {
        loading: false,
        modulesByRoleData: action.payload,
        error: "",
      };
    case GET_MODULES_BY_ROLE_ID_FAILURE:
      return {
        loading: false,
        modulesByRoleData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSubModulesByRoleId = {
  loading: false,
  subModulesByRoleData: [],
  error: "",
};

const getSubModulesByRoleIdReducer = (state = initialStateForSubModulesByRoleId, action) => {
  switch (action.type) {
    case GET_SUB_MODULES_BY_ROLE_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUB_MODULES_BY_ROLE_ID_SUCCESS:
      return {
        loading: false,
        subModulesByRoleData: action.payload,
        error: "",
      };
    case GET_SUB_MODULES_BY_ROLE_ID_FAILURE:
      return {
        loading: false,
        subModulesByRoleData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};


export {
getModulesByRoleIdReducer, getSubModulesByRoleIdReducer
};

