import {
  GET_ALLGRN_REQUEST,
  GET_ALLGRN_SUCCESS,
  GET_ALLGRN_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE,
  CREATE_GRN_REQUEST,
  CREATE_GRN_SUCCESS,
  CREATE_GRN_FAILURE,
  GET_GRNITEMS_REQUEST,
  GET_GRNITEMS_SUCCESS,
  GET_GRNITEMS_FAILURE
} from "./grnTypes";

const initialState = {
  loading: false,
  GRN: [],
  error: "",
};

const getAllGRNReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLGRN_SUCCESS:
      return {
        loading: false,
        GRN: action.payload,
        error: "",
      };
    case GET_ALLGRN_FAILURE:
      return {
        loading: false,
        GRN: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLineItems = {
  loading: false,
  lineItems: [],
  error: "",
};

const getLineItemsByGrnReducer = (state = initialStateForLineItems, action) => {
  switch (action.type) {
    case GET_LINEITEMSBYGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LINEITEMSBYGRN_SUCCESS:
      return {
        loading: false,
        lineItems: action.payload,
        error: "",
      };
    case GET_LINEITEMSBYGRN_FAILURE:
      return {
        loading: false,
        lineItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForGrnItems = {
  loading: false,
  grnItems: [],
  error: "",
};

const getGrnItemsReducer = (state = initialStateForGrnItems, action) => {
  switch (action.type) {
    case GET_GRNITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GRNITEMS_SUCCESS:
      return {
        loading: false,
        grnItems: action.payload,
        error: "",
      };
    case GET_GRNITEMS_FAILURE:
      return {
        loading: false,
        grnItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDataForDownload = {
  loading: false,
  GRNDataForDownload: [],
  error: "",
};

const getAllGRNDataForDownloadReducer = (state = initialStateForDataForDownload, action) => {
  switch (action.type) {
    case GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        GRNDataForDownload: action.payload,
        error: "",
      };
    case GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE:
      return {
        loading: false,
        GRNDataForDownload: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForCreateGrn = {
  loading: false,
  grn: {},
  error: "",
};

const createGrnReducer = (
  state = initialStateForCreateGrn,
  action
) => {
  switch (action.type) {
    case CREATE_GRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_GRN_SUCCESS:
      return {
        loading: false,
        grn: action.payload,
        error: "",
      };
    case CREATE_GRN_FAILURE:
      return {
        loading: false,
        grn: {},
        error: action.payload,
      };
    default:
      return state;
  }
};



export { getAllGRNReducer, getLineItemsByGrnReducer, getAllGRNDataForDownloadReducer,createGrnReducer,getGrnItemsReducer };
