export const GET_CREATE_MATERIAL_RETURN_ORDER_REQUEST =
  "GET_CREATE_MATERIAL_RETURN_ORDER_REQUEST";
export const GET_CREATE_MATERIAL_RETURN_ORDER_SUCCESS =
  "GET_CREATE_MATERIAL_RETURN_ORDER_SUCCESS";
export const GET_CREATE_MATERIAL_RETURN_ORDER_FAILURE =
  "GET_CREATE_MATERIAL_RETURN_ORDER_FAILURE";

export const CREATE_MATERIAL_RETURN_ORDER_REQUEST =
  "CREATE_MATERIAL_RETURN_ORDER_REQUEST";
export const CREATE_MATERIAL_RETURN_ORDER_SUCCESS =
  "CREATE_MATERIAL_RETURN_ORDER_SUCCESS";
export const CREATE_MATERIAL_RETURN_ORDER_FAILURE =
  "CREATE_MATERIAL_RETURN_ORDER_FAILURE";
