export const GET_PRODUCTION_PLAN_REQUEST = "GET_PRODUCTION_PLAN_REQUEST";
export const GET_PRODUCTION_PLAN_SUCCESS = "GET_PRODUCTION_PLAN_SUCCESS";
export const GET_PRODUCTION_PLAN_FAILURE = "GET_PRODUCTION_PLAN_FAILURE";

export const CREATE_PRODUCTION_PLAN_REQUEST = "CREATE_PRODUCTION_PLAN_REQUEST";
export const CREATE_PRODUCTION_PLAN_SUCCESS = "CREATE_PRODUCTION_PLAN_SUCCESS";
export const CREATE_PRODUCTION_PLAN_FAILURE = "CREATE_PRODUCTION_PLAN_FAILURE";

export const EDIT_REQUIRED_QTY_REQUEST = "EDIT_REQUIRED_QTY_REQUEST";
export const EDIT_REQUIRED_QTY_SUCCESS = "EDIT_REQUIRED_QTY_SUCCESS";
export const EDIT_REQUIRED_QTY_FAILURE = "EDIT_REQUIRED_QTY_FAILURE";

export const DELETE_PRODUCTION_PLAN_REQUEST = "DELETE_PRODUCTION_PLAN_REQUEST";
export const DELETE_PRODUCTION_PLAN_SUCCESS = "DELETE_PRODUCTION_PLAN_SUCCESS";
export const DELETE_PRODUCTION_PLAN_FAILURE = "DELETE_PRODUCTION_PLAN_FAILURE";

export const UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_REQUEST = "UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_REQUEST";
export const UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_SUCCESS = "UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_SUCCESS";
export const UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_FAILURE = "UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_FAILURE";


export const PUBLISH_PRODUCTION_PLAN_REQUEST = "PUBLISH_PRODUCTION_PLAN_REQUEST";
export const PUBLISH_PRODUCTION_PLAN_SUCCESS = "PUBLISH_PRODUCTION_PLAN_SUCCESS";
export const PUBLISH_PRODUCTION_PLAN_FAILURE = "PUBLISH_PRODUCTION_PLAN_FAILURE";

