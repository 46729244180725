/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import * as Yup from "yup";

const pancardRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
const gstnoRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
const cinnumberRegex =
  /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
const pfaccountnumberRegex =
  /[A-Z]{2}\/[A-Z]{3}\/[0-9]{7}\/[0-9A-Z]{2}[0-9]{1}\/[0-9]{7}/;
const ifsccodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = {
  // vendor form

  validationSchemaForStepOne: {
    lineOfBusiness: Yup.string().required("Choose a Line Of Business"),
    currency: Yup.string().required("Choose a Currency"),
    supplierName: Yup.string().required("Enter a Supplier Name"),
    buildingNoName: Yup.string().required("Enter a Building No/Name"),
    floor: Yup.string().required("Enter a Floor"),
    street: Yup.string().required("Enter a Street"),
    // country: Yup.object().required("Choose a Country"),
    state: Yup.string().required("Choose a State"),
    city: Yup.string().required("Enter a City"),
    pinCode: Yup.string()
      .required("Enter a PinCode")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
    contactPerson: Yup.string().required("Enter a Contact Person"),
    contactNo: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Enter a Contact No"),
    email: Yup.string().email("E-mail is Invalid").required("Enter a E-mail"),
    industrialType: Yup.array(
      Yup.string().oneOf([
        "Dealer",
        "Manufacturer",
        "Service",
        "Sub-Contractor",
        "Trader",
      ])
    )
      .min(1, "Choose atleast one")
      .required("Choose the Industrial Type"),
  },

  validationSchemaForStepTwo: {
    pan: Yup.string()
      .required("Enter PAN No")
      .matches(pancardRegex, "Invalid Format"),
    gstNo: Yup.string()
      .matches(gstnoRegex, "Invalid Format")
      .required("Enter GST No"),
    cinNumber: Yup.string()
      .required("Enter CIN No")
      .matches(cinnumberRegex, "Invalid Format"),
    sezNonsez: Yup.string().required("Choose a SEZ Type"),
    msmedAct: Yup.string().oneOf(["Yes", "No"]).required("Required"),
    tdsLowerDeduction: Yup.string().oneOf(["Yes", "No"]).required("Required"),
    categoryOfSupply: Yup.array(
      Yup.string().oneOf(["Material", "Services", "Man Power"])
    )
      .min(1, "Choose atleast one")
      .required("Choose Category Of Supply"),
    pfAccountNumber: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: () =>
        Yup.string()
          .matches(pfaccountnumberRegex, "Invalid Format")
          .required("Enter a valid PF Account Number"),
      otherwise: () =>
        Yup.string().matches(pfaccountnumberRegex, "Invalid Format").optional(),
    }),
    esiAccountNumber: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: () => Yup.string().required(),
      otherwise: () => Yup.string().optional(),
    }),
  },

  validationSchemaForStepThree: {
    beneficiaryBankAndAddress: Yup.string().required(
      "Enter Beneficiary Bank And Address"
    ),
    accountNumber: Yup.number().required("Enter Account Number"),
    accountType: Yup.string().required("Choose Account Type"),
    accountName: Yup.string().required("Enter Account Name"),
    ifscCode: Yup.string()
      .matches(ifsccodeRegex, "Invalid Format")
      .required("Enter IFSC Code"),
    swiftCodeIban: Yup.string().required("Enter Swift Code/IBAN"),
    swiftCodeOfIntermediaryRoutingBank: Yup.string().required(
      "Enter Swift Code Of Intermediary Routing Bank"
    ),
    intermediaryRoutingBank: Yup.string().required(
      "Enter Intermediary Routing Bank"
    ),
  },

  validationSchemaForStepFour: {
    incorporationCertificate: Yup.mixed()
      .required("Upload Incorporation Certificate")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    cancelledCheque: Yup.mixed()
      .required("Upload Cancelled Cheque")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    panCard: Yup.mixed()
      .required("Upload PAN Card")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    gstRegistrationCertificate: Yup.mixed()
      .required("Upload GST Registration Certificate")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    validLut: Yup.mixed()
      .required("Upload Valid LUT")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    sezApproval: Yup.mixed().when("sezNonsez", {
      is: "SEZ",
      then: () =>
        Yup.mixed()
          .required("Upload SEZ Approval")
          .test("fileSize", "The file size is too large", (value) => {
            return value && value.size < 700000;
          })
          .test("type", "Upload only PDF", (value) => {
            return value && value.type === "application/pdf";
          }),
      otherwise: () => Yup.mixed().optional(),
    }),
    pfCertificate: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: () =>
        Yup.mixed()
          .required("Upload PF Certificate")
          .test("fileSize", "The file size is too large", (value) => {
            return value && value.size < 700000;
          })
          .test("type", "Upload only PDF", (value) => {
            return value && value.type === "application/pdf";
          }),
      otherwise: () => Yup.mixed().optional(),
    }),
    esiCertificate: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: () =>
        Yup.mixed()
          .required("Upload ESI Certificate")
          .test("fileSize", "The file size is too large", (value) => {
            return value && value.size < 700000;
          })
          .test("type", "Upload only PDF", (value) => {
            return value && value.type === "application/pdf";
          }),
      otherwise: () => Yup.mixed().optional(),
    }),
    tdsLowerDeductionCertificate: Yup.mixed().when("tdsLowerDeduction", {
      is: "Yes",
      then: () =>
        Yup.mixed()
          .required("Upload tds LowerDeduction Certificate")
          .test("fileSize", "The file size is too large", (value) => {
            return value && value.size < 700000;
          })
          .test("type", "Upload only PDF", (value) => {
            return value && value.type === "application/pdf";
          }),
      otherwise: () => Yup.mixed().optional(),
    }),
    msmeCertificate: Yup.mixed().when("msmedAct", {
      is: "Yes",
      then: () =>
        Yup.mixed()
          .required("Upload msme Certificate")
          .test("fileSize", "The file size is too large", (value) => {
            return value && value.size < 700000;
          })
          .test("type", "Upload only PDF", (value) => {
            return value && value.type === "application/pdf";
          }),
      otherwise: () => Yup.mixed().optional(),
    }),
  },
};

export default validationSchema;
