import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from "./categoryCreationTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getCategoriesRequest = () => {
  return {
    type: GET_CATEGORIES_REQUEST,
  };
};

const getCategoriesSuccess = (payload) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: payload,
  };
};

const getCategoriesFailure = (error) => {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload: error,
  };
};

const getCategories = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getCategoriesRequest());
    let url = `${baseUrl}/category`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCategoriesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getCategoriesFailure(err.response));
      });
  };
};

const createCategoryRequest = () => {
  return {
    type: CREATE_CATEGORY_REQUEST,
  };
};

const createCategorySuccess = (payload) => {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const createCategoryFaliure = (error) => {
  return {
    type: CREATE_CATEGORY_FAILURE,
    payload: error,
  };
};

const createCategory = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createCategoryRequest());
    let url = `${baseUrl}/category`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createCategorySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createCategoryFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateCategoryRequest = () => {
  return {
    type: UPDATE_CATEGORY_REQUEST,
  };
};

const updateCategorySuccess = (payload) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const updateCategoryFaliure = (error) => {
  return {
    type: UPDATE_CATEGORY_FAILURE,
    payload: error,
  };
};

const updateCategory = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateCategoryRequest());
    let url = `${baseUrl}/category`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateCategorySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateCategoryFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteCategoryRequest = () => {
  return {
    type: DELETE_CATEGORY_REQUEST,
  };
};

const deleteCategorySuccess = (payload) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: payload,
  };
};

const deleteCategoryFaliure = (error) => {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload: error,
  };
};

const deleteCategory = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(deleteCategoryRequest());
    let url = `${baseUrl}/category?id=${id}`;

    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteCategorySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteCategoryFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getCategories, createCategory, updateCategory, deleteCategory };
