export const GET_ITEMMASTER_REQUEST = "GET_ITEMMASTER_REQUEST";
export const GET_ITEMMASTER_SUCCESS = "GET_ITEMMASTER_SUCCESS";
export const GET_ITEMMASTER_FAILURE = "GET_ITEMMASTER_FAILURE";

export const EDIT_ITEMMASTER_REQUEST = "EDIT_ITEMMASTER_REQUEST";
export const EDIT_ITEMMASTER_SUCCESS = "EDIT_ITEMMASTER_SUCCESS";
export const EDIT_ITEMMASTER_FAILURE = "EDIT_ITEMMASTER_FAILURE";

export const GET_BINSBYITEMCODE_REQUEST = "GET_BINSBYITEMCODE_REQUEST";
export const GET_BINSBYITEMCODE_SUCCESS = "GET_BINSBYITEMCODE_SUCCESS";
export const GET_BINSBYITEMCODE_FAILURE = "GET_BINSBYITEMCODE_FAILURE";

export const UPLOADCSV_REQUEST = "UPLOADCSV_REQUEST";
export const UPLOADCSV_SUCCESS = "UPLOADCSV_SUCCESS";
export const UPLOADCSV_FAILURE = "UPLOADCSV_FAILURE";

export const GET_ITEMS_DATA_FOR_DOWNLOAD_REQUEST = "GET_ITEMS_DATA_FOR_DOWNLOAD_REQUEST";
export const GET_ITEMS_DATA_FOR_DOWNLOAD_SUCCESS = "GET_ITEMS_DATA_FOR_DOWNLOAD_SUCCESS"; 
export const GET_ITEMS_DATA_FOR_DOWNLOAD_FAILURE = "GET_ITEMS_DATA_FOR_DOWNLOAD_FAILURE";

export const CREATE_ITEM_REQUEST = "CREATE_ITEM_REQUEST";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAILURE = "CREATE_ITEM_FAILURE";

export const GET_ITEMTYPE_REQUEST = "GET_ITEMTYPE_REQUEST";
export const GET_ITEMTYPE_SUCCESS = "GET_ITEMTYPE_SUCCESS";
export const GET_ITEMTYPE_FAILURE = "GET_ITEMTYPE_FAILURE";