/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import { CSVLink } from "react-csv";

// Utils component
import TextError from "utils/TextError";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getSubModulesByRoleId,
  getPallets,
  deletePallet,
  getPalletTypes,
  createPallet,
  createPalletType,
  editPallet,
  uploadPalletMaster,
  printPallet,
} from "../../../store";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
import PrintButton from "components/standardized-components/PrintButton";

const cookies = new Cookies();

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PalletMaster = ({
  getPalletsReducer,
  getPalletTypesReducer,
  getSubModulesByRoleId,
  getPallets,
  deletePallet,
  getSubModulesByRoleIdReducer,
  getPalletTypes,
  editPallet,
  createPallet,
  uploadPalletMaster,
  createPalletType,
  printPallet,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let printerId = cookies.get("printerIdForWarehouse");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let palletMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "c2ba4c5e-9df6-4a64-8d33-b7128252b2d1"
  );

  let addPalletAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "78e320ef-6419-456f-86c3-6b59723c76ad"
      )
    : null;
  let addPalletTypeAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "481e9912-6123-4b22-b208-2fa37db9e5c6"
      )
    : null;

  let uploadAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "f5ac5df3-8558-4759-bc80-a9ac813f572a"
      )
    : null;
  let downloadPalletAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "3c3cce48-ba82-4a02-b073-8b124c6865b4"
      )
    : null;
  let editPalletAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "fa96da14-3f71-4e32-8302-cff3b12e3a4a"
      )
    : null;
  let deletePalletAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "99c0b1f4-113c-4c9a-b738-626c7ad9be93"
      )
    : null;
  let printPalletAccess = palletMasterModule
    ? palletMasterModule.actionId.includes(
        "5cfcb70d-2606-48bd-85b5-047d932ced75"
      )
    : null;

  let partMasterModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "5cff6400-89e1-457c-80ba-5fda133ca39e"
  );

  let viewAccess = partMasterModule
    ? partMasterModule.actionId.includes("e95aff8c-752e-4ce7-b97a-4a450d0d1d83")
    : null;

  let updateAccess = partMasterModule
    ? partMasterModule.actionId.includes("9c9dfc9e-c1f2-471a-81e6-5f5961530791")
    : null;

  const [vendorsListForPlannerSelected, setVendorsListForPlannerSelected] =
    useState("");
  const [partNoSelected, setPartNoSelected] = useState("");
  const [plantSelected, setPlantSelected] = useState("");

  useEffect(() => {
    getPallets(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Pallet Code", accessor: "palletCode", align: "left" },

      { Header: "Pallet Type", accessor: "palletType", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [singleDataForDelete, setSingleDataForDelete] = useState({});
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const handleOpenDeleteUserModal = (data) => {
    setOpenDeleteUserModal(true);
    setSingleDataForDelete(data);
  };
  const handleCloseDeleteUserModal = () => {
    setOpenDeleteUserModal(false);
  };
  const [isErrorDeleteUser, setIsErrorDeleteUser] = useState(false);
  useEffect(() => {
    if (isErrorDeleteUser) {
      setTimeout(() => {
        setIsErrorDeleteUser(false);
      }, 3000);
    }
  }, [isErrorDeleteUser]);

  const [errorMsgForDelete, setErrorMsgForDelete] = useState(false);
  const deleteTripNo = async () => {
    let palletId = singleDataForDelete.palletId;
    let res = await deletePallet(palletId);
    if (res.status) {
      await getPallets();
      handleCloseDeleteUserModal();
    }
    if (!res.status) {
      setIsErrorDeleteUser(true);
      setErrorMsgForDelete(res.data.data.msg);
    }
  };

  const [singleDataForPrint, setSingleDataForPrint] = useState({});
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const handleOpenPrintModal = (data) => {
    setOpenPrintModal(true);
    setSingleDataForPrint(data);
  };

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const [isErrorPrint, setIsErrorPrint] = useState(false);
  const [isSuccessPrint, setIsSuccessPrint] = useState(false);
  const [errorMsgForPrint, setErrorMsgForPrint] = useState(false);

  useEffect(() => {
    if (isErrorPrint) {
      setTimeout(() => {
        setIsErrorPrint(false);
      }, 3000);
    }
  }, [isErrorPrint]);

  useEffect(() => {
    if (isSuccessPrint) {
      setTimeout(() => {
        setIsSuccessPrint(false);
      }, 3000);
    }
  }, [isSuccessPrint]);

  const printPalletHandler = async () => {
    let payload = {
      palletId: singleDataForPrint.palletId,
      printerId: printerId,
    };
    let res = await printPallet(payload);

    if (res.status) {
      await getPallets(jwtToken);
      setIsSuccessPrint(true);
      setTimeout(() => {
        handleClosePrintModal();
      }, 3000);
    }

    if (!res.status) {
      setIsErrorPrint(true);
      setErrorMsgForPrint(res.data.data.msg);
    }
  };

  const [singlePalletDataForUpdate, setSinglePalletDataForUpdate] = useState(
    {}
  );

  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");

  const initialValueForUpdate = {
    palletCode: singlePalletDataForUpdate.palletCode,

    palletTypes: singlePalletDataForUpdate.palletType,
  };

  const onSubmitForUpdate = async (values) => {
    let payload = {
      palletId: singlePalletDataForUpdate.palletId,
      palletCode: values.palletCode,

      userId: userId,
      palletTypeId: listOfPalletTypesSelectedForEdit.value,
    };

    let res = await editPallet(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getPallets(jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
    }
  };

  const [openUpdatePalletMasterModal, setOpenUpdatePalletMasterModal] =
    useState(false);
  const handleOpenUpdatePalletMasterModal = (singleData) => {
    setListOfPalletTypesSelectedForEdit({
      label: singleData.palletType,
      value: singleData.palletTypeId,
    });
    setSinglePalletDataForUpdate(singleData);
    setOpenUpdatePalletMasterModal(true);
    getPalletTypes(jwtToken);
  };

  const handleClosePalletMasterModal = () => {
    setOpenUpdatePalletMasterModal(false);
    formikForUpdate.setErrors({});
    formikForUpdate.setValues(initialValueForUpdate);
  };
  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempPalletData = [];
    let data = getPalletsReducer.pallets.data
      ? getPalletsReducer.pallets.data
      : [];
    setLoading(getPalletsReducer.loading);
    data.map((singlePalletData) => {
      const dataObject = {
        privateKey: singlePalletData.id,
        id: singlePalletData.id,

        palletCode: singlePalletData.palletCode,

        palletName: singlePalletData.palletName,
        palletType: singlePalletData.palletType,
        status: singlePalletData.isEmpty === 1 ? "Empty" : "Occupied",
        action: (
          <div>
            {editPalletAccess && (
              <EditButton
                tooltip="Edit Pallet"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() =>
                  handleOpenUpdatePalletMasterModal(singlePalletData)
                }
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}

            {deletePalletAccess && (
              <DeleteButton
                tooltip="Delete Pallet"
                disabled={singlePalletData.isEditable === 0 ? true : false}
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteUserModal(singlePalletData)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}

            {printPalletAccess && (
              <PrintButton
                tooltip="Print"
                // disabled={singleData.isEditable === 0 ? true : false}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenPrintModal(singlePalletData)}
              >
                <Icon>print</Icon>
              </PrintButton>
            )}
          </div>
        ),
      };

      tempPalletData.push(dataObject);
    });

    // viewAccess &&
    setState({ ...state, rows: tempPalletData });
  }, [getPalletsReducer]);

  const [listOfPalletTypesOptions, setListOfPalletTypesOptions] = useState([]);
  useEffect(() => {
    let tempListOfPalletTypesOptions = [];
    let data = getPalletTypesReducer.palletTypes.data
      ? getPalletTypesReducer.palletTypes.data
      : [];
    data.map((pallet) => {
      tempListOfPalletTypesOptions.push({
        label: pallet.palletType,
        value: pallet.palletTypeId,
      });
    });
    setListOfPalletTypesOptions(tempListOfPalletTypesOptions);
  }, [getPalletTypesReducer]);

  const [listOfPalletTypesSelected, setListOfPalletTypesSelected] =
    useState("");

  const handleChangeListOfPalletTypes = (selectedOption) => {
    setListOfPalletTypesSelected(selectedOption);
  };
  const [
    listOfPalletTypesSelectedForEdit,
    setListOfPalletTypesSelectedForEdit,
  ] = useState("");

  const handleChangeListOfPalletTypesForEdit = (selectedOption) => {
    setListOfPalletTypesSelectedForEdit(selectedOption);
  };

  const [isSuccessCreatePallet, setIsSuccessCreatePallet] = useState(false);
  const [isErrorCreatePallet, setIsErrorCreatePallet] = useState(false);

  useEffect(() => {
    if (isErrorCreatePallet) {
      setTimeout(() => {
        setIsErrorCreatePallet(false);
      }, 3000);
    }
  }, [isErrorCreatePallet]);

  useEffect(() => {
    if (isSuccessCreatePallet) {
      setTimeout(() => {
        setIsSuccessCreatePallet(false);
      }, 3000);
    }
  }, [isSuccessCreatePallet]);

  const [createPalletModal, setCreatePalletModal] = useState(false);
  const handleCreatePalletModal = () => {
    setCreatePalletModal(!createPalletModal);
    getPalletTypes(jwtToken);
    setListOfPalletTypesSelected("");

    formikForAddPallet.setValues(initialValueForCreatePallet);
    formikForAddPallet.setTouched({});
    formikForAddPallet.setErrors({});
  };

  const initialValueForCreatePallet = {
    // palletName: "",
    palletType: "",
    palletCode: "",
  };

  const validationSchemaForCreatePallet = Yup.object({
    palletCode: Yup.string().required("Enter the Pallet Code"),
    // palletName: Yup.string().required("Select the Pallet Name"),
    palletType: Yup.string().required("Select the Pallet type"),
  });
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmitForCreatePallet = async (values, { resetForm }) => {
    let payload = {
      palletCode: values.palletCode,

      palletTypeId: listOfPalletTypesSelected.value,
      userId: userId,
    };

    let res = await createPallet(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreatePallet(true);
      getPallets(jwtToken);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreatePallet(true);
      setErrorMsg(res.data.data.msg);
    }
  };

  const formikForAddPallet = useFormik({
    initialValues: initialValueForCreatePallet,
    onSubmit: onSubmitForCreatePallet,
    validationSchema: validationSchemaForCreatePallet,
  });

  const [isSuccessCreatePalletType, setIsSuccessCreatePalletType] =
    useState(false);
  const [isErrorCreatePalletType, setIsErrorCreatePalletType] = useState(false);

  useEffect(() => {
    if (isErrorCreatePalletType) {
      setTimeout(() => {
        setIsErrorCreatePalletType(false);
      }, 3000);
    }
  }, [isErrorCreatePalletType]);

  useEffect(() => {
    if (isSuccessCreatePalletType) {
      setTimeout(() => {
        setIsSuccessCreatePalletType(false);
      }, 3000);
    }
  }, [isSuccessCreatePalletType]);

  const [createPalletTypeModal, setCreatePalletTypeModal] = useState(false);
  const handleCreatePalletTypeModal = () => {
    setCreatePalletTypeModal(!createPalletTypeModal);

    formikForPalletType.setValues(initialValueForCreatePalletType);
    formikForPalletType.setTouched({});
    formikForPalletType.setErrors({});
  };

  const initialValueForCreatePalletType = {
    palletType: "",
  };

  const validationSchemaForCreatePalletType = Yup.object({
    palletType: Yup.string().required("Select the Pallet type"),
  });
  const [errorMsgForPalletType, setErrorMsgForPalletType] = useState(false);

  const onSubmitForCreatePalletType = async (values, { resetForm }) => {
    let payload = {
      palletType: values.palletType,
      userId: userId,
    };

    let res = await createPalletType(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreatePalletType(true);
      getPallets(jwtToken);
      getPalletTypes(jwtToken);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreatePalletType(true);
      setErrorMsgForPalletType(res.data.data.msg);
    }
  };

  const formikForPalletType = useFormik({
    initialValues: initialValueForCreatePalletType,
    onSubmit: onSubmitForCreatePalletType,
    validationSchema: validationSchemaForCreatePalletType,
  });

  const headers = [
    {
      label: "palletCode",
      key: "palletCode",
    },

    { label: "palletType", key: "palletType" },
  ];
  const csvLink = {
    filename: "Pallet Template.csv",
    headers: headers,
    data: [],
  };
  const csvLink2 = {
    filename: "Pallet Master.csv",
    headers: headers,
    data: rows,
  };

  const DataUploadNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };
  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("pallet", uploadCsvData);

    let res = await uploadPalletMaster(data, jwtToken);

    if (res.status) {
      setUploadCsvFilter(false);
      getPallets(jwtToken);
      DataUploadNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification();
    }
  };

  return (
    <MDBox pt={0} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            mb={-3}
            mt={-3}
          >
            <MDBox mt={2}>
              {addPalletAccess && (
                <Tooltip title="Add Pallet">
                  <MDButton
                    style={{ marginRight: "1rem" }}
                    color="info"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={handleCreatePalletModal}
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              )}

              {addPalletTypeAccess && (
                <Tooltip title="Add Pallet Type">
                  <MDButton
                    style={{ marginRight: "1rem" }}
                    color="warning"
                    variant="contained"
                    type="button"
                    iconOnly
                    onClick={handleCreatePalletTypeModal}
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              )}

              <CSVLink {...csvLink}>
                <Tooltip title="Download Template">
                  <MDButton
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="error"
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
              </CSVLink>
              {uploadAccess && (
                <Tooltip title="Upload Pallet Master">
                  <MDButton
                    // disabled={!uploadNewWarehouse}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </MDButton>
                </Tooltip>
              )}
              {downloadPalletAccess && (
                <CSVLink {...csvLink2}>
                  <Tooltip title="Download Pallet Master List">
                    <MDButton
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="success"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </MDButton>
                  </Tooltip>
                </CSVLink>
              )}
            </MDBox>
          </MDBox>

          {uploadCsvFilter && (
            <div style={{ marginLeft: "1" }}>
              <div>
                <input
                  className="choose_file"
                  style={{
                    marginTop: "1.5rem",
                    marginLeft: "1.5rem",
                    // marginBottom: "-9rem",
                  }}
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    setUploadCsvData(e.target.files[0]);
                  }}
                />
                <MDButton
                  disabled={
                    !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                  }
                  variant="contained"
                  color="info"
                  style={{
                    marginLeft: { xs: "1.5rem", lg: "1" },
                    marginTop: "0rem",
                  }}
                  type="button"
                  onClick={uploadCsvHandler}
                >
                  UPLOAD
                </MDButton>
              </div>
              <div>
                <span
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginLeft: "30px",
                    fontStyle: "italic",
                  }}
                >
                  <strong>*</strong> Please upload
                  <strong>".csv"</strong> file only
                </span>
              </div>
            </div>
          )}

          <MDBox pt={0}>
            {/* {!loading ? ( */}
            {!loading ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  marginBottom: "100px",
                }}
              >
                <CircularProgress color="info" />
              </div>
            )}
          </MDBox>

          <Modal
            open={openDeleteUserModal}
            onClose={handleCloseDeleteUserModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={deleteStyle}>
              <MDTypography>Are you sure you want to delete?</MDTypography>
              <MDBox mt={2}>
                <MDButton
                  // style={{ width: "48%", marginRight: "0.5rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleCloseDeleteUserModal}
                >
                  Cancel
                </MDButton>
                <MDButton
                  style={{ marginLeft: "0.5rem" }}
                  color="error"
                  onClick={deleteTripNo}
                >
                  Delete
                </MDButton>
                <MDBox mt={2}>
                  <Collapse in={isErrorDeleteUser}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDeleteUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsgForDelete}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
          <Modal
            open={openUpdatePalletMasterModal}
            onClose={handleClosePalletMasterModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Update
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleClosePalletMasterModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForUpdate.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Pallet Type
                    </MDTypography>
                    <Select
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Select..."
                      name="palletTypes"
                      options={listOfPalletTypesOptions}
                      value={listOfPalletTypesSelectedForEdit}
                      onChange={(selectedOption) => {
                        handleChangeListOfPalletTypesForEdit(selectedOption);
                        formikForUpdate.handleChange("palletTypes")(
                          selectedOption.label
                        );
                      }}
                    />
                    {formikForUpdate.touched.palletTypes &&
                      formikForUpdate.errors.palletTypes && (
                        <TextError msg={formikForUpdate.errors.palletTypes} />
                      )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorForUpdate}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorForUpdate(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForUpdate}
                        {/* error */}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessForUpdate}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorForUpdate(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Pallet updated successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="success"
                      fullWidth
                      type="submit"
                      disabled={!formikForUpdate.isValid}
                    >
                      Submit
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>

          <Modal
            open={createPalletModal}
            onClose={handleCreatePalletModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Create Pallet
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCreatePalletModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForAddPallet.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Pallet Type
                    </MDTypography>
                    <Select
                      className="select-css"
                      maxMenuHeight={130}
                      placeholder="Select..."
                      name="palletType"
                      options={listOfPalletTypesOptions}
                      value={listOfPalletTypesSelected}
                      onChange={(selectedOption) => {
                        handleChangeListOfPalletTypes(selectedOption);
                        formikForAddPallet.handleChange("palletType")(
                          selectedOption.label
                        );
                      }}
                    />
                    {formikForAddPallet.touched.palletType &&
                      formikForAddPallet.errors.palletType && (
                        <TextError msg={formikForAddPallet.errors.palletType} />
                      )}
                  </MDBox>

                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Pallet Code
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="palletCode"
                      fullWidth
                      autoComplete="off"
                      onChange={formikForAddPallet.handleChange}
                      onBlur={formikForAddPallet.handleBlur}
                      value={formikForAddPallet.values.palletCode}
                      error={
                        formikForAddPallet.touched.palletCode &&
                        formikForAddPallet.errors.palletCode &&
                        true
                      }
                    />
                    {formikForAddPallet.touched.palletCode &&
                      formikForAddPallet.errors.palletCode && (
                        <TextError msg={formikForAddPallet.errors.palletCode} />
                      )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorCreatePallet}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreatePallet(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessCreatePallet}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessCreatePallet(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Pallet created successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={
                        !formikForAddPallet.isValid ||
                        listOfPalletTypesSelected.value == ""
                      }
                    >
                      Create Pallet
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>

          <Modal
            open={createPalletTypeModal}
            onClose={handleCreatePalletTypeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                  Create Pallet Type
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCreatePalletTypeModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

              <MDBox pt={1} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={formikForPalletType.handleSubmit}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                      Pallet Type
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="palletType"
                      fullWidth
                      autoComplete="off"
                      onChange={formikForPalletType.handleChange}
                      onBlur={formikForPalletType.handleBlur}
                      value={formikForPalletType.values.palletType}
                      error={
                        formikForPalletType.touched.palletType &&
                        formikForPalletType.errors.palletType &&
                        true
                      }
                      inputProps={{
                        maxLength: 45,
                      }}
                    />
                    {formikForPalletType.touched.palletType &&
                      formikForPalletType.errors.palletType && (
                        <TextError
                          msg={formikForPalletType.errors.palletType}
                        />
                      )}
                  </MDBox>

                  <MDBox>
                    <Collapse in={isErrorCreatePalletType}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorCreatePalletType(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsgForPalletType}
                      </Alert>
                    </Collapse>
                    <Collapse in={isSuccessCreatePalletType}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsSuccessCreatePalletType(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        PalletType created successfully
                      </Alert>
                    </Collapse>
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="contained"
                      color="warning"
                      fullWidth
                      type="submit"
                      disabled={!formikForPalletType.isValid}
                    >
                      Create Pallet Type
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
          <Modal
            open={openPrintModal}
            onClose={handleClosePrintModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={deleteStyle}>
              <MDTypography>Are you sure you want to print?</MDTypography>
              <MDBox mt={2}>
                <MDButton
                  // style={{ width: "48%", marginRight: "0.5rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleClosePrintModal}
                >
                  Cancel
                </MDButton>
                <MDButton
                  style={{ marginLeft: "0.5rem" }}
                  color="success"
                  onClick={printPalletHandler}
                >
                  Print
                </MDButton>
                <MDBox mt={2}>
                  <Collapse in={isSuccessPrint}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessPrint(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Pallet printed successfully
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorPrint}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDeleteUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {/* Could not delete Pallet  */}
                      {errorMsgForPrint}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
        </Grid>
      </Grid>
      <ToastContainer />
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getPalletsReducer: state.getPalletsReducer,
    getPalletTypesReducer: state.getPalletTypesReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPallets: (jwtToken) => dispatch(getPallets(jwtToken)),
    getPalletTypes: (jwtToken) => dispatch(getPalletTypes(jwtToken)),
    deletePallet: (palletId, jwtToken) =>
      dispatch(deletePallet(palletId, jwtToken)),
    printPallet: (payload, jwtToken) =>
      dispatch(printPallet(payload, jwtToken)),
    createPallet: (payload, jwtToken) =>
      dispatch(createPallet(payload, jwtToken)),
    createPalletType: (payload, jwtToken) =>
      dispatch(createPalletType(payload, jwtToken)),
    editPallet: (payload, jwtToken) => dispatch(editPallet(payload, jwtToken)),
    uploadPalletMaster: (payload, jwtToken) =>
      dispatch(uploadPalletMaster(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PalletMaster);
