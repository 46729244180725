import {
  GET_WORKORDER_REQUEST,
  GET_WORKORDER_SUCCESS,
  GET_WORKORDER_FAILURE,
  GET_ITEMSFORWORKORDER_REQUEST,
  GET_ITEMSFORWORKORDER_SUCCESS,
  GET_ITEMSFORWORKORDER_FAILURE,
} from "./workOrderTypes";

const initialState = {
  loading: false,
  workOrder: [],
  error: "",
};

const getWorkOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WORKORDER_SUCCESS:
      return {
        loading: false,
        workOrder: action.payload,
        error: "",
      };
    case GET_WORKORDER_FAILURE:
      return {
        loading: false,
        workOrder: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForItems = {
  loading: false,
  itemsForWorkOrder: [],
  error: "",
};

const getItemsForWorkOrderReducer = (state = initialStateForItems, action) => {
  switch (action.type) {
    case GET_ITEMSFORWORKORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMSFORWORKORDER_SUCCESS:
      return {
        loading: false,
        itemsForWorkOrder: action.payload,
        error: "",
      };
    case GET_ITEMSFORWORKORDER_FAILURE:
      return {
        loading: false,
        itemsForWorkOrder: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getWorkOrderReducer, getItemsForWorkOrderReducer };
