import {
  GET_WMS_STOCK_REQUEST,
  GET_WMS_STOCK_SUCCESS,
  GET_WMS_STOCK_FAILURE,
} from "./wmsStockTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getWmsStockRequest = () => {
  return {
    type: GET_WMS_STOCK_REQUEST,
  };
};

const getWmsStockSuccess = (payload) => {
  return {
    type: GET_WMS_STOCK_SUCCESS,
    payload: payload,
  };
};

const getWmsStockFailure = (error) => {
  return {
    type: GET_WMS_STOCK_FAILURE,
    payload: error,
  };
};

const getWmsStock = (warehouseId, status,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWmsStockRequest());
    let url = `${baseUrl}/reports/wms_stock?warehouseCode=${warehouseId}&allStock=${status}`;
    axios
      .get(url, headers)
      .then((response) => {

        dispatch(getWmsStockSuccess(response.data));
      })
      .catch((err) => {

        dispatch(getWmsStockFailure(err.message));
      });
  };
};

export { getWmsStock };
