/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import MixedBarChart from "examples/Charts/BarCharts/MixedBarChart";

// Functions from store
import { getWarehouse, getWarehouses } from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

const WarehouseOccupancyHomePageCard = ({
  getWarehouse,
  warehouseOccupancy,
  getWarehouses,
  warehouses,
}) => {
  const [warehouseId, setWarehouseId] = useState("");

  useEffect(() => {
    getWarehouse(warehouseId);
  }, [warehouseId]);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const [warehouseName, setWarehouseName] = useState("");

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      if (warehouse.type === "s" || warehouse.type === "d") {
        tempWarehouseOptions.push({
          label: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseId(tempWarehouseOptions[0]?.value);
    setWarehouseName(tempWarehouseOptions[0]);
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [category, setCategory] = useState([]);
  const [emptyLocations, setEmptyLocations] = useState([]);
  const [occupiedLocations, setOccupiedLocations] = useState([]);
  const [itemQty, setItemQty] = useState([]);

  useEffect(() => {
    let tempCategory = [];
    let tempEmptyLocations = [];
    let tempOccupiedLocations = [];
    let tempItemQty = [];

    let data = warehouseOccupancy.warehouses
      ? warehouseOccupancy.warehouses
      : [];

    data.map((category) => {
      tempCategory.push(category.categoryName);
      tempEmptyLocations.push(category.emptyLocations);
      tempOccupiedLocations.push(category.occupiedLocations);
      tempItemQty.push(category.occupiedQty);
    });
    setCategory(tempCategory);
    setEmptyLocations(tempEmptyLocations);
    setOccupiedLocations(tempOccupiedLocations);
    setItemQty(tempItemQty);
  }, [warehouseOccupancy]);

  //   const chartData = {
  //     labels: category,
  //     datasets: {
  //       label: "FG",
  //       data: [
  //         lessThanThirty,
  //         thirtyOneToSixty,
  //         sixtyOneToNinety,
  //         greaterThanNinety,
  //         expired,
  //         putawayNotDone,
  //       ],
  //     },
  //   };

  const chartData = {
    labels: category,
    datasets: [
      {
        label: "Occupied",
        color: "red",
        data: occupiedLocations,
        items: itemQty,
      },
      {
        label: "Empty",
        color: "green",
        data: emptyLocations,
      },
    ],
  };

  const changeWarehouse = (e) => {
    setWarehouseId(e.value);
    setWarehouseName(e);
  };

  return (
    <MixedBarChart
      color="light"
      title="Warehouse Occupancy"
      chart={chartData}
      dropdown={
        <Select
          className="select-homepage"
          name="warehouseId"
          value={warehouseName}
          onChange={changeWarehouse}
          options={warehouseOptions}
        />
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    warehouseOccupancy: state.warehouseOccupancyHomePage,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWarehouse: (id) => dispatch(getWarehouse(id)),
    getWarehouses: () => dispatch(getWarehouses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseOccupancyHomePageCard);
