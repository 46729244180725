/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
} from "./deviceOnboardingTypes";
import axios from "axios";
import Config from "../../../config/index";

const baseUrl = Config.baseUrl;

const getDevicesRequest = () => {
  return {
    type: GET_DEVICES_REQUEST,
  };
};

const getDevicesSuccess = (payload) => {
  return {
    type: GET_DEVICES_SUCCESS,
    payload: payload,
  };
};

const getDevicesFaliure = (error) => {
  return {
    type: GET_DEVICES_FAILURE,
    payload: error,
  };
};

const getDevices = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDevicesRequest());
    let url = `${baseUrl}/device/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDevicesSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getDevicesFaliure(err.message));
      });
  };
};

const createDevice = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/device/create`;
    return axios
      .post(
        url,
        payload,

        headers
      )
      .then((response) => {
        return { status: true, data: response.data };
      })
      .catch((err) => {
        return { status: false, data: err.message };
      });
  };
};

const updateDevice = (payload, id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/device/update`;
    return axios
      .put(
        url,
        payload,

        headers
      )
      .then((response) => {
        return { status: true, data: response.data };
      })
      .catch((err) => {
        return { status: false, data: err.message };
      });
  };
};

const deleteDevice = (id, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    let url = `${baseUrl}/device/delete?id=${id}`;
    return axios
      .delete(url, headers)
      .then((response) => {
        return { status: true, data: response.data };
      })
      .catch((err) => {
        return { status: false, data: err.message };
      });
  };
};

export { getDevices, createDevice, updateDevice, deleteDevice };
