import { useField, Field, useFormikContext } from "formik";
import TextError from "utils/TextError";
import MDTypography from "components/MDTypography";
import Config from "../../../config/index";

const FormFileUpload = ({ label, ...props }) => {
  let baseUrl = Config.baseUrl;

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <>
      <MDTypography variant="h6" color="inherit">
        {label}{" "}
        {props.notRequired ? "" : <span style={{ color: "red" }}>*</span>}
      </MDTypography>
      <Field
        className="choose_file"
        type="file"
        {...field}
        {...props}
        value={undefined}
        onChange={(event) => {
          setFieldValue(field.name, event.target.files[0]);
        }}
      />
      {field?.value?.name && (
        <MDTypography mt={1} color="info" variant="subtitle2">
          Selected file: {field?.value?.name}
        </MDTypography>
      )}
      {props.isRejected && (
        <a
          rel="noopener noreferrer"
          href={`${baseUrl}/vendor/get_document?email=${props.email}&file_name=${field?.value?.name}`}
          target="_blank"
          className="btn"
          style={{ display: "inline-block", marginBottom: "0.5rem" }}
        >
          View Previous File
        </a>
      )}
      {meta.touched && meta.error && <TextError msg={meta.error} />}
    </>
  );
};

export default FormFileUpload;
