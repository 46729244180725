export const GET_RELATIONLOCATION_REQUEST = "GET_RELATIONLOCATION_REQUEST";
export const GET_RELATIONLOCATION_SUCCESS = "GET_RELATIONLOCATION_SUCCESS";
export const GET_RELATIONLOCATION_FAILURE = "GET_RELATIONLOCATION_FAILURE";

export const CREATE_RELATIONLOCATION_REQUEST = "CREATE_RELATIONLOCATION_REQUEST";
export const CREATE_RELATIONLOCATION_SUCCESS = "CREATE_RELATIONLOCATION_SUCCESS";
export const CREATE_RELATIONLOCATION_FAILURE = "CREATE_RELATIONLOCATION_FAILURE";

export const UPDATE_RELATION_LOCATION_REQUEST = "UPDATE_RELATION_LOCATION_REQUEST";
export const UPDATE_RELATION_LOCATION_SUCCESS = "UPDATE_RELATION_LOCATION_SUCCESS";
export const UPDATE_RELATION_LOCATION_FAILURE = "UPDATE_RELATION_LOCATION_FAILURE";