import {
  CREATE_ASSEMBLY_FAILURE,
  CREATE_ASSEMBLY_REQUEST,
  CREATE_ASSEMBLY_SUCCESS,
  DELETE_ASSEMBLY_FAILURE,
  DELETE_ASSEMBLY_REQUEST,
  DELETE_ASSEMBLY_SUCCESS,
  EDIT_ASSEMBLY_FAILURE,
  EDIT_ASSEMBLY_REQUEST,
  EDIT_ASSEMBLY_SUCCESS,
  GET_ASSEMBLY_MASTER_FAILURE,
  GET_ASSEMBLY_MASTER_REQUEST,
  GET_ASSEMBLY_MASTER_SUCCESS,
  GET_ASSEMBLY_TYPES_FAILURE,
  GET_ASSEMBLY_TYPES_REQUEST,
  GET_ASSEMBLY_TYPES_SUCCESS,
} from "./assemblyMasterTypes";

const initialStateForAssembly = {
  loading: false,
  assembly: [],
  error: "",
};

const getAssemblyMasterReducer = (state = initialStateForAssembly, action) => {
  switch (action.type) {
    case GET_ASSEMBLY_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSEMBLY_MASTER_SUCCESS:
      return {
        loading: false,
        assembly: action.payload,
        error: "",
      };
    case GET_ASSEMBLY_MASTER_FAILURE:
      return {
        loading: false,
        assembly: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAssemblyTypes = {
  loading: false,
  assemblyTypes: [],
  error: "",
};

const getAssemblyTypesReducer = (
  state = initialStateForAssemblyTypes,
  action
) => {
  switch (action.type) {
    case GET_ASSEMBLY_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSEMBLY_TYPES_SUCCESS:
      return {
        loading: false,
        assemblyTypes: action.payload,
        error: "",
      };
    case GET_ASSEMBLY_TYPES_FAILURE:
      return {
        loading: false,
        assemblyTypes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreateAssembly = {
  loading: false,
  createAssembly: {},
  error: "",
};

const createAssemblyReducer = (
  state = initialStateForCreateAssembly,
  action
) => {
  switch (action.type) {
    case CREATE_ASSEMBLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ASSEMBLY_SUCCESS:
      return {
        loading: false,
        createAssembly: action.payload,
        error: "",
      };
    case CREATE_ASSEMBLY_FAILURE:
      return {
        loading: false,
        createAssembly: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  deleteAssembly: {},
  error: "",
};

const deleteAssemblyReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_ASSEMBLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ASSEMBLY_SUCCESS:
      return {
        loading: true,
        deleteAssembly: action.payload,
        error: "",
      };
    case DELETE_ASSEMBLY_FAILURE:
      return {
        loading: false,
        deleteAssembly: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEdit = {
  loading: false,
  AssemblyEdit: {},
  error: "",
};

const editAssemblyReducer = (state = initialStateForEdit, action) => {
  switch (action.type) {
    case EDIT_ASSEMBLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ASSEMBLY_SUCCESS:
      return {
        loading: true,
        AssemblyEdit: action.payload,
        error: "",
      };
    case EDIT_ASSEMBLY_FAILURE:
      return {
        loading: false,
        AssemblyEdit: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAssemblyMasterReducer,
  createAssemblyReducer,
  deleteAssemblyReducer,
  editAssemblyReducer,
  getAssemblyTypesReducer,
};
