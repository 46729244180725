/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Functions from store
import { getProfile } from "../../store";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Profile({ profileData, getProfile }) {
  let user = cookies.get("loginDetailsForWMS");
  let jwtToken = user?.jwt;

  let email = user.data.email;

  useEffect(() => {
    getProfile(email, jwtToken);
  }, [getProfile]);

  const [profileInfo, setProfileInfo] = useState({});

  useEffect(() => {
    let temp = profileData.profile ? profileData.profile : {};

    setProfileInfo(temp);
  }, [profileData]);

  // let profileInfo = user.data;

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          width: { xs: "100%", sm: "100%", md: "60%", lg: "50%", xl: "50%" },
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "50px",
          marginBottom: "100px",
        }}
      >
        <MDBox ml={2} mt={2}>
          <MDTypography variant="h4" fontWeight="medium">
            User Profile
          </MDTypography>
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item style={{ marginLeft: "1.5rem", marginTop: "1.5rem" }}>
            <MDAvatar
              src="https://p.kindpng.com/picc/s/78-785827_user-profile-avatar-login-account-male-user-icon.png"
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {profileInfo?.userName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {profileInfo?.role}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Divider />
        <MDBox mb={2} mt={-2}>
          <MDBox p={2}>
            <MDBox>
              <MDBox key="name">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  name: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="inherit"
                  style={{ marginLeft: "4rem" }}
                >
                  {profileInfo?.name}
                </MDTypography>
              </MDBox>
              <MDBox key="email">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  e-mail: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="inherit"
                  style={{ marginLeft: "3.72rem" }}
                >
                  {profileInfo?.email}
                </MDTypography>
              </MDBox>
              <MDBox key="role">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  role: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="inherit"
                  style={{ marginLeft: "4.53rem" }}
                >
                  {profileInfo?.roleName}
                </MDTypography>
              </MDBox>
              {/* <MDBox key="clientId">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  client-ID: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="inherit"
                  style={{ marginLeft: "2.75rem" }}
                >
                  {profileInfo?.clientId}
                </MDTypography>
              </MDBox> */}
              <MDBox key="printerName">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Printer Name : &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="inherit"
                  style={{ marginLeft: "0.75rem" }}
                >
                  {profileInfo?.printerName}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profile,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getProfile: (email, jwtToken) => dispatch(getProfile(email, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Profile);
