import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import TextError from "utils/TextError";
import MDTypography from "components/MDTypography";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

const FormSelect = ({ label, options, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const [value, setValue] = useState({});

  useEffect(() => {
    if (!props.isMulti) {
      setValue({ label: field.value, value: field.value });
    }
    if (props.isMulti) {
      let arr = [];
      field.value.map((value) => {
        let options = {
          label: value,
          value: value,
        };
        arr.push(options);
      });
      setValue(arr);
    }
  }, []);

  return (
    <>
      <MDTypography variant="h6" color="inherit">
        {label}{" "}
        {props.notRequired ? "" : <span style={{ color: "red" }}>*</span>}
      </MDTypography>
      <Select
        {...field}
        {...props}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: 5,
            minHeight: "44px",
            height: "auto",
          }),
        }}
        className="select-css"
        maxMenuHeight={130}
        placeholder={props.placeholder ? props.placeholder : "Select..."}
        options={options}
        value={value}
        onChange={(option) => {
          setValue(option);
          if (!props.isMulti) {
            setFieldValue(field.name, option.value);
          }

          if (props.isMulti) {
            let arr = [];
            option.map((value) => arr.push(value.value));
            setFieldValue(field.name, arr);
          }
        }}
      />
      {meta.touched && meta.error && <TextError msg={meta.error} />}
    </>
  );
};

export default FormSelect;
