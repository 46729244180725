/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getCategoryItemMapping,
  createCategoryItemMapping,
  updateCategoryItemMapping,
  getCategories,
  getItemMaster,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForItemsViewModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  maxHeight: { xs: 340, sm: 340, md: 440, lg: 600, xl: 600 },
  overflow: "scroll",
  width: { xs: 350, lg: 800 },
  color: "black",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const CategoryItemMapping = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getCategoryItemMapping,
  categoryItemMappingState,
  createCategoryItemMapping,
  updateCategoryItemMapping,
  getCategories,
  allCategories,
  getItemMaster,
  listOfAllItems,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails?.data?.roleId;
  let userId = loginDetails?.data?.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let categoryItemMapping = modulesData.find(
    (moduleId) => moduleId.moduleId == "cbf5f0c3-01dc-4b05-a697-194a8cdf7c9d"
  );

  let createAccess = categoryItemMapping
    ? categoryItemMapping.actionId.includes(
        "50fb775e-f731-4dd7-954f-0ec20bd1cff4"
      )
    : null;
  let addAccess = categoryItemMapping
    ? categoryItemMapping.actionId.includes(
        "99d0dbea-0e32-4847-9e69-6ba3ec0b2b26"
      )
    : null;
  let removeAccess = categoryItemMapping
    ? categoryItemMapping.actionId.includes(
        "ac464e35-c53f-4614-8706-01c4d98e04e6"
      )
    : null;
  let viewMapping = categoryItemMapping
    ? categoryItemMapping.actionId.includes(
        "4fb110b1-768f-4f1f-a71c-00218321069a"
      )
    : null;

  useEffect(() => {
    getCategoryItemMapping(jwtToken);
  }, []);

  const [categoriesOptions, setCategoriesOptions] = useState([]);

  useEffect(() => {
    let tempCategoriesOptions = [];
    let data = allCategories.categories.data
      ? allCategories.categories.data
      : [];

    data.map((category) => {
      tempCategoriesOptions.push({
        label: `${category.categoryCode} / ${category.categoryName}`,
        value: category.categoryId,
      });
    });

    setCategoriesOptions(tempCategoriesOptions);
  }, [allCategories]);

  const [defaultCategoryItems, setDefaultCategoryItems] = useState([]);
  const [defaultCategoryItemsoptions, setDefaultCategoryItemsOptions] =
    useState([]);

  useEffect(() => {
    let tempItemsOptions = [];
    let data = defaultCategoryItems ? defaultCategoryItems : [];

    data.map((item) => {
      tempItemsOptions.push({
        label: `${item.itemCode} / ${item.itemDescription}`,
        value: item,
      });
    });

    setDefaultCategoryItemsOptions(tempItemsOptions);
  }, [defaultCategoryItems]);

  const [viewItemsData, setViewItemsData] = useState([]);
  const [itemsForRemovingoptions, setItemsForRemovingOptions] = useState([]);

  useEffect(() => {
    let tempItemsOptions = [];
    let data = viewItemsData ? viewItemsData : [];

    data.map((item) => {
      tempItemsOptions.push({
        label: `${item.itemCode} / ${item.itemDescription}`,
        value: item,
      });
    });

    setItemsForRemovingOptions(tempItemsOptions);
  }, [viewItemsData]);

  const [state, setState] = useState({
    columns: [
      { Header: "Category Code", accessor: "categoryCode" },
      { Header: "Category Name", accessor: "categoryName" },
      {
        Header: "action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [categoryCode, setCategoryCode] = useState("");
  const [categoryName, setCategoryName] = useState("");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempCategoryItem = [];
    let data = categoryItemMappingState?.categoryItemMapping?.data
      ? categoryItemMappingState?.categoryItemMapping?.data
      : [];
    setLoading(categoryItemMappingState.loading);
    let filteredData = data.filter((category) => category.categoryId === 1);

    setDefaultCategoryItems(filteredData[0]?.items);

    data.map((category) => {
      const categoryObj = {
        categoryCode: category.categoryCode,
        categoryName: category.categoryName,
        action: (
          <div>
            {category.categoryId !== 1 && (
              <Tooltip title="Add Item">
                <MDButton
                  disabled={!addAccess}
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  onClick={() => {
                    setCategoryCode(category.categoryCode);
                    setCategoryName(category.categoryName);
                    handleOpenAddMappingModal(category);
                    getItemMaster(jwtToken);
                  }}
                  style={{ marginRight: "5px" }}
                >
                  <Icon>add</Icon>
                </MDButton>
              </Tooltip>
            )}

            {category.categoryId !== 1 && (
              <Tooltip title="Remove Item">
                <MDButton
                  disabled={!removeAccess}
                  variant="gradient"
                  color="error"
                  iconOnly
                  type="button"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    setViewItemsData(category.items);
                    setCategoryCode(category.categoryCode);
                    setCategoryName(category.categoryName);
                    handleOpenRemoveMappingModal(category);
                    getItemMaster(jwtToken);
                  }}
                >
                  <Icon>remove</Icon>
                </MDButton>
              </Tooltip>
            )}

            <Tooltip title="View Item">
              <MDButton
                variant="gradient"
                disabled={!viewMapping}
                color="info"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setCategoryCode(category.categoryCode);
                  setCategoryName(category.categoryName);
                  handleOpenViewItemsModal(category.items);
                }}
              >
                <Icon>visibility</Icon>
              </MDButton>
            </Tooltip>
          </div>
        ),
      };
      tempCategoryItem.push(categoryObj);
    });

    setState({ ...state, rows: tempCategoryItem });
  }, [categoryItemMappingState]);

  const [categorySelected, setCategorySelected] = useState("");
  const handleCategoryChange = (selectedValue) => {
    setCategorySelected(selectedValue);
  };

  const [itemSelected, setItemSelected] = useState("");
  const handleItemChange = (selectedValue) => {
    setItemSelected(selectedValue);
  };

  const [itemForRemovingSelected, setItemForRemovingSelected] = useState("");
  const handleItemForRemovingChange = (selectedValue) => {
    setItemForRemovingSelected(selectedValue);
  };

  const [isErrorCreateMapping, setIsErrorCreateMapping] = useState(false);
  const [isSuccessCreateMapping, setIsSuccessCreateMapping] = useState(false);

  useEffect(() => {
    if (isErrorCreateMapping) {
      setTimeout(() => {
        setIsErrorCreateMapping(false);
      }, 3000);
    }
  }, [isErrorCreateMapping]);

  useEffect(() => {
    if (isSuccessCreateMapping) {
      setTimeout(() => {
        setIsSuccessCreateMapping(false);
      }, 3000);
    }
  }, [isSuccessCreateMapping]);

  const [openCreateMappingModal, setOpenCreateMappingModal] = useState(false);

  const handleOpenCreateMappingModal = () => {
    setOpenCreateMappingModal(true);
    getCategories(jwtToken);
    getItemMaster(jwtToken);
  };

  const handleCloseCreateMappingModal = () => {
    setOpenCreateMappingModal(false);
    setCategorySelected("");
    setItemSelected("");
  };

  const initialValueForCreateMapping = {
    categoryCode: "",
    itemCode: "",
  };

  const validationSchema = Yup.object({
    categoryCode: Yup.object().required("Select the category!"),
    itemCode: Yup.object().required("Select the item!"),
  });

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");

  const onSubmitForCreateMapping = async (values, { resetForm }) => {
    let payload = {
      categoryId: categorySelected.value,
      itemId: itemSelected?.value?.itemId,
    };

    let res = await createCategoryItemMapping(payload, jwtToken);

    if (res.status) {
      setIsSuccessCreateMapping(true);
      getCategoryItemMapping(jwtToken);
      setCategorySelected("");
      setItemSelected("");

      setTimeout(() => {
        handleCloseCreateMappingModal();
      }, 2250);

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForCreate(res.data.data.msg);
      setIsErrorCreateMapping(true);
    }
  };

  const formikForCreateMapping = useFormik({
    initialValues: initialValueForCreateMapping,
    validationSchema: validationSchema,
    onSubmit: onSubmitForCreateMapping,
  });

  const [isErrorAddMapping, setIsErrorAddMapping] = useState(false);
  const [isSuccessAddMapping, setIsSuccessAddMapping] = useState(false);

  useEffect(() => {
    if (isErrorAddMapping) {
      setTimeout(() => {
        setIsErrorAddMapping(false);
      }, 3000);
    }
  }, [isErrorAddMapping]);

  useEffect(() => {
    if (isSuccessAddMapping) {
      setTimeout(() => {
        setIsSuccessAddMapping(false);
      }, 3000);
    }
  }, [isSuccessAddMapping]);

  const [openAddMappingModal, setOpenAddMappingModal] = useState(false);
  const [dataForAddingItem, setDataForAddingItem] = useState({});

  const handleOpenAddMappingModal = (data) => {
    setDataForAddingItem(data);
    setOpenAddMappingModal(true);
  };

  const handleCloseAddMappingModal = () => {
    setOpenAddMappingModal(false);
    setItemSelected("");
  };

  const initialValueForAddMapping = {
    itemCode: "",
  };

  const validationSchemaForAddMapping = Yup.object({
    itemCode: Yup.object().required("Select the item!"),
  });

  const [errorMsgForAdd, setErrorMsgForAdd] = useState("");

  const onSubmitForAddMapping = async (values, { resetForm }) => {
    let payload = {
      categoryId: dataForAddingItem.categoryId,
      itemId: itemSelected?.value?.itemId,
      id: itemSelected?.value?.itemCategoryMapId,
    };

    let res = await updateCategoryItemMapping(payload, jwtToken);

    if (res.status) {
      setIsSuccessAddMapping(true);
      getCategoryItemMapping(jwtToken);
      setItemSelected("");

      setTimeout(() => {
        handleCloseAddMappingModal();
      }, 2250);

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForAdd(res.data.data.msg);
      setIsErrorAddMapping(true);
    }
  };

  const formikForAddMapping = useFormik({
    initialValues: initialValueForAddMapping,
    validationSchema: validationSchemaForAddMapping,
    onSubmit: onSubmitForAddMapping,
  });

  const [isErrorRemoveMapping, setIsErrorRemoveMapping] = useState(false);
  const [isSuccessRemoveMapping, setIsSuccessRemoveMapping] = useState(false);

  useEffect(() => {
    if (isErrorRemoveMapping) {
      setTimeout(() => {
        setIsErrorRemoveMapping(false);
      }, 3000);
    }
  }, [isErrorRemoveMapping]);

  useEffect(() => {
    if (isSuccessRemoveMapping) {
      setTimeout(() => {
        setIsSuccessRemoveMapping(false);
      }, 3000);
    }
  }, [isSuccessRemoveMapping]);

  const [openRemoveMappingModal, setOpenRemoveMappingModal] = useState(false);
  const [dataForRemovingItem, setDataForRemovingItem] = useState({});

  const handleOpenRemoveMappingModal = (data) => {
    setDataForRemovingItem(data);
    setOpenRemoveMappingModal(true);
  };

  const handleCloseRemoveMappingModal = () => {
    setOpenRemoveMappingModal(false);
    setItemForRemovingSelected("");
  };

  const initialValueForRemoveMapping = {
    itemCode: "",
  };

  const validationSchemaForRemoveMapping = Yup.object({
    itemCode: Yup.object().required("Select the item!"),
  });

  const [errorMsgForRemove, setErrorMsgForRemove] = useState("");

  const onSubmitForRemoveMapping = async (values, { resetForm }) => {
    let payload = {
      itemId: itemForRemovingSelected?.value?.itemId,
      categoryId: 1,
      id: itemForRemovingSelected?.value?.itemCategoryMapId,
    };

    let res = await updateCategoryItemMapping(payload, jwtToken);

    if (res.status) {
      setIsSuccessRemoveMapping(true);
      getCategoryItemMapping(jwtToken);
      setItemForRemovingSelected("");

      setTimeout(() => {
        handleCloseRemoveMappingModal();
      }, 2250);

      resetForm();
    }
    if (!res.status) {
      setErrorMsgForRemove(res.data.data.msg);
      setIsErrorRemoveMapping(true);
    }
  };

  const formikForRemoveMapping = useFormik({
    initialValues: initialValueForRemoveMapping,
    validationSchema: validationSchemaForRemoveMapping,
    onSubmit: onSubmitForRemoveMapping,
  });

  const [viewItemsTable, setViewItemsTable] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
    ],
    rows: [],
  });

  const { columns: viewItemsColumns, rows: viewItemsRows } = viewItemsTable;

  const [openViewItemsModal, setOpenViewItemsModal] = useState(false);

  const handleOpenViewItemsModal = (data) => {
    setViewItemsData(data);
    setOpenViewItemsModal(true);
  };

  const handleCloseViewItemsModal = () => {
    setOpenViewItemsModal(false);
  };

  useEffect(() => {
    let tempData = [];
    let data = viewItemsData ? viewItemsData : [];

    data.map((item) => {
      const itemObj = {
        itemCode: item.itemCode,
        itemDescription: item.itemDescription,
      };
      tempData.push(itemObj);
    });

    setViewItemsTable({ ...viewItemsTable, rows: tempData });
  }, [viewItemsData]);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {/* {createAssetAccess && ( */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={3}
                >
                  {createAccess && (
                    <MDBox color="text" px={2} ml={-2} mb={1}>
                      <CreateButton
                        tooltip="Create Category Item Mapping"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateMappingModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    </MDBox>
                  )}
                </MDBox>

                <MDBox mt={0}>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
                <Modal
                  open={openCreateMappingModal}
                  onClose={handleCloseCreateMappingModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Mapping
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateMappingModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreateMapping.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Category
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Select Category..."
                              name="categoryCode"
                              value={categorySelected}
                              options={categoriesOptions}
                              onChange={(selectedOption) => {
                                handleCategoryChange(selectedOption);
                                formikForCreateMapping.setFieldValue(
                                  "categoryCode",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForCreateMapping.errors.categoryCode && (
                              <TextError
                                msg={formikForCreateMapping.errors.categoryCode}
                              />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Item Code/Des
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Select Item..."
                              name="itemCode"
                              value={itemSelected}
                              options={defaultCategoryItemsoptions}
                              onChange={(selectedOption) => {
                                handleItemChange(selectedOption);
                                formikForCreateMapping.setFieldValue(
                                  "itemCode",
                                  selectedOption
                                );
                              }}
                            />
                            {formikForCreateMapping.errors.itemCode && (
                              <TextError
                                msg={formikForCreateMapping.errors.itemCode}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={2}>
                            <Collapse in={isSuccessCreateMapping}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateMapping(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Item mapped successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorCreateMapping}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateMapping(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMsgForCreate}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={!formikForCreateMapping.isValid}
                            >
                              Create Mapping
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openAddMappingModal}
                  onClose={handleCloseAddMappingModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Add Item
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseAddMappingModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForAddMapping.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select the Item
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Select Item..."
                            name="itemCode"
                            value={itemSelected}
                            options={defaultCategoryItemsoptions}
                            onChange={(selectedOption) => {
                              handleItemChange(selectedOption);
                              formikForAddMapping.setFieldValue(
                                "itemCode",
                                selectedOption
                              );
                            }}
                          />
                          {formikForAddMapping.errors.itemCode && (
                            <TextError
                              msg={formikForAddMapping.errors.itemCode}
                            />
                          )}
                        </MDBox>

                        <MDBox>
                          <Collapse in={isErrorAddMapping}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorAddMapping(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForAdd}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessAddMapping}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessAddMapping(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Item added successfully!
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formikForAddMapping.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openRemoveMappingModal}
                  onClose={handleCloseRemoveMappingModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Remove Item
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseRemoveMappingModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForRemoveMapping.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Select the Item
                          </MDTypography>

                          <Select
                            isClearable
                            className="select-css"
                            maxMenuHeight={130}
                            placeholder="Select Item..."
                            name="itemCode"
                            value={itemForRemovingSelected}
                            options={itemsForRemovingoptions}
                            onChange={(selectedOption) => {
                              handleItemForRemovingChange(selectedOption);
                              formikForRemoveMapping.setFieldValue(
                                "itemCode",
                                selectedOption
                              );
                            }}
                          />
                          {formikForRemoveMapping.errors.itemCode && (
                            <TextError
                              msg={formikForRemoveMapping.errors.itemCode}
                            />
                          )}
                        </MDBox>

                        <MDBox>
                          <Collapse in={isErrorRemoveMapping}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorRemoveMapping(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForRemove}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessRemoveMapping}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessRemoveMapping(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Item removed successfully!
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formikForRemoveMapping.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openViewItemsModal}
                  onClose={handleCloseViewItemsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForItemsViewModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Items
                      </MDTypography>

                      <MDBox>
                        <span>
                          <Tooltip title="Close">
                            <IconButton
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "-55px",
                                marginBottom: "5px",
                              }}
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseViewItemsModal}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginBottom: "1rem" }} />
                    <div style={{ display: "flex" }}>
                      <MDTypography variant="h5" style={{ marginLeft: "2rem" }}>
                        Category:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {`${categoryCode} / ${categoryName}`}
                        </span>
                      </MDTypography>
                    </div>

                    <MDBox>
                      <DataTable
                        table={{
                          columns: viewItemsColumns,
                          rows: viewItemsRows,
                        }}
                        isSorted={true}
                        tableSearch={true}
                        entriesPerPage={{
                          defaultValue: 5,
                          entries: [5, 10, 15, 20, 25, 30],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    categoryItemMappingState: state.getCategoryItemMappingReducer,
    allCategories: state.getCategoriesReducer,
    listOfAllItems: state.itemMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getCategoryItemMapping: (jwtToken) =>
      dispatch(getCategoryItemMapping(jwtToken)),
    createCategoryItemMapping: (payload, jwtToken) =>
      dispatch(createCategoryItemMapping(payload, jwtToken)),
    updateCategoryItemMapping: (payload, jwtToken) =>
      dispatch(updateCategoryItemMapping(payload, jwtToken)),
    getCategories: (jwtToken) => dispatch(getCategories(jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryItemMapping);
