import { CREATE_PALLET_FAILURE, CREATE_PALLET_REQUEST, CREATE_PALLET_SUCCESS, CREATE_PALLET_TYPE_FAILURE, CREATE_PALLET_TYPE_REQUEST, CREATE_PALLET_TYPE_SUCCESS, DELETE_PALLET_FAILURE, DELETE_PALLET_REQUEST, DELETE_PALLET_SUCCESS, EDIT_PALLET_FAILURE, EDIT_PALLET_REQUEST, EDIT_PALLET_SUCCESS, GET_PALLETS_FAILURE, GET_PALLETS_REQUEST, GET_PALLETS_SUCCESS, GET_PALLET_TYPES_FAILURE, GET_PALLET_TYPES_REQUEST, GET_PALLET_TYPES_SUCCESS} from "./palletMasterTypes";

  const initialStateForGetPalletTypes = {
    loading: false,
    palletTypes: [],
    error: "",
  };
  
  const getPalletTypesReducer = (
    state = initialStateForGetPalletTypes,
    action
  ) => {
    switch (action.type) {
      case GET_PALLET_TYPES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_PALLET_TYPES_SUCCESS:
        return {
          loading: false,
          palletTypes: action.payload,
          error: "",
        };
      case GET_PALLET_TYPES_FAILURE:
        return {
          loading: false,
          palletTypes: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  
  const initialStateForCreatePalletType = {
    loading: false,
    createPalletType: {},
    error: "",
  };
  
  const createPalletTypeReducer = (
    state = initialStateForCreatePalletType,
    action
  ) => {
    switch (action.type) {
      case CREATE_PALLET_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_PALLET_TYPE_SUCCESS:
        return {
          loading: false,
          createPalletType: action.payload,
          error: "",
        };
      case CREATE_PALLET_TYPE_FAILURE:
        return {
          loading: false,
          createPalletType: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };

  
  const initialStateForDelete = {
    loading: false,
    deletePallet: {},
    error: "",
  };
  
  const deletePalletReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
      case DELETE_PALLET_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_PALLET_SUCCESS:
        return {
          loading: true,
          deletePallet: action.payload,
          error: "",
        };
      case DELETE_PALLET_FAILURE:
        return {
          loading: false,
          deletePallet: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
const initialStateForEdit = {
    loading: false,
    palletEdit: {},
    error: "",
  };
  
  const editPalletReducer = (state = initialStateForEdit, action) => {
    switch (action.type) {
      case EDIT_PALLET_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case EDIT_PALLET_SUCCESS:
        return {
          loading: true,
          palletEdit: action.payload,
          error: "",
        };
      case EDIT_PALLET_FAILURE:
        return {
          loading: false,
          palletEdit: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialStateForPallets = {
    loading: false,
    pallets: [],
    error: "",
  };
  
  const getPalletsReducer = (state = initialStateForPallets, action) => {
    switch (action.type) {
      case GET_PALLETS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_PALLETS_SUCCESS:
        return {
          loading: false,
          pallets: action.payload,
          error: "",
        };
      case GET_PALLETS_FAILURE:
        return {
          loading: false,
          pallets: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const initialStateForCreatePallet = {
    loading: false,
    createPallet: {},
    error: "",
  };
  
  const createPalletReducer = (
    state = initialStateForCreatePallet,
    action
  ) => {
    switch (action.type) {
      case CREATE_PALLET_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_PALLET_SUCCESS:
        return {
          loading: false,
          createPallet: action.payload,
          error: "",
        };
      case CREATE_PALLET_FAILURE:
        return {
          loading: false,
          createPallet: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };



  export {
    getPalletTypesReducer,
    createPalletTypeReducer,
    
    deletePalletReducer,
    editPalletReducer,
    getPalletsReducer,
    createPalletReducer
  };