import {
  GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE,
  TRANSFERORDER_INDIVIDUALPRINT_REQUEST,
  TRANSFERORDER_INDIVIDUALPRINT_SUCCESS,
  TRANSFERORDER_INDIVIDUALPRINT_FAILURE,
  TRANSFERORDER_PACKAGEPRINT_REQUEST,
  TRANSFERORDER_PACKAGEPRINT_SUCCESS,
  TRANSFERORDER_PACKAGEPRINT_FAILURE,
} from "./createTransferOrderTypes";

const initialState = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuByItemCodeAndWarehouseCodeReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForTransferOrderIndividualPrint = {
  loading: false,
  qty: [],
  error: "",
};

const transferOrderIndividualPrintReducer = (
  state = initialStateForTransferOrderIndividualPrint,
  action
) => {
  switch (action.type) {
    case TRANSFERORDER_INDIVIDUALPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSFERORDER_INDIVIDUALPRINT_SUCCESS:
      return {
        loading: false,
        qty: action.payload,
        error: "",
      };
    case TRANSFERORDER_INDIVIDUALPRINT_FAILURE:
      return {
        loading: false,
        qty: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForTransferOrderPackagePrint = {
  loading: false,
  qty: [],
  error: "",
};

const transferOrderPackagePrintReducer = (
  state = initialStateForTransferOrderPackagePrint,
  action
) => {
  switch (action.type) {
    case TRANSFERORDER_PACKAGEPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSFERORDER_PACKAGEPRINT_SUCCESS:
      return {
        loading: false,
        qty: action.payload,
        error: "",
      };
    case TRANSFERORDER_PACKAGEPRINT_FAILURE:
      return {
        loading: false,
        qty: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getSkuByItemCodeAndWarehouseCodeReducer,
  transferOrderIndividualPrintReducer,
  transferOrderPackagePrintReducer,
};
