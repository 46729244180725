export const GET_SALESORDER_REQUEST = "GET_SALESORDER_REQUEST";
export const GET_SALESORDER_SUCCESS = "GET_SALESORDER_SUCCESS";
export const GET_SALESORDER_FAILURE = "GET_SALESORDER_FAILURE";

export const GET_FG_CODE_REQUEST = "GET_FG_CODE_REQUEST";
export const GET_FG_CODE_SUCCESS = "GET_FG_CODE_SUCCESS";
export const GET_FG_CODE_FAILURE = "GET_FG_CODE_FAILURE";

export const PUT_APPROVEVIN_REQUEST = "PUT_APPROVEVIN_REQUEST";
export const PUT_APPROVEVIN_SUCCESS = "PUT_APPROVEVIN_SUCCESS";
export const PUT_APPROVEVIN_FAILURE = "PUT_APPROVEVIN_FAILURE";

export const GET_VINSBYTRIPNO_REQUEST = "GET_VINSBYTRIPNO_REQUEST";
export const GET_VINSBYTRIPNO_SUCCESS = "GET_VINSBYTRIPNO_SUCCESS";
export const GET_VINSBYTRIPNO_FAILURE = "GET_VINSBYTRIPNO_FAILURE";

export const GET_ACCESSORIESBYVIN_REQUEST = "GET_ACCESSORIESBYVIN_REQUEST";
export const GET_ACCESSORIESBYVIN_SUCCESS = "GET_ACCESSORIESBYVIN_SUCCESS";
export const GET_ACCESSORIESBYVIN_FAILURE = "GET_ACCESSORIESBYVIN_FAILURE";

export const UPDATE_RELEASE_TRIP_REQUEST = "UPDATE_RELEASE_TRIP_REQUEST";
export const UPDATE_RELEASE_TRIP_SUCCESS = "UPDATE_RELEASE_TRIP_SUCCESS";
export const UPDATE_RELEASE_TRIP_FAILURE = "UPDATE_RELEASE_TRIP_FAILURE";

export const UPDATE_VIN_REQUEST = "UPDATE_VIN_REQUEST";
export const UPDATE_VIN_SUCCESS = "UPDATE_VIN_SUCCESS";
export const UPDATE_VIN_FAILURE = "UPDATE_VIN_FAILURE";

export const CREATE_DISPATCH_REQUEST = "CREATE_DISPATCH_REQUEST";
export const CREATE_DISPATCH_SUCCESS = "CREATE_DISPATCH_SUCCESS";
export const CREATE_DISPATCH_FAILURE = "CREATE_DISPATCH_FAILURE";
