import {
  GET_TRANSFERORDERS_REQUEST,
  GET_TRANSFERORDERS_SUCCESS,
  GET_TRANSFERORDERS_FAILURE,
  CREATE_TRANSFER_ORDER_REQUEST,
  CREATE_TRANSFER_ORDER_SUCCESS,
  CREATE_TRANSFER_ORDER_FAILURE,
  CREATE_TRANSFER_ORDER_FOR_QTY_REQUEST,
  CREATE_TRANSFER_ORDER_FOR_QTY_SUCCESS,
  CREATE_TRANSFER_ORDER_FOR_QTY_FAILURE,
  GET_SUIDS_REQUEST,
  GET_SUIDS_SUCCESS,
  GET_SUIDS_FAILURE,
  GET_AVAILABLEQTYFORTO_REQUEST,
  GET_AVAILABLEQTYFORTO_SUCCESS,
  GET_AVAILABLEQTYFORTO_FAILURE,
} from "./transferOrderTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getTransferOrdersRequest = () => {
  return {
    type: GET_TRANSFERORDERS_REQUEST,
  };
};

const getTransferOrdersSuccess = (payload) => {
  return {
    type: GET_TRANSFERORDERS_SUCCESS,
    payload: payload,
  };
};

const getTransferOrdersFailure = (error) => {
  return {
    type: GET_TRANSFERORDERS_FAILURE,
    payload: error,
  };
};

const getAllTransferOrders = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getTransferOrdersRequest());
    let url = `${baseUrl}/transfer_order/get_transfer_order`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getTransferOrdersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getTransferOrdersFailure(err.message));
      });
  };
};

const createTransferOrderRequest = () => {
  return {
    type: CREATE_TRANSFER_ORDER_REQUEST,
  };
};

const createTransferOrderSuccess = (payload) => {
  return {
    type: CREATE_TRANSFER_ORDER_SUCCESS,
    payload: payload,
  };
};

const createTransferOrderFailure = (error) => {
  return {
    type: CREATE_TRANSFER_ORDER_FAILURE,
    payload: error,
  };
};

const createTransferOrder = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createTransferOrderRequest());
    let url = `${baseUrl}/transfer_order/create_with_suids`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createTransferOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createTransferOrderFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createTransferOrderForQtyRequest = () => {
  return {
    type: CREATE_TRANSFER_ORDER_FOR_QTY_REQUEST,
  };
};

const createTransferOrderForQtySuccess = (payload) => {
  return {
    type: CREATE_TRANSFER_ORDER_FOR_QTY_SUCCESS,
    payload: payload,
  };
};

const createTransferOrderForQtyFailure = (error) => {
  return {
    type: CREATE_TRANSFER_ORDER_FOR_QTY_FAILURE,
    payload: error,
  };
};

const createTransferOrderForQty = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createTransferOrderForQtyRequest());
    let url = `${baseUrl}/transfer_order/create_with_qty`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createTransferOrderForQtySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createTransferOrderForQtyFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getSuidsRequest = () => {
  return {
    type: GET_SUIDS_REQUEST,
  };
};

const getSuidsSuccess = (payload) => {
  return {
    type: GET_SUIDS_SUCCESS,
    payload: payload,
  };
};

const getSuidsFailure = (error) => {
  return {
    type: GET_SUIDS_FAILURE,
    payload: error,
  };
};

const getSuids = (itemId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSuidsRequest());
    let url = `${baseUrl}/transfer_order/get_suids?itemId=${itemId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSuidsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSuidsFailure(err.message));
      });
  };
};

const getAvailableQtyForToRequest = () => {
  return {
    type: GET_AVAILABLEQTYFORTO_REQUEST,
  };
};

const getAvailableQtyForToSuccess = (payload) => {
  return {
    type: GET_AVAILABLEQTYFORTO_SUCCESS,
    payload: payload,
  };
};

const getAvailableQtyForToFailure = (error) => {
  return {
    type: GET_AVAILABLEQTYFORTO_FAILURE,
    payload: error,
  };
};

const getAvailableQtyForTo = (itemId, location, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAvailableQtyForToRequest());
    let url = `${baseUrl}/picklist/available_qty_for_location?item_id=${itemId}&location_code=${location}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAvailableQtyForToSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAvailableQtyForToFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getAllTransferOrders,
  createTransferOrder,
  createTransferOrderForQty,
  getSuids,
  getAvailableQtyForTo,
};
