import axios from "axios";
import Config from "../../config/index";
import {
  CREATE_PRODUCTION_PLAN_FAILURE,
  CREATE_PRODUCTION_PLAN_REQUEST,
  CREATE_PRODUCTION_PLAN_SUCCESS,
  DELETE_PRODUCTION_PLAN_FAILURE,
  DELETE_PRODUCTION_PLAN_REQUEST,
  DELETE_PRODUCTION_PLAN_SUCCESS,
  EDIT_REQUIRED_QTY_FAILURE,
  EDIT_REQUIRED_QTY_REQUEST,
  EDIT_REQUIRED_QTY_SUCCESS,
  GET_PRODUCTION_PLAN_FAILURE,
  GET_PRODUCTION_PLAN_REQUEST,
  GET_PRODUCTION_PLAN_SUCCESS,
  PUBLISH_PRODUCTION_PLAN_FAILURE,
  PUBLISH_PRODUCTION_PLAN_REQUEST,
  PUBLISH_PRODUCTION_PLAN_SUCCESS,
  UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_FAILURE,
  UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_REQUEST,
  UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_SUCCESS,
} from "./productionPlanTypes";

let baseUrl = Config.baseUrl;

const getProductionPlanRequest = () => {
  return {
    type: GET_PRODUCTION_PLAN_REQUEST,
  };
};

const getProductionPlanSuccess = (payload) => {
  return {
    type: GET_PRODUCTION_PLAN_SUCCESS,
    payload: payload,
  };
};

const getProductionPlanFailure = (error) => {
  return {
    type: GET_PRODUCTION_PLAN_FAILURE,
    payload: error,
  };
};

const getProductionPlan = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getProductionPlanRequest());
    let url = `${baseUrl}/production_plan/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProductionPlanSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProductionPlanFailure(err.message));
      });
  };
};

const createProductionPlanRequest = () => {
  return {
    type: CREATE_PRODUCTION_PLAN_REQUEST,
  };
};

const createProductionPlanSuccess = (payload) => {
  return {
    type: CREATE_PRODUCTION_PLAN_SUCCESS,
    payload: payload,
  };
};

const createProductionPlanFailure = (error) => {
  return {
    type: CREATE_PRODUCTION_PLAN_FAILURE,
    payload: error,
  };
};

const createProductionPlan = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createProductionPlanRequest());
    let url = `${baseUrl}/production_plan/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createProductionPlanSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createProductionPlanFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editRequiredQtyRequest = () => {
  return {
    type: EDIT_REQUIRED_QTY_REQUEST,
  };
};

const editRequiredQtySuccess = (payload) => {
  return {
    type: EDIT_REQUIRED_QTY_SUCCESS,
    payload: payload,
  };
};

const editRequiredQtyFaliure = (error) => {
  return {
    type: EDIT_REQUIRED_QTY_FAILURE,
    payload: error,
  };
};

const editRequiredQty = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editRequiredQtyRequest());
    let url = `${baseUrl}/production_plan/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editRequiredQtySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editRequiredQtyFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteProductionPlanRequest = () => {
  return {
    type: DELETE_PRODUCTION_PLAN_REQUEST,
  };
};

const deleteProductionPlanSuccess = (payload) => {
  return {
    type: DELETE_PRODUCTION_PLAN_SUCCESS,
    payload: payload,
  };
};

const deleteProductionPlanFaliure = (error) => {
  return {
    type: DELETE_PRODUCTION_PLAN_FAILURE,
    payload: error,
  };
};

const deleteProductionPlan = (productionPlanId, userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteProductionPlanRequest());
    let url = `${baseUrl}/production_plan/delete?productionPlanId=${productionPlanId}&userId=${userId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteProductionPlanSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteProductionPlanFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadForProductionPlanRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_REQUEST,
  };
};

const uploadForProductionPlanSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_SUCCESS,
    payload: payload,
  };
};

const uploadForProductionPlanFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_PRODUCTION_PLAN_FAILURE,
    payload: error,
  };
};

const uploadForProductionPlan = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadForProductionPlanRequest());
    let url = `${baseUrl}/production_plan/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadForProductionPlanSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadForProductionPlanFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const publishProductionPlanRequest = () => {
  return {
    type: PUBLISH_PRODUCTION_PLAN_REQUEST,
  };
};

const publishProductionPlanSuccess = (payload) => {
  return {
    type: PUBLISH_PRODUCTION_PLAN_SUCCESS,
    payload: payload,
  };
};

const publishProductionPlanFailure = (error) => {
  return {
    type: PUBLISH_PRODUCTION_PLAN_FAILURE,
    payload: error,
  };
};

const publishProductionPlan = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(publishProductionPlanRequest());
    let url = `${baseUrl}/production_plan/publish`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(publishProductionPlanSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(publishProductionPlanFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getProductionPlan,
  createProductionPlan,
  editRequiredQty,
  deleteProductionPlan,
  uploadForProductionPlan,
  publishProductionPlan,
};
