export const GET_ALLOCATED_ACTIVITY_REQUEST = "GET_ALLOCATED_ACTIVITY_REQUEST";
export const GET_ALLOCATED_ACTIVITY_SUCCESS = "GET_ALLOCATED_ACTIVITY_SUCCESS";
export const GET_ALLOCATED_ACTIVITY_FAILURE = "GET_ALLOCATED_ACTIVITY_FAILURE";


export const GET_CREATEDACTIVITY_REQUEST = "GET_CREATEDACTIVITY_REQUEST";
export const GET_CREATEDACTIVITY_SUCCESS = "GET_CREATEDACTIVITY_SUCCESS";
export const GET_CREATEDACTIVITY_FAILURE = "GET_CREATEDACTIVITY_FAILURE";

export const CREATE_ACTIVITY_REQUEST = "CREATE_ACTIVITY_REQUEST";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "CREATE_ACTIVITY_FAILURE";


export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";

export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE";