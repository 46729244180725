export const GET_FINISHEDGOODS_REQUEST = "GET_FINISHEDGOODS_REQUEST";
export const GET_FINISHEDGOODS_SUCCESS = "GET_FINISHEDGOODS_SUCCESS";
export const GET_FINISHEDGOODS_FAILURE = "GET_FINISHEDGOODS_FAILURE";

export const GET_GROUPED_FINISHEDGOODS_REQUEST =
  "GET_GROUPED_FINISHEDGOODS_REQUEST";
export const GET_GROUPED_FINISHEDGOODS_SUCCESS =
  "GET_GROUPED_FINISHEDGOODS_SUCCESS";
export const GET_GROUPED_FINISHEDGOODS_FAILURE =
  "GET_GROUPED_FINISHEDGOODS_FAILURE";

export const GET_VINBYFGCODE_REQUEST = "GET_VINBYFGCODE_REQUEST";
export const GET_VINBYFGCODE_SUCCESS = "GET_VINBYFGCODE_SUCCESS";
export const GET_VINBYFGCODE_FAILURE = "GET_VINBYFGCODE_FAILURE";

export const CREATE_PICKLIST_FOR_SUID_REQUEST =
  "CREATE_PICKLIST_FOR_SUID_REQUEST";
export const CREATE_PICKLIST_FOR_SUID_SUCCESS =
  "CREATE_PICKLIST_FOR_SUID_SUCCESS";
export const CREATE_PICKLIST_FOR_SUID_FAILURE =
  "CREATE_PICKLIST_FOR_SUID_FAILURE";

export const CREATE_PICKLIST_FOR_QTY_REQUEST =
  "CREATE_PICKLIST_FOR_QTY_REQUEST";
export const CREATE_PICKLIST_FOR_QTY_SUCCESS =
  "CREATE_PICKLIST_FOR_QTY_SUCCESS";
export const CREATE_PICKLIST_FOR_QTY_FAILURE =
  "CREATE_PICKLIST_FOR_QTY_FAILURE";

export const GET_SUIDS_FOR_FG_REQUEST = "GET_SUIDS_FOR_FG_REQUEST";
export const GET_SUIDS_FOR_FG_SUCCESS = "GET_SUIDS_FOR_FG_SUCCESS";
export const GET_SUIDS_FOR_FG_FAILURE = "GET_SUIDS_FOR_FG_FAILURE";

export const GET_ITEMS_FOR_FG_REQUEST = "GET_ITEMS_FOR_FG_REQUEST";
export const GET_ITEMS_FOR_FG_SUCCESS = "GET_ITEMS_FOR_FG_SUCCESS";
export const GET_ITEMS_FOR_FG_FAILURE = "GET_ITEMS_FOR_FG_FAILURE";

export const GET_FG_CODE_FOR_DOCUMENT_REQUEST =
  "GET_FG_CODE_FOR_DOCUMENT_REQUEST";
export const GET_FG_CODE_FOR_DOCUMENT_SUCCESS =
  "GET_FG_CODE_FOR_DOCUMENT_SUCCESS";
export const GET_FG_CODE_FOR_DOCUMENT_FAILURE =
  "GET_FG_CODE_FOR_DOCUMENT_FAILURE";
