/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

function configs(labels, datasets) {
  let dataArr = [];

  datasets.map((singleData) => {
    const dataObj = {
      label: singleData.label,
      tension: 0.4,
      borderWidth: 0,
      borderRadius: 4,
      borderSkipped: false,
      backgroundColor: singleData.color,
      data: singleData.data,
      maxBarThickness: 6,
      ...singleData,
    };

    dataArr.push(dataObj);
  });

  return {
    data: {
      labels,
      datasets: dataArr,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: "#000",
          bodyColor: "#fff",
          borderColor: "#fff",
          bodyFont: { size: 13 },
          borderWidth: 1,
          padding: 10,
          titleColor: "#fff",
          titleFont: { weight: "bold", size: 14 },
          yAlign: "bottom",
          borderColor: "fff",

          callbacks: {
            label: function (context) {
              let label;
              if (context.dataset.label === "Empty") {
                label = `Empty : ${context.parsed.y}`;
              }
              if (context.dataset.label === "Occupied") {
                label = `Occupied : ${context.parsed.y}   Qty : ${
                  context.dataset.items[context.dataIndex]
                }`;
              }
              return label;
            },
          },
        },
      },

      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 500,
            beginAtZero: true,
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            color: "black",
            // color: "#fff",
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            display: true,
            color: "black",
            // color: "#f8f9fa",
            padding: 10,
            font: {
              size: 12,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
