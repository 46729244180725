import {
  GET_ACN_FOR_CATEGORY_FAILURE,
  GET_ACN_FOR_CATEGORY_REQUEST,
  GET_ACN_FOR_CATEGORY_SUCCESS,
  GET_CATEGORY_MAPPING_FAILURE,
  GET_CATEGORY_MAPPING_REQUEST,
  GET_CATEGORY_MAPPING_SUCCESS,
  GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_FAILURE,
  GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_REQUEST,
  GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_SUCCESS,
  GET_CATEGORYITEMS_REQUEST,
  GET_CATEGORYITEMS_SUCCESS,
  GET_CATEGORYITEMS_FAILURE,
} from "./categoryLocationMappingTypes";

const initialState = {
  loading: false,
  category: [],
  error: "",
};

const getCategoryLocationMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_MAPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORY_MAPPING_SUCCESS:
      return {
        loading: false,
        category: action.payload,
        error: "",
      };
    case GET_CATEGORY_MAPPING_FAILURE:
      return {
        loading: false,
        category: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel1ByLevelForCategory = {
  loading: false,
  level1ByLevel: [],
  error: "",
};

const getLevel1ByLevelReducerForCategory = (
  state = initialStateForLevel1ByLevelForCategory,
  action
) => {
  switch (action.type) {
    case GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_SUCCESS:
      return {
        loading: false,
        level1ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_FAILURE:
      return {
        loading: false,
        level1ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel2ByLevelForCategory = {
  loading: false,
  level2ByLevel: [],
  error: "",
};

const getLevel2ByLevelForCategoryReducer = (
  state = initialStateForLevel2ByLevelForCategory,
  action
) => {
  switch (action.type) {
    case GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_SUCCESS:
      return {
        loading: false,
        level2ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_FAILURE:
      return {
        loading: false,
        level2ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel3ByLevelForCategory = {
  loading: false,
  level3ByLevel: [],
  error: "",
};

const getLevel3ByLevelForCategoryReducer = (
  state = initialStateForLevel3ByLevelForCategory,
  action
) => {
  switch (action.type) {
    case GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_SUCCESS:
      return {
        loading: false,
        level3ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_FAILURE:
      return {
        loading: false,
        level3ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel4ByLevelForCategory = {
  loading: false,
  level4ByLevel: [],
  error: "",
};

const getLevel4ByLevelForCategoryReducer = (
  state = initialStateForLevel4ByLevelForCategory,
  action
) => {
  switch (action.type) {
    case GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_SUCCESS:
      return {
        loading: false,
        level4ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_FAILURE:
      return {
        loading: false,
        level4ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForActivityForCategory = {
  loading: false,
  acn: [],
  error: "",
};

const getAcnReducerForCategory = (
  state = initialStateForActivityForCategory,
  action
) => {
  switch (action.type) {
    case GET_ACN_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACN_FOR_CATEGORY_SUCCESS:
      return {
        loading: false,
        acn: action.payload,
        error: "",
      };
    case GET_ACN_FOR_CATEGORY_FAILURE:
      return {
        loading: false,
        acn: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCategoryItems = {
  loading: false,
  categoryItems: [],
  error: "",
};

const getCategoryItemsReducer = (
  state = initialStateForCategoryItems,
  action
) => {
  switch (action.type) {
    case GET_CATEGORYITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORYITEMS_SUCCESS:
      return {
        loading: false,
        categoryItems: action.payload,
        error: "",
      };
    case GET_CATEGORYITEMS_FAILURE:
      return {
        loading: false,
        categoryItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getCategoryLocationMappingReducer,
  getLevel1ByLevelReducerForCategory,
  getLevel2ByLevelForCategoryReducer,
  getLevel3ByLevelForCategoryReducer,
  getLevel4ByLevelForCategoryReducer,
  getAcnReducerForCategory,
  getCategoryItemsReducer,
};
