/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard components
import MDBox from "components/MDBox";
import MDCard from "components/MDCard";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons

// Functions from store
import {
  getSubModulesByRoleId,
  getMappedStationsByAssembly,
  getAssemblyMaster,
} from "../../store";

// React-Select component and styling

import "../../index.css";

// Cookies
import Config from "../../config/index";
import Cookies from "universal-cookie";
import ViewButton from "components/standardized-components/ViewButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const styleForStationModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, lg: 1200 },
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const AssemblyView = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getMappedStationsByAssembly,
  mappedStationsByAssemblyReducer,
  getAssemblyMaster,
  listOfAssembly,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  const desLength = Config.descriptionLength;

  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let assemblyViewModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "b0c01805-5fd3-4bdd-b542-5b704eb8e99f"
  );

  let viewAssembly = assemblyViewModule
    ? assemblyViewModule.actionId.includes(
        "a8363450-5728-4b7c-b959-b392be7f101a"
      )
    : null;
  let viewStations = assemblyViewModule
    ? assemblyViewModule.actionId.includes(
        "2e3367c4-6fdf-4631-9cd4-1e5ec9bae71c"
      )
    : null;

  const [assemblyId, setAssemblyId] = useState("");
  const [assemblyName, setAssemblyName] = useState("");

  useEffect(() => {
    getAssemblyMaster(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Assembly", accessor: "assembly" },
      { Header: "Assembly Type", accessor: "assemblyType" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Zone", accessor: "zone" },
      { Header: "Action", accessor: "action",cannotSearch:true,cannotSort: true, } ,
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempAssembly = [];
    let data = listOfAssembly.assembly.data ? listOfAssembly.assembly.data : [];
    setLoading(listOfAssembly.loading);
    data.map((assembly) => {
      const assemblyObj = {
        assembly: assembly.assembly,
        assemblyType: assembly.assemblyType,
        warehouse: assembly.warehouseCode,
        zone: assembly.zone,
        action: (
          <ViewButton
            tooltip="View Stations"
            variant="gradient"
            color="info"
            iconOnly
            type="button"
            onClick={() => {
              setAssemblyName(assembly?.assembly);
              setAssemblyId(assembly?.assemblyId);
              handleOpenStationModal(assembly);
              getMappedStationsByAssembly(assembly?.assemblyId, jwtToken);
            }}
          >
            <Icon> visibility_icon </Icon>
          </ViewButton>
        ),
      };
      tempAssembly.push(assemblyObj);
    });

    viewAssembly && setState({ ...state, rows: tempAssembly });
  }, [listOfAssembly]);

  const [stationTable, setStationTable] = useState({
    columns: [
      { Header: "Station", accessor: "station" },
      { Header: "Section", accessor: "section" },
      { Header: "Item", accessor: "itemCode" },
      { Header: "Rack", accessor: "rack" },
      { Header: "Location", accessor: "location" },
      { Header: "Available Qty", accessor: "availableQty" },
    ],
    rows: [],
  });

  const [loading2, setLoading2] = useState(true);

  const { columns: stationColumns, rows: stationRows } = stationTable;

  useEffect(() => {
    let tempStations = [];
    let data = mappedStationsByAssemblyReducer.stationsByAssembly.data
      ? mappedStationsByAssemblyReducer.stationsByAssembly.data
      : [];
    setLoading2(mappedStationsByAssemblyReducer.loading);
    data.map((station) => {
      let des = station?.itemDescription;
      let len = desLength;
      const stationObj = {
        station: station.station,
        itemCode: station.itemCode
          ? `${station.itemCode} / ${des ? des.substring(0, len) : ""}`
          : "",
        section: station.sectionName,
        rack: station.rackName,
        location: station.locationName,
        availableQty: station.availableQty,
      };
      tempStations.push(stationObj);
    });

    viewStations && setStationTable({ ...stationTable, rows: tempStations });
  }, [mappedStationsByAssemblyReducer]);

  const [stationModal, setOpenStationModal] = useState(false);

  const handleOpenStationModal = () => {
    setOpenStationModal(true);
  };
  const handleCloseStationModal = () => {
    setOpenStationModal(false);
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox mt={0}>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>

                <Modal
                  open={stationModal}
                  onClose={handleCloseStationModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForStationModal}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        {assemblyName} Stations
                      </MDTypography>

                      <MDBox display="flex" marginLeft="auto">
                        <span>
                          <Tooltip title="Close">
                            <IconButton
                              style={{
                                display: "flex",
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                marginLeft: "auto",
                                marginBottom: "5px",
                                marginRight: "10px",
                              }}
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseStationModal}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </span>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginBottom: "1rem" }} />
                    <MDBox>
                      {!loading2 ? (
                        <DataTable
                          table={{ columns: stationColumns, rows: stationRows }}
                          isSorted={true}
                          tableSearch={true}
                          entriesPerPage={{
                            defaultValue: 5,
                            entries: [5, 10, 15, 20, 25, 30],
                          }}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "100px",
                            marginBottom: "100px",
                          }}
                        >
                          <CircularProgress color="info" />
                        </div>
                      )}
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    mappedStationsByAssemblyReducer: state.mappedStationsByAssemblyReducer,
    listOfAssembly: state.getAssemblyMasterReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getMappedStationsByAssembly: (id, jwtToken) =>
      dispatch(getMappedStationsByAssembly(id, jwtToken)),
    getAssemblyMaster: (jwtToken) => dispatch(getAssemblyMaster(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyView);
