/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

// Functions from store
import { getYard, getWarehouses } from "../../store";
import MixedBarChart from "examples/Charts/BarCharts/MixedBarChart";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

const YardOccupancyHomePageCard = ({
  getYard,
  yardOccupancy,
  getWarehouses,
  warehouses,
}) => {
  const [warehouseId, setWarehouseId] = useState("");

  useEffect(() => {
    getYard(warehouseId);
  }, [warehouseId]);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const [warehouseName, setWarehouseName] = useState("");

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    // setWarehouseId(data[0]?.id);
    data.map((warehouse) => {
      if (warehouse.type === "f") {
        tempWarehouseOptions.push({
          label: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseId(tempWarehouseOptions[0]?.value);
    setWarehouseName(tempWarehouseOptions[0]);
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [emptyLocations, setEmptyLocations] = useState("");
  const [occupiedLocations, setOccupiedLocations] = useState("");
  const [itemQty, setItemQty] = useState("");

  useEffect(() => {
    setEmptyLocations(yardOccupancy.warehouses[0]?.emptyLocations);
    setOccupiedLocations(yardOccupancy.warehouses[0]?.occupiedLocations);
    setItemQty(yardOccupancy.warehouses[0]?.occupiedQty);
  }, [yardOccupancy]);

  const chartData = {
    labels: ["Empty", "Occupied"],
    datasets: {
      //   label: "FG",
      items: itemQty,
      data: [emptyLocations, occupiedLocations],
      color: ["green", "red"],
      extras: "dropdown",
    },
  };

  const changeWarehouse = (e) => {
    setWarehouseId(e.value);
    setWarehouseName(e);
  };

  return (
    // <ReportsBarChart color="success" title="Yard Occupancy" chart={chartData} />
    <ReportsBarChart
      color="light"
      title="Yard Occupancy"
      chart={chartData}
      dropdown={
        <Select
          className="select-homepage"
          name="warehouseId"
          value={warehouseName}
          onChange={changeWarehouse}
          options={warehouseOptions}
        />
      }
    />
    // <VerticalBarChart
    //   height={170}
    //   //   icon={{ color: "success", component: "leaderboard" }}
    //   title="Yard Occupancy"
    //   description="Dropdown here"
    //   chart={chartData}
    // />
  );
};

const mapStateToProps = (state) => {
  return {
    yardOccupancy: state.getYardOccupancyHomePage,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getYard: (id) => dispatch(getYard(id)),
    getWarehouses: () => dispatch(getWarehouses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YardOccupancyHomePageCard);
