import React, { useRef, useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 575,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

function IdleTimerContainer() {
  const navigate = useNavigate();
  const idleTimerRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onIdle = () => {
    setModalIsOpen(true);
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
    ref: idleTimerRef,
  });

  const logoutHandler = () => {
    navigate("/authentication/logout");
  };

  useEffect(() => {
    if (modalIsOpen) {
      window.addEventListener("beforeunload", logoutHandler);

      return () => {
        window.removeEventListener("beforeunload", logoutHandler);
      };
    }
  }, [modalIsOpen]);

  return (
    <div>
      <Modal
        open={modalIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDCard sx={modalStyle}>
          <MDTypography>
            Your session has been logged out due to inactivity. You'll be
            redirected to the login screen.
          </MDTypography>
          <MDBox mt={2} display="flex">
            <div style={{ float: "right" }}>
              <MDButton
                style={{ width: "100%", marginLeft: "0.5rem" }}
                color="error"
                onClick={logoutHandler}
              >
                Okay
              </MDButton>
            </div>
          </MDBox>
        </MDCard>
      </Modal>
    </div>
  );
}

export default IdleTimerContainer;
