import {
  GET_STATIONS_BY_ASSEMBLY_REQUEST,
  GET_STATIONS_BY_ASSEMBLY_SUCCESS,
  GET_STATIONS_BY_ASSEMBLY_FAILURE,
  GET_ITEMS_BY_STATION_REQUEST,
  GET_ITEMS_BY_STATION_SUCCESS,
  GET_ITEMS_BY_STATION_FAILURE,
} from "./stationAndItemTypes";

const initialState = {
  loading: false,
  stationsByAssemblyType: [],
  error: "",
};

const getStationsByAssemblyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIONS_BY_ASSEMBLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STATIONS_BY_ASSEMBLY_SUCCESS:
      return {
        loading: false,
        stationsByAssemblyType: action.payload,
        error: "",
      };
    case GET_STATIONS_BY_ASSEMBLY_FAILURE:
      return {
        loading: false,
        stationsByAssemblyType: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForItemsByStation = {
  loading: false,
  itemsByStation: [],
  error: "",
};

const getItemsByStationReducer = (
  state = initialStateForItemsByStation,
  action
) => {
  switch (action.type) {
    case GET_ITEMS_BY_STATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMS_BY_STATION_SUCCESS:
      return {
        loading: false,
        itemsByStation: action.payload,
        error: "",
      };
    case GET_ITEMS_BY_STATION_FAILURE:
      return {
        loading: false,
        itemsByStation: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export { getStationsByAssemblyTypeReducer, getItemsByStationReducer };
