const {
  GET_DIRECT_DEMAND_REQUEST,
  GET_DIRECT_DEMAND_SUCCESS,
  GET_DIRECT_DEMAND_FAILURE,
  CREATE_DIRECT_DEMAND_REQUEST,
  CREATE_DIRECT_DEMAND_SUCCESS,
  CREATE_DIRECT_DEMAND_FAILURE,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  GET_PARTNO_FOR_PLANNER_REQUEST,
  GET_PARTNO_FOR_PLANNER_SUCCESS,
  GET_PARTNO_FOR_PLANNER_FAILURE,
  GET_PARTNO_REQUEST,
  GET_PARTNO_SUCCESS,
  GET_PARTNO_FAILURE,
  GET_PLANT_REQUEST,
  GET_PLANT_SUCCESS,
  GET_PLANT_FAILURE,
  GET_PACKAGE_TYPE_REQUEST,
  GET_PACKAGE_TYPE_SUCCESS,
  GET_PACKAGE_TYPE_FAILURE,
  POST_CALCULATE_PACKAGE_DETAILS_REQUEST,
  POST_CALCULATE_PACKAGE_DETAILS_SUCCESS,
  POST_CALCULATE_PACKAGE_DETAILS_FAILURE,
  GET_DELIVERY_PLANT_REQUEST,
  GET_DELIVERY_PLANT_SUCCESS,
  GET_DELIVERY_PLANT_FAILURE,
  GET_UNLOADING_POINT_REQUEST,
  GET_UNLOADING_POINT_SUCCESS,
  GET_UNLOADING_POINT_FAILURE,
  GET_CALCULATE_PACKAGE_WEIGHT_REQUEST,
  GET_CALCULATE_PACKAGE_WEIGHT_SUCCESS,
  GET_CALCULATE_PACKAGE_WEIGHT_FAILURE,
  GET_BINS_FOR_PRINT_REQUEST,
  GET_BINS_FOR_PRINT_SUCCESS,
  GET_BINS_FOR_PRINT_FAILURE,
} = require("./directDemandTypes");

const initialStateForGetDirectDemand = {
  loading: false,
  demand: [],
  error: "",
};

const getDirectDemandReducer = (
  state = initialStateForGetDirectDemand,
  action
) => {
  switch (action.type) {
    case GET_DIRECT_DEMAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DIRECT_DEMAND_SUCCESS:
      return {
        loading: false,
        demand: action.payload,
        error: "",
      };
    case GET_DIRECT_DEMAND_FAILURE:
      return {
        loading: false,
        demand: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  createDemand: {},
  error: "",
};

const createDirectDemandReducer = (state = initialStateForCreate, action) => {
  switch (action.type) {
    case CREATE_DIRECT_DEMAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DIRECT_DEMAND_SUCCESS:
      return {
        loading: false,
        createDemand: action.payload,
        error: "",
      };
    case CREATE_DIRECT_DEMAND_FAILURE:
      return {
        loading: false,
        createDemand: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPartNo = {
  loading: false,
  partNoData: {},
  error: "",
};

const partNoReducer = (state = initialStateForPartNo, action) => {
  switch (action.type) {
    case GET_PARTNO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PARTNO_SUCCESS:
      return {
        loading: false,
        partNoData: action.payload,
        error: "",
      };
    case GET_PARTNO_FAILURE:
      return {
        loading: false,
        partNoData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPlant = {
  loading: false,
  plantData: {},
  error: "",
};

const plantReducer = (state = initialStateForPlant, action) => {
  switch (action.type) {
    case GET_PLANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PLANT_SUCCESS:
      return {
        loading: false,
        plantData: action.payload,
        error: "",
      };
    case GET_PLANT_FAILURE:
      return {
        loading: false,
        plantData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPackageType = {
  loading: false,
  packageTypeData: {},
  error: "",
};

const packageTypeReducer = (state = initialStateForPackageType, action) => {
  switch (action.type) {
    case GET_PACKAGE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PACKAGE_TYPE_SUCCESS:
      return {
        loading: false,
        packageTypeData: action.payload,
        error: "",
      };
    case GET_PACKAGE_TYPE_FAILURE:
      return {
        loading: false,
        packageTypeData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUnloadingPoint = {
  loading: false,
  unloadingPointData: {},
  error: "",
};

const unloadingPointReducer = (
  state = initialStateForUnloadingPoint,
  action
) => {
  switch (action.type) {
    case GET_UNLOADING_POINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_UNLOADING_POINT_SUCCESS:
      return {
        loading: false,
        unloadingPointData: action.payload,
        error: "",
      };
    case GET_UNLOADING_POINT_FAILURE:
      return {
        loading: false,
        unloadingPointData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDeliveryPlant = {
  loading: false,
  deliveryPlantData: {},
  error: "",
};

const deliveryPlantReducer = (state = initialStateForDeliveryPlant, action) => {
  switch (action.type) {
    case GET_DELIVERY_PLANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_PLANT_SUCCESS:
      return {
        loading: false,
        deliveryPlantData: action.payload,
        error: "",
      };
    case GET_DELIVERY_PLANT_FAILURE:
      return {
        loading: false,
        deliveryPlantData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPacketWeight = {
  loading: false,
  packetWeightData: {},
  error: "",
};

const packetWeightReducer = (state = initialStateForPacketWeight, action) => {
  switch (action.type) {
    case GET_CALCULATE_PACKAGE_WEIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CALCULATE_PACKAGE_WEIGHT_SUCCESS:
      return {
        loading: false,
        packetWeightData: action.payload,
        error: "",
      };
    case GET_CALCULATE_PACKAGE_WEIGHT_FAILURE:
      return {
        loading: false,
        packetWeightData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPackageDetails = {
  loading: false,
  packageDetailsData: {},
  error: "",
};

const packageDetailsReducer = (
  state = initialStateForPackageDetails,
  action
) => {
  switch (action.type) {
    case POST_CALCULATE_PACKAGE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CALCULATE_PACKAGE_DETAILS_SUCCESS:
      return {
        loading: false,
        packageDetailsData: action.payload,
        error: "",
      };
    case POST_CALCULATE_PACKAGE_DETAILS_FAILURE:
      return {
        loading: false,
        packageDetailsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForBinsForPrint = {
  loading: false,
  binsForPrintData: {},
  error: "",
};

const binsForPrintReducer = (state = initialStateForBinsForPrint, action) => {
  switch (action.type) {
    case GET_BINS_FOR_PRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BINS_FOR_PRINT_SUCCESS:
      return {
        loading: false,
        binsForPrintData: action.payload,
        error: "",
      };
    case GET_BINS_FOR_PRINT_FAILURE:
      return {
        loading: false,
        binsForPrintData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPartNoForPlanner = {
  loading: false,
  partNoForPlannerData: {},
  error: "",
};

const partNoForPlannerReducer = (
  state = initialStateForPartNoForPlanner,
  action
) => {
  switch (action.type) {
    case GET_PARTNO_FOR_PLANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PARTNO_FOR_PLANNER_SUCCESS:
      return {
        loading: false,
        partNoForPlannerData: action.payload,
        error: "",
      };
    case GET_PARTNO_FOR_PLANNER_FAILURE:
      return {
        loading: false,
        partNoForPlannerData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVendorsListForPlanner = {
  loading: false,
  vendorsListForPlannerData: {},
  error: "",
};

const vendorsListForPlannerReducer = (
  state = initialStateForVendorsListForPlanner,
  action
) => {
  switch (action.type) {
    case GET_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VENDORS_SUCCESS:
      return {
        loading: false,
        vendorsListForPlannerData: action.payload,
        error: "",
      };
    case GET_VENDORS_FAILURE:
      return {
        loading: false,
        vendorsListForPlannerData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getDirectDemandReducer,
  createDirectDemandReducer,
  partNoReducer,
  plantReducer,
  packageTypeReducer,
  packageDetailsReducer,
  deliveryPlantReducer,
  unloadingPointReducer,
  packetWeightReducer,
  binsForPrintReducer,
  partNoForPlannerReducer,
  vendorsListForPlannerReducer,
};
