/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

import { getSubModulesByRoleId } from "../../store";

import Cookies from "universal-cookie";

import RejectedTransaction from "./rejected-transaction";
import Transaction from "./transaction";
import CompletedTransaction from "./completed-transaction";

const cookies = new Cookies();

const TransactionParent = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let transactionModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "d267d04a-879e-409b-92f2-4f0c4941b742"
  );

  let rejectedTransactionModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ad4ef1a3-9a6f-489a-8bc1-4f20808d1ae1"
  );
  let completedTransactionModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "47a8970c-0653-4742-a44a-dca7182ce915"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {transactionModule &&
            completedTransactionModule &&
            rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Transaction">
                  <Transaction />
                </TabPanel>
                <TabPanel header="Completed Transaction">
                  <CompletedTransaction />
                </TabPanel>
                <TabPanel header="Rejected Transaction">
                  <RejectedTransaction />
                </TabPanel>
              </TabView>
            ) : !transactionModule &&
              completedTransactionModule &&
              rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Completed Transaction">
                  <CompletedTransaction />
                </TabPanel>
                <TabPanel header="Rejected Transaction">
                  <RejectedTransaction />
                </TabPanel>
              </TabView>
            ) : transactionModule &&
              !completedTransactionModule &&
              rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Transaction">
                  <Transaction />
                </TabPanel>
                <TabPanel header="Rejected Transaction">
                  <RejectedTransaction />
                </TabPanel>
              </TabView>
            ) : transactionModule &&
              completedTransactionModule &&
              !rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Transaction">
                  <Transaction />
                </TabPanel>
                <TabPanel header="Completed Transaction">
                  <CompletedTransaction />
                </TabPanel>
              </TabView>
            ) : transactionModule &&
              !completedTransactionModule &&
              !rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Transaction">
                  <Transaction />
                </TabPanel>
              </TabView>
            ) : !transactionModule &&
              !completedTransactionModule &&
              rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Rejected Transaction">
                  <RejectedTransaction />
                </TabPanel>
              </TabView>
            ) : completedTransactionModule &&
              !transactionModule &&
              !rejectedTransactionModule ? (
              <TabView>
                <TabPanel header="Completed Transaction">
                  <CompletedTransaction />
                </TabPanel>
              </TabView>
            ) : !completedTransactionModule &&
              !transactionModule &&
              !rejectedTransactionModule ? (
              <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                No Transaction Access
              </h4>
            ) : null}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(TransactionParent);
