import {
  CREATE_NEWASSET_FAILURE,
  CREATE_NEWASSET_REQUEST,
  CREATE_NEWASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  GET_ASSETMASTER_FAILURE,
  GET_ASSETMASTER_REQUEST,
  GET_ASSETMASTER_SUCCESS,
  GET_ASSETTYPES_FAILURE,
  GET_ASSETTYPES_REQUEST,
  GET_ASSETTYPES_SUCCESS,
  UPDATE_ASSET_FAILURE,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  MAP_ASSET_REQUEST,
  MAP_ASSET_SUCCESS,
  MAP_ASSET_FAILURE,
  GET_UNMAPPED_BINS_REQUEST,
  GET_UNMAPPED_BINS_SUCCESS,
  GET_UNMAPPED_BINS_FAILURE,
  GET_UNMAPPED_PALLETS_REQUEST,
  GET_UNMAPPED_PALLETS_SUCCESS,
  GET_UNMAPPED_PALLETS_FAILURE,
  UPLOAD_EXCEL_FOR_ASSETMASTER_REQUEST,
  UPLOAD_EXCEL_FOR_ASSETMASTER_SUCCESS,
  UPLOAD_EXCEL_FOR_ASSETMASTER_FAILURE,
  GET_MAPPEDBIN_RFID_REQUEST,
  GET_MAPPEDBIN_RFID_SUCCESS,
  GET_MAPPEDBIN_RFID_FAILURE,
} from "./assetMasterTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getAssetsRequest = () => {
  return {
    type: GET_ASSETMASTER_REQUEST,
  };
};

const getAssetsSuccess = (payload) => {
  return {
    type: GET_ASSETMASTER_SUCCESS,
    payload: payload,
  };
};

const getAssetsFailure = (error) => {
  return {
    type: GET_ASSETMASTER_FAILURE,
    payload: error,
  };
};

const getAssets = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getAssetsRequest());
    let url = `${baseUrl}/asset/get`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssetsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssetsFailure(err.response));
      });
  };
};
const getMappedBinsByRfidRequest = () => {
  return {
    type: GET_MAPPEDBIN_RFID_REQUEST,
  };
};

const getMappedBinsByRfidSuccess = (payload) => {
  return {
    type: GET_MAPPEDBIN_RFID_SUCCESS,
    payload: payload,
  };
};

const getMappedBinsByRfidFailure = (error) => {
  return {
    type: GET_MAPPEDBIN_RFID_FAILURE,
    payload: error,
  };
};

const getMappedBinsByRfid = (rfid,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getMappedBinsByRfidRequest());
    let url = `${baseUrl}/asset/get_mapping?rfid=${rfid}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMappedBinsByRfidSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getMappedBinsByRfidFailure(err.response));
      });
  };
};
const getUnmappedBinsRequest = () => {
  return {
    type: GET_UNMAPPED_BINS_REQUEST,
  };
};

const getUnmappedBinsSuccess = (payload) => {
  return {
    type: GET_UNMAPPED_BINS_SUCCESS,
    payload: payload,
  };
};

const getUnmappedBinsFailure = (error) => {
  return {
    type: GET_UNMAPPED_BINS_FAILURE,
    payload: error,
  };
};

const getUnmappedBins = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getUnmappedBinsRequest());
    let url = `${baseUrl}/rfid_mapping/get_bins`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnmappedBinsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnmappedBinsFailure(err.response));
      });
  };
};
const getUnmappedPalletsRequest = () => {
  return {
    type: GET_UNMAPPED_PALLETS_REQUEST,
  };
};

const getUnmappedPalletsSuccess = (payload) => {
  return {
    type: GET_UNMAPPED_PALLETS_SUCCESS,
    payload: payload,
  };
};

const getUnmappedPalletsFailure = (error) => {
  return {
    type: GET_UNMAPPED_PALLETS_FAILURE,
    payload: error,
  };
};

const getUnmappedPallets = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getUnmappedPalletsRequest());
    let url = `${baseUrl}/rfid_mapping/get_pallets`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnmappedPalletsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnmappedPalletsFailure(err.response));
      });
  };
};

const createNewAssetRequest = () => {
  return {
    type: CREATE_NEWASSET_REQUEST,
  };
};

const createNewAssetSuccess = (payload) => {
  return {
    type: CREATE_NEWASSET_SUCCESS,
    payload: payload,
  };
};

const createNewAssetFaliure = (error) => {
  return {
    type: CREATE_NEWASSET_FAILURE,
    payload: error,
  };
};

const createNewAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createNewAssetRequest());
    let url = `${baseUrl}/asset/create`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createNewAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createNewAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateAssetRequest = () => {
  return {
    type: UPDATE_ASSET_REQUEST,
  };
};

const updateAssetSuccess = (payload) => {
  return {
    type: UPDATE_ASSET_SUCCESS,
    payload: payload,
  };
};

const updateAssetFaliure = (error) => {
  return {
    type: UPDATE_ASSET_FAILURE,
    payload: error,
  };
};

const updateAsset = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateAssetRequest());
    let url = `${baseUrl}/asset/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteAssetRequest = () => {
  return {
    type: DELETE_ASSET_REQUEST,
  };
};

const deleteAssetSuccess = (payload) => {
  return {
    type: DELETE_ASSET_SUCCESS,
    payload: payload,
  };
};

const deleteAssetFaliure = (error) => {
  return {
    type: DELETE_ASSET_FAILURE,
    payload: error,
  };
};

const deleteAsset = (assetId, userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(deleteAssetRequest());
    let url = `${baseUrl}/asset/delete?assetId=${assetId}&userId=${userId}`;

    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getAssetTypesRequest = () => {
  return {
    type: GET_ASSETTYPES_REQUEST,
  };
};

const getAssetTypesSuccess = (payload) => {
  return {
    type: GET_ASSETTYPES_SUCCESS,
    payload: payload,
  };
};

const getAssetTypesFailure = (error) => {
  return {
    type: GET_ASSETTYPES_FAILURE,
    payload: error,
  };
};

const getAssetTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssetTypesRequest());
    let url = `${baseUrl}/asset_type/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssetTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssetTypesFailure(err.message));
      });
  };
};

const mapAssetRequest = () => {
  return {
    type: MAP_ASSET_REQUEST,
  };
};

const mapAssetSuccess = (payload) => {
  return {
    type: MAP_ASSET_SUCCESS,
    payload: payload,
  };
};

const mapAssetFaliure = (error) => {
  return {
    type: MAP_ASSET_FAILURE,
    payload: error,
  };
};

const mapAsset = (payload, type, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(mapAssetRequest());
    let url;
    if (type == "bin") {
      url = `${baseUrl}/rfid_mapping/map_with_bin`;
    }

    if (type == "pallet") {
      url = `${baseUrl}/rfid_mapping/map_with_pallet`;
    }
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(mapAssetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(mapAssetFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};


const uploadAssetMasterRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_ASSETMASTER_REQUEST,
  };
};

const uploadAssetMasterSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_ASSETMASTER_SUCCESS,
    payload: payload,
  };
};

const uploadAssetMasterFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_ASSETMASTER_FAILURE,
    payload: error,
  };
};

const uploadAssetMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadAssetMasterRequest());
    let url = `${baseUrl}/asset/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadAssetMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadAssetMasterFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};


export {
  getAssets,
  createNewAsset,
  updateAsset,
  deleteAsset,
  getAssetTypes,
  mapAsset,
  getUnmappedBins,
  getUnmappedPallets,
  uploadAssetMaster,
  getMappedBinsByRfid
};
