/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getRelationLocation,
  createRelationLocation,
  updateRelationLocation,
  getSubModulesByRoleId,
} from "../../store";

// Cookies
import Cookies from "universal-cookie";
import Select from "react-select";
import EditButton from "components/standardized-components/EditButton";
import { CircularProgress } from "@mui/material";
const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const RelationLocation = ({
  getRelationLocation,
  getRelationLocationReducer,
  createRelationLocation,
  updateRelationLocation,
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let relationLocation = modulesData.find(
    (moduleId) => moduleId.moduleId == "a704a09e-b7ad-40a8-97b9-b6146e2acfac"
  );

  let viewAccess = relationLocation
    ? relationLocation.actionId.includes("b5d22d92-391f-47c7-b640-3b54b8a9b270")
    : null;
  let editLocation = relationLocation
    ? relationLocation.actionId.includes("e83bdeb8-b467-4910-81dd-7b28896c5bc0")
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "rltName", align: "left" },
      { Header: "Level", accessor: "rltLevel", align: "left" },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  useEffect(() => {
    getRelationLocation(jwtToken);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempRelationLocation = [];
    let data = getRelationLocationReducer.locations.data
      ? getRelationLocationReducer.locations.data
      : [];
    setLoading(getRelationLocationReducer.loading);
    data.map((relationLocation) => {
      const relationLocationObject = {
        rltName: relationLocation.rltName,
        rltLevel: relationLocation.rltLevel,
        action: (
          <div>
            {editLocation && (
              <EditButton
                tooltip="Edit"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() =>
                  handleOpenUpdateRelationLocationModal(relationLocation)
                }
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
          </div>
        ),
      };
      tempRelationLocation.push(relationLocationObject);
    });
    viewAccess && setState({ ...state, rows: tempRelationLocation });
  }, [getRelationLocationReducer]);

  const [relationLocationSelected, setRelationLocationSelected] = useState("");

  const handleRelationLocationChange = (selectedRelationLocation) => {
    setRelationLocationSelected(selectedRelationLocation);
  };

  const relationLocationOptions = [
    {
      label: "Level 1",
      value: 1,
    },
    {
      label: "Level 2",
      value: 2,
    },
    {
      label: "Level 3",
      value: 3,
    },
  ];

  const [createLocationModal, setCreateLocationModal] = useState(false);
  const handleCreateLocationModal = () => {
    setCreateLocationModal(!createLocationModal);

    setRelationLocationSelected("");
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
  };

  const initialValues = {
    rltName: "",
    rltLevel: "",
  };
  const validationSchema = Yup.object({
    rltName: Yup.string().required("Select a name"),
    rltLevel: Yup.object().required("Select a level"),
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      rltName: values.rltName,
      rltLevel: relationLocationSelected.value,
    };

    let res = await createRelationLocation(payload, jwtToken);
    if (res.status) {
      getRelationLocation(jwtToken);
      setIsSuccess(true);
    }
    if (!res.status) {
      setErrorMsg(res.data.data.msg);
      setIsError(true);
    }

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [openUpdateRelationLocationModal, setOpenUpdateRelationLocationModal] =
    useState(false);
  const [
    singleRelationLocationDataForUpdate,
    setRelationLocationDataForUpdate,
  ] = useState({});
  const handleOpenUpdateRelationLocationModal = (RelationLocation = {}) => {
    setRelationLocationDataForUpdate(RelationLocation);
    setOpenUpdateRelationLocationModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateRelationLocationModal = () => {
    setOpenUpdateRelationLocationModal(false);
    formikForUpdate.setErrors({});
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    rltId: singleRelationLocationDataForUpdate.rltId,
    rltName: singleRelationLocationDataForUpdate.rltName,
  };

  const validationSchemaForUpdate = Yup.object({
    rltName: Yup.string().required("Enter the name!"),
  });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      id: singleRelationLocationDataForUpdate.rltId,
      rltName: values.rltName,
    };
    let res = await updateRelationLocation(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getRelationLocation(jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox
                  color="text"
                  px={{ xs: 0.5, sm: 0.5, md: 2, lg: 2, xl: 2 }}
                  style={{ marginLeft: "-15px" }}
                >
                  <Tooltip title="Create Relation Location">
                    <MDButton
                      disabled={!addLocation}
                      color="info"
                      variant="contained"
                      type="button"
                      iconOnly
                      onClick={(event) => handleCreateLocationModal()}
                    >
                      <Icon>add</Icon>
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </MDBox> */}
              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
              <Modal
                open={createLocationModal}
                onClose={handleCreateLocationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create Relation Location
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCreateLocationModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Name</MDTypography>

                        <MDInput
                          type="text"
                          name="rltName"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.rltName}
                          error={
                            formik.touched.rltName &&
                            formik.errors.rltName &&
                            true
                          }
                          inputProps={{
                            style: {
                              height: "10px",
                            },
                            maxLength: 45,
                          }}
                        />

                        {formik.touched.rltName && formik.errors.rltName && (
                          <TextError msg={formik.errors.rltName} />
                        )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="h6">Level</MDTypography>
                        <Select
                          isClearable
                          className="select-css"
                          maxMenuHeight={130}
                          placeholder="Choose one..."
                          name="rltLevel"
                          onBlur={() => {
                            formik.handleBlur({
                              target: { name: "rltLevel" },
                            });
                          }}
                          value={relationLocationSelected}
                          options={relationLocationOptions}
                          onChange={(selectedOption) => {
                            handleRelationLocationChange(selectedOption);
                            formik.setFieldValue("rltLevel", selectedOption);
                          }}
                        />
                        {formik.touched.rltLevel && formik.errors.rltLevel && (
                          <TextError msg={formik.errors.rltLevel} />
                        )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isError}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsError(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccess}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccess(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Location Created successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openUpdateRelationLocationModal}
                onClose={handleCloseUpdateRelationLocationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Update
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseUpdateRelationLocationModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForUpdate.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Name</MDTypography>
                        <MDInput
                          type="text"
                          name="rltName"
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForUpdate.handleBlur}
                          onChange={formikForUpdate.handleChange}
                          value={formikForUpdate.values.rltName}
                          error={
                            formikForUpdate.touched.rltName &&
                            formikForUpdate.errors.rltName &&
                            true
                          }
                          inputProps={{
                            maxLength: 45,
                          }}
                        />
                        {formikForUpdate.touched.rltName &&
                          formikForUpdate.errors.rltName && (
                            <TextError msg={formikForUpdate.errors.rltName} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorForUpdate}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Could not update the RelationLocation!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessForUpdate}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForUpdate(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Relation location updated successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formikForUpdate.isValid}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getRelationLocationReducer: state.getRelationLocationReducer,
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getRelationLocation: (jwtToken) => dispatch(getRelationLocation(jwtToken)),
    createRelationLocation: (payload, jwtToken) =>
      dispatch(createRelationLocation(payload, jwtToken)),
    updateRelationLocation: (payload, jwtToken) =>
      dispatch(updateRelationLocation(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationLocation);
