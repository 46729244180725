import { useField } from "formik";
import { TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import TextError from "utils/TextError";

const FormTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <MDTypography variant="h6" color="inherit">
        {label}{" "}
        {props.notRequired ? "" : <span style={{ color: "red" }}>*</span>}
      </MDTypography>
      <TextField
        className="contact"
        fullWidth
        {...field}
        {...props}
        error={meta.touched && Boolean(meta.error)}
      />
      {meta.touched && meta.error && <TextError msg={meta.error} />}
    </>
  );
};

export default FormTextField;
