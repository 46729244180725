export const GET_PICKLISTTRACKING_REQUEST = "GET_PICKLISTTRACKING_REQUEST";
export const GET_PICKLISTTRACKING_SUCCESS = "GET_PICKLISTTRACKING_SUCCESS";
export const GET_PICKLISTTRACKING_FAILURE = "GET_PICKLISTTRACKING_FAILURE";

export const GET_GROUPEDPICKLIST_REQUEST = "GET_GROUPEDPICKLIST_REQUEST";
export const GET_GROUPEDPICKLIST_SUCCESS = "GET_GROUPEDPICKLIST_SUCCESS";
export const GET_GROUPEDPICKLIST_FAILURE = "GET_GROUPEDPICKLIST_FAILURE";

export const GET_ITEMSBYPICKLIST_REQUEST = "GET_ITEMSBYPICKLIST_REQUEST";
export const GET_ITEMSBYPICKLIST_SUCCESS = "GET_ITEMSBYPICKLIST_SUCCESS";
export const GET_ITEMSBYPICKLIST_FAILURE = "GET_ITEMSBYPICKLIST_FAILURE";

export const CREATE_PICKLIST__REQUEST = "CREATE_PICKLIST__REQUEST";
export const CREATE_PICKLIST__SUCCESS = "CREATE_PICKLIST__SUCCESS";
export const CREATE_PICKLIST__FAILURE = "CREATE_PICKLIST__FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_ALLOCATE_USER_REQUEST = "UPDATE_ALLOCATE_USER_REQUEST";
export const UPDATE_ALLOCATE_USER_SUCCESS = "UPDATE_ALLOCATE_USER_SUCCESS";
export const UPDATE_ALLOCATE_USER_FAILURE = "UPDATE_ALLOCATE_USER_FAILURE";

export const GET_DOCUMENT_NUMBER_REQUEST = "GET_DOCUMENT_NUMBER_REQUEST";
export const GET_DOCUMENT_NUMBER_SUCCESS = "GET_DOCUMENT_NUMBER_SUCCESS";
export const GET_DOCUMENT_NUMBER_FAILURE = "GET_DOCUMENT_NUMBER_FAILURE";

export const GET_ITEM_BY_DOCUMENTNO_REQUEST = "GET_ITEM_BY_DOCUMENTNO_REQUEST";
export const GET_ITEM_BY_DOCUMENTNO_SUCCESS = "GET_ITEM_BY_DOCUMENTNO_SUCCESS";
export const GET_ITEM_BY_DOCUMENTNO_FAILURE = "GET_ITEM_BY_DOCUMENTNO_FAILURE";

export const GET_AVAILABLE_QTY_REQUEST = "GET_AVAILABLE_QTY_REQUEST";
export const GET_AVAILABLE_QTY_SUCCESS = "GET_AVAILABLE_QTY_SUCCESS";
export const GET_AVAILABLE_QTY_FAILURE = "GET_AVAILABLE_QTY_FAILURE";
