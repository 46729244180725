/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
// Formik and Yup

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import "react-datepicker/dist/react-datepicker.css";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Functions from store
import {
  getAcn,
  getAgeingParameter,
  getCreatedActivity,
  getDocumentType,
  getMovementByDocumentId,
  getPicklistAgeing,
  getSubModulesByRoleId,
} from "../../../store";

import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
import ReactDatePicker from "react-datepicker";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";
const cookies = new Cookies();

const PicklistAgeing = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  getCreatedActivity,
  getMovementByDocReducer,
  getAcnReducer,
  getDocumentType,
  getDocumentTypeReducer,
  getAgeingParameter,
  getPicklistAgeingReducer,
  getAgeingParameterReducer,
  getMovementByDocumentId,
  getAcn,
  getPicklistAgeing,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  useEffect(() => {
    getAcn(jwtToken);
  }, []);

  useEffect(() => {
    getDocumentType(jwtToken);
  }, []);

  useEffect(() => {
    getAgeingParameter(jwtToken);
  }, []);

  const [documentTypeSelected, setDocumentTypeSelected] = useState("");
  const [movementTypeSelected, setMovementTypeSelected] = useState("");
  const [parameterSelected, setparameterSelected] = useState("");

  useEffect(() => {
    getMovementByDocumentId(
      documentTypeSelected ? documentTypeSelected?.value : "",
      jwtToken
    );
  }, [documentTypeSelected]);

  useEffect(() => {
    getPicklistAgeing(
      documentTypeSelected ? documentTypeSelected.value : "",
      movementTypeSelected ? movementTypeSelected.value : "",
      parameterSelected ? parameterSelected.value : "",
      jwtToken
    );
  }, [documentTypeSelected, movementTypeSelected, parameterSelected]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let picklistAgeingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "ed6d6e31-3de3-49f9-a443-52854062de6b"
  );

  let viewAccess = picklistAgeingModule
    ? picklistAgeingModule.actionId.includes(
        "019a34cc-7f85-4259-bd17-c5a3d801b197"
      )
    : null;

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "Picklist Code", accessor: "picklistCode" },
      { Header: "Document code", accessor: "documentCode" },
      { Header: "Document Number", accessor: "documentNumber" },
      { Header: "Status", accessor: "status" },
      { Header: "Source", accessor: "source" },
      { Header: "Destination", accessor: "destination" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getPicklistAgeingReducer.picklistAgeing
      ? getPicklistAgeingReducer.picklistAgeing
      : [];
    setLoading(getPicklistAgeingReducer.loading);
    data?.map((finishedGoods) => {
      const finishedGoodsObject = {
        picklistCode: finishedGoods.picklistCode,
        documentCode: finishedGoods.documentCode,
        documentNumber: finishedGoods.documentNumber,
        source: finishedGoods.source,
        destination: finishedGoods.destination,
        status: (
          <button
            className={
              (finishedGoods.status === 0 && `status-created`) ||
              (finishedGoods.status === 1 && `status-warning`) ||
              (finishedGoods.status === 2 && `status-info`) ||
              (finishedGoods.status === 3 && `status-info`) ||
              (finishedGoods.status === 4 && `status-info`) ||
              (finishedGoods.status === 5 && `status-success`)
            }
            style={{ width: "5.5rem" }}
          >
            {(finishedGoods.status === 0 && `Created`) ||
              (finishedGoods.status === 1 && `Released`) ||
              (finishedGoods.status === 2 && `Picking`) ||
              (finishedGoods.status === 3 && `Picked`) ||
              (finishedGoods.status === 4 && `Putaway`) ||
              (finishedGoods.status === 5 && `Delivered`)}
          </button>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewAccess && setState({ ...state, rows: tempFinishedGoods });
  }, [getPicklistAgeingReducer]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // if (startDate || endDate) {
    const isoStartDate = new Date(
      (startDate ? startDate : new Date()).getTime() -
        (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    const isoEndDate = new Date(
      (endDate ? endDate : new Date()).getTime() -
        (endDate ? endDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    // getReports(itemIdSelected, status, isoStartDate, isoEndDate);

    // }
  }, []);

  const headers = [
    {
      label: "picklistCode",
      key: "picklistCode",
    },
    {
      label: "documentCode",
      key: "documentCode",
    },
    { label: "documentNumber", key: "documentNumber" },
    { label: "status", key: "status" },
    { label: "source", key: "source" },
    { label: "destination", key: "destination" },
  ];

  const csvLink = {
    filename: "Picklist Age-ing.csv",
    headers: headers,
    data: rows,
  };

  const handleMovementTypeChange = (selectedOption) => {
    setMovementTypeSelected(selectedOption);
    setparameterSelected("");
  };

  const [createdActivityOptions, setCreatedActivityOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getMovementByDocReducer.documents
      ? getMovementByDocReducer.documents
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.mvtCode,
        value: activity.mvtId,
      });
    });
    setCreatedActivityOptions(tempCreatedActivityOptions);
  }, [getMovementByDocReducer]);

  const handleParameterChange = (selectedOption) => {
    setparameterSelected(selectedOption);
  };

  const [parameterOptions, setParameterOptions] = useState([]);

  useEffect(() => {
    let tempCreatedActivityOptions = [];
    let data = getAgeingParameterReducer.ageingParameter
      ? getAgeingParameterReducer.ageingParameter
      : [];
    data.map((activity) => {
      tempCreatedActivityOptions.push({
        label: activity.ageingValue,
        value: activity.ageingValue,
      });
    });
    setParameterOptions(tempCreatedActivityOptions);
  }, [getAgeingParameterReducer]);

  const handleDocumentTypeChange = (selectedOption) => {
    setDocumentTypeSelected(selectedOption);
    setMovementTypeSelected("");
    setparameterSelected("");
  };

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    let tempDocumentTypeOptions = [];
    let data = getDocumentTypeReducer.documentType.data
      ? getDocumentTypeReducer.documentType.data
      : [];
    data.map((document) => {
      tempDocumentTypeOptions.push({
        label: document.documentName,
        value: document.documentId,
      });
    });
    setDocumentTypeOptions(tempDocumentTypeOptions);
  }, [getDocumentTypeReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-4}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <MDBox>
                      <MDBox
                        display="flex"
                        mt={2}
                        flexDirection={{ xs: "column", lg: "row" }}
                      >
                        <MDBox
                          width={{ xs: "150px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Document Type
                          </MDTypography>
                          <Select
                            isClearable
                            placeholder="Choose One..."
                            className="select-css-for-filter"
                            maxMenuHeight={130}
                            autoComplete="off"
                            value={documentTypeSelected}
                            options={documentTypeOptions}
                            onChange={handleDocumentTypeChange}
                          />
                        </MDBox>

                        <MDBox
                          width={{ xs: "150px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: 500 }}
                          >
                            Movement Type
                          </MDTypography>
                          <Select
                            isClearable
                            placeholder="Choose One..."
                            className="select-css-for-filter"
                            maxMenuHeight={130}
                            autoComplete="off"
                            value={movementTypeSelected}
                            options={createdActivityOptions}
                            onChange={handleMovementTypeChange}
                          />
                        </MDBox>

                        <MDBox
                          display="flex"
                          alignItems="center"
                          width={{ xs: "150px", lg: "200px" }}
                          style={{ marginRight: "10px" }}
                        >
                          <MDBox flex="1">
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Ageing Parameter
                            </MDTypography>
                            <Select
                              isClearable
                              placeholder="Choose One..."
                              className="select-css-for-filter"
                              maxMenuHeight={130}
                              autoComplete="off"
                              value={parameterSelected}
                              options={parameterOptions}
                              onChange={handleParameterChange}
                            />
                          </MDBox>
                        </MDBox>
                        <MDBox mt={3} ml={1}>
                          <CSVLink {...csvLink}>
                            <DownloadDataButton
                              type="button"
                              variant="outlined"
                              color="success"
                              iconOnly
                              tooltip="Download All Data"
                            >
                              <Icon>download</Icon>
                            </DownloadDataButton>
                          </CSVLink>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    hideColumns={["", "asnCode", "vendorCodeName"]}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getMovementByDocReducer: state.getMovementByDocReducer,
    getAcnReducer: state.getAcnReducer,
    getDocumentTypeReducer: state.getDocumentTypeReducer,
    getPicklistAgeingReducer: state.getPicklistAgeingReducer,
    getAgeingParameterReducer: state.getAgeingParameterReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getCreatedActivity: (documentType, jwtToken) =>
      dispatch(getCreatedActivity(documentType, jwtToken)),
    getMovementByDocumentId: (documentTypeSelected, jwtToken) =>
      dispatch(getMovementByDocumentId(documentTypeSelected, jwtToken)),
    getAcn: (jwtToken) => dispatch(getAcn(jwtToken)),
    getDocumentType: (jwtToken) => dispatch(getDocumentType(jwtToken)),
    getAgeingParameter: (jwtToken) => dispatch(getAgeingParameter(jwtToken)),
    getPicklistAgeing: (
      documentTypeSelected,
      movementTypeSelected,
      parameterSelected,
      jwtToken
    ) =>
      dispatch(
        getPicklistAgeing(
          documentTypeSelected,
          movementTypeSelected,
          parameterSelected,
          jwtToken
        )
      ),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PicklistAgeing);
