// Dashboard components
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

const ViewButton = ({ onClick, disabled, tooltip, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <MDButton
        onClick={onClick}
        disabled={disabled}
        variant="gradient"
        iconOnly
        type="button"
        {...props}
        color={props?.style?.backgroundColor ? null : "info"}
      >
        <Icon>visibility_icon </Icon>
      </MDButton>
    </Tooltip>
  );
};

export default ViewButton;
