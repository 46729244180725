import {
  GET_VENDOR_MASTER_MAPPING_REQUEST,
  GET_VENDOR_MASTER_MAPPING_SUCCESS,
  GET_VENDOR_MASTER_MAPPING_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILURE,
  UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_REQUEST,
  UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_SUCCESS,
  UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_FAILURE,
  GET_LIST_OF_BIN_TYPES_REQUEST,
  GET_LIST_OF_BIN_TYPES_SUCCESS,
  GET_LIST_OF_BIN_TYPES_FAILURE,
  UPDATE_VENDOR_MATERIAL_MAPPING_REQUEST,
  UPDATE_VENDOR_MATERIAL_MAPPING_SUCCESS,
  UPDATE_VENDOR_MATERIAL_MAPPING_FAILURE,
} from "./supplierPortalMastersTypes";
import Config from "../../config/index";

import axios from "axios";

const URL = Config.baseUrl;

let baseUrl = Config.baseUrl;

const getVendorMasterMappingRequest = () => {
  return {
    type: GET_VENDOR_MASTER_MAPPING_REQUEST,
  };
};

const getVendorMasterMappingSuccess = (payload) => {
  return {
    type: GET_VENDOR_MASTER_MAPPING_SUCCESS,
    payload: payload,
  };
};

const getVendorMasterMappingFailure = (error) => {
  return {
    type: GET_VENDOR_MASTER_MAPPING_FAILURE,
    payload: error,
  };
};

const getVendorMasterMapping = (partCode, vendorCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getVendorMasterMappingRequest());

    let url = `${baseUrl}/vendor_part_mapping/get?itemCode=${partCode}&vendorCode=${vendorCode}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getVendorMasterMappingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getVendorMasterMappingFailure(err.message));
      });
  };
};

const updateEmailRequest = () => {
  return {
    type: UPDATE_EMAIL_REQUEST,
  };
};

const updateEmailSuccess = (payload) => {
  return {
    type: UPDATE_EMAIL_SUCCESS,
    payload: payload,
  };
};

const updateEmailFaliure = (error) => {
  return {
    type: UPDATE_EMAIL_FAILURE,
    payload: error,
  };
};

const updateEmail = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateEmailRequest());
    let url = `${baseUrl}/vendor/add_email`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateEmailSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateEmailFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const createVendorRequest = () => {
  return {
    type: CREATE_VENDOR_REQUEST,
  };
};

const createVendorSuccess = (payload) => {
  return {
    type: CREATE_VENDOR_SUCCESS,
    payload: payload,
  };
};

const createVendorFaliure = (error) => {
  return {
    type: CREATE_VENDOR_FAILURE,
    payload: error,
  };
};

const createVendor = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createVendorRequest());
    let url = `${baseUrl}/vendor/create_user`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createVendorSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createVendorFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const uploadCsvForVendorMaterialMappingMasterRequest = () => {
  return {
    type: UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_REQUEST,
  };
};

const uploadCsvForVendorMaterialMappingMasterSuccess = (payload) => {
  return {
    type: UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_SUCCESS,
    payload: payload,
  };
};

const uploadCsvForVendorMaterialMappingMasterFailure = (error) => {
  return {
    type: UPLOADCSV_FOR_VENDOR_MASTER_MAPPING_FAILURE,
    payload: error,
  };
};

const uploadCsvForVendorMaterialMappingMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvForVendorMaterialMappingMasterRequest());
    let url = `${baseUrl}/vendor_part_mapping/upload_part_mappings`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvForVendorMaterialMappingMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvForVendorMaterialMappingMasterFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getListOfBinTypesRequest = () => {
  return {
    type: GET_LIST_OF_BIN_TYPES_REQUEST,
  };
};

const getListOfBinTypesSuccess = (payload) => {
  return {
    type: GET_LIST_OF_BIN_TYPES_SUCCESS,
    payload: payload,
  };
};

const getListOfBinTypesFailure = (error) => {
  return {
    type: GET_LIST_OF_BIN_TYPES_FAILURE,
    payload: error,
  };
};

const getListOfBinTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getListOfBinTypesRequest());

    let url = `${baseUrl}/bin/get_bin_type`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getListOfBinTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getListOfBinTypesFailure(err.message));
      });
  };
};

const updateVendorMaterialMappingRequest = () => {
  return {
    type: UPDATE_VENDOR_MATERIAL_MAPPING_REQUEST,
  };
};

const updateVendorMaterialMappingSuccess = (payload) => {
  return {
    type: UPDATE_VENDOR_MATERIAL_MAPPING_SUCCESS,
    payload: payload,
  };
};

const updateVendorMaterialMappingFaliure = (error) => {
  return {
    type: UPDATE_VENDOR_MATERIAL_MAPPING_FAILURE,
    payload: error,
  };
};

const updateVendorMaterialMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateVendorMaterialMappingRequest());
    let url = `${baseUrl}/vendor_part_mapping/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateVendorMaterialMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateVendorMaterialMappingFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  getVendorMasterMapping,
  updateEmail,
  createVendor,
  uploadCsvForVendorMaterialMappingMaster,
  getListOfBinTypes,
  updateVendorMaterialMapping,
};
