import {
  GET_WMS_STOCK_REQUEST,
  GET_WMS_STOCK_SUCCESS,
  GET_WMS_STOCK_FAILURE,
  DOWNLOAD_WMS_STOCK_REQUEST,
  DOWNLOAD_WMS_STOCK_SUCCESS,
  DOWNLOAD_WMS_STOCK_FAILURE,
} from "./wmsStockTypes";

const initialStateForWmsStock = {
  loading: false,
  warehouseOccupancy: [],
  error: "",
};

const getWmsStockReducer = (state = initialStateForWmsStock, action) => {
  switch (action.type) {
    case GET_WMS_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WMS_STOCK_SUCCESS:
      return {
        loading: false,
        warehouseOccupancy: action.payload,
        error: "",
      };
    case GET_WMS_STOCK_FAILURE:
      return {
        loading: false,
        warehouseOccupancy: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDownload = {
  loading: false,
  warehouseOccupancy: [],
  error: "",
};

const downloadWmsStockReducer = (state = initialStateForDownload, action) => {
  switch (action.type) {
    case DOWNLOAD_WMS_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_WMS_STOCK_SUCCESS:
      return {
        loading: false,
        warehouseOccupancy: action.payload,
        error: "",
      };
    case DOWNLOAD_WMS_STOCK_FAILURE:
      return {
        loading: false,
        warehouseOccupancy: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getWmsStockReducer, downloadWmsStockReducer };
