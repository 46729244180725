/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";
import PasswordStrengthMeter from "utils/PasswordStrengthMeter";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import { resetPassword } from "../../../store";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

function ResetPassword({ resetPassword, resetPasswordData }) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  let userDetails = cookies.get("loginDetailsForWMS").data;

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  const initialValues = {
    email: userDetails.email,
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    clientId: userDetails.clientId,
  };

  const validationSchema = Yup.object({
    oldpassword: Yup.string().required("Enter the old password!"),
    newpassword: Yup.string()
      .required("Enter the new password!")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase"
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), ""], "Passwords must match")
      .required("Enter the new password again!"),
  });

  const PasswordResetNotification = () =>
    toast.success("Password Changed Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const onSubmit = async (values, { resetForm }) => {
    let res = await resetPassword(values);

    if (!res.status) {
      setIsError(true);
    }
    if (res.status) {
      setTimeout(() => {
        navigate("/authentication/logout");
      }, 3000);
      PasswordResetNotification();
    }
    if (res.status) {
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: "40%", marginTop: "10%" }}>
          <MDBox ml={3} mt={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Reset Password
            </MDTypography>
          </MDBox>
          <Divider />
          <MDBox pt={1} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="oldpassword"
                  label="Old Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldpassword}
                  error={
                    formik.touched.oldpassword &&
                    formik.errors.oldpassword &&
                    true
                  }
                />
                {formik.touched.oldpassword && formik.errors.oldpassword && (
                  <TextError msg={formik.errors.oldpassword} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="newpassword"
                  label="New Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newpassword}
                  error={
                    formik.touched.newpassword &&
                    formik.errors.newpassword &&
                    true
                  }
                />
                <PasswordStrengthMeter password={formik.values.newpassword} />
                {formik.touched.newpassword && formik.errors.newpassword && (
                  <TextError msg={formik.errors.newpassword} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="confirmpassword"
                  label="Confirm Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmpassword}
                  error={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword &&
                    true
                  }
                />
                {formik.touched.confirmpassword &&
                  formik.errors.confirmpassword && (
                    <TextError msg={formik.errors.confirmpassword} />
                  )}
              </MDBox>
              <Collapse in={isError}>
                <Alert
                  //   style={errorStyling}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Invalid Old Password
                </Alert>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  style={{ backgroundColor: "red", float: "left" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  reset
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </div>
      <ToastContainer />
      <br />
      <br />
      <br />
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    resetPasswordData: state.resetPassword,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    resetPassword: (payload) => dispatch(resetPassword(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ResetPassword);
