import {
  GET_MAPPED_ACTIVITY_REQUEST,
  GET_MAPPED_ACTIVITY_SUCCESS,
  GET_MAPPED_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_MAPPING_REQUEST,
  CREATE_ACTIVITY_MAPPING_SUCCESS,
  CREATE_ACTIVITY_MAPPING_FAILURE,
  GET_LEVEL1_BY_LEVEL_REQUEST,
  GET_LEVEL1_BY_LEVEL_SUCCESS,
  GET_LEVEL1_BY_LEVEL_FAILURE,
  GET_LEVEL2_BY_LEVEL_REQUEST,
  GET_LEVEL2_BY_LEVEL_SUCCESS,
  GET_LEVEL2_BY_LEVEL_FAILURE,
  GET_LEVEL3_BY_LEVEL_REQUEST,
  GET_LEVEL3_BY_LEVEL_SUCCESS,
  GET_LEVEL3_BY_LEVEL_FAILURE,
  GET_LEVEL4_BY_LEVEL_REQUEST,
  GET_LEVEL4_BY_LEVEL_SUCCESS,
  GET_LEVEL4_BY_LEVEL_FAILURE,
  GET_ACN_REQUEST,
  GET_ACN_SUCCESS,
  GET_ACN_FAILURE,
  UPLOADCSV_ITEM_REQUEST,
  UPLOADCSV_ITEM_SUCCESS,
  UPLOADCSV_ITEM_FAILURE,
  GET_LEVEL1_BY_LEVELSRC_REQUEST,
  GET_LEVEL1_BY_LEVELSRC_SUCCESS,
  GET_LEVEL1_BY_LEVELSRC_FAILURE,
  GET_LEVEL2_BY_LEVELSRC_REQUEST,
  GET_LEVEL2_BY_LEVELSRC_SUCCESS,
  GET_LEVEL2_BY_LEVELSRC_FAILURE,
  GET_LEVEL3_BY_LEVELSRC_REQUEST,
  GET_LEVEL3_BY_LEVELSRC_SUCCESS,
  GET_LEVEL3_BY_LEVELSRC_FAILURE,
  GET_LEVEL4_BY_LEVELSRC_REQUEST,
  GET_LEVEL4_BY_LEVELSRC_SUCCESS,
  GET_LEVEL4_BY_LEVELSRC_FAILURE,
  DELETE_ITEMS_REQUEST,
  DELETE_ITEMS_SUCCESS,
  DELETE_ITEMS_FAILURE,
  EDIT_ITEMS_REQUEST,
  EDIT_ITEMS_SUCCESS,
  EDIT_ITEMS_FAILURE,
  CREATE_UNPLANNED_REQUEST,
  CREATE_UNPLANNED_SUCCESS,
  CREATE_UNPLANNED_FAILURE,
} from "./itemLocationMappingTypes";
import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getMappedActivityRequest = () => {
  return {
    type: GET_MAPPED_ACTIVITY_REQUEST,
  };
};

const getMappedActivitySuccess = (payload) => {
  return {
    type: GET_MAPPED_ACTIVITY_SUCCESS,
    payload: payload,
  };
};

const getMappedActivityFailure = (error) => {
  return {
    type: GET_MAPPED_ACTIVITY_FAILURE,
    payload: error,
  };
};

const getMappedActivity = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMappedActivityRequest());
    let url = `${baseUrl}/mvtmap`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMappedActivitySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getMappedActivityFailure(err.message));
      });
  };
};

const createActivityMappingRequest = () => {
  return {
    type: CREATE_ACTIVITY_MAPPING_REQUEST,
  };
};

const createActivityMappingSuccess = (payload) => {
  return {
    type: CREATE_ACTIVITY_MAPPING_SUCCESS,
    payload: payload,
  };
};

const createActivityMappingFailure = (error) => {
  return {
    type: CREATE_ACTIVITY_MAPPING_FAILURE,
    payload: error,
  };
};

const createActivityMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createActivityMappingRequest());
    let url = `${baseUrl}/mvtmap`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createActivityMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createActivityMappingFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createPicklistForUnplannedIssueRequest = () => {
  return {
    type: CREATE_UNPLANNED_REQUEST,
  };
};

const createPicklistForUnplannedIssueSuccess = (payload) => {
  return {
    type: CREATE_UNPLANNED_SUCCESS,
    payload: payload,
  };
};

const createPicklistForUnplannedIssueFailure = (error) => {
  return {
    type: CREATE_UNPLANNED_FAILURE,
    payload: error,
  };
};

const createPicklistForUnplannedIssue = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPicklistForUnplannedIssueRequest());
    let url = `${baseUrl}/unplanned_issue/picklist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPicklistForUnplannedIssueSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPicklistForUnplannedIssueFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editLocationRequest = () => {
  return {
    type: EDIT_ITEMS_REQUEST,
  };
};

const editLocationSuccess = (payload) => {
  return {
    type: EDIT_ITEMS_SUCCESS,
    payload: payload,
  };
};

const editLocationFailure = (error) => {
  return {
    type: EDIT_ITEMS_FAILURE,
    payload: error,
  };
};

const editLocation = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editLocationRequest());
    let url = `${baseUrl}/mvtmap`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editLocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editLocationFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getLevel1ByLevelRequest = () => {
  return {
    type: GET_LEVEL1_BY_LEVEL_REQUEST,
  };
};

const getLevel1ByLevelSuccess = (payload) => {
  return {
    type: GET_LEVEL1_BY_LEVEL_SUCCESS,
    payload: payload,
  };
};

const getLevel1ByLevelFailure = (error) => {
  return {
    type: GET_LEVEL1_BY_LEVEL_FAILURE,
    payload: error,
  };
};

const getLevel1ByLevel = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel1ByLevelRequest());
    let url = `${baseUrl}/location/level?level=1&warehouse_id=${warehouseId}&parent_id=0`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel1ByLevelSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel1ByLevelFailure(err.message));
      });
  };
};

const getLevel2ByLevelRequest = () => {
  return {
    type: GET_LEVEL2_BY_LEVEL_REQUEST,
  };
};

const getLevel2ByLevelSuccess = (payload) => {
  return {
    type: GET_LEVEL2_BY_LEVEL_SUCCESS,
    payload: payload,
  };
};

const getLevel2ByLevelFailure = (error) => {
  return {
    type: GET_LEVEL2_BY_LEVEL_FAILURE,
    payload: error,
  };
};

const getLevel2ByLevel = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel2ByLevelRequest());
    let url = `${baseUrl}/location/level?level=2&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel2ByLevelSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel2ByLevelFailure(err.message));
      });
  };
};

const getLevel3ByLevelRequest = () => {
  return {
    type: GET_LEVEL3_BY_LEVEL_REQUEST,
  };
};

const getLevel3ByLevelSuccess = (payload) => {
  return {
    type: GET_LEVEL3_BY_LEVEL_SUCCESS,
    payload: payload,
  };
};

const getLevel3ByLevelFailure = (error) => {
  return {
    type: GET_LEVEL3_BY_LEVEL_FAILURE,
    payload: error,
  };
};

const getLevel3ByLevel = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel3ByLevelRequest());
    let url = `${baseUrl}/location/level?level=3&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel3ByLevelSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel3ByLevelFailure(err.message));
      });
  };
};

const getLevel4ByLevelRequest = () => {
  return {
    type: GET_LEVEL4_BY_LEVEL_REQUEST,
  };
};

const getLevel4ByLevelSuccess = (payload) => {
  return {
    type: GET_LEVEL4_BY_LEVEL_SUCCESS,
    payload: payload,
  };
};

const getLevel4ByLevelFailure = (error) => {
  return {
    type: GET_LEVEL4_BY_LEVEL_FAILURE,
    payload: error,
  };
};

const getLevel4ByLevel = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel4ByLevelRequest());
    let url = `${baseUrl}/location/level?level=4&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel4ByLevelSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel4ByLevelFailure(err.message));
      });
  };
};

const getAcnRequest = () => {
  return {
    type: GET_ACN_REQUEST,
  };
};

const getAcnSuccess = (payload) => {
  return {
    type: GET_ACN_SUCCESS,
    payload: payload,
  };
};

const getAcnFailure = (error) => {
  return {
    type: GET_ACN_FAILURE,
    payload: error,
  };
};

const getAcn = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAcnRequest());
    let url = `${baseUrl}/acn`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAcnSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAcnFailure(err.message));
      });
  };
};

const uploadCsvForItemMappingRequest = () => {
  return {
    type: UPLOADCSV_ITEM_REQUEST,
  };
};

const uploadCsvForItemMappingSuccess = (payload) => {
  return {
    type: UPLOADCSV_ITEM_SUCCESS,
    payload: payload,
  };
};

const uploadCsvForItemMappingFailure = (error) => {
  return {
    type: UPLOADCSV_ITEM_FAILURE,
    payload: error,
  };
};

const uploadCsvForItemMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvForItemMappingRequest());
    let url = `${baseUrl}/item/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvForItemMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvForItemMappingFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getLevel1ByLevelSrcRequest = () => {
  return {
    type: GET_LEVEL1_BY_LEVELSRC_REQUEST,
  };
};

const getLevel1ByLevelSrcSuccess = (payload) => {
  return {
    type: GET_LEVEL1_BY_LEVELSRC_SUCCESS,
    payload: payload,
  };
};

const getLevel1ByLevelSrcFailure = (error) => {
  return {
    type: GET_LEVEL1_BY_LEVELSRC_FAILURE,
    payload: error,
  };
};

const getLevel1ByLevelSrc = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel1ByLevelSrcRequest());
    let url = `${baseUrl}/location/level?level=1&warehouse_id=${warehouseId}&parent_id=0`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel1ByLevelSrcSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel1ByLevelSrcFailure(err.message));
      });
  };
};

const getLevel2ByLevelSrcRequest = () => {
  return {
    type: GET_LEVEL2_BY_LEVELSRC_REQUEST,
  };
};

const getLevel2ByLevelSrcSuccess = (payload) => {
  return {
    type: GET_LEVEL2_BY_LEVELSRC_SUCCESS,
    payload: payload,
  };
};

const getLevel2ByLevelSrcFailure = (error) => {
  return {
    type: GET_LEVEL2_BY_LEVELSRC_FAILURE,
    payload: error,
  };
};

const getLevel2ByLevelSrc = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel2ByLevelSrcRequest());
    let url = `${baseUrl}/location/level?level=2&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel2ByLevelSrcSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel2ByLevelSrcFailure(err.message));
      });
  };
};

const getLevel3ByLevelSrcRequest = () => {
  return {
    type: GET_LEVEL3_BY_LEVELSRC_REQUEST,
  };
};

const getLevel3ByLevelSrcSuccess = (payload) => {
  return {
    type: GET_LEVEL3_BY_LEVELSRC_SUCCESS,
    payload: payload,
  };
};

const getLevel3ByLevelSrcFailure = (error) => {
  return {
    type: GET_LEVEL3_BY_LEVELSRC_FAILURE,
    payload: error,
  };
};

const getLevel3ByLevelSrc = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel3ByLevelSrcRequest());
    let url = `${baseUrl}/location/level?level=3&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel3ByLevelSrcSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel3ByLevelSrcFailure(err.message));
      });
  };
};

const getLevel4ByLevelSrcRequest = () => {
  return {
    type: GET_LEVEL4_BY_LEVELSRC_REQUEST,
  };
};

const getLevel4ByLevelSrcSuccess = (payload) => {
  return {
    type: GET_LEVEL4_BY_LEVELSRC_SUCCESS,
    payload: payload,
  };
};

const getLevel4ByLevelSrcFailure = (error) => {
  return {
    type: GET_LEVEL4_BY_LEVELSRC_FAILURE,
    payload: error,
  };
};

const getLevel4ByLevelSrc = (warehouseId, parentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLevel4ByLevelSrcRequest());
    let url = `${baseUrl}/location/level?level=4&warehouse_id=${warehouseId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLevel4ByLevelSrcSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLevel4ByLevelSrcFailure(err.message));
      });
  };
};

const deleteItemsRequest = () => {
  return {
    type: DELETE_ITEMS_REQUEST,
  };
};

const deleteItemsSuccess = (payload) => {
  return {
    type: DELETE_ITEMS_SUCCESS,
    payload: payload,
  };
};

const deleteItemsFaliure = (error) => {
  return {
    type: DELETE_ITEMS_FAILURE,
    payload: error,
  };
};

const deleteItems = (itemLocAcnMapId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteItemsRequest());
    let url = `${baseUrl}/mvtmap?id=${itemLocAcnMapId}`;
    // let url = `/mvtmap?id=334`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deleteItemsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteItemsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getMappedActivity,
  createActivityMapping,
  editLocation,
  getLevel1ByLevel,
  getLevel2ByLevel,
  getLevel3ByLevel,
  getLevel4ByLevel,
  getAcn,
  createPicklistForUnplannedIssue,
  deleteItems,
  uploadCsvForItemMapping,
  getLevel1ByLevelSrc,
  getLevel2ByLevelSrc,
  getLevel3ByLevelSrc,
  getLevel4ByLevelSrc,
};
