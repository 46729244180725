export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";

export const GET_REJECTED_TRANSACTION_REQUEST = "GET_REJECTED_TRANSACTION_REQUEST";
export const GET_REJECTED_TRANSACTION_SUCCESS = "GET_REJECTED_TRANSACTION_SUCCESS";
export const GET_REJECTED_TRANSACTION_FAILURE = "GET_REJECTED_TRANSACTION_FAILURE";

export const GET_COMPLETED_TRANSACTION_REQUEST = "GET_COMPLETED_TRANSACTION_REQUEST";
export const GET_COMPLETED_TRANSACTION_SUCCESS = "GET_COMPLETED_TRANSACTION_SUCCESS";
export const GET_COMPLETED_TRANSACTION_FAILURE = "GET_COMPLETED_TRANSACTION_FAILURE";


export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAILURE = "CREATE_TRANSACTION_FAILURE";
