/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";

// Date fns
import { format, addMinutes } from "date-fns";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getProductionPlan,
  createProductionPlan,
  editRequiredQty,
  deleteProductionPlan,
  uploadForProductionPlan,
  publishProductionPlan,
  getItemMaster,
} from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "fit-content",
  maxHeight: "90%",
  overflow: "auto",
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const ProductionPlan = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  createProductionPlan,
  editRequiredQty,
  deleteProductionPlan,
  publishProductionPlan,
  uploadForProductionPlan,
  getProductionPlan,
  getProductionPlanReducer,
  itemMaster,
  getItemMaster,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);
  const [uncheck, setUncheck] = useState(false);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let productionPlanModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "8a3103fd-be61-47d3-86a7-00cdb982d36b"
  );
  let createAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "f97b79ad-2f1c-4463-bfef-ab02fd4f5fcb"
      )
    : null;
  let viewAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "e9bca4be-e559-4f03-8625-d52ae84dce8a"
      )
    : null;
  let uploadAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "455b3a74-65c8-4b80-b042-343f0189fccf"
      )
    : null;
  let downloadAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "056c46b0-b7ff-46f4-bb7c-62ca4b836967"
      )
    : null;
  let editAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "9662365b-3e9c-4982-85bf-fa36c32b1566"
      )
    : null;
  let deleteAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "b0232be7-2fc0-4e93-b5dc-d1b60fef6967"
      )
    : null;
  let publishAccess = productionPlanModule
    ? productionPlanModule.actionId.includes(
        "cc444485-f416-43ef-9860-03cea31a3fda"
      )
    : null;

  useEffect(() => {
    getProductionPlan(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      // { Header: "Part Code/Description", accessor: "partDesc" },
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Purchase Date", accessor: "date" },
      // { Header: "po number", accessor: "poNumber" },
      // { Header: "po line number", accessor: "poLineNumber" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Unloading Point", accessor: "unloadingPoint" },
      { Header: "Delivery Plant", accessor: "deliveryPlant" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorMsgForDelete, setErrorMsgForDelete] = useState("");
  const [isErrorForCreate, setIsErrorForCreate] = useState(false);
  const [isSuccessForCreate, setIsSuccessForCreate] = useState(false);

  useEffect(() => {
    if (isErrorForCreate) {
      setTimeout(() => {
        setIsErrorForCreate(false);
      }, 3000);
    }
  }, [isErrorForCreate]);

  useEffect(() => {
    if (isSuccessForCreate) {
      setTimeout(() => {
        setIsSuccessForCreate(false);
      }, 3000);
    }
  }, [isSuccessForCreate]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempAsset = [];
    let data = getProductionPlanReducer.production.data
      ? getProductionPlanReducer.production.data
      : [];
    setLoading(getProductionPlanReducer.loading);
    data.map((plans) => {
      const formatDate = new Date(plans.date);

      const plansObject = {
        privateKey: plans.productionPlanId,
        date: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: plans.qty,
        productionPlanId: plans.productionPlanId,
        isPublished: plans.isPublished,
        itemCode: plans.itemCode,
        unloadingPoint:
          plans.unloadingPoint == "undefined" ? "NA" : plans.unloadingPoint,
        deliveryPlant:
          plans.deliveryPlant == "undefined" ? "NA" : plans.deliveryPlant,

        isEditable: plans.isEditable,
        action: (
          <div>
            {editAccess && (
              <EditButton
                tooltip="Edit"
                disabled={plans.isPublished === 1}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateProductionModal(plans)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
            {deleteAccess && (
              <DeleteButton
                tooltip="Delete"
                disabled={plans.isPublished === 1}
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteModal(plans)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </div>
        ),
      };
      viewAccess && tempAsset.push(plansObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [getProductionPlanReducer]);

  const [openCreateProductionModal, setOpenCreateProductionModal] =
    useState(false);

  const handleOpenCreateProductionModal = () => {
    setOpenCreateProductionModal(true);
    formikForCreateProductionPlan.setValues(initialValueForCreateProduction);
    formikForCreateProductionPlan.setTouched({});
    formikForCreateProductionPlan.setErrors({});
    setSelectedItemCode("");
    getItemMaster(jwtToken);
  };

  const handleCloseCreateProductionModal = () => {
    setOpenCreateProductionModal(false);
  };

  const initialValueForCreateProduction = {
    // partCodeDes: "",
    purchaseDate: "",
    itemCode: "",
    reqQty: "",
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of the day
  const todayStr = today.toISOString().split("T")[0];

  const validationSchemaForProduction = Yup.object({
    purchaseOrderNumber: Yup.string().required("Enter purchase order number"),
    purchaseOrderLineNumber: Yup.string().required(
      "Enter purchase order line number"
    ),
    unloadingPoint: Yup.string().required("Enter unloading point"),
    deliveryPlant: Yup.string().required("Enter delivery plant"),
    purchaseDate: Yup.date().min(today, "Date cannot be in the past!"),
    itemCode: Yup.object().required("Select item Code"),
    reqQty: Yup.string().required("Enter a qty"),
  });

  const onSubmitForCreateProduction = async (values, { resetForm }) => {
    let payload = {
      // partCodeDes: values.partCodeDes,
      date: values.purchaseDate,
      userId: userId,
      qty: values.reqQty,
      unloadingPoint: values.unloadingPoint,
      deliveryPlant: values.deliveryPlant,
      purchaseOrderNumber: values.purchaseOrderNumber,
      purchaseOrderLineNumber: values.purchaseOrderLineNumber,
      // itemCode: selectedItemCode?.label,
      itemId: selectedItemCode?.value,
      itemCode: selectedItemCode?.code,
    };

    let res = await createProductionPlan(payload, jwtToken);

    if (res.status) {
      setIsSuccessForCreate(true);
      getProductionPlan(jwtToken);
      setSelectedItemCode("");

      resetForm();
    }
    if (!res.status) {
      setIsErrorForCreate(true);
      setErrorMsgForCreate(res.data.data.msg);
    }
  };

  const formikForCreateProductionPlan = useFormik({
    initialValues: initialValueForCreateProduction,
    validationSchema: validationSchemaForProduction,
    onSubmit: onSubmitForCreateProduction,
  });
  const [singleProductionDataForPublish, setSingleProductionDataForPublish] =
    useState({});

  const [singleProductionDataForDelete, setSingleProductionDataForDelete] =
    useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (plans = {}) => {
    setSingleProductionDataForDelete(plans);

    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);

  useEffect(() => {
    if (isErrorDelete) {
      setTimeout(() => {
        setIsErrorDelete(false);
      }, 3000);
    }
  }, [isErrorDelete]);

  useEffect(() => {
    if (isSuccessDelete) {
      setTimeout(() => {
        setIsSuccessDelete(false);
      }, 3000);
    }
  }, [isSuccessDelete]);
  const [isErrorPublish, setIsErrorPublish] = useState(false);
  const [isSuccessPublish, setIsSuccessPublish] = useState(false);
  const [isErrorMsgForPublish, setIsErrorMsgForPublish] = useState(false);

  useEffect(() => {
    if (isErrorPublish) {
      setTimeout(() => {
        setIsErrorPublish(false);
      }, 3000);
    }
  }, [isErrorPublish]);

  useEffect(() => {
    if (isSuccessPublish) {
      setTimeout(() => {
        setIsSuccessPublish(false);
      }, 3000);
    }
  }, [isSuccessPublish]);

  const deleteProductionData = async () => {
    let productionPlanId = singleProductionDataForDelete.productionPlanId;

    let response = await deleteProductionPlan(
      productionPlanId,
      userId,
      jwtToken
    );
    if (response.status) {
      setIsSuccessDelete(true);
      getProductionPlan(jwtToken);

      setTimeout(() => {
        handleCloseDeleteModal();
      }, 2250);
    }
    if (!response.status) {
      setIsErrorDelete(true);
      setErrorMsgForDelete(response.data.data.msg);
    }
  };

  const [openCreatePublishModal, setOpenCreatePublishModal] = useState(false);

  const [selectedPublishData, setSelectedPublishData] = useState([]);

  const handleOpenCreatePublishModal = (data = {}) => {
    setOpenCreatePublishModal(true);
  };

  const handleCloseCreatePickupListModal = () => {
    setOpenCreatePublishModal(false);
  };

  const createPublishHandler = (event, data) => {
    handleOpenCreatePublishModal(data);
  };

  const PublishData = (data) => {
    setSelectedPublishData(data);
  };

  const onClickReleaseProduction = async () => {
    let payload = [];

    selectedPublishData.map((data) => {
      const tempPayload = {
        productionPlanId: data.productionPlanId,
      };
      payload.push(tempPayload);
    });
    let res = await publishProductionPlan(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      getProductionPlan(jwtToken);
      setIsSuccessPublish(true);
      setTimeout(() => {
        handleCloseCreatePickupListModal();
      }, 2000);
    }
    if (!res.status) {
      setIsErrorPublish(true);
      setIsErrorMsgForPublish(res.data.data.msg);
    }
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let value = selectedPublishData.some((data) => data.isPublished === 1);
    setDisableButton(value);
  }, [selectedPublishData]);

  const [openUpdateProductionModal, setOpenUpdateProductionModal] =
    useState(false);
  const [singleProductionDataForUpdate, setProductionDataForUpdate] = useState(
    {}
  );
  const handleOpenUpdateProductionModal = (Asset = {}) => {
    setProductionDataForUpdate(Asset);
    setOpenUpdateProductionModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateProductionModal = () => {
    setOpenUpdateProductionModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 4000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    reqQty: singleProductionDataForUpdate.qty,
    eDate: singleProductionDataForUpdate.date?.slice(0, 10),
  };
  // const today = new Date();
  // today.setHours(0, 0, 0, 0); // Set to start of the day
  // const todayStr = today.toISOString().split('T')[0];

  const validationSchemaForUpdate = Yup.object({
    eDate: Yup.date().min(today, "Date cannot be in the past!"),
    // .required("Enter the asset name!"),
  });
  //   const validationSchemaForUpdate = Yup.object({
  //     eDate: Yup.string().required("Enter the asset name!"),
  //     assetCode: Yup.string().required("Enter the asset code!"),
  //   });

  const onSubmitForUpdate = async (values) => {
    let payload = {
      productionPlanId: singleProductionDataForUpdate.productionPlanId,
      qty: values.reqQty,
      date: values.eDate,
      userId: userId,
    };

    let res = await editRequiredQty(payload, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getProductionPlan(jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("production", uploadCsvData);

    let res = await uploadForProductionPlan(data, jwtToken);

    if (res.status) {
      getProductionPlan(jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification();
    }
  };

  const headers = [
    {
      label: "date",
      key: "date",
    },
    {
      label: "itemCode",
      key: "itemCode",
    },
    { label: "qty", key: "qty" },
    { label: "unloadingPoint", key: "unloadingPoint" },
    { label: "deliveryPlant", key: "deliveryPlant" },
  ];
  const csvLink = {
    filename: "Production Plan Template.csv",
    headers: headers,
    data: [],
  };
  const csvLink2 = {
    filename: "Production Plan.csv",
    headers: headers,
    data: rows,
  };
  const [selectedItemCode, setSelectedItemCode] = useState("");

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        code: item.itemCode,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                {/* {createAssetAccess && ( */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  // mb={3}
                >
                  <MDBox color="text" px={2} ml={-2} mb={1}>
                    {createAccess && (
                      <CreateButton
                        tooltip="Create Production Plan"
                        color="info"
                        variant="contained"
                        type="button"
                        onClick={handleOpenCreateProductionModal}
                        style={{ marginRight: "10px" }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </CreateButton>
                    )}

                    <CSVLink {...csvLink}>
                      <DownloadButton
                        tooltip="Download Template"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="error"
                        iconOnly
                      >
                        <Icon>download</Icon>
                      </DownloadButton>
                    </CSVLink>

                    {uploadAccess && (
                      <UploadButton
                        tooltip="Upload Production Plan"
                        style={{ marginRight: "1rem" }}
                        type="button"
                        variant="outlined"
                        color="info"
                        iconOnly
                        onClick={uploadCsvFilterHandler}
                      >
                        <Icon>upload</Icon>
                      </UploadButton>
                    )}

                    <CSVLink {...csvLink2}>
                      {downloadAccess && (
                        <DownloadDataButton
                          tooltip="Download Production Plan List"
                          style={{ marginRight: "1rem" }}
                          type="button"
                          variant="outlined"
                          color="success"
                          iconOnly
                        >
                          <Icon>download</Icon>
                        </DownloadDataButton>
                      )}
                    </CSVLink>
                  </MDBox>
                </MDBox>
                {uploadCsvFilter && (
                  <div>
                    <div>
                      <input
                        className="choose_file"
                        style={{
                          // marginTop: "1.5rem",
                          marginLeft: "1.5rem",
                          // marginBottom: "-9rem",
                        }}
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          setUploadCsvData(e.target.files[0]);
                        }}
                      />

                      <MDButton
                        disabled={
                          !uploadCsvData.name ||
                          uploadCsvData.type !== "text/csv"
                        }
                        variant="contained"
                        color="info"
                        style={{ marginLeft: "0", marginTop: "0rem" }}
                        type="button"
                        onClick={uploadCsvHandler}
                      >
                        UPLOAD
                      </MDButton>
                    </div>
                    <div>
                      <span
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontStyle: "italic",
                        }}
                      >
                        <strong>*</strong> Please upload
                        <strong>".csv"</strong> file only
                      </span>
                    </div>
                  </div>
                )}

                <MDBox mt={0}>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      tableSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      options={{ draggable: false }}
                      selection={true}
                      selectionButtonClick={PublishData}
                      uncheckProp={uncheck}
                      selectionButtonComponent={
                        // createPickuplistAccess &&
                        <Tooltip title="Publish">
                          {publishAccess && (
                            <MDButton
                              disabled={disableButton}
                              color="white"
                              type="button"
                              style={{
                                marginTop: "0.5rem",
                                color: "white",
                                border: "1px solid #0275d8",
                                background: "#0275d8",
                                marginLeft: 5,
                              }}
                              onClick={(evt, data) =>
                                createPublishHandler(evt, data)
                              }
                            >
                              Publish
                            </MDButton>
                          )}
                          {disableButton && publishAccess && (
                            <MDTypography
                              color="error"
                              variant="p"
                              style={{
                                // textAlign: "center",
                                marginLeft: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              Remove the published records!
                            </MDTypography>
                          )}
                        </Tooltip>
                      }
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}
                </MDBox>
                <Modal
                  open={openCreateProductionModal}
                  onClose={handleCloseCreateProductionModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Create Production Plan
                      </MDTypography>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateProductionModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            marginLeft: "-55px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForCreateProductionPlan.handleSubmit}
                      >
                        <MDBox>
                          <MDBox mb={1}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Purchase Date
                            </MDTypography>
                            <MDInput
                              type="date"
                              name="purchaseDate"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateProductionPlan.handleBlur}
                              onChange={
                                formikForCreateProductionPlan.handleChange
                              }
                              value={
                                formikForCreateProductionPlan.values
                                  .purchaseDate
                              }
                              error={
                                formikForCreateProductionPlan.touched
                                  .purchaseDate &&
                                formikForCreateProductionPlan.errors
                                  .purchaseDate &&
                                true
                              }
                            />
                            {formikForCreateProductionPlan.touched
                              .purchaseDate &&
                              formikForCreateProductionPlan.errors
                                .purchaseDate && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors
                                      .purchaseDate
                                  }
                                />
                              )}
                          </MDBox>
                          <MDBox mb={1}>
                            <div style={{ display: "flex" }}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                PO Number
                              </MDTypography>
                            </div>
                            <MDInput
                              type="text"
                              name="purchaseOrderNumber"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateProductionPlan.handleBlur}
                              onChange={
                                formikForCreateProductionPlan.handleChange
                              }
                              value={
                                formikForCreateProductionPlan.values
                                  .purchaseOrderNumber
                              }
                              error={
                                formikForCreateProductionPlan.touched
                                  .purchaseOrderNumber &&
                                formikForCreateProductionPlan.errors
                                  .purchaseOrderNumber &&
                                true
                              }
                            />
                            {formikForCreateProductionPlan.touched
                              .purchaseOrderNumber &&
                              formikForCreateProductionPlan.errors
                                .purchaseOrderNumber && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors
                                      .purchaseOrderNumber
                                  }
                                />
                              )}
                          </MDBox>
                          <MDBox mb={1}>
                            <div style={{ display: "flex" }}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                PO Line Number
                              </MDTypography>
                            </div>
                            <MDInput
                              type="text"
                              name="purchaseOrderLineNumber"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateProductionPlan.handleBlur}
                              onChange={
                                formikForCreateProductionPlan.handleChange
                              }
                              value={
                                formikForCreateProductionPlan.values
                                  .purchaseOrderLineNumber
                              }
                              error={
                                formikForCreateProductionPlan.touched
                                  .purchaseOrderLineNumber &&
                                formikForCreateProductionPlan.errors
                                  .purchaseOrderLineNumber &&
                                true
                              }
                            />
                            {formikForCreateProductionPlan.touched
                              .purchaseOrderLineNumber &&
                              formikForCreateProductionPlan.errors
                                .purchaseOrderLineNumber && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors
                                      .purchaseOrderLineNumber
                                  }
                                />
                              )}
                          </MDBox>
                          <MDBox style={{ width: "300px" }}>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: "500" }}
                            >
                              {" "}
                              Item Code/Des
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css-for-itemcode"
                              maxMenuHeight={130}
                              placeholder="Choose one..."
                              name="itemCode"
                              onBlur={() => {
                                formikForCreateProductionPlan.handleBlur({
                                  target: { name: "itemCode" },
                                });
                              }}
                              value={selectedItemCode}
                              options={itemCodeOptions}
                              onChange={(selectedOption) => {
                                handleItemCodeChange(selectedOption);
                                formikForCreateProductionPlan.setFieldValue(
                                  "itemCode",
                                  selectedOption
                                );
                              }}
                            />

                            {formikForCreateProductionPlan.touched.itemCode &&
                              formikForCreateProductionPlan.errors.itemCode && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors
                                      .itemCode
                                  }
                                />
                              )}
                          </MDBox>

                          <MDBox mb={1}>
                            <div style={{ display: "flex" }}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                Required Qty
                              </MDTypography>
                            </div>
                            <MDInput
                              type="number"
                              name="reqQty"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateProductionPlan.handleBlur}
                              onChange={
                                formikForCreateProductionPlan.handleChange
                              }
                              value={
                                formikForCreateProductionPlan.values.reqQty
                              }
                              error={
                                formikForCreateProductionPlan.touched.reqQty &&
                                formikForCreateProductionPlan.errors.reqQty &&
                                true
                              }
                            />
                            {formikForCreateProductionPlan.touched.reqQty &&
                              formikForCreateProductionPlan.errors.reqQty && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors.reqQty
                                  }
                                />
                              )}
                          </MDBox>
                          <MDBox mb={1}>
                            <div style={{ display: "flex" }}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                Unloading Point
                              </MDTypography>
                            </div>
                            <MDInput
                              type="text"
                              name="unloadingPoint"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateProductionPlan.handleBlur}
                              onChange={
                                formikForCreateProductionPlan.handleChange
                              }
                              value={
                                formikForCreateProductionPlan.values
                                  .unloadingPoint
                              }
                              error={
                                formikForCreateProductionPlan.touched
                                  .unloadingPoint &&
                                formikForCreateProductionPlan.errors
                                  .unloadingPoint &&
                                true
                              }
                            />
                            {formikForCreateProductionPlan.touched
                              .unloadingPoint &&
                              formikForCreateProductionPlan.errors
                                .unloadingPoint && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors
                                      .unloadingPoint
                                  }
                                />
                              )}
                          </MDBox>
                          <MDBox mb={1}>
                            <div style={{ display: "flex" }}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: 500 }}
                              >
                                Delivery Plant
                              </MDTypography>
                            </div>
                            <MDInput
                              type="text"
                              name="deliveryPlant"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreateProductionPlan.handleBlur}
                              onChange={
                                formikForCreateProductionPlan.handleChange
                              }
                              value={
                                formikForCreateProductionPlan.values
                                  .deliveryPlant
                              }
                              error={
                                formikForCreateProductionPlan.touched
                                  .deliveryPlant &&
                                formikForCreateProductionPlan.errors
                                  .deliveryPlant &&
                                true
                              }
                            />
                            {formikForCreateProductionPlan.touched
                              .deliveryPlant &&
                              formikForCreateProductionPlan.errors
                                .deliveryPlant && (
                                <TextError
                                  msg={
                                    formikForCreateProductionPlan.errors
                                      .deliveryPlant
                                  }
                                />
                              )}
                          </MDBox>
                          <MDBox mt={2}>
                            <Collapse in={isSuccessForCreate}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessForCreate(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Plan created successfully!
                              </Alert>
                            </Collapse>
                            <Collapse in={isErrorForCreate}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorForCreate(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {/* Could not create the Asset! */}
                                {errorMsgForCreate}
                              </Alert>
                            </Collapse>
                          </MDBox>

                          <div className="mt-3">
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                              disabled={!formikForCreateProductionPlan.isValid}
                            >
                              Create
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openCreatePublishModal}
                  onClose={handleCloseCreatePickupListModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForRelease}>
                    <MDTypography>
                      Are you sure you want to Publish ?
                    </MDTypography>
                    <MDBox mt={2}>
                      <MDButton
                        // style={{ width: "48%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseCreatePickupListModal}
                      >
                        Cancel
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: "0.5rem" }}
                        color="info"
                        onClick={onClickReleaseProduction}
                      >
                        Publish
                      </MDButton>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessPublish}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessPublish(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Published successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorPublish}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorPublish(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {isErrorMsgForPublish}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openUpdateProductionModal}
                  onClose={handleCloseUpdateProductionModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={style}>
                    <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Update
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseUpdateProductionModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              marginLeft: "-55px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForUpdate.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDTypography variant="h6"> Qty</MDTypography>
                          <MDInput
                            type="number"
                            name="reqQty"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.reqQty}
                            error={
                              formikForUpdate.touched.reqQty &&
                              formikForUpdate.errors.reqQty &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.reqQty &&
                            formikForUpdate.errors.reqQty && (
                              <TextError msg={formikForUpdate.errors.reqQty} />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="h6"> Date</MDTypography>
                          <MDInput
                            type="date"
                            name="eDate"
                            fullWidth
                            autoComplete="off"
                            onBlur={formikForUpdate.handleBlur}
                            onChange={formikForUpdate.handleChange}
                            value={formikForUpdate.values.eDate}
                            error={
                              formikForUpdate.touched.eDate &&
                              formikForUpdate.errors.eDate &&
                              true
                            }
                            inputProps={{
                              maxLength: 45,
                            }}
                          />
                          {formikForUpdate.touched.eDate &&
                            formikForUpdate.errors.eDate && (
                              <TextError msg={formikForUpdate.errors.eDate} />
                            )}
                        </MDBox>
                        <MDBox>
                          <Collapse in={isErrorForUpdate}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForUpdate}
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForUpdate}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForUpdate(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Updated successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={3}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formikForUpdate.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openDeleteModal}
                  onClose={handleCloseDeleteModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={deleteStyle}>
                    <MDTypography>
                      Are you sure you want to delete?
                    </MDTypography>
                    <MDBox mt={2} display="flex">
                      <div>
                        <MDButton
                          style={{ width: "100%", marginRight: "0.5rem" }}
                          color="info"
                          variant="outlined"
                          onClick={handleCloseDeleteModal}
                        >
                          Cancel
                        </MDButton>
                      </div>
                      <div style={{ float: "right" }}>
                        <MDButton
                          style={{ width: "100%", marginLeft: "0.5rem" }}
                          color="error"
                          onClick={deleteProductionData}
                        >
                          Delete
                        </MDButton>
                      </div>
                    </MDBox>
                    <MDBox mt={2}>
                      <Collapse in={isSuccessDelete}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Deleted successfully!
                        </Alert>
                      </Collapse>
                      <Collapse in={isErrorDelete}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorDelete(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForDelete}
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ToastContainer />
        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getProductionPlanReducer: state.getProductionPlanReducer,
    // profileData: state.profile,
    itemMaster: state.itemMaster,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getProductionPlan: (jwtToken) => dispatch(getProductionPlan(jwtToken)),
    deleteProductionPlan: (productionPlanId, userId, jwtToken) =>
      dispatch(deleteProductionPlan(productionPlanId, userId, jwtToken)),
    publishProductionPlan: (payload, jwtToken) =>
      dispatch(publishProductionPlan(payload, jwtToken)),
    createProductionPlan: (payload, jwtToken) =>
      dispatch(createProductionPlan(payload, jwtToken)),
    editRequiredQty: (payload, jwtToken) =>
      dispatch(editRequiredQty(payload, jwtToken)),
    uploadForProductionPlan: (payload, jwtToken) =>
      dispatch(uploadForProductionPlan(payload, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionPlan);
