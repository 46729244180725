import {
  GET_CATEGORYITEMMAPPING_REQUEST,
  GET_CATEGORYITEMMAPPING_SUCCESS,
  GET_CATEGORYITEMMAPPING_FAILURE,
  CREATE_CATEGORYITEMMAPPING_REQUEST,
  CREATE_CATEGORYITEMMAPPING_SUCCESS,
  CREATE_CATEGORYITEMMAPPING_FAILURE,
  UPDATE_CATEGORYITEMMAPPING_REQUEST,
  UPDATE_CATEGORYITEMMAPPING_SUCCESS,
  UPDATE_CATEGORYITEMMAPPING_FAILURE,
  GET_WAREHOUSES_BY_MOVEMENT_REQUEST,
  GET_WAREHOUSES_BY_MOVEMENT_SUCCESS,
  GET_WAREHOUSES_BY_MOVEMENT_FAILURE,
} from "./categoryItemMappingTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getCategoryItemMappingRequest = () => {
  return {
    type: GET_CATEGORYITEMMAPPING_REQUEST,
  };
};

const getCategoryItemMappingSuccess = (payload) => {
  return {
    type: GET_CATEGORYITEMMAPPING_SUCCESS,
    payload: payload,
  };
};

const getCategoryItemMappingFailure = (error) => {
  return {
    type: GET_CATEGORYITEMMAPPING_FAILURE,
    payload: error,
  };
};

const getCategoryItemMapping = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getCategoryItemMappingRequest());
    let url = `${baseUrl}/item_category_map`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCategoryItemMappingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getCategoryItemMappingFailure(err.response));
      });
  };
};

const createCategoryItemMappingRequest = () => {
  return {
    type: CREATE_CATEGORYITEMMAPPING_REQUEST,
  };
};

const createCategoryItemMappingSuccess = (payload) => {
  return {
    type: CREATE_CATEGORYITEMMAPPING_SUCCESS,
    payload: payload,
  };
};

const createCategoryItemMappingFaliure = (error) => {
  return {
    type: CREATE_CATEGORYITEMMAPPING_FAILURE,
    payload: error,
  };
};

const createCategoryItemMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createCategoryItemMappingRequest());
    let url = `${baseUrl}/item_category_map`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createCategoryItemMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createCategoryItemMappingFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateCategoryItemMappingRequest = () => {
  return {
    type: UPDATE_CATEGORYITEMMAPPING_REQUEST,
  };
};

const updateCategoryItemMappingSuccess = (payload) => {
  return {
    type: UPDATE_CATEGORYITEMMAPPING_SUCCESS,
    payload: payload,
  };
};

const updateCategoryItemMappingFaliure = (error) => {
  return {
    type: UPDATE_CATEGORYITEMMAPPING_FAILURE,
    payload: error,
  };
};

const updateCategoryItemMapping = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(updateCategoryItemMappingRequest());
    let url = `${baseUrl}/item_category_map`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateCategoryItemMappingSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateCategoryItemMappingFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getWarehousesByMovementRequest = () => {
  return {
    type: GET_WAREHOUSES_BY_MOVEMENT_REQUEST,
  };
};

const getWarehousesByMovementSuccess = (payload) => {
  return {
    type: GET_WAREHOUSES_BY_MOVEMENT_SUCCESS,
    payload: payload,
  };
};

const getWarehousesByMovementFailure = (error) => {
  return {
    type: GET_WAREHOUSES_BY_MOVEMENT_FAILURE,
    payload: error,
  };
};


const getWarehousesByMovement = (activityIdSelected,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehousesByMovementRequest());
    // let url = `${baseUrl}/reports/warehouse_occupancy?locationCode=${locationCode}`;
    let url = `${baseUrl}/warehouse_by_acn?acnId=${activityIdSelected}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehousesByMovementSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getWarehousesByMovementFailure(err.message));
      });
  };
};
export {
  getCategoryItemMapping,
  createCategoryItemMapping,
  updateCategoryItemMapping,
  getWarehousesByMovement,
};
