/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    VALIDATE_OTP_FOR_FORGOT_PASSWORD_REQUEST,
    VALIDATE_OTP_FOR_FORGOT_PASSWORD_SUCCESS,
    VALIDATE_OTP_FOR_FORGOT_PASSWORD_FAILURE
} from './forgotPasswordTypes';

const initialState = {
    loading: false,
    forgotPassword: {},
    error: ""
}


const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                loading: true,
                forgotPassword: action.payload,
                error: ''
            }
        case FORGOT_PASSWORD_FAILURE:
            return {
                loading: false,
                forgotPassword: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForValidateOtpForForgotPassword = {
    loading: false,
    validateOtpForForgotPassword: {},
    error: ""
}


const validateOtpForForgotPasswordReducer = (state = initialStateForValidateOtpForForgotPassword, action) => {
    switch (action.type) {
        case VALIDATE_OTP_FOR_FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case VALIDATE_OTP_FOR_FORGOT_PASSWORD_SUCCESS:
            return {
                loading: false,
                validateOtpForForgotPassword: action.payload,
                error: ''
            }
        case VALIDATE_OTP_FOR_FORGOT_PASSWORD_FAILURE:
            return {
                loading: false,
                validateOtpForForgotPassword: {},
                error: action.payload
            }
        default:
            return state
    }
}

export {forgotPasswordReducer,validateOtpForForgotPasswordReducer}