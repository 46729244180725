import {
  GET_BOM_REQUEST,
  GET_BOM_SUCCESS,
  GET_BOM_FAILURE,
  CREATE_BOM_REQUEST,
  CREATE_BOM_SUCCESS,
  CREATE_BOM_FAILURE,
  EDIT_BOM_REQUEST,
  EDIT_BOM_SUCCESS,
  EDIT_BOM_FAILURE,
  UPLOAD_EXCEL_FOR_BOM_REQUEST,
  UPLOAD_EXCEL_FOR_BOM_SUCCESS,
  UPLOAD_EXCEL_FOR_BOM_FAILURE,
  GET_ITEMSBYFGCODE_REQUEST,
  GET_ITEMSBYFGCODE_SUCCESS,
  GET_ITEMSBYFGCODE_FAILURE,
  GET_ITEMSFORBOM_REQUEST,
  GET_ITEMSFORBOM_SUCCESS,
  GET_ITEMSFORBOM_FAILURE,
  GET_FG_BY_ASSEMBLYTYPE_REQUEST,
  GET_FG_BY_ASSEMBLYTYPE_SUCCESS,
  GET_FG_BY_ASSEMBLYTYPE_FAILURE,
  GET_ASSEMBLY_BY_ASSEMBLYTYPE_REQUEST,
  GET_ASSEMBLY_BY_ASSEMBLYTYPE_SUCCESS,
  GET_ASSEMBLY_BY_ASSEMBLYTYPE_FAILURE,
} from "./bomTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getBomRequest = () => {
  return {
    type: GET_BOM_REQUEST,
  };
};

const getBomSuccess = (payload) => {
  return {
    type: GET_BOM_SUCCESS,
    payload: payload,
  };
};

const getBomFailure = (error) => {
  return {
    type: GET_BOM_FAILURE,
    payload: error,
  };
};

const getBom = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getBomRequest());
    let url = `${baseUrl}/bom/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getBomSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getBomFailure(err.message));
      });
  };
};
const getItemsForBomRequest = () => {
  return {
    type: GET_ITEMSFORBOM_REQUEST,
  };
};

const getItemsForBomSuccess = (payload) => {
  return {
    type: GET_ITEMSFORBOM_SUCCESS,
    payload: payload,
  };
};

const getItemsForBomFailure = (error) => {
  return {
    type: GET_ITEMSFORBOM_FAILURE,
    payload: error,
  };
};

const getItemsForBom = (stationId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsForBomRequest());
    let url = `${baseUrl}/station_item/get_items_by_id?stationId=${stationId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsForBomSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemsForBomFailure(err.message));
      });
  };
};

const editBomRequest = () => {
  return {
    type: EDIT_BOM_REQUEST,
  };
};

const editBomSuccess = (payload) => {
  return {
    type: EDIT_BOM_SUCCESS,
    payload: payload,
  };
};

const editBomFailure = (error) => {
  return {
    type: EDIT_BOM_FAILURE,
    payload: error,
  };
};

const editBom = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editBomRequest());
    let url = `${baseUrl}/bom/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editBomSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editBomFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createBomRequest = () => {
  return {
    type: CREATE_BOM_REQUEST,
  };
};

const createBomSuccess = (payload) => {
  return {
    type: CREATE_BOM_SUCCESS,
    payload: payload,
  };
};

const createBomFailure = (error) => {
  return {
    type: CREATE_BOM_FAILURE,
    payload: error,
  };
};

const createBom = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createBomRequest());
    let url = `${baseUrl}/bom`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createBomSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createBomFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadForBomRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_BOM_REQUEST,
  };
};

const uploadForBomSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_BOM_SUCCESS,
    payload: payload,
  };
};

const uploadForBomFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_BOM_FAILURE,
    payload: error,
  };
};

const uploadForBom = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadForBomRequest());
    let url = `${baseUrl}/bom/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadForBomSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadForBomFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getItemsByFgcodeRequest = () => {
  return {
    type: GET_ITEMSBYFGCODE_REQUEST,
  };
};

const getItemsByFgcodeSuccess = (payload) => {
  return {
    type: GET_ITEMSBYFGCODE_SUCCESS,
    payload: payload,
  };
};

const getItemsByFgcodeFailure = (error) => {
  return {
    type: GET_ITEMSBYFGCODE_FAILURE,
    payload: error,
  };
};

const getItemsByFgCode = (fgCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsByFgcodeRequest());
    let url = `${baseUrl}/bom/get_by_fgId?fgModelId=${fgCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsByFgcodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemsByFgcodeFailure(err.message));
      });
  };
};

const getFgCodeByAssemblyTypeRequest = () => {
  return {
    type: GET_FG_BY_ASSEMBLYTYPE_REQUEST,
  };
};

const getFgCodeByAssemblyTypeSuccess = (payload) => {
  return {
    type: GET_FG_BY_ASSEMBLYTYPE_SUCCESS,
    payload: payload,
  };
};

const getFgCodeByAssemblyTypeFailure = (error) => {
  return {
    type: GET_FG_BY_ASSEMBLYTYPE_FAILURE,
    payload: error,
  };
};

const getFgCodeByAssemblyType = (assemblyType, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getFgCodeByAssemblyTypeRequest());
    // let url = `${baseUrl}/bom/get_item_by_assembly_type?assemblyTypeId=3`;
    let url = `${baseUrl}/bom/get_item_by_assembly_type?assemblyTypeId=${assemblyType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getFgCodeByAssemblyTypeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFgCodeByAssemblyTypeFailure(err.message));
      });
  };
};
const getAssemblyByAssemblyTypeRequest = () => {
  return {
    type: GET_ASSEMBLY_BY_ASSEMBLYTYPE_REQUEST,
  };
};

const getAssemblyByAssemblyTypeSuccess = (payload) => {
  return {
    type: GET_ASSEMBLY_BY_ASSEMBLYTYPE_SUCCESS,
    payload: payload,
  };
};

const getAssemblyByAssemblyTypeFailure = (error) => {
  return {
    type: GET_ASSEMBLY_BY_ASSEMBLYTYPE_FAILURE,
    payload: error,
  };
};

const getAssemblyByAssemblyType = (assemblyType, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssemblyByAssemblyTypeRequest());
    // let url = `${baseUrl}/assembly/get_by_type?assemblyTypeId=2`;
    let url = `${baseUrl}/assembly/get_by_type?assemblyTypeId=${assemblyType}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssemblyByAssemblyTypeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAssemblyByAssemblyTypeFailure(err.message));
      });
  };
};

export {
  getBom,
  editBom,
  createBom,
  uploadForBom,
  getItemsByFgCode,
  getItemsForBom,
  getFgCodeByAssemblyType,
  getAssemblyByAssemblyType
};
