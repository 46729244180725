/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

// Store
import { getSubModulesByRoleId } from "../../store";

// import DailyDemand from "./daily-demand";

// import BinMaster from "./bin-master";
import ItemLocationMapping from "./item-location-mapping";
import CategoryLocationMapping from "./category-location-mapping";

// Cookies
import Cookies from "universal-cookie";
// import PalletMaster from "./pallet-master";

const cookies = new Cookies();

const LocationMapping = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;
  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let ItemLocationMappingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "7876f9ad-8eb2-4b40-b3dd-5a773c290a90"
  );

  let categoryMappingModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "4df7bd62-072b-43a6-b68a-a2ce2949e84b"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>

        <Grid container spacing={6}>

          <Grid item xs={12}>

            {/* <TabView>
              <TabPanel header="Item Location Mapping">
                <ItemLocationMapping/>
              </TabPanel>
              <TabPanel header="Category Location Mapping">
                <CategoryLocationMapping/>
              </TabPanel>
            </TabView>
             */}
             {ItemLocationMappingModule && categoryMappingModule ? (
              <TabView>
                <TabPanel header="Item Location Mapping">
                  <ItemLocationMapping />
                </TabPanel>

                <TabPanel header="Category Location Mapping">
                  <CategoryLocationMapping />
                </TabPanel>
              </TabView>
            ) : !ItemLocationMappingModule && categoryMappingModule ? (
              <TabView>
                <TabPanel header="Category Location Mapping">
                  <CategoryLocationMapping />
                </TabPanel>
              </TabView>
            ) : ItemLocationMappingModule && !categoryMappingModule ? (
              <TabView>
                <TabPanel header="Item Location Mapping">
                  <ItemLocationMapping />
                </TabPanel>
              </TabView>
            ) : (
              <>
                <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                  No LocationMapping Access
                </h4>
              </>
            )}


          </Grid>

        </Grid>


      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(LocationMapping);
