import axios from "axios";
import Config from "../../config/index";
import {
  CREATE_PURCHASE_ORDER_FAILURE,
  CREATE_PURCHASE_ORDER_REQUEST,
  CREATE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAILURE,
  DELETE_PURCHASE_ORDER_REQUEST,
  DELETE_PURCHASE_ORDER_SUCCESS,
  EDIT_REQ_QTY_FAILURE,
  EDIT_REQ_QTY_REQUEST,
  EDIT_REQ_QTY_SUCCESS,
  GET_PURCHASE_ORDER_FAILURE,
  GET_PURCHASE_ORDER_REQUEST,
  GET_PURCHASE_ORDER_SUCCESS,
  PUBLISH_PURCHASE_ORDER_FAILURE,
  PUBLISH_PURCHASE_ORDER_REQUEST,
  PUBLISH_PURCHASE_ORDER_SUCCESS,
  GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE,
  GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST,
  GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS,
  PUBLISH_PO_REQUEST,
  PUBLISH_PO_SUCCESS,
  PUBLISH_PO_FAILURE,
  UPLOAD_EXCEL_FOR_PURCHASE_ORDER_FAILURE,
  UPLOAD_EXCEL_FOR_PURCHASE_ORDER_REQUEST,
  UPLOAD_EXCEL_FOR_PURCHASE_ORDER_SUCCESS,
  GET_VENDOR_CODE_REQUEST,
  GET_VENDOR_CODE_SUCCESS,
  GET_VENDOR_CODE_FAILURE,
  UPDATE_ACCEPTPO_REQUEST,
  UPDATE_ACCEPTPO_SUCCESS,
  UPDATE_ACCEPTPO_FAILURE,
  GET_RELEASED_PURCHASE_ORDERS_REQUEST,
  GET_RELEASED_PURCHASE_ORDERS_SUCCESS,
  GET_RELEASED_PURCHASE_ORDERS_FAILURE,
  GET_ACCEPTED_PURCHASE_ORDERS_REQUEST,
  GET_ACCEPTED_PURCHASE_ORDERS_SUCCESS,
  GET_ACCEPTED_PURCHASE_ORDERS_FAILURE,
} from "./purchaseOrderTypes";

let baseUrl = Config.baseUrl;

const getPurchaseOrderRequest = () => {
  return {
    type: GET_PURCHASE_ORDER_REQUEST,
  };
};

const getPurchaseOrderSuccess = (payload) => {
  return {
    type: GET_PURCHASE_ORDER_SUCCESS,
    payload: payload,
  };
};

const getPurchaseOrderFailure = (error) => {
  return {
    type: GET_PURCHASE_ORDER_FAILURE,
    payload: error,
  };
};

const getPurchaseOrder = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPurchaseOrderSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPurchaseOrderFailure(err.message));
      });
  };
};

const getReleasedPurchaseOrderRequest = () => {
  return {
    type: GET_RELEASED_PURCHASE_ORDERS_REQUEST,
  };
};

const getReleasedPurchaseOrderSuccess = (payload) => {
  return {
    type: GET_RELEASED_PURCHASE_ORDERS_SUCCESS,
    payload: payload,
  };
};

const getReleasedPurchaseOrderFailure = (error) => {
  return {
    type: GET_RELEASED_PURCHASE_ORDERS_FAILURE,
    payload: error,
  };
};

const getReleasedPurchaseOrder = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getReleasedPurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/get_released`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getReleasedPurchaseOrderSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getReleasedPurchaseOrderFailure(err.message));
      });
  };
};

const getAcceptedPurchaseOrdersRequest = () => {
  return {
    type: GET_ACCEPTED_PURCHASE_ORDERS_REQUEST,
  };
};

const getAcceptedPurchaseOrdersSuccess = (payload) => {
  return {
    type: GET_ACCEPTED_PURCHASE_ORDERS_SUCCESS,
    payload: payload,
  };
};

const getAcceptedPurchaseOrdersFailure = (error) => {
  return {
    type: GET_ACCEPTED_PURCHASE_ORDERS_FAILURE,
    payload: error,
  };
};

const getAcceptedPurchaseOrders = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAcceptedPurchaseOrdersRequest());
    let url = `${baseUrl}/purchase_order/get_released`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAcceptedPurchaseOrdersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAcceptedPurchaseOrdersFailure(err.message));
      });
  };
};

const createPurchaseOrderRequest = () => {
  return {
    type: CREATE_PURCHASE_ORDER_REQUEST,
  };
};

const createPurchaseOrderSuccess = (payload) => {
  return {
    type: CREATE_PURCHASE_ORDER_SUCCESS,
    payload: payload,
  };
};

const createPurchaseOrderFailure = (error) => {
  return {
    type: CREATE_PURCHASE_ORDER_FAILURE,
    payload: error,
  };
};

const createPurchaseOrder = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPurchaseOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPurchaseOrderFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editPurchaseOrderRequest = () => {
  return {
    type: EDIT_REQ_QTY_REQUEST,
  };
};

const editPurchaseOrderSuccess = (payload) => {
  return {
    type: EDIT_REQ_QTY_SUCCESS,
    payload: payload,
  };
};

const editPurchaseOrderFaliure = (error) => {
  return {
    type: EDIT_REQ_QTY_FAILURE,
    payload: error,
  };
};

const editPurchaseOrder = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editPurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editPurchaseOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editPurchaseOrderFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deletePurchaseOrderRequest = () => {
  return {
    type: DELETE_PURCHASE_ORDER_REQUEST,
  };
};

const deletePurchaseOrderSuccess = (payload) => {
  return {
    type: DELETE_PURCHASE_ORDER_SUCCESS,
    payload: payload,
  };
};

const deletePurchaseOrderFaliure = (error) => {
  return {
    type: DELETE_PURCHASE_ORDER_FAILURE,
    payload: error,
  };
};

const deletePurchaseOrder = (purchaseOrderId, userId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deletePurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/delete?purchaseOrderId=${purchaseOrderId}&userId=${userId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deletePurchaseOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deletePurchaseOrderFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadForPurchaseOrderRequest = () => {
  return {
    type: UPLOAD_EXCEL_FOR_PURCHASE_ORDER_REQUEST,
  };
};

const uploadForPurchaseOrderSuccess = (payload) => {
  return {
    type: UPLOAD_EXCEL_FOR_PURCHASE_ORDER_SUCCESS,
    payload: payload,
  };
};

const uploadForPurchaseOrderFailure = (error) => {
  return {
    type: UPLOAD_EXCEL_FOR_PURCHASE_ORDER_FAILURE,
    payload: error,
  };
};

const uploadForPurchaseOrder = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadForPurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadForPurchaseOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadForPurchaseOrderFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const publishPurchaseOrderRequest = () => {
  return {
    type: PUBLISH_PURCHASE_ORDER_REQUEST,
  };
};

const publishPurchaseOrderSuccess = (payload) => {
  return {
    type: PUBLISH_PURCHASE_ORDER_SUCCESS,
    payload: payload,
  };
};

const publishPurchaseOrderFailure = (error) => {
  return {
    type: PUBLISH_PURCHASE_ORDER_FAILURE,
    payload: error,
  };
};

const publishPurchaseOrder = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(publishPurchaseOrderRequest());
    let url = `${baseUrl}/purchase_order/release`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(publishPurchaseOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(publishPurchaseOrderFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};
const getNotAcceptedPurchasedOrdersRequest = () => {
  return {
    type: GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST,
  };
};

const getNotAcceptedPurchasedOrdersSuccess = (payload) => {
  return {
    type: GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS,
    payload: payload,
  };
};

const getNotAcceptedPurchasedOrdersFailure = (error) => {
  return {
    type: GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE,
    payload: error,
  };
};

const getNotAcceptedPurchasedOrders = (emailId) => {
  return (dispatch) => {
    dispatch(getNotAcceptedPurchasedOrdersRequest());
    let url = `${baseUrl}/vendor/get_not_accepted_purchase_order_without_itemList?email=${emailId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getNotAcceptedPurchasedOrdersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getNotAcceptedPurchasedOrdersFailure(err.message));
      });
  };
};

// const getPurchasedOrderStatusRequest = () => {
//   return {
//     type: GET_PURCHASEDORDERSTATUS_REQUEST,
//   };
// };

// const getPurchasedOrderStatusSuccess = (payload) => {
//   return {
//     type: GET_PURCHASEDORDERSTATUS_SUCCESS,
//     payload: payload,
//   };
// };

// const getPurchasedOrderStatusFailure = (error) => {
//   return {
//     type: GET_PURCHASEDORDERSTATUS_FAILURE,
//     payload: error,
//   };
// };

// const getPurchasedOrderStatus = (orderNumber, vendorId) => {
//   return (dispatch) => {
//     dispatch(getPurchasedOrderStatusRequest());
//     let url = `${baseUrl}/vendor/get_is_accepted?orderNumber=${orderNumber}&vendorId=${vendorId}`;
//     axios
//       .get(url)
//       .then((response) => {
//         dispatch(getPurchasedOrderStatusSuccess(response.data));
//       })
//       .catch((err) => {
//         dispatch(getPurchasedOrderStatusFailure(err.message));
//       });
//   };
// };

const acceptPoRequest = () => {
  return {
    type: UPDATE_ACCEPTPO_REQUEST,
  };
};

const acceptPoSuccess = (payload) => {
  return {
    type: UPDATE_ACCEPTPO_SUCCESS,
    payload: payload,
  };
};

const acceptPoFaliure = (error) => {
  return {
    type: UPDATE_ACCEPTPO_FAILURE,
    payload: error,
  };
};

const acceptPo = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(acceptPoRequest());
    let url = `${baseUrl}/purchase_order/accept`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(acceptPoSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(acceptPoFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const publishPoRequest = () => {
  return {
    type: PUBLISH_PO_REQUEST,
  };
};

const publishPoSuccess = (payload) => {
  return {
    type: PUBLISH_PO_SUCCESS,
    payload: payload,
  };
};

const publishPoFaliure = (error) => {
  return {
    type: PUBLISH_PO_FAILURE,
    payload: error,
  };
};

const publishPo = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(publishPoRequest());
    let url = `${baseUrl}/purchase_order/publish`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(publishPoSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(publishPoFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getVendorCodesRequest = () => {
  return {
    type: GET_VENDOR_CODE_REQUEST,
  };
};

const getVendorCodesSuccess = (payload) => {
  return {
    type: GET_VENDOR_CODE_SUCCESS,
    payload: payload,
  };
};

const getVendorCodesFailure = (error) => {
  return {
    type: GET_VENDOR_CODE_FAILURE,
    payload: error,
  };
};

const getVendorCodes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getVendorCodesRequest());
    let url = `${baseUrl}/vendor/get_approved`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getVendorCodesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getVendorCodesFailure(err.message));
      });
  };
};

export {
  getPurchaseOrder,
  createPurchaseOrder,
  editPurchaseOrder,
  deletePurchaseOrder,
  uploadForPurchaseOrder,
  publishPurchaseOrder,
  acceptPo,
  getNotAcceptedPurchasedOrders,
  // getPurchasedOrderStatus,
  getVendorCodes,
  getReleasedPurchaseOrder,
  getAcceptedPurchaseOrders,
  publishPo,
};
