export const GET_STATION_MASTER_REQUEST = "GET_STATION_MASTER_REQUEST";
export const GET_STATION_MASTER_SUCCESS = "GET_STATION_MASTER_SUCCESS";
export const GET_STATION_MASTER_FAILURE = "GET_STATION_MASTER_FAILURE";

export const GET_STATIONS_BY_ASSEMBLYID_REQUEST = "GET_STATIONS_BY_ASSEMBLYID_REQUEST";
export const GET_STATIONS_BY_ASSEMBLYID_SUCCESS = "GET_STATIONS_BY_ASSEMBLYID_SUCCESS";
export const GET_STATIONS_BY_ASSEMBLYID_FAILURE = "GET_STATIONS_BY_ASSEMBLYID_FAILURE";

export const CREATE_STATION_REQUEST = "CREATE_STATION_REQUEST";
export const CREATE_STATION_SUCCESS = "CREATE_STATION_SUCCESS";
export const CREATE_STATION_FAILURE = "CREATE_STATION_FAILURE";

export const DELETE_STATION_REQUEST = "DELETE_STATION_REQUEST";
export const DELETE_STATION_SUCCESS = "DELETE_STATION_SUCCESS";
export const DELETE_STATION_FAILURE = "DELETE_STATION_FAILURE";

export const EDIT_STATION_REQUEST = "EDIT_STATION_REQUEST";
export const EDIT_STATION_SUCCESS = "EDIT_STATION_SUCCESS";
export const EDIT_STATION_FAILURE = "EDIT_STATION_FAILURE";
