// Dashboard components
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const StandardSwitch = ({ checked, onChange }) => {
  const theme = createTheme({});
  return (
    <ThemeProvider theme={theme}>
      <Switch checked={checked} onChange={onChange} color="success" />
    </ThemeProvider>
  );
};

export default StandardSwitch;
