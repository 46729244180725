/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

import { getSubModulesByRoleId } from "../../store";

import Cookies from "universal-cookie";

import PurchaseOrder from "./purchase-order";
import AcceptedPurchaseOrders from "./accepted-purchase-orders";

const cookies = new Cookies();

const PurchaseOrdersParent = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let purchaseOrderModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "aa4860c3-2619-4478-8005-c53efa538d66"
  );

  let acceptedPOModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "6efc7768-8b96-453b-989a-c861a5ae265a"
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
          {purchaseOrderModule && acceptedPOModule ? (
            <TabView>
              <TabPanel header="Purchase Order">
                <PurchaseOrder />
              </TabPanel>
              <TabPanel header="Accepted Purchase Orders">
                <AcceptedPurchaseOrders />
              </TabPanel>
            </TabView>
          ): !purchaseOrderModule && acceptedPOModule ? (
             
            <TabView>
               <TabPanel header="Accepted Purchase Orders">
                 <AcceptedPurchaseOrders />
               </TabPanel>
             </TabView>

           ) : purchaseOrderModule && !acceptedPOModule ? (
              
            <TabView>
              <TabPanel header="Purchase Order">
                <PurchaseOrder />
              </TabPanel>
            </TabView>
            
          ) : (
            <>
              <h4 style={{ textAlign: "center", marginTop: "6rem" }}>
                No Purchase Order Access
              </h4>
            </>)}

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(PurchaseOrdersParent);
