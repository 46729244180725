import {
  GET_PICKLISTTRACKING_FAILURE,
  GET_PICKLISTTRACKING_REQUEST,
  GET_PICKLISTTRACKING_SUCCESS,
  GET_GROUPEDPICKLIST_REQUEST,
  GET_GROUPEDPICKLIST_SUCCESS,
  GET_GROUPEDPICKLIST_FAILURE,
  GET_ITEMSBYPICKLIST_REQUEST,
  GET_ITEMSBYPICKLIST_SUCCESS,
  GET_ITEMSBYPICKLIST_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_ALLOCATE_USER_REQUEST,
  UPDATE_ALLOCATE_USER_SUCCESS,
  UPDATE_ALLOCATE_USER_FAILURE,
  GET_DOCUMENT_NUMBER_REQUEST,
  GET_DOCUMENT_NUMBER_SUCCESS,
  GET_DOCUMENT_NUMBER_FAILURE,
  GET_ITEM_BY_DOCUMENTNO_REQUEST,
  GET_ITEM_BY_DOCUMENTNO_SUCCESS,
  GET_ITEM_BY_DOCUMENTNO_FAILURE,
  GET_AVAILABLE_QTY_REQUEST,
  GET_AVAILABLE_QTY_SUCCESS,
  GET_AVAILABLE_QTY_FAILURE,
} from "./picklistTrackingTypes";

const initialStateForPickListTracking = {
  loading: false,
  pickList: [],
  error: "",
};

const getPickListTrackingReducer = (
  state = initialStateForPickListTracking,
  action
) => {
  switch (action.type) {
    case GET_PICKLISTTRACKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKLISTTRACKING_SUCCESS:
      return {
        loading: false,
        pickList: action.payload,
        error: "",
      };
    case GET_PICKLISTTRACKING_FAILURE:
      return {
        loading: false,
        pickList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForGroupedPickListTracking = {
  loading: false,
  list: [],
  error: "",
};

const getGroupedPickListTrackingReducer = (
  state = initialStateForGroupedPickListTracking,
  action
) => {
  switch (action.type) {
    case GET_GROUPEDPICKLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUPEDPICKLIST_SUCCESS:
      return {
        loading: false,
        list: action.payload,
        error: "",
      };
    case GET_GROUPEDPICKLIST_FAILURE:
      return {
        loading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLineItems = {
  loading: false,
  items: [],
  error: "",
};

const getItemsByPickListReducer = (
  state = initialStateForLineItems,
  action
) => {
  switch (action.type) {
    case GET_ITEMSBYPICKLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMSBYPICKLIST_SUCCESS:
      return {
        loading: false,
        items: action.payload,
        error: "",
      };
    case GET_ITEMSBYPICKLIST_FAILURE:
      return {
        loading: false,
        items: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForUser = {
  loading: false,
  user: [],
  error: "",
};

const getUserReducer = (state = initialStateForUser, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        error: "",
      };
    case GET_USER_FAILURE:
      return {
        loading: false,
        user: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRelease = {
  loading: false,
  user: {},
  error: "",
};

const releaseUserReducer = (state = initialStateForRelease, action) => {
  switch (action.type) {
    case UPDATE_ALLOCATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ALLOCATE_USER_SUCCESS:
      return {
        loading: true,
        user: action.payload,
        error: "",
      };
    case UPDATE_ALLOCATE_USER_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateDocument = {
  loading: false,
  document: [],
  error: "",
};

const getDocumentNumberReducer = (state = initialStateDocument, action) => {
  switch (action.type) {
    case GET_DOCUMENT_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENT_NUMBER_SUCCESS:
      return {
        loading: false,
        document: action.payload,
        error: "",
      };
    case GET_DOCUMENT_NUMBER_FAILURE:
      return {
        loading: false,
        document: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateItem = {
  loading: false,
  items: [],
  error: "",
};

const getItemByDocumentNoReducer = (state = initialStateItem, action) => {
  switch (action.type) {
    case GET_ITEM_BY_DOCUMENTNO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEM_BY_DOCUMENTNO_SUCCESS:
      return {
        loading: false,
        items: action.payload,
        error: "",
      };
    case GET_ITEM_BY_DOCUMENTNO_FAILURE:
      return {
        loading: false,
        items: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAvailableQty = {
  loading: false,
  availableQty: [],
  error: "",
};

const getAvailableQtyReducer = (
  state = initialStateForAvailableQty,
  action
) => {
  switch (action.type) {
    case GET_AVAILABLE_QTY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AVAILABLE_QTY_SUCCESS:
      return {
        loading: false,
        availableQty: action.payload,
        error: "",
      };
    case GET_AVAILABLE_QTY_FAILURE:
      return {
        loading: false,
        availableQty: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getPickListTrackingReducer,
  getGroupedPickListTrackingReducer,
  getItemsByPickListReducer,
  getUserReducer,
  releaseUserReducer,
  getDocumentNumberReducer,
  getItemByDocumentNoReducer,
  getAvailableQtyReducer,
};
