export const GET_BOM_REQUEST = "GET_BOM_REQUEST";
export const GET_BOM_SUCCESS = "GET_BOM_SUCCESS";
export const GET_BOM_FAILURE = "GET_BOM_FAILURE";
export const GET_ITEMSFORBOM_REQUEST = "GET_ITEMSFORBOM_REQUEST";
export const GET_ITEMSFORBOM_SUCCESS = "GET_ITEMSFORBOM_SUCCESS";
export const GET_ITEMSFORBOM_FAILURE = "GET_ITEMSFORBOM_FAILURE";

export const EDIT_BOM_REQUEST = "EDIT_BOM_REQUEST";
export const EDIT_BOM_SUCCESS = "EDIT_BOM_SUCCESS";
export const EDIT_BOM_FAILURE = "EDIT_BOM_FAILURE";

export const CREATE_BOM_REQUEST = "CREATE_BOM_REQUEST";
export const CREATE_BOM_SUCCESS = "CREATE_BOM_SUCCESS";
export const CREATE_BOM_FAILURE = "CREATE_BOM_FAILURE";

export const UPLOAD_EXCEL_FOR_BOM_REQUEST = "UPLOAD_EXCEL_FOR_BOM_REQUEST";
export const UPLOAD_EXCEL_FOR_BOM_SUCCESS = "UPLOAD_EXCEL_FOR_BOM_SUCCESS";
export const UPLOAD_EXCEL_FOR_BOM_FAILURE = "UPLOAD_EXCEL_FOR_BOM_FAILURE";

export const GET_ITEMSBYFGCODE_REQUEST = "GET_ITEMSBYFGCODE_REQUEST";
export const GET_ITEMSBYFGCODE_SUCCESS = "GET_ITEMSBYFGCODE_SUCCESS";
export const GET_ITEMSBYFGCODE_FAILURE = "GET_ITEMSBYFGCODE_FAILURE";

export const GET_FG_BY_ASSEMBLYTYPE_SUCCESS = "GET_FG_BY_ASSEMBLYTYPE_SUCCESS";
export const GET_FG_BY_ASSEMBLYTYPE_REQUEST = "GET_FG_BY_ASSEMBLYTYPE_REQUEST";
export const GET_FG_BY_ASSEMBLYTYPE_FAILURE = "GET_FG_BY_ASSEMBLYTYPE_FAILURE";

export const GET_ASSEMBLY_BY_ASSEMBLYTYPE_SUCCESS = "GET_ASSEMBLY_BY_ASSEMBLYTYPE_SUCCESS";
export const GET_ASSEMBLY_BY_ASSEMBLYTYPE_REQUEST = "GET_ASSEMBLY_BY_ASSEMBLYTYPE_REQUEST";
export const GET_ASSEMBLY_BY_ASSEMBLYTYPE_FAILURE = "GET_ASSEMBLY_BY_ASSEMBLYTYPE_FAILURE";

