/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  CREATE_USER_DETAILS_REQUEST,
  CREATE_USER_DETAILS_SUCCESS,
  CREATE_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  DELETE_USER_DETAILS_REQUEST,
  DELETE_USER_DETAILS_SUCCESS,
  DELETE_USER_DETAILS_FAILURE,
} from "./userDetailsTypes";
import axios from "axios";
import Config from "../../../config/index";
let userManagementUrl = Config.userManagementUrl;
// let userManagementUrl = `http://13.71.2.248:1338/api/v2/users_management`;

const getUsersDetailsRequest = () => {
  return {
    type: GET_USER_DETAILS_REQUEST,
  };
};

const getUsersDetailsSuccess = (payload) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};

const getUsersDetailsFaliure = (error) => {
  return {
    type: GET_USER_DETAILS_FAILURE,
    payload: error,
  };
};

const getUsersDetails = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUsersDetailsRequest());
    let url = `${userManagementUrl}/user/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUsersDetailsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUsersDetailsFaliure(err.message));
      });
  };
};

const createUserDetailsRequest = () => {
  return {
    type: CREATE_USER_DETAILS_REQUEST,
  };
};

const createUserDetailsSuccess = (payload) => {
  return {
    type: CREATE_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};

const createUserDetailsFaliure = (error) => {
  return {
    type: CREATE_USER_DETAILS_FAILURE,
    payload: error,
  };
};

const createUserDetails = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createUserDetailsRequest());
    let url = `${userManagementUrl}/user`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createUserDetailsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createUserDetailsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateUserDetailsRequest = () => {
  return {
    type: UPDATE_USER_DETAILS_REQUEST,
  };
};

const updateUserDetailsSuccess = (payload) => {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};

const updateUserDetailsFaliure = (error) => {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
    payload: error,
  };
};

const updateUserDetails = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateUserDetailsRequest());
    let url = `${userManagementUrl}/user/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateUserDetailsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserDetailsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteUserDetailsRequest = () => {
  return {
    type: DELETE_USER_DETAILS_REQUEST,
  };
};

const deleteUserDetailsSuccess = (payload) => {
  return {
    type: DELETE_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};

const deleteUserDetailsFaliure = (error) => {
  return {
    type: DELETE_USER_DETAILS_FAILURE,
    payload: error,
  };
};

const deleteUserDetails = (email, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteUserDetailsRequest());
    let url = `${userManagementUrl}/user/delete?email=${email}`;
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deleteUserDetailsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteUserDetailsFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getUsersDetails,
  createUserDetails,
  updateUserDetails,
  deleteUserDetails,
};
