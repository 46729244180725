import {
  GET_ALLGRN_REQUEST,
  GET_ALLGRN_SUCCESS,
  GET_ALLGRN_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS,
  GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE,
  UPLOADCSV_FOR_GRN_REQUEST,
  UPLOADCSV_FOR_GRN_SUCCESS,
  UPLOADCSV_FOR_GRN_FAILURE,
  CREATE_GRN_REQUEST,
  CREATE_GRN_SUCCESS,
  CREATE_GRN_FAILURE,
  GET_GRNITEMS_REQUEST,
  GET_GRNITEMS_SUCCESS,
  GET_GRNITEMS_FAILURE,
} from "./grnTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getAllGRNRequest = () => {
  return {
    type: GET_ALLGRN_REQUEST,
  };
};

const getAllGRNSuccess = (payload) => {
  return {
    type: GET_ALLGRN_SUCCESS,
    payload: payload,
  };
};

const getAllGRNFailure = (error) => {
  return {
    type: GET_ALLGRN_FAILURE,
    payload: error,
  };
};

const getAllGRN = (flag) => {
  // let headers = {
  //   headers: {
  //     Authorization: `Bearer ${jwtToken}`,
  //   },
  // };
  return (dispatch) => {
    dispatch(getAllGRNRequest());
    let url;
    if (flag == 1) {
      url = `${baseUrl}/grn`;
    }

    if (flag == 2) {
      url = `${baseUrl}/grn/grpd`;
    }

    axios
      .get(url)
      .then((response) => {
        dispatch(getAllGRNSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAllGRNFailure(err.message));
      });
  };
};

const getLineItemsByGrnRequest = () => {
  return {
    type: GET_LINEITEMSBYGRN_REQUEST,
  };
};

const getLineItemsByGrnSuccess = (payload) => {
  return {
    type: GET_LINEITEMSBYGRN_SUCCESS,
    payload: payload,
  };
};

const getLineItemsByGrnFailure = (error) => {
  return {
    type: GET_LINEITEMSBYGRN_FAILURE,
    payload: error,
  };
};

const getLineItemsByGrn = (grn, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLineItemsByGrnRequest());
    let url = `${baseUrl}/items_by_grn?grn_number=${grn}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLineItemsByGrnSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLineItemsByGrnFailure(err.message));
      });
  };
};

const getItemsByGrnRequest = () => {
  return {
    type: GET_GRNITEMS_REQUEST,
  };
};

const getItemsByGrnSuccess = (payload) => {
  return {
    type: GET_GRNITEMS_SUCCESS,
    payload: payload,
  };
};

const getItemsByGrnFailure = (error) => {
  return {
    type: GET_GRNITEMS_FAILURE,
    payload: error,
  };
};

const getItemsByGrn = (grnNumber, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getItemsByGrnRequest());
    let url = `${baseUrl}/grn/item_by_grn?grn_number=${grnNumber}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsByGrnSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsByGrnFailure(err.message));
      });
  };
};

const getAllGRNDataForDownloadRequest = () => {
  return {
    type: GET_ALLGRN_DATA_FOR_DOWNLOAD_REQUEST,
  };
};

const getAllGRNDataForDownloadSuccess = (payload) => {
  return {
    type: GET_ALLGRN_DATA_FOR_DOWNLOAD_SUCCESS,
    payload: payload,
  };
};

const getAllGRNDataForDownloadFailure = (error) => {
  return {
    type: GET_ALLGRN_DATA_FOR_DOWNLOAD_FAILURE,
    payload: error,
  };
};

const getAllGRNDataForDownload = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllGRNDataForDownloadRequest());
    // let url = `${baseUrl}/grn`;
    let url = `${baseUrl}/download_grn_data`;
    // let url = `${baseUrl}/v2/grn?page_number=3&rows_per_page=5&search=`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllGRNDataForDownloadSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAllGRNDataForDownloadFailure(err.message));
      });
  };
};

const uploadCsvForGrnRequest = () => {
  return {
    type: UPLOADCSV_FOR_GRN_REQUEST,
  };
};

const uploadCsvForGrnSuccess = (payload) => {
  return {
    type: UPLOADCSV_FOR_GRN_SUCCESS,
    payload: payload,
  };
};

const uploadCsvForGrnFailure = (error) => {
  return {
    type: UPLOADCSV_FOR_GRN_FAILURE,
    payload: error,
  };
};

const uploadCsvForGrn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadCsvForGrnRequest());
    let url = `${baseUrl}/grn/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadCsvForGrnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvForGrnFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const createGrnRequest = () => {
  return {
    type: CREATE_GRN_REQUEST,
  };
};

const createGrnSuccess = (payload) => {
  return {
    type: CREATE_GRN_SUCCESS,
    payload: payload,
  };
};

const createGrnFailure = (error) => {
  return {
    type: CREATE_GRN_FAILURE,
    payload: error,
  };
};

const createGrn = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createGrnRequest());
    let url = `${baseUrl}/grn`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createGrnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createGrnFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getAllGRN,
  getLineItemsByGrn,
  getAllGRNDataForDownload,
  uploadCsvForGrn,
  createGrn,
  getItemsByGrn,
};
