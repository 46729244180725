export const GET_REJECTIONS_REQUEST = "GET_REJECTIONS_REQUEST";
export const GET_REJECTIONS_SUCCESS = "GET_REJECTIONS_SUCCESS";
export const GET_REJECTIONS_FAILURE = "GET_REJECTIONS_FAILURE";

export const GET_ACCEPTED_REJECTIONS_REQUEST =
  "GET_ACCEPTED_REJECTIONS_REQUEST";
export const GET_ACCEPTED_REJECTIONS_SUCCESS =
  "GET_ACCEPTED_REJECTIONS_SUCCESS";
export const GET_ACCEPTED_REJECTIONS_FAILURE =
  "GET_ACCEPTED_REJECTIONS_FAILURE";

export const REJECTIONS_BY_SUID_REQUEST ="REJECTIONS_BY_SUID_REQUEST";
export const REJECTIONS_BY_SUID_SUCCESS ="REJECTIONS_BY_SUID_SUCCESS";
export const REJECTIONS_BY_SUID_FAILURE ="REJECTIONS_BY_SUID_FAILURE";

export const ACCEPTED_BY_SUID_REQUEST ="ACCEPTED_BY_SUID_REQUEST";
export const ACCEPTED_BY_SUID_SUCCESS ="ACCEPTED_BY_SUID_SUCCESS";
export const ACCEPTED_BY_SUID_FAILURE ="ACCEPTED_BY_SUID_FAILURE";

export const CREATE_REJECTION_REQUEST ="CREATE_REJECTION_REQUEST";
export const CREATE_REJECTION_SUCCESS ="CREATE_REJECTION_SUCCESS";
export const CREATE_REJECTION_FAILURE ="CREATE_REJECTION_FAILURE";


export const GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_REQUEST = "GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_REQUEST";
export const GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_SUCCESS = "GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_SUCCESS";
export const GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_FAILURE = "GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_FAILURE";


export const PUT_RELEASE_REJECTIONS_REQUEST = "PUT_RELEASE_REJECTIONS_REQUEST";
export const PUT_RELEASE_REJECTIONS_SUCCESS = "PUT_RELEASE_REJECTIONS_SUCCESS";
export const PUT_RELEASE_REJECTIONS_FAILURE = "PUT_RELEASE_REJECTIONS_FAILURE";