export const GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST =
  "GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST";
export const GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS =
  "GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS";
export const GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE =
  "GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE";

export const TRANSFERORDER_INDIVIDUALPRINT_REQUEST =
  "TRANSFERORDER_INDIVIDUALPRINT_REQUEST";
export const TRANSFERORDER_INDIVIDUALPRINT_SUCCESS =
  "TRANSFERORDER_INDIVIDUALPRINT_SUCCESS";
export const TRANSFERORDER_INDIVIDUALPRINT_FAILURE =
  "TRANSFERORDER_INDIVIDUALPRINT_FAILURE";

export const TRANSFERORDER_PACKAGEPRINT_REQUEST =
  "TRANSFERORDER_PACKAGEPRINT_REQUEST";
export const TRANSFERORDER_PACKAGEPRINT_SUCCESS =
  "TRANSFERORDER_PACKAGEPRINT_SUCCESS";
export const TRANSFERORDER_PACKAGEPRINT_FAILURE =
  "TRANSFERORDER_PACKAGEPRINT_FAILURE";
