import {
  GET_MAPPEDSTATIONSBYASSEMBLY_REQUEST,
  GET_MAPPEDSTATIONSBYASSEMBLY_SUCCESS,
  GET_MAPPEDSTATIONSBYASSEMBLY_FAILURE,
} from "./assemblyViewTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getMappedStationsByAssemblyRequest = () => {
  return {
    type: GET_MAPPEDSTATIONSBYASSEMBLY_REQUEST,
  };
};

const getMappedStationsByAssemblySuccess = (payload) => {
  return {
    type: GET_MAPPEDSTATIONSBYASSEMBLY_SUCCESS,
    payload: payload,
  };
};

const getMappedStationsByAssemblyFailure = (error) => {
  return {
    type: GET_MAPPEDSTATIONSBYASSEMBLY_FAILURE,
    payload: error,
  };
};

const getMappedStationsByAssembly = (assemblyId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMappedStationsByAssemblyRequest());
    let url = `${baseUrl}/station_item/get_mapping_by_assembly_id?assemblyId=${assemblyId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMappedStationsByAssemblySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getMappedStationsByAssemblyFailure(err.message));
      });
  };
};

export { getMappedStationsByAssembly };
