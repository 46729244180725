export const GET_ASSETMASTER_REQUEST = "GET_ASSETMASTER_REQUEST";
export const GET_ASSETMASTER_SUCCESS = "GET_ASSETMASTER_SUCCESS";
export const GET_ASSETMASTER_FAILURE = "GET_ASSETMASTER_FAILURE";

export const GET_ASSETTYPES_REQUEST = "GET_ASSETTYPES_REQUEST";
export const GET_ASSETTYPES_SUCCESS = "GET_ASSETTYPES_SUCCESS";
export const GET_ASSETTYPES_FAILURE = "GET_ASSETTYPES_FAILURE";

export const GET_MAPPEDBIN_RFID_REQUEST = "GET_MAPPEDBIN_RFID_REQUEST";
export const GET_MAPPEDBIN_RFID_SUCCESS = "GET_MAPPEDBIN_RFID_SUCCESS";
export const GET_MAPPEDBIN_RFID_FAILURE = "GET_MAPPEDBIN_RFID_FAILURE";

export const CREATE_NEWASSET_REQUEST = "CREATE_NEWASSET_REQUEST";
export const CREATE_NEWASSET_SUCCESS = "CREATE_NEWASSET_SUCCESS";
export const CREATE_NEWASSET_FAILURE = "CREATE_NEWASSET_FAILURE";

export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

export const MAP_ASSET_REQUEST = "MAP_ASSET_REQUEST";
export const MAP_ASSET_SUCCESS = "MAP_ASSET_SUCCESS";
export const MAP_ASSET_FAILURE = "MAP_ASSET_FAILURE";

export const GET_UNMAPPED_BINS_REQUEST = "GET_UNMAPPED_BINS_REQUEST";
export const GET_UNMAPPED_BINS_SUCCESS = "GET_UNMAPPED_BINS_SUCCESS";
export const GET_UNMAPPED_BINS_FAILURE = "GET_UNMAPPED_BINS_FAILURE";

export const GET_UNMAPPED_PALLETS_REQUEST = "GET_UNMAPPED_PALLETS_REQUEST";
export const GET_UNMAPPED_PALLETS_SUCCESS = "GET_UNMAPPED_PALLETS_SUCCESS";
export const GET_UNMAPPED_PALLETS_FAILURE = "GET_UNMAPPED_PALLETS_FAILURE";

export const UPLOAD_EXCEL_FOR_ASSETMASTER_REQUEST = "UPLOAD_EXCEL_FOR_ASSETMASTER_REQUEST";
export const UPLOAD_EXCEL_FOR_ASSETMASTER_SUCCESS = "UPLOAD_EXCEL_FOR_ASSETMASTER_SUCCESS";
export const UPLOAD_EXCEL_FOR_ASSETMASTER_FAILURE = "UPLOAD_EXCEL_FOR_ASSETMASTER_FAILURE";