import { GET_ITEM_TYPE_FAILURE, GET_ITEM_TYPE_REQUEST, GET_ITEM_TYPE_SUCCESS, GET_ITEMSTATUS_FAILURE, GET_ITEMSTATUS_REQUEST, GET_ITEMSTATUS_SUCCESS } from "./itemStatusTypes";

  const initialStateForType = {
    loading: false,
    itemTypes: [],
    error: "",
  };
  
  const getItemTypeReducer = (state = initialStateForType, action) => {
    switch (action.type) {
      case GET_ITEM_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_ITEM_TYPE_SUCCESS:
        return {
          loading: false,
          itemTypes: action.payload,
          error: "",
        };
      case GET_ITEM_TYPE_FAILURE:
        return {
          loading: false,
          itemTypes: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const initialStateForStatus = {
    loading: false,
    itemStatus: [],
    error: "",
  };
  
  const getItemStatusReducer = (state = initialStateForStatus, action) => {
    switch (action.type) {
      case GET_ITEMSTATUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_ITEMSTATUS_SUCCESS:
        return {
          loading: false,
          itemStatus: action.payload,
          error: "",
        };
      case GET_ITEMSTATUS_FAILURE:
        return {
          loading: false,
          itemStatus: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export { getItemTypeReducer,getItemStatusReducer};
  