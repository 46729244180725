export const GET_CATEGORY_MAPPING_REQUEST = "GET_CATEGORY_MAPPING_REQUEST";
export const GET_CATEGORY_MAPPING_SUCCESS = "GET_CATEGORY_MAPPING_SUCCESS";
export const GET_CATEGORY_MAPPING_FAILURE = "GET_CATEGORY_MAPPING_FAILURE";

export const CREATE_CATEGORY_MAPPING_REQUEST =
  "CREATE_CATEGORY_MAPPING_REQUEST";
export const CREATE_CATEGORY_MAPPING_SUCCESS =
  "CREATE_CATEGORY_MAPPING_SUCCESS";
export const CREATE_CATEGORY_MAPPING_FAILURE =
  "CREATE_CATEGORY_MAPPING_FAILURE";

export const GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_REQUEST =
  "GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_REQUEST";
export const GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_SUCCESS =
  "GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_SUCCESS";
export const GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_FAILURE =
  "GET_LEVEL1_BY_LEVEL_FOR_CATEGORY_FAILURE";

export const GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_REQUEST =
  "GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_REQUEST";
export const GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_SUCCESS =
  "GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_SUCCESS";
export const GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_FAILURE =
  "GET_LEVEL2_BY_LEVEL_FOR_CATEGORY_FAILURE";

export const GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_REQUEST =
  "GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_REQUEST";
export const GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_SUCCESS =
  "GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_SUCCESS";
export const GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_FAILURE =
  "GET_LEVEL3_BY_LEVEL_FOR_CATEGORY_FAILURE";

export const GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_REQUEST =
  "GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_REQUEST";
export const GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_SUCCESS =
  "GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_SUCCESS";
export const GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_FAILURE =
  "GET_LEVEL4_BY_LEVEL_FOR_CATEGORY_FAILURE";

export const GET_ACN_FOR_CATEGORY_REQUEST = "GET_ACN_REQUEST";
export const GET_ACN_FOR_CATEGORY_SUCCESS = "GET_ACN_SUCCESS";
export const GET_ACN_FOR_CATEGORY_FAILURE = "GET_ACN_FAILURE";

export const GET_CATEGORYITEMS_REQUEST = "GET_CATEGORYITEMS_REQUEST";
export const GET_CATEGORYITEMS_SUCCESS = "GET_CATEGORYITEMS_SUCCESS";
export const GET_CATEGORYITEMS_FAILURE = "GET_CATEGORYITEMS_FAILURE";
