import {
  GET_MAPPEDSTATIONSBYASSEMBLY_REQUEST,
  GET_MAPPEDSTATIONSBYASSEMBLY_SUCCESS,
  GET_MAPPEDSTATIONSBYASSEMBLY_FAILURE,
} from "./assemblyViewTypes";

const initialState = {
  loading: false,
  stationsByAssembly: {},
  error: "",
};

const mappedStationsByAssemblyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAPPEDSTATIONSBYASSEMBLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MAPPEDSTATIONSBYASSEMBLY_SUCCESS:
      return {
        loading: false,
        stationsByAssembly: action.payload,
        error: "",
      };
    case GET_MAPPEDSTATIONSBYASSEMBLY_FAILURE:
      return {
        loading: false,
        stationsByAssembly: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { mappedStationsByAssemblyReducer };
