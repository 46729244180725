/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Functions from store
import {
  getSubModulesByRoleId,
  getSuids,
  getWarehouses,
  getWmsStock,
} from "../../../store";

import { CSVLink } from "react-csv";

// Cookies
import Cookies from "universal-cookie";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();
const WmsStock = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  warehouses,
  getWarehouses,
  getWmsStock,
  getWmsStockReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getWarehouses(jwtToken);
  }, []);
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [selectedAllStock, setSelectedAllStock] = useState("");

  useEffect(() => {
    getWmsStock(warehouseIdSelected?.value, selectedAllStock?.value, jwtToken);
  }, [warehouseIdSelected, selectedAllStock]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let wmsStockModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "99dfc411-81a6-4609-942c-d1a0aee2cc79"
  );

  let viewWmsStockOccupancy = wmsStockModule
    ? wmsStockModule.actionId.includes(
        "1d97c139-638e-4d1b-a395-b32e731f4287"
      )
    : null;

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDescription" },
      { Header: "Category Code", accessor: "categoryCode" },
      { Header: "Control Type", accessor: "controlType" },
      { Header: "Qty", accessor: "qty" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getWmsStockReducer.warehouseOccupancy.data
      ? getWmsStockReducer.warehouseOccupancy.data
      : [];
    setLoading(getWmsStockReducer.loading);
    data?.map((finishedGoods) => {
      const qtyDisplay = finishedGoods.qty === 0 ? "NA" : finishedGoods.qty;
      const uomDisplay = finishedGoods.qty === 0 ? "" : finishedGoods.uom;
      const finishedGoodsObject = {
        itemCode: finishedGoods.itemCode,
        itemDescription: finishedGoods.itemDescription,
        categoryCode: finishedGoods.categoryCode,
        controlType: finishedGoods.controlType,
        qty: `${qtyDisplay} ${uomDisplay}`,
        controlType:
          (finishedGoods.controlType === 0 && `No Control`) ||
          (finishedGoods.controlType === 1 && `Serial Control`) ||
          (finishedGoods.controlType === 2 && `Lot Control`),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    viewWmsStockOccupancy &&
    setState({ ...state, rows: tempFinishedGoods });
  }, [getWmsStockReducer]);

  const headers = [
    {
      label: "itemCode",
      key: "itemCode",
    },
    {
      label: "itemDescription",
      key: "itemDescription",
    },
    { label: "categoryCode", key: "categoryCode" },
    { label: "controlType", key: "controlType" },
    { label: "qty", key: "qty" },
  ];

  const csvLink = {
    filename: "WMS Stock Report.csv",
    headers: headers,
    data: rows,
  };
  const allStockOptions = [
    { label: "All Stock", value: true },
    { label: "Not Available", value: false },
  ];

  const handleStockChange = (selectedOption) => {
    setSelectedAllStock(selectedOption);
  };
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        label: warehouse.warehouseCode,
        value: warehouse.warehouseCode,
        // key: warehouse.warehouseCode,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);
  const handleChangeWarehouse = (selectedData) => {
    setWarehouseIdSelected(selectedData);
    setSelectedAllStock("");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-3}
                mt={-2}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", lg: "row" }}
                      alignItems="center"
                      width="100%"
                    >
                      <MDBox
                        width={{ xs: "100%", lg: "200px" }}
                        mb={{ xs: 2, lg: 0 }}
                        mr={{ lg: 2 }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          List of Warehouses
                        </MDTypography>
                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                          autoComplete="off"
                          value={warehouseIdSelected}
                          options={warehouseOptions}
                          onChange={handleChangeWarehouse}
                        />
                      </MDBox>
                      <MDBox
                        width={{ xs: "100%", lg: "200px" }}
                        mb={{ xs: 2, lg: 0 }}
                        mr={{ lg: 2 }}
                      >
                        <MDTypography variant="h6" style={{ fontWeight: 500 }}>
                          All Stock
                        </MDTypography>

                        <Select
                          isClearable
                          placeholder="Choose One..."
                          className="select-css-for-filter"
                          maxMenuHeight={130}
                          autoComplete="off"
                          value={selectedAllStock}
                          options={allStockOptions}
                          onChange={handleStockChange}
                        />
                      </MDBox>

                      <MDBox
                        style={{
                          marginTop: "25px",
                          marginLeft: { xs: 0, lg: "8px" },
                        }}
                      >
                        <CSVLink {...csvLink}>
                          <DownloadDataButton
                            type="button"
                            variant="outlined"
                            color="success"
                            iconOnly
                            tooltip="Download All Data"
                          >
                            <Icon>download</Icon>
                          </DownloadDataButton>
                        </CSVLink>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    hideColumns={["", "asnCode", "vendorCodeName"]}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ToastContainer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,

    getSuidsReducer: state.getSuidsReducer,
    warehouses: state.warehouses,
    getWmsStockReducer: state.getWmsStockReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWarehouses: (jwtToken) => dispatch(getWarehouses(jwtToken)),
    getWmsStock: (warehouseId, status, jwtToken) =>
      dispatch(getWmsStock(warehouseId, status, jwtToken)),

    getSuids: (itemId, jwtToken) => dispatch(getSuids(itemId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(WmsStock);
