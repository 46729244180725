/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

// Functions from store
import { getFg } from "../../store";

const FgHomePageCard = ({ getFg, fgHomePage }) => {
  useEffect(() => {
    getFg();
  }, []);

  const [putawayNotDone, setPutawayNotDone] = useState(0);
  const [lessThanThirty, setLessThanThirty] = useState(0);
  const [thirtyOneToSixty, setThirtyOneToSixty] = useState(0);
  const [sixtyOneToNinety, setSixtyOneToNinety] = useState(0);
  const [greaterThanNinety, setGreaterThanNinety] = useState(0);
  const [expired, setExpired] = useState(0);

  useEffect(() => {
    setLessThanThirty(fgHomePage.fg.lessThanThirty);
    setThirtyOneToSixty(fgHomePage.fg.thirtyOneToSixty);
    setSixtyOneToNinety(fgHomePage.fg.sixtyOneToNinety);
    setGreaterThanNinety(fgHomePage.fg.greaterThanNinety);
    setExpired(fgHomePage.fg.expired);
    setPutawayNotDone(fgHomePage.fg.putawayNotDone);
  }, [fgHomePage]);

  const chartData = {
    labels: ["< 30 *", "31-60 *", "61-90 *", "> 90 *", "Expired", "Putaway ND"],
    datasets: {
      label: "FG",
      data: [
        lessThanThirty,
        thirtyOneToSixty,
        sixtyOneToNinety,
        greaterThanNinety,
        expired,
        putawayNotDone,
      ],
    },
  };

  return (
    <ReportsBarChart
      height={300}
      color="primary"
      title="Finished Goods"
      chart={chartData}
      description="* in days"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fgHomePage: state.getFinishedGoodsHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFg: () => dispatch(getFg()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FgHomePageCard);
