// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  appearance: none;
  width: 250px;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #f25f5c;
}

.strength-Fair::-webkit-progress-value {
  background-color: #ffe066;
}

.strength-Good::-webkit-progress-value {
  background-color: #247ba0;
}

.strength-Strong::-webkit-progress-value {
  background-color: green;
}
`, "",{"version":3,"sources":["webpack://./src/utils/PasswordStrengthMeter.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;CAgBC;;AAED;EACE,gBAAgB;AAClB;;AAEA;EAEE,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gDAAgD;AAClD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["/*********************\n * Mobillor Technologies Pvt. ltd. CONFIDENTIAL\n * __________________\n * \n *  Mobillor Technologies Pvt. Ltd. \n *  All Rights Reserved.\n * \n * NOTICE:  All information contained herein is, and remains\n * the property of Mobillor Technologies Pvt. Ltd.  \n * The intellectual and technical concepts contained\n * herein are proprietary to Mobillor Technologies Pvt. Ltd.\n * may be covered by Rebublic Of India and Foreign Patents,\n * patents in process, and are protected by trade secret or copyright law.\n * Dissemination of this information or reproduction of this material\n * is strictly forbidden unless prior written permission is obtained\n * from Mobillor Technologies Pvt. Ltd.\n*/\n\n.password-strength-meter {\n  text-align: left;\n}\n\n.password-strength-meter-progress {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 250px;\n  height: 8px;\n}\n\n.password-strength-meter-progress::-webkit-progress-bar {\n  background-color: #eee;\n  border-radius: 3px;\n}\n\n.password-strength-meter-label {\n  font-size: 14px;\n}\n\n.password-strength-meter-progress::-webkit-progress-value {\n  border-radius: 2px;\n  background-size: 35px 20px, 100% 100%, 100% 100%;\n}\n\n.strength-Weak::-webkit-progress-value {\n  background-color: #f25f5c;\n}\n\n.strength-Fair::-webkit-progress-value {\n  background-color: #ffe066;\n}\n\n.strength-Good::-webkit-progress-value {\n  background-color: #247ba0;\n}\n\n.strength-Strong::-webkit-progress-value {\n  background-color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
