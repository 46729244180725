/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "utils/TextError";
import { format, addMinutes } from "date-fns";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDInput from "components/MDInput";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getSubModulesByRoleId,
  getPurchaseOrder,
  createPurchaseOrder,
  editPurchaseOrder,
  deletePurchaseOrder,
  uploadForPurchaseOrder,
  publishPurchaseOrder,
  getItemMaster,
  getVendorCodes,
} from "../../../store";

// React-Select component and styling

import "../../../index.css";

// config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import EditButton from "components/standardized-components/EditButton";
import UploadButton from "components/standardized-components/UploadButton";
import DownloadButton from "components/standardized-components/DownloadButton";
import DownloadDataButton from "components/standardized-components/DownloadDataButton";
import DeleteButton from "components/standardized-components/DeleteButton";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();
const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const styleForCreatePo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,

  width: { xs: 350, lg: 1130 },
  height: "fit-content",
  maxHeight: "90%",
  overflow: "scroll",
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForRelease = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const PurchaseOrder = ({
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
  createPurchaseOrder,
  editPurchaseOrder,
  deletePurchaseOrder,
  publishPurchaseOrder,
  uploadForPurchaseOrder,
  getPurchaseOrder,
  getVendorCodes,
  getPurchaseOrderReducer,
  itemMaster,
  getItemMaster,
  getVendorCodeReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;

  let roleId = loginDetails.data.roleId;
  let userId = loginDetails.data.userId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let purchaseOrderModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "aa4860c3-2619-4478-8005-c53efa538d66"
  );
  let createAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "dbee5e2e-a68d-4cbf-99bd-817f09cc20dc"
      )
    : null;
  let viewAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "16d13a65-675a-493c-b856-3e5b0fd96fc0"
      )
    : null;
  let uploadAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "7aa71f3a-219c-4295-b561-219cf378e953"
      )
    : null;

  let downloadAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "cb45caa1-e2c4-421a-848e-e1bd82d2bcba"
      )
    : null;
  let editAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "e83c5627-9334-4702-bc8c-26092e94e6ec"
      )
    : null;
  let deleteAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "0b0369bb-e87b-4dc0-9276-5fe43a16f1a4"
      )
    : null;
  let releaseAccess = purchaseOrderModule
    ? purchaseOrderModule.actionId.includes(
        "9097e31f-3a9c-4c7d-9679-a140d24ae7e8"
      )
    : null;

  useEffect(() => {
    getPurchaseOrder(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "PO No.", accessor: "purchaseOrderNumber" },
      { Header: "PO LineNo.", accessor: "purchaseOrderLineNumber" },
      // { Header: "Part Code/Description", accessor: "partDesc" },
      { Header: "Vendor Code", accessor: "vendorCode" },
      { Header: "Item code", accessor: "itemCode" },
      { Header: "Po Date", accessor: "date" },
      { Header: "Qty", accessor: "qty" },
      { Header: "Unloading Point", accessor: "unloadingPoint" },
      { Header: "Delivery Plant", accessor: "deliveryPlant" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        cannotSearch: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [errorMsgForCreate, setErrorMsgForCreate] = useState("");
  const [errorMsgForUpdate, setErrorMsgForUpdate] = useState("");
  const [errorMsgForDelete, setErrorMsgForDelete] = useState("");
  const [isErrorForCreateOrder, setIsErrorForCreateOrder] = useState(false);
  const [isSuccessForCreateOrder, setIsSuccessForCreateOrder] = useState(false);

  useEffect(() => {
    if (isErrorForCreateOrder) {
      setTimeout(() => {
        setIsErrorForCreateOrder(false);
      }, 3000);
    }
  }, [isErrorForCreateOrder]);

  useEffect(() => {
    if (isSuccessForCreateOrder) {
      setTimeout(() => {
        setIsSuccessForCreateOrder(false);
      }, 3000);
    }
  }, [isSuccessForCreateOrder]);
  const [isErrorRelease, setIsErrorRelease] = useState(false);
  const [isSuccessRelease, setIsSuccessRelease] = useState(false);

  useEffect(() => {
    if (isErrorRelease) {
      setTimeout(() => {
        setIsErrorRelease(false);
      }, 3000);
    }
  }, [isErrorRelease]);

  useEffect(() => {
    if (isSuccessRelease) {
      setTimeout(() => {
        setIsSuccessRelease(false);
      }, 3000);
    }
  }, [isSuccessRelease]);

  const [singleDataForRelease, setSingleDataForRelease] = useState({});

  const [openReleaseModal, setOpenReleaseModal] = useState(false);

  const handleOpenReleaseModal = (asset = {}) => {
    setSingleDataForRelease(asset);
    setOpenReleaseModal(true);
  };
  const handleCloseReleaseModal = () => {
    setOpenReleaseModal(false);
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let tempAsset = [];
    let data = getPurchaseOrderReducer.purchaseOrders.data
      ? getPurchaseOrderReducer.purchaseOrders.data
      : [];
    setLoading(getPurchaseOrderReducer.loading);
    data.map((purchase) => {
      const formatDate = new Date(purchase.date);

      const purchaseObject = {
        privateKey: purchase.purchaseOrderId,
        purchaseOrderNumber: purchase.purchaseOrderNumber,
        purchaseOrderLineNumber: purchase.purchaseOrderLineNumber,
        vendorCode: purchase.vendorCode,
        purchaseOrderId: purchase.purchaseOrderId,
        itemCode: purchase.itemCode,
        isReleased: purchase.isReleased,
        date: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        qty: purchase.qty,
        unloadingPoint:
          purchase.unloadingPoint == (undefined || null || "undefined")
            ? "NA"
            : purchase.unloadingPoint,
        deliveryPlant:
          purchase.deliveryPlant == (undefined || null || "undefined")
            ? "NA"
            : purchase.deliveryPlant,
        status: purchase.isReleased === 0 ? "Not Released" : "Released",
        // isEditable: purchase.isEditable,
        action: (
          <div>
            {editAccess && (
              <EditButton
                tooltip="Edit"
                disabled={purchase.isReleased === 1}
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdatePurchaseOrderModal(purchase)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
            {/* <Tooltip title="release">
              <MDButton
              // disabled={purchase.isEditable === 0}
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              
              onClick={() => handleOpenReleaseModal(purchase)}
              style={{ marginRight: "5px" }}
              >
              <Icon>forward</Icon>
              </MDButton>
            </Tooltip> */}

            {deleteAccess && (
              <DeleteButton
                tooltip="Delete"
                disabled={purchase.isReleased === 1}
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => handleOpenDeleteModal(purchase)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </div>
        ),
      };
      viewAccess && tempAsset.push(purchaseObject);
    });

    setState({ ...state, rows: tempAsset });
  }, [getPurchaseOrderReducer]);

  let typeOptions = [
    {
      label: "Bin",
      value: "bin",
    },
    {
      label: "Pallet",
      value: "pallet",
    },
  ];

  const [openCreatePoModal, setOpenCreatePoModal] = useState(false);

  const handleOpenCreatePoModal = () => {
    setOpenCreatePoModal(true);
    formikForCreatePo.setValues(initialValueForCreatePo);
    formikForCreatePo.setTouched({});
    formikForCreatePo.setErrors({});
    setSelectedItemCode("");
    setSelectedVendorCode("");

    getVendorCodes(jwtToken);
    getItemMaster(jwtToken);
  };

  const handleCloseCreateProductionModal = () => {
    setOpenCreatePoModal(false);
  };

  const initialValueForCreatePo = {
    // partCodeDes: "",
    poDate: "",
    vendorCode: "",
    itemCode: "",
    poNo: "",
    poLineNo: "",
    reqQty: "",
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of the day
  const todayStr = today.toISOString().split("T")[0];

  const validationSchemaForPo = Yup.object({
    // partCodeDes: Yup.string().required("select partCode"),
    poDate: Yup.date().min(today, "Date cannot be in the past!"),
    poNo: Yup.string().required("Enter PO No."),
    vendorCode: Yup.object().required("Select Vendor Code"),
    itemCode: Yup.object().required("Select Item Code"),
    poLineNo: Yup.string().required("Enter PO Line No."),
    reqQty: Yup.string().required("Enter a qty"),
  });

  const onSubmitForCreatePo = async (values, { resetForm }) => {
    let payload = {
      purchaseOrderNumber: values.poNo,
      purchaseOrderLineNumber: values.poLineNo,
      itemCode: selectedItemCode?.itemCode,
      itemId: selectedItemCode?.value,
      date: values.poDate,
      qty: values.reqQty,

      userId: userId,
      vendorCode: selectedVendorCode?.value,
    };

    let res = await createPurchaseOrder(payload, jwtToken);

    if (res.status) {
      setIsSuccessForCreateOrder(true);
      getPurchaseOrder(jwtToken);
      setSelectedItemCode("");
      setSelectedVendorCode("");

      resetForm();
    }
    if (!res.status) {
      setIsErrorForCreateOrder(true);
      setErrorMsgForCreate(res.data.data.msg);
    }
  };

  const formikForCreatePo = useFormik({
    initialValues: initialValueForCreatePo,
    validationSchema: validationSchemaForPo,
    onSubmit: onSubmitForCreatePo,
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    singlePurchaseOrderDataForDelete,
    setSinglePurchaseOrderDataForDelete,
  ] = useState({});

  const handleOpenDeleteModal = (asset = {}) => {
    setSinglePurchaseOrderDataForDelete(asset);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);

  useEffect(() => {
    if (isErrorDelete) {
      setTimeout(() => {
        setIsErrorDelete(false);
      }, 3000);
    }
  }, [isErrorDelete]);

  useEffect(() => {
    if (isSuccessDelete) {
      setTimeout(() => {
        setIsSuccessDelete(false);
      }, 3000);
    }
  }, [isSuccessDelete]);

  const deletePurchaseOrderData = async () => {
    let purchaseOrderId = Number(
      singlePurchaseOrderDataForDelete.purchaseOrderId
    );

    let res = await deletePurchaseOrder(purchaseOrderId, userId, jwtToken);

    if (res.status) {
      setIsSuccessDelete(true);
      getPurchaseOrder(jwtToken);

      // setTimeout(() => {
      //   handleCloseDeleteModal();
      // }, 2250);
    }
    if (!res.status) {
      setIsErrorDelete(true);
      setErrorMsgForDelete(res.data.data.msg);
    }
  };
  const [isErrorMsgForRelease, setIsErrorMsgForRelease] = useState(false);
  const [isSuccessMsgForRelease, setIsSuccessMsgForRelease] = useState(false);

  const [openUpdatePurchaseOrderModal, setOpenUpdatePurchaseOrderModal] =
    useState(false);
  const [singlePurchaseOrderDataForUpdate, setPurchaseOrderDataForUpdate] =
    useState({});
  const handleOpenUpdatePurchaseOrderModal = (Asset = {}) => {
    setPurchaseOrderDataForUpdate(Asset);
    setOpenUpdatePurchaseOrderModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateProductionModal = () => {
    setOpenUpdatePurchaseOrderModal(false);
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    reqQty: singlePurchaseOrderDataForUpdate.qty,
    eDate: singlePurchaseOrderDataForUpdate.date?.slice(0, 10),
  };

  //   const validationSchemaForUpdate = Yup.object({
  //     assetName: Yup.string().required("Enter the asset name!"),
  //     assetCode: Yup.string().required("Enter the asset code!"),
  //   });

  const [selectedItemCode, setSelectedItemCode] = useState("");

  const handleItemCodeChange = (selectedItemCode) => {
    setSelectedItemCode(selectedItemCode);
  };

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster.data ? itemMaster.itemMaster.data : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      tempItemCodeOptions.push({
        label: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        value: item.itemId,
        itemCode: item.itemCode,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [selectedVendorCode, setSelectedVendorCode] = useState("");

  const handleVendorCodeChange = (selectedItemCode) => {
    setSelectedVendorCode(selectedItemCode);
  };

  const [vendorCodeOptions, setVendorCodeOptions] = useState([]);

  useEffect(() => {
    let tempVendorCodeOptions = [];
    let data = getVendorCodeReducer.vendors.data
      ? getVendorCodeReducer.vendors.data
      : [];

    data.map((item) => {
      tempVendorCodeOptions.push({
        label: item.vendorName,
        value: item.vendorCode,
      });
    });
    setVendorCodeOptions(tempVendorCodeOptions);
  }, [getVendorCodeReducer]);

  const onSubmitForUpdate = async (values) => {
    let payload = {
      purchaseOrderId: singlePurchaseOrderDataForUpdate.purchaseOrderId,
      qty: values.reqQty,
      date: values.eDate,
      userId: userId,
    };

    let res = await editPurchaseOrder(payload, jwtToken);

    if (res.status) {
      setIsSuccessForUpdate(true);
      getPurchaseOrder(jwtToken);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
      setErrorMsgForUpdate(res.data.data.msg);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    // validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const DataUploadSuccessNotification = () =>
    toast.success("Data Uploaded Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const DataUploadFailureNotification = () =>
    toast.error("Please check the data format", {
      position: "top-center",
      autoClose: 2000,
    });

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("userId", userId);
    data.append("purchase", uploadCsvData);

    let res = await uploadForPurchaseOrder(data, jwtToken);

    if (res.status) {
      getPurchaseOrder(jwtToken);
      setUploadCsvFilter(false);
      DataUploadSuccessNotification();
    }
    if (!res.status) {
      DataUploadFailureNotification();
    }
  };

  const headers = [
    {
      label: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
    },
    {
      label: "purchaseOrderLineNumber",
      key: "purchaseOrderLineNumber",
    },
    { label: "vendorCode", key: "vendorCode" },
    { label: "itemCode", key: "itemCode" },
    { label: "date", key: "date" },
    { label: "qty", key: "qty" },
    { label: "unloadingPoint", key: "unloadingPoint" },
    { label: "deliveryPlant", key: "deliveryPlant" },
  ];

  const csvLink = {
    filename: "Purchase Order.csv",
    headers: headers,
    data: rows,
  };

  const csvLink2 = {
    filename: "Purchase Order Template.csv",
    headers: headers,
    data: [],
  };
  const [openCreatePublishModal, setOpenCreatePublishModal] = useState(false);

  const handleOpenCreatePublishModal = (data = {}) => {
    setOpenCreatePublishModal(true);
  };

  const createPublishHandler = (event, data) => {
    handleOpenCreatePublishModal(data);
  };

  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState(false);

  const handleOpenCreateReleaseModal = (data = {}) => {
    setOpenCreateReleaseModal(true);
  };

  const handleCloseCreateReleaseModal = () => {
    setOpenCreateReleaseModal(false);
  };

  const createReleaseHandler = (event, data) => {
    handleOpenCreateReleaseModal(data);
  };

  const [uncheck, setUncheck] = useState(false);

  const [selectedReleaseData, setSelectedReleaseData] = useState([]);

  const ReleaseForData = (data) => {
    setSelectedReleaseData(data);
  };
  const onClickCreateReleaseList = async () => {
    let tempArray = [];
    selectedReleaseData.map((data) => {
      const tempPayload = {
        purchaseOrderId: data.purchaseOrderId,
      };

      tempArray.push(tempPayload);
    });

    let payload = {
      userId: userId,
      purchaseOrderIds: tempArray,
    };

    let res = await publishPurchaseOrder(payload, jwtToken);

    if (res.status) {
      setUncheck(!uncheck);
      setIsSuccessRelease(true);
      getPurchaseOrder(jwtToken);

      setTimeout(() => {
        handleCloseCreateReleaseModal();
      }, 2250);
    }
    if (!res.status) {
      setIsErrorRelease(true);
      setIsErrorMsgForRelease(res.data.data.msg);
    }
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let value = selectedReleaseData.some((data) => data.isReleased === 1);
    setDisableButton(value);
  }, [selectedReleaseData]);

  return (
    <div>
      {/* <DashboardLayout>
        <DashboardNavbar /> */}

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* {createAssetAccess && ( */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={-1}
              // mb={3}
            >
              <MDBox color="text" px={2} ml={-2} mb={1}>
                {createAccess && (
                  <CreateButton
                    tooltip="Create Purchase Order"
                    color="info"
                    variant="contained"
                    type="button"
                    onClick={handleOpenCreatePoModal}
                    style={{ marginRight: "10px" }}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                )}

                <CSVLink {...csvLink2}>
                  <DownloadButton
                    tooltip="Download Template"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="error"
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </DownloadButton>
                </CSVLink>

                {uploadAccess && (
                  <UploadButton
                    tooltip="Upload Purchase Order"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    variant="outlined"
                    color="info"
                    iconOnly
                    onClick={uploadCsvFilterHandler}
                  >
                    <Icon>upload</Icon>
                  </UploadButton>
                )}

                {downloadAccess && (
                  <CSVLink {...csvLink}>
                    <DownloadDataButton
                      tooltip="Download Purchase Order List"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      variant="outlined"
                      color="success"
                      iconOnly
                    >
                      <Icon>download</Icon>
                    </DownloadDataButton>
                  </CSVLink>
                )}
              </MDBox>
            </MDBox>
            {uploadCsvFilter && (
              <div>
                <div>
                  <input
                    className="choose_file"
                    style={{
                      // marginTop: "1.5rem",
                      marginLeft: "1.5rem",
                      // marginBottom: "-9rem",
                    }}
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setUploadCsvData(e.target.files[0]);
                    }}
                  />

                  <MDButton
                    disabled={
                      !uploadCsvData.name || uploadCsvData.type !== "text/csv"
                    }
                    variant="contained"
                    color="info"
                    style={{
                      marginLeft: { xs: "1.5rem", lg: "1" },
                      marginTop: "0rem",
                    }}
                    type="button"
                    onClick={uploadCsvHandler}
                  >
                    UPLOAD
                  </MDButton>
                </div>
                <div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    <strong>*</strong> Please upload
                    <strong>".csv"</strong> file only
                  </span>
                </div>
              </div>
            )}

            <MDBox mt={0}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  options={{ draggable: false }}
                  selection={true}
                  uncheckProp={uncheck}
                  selectionButtonClick={ReleaseForData}
                  selectionButtonComponent={
                    // createPickuplistAccess &&
                    <Tooltip title="Release">
                      {releaseAccess && (
                        <MDButton
                          disabled={disableButton}
                          color="white"
                          type="button"
                          style={{
                            marginTop: "0.5rem",
                            color: "white",
                            border: "1px solid #0275d8",
                            background: "#0275d8",
                            marginLeft: "1rem",
                          }}
                          onClick={(evt, data) =>
                            createReleaseHandler(evt, data)
                          }
                        >
                          Release
                        </MDButton>
                      )}
                      {disableButton && releaseAccess && (
                        <MDTypography
                          color="error"
                          variant="p"
                          style={{
                            // textAlign: "center",
                            marginLeft: "1rem",
                            marginTop: "1rem",
                          }}
                        >
                          Remove the Released records!
                        </MDTypography>
                      )}
                    </Tooltip>
                  }
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            <Modal
              open={openCreatePoModal}
              onClose={handleCloseCreateProductionModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForCreatePo}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Create Purchase Order
                  </MDTypography>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseCreateProductionModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        marginLeft: "-55px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForCreatePo.handleSubmit}
                  >
                    <MDBox>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Purchase Order Date
                            </MDTypography>
                            <MDInput
                              type="date"
                              name="poDate"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreatePo.handleBlur}
                              onChange={formikForCreatePo.handleChange}
                              value={formikForCreatePo.values.poDate}
                              error={
                                formikForCreatePo.touched.poDate &&
                                formikForCreatePo.errors.poDate &&
                                true
                              }
                            />
                            {formikForCreatePo.touched.poDate &&
                              formikForCreatePo.errors.poDate && (
                                <TextError
                                  msg={formikForCreatePo.errors.poDate}
                                />
                              )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Item Code/Des
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css-for-itemcode"
                              maxMenuHeight={130}
                              placeholder="Choose one..."
                              name="itemCode"
                              onBlur={() => {
                                formikForCreatePo.handleBlur({
                                  target: { name: "itemCode" },
                                });
                              }}
                              value={selectedItemCode}
                              options={itemCodeOptions}
                              onChange={(selectedOption) => {
                                handleItemCodeChange(selectedOption);
                                formikForCreatePo.setFieldValue(
                                  "itemCode",
                                  selectedOption
                                );
                              }}
                            />

                            {formikForCreatePo.touched.itemCode &&
                              formikForCreatePo.errors.itemCode && (
                                <TextError
                                  msg={formikForCreatePo.errors.itemCode}
                                />
                              )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Vendor Code
                            </MDTypography>

                            <Select
                              isClearable
                              className="select-css"
                              maxMenuHeight={130}
                              placeholder="Choose one..."
                              name="vendorCode"
                              onBlur={() => {
                                formikForCreatePo.handleBlur({
                                  target: { name: "vendorCode" },
                                });
                              }}
                              value={selectedVendorCode}
                              options={vendorCodeOptions}
                              onChange={(selectedOption) => {
                                handleVendorCodeChange(selectedOption);
                                formikForCreatePo.setFieldValue(
                                  "vendorCode",
                                  selectedOption
                                );
                              }}
                            />

                            {formikForCreatePo.touched.vendorCode &&
                              formikForCreatePo.errors.vendorCode && (
                                <TextError
                                  msg={formikForCreatePo.errors.vendorCode}
                                />
                              )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              PO No
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="poNo"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreatePo.handleBlur}
                              onChange={formikForCreatePo.handleChange}
                              value={formikForCreatePo.values.poNo}
                              error={
                                formikForCreatePo.touched.poNo &&
                                formikForCreatePo.errors.poNo &&
                                true
                              }
                            />
                            {formikForCreatePo.touched.poNo &&
                              formikForCreatePo.errors.poNo && (
                                <TextError
                                  msg={formikForCreatePo.errors.poNo}
                                />
                              )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              PO Line No
                            </MDTypography>
                            <MDInput
                              type="number"
                              name="poLineNo"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreatePo.handleBlur}
                              onChange={formikForCreatePo.handleChange}
                              value={formikForCreatePo.values.poLineNo}
                              error={
                                formikForCreatePo.touched.poLineNo &&
                                formikForCreatePo.errors.poLineNo &&
                                true
                              }
                            />
                            {formikForCreatePo.touched.poLineNo &&
                              formikForCreatePo.errors.poLineNo && (
                                <TextError
                                  msg={formikForCreatePo.errors.poLineNo}
                                />
                              )}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="h6"
                              style={{ fontWeight: 500 }}
                            >
                              Required Qty
                            </MDTypography>

                            <MDInput
                              type="number"
                              name="reqQty"
                              fullWidth
                              autoComplete="off"
                              onBlur={formikForCreatePo.handleBlur}
                              onChange={formikForCreatePo.handleChange}
                              value={formikForCreatePo.values.reqQty}
                              error={
                                formikForCreatePo.touched.reqQty &&
                                formikForCreatePo.errors.reqQty &&
                                true
                              }
                            />
                            {formikForCreatePo.touched.reqQty &&
                              formikForCreatePo.errors.reqQty && (
                                <TextError
                                  msg={formikForCreatePo.errors.reqQty}
                                />
                              )}
                          </MDBox>
                        </Grid>
                        <MDBox mt={2}>
                          <Collapse in={isSuccessForCreateOrder}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessForCreateOrder(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Purchase Order created successfully!
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorForCreateOrder}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForCreateOrder(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {errorMsgForCreate}
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <MDButton
                          type="submit"
                          color="info"
                          fullWidth
                          disabled={!formikForCreatePo.isValid}
                        >
                          Create
                        </MDButton>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={openUpdatePurchaseOrderModal}
              onClose={handleCloseUpdateProductionModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Update
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseUpdateProductionModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>

                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForUpdate.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6"> Qty</MDTypography>
                      <MDInput
                        type="number"
                        name="reqQty"
                        fullWidth
                        autoComplete="off"
                        onBlur={formikForUpdate.handleBlur}
                        onChange={formikForUpdate.handleChange}
                        value={formikForUpdate.values.reqQty}
                        error={
                          formikForUpdate.touched.reqQty &&
                          formikForUpdate.errors.reqQty &&
                          true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formikForUpdate.touched.reqQty &&
                        formikForUpdate.errors.reqQty && (
                          <TextError msg={formikForUpdate.errors.reqQty} />
                        )}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="h6"> Date</MDTypography>
                      <MDInput
                        type="date"
                        name="eDate"
                        fullWidth
                        autoComplete="off"
                        onBlur={formikForUpdate.handleBlur}
                        onChange={formikForUpdate.handleChange}
                        value={formikForUpdate.values.eDate}
                        error={
                          formikForUpdate.touched.eDate &&
                          formikForUpdate.errors.eDate &&
                          true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formikForUpdate.touched.eDate &&
                        formikForUpdate.errors.eDate && (
                          <TextError msg={formikForUpdate.errors.eDate} />
                        )}
                    </MDBox>
                    <MDBox>
                      <Collapse in={isErrorForUpdate}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsgForUpdate}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForUpdate}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Updated successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        variant="contained"
                        color="success"
                        fullWidth
                        type="submit"
                        disabled={!formikForUpdate.isValid}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={deleteStyle}>
                <MDTypography>Are you sure you want to delete?</MDTypography>
                <MDBox mt={2} display="flex">
                  <div>
                    <MDButton
                      style={{ width: "100%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteModal}
                    >
                      Cancel
                    </MDButton>
                  </div>
                  <div style={{ float: "right" }}>
                    <MDButton
                      style={{ width: "100%", marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deletePurchaseOrderData}
                    >
                      Delete
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessDelete}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessDelete(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Deleted successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorDelete}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDelete(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsgForDelete}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>

            <Modal
              open={openCreateReleaseModal}
              onClose={handleCloseCreateReleaseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={styleForRelease}>
                <MDTypography>Are you sure you want to Release?</MDTypography>
                <MDBox mt={2}>
                  <MDButton
                    style={{ width: "10rem", marginRight: "0.5rem" }}
                    color="info"
                    variant="outlined"
                    onClick={handleCloseCreateReleaseModal}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    style={{ width: "10rem", marginLeft: "0.5rem" }}
                    color="info"
                    onClick={onClickCreateReleaseList}
                  >
                    Release
                  </MDButton>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessRelease}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessRelease(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Released successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorRelease}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorRelease(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {isErrorMsgForRelease}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer />
      {/* <Footer /> */}
      {/* </DashboardLayout> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getPurchaseOrderReducer: state.getPurchaseOrderReducer,
    profileData: state.profile,
    itemMaster: state.itemMaster,
    getVendorCodeReducer: state.getVendorCodeReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getPurchaseOrder: (jwtToken) => dispatch(getPurchaseOrder(jwtToken)),
    getVendorCodes: (jwtToken) => dispatch(getVendorCodes(jwtToken)),
    deletePurchaseOrder: (purchaseOrderId, userId, jwtToken) =>
      dispatch(deletePurchaseOrder(purchaseOrderId, userId, jwtToken)),
    publishPurchaseOrder: (payload, jwtToken) =>
      dispatch(publishPurchaseOrder(payload, jwtToken)),
    createPurchaseOrder: (payload, jwtToken) =>
      dispatch(createPurchaseOrder(payload, jwtToken)),
    editPurchaseOrder: (payload, jwtToken) =>
      dispatch(editPurchaseOrder(payload, jwtToken)),
    uploadForPurchaseOrder: (payload, jwtToken) =>
      dispatch(uploadForPurchaseOrder(payload, jwtToken)),
    getItemMaster: (jwtToken) => dispatch(getItemMaster(jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);
