import axios from "axios";
import Config from "../../config/index";

import {
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRANSACTION_SUCCESS,
  GET_COMPLETED_TRANSACTION_FAILURE,
  GET_COMPLETED_TRANSACTION_REQUEST,
  GET_COMPLETED_TRANSACTION_SUCCESS,
  GET_REJECTED_TRANSACTION_FAILURE,
  GET_REJECTED_TRANSACTION_REQUEST,
  GET_REJECTED_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
} from "./transactionTypes";

let baseUrl = Config.baseUrl;

const getTransactionsRequest = () => {
  return {
    type: GET_TRANSACTION_REQUEST,
  };
};

const getTransactionsSuccess = (payload) => {
  return {
    type: GET_TRANSACTION_SUCCESS,
    payload: payload,
  };
};

const getTransactionsFailure = (error) => {
  return {
    type: GET_TRANSACTION_FAILURE,
    payload: error,
  };
};

const getTransactions = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getTransactionsRequest());
    let url = `${baseUrl}/transaction/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getTransactionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getTransactionsFailure(err.message));
      });
  };
};

const getRejectedTransactionsRequest = () => {
  return {
    type: GET_REJECTED_TRANSACTION_REQUEST,
  };
};

const getRejectedTransactionsSuccess = (payload) => {
  return {
    type: GET_REJECTED_TRANSACTION_SUCCESS,
    payload: payload,
  };
};

const getRejectedTransactionsFailure = (error) => {
  return {
    type: GET_REJECTED_TRANSACTION_FAILURE,
    payload: error,
  };
};

const getRejectedTransactions = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getRejectedTransactionsRequest());
    let url = `${baseUrl}/transaction/get_rejected`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getRejectedTransactionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRejectedTransactionsFailure(err.message));
      });
  };
};
const getCompletedTransactionsRequest = () => {
  return {
    type: GET_COMPLETED_TRANSACTION_REQUEST,
  };
};

const getCompletedTransactionsSuccess = (payload) => {
  return {
    type: GET_COMPLETED_TRANSACTION_SUCCESS,
    payload: payload,
  };
};

const getCompletedTransactionsFailure = (error) => {
  return {
    type: GET_COMPLETED_TRANSACTION_FAILURE,
    payload: error,
  };
};

const getCompletedTransactions = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCompletedTransactionsRequest());
    let url = `${baseUrl}/transaction/get_completed`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCompletedTransactionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getCompletedTransactionsFailure(err.message));
      });
  };
};

// const publishPurchaseOrderRequest = () => {
//   return {
//     type: PUBLISH_PURCHASE_ORDER_REQUEST,
//   };
// };

// const publishPurchaseOrderSuccess = (payload) => {
//   return {
//     type: PUBLISH_PURCHASE_ORDER_SUCCESS,
//     payload: payload,
//   };
// };

// const publishPurchaseOrderFailure = (error) => {
//   return {
//     type: PUBLISH_PURCHASE_ORDER_FAILURE,
//     payload: error,
//   };
// };

// const publishPurchaseOrder = (payload, jwtToken) => {
//   let headers = {
//     headers: {
//       Authorization: `Bearer ${jwtToken}`,
//     },
//   };
//   return (dispatch) => {
//     dispatch(publishPurchaseOrderRequest());
//     let url = `${baseUrl}/purchase_order/release`;
//     return axios
//       .put(url, payload, headers)
//       .then((response) => {
//         dispatch(publishPurchaseOrderSuccess(response.data));
//         return { status: true, data: response.data };
//       })
//       .catch((err) => {
//         dispatch(publishPurchaseOrderFailure(err.response));
//         return { status: false, data: err.response };
//       });
//   };
// };

const createTransactionRequest = () => {
  return {
    type: CREATE_TRANSACTION_REQUEST,
  };
};

const createTransactionSuccess = (payload) => {
  return {
    type: CREATE_TRANSACTION_SUCCESS,
    payload: payload,
  };
};

const createTransactionFailure = (error) => {
  return {
    type: CREATE_TRANSACTION_FAILURE,
    payload: error,
  };
};

const createTransaction = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createTransactionRequest());
    let url = `${baseUrl}/transaction/picklist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createTransactionSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createTransactionFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getTransactions,
  createTransaction,
  getRejectedTransactions,
  getCompletedTransactions,
};
