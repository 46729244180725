import {
  GET_LOCATIONS_FOR_SHOP_FAILURE,
  GET_LOCATIONS_FOR_SHOP_REQUEST,
  GET_LOCATIONS_FOR_SHOP_SUCCESS,
  GETWAREHOUSE_FOR_SHOP_FAILURE,
  GETWAREHOUSE_FOR_SHOP_REQUEST,
  GETWAREHOUSE_FOR_SHOP_SUCCESS,
} from "./shopWarehouseTypes";

const initialState = {
  loading: false,
  warehouses: [],
  error: "",
};

const getWarehousesForAssemblyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETWAREHOUSE_FOR_SHOP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETWAREHOUSE_FOR_SHOP_SUCCESS:
      return {
        loading: false,
        warehouses: action.payload,
        error: "",
      };
    case GETWAREHOUSE_FOR_SHOP_FAILURE:
      return {
        loading: false,
        warehouses: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLocations = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsForAssemblyReducer = (
  state = initialStateForLocations,
  action
) => {
  switch (action.type) {
    case GET_LOCATIONS_FOR_SHOP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS_FOR_SHOP_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GET_LOCATIONS_FOR_SHOP_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getWarehousesForAssemblyReducer, getLocationsForAssemblyReducer };
