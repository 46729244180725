/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  createDepartment,
  deleteDepartment,
  getDepartments,
  updateDepartment,
  getSubModulesByRoleId,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const Department = ({
  departments,
  getDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = loginDetails?.jwt;
  let userId = loginDetails?.data.userId;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let departmentModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "5cb29527-adf2-440f-938f-42937a6c9fab"
  );

  let createAccess = departmentModule
    ? departmentModule.actionId.includes("dd4815a0-cb6d-4ace-890d-0a7446e05ead")
    : null;

  let viewAccess = departmentModule
    ? departmentModule.actionId.includes("4deaf2f1-efa4-499c-86af-e4e7d749d921")
    : null;

  let updateAccess = departmentModule
    ? departmentModule.actionId.includes("1c953f7f-cc14-4f5c-b5d5-c17cdd686d18")
    : null;

  let deleteAccess = departmentModule
    ? departmentModule.actionId.includes("b3422dcb-42f3-40ec-8d1b-c04b0dcf80a4")
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "Department Name", accessor: "departmentName", align: "left" },
      {
        Header: "Action",
        accessor: "actions",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [openDeleteDepartmentModal, setOpenDeleteDepartmentModal] =
    useState(false);
  const [singleDepartmentDataForDelete, setDepartmentDataForDelete] = useState(
    {}
  );
  const handleOpenDeleteDepartmentModal = (department = {}) => {
    setDepartmentDataForDelete(department);
    setOpenDeleteDepartmentModal(true);
  };
  const handleCloseDeleteDepartmentModal = () => {
    setOpenDeleteDepartmentModal(false);
  };

  const [openUpdateDepartmentModal, setOpenUpdateDepartmentModal] =
    useState(false);
  const [singleDepartmentDataForUpdate, setDepartmentDataForUpdate] = useState(
    {}
  );
  const handleOpenUpdateDepartmentModal = (department = {}) => {
    setDepartmentDataForUpdate(department);
    setOpenUpdateDepartmentModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateDepartmentModal = () => {
    setOpenUpdateDepartmentModal(false);

    formikForUpdate.setValues(initialValueForUpdate);
    formikForUpdate.setTouched({});
    formikForUpdate.setErrors({});
  };

  useEffect(() => {
    getDepartments(jwtToken);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempDepartment = [];
    let data = departments.departments.data ? departments.departments.data : [];

    setLoading(departments.loading);

    data.map((department) => {
      const departmentObject = {
        departmentName: department.departmentName,
        actions: (
          <div>
            {updateAccess ? (
              <EditButton
                tooltip="Edit Department"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenUpdateDepartmentModal(department)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            ) : (
              <EditButton
                tooltip="Edit Department"
                disabled
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}
            {deleteAccess ? (
              <DeleteButton
                tooltip="Delete Department"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                onClick={() => handleOpenDeleteDepartmentModal(department)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            ) : (
              <DeleteButton
                tooltip="Delete Department"
                disabled
                variant="gradient"
                color="error"
                iconOnly
                type="button"
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </div>
        ),
      };
      tempDepartment.push(departmentObject);
    });

    viewAccess && setState({ ...state, rows: tempDepartment });
  }, [departments]);

  const [createDepartmentModal, setCreateDepartmentModal] = useState(false);
  const handleCreateDepartmentModal = () => {
    setCreateDepartmentModal(!createDepartmentModal);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
  };

  const [isErrorCreateDepartment, setIsErrorCreateDepartment] = useState(false);
  const [isSuccessCreateDepartment, setIsSuccessCreateDepartment] =
    useState(false);

  useEffect(() => {
    if (isErrorCreateDepartment) {
      setTimeout(() => {
        setIsErrorCreateDepartment(false);
      }, 3000);
    }
  }, [isErrorCreateDepartment]);

  useEffect(() => {
    if (isSuccessCreateDepartment) {
      setTimeout(() => {
        setIsSuccessCreateDepartment(false);
      }, 3000);
    }
  }, [isSuccessCreateDepartment]);

  const initialValues = {
    departmentName: "",
  };

  const validationSchema = Yup.object({
    departmentName: Yup.string().required("Enter the department name!"),
  });

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    let res = await createDepartment(values, jwtToken);
    if (res.status) {
      setIsSuccessCreateDepartment(true);
      getDepartments(jwtToken);
      resetForm();
    }
    if (!res.status) {
      setCreateErrorMsg(res.data.data.message);
      setIsErrorCreateDepartment(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    departmentId: singleDepartmentDataForUpdate.departmentId,
    departmentName: singleDepartmentDataForUpdate.departmentName,
    clientId: singleDepartmentDataForUpdate.clientId,
  };

  const validationSchemaForUpdate = Yup.object({
    departmentName: Yup.string().required("Enter the department name!"),
  });

  const [updateErrorMsg, setUpdateErrorMsg] = useState("");

  const onSubmitForUpdate = async (values) => {
    let res = await updateDepartment(values, jwtToken);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getDepartments(jwtToken);
    }
    if (!res.status) {
      setUpdateErrorMsg(res.data.data.message);
      setIsErrorForUpdate(true);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [isErrorDeleteDepartment, setIsErrorDeleteDepartment] = useState(false);

  useEffect(() => {
    if (isErrorDeleteDepartment) {
      setTimeout(() => {
        setIsErrorDeleteDepartment(false);
      }, 3000);
    }
  }, [isErrorDeleteDepartment]);

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const deleteDepartmentData = async () => {
    let departmentId = singleDepartmentDataForDelete.departmentId;
    let response = await deleteDepartment(departmentId, jwtToken);
    if (response.status) {
      getDepartments(jwtToken);
      handleCloseDeleteDepartmentModal();
    }
    if (!response.status) {
      setDeleteErrorMsg(response.data.data.message);
      setIsErrorDeleteDepartment(true);
    }
  };

  return (
    <div>
      <MDBox mt={-8}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {createAccess && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <CreateButton
                    tooltip="Create Department"
                    color="info"
                    variant="contained"
                    type="button"
                    onClick={handleCreateDepartmentModal}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
              </MDBox>
            )}
            <MDBox mt={createAccess ? -3 : 0}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  tableSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            <Modal
              open={createDepartmentModal}
              onClose={handleCreateDepartmentModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Create Department
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCreateDepartmentModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6">Name</MDTypography>
                      <MDInput
                        type="text"
                        name="departmentName"
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 45 }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.departmentName}
                        error={
                          formik.touched.departmentName &&
                          formik.errors.departmentName &&
                          true
                        }
                      />
                      {formik.touched.departmentName &&
                        formik.errors.departmentName && (
                          <TextError msg={formik.errors.departmentName} />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorCreateDepartment}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorCreateDepartment(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Could not create the department! */}
                          {createErrorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessCreateDepartment}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessCreateDepartment(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Department Created Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        variant="contained"
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={!formik.isValid}
                      >
                        Create Department
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openUpdateDepartmentModal}
              onClose={handleCloseUpdateDepartmentModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Update Department
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseUpdateDepartmentModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForUpdate.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography variant="h6">Name</MDTypography>
                      <MDInput
                        type="text"
                        name="departmentName"
                        fullWidth
                        autoComplete="off"
                        onBlur={formikForUpdate.handleBlur}
                        onChange={formikForUpdate.handleChange}
                        value={formikForUpdate.values.departmentName}
                        error={
                          formikForUpdate.touched.departmentName &&
                          formikForUpdate.errors.departmentName &&
                          true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formikForUpdate.touched.departmentName &&
                        formikForUpdate.errors.departmentName && (
                          <TextError
                            msg={formikForUpdate.errors.departmentName}
                          />
                        )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForUpdate}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Could not update the department! */}
                          {updateErrorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForUpdate}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Department updated successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        variant="contained"
                        color="success"
                        fullWidth
                        type="submit"
                        disabled={!formikForUpdate.isValid}
                      >
                        Update Department
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openDeleteDepartmentModal}
              onClose={handleCloseDeleteDepartmentModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={deleteStyle}>
                <MDTypography>Are you sure you want to delete?</MDTypography>
                <MDBox mt={2} display="flex">
                  <div>
                    <MDButton
                      style={{ width: "100%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteDepartmentModal}
                    >
                      Cancel
                    </MDButton>
                  </div>
                  <div style={{ float: "right" }}>
                    <MDButton
                      style={{ width: "100%", marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteDepartmentData}
                    >
                      Delete
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isErrorDeleteDepartment}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDeleteDepartment(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {/* Could not delete the department! */}
                      {deleteErrorMsg}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
      {/* {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    departments: state.getDepartmentsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getDepartments: (jwtToken) => dispatch(getDepartments(jwtToken)),
    createDepartment: (payload, jwtToken) =>
      dispatch(createDepartment(payload, jwtToken)),
    updateDepartment: (payload, jwtToken) =>
      dispatch(updateDepartment(payload, jwtToken)),
    deleteDepartment: (departmentId, jwtToken) =>
      dispatch(deleteDepartment(departmentId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Department);
