/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  DELETE_ROLES_FAILURE,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_REQUEST,
  CREATE_ROLES_REQUEST,
  CREATE_ROLES_SUCCESS,
  CREATE_ROLES_FAILURE,
  EDIT_ROLES_REQUEST,
  EDIT_ROLES_SUCCESS,
  EDIT_ROLES_FAILURE,
  GET_USER_ROLES_BY_DEPARTMENT_REQUEST,
  GET_USER_ROLES_BY_DEPARTMENT_SUCCESS,
  GET_USER_ROLES_BY_DEPARTMENT_FAILURE,
} from "./userRolesTypes";
import axios from "axios";
import Config from "../../../config/index";
let userManagementUrl = Config.userManagementUrl;

const getAllRolesRequest = () => {
  return {
    type: GET_ALL_ROLES_REQUEST,
  };
};

const getAllRolesSuccess = (payload) => {
  return {
    type: GET_ALL_ROLES_SUCCESS,
    payload: payload,
  };
};

const getAllRolesFaliure = (error) => {
  return {
    type: GET_ALL_ROLES_FAILURE,
    payload: error,
  };
};

const getAllRoles = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllRolesRequest());
    let url = `${userManagementUrl}/roles/get`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllRolesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAllRolesFaliure(err.message));
        return { status: false };
      });
  };
};

const deleteRolesRequest = () => {
  return {
    type: DELETE_ROLES_REQUEST,
  };
};

const deletRolesSuccess = (payload) => {
  return {
    type: DELETE_ROLES_SUCCESS,
    payload: payload,
  };
};

const deleteRolesFaliure = (error) => {
  return {
    type: DELETE_ROLES_FAILURE,
    payload: error,
  };
};

const deleteRoles = (jwtToken, roleId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteRolesRequest());
    let url = `${userManagementUrl}/roles/delete?role_id=${roleId}`;
    return axios
      .delete(url, headers)
      .then((response) => {
        dispatch(deletRolesSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(deleteRolesFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const createRolesRequest = () => {
  return {
    type: CREATE_ROLES_REQUEST,
  };
};

const createRolesSuccess = (payload) => {
  return {
    type: CREATE_ROLES_SUCCESS,
    payload: payload,
  };
};

const createRolesFaliure = (error) => {
  return {
    type: CREATE_ROLES_FAILURE,
    payload: error,
  };
};

const createRoles = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createRolesRequest());
    let url = `${userManagementUrl}/roles`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createRolesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createRolesFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editRolesRequest = () => {
  return {
    type: EDIT_ROLES_REQUEST,
  };
};

const editRolesSuccess = (payload) => {
  return {
    type: EDIT_ROLES_SUCCESS,
    payload: payload,
  };
};

const editRolesFaliure = (error) => {
  return {
    type: EDIT_ROLES_FAILURE,
    payload: error,
  };
};

const editRoles = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editRolesRequest());
    let url = `${userManagementUrl}/roles/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editRolesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editRolesFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getUserRolesByDepartmentRequest = () => {
  return {
    type: GET_USER_ROLES_BY_DEPARTMENT_REQUEST,
  };
};

const getUserRolesByDepartmentSuccess = (payload) => {
  return {
    type: GET_USER_ROLES_BY_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};

const getUserRolesByDepartmentFaliure = (error) => {
  return {
    type: GET_USER_ROLES_BY_DEPARTMENT_FAILURE,
    payload: error,
  };
};

const getUserRolesByDepartment = (departmentId) => {
  return (dispatch) => {
    dispatch(getUserRolesByDepartmentRequest());
    let url = `${userManagementUrl}/users_management/roles/get_roles_by_department?departmentId=${departmentId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getUserRolesByDepartmentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUserRolesByDepartmentFaliure(err.message));
      });
  };
};

export {
  getAllRoles,
  deleteRoles,
  createRoles,
  editRoles,
  getUserRolesByDepartment,
};
