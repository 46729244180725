import {
  GET_SALESORDER_REQUEST,
  GET_SALESORDER_SUCCESS,
  GET_SALESORDER_FAILURE,
  GET_VINSBYTRIPNO_REQUEST,
  GET_VINSBYTRIPNO_SUCCESS,
  GET_VINSBYTRIPNO_FAILURE,
  PUT_APPROVEVIN_REQUEST,
  PUT_APPROVEVIN_SUCCESS,
  PUT_APPROVEVIN_FAILURE,
  GET_ACCESSORIESBYVIN_REQUEST,
  GET_ACCESSORIESBYVIN_SUCCESS,
  GET_ACCESSORIESBYVIN_FAILURE,
  UPDATE_RELEASE_TRIP_REQUEST,
  UPDATE_RELEASE_TRIP_SUCCESS,
  UPDATE_RELEASE_TRIP_FAILURE,
  UPDATE_VIN_REQUEST,
  UPDATE_VIN_SUCCESS,
  UPDATE_VIN_FAILURE,
  CREATE_DISPATCH_REQUEST,
  CREATE_DISPATCH_SUCCESS,
  CREATE_DISPATCH_FAILURE,
  GET_FG_CODE_REQUEST,
  GET_FG_CODE_SUCCESS,
  GET_FG_CODE_FAILURE,
} from "./dispatchTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getSalesOrderRequest = () => {
  return {
    type: GET_SALESORDER_REQUEST,
  };
};

const getSalesOrderSuccess = (payload) => {
  return {
    type: GET_SALESORDER_SUCCESS,
    payload: payload,
  };
};

const getSalesOrderFailure = (error) => {
  return {
    type: GET_SALESORDER_FAILURE,
    payload: error,
  };
};

const getSalesOrder = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSalesOrderRequest());
    let url = `${baseUrl}/dispatch/get_sales_orders`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSalesOrderSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSalesOrderFailure(err.message));
      });
  };
};
const getFgCodeRequest = () => {
  return {
    type: GET_FG_CODE_REQUEST,
  };
};

const getFgCodeSuccess = (payload) => {
  return {
    type: GET_FG_CODE_SUCCESS,
    payload: payload,
  };
};

const getFgCodeFailure = (error) => {
  return {
    type: GET_FG_CODE_FAILURE,
    payload: error,
  };
};

const getFgCode = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getFgCodeRequest());
    let url = `${baseUrl}/model/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getFgCodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFgCodeFailure(err.message));
      });
  };
};

const approveVinRequest = () => {
  return {
    type: PUT_APPROVEVIN_REQUEST,
  };
};

const approveVinSuccess = (payload) => {
  return {
    type: PUT_APPROVEVIN_SUCCESS,
    payload: payload,
  };
};

const approveVinFailure = (error) => {
  return {
    type: PUT_APPROVEVIN_FAILURE,
    payload: error,
  };
};

const approveVin = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(approveVinRequest());
    let url = `${baseUrl}/dispatch/accept_vin`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(approveVinSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(approveVinFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getVinStatusByTripNoRequest = () => {
  return {
    type: GET_VINSBYTRIPNO_REQUEST,
  };
};

const getVinStatusByTripNoSuccess = (payload) => {
  return {
    type: GET_VINSBYTRIPNO_SUCCESS,
    payload: payload,
  };
};

const getVinStatusByTripNoFailure = (error) => {
  return {
    type: GET_VINSBYTRIPNO_FAILURE,
    payload: error,
  };
};

const getVinStatusByTripNo = (tripNo, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getVinStatusByTripNoRequest());
    let url = `${baseUrl}/dispatch/get_vin_by_sales_order?salesOrderId=${tripNo}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getVinStatusByTripNoSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getVinStatusByTripNoFailure(err.message));
      });
  };
};

const getAccessoriesByVinRequest = () => {
  return {
    type: GET_ACCESSORIESBYVIN_REQUEST,
  };
};

const getAccessoriesByVinSuccess = (payload) => {
  return {
    type: GET_ACCESSORIESBYVIN_SUCCESS,
    payload: payload,
  };
};

const getAccessoriesByVinFailure = (error) => {
  return {
    type: GET_ACCESSORIESBYVIN_FAILURE,
    payload: error,
  };
};

const getAccessoriesByVin = (vin, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAccessoriesByVinRequest());
    let url = `${baseUrl}/`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAccessoriesByVinSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAccessoriesByVinFailure(err.message));
      });
  };
};

const releaseTripRequest = () => {
  return {
    type: UPDATE_RELEASE_TRIP_REQUEST,
  };
};

const releaseTripSuccess = (payload) => {
  return {
    type: UPDATE_RELEASE_TRIP_SUCCESS,
    payload: payload,
  };
};

const releaseTripFaliure = (error) => {
  return {
    type: UPDATE_RELEASE_TRIP_FAILURE,
    payload: error,
  };
};

const releaseTrip = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(releaseTripRequest());
    let url = `${baseUrl}/dispatch/release_sales_order`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(releaseTripSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(releaseTripFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const changeVinRequest = () => {
  return {
    type: UPDATE_VIN_REQUEST,
  };
};

const changeVinSuccess = (payload) => {
  return {
    type: UPDATE_VIN_SUCCESS,
    payload: payload,
  };
};

const changeVinFaliure = (error) => {
  return {
    type: UPDATE_VIN_FAILURE,
    payload: error,
  };
};

const changeVin = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(changeVinRequest());
    let url = `${baseUrl}/dispatch/replace_vin`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(changeVinSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(changeVinFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};
const createDispatchRequest = () => {
  return {
    type: CREATE_DISPATCH_REQUEST,
  };
};

const createDispatchSuccess = (payload) => {
  return {
    type: CREATE_DISPATCH_SUCCESS,
    payload: payload,
  };
};

const createDispatchFailure = (error) => {
  return {
    type: CREATE_DISPATCH_FAILURE,
    payload: error,
  };
};

const createDispatch = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createDispatchRequest());
    let url = `${baseUrl}/dispatch/trip`;

    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createDispatchSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createDispatchFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getSalesOrder,
  approveVin,
  getVinStatusByTripNo,
  getAccessoriesByVin,
  releaseTrip,
  changeVin,
  createDispatch,
  getFgCode,
};
