import {
  GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE,
  TRANSFERORDER_INDIVIDUALPRINT_REQUEST,
  TRANSFERORDER_INDIVIDUALPRINT_SUCCESS,
  TRANSFERORDER_INDIVIDUALPRINT_FAILURE,
  TRANSFERORDER_PACKAGEPRINT_REQUEST,
  TRANSFERORDER_PACKAGEPRINT_SUCCESS,
  TRANSFERORDER_PACKAGEPRINT_FAILURE,
} from "./createTransferOrderTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getSkuByItemCodeAndWarehouseCodeRequest = () => {
  return {
    type: GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST,
  };
};

const getSkuByItemCodeAndWarehouseCodeSuccess = (payload) => {
  return {
    type: GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS,
    payload: payload,
  };
};

const getSkuByItemCodeAndWarehouseCodeFailure = (error) => {
  return {
    type: GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE,
    payload: error,
  };
};

const getSkuByItemCodeAndWarehouseCode = (itemId, warehouseId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuByItemCodeAndWarehouseCodeRequest());
    // let url = `${baseUrl}/transfer_order/sku_by_item_code_&_wh_code?item_id=${itemId}&warehouse_code=${warehouseId}`;
    let url = `${baseUrl}/transfer_order/sku_by_item_code_&_wh_code?item_id=${itemId}&warehouse_code=${warehouseId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSkuByItemCodeAndWarehouseCodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSkuByItemCodeAndWarehouseCodeFailure(err.message));
      });
  };
};

const transferOrderIndividualPrintRequest = () => {
  return {
    type: TRANSFERORDER_INDIVIDUALPRINT_REQUEST,
  };
};

const transferOrderIndividualPrintSuccess = (payload) => {
  return {
    type: TRANSFERORDER_INDIVIDUALPRINT_SUCCESS,
    payload: payload,
  };
};

const transferOrderIndividualPrintFailure = (error) => {
  return {
    type: TRANSFERORDER_INDIVIDUALPRINT_FAILURE,
    payload: error,
  };
};

const transferOrderIndividualPrint = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(transferOrderIndividualPrintRequest());
    let url = `${baseUrl}/transfer_order/print_individual`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(transferOrderIndividualPrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(transferOrderIndividualPrintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const transferOrderPackagePrintRequest = () => {
  return {
    type: TRANSFERORDER_PACKAGEPRINT_REQUEST,
  };
};

const transferOrderPackagePrintSuccess = (payload) => {
  return {
    type: TRANSFERORDER_PACKAGEPRINT_SUCCESS,
    payload: payload,
  };
};

const transferOrderPackagePrintFailure = (error) => {
  return {
    type: TRANSFERORDER_PACKAGEPRINT_FAILURE,
    payload: error,
  };
};

const transferOrderPackagePrint = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(transferOrderPackagePrintRequest());
    let url = `${baseUrl}/transfer_order/print_package`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(transferOrderPackagePrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(transferOrderPackagePrintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  getSkuByItemCodeAndWarehouseCode,
  transferOrderIndividualPrint,
  transferOrderPackagePrint,
};
