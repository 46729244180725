export const GET_AGEING_REPORT_REQUEST = "GET_AGEING_REPORT_REQUEST";
export const GET_AGEING_REPORT_SUCCESS = "GET_AGEING_REPORT_SUCCESS";
export const GET_AGEING_REPORT_FAILURE = "GET_AGEING_REPORT_FAILURE";

export const GET_LOCATION_REQUEST = "GET_LOCATION_REQUEST";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";

export const GET_LOCATION_BY_AGEING_REQUEST = "GET_LOCATION_BY_AGEING_REQUEST";
export const GET_LOCATION_BY_AGEING_SUCCESS = "GET_LOCATION_BY_AGEING_SUCCESS";
export const GET_LOCATION_BY_AGEING_FAILURE = "GET_LOCATION_BY_AGEING_FAILURE";
