/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  createUserDetails,
  deleteUserDetails,
  getAllRoles,
  getUserRolesByDepartment,
  getUsersDetails,
  updateUserDetails,
  getSubModulesByRoleId,
} from "../../../store";

// Cookies
import Cookies from "universal-cookie";
import CreateButton from "components/standardized-components/CreateButton";
import EditButton from "components/standardized-components/EditButton";
import DeleteButton from "components/standardized-components/DeleteButton";
const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const UserDetails = ({
  userDetailsReducer,
  getUsersDetails,
  createUserDetails,
  updateUserDetails,
  deleteUserDetails,
  getAllRoles,
  getAllRolesReducer,

  getUserRolesByDepartment,
  getSubModulesByRoleId,
  getSubModulesByRoleIdReducer,
}) => {
  let userDetails = cookies.get("loginDetailsForWMS");

  let jwtToken = userDetails?.jwt;

  let roleId = userDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let userDetailsModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "23fcfb3a-a2da-4630-af00-79cf88872c72"
  );

  let createAccess = userDetailsModule
    ? userDetailsModule.actionId.includes(
        "5028608e-570d-4c02-bc01-121714404c00"
      )
    : null;

  let viewAccess = userDetailsModule
    ? userDetailsModule.actionId.includes(
        "3a1336b4-eaaa-4f52-9a26-4ce1571c7f03"
      )
    : null;

  let updateAccess = userDetailsModule
    ? userDetailsModule.actionId.includes(
        "c2849b42-cce8-4155-a842-089033019a3c"
      )
    : null;

  let deleteAccess = userDetailsModule
    ? userDetailsModule.actionId.includes(
        "d641c806-f4a9-485c-9eac-fcc36754ba11"
      )
    : null;

  useEffect(() => {
    getUsersDetails(jwtToken);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "User Name", accessor: "userName", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },

      { Header: "Role", accessor: "roleName", align: "left" },
      {
        Header: "Action",
        accessor: "actions",
        align: "left",
        cannotSearch: true,
        cannotSort: true,
      },
    ],
    rows: [],
  });

  const { columns, rows } = state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempUserDetails = [];
    let data = userDetailsReducer.usersDetails.data
      ? userDetailsReducer.usersDetails.data
      : [];

    setLoading(userDetailsReducer.loading);

    data.map((user) => {
      const userObject = {
        name: user.name,
        userName: user.userName,
        email: user.email,

        roleName: user.roleName,
        actions: (
          <div>
            {updateAccess ? (
              <EditButton
                tooltip="Edit Details"
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => {
                  handleOpenEditUserDetailsModal(user);
                  getAllRoles(jwtToken);
                }}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            ) : (
              <EditButton
                tooltip="Edit Details"
                disabled
                variant="gradient"
                color="success"
                iconOnly
                type="button"
                onClick={() => handleOpenEditUserDetailsModal(user)}
                style={{ marginRight: "5px" }}
              >
                <Icon>edit_sharp</Icon>
              </EditButton>
            )}

            {deleteAccess ? (
              <DeleteButton
                tooltip="Delete Details"
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                onClick={() => handleOpenDeleteUserDetailsModal(user)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            ) : (
              <DeleteButton
                tooltip="Delete Details"
                disabled
                variant="gradient"
                color="error"
                iconOnly
                type="button"
                onClick={() => handleOpenDeleteUserDetailsModal(user)}
              >
                <Icon>delete</Icon>
              </DeleteButton>
            )}
          </div>
        ),
      };
      tempUserDetails.push(userObject);
    });

    viewAccess && setState({ ...state, rows: tempUserDetails });
  }, [userDetailsReducer]);

  const [rolesOptions, setRolesOptions] = useState([]);
  useEffect(() => {
    let roles = getAllRolesReducer?.rolesData?.data
      ? getAllRolesReducer?.rolesData?.data
      : [];
    let rolesOptionsTemp = [];
    roles.map((role) => {
      rolesOptionsTemp.push({ label: role.roleName, value: role.roleId });
    });
    setRolesOptions(rolesOptionsTemp);
  }, [getAllRolesReducer]);

  const [roleSelected, setRoleSelected] = useState("");
  const handleRoleSelected = (selectedOption) => {
    setRoleSelected(selectedOption);
  };

  const [createUserDetailsModal, setCreateUserDetailsModal] = useState(false);
  const handleCreateUserDetailsModal = () => {
    setCreateUserDetailsModal(!createUserDetailsModal);
    setRoleSelected("");

    setIsErrorCreateUserDetails(false);
    setIsSuccessCreateUserDetails(false);
    formik.setValues(initialValues);
    formik.setTouched({});
    formik.setErrors({});
    getAllRoles(jwtToken);
  };

  const [isErrorCreateUserDetails, setIsErrorCreateUserDetails] =
    useState(false);
  const [isSuccessCreateUserDetails, setIsSuccessCreateUserDetails] =
    useState(false);

  useEffect(() => {
    if (isErrorCreateUserDetails) {
      setTimeout(() => {
        setIsErrorCreateUserDetails(false);
      }, 3000);
    }
  }, [isErrorCreateUserDetails]);

  useEffect(() => {
    if (isSuccessCreateUserDetails) {
      setTimeout(() => {
        setIsSuccessCreateUserDetails(false);
      }, 3000);
    }
  }, [isSuccessCreateUserDetails]);

  const initialValues = {
    name: "",
    username: "",
    email: "",
    roleId: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter a valid name!"),
    username: Yup.string().required("Enter a valid user name!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter a valid email!"),
    roleId: Yup.object().required("Select a role!"),
  });

  const [createErrorMsg, setCreateErrorMsg] = useState("");

  const onSubmit = async (values, { resetForm }) => {
    // values.loginId = loginIdSelected.value;
    // values.departmentId = departmentSelected.value;
    values.roleId = roleSelected.value;
    // values.departmentName = departmentSelected.label;
    // values.roleName = roleSelected.label;

    let res = await createUserDetails(values, jwtToken);

    if (res.status) {
      setIsSuccessCreateUserDetails(true);
      setRoleSelected("");

      getUsersDetails(jwtToken);
      resetForm();
    }
    if (!res.status) {
      setCreateErrorMsg(res.data.data.message);
      setIsErrorCreateUserDetails(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [openEditUserDetailsModal, setOpenEditUserDetailsModal] =
    useState(false);
  const [singleUserDetailsDataForUpdate, setSingleUserDetailsDataForUpdate] =
    useState({});

  const handleOpenEditUserDetailsModal = (userDetails = {}) => {
    getUserRolesByDepartment(userDetails.departmentId);

    setRoleSelected({ label: userDetails.roleName, value: userDetails.roleId });
    setSingleUserDetailsDataForUpdate(userDetails);
    setOpenEditUserDetailsModal(true);
  };

  const handleCloseEditUserDetailsModal = () => {
    setRoleSelected("");

    setOpenEditUserDetailsModal(false);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
    formikForUpdate.setValues(initialValueForUpdate);
    formikForUpdate.setTouched({});
    formikForUpdate.setErrors({});
  };

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  useEffect(() => {
    if (isErrorForUpdate) {
      setTimeout(() => {
        setIsErrorForUpdate(false);
      }, 3000);
    }
  }, [isErrorForUpdate]);

  useEffect(() => {
    if (isSuccessForUpdate) {
      setTimeout(() => {
        setIsSuccessForUpdate(false);
      }, 3000);
    }
  }, [isSuccessForUpdate]);

  const initialValueForUpdate = {
    // roleId: singleUserDetailsDataForUpdate.roleId,
    email: singleUserDetailsDataForUpdate.email,
    roleId: singleUserDetailsDataForUpdate.roleName,

    username: singleUserDetailsDataForUpdate.userName,
    name: singleUserDetailsDataForUpdate.name,
  };

  const validationSchemaForUpdate = Yup.object({
    name: Yup.string().required("Enter a valid name!"),
    username: Yup.string().required("Enter a valid user name!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter a valid email!"),
    roleId: Yup.object().required("Select a role!"),
  });

  const [updateErrorMsg, setUpdateErrorMsg] = useState("");

  const onSubmitForUpdate = async (values) => {
    // values.loginId = loginIdSelected.value;
    // values.departmentId = departmentSelected.value;
    // values.roleId = roleSelected.value;
    // values.departmentName = departmentSelected.label;

    values.roleId = roleSelected.value;
    let res = await updateUserDetails(values, jwtToken);

    if (res.status) {
      setIsSuccessForUpdate(true);
      getUsersDetails(jwtToken);
    }
    if (!res.status) {
      setUpdateErrorMsg(res.data.data.message);
      setIsErrorForUpdate(true);
    }
  };

  const formikForUpdate = useFormik({
    initialValues: initialValueForUpdate,
    onSubmit: onSubmitForUpdate,
    validationSchema: validationSchemaForUpdate,
    enableReinitialize: true,
  });

  const [openDeleteUserDetailsModal, setOpenDeleteUserDetailsModal] =
    useState(false);
  const [singleUserDetailsDataForDelete, setUserDetailsDataForDelete] =
    useState({});

  const [isErrorDeleteUser, setIsErrorDeleteUser] = useState(false);
  const [isSuccessDeleteUser, setIsSuccessDeleteUser] = useState(false);

  useEffect(() => {
    if (isErrorDeleteUser) {
      setTimeout(() => {
        setIsErrorDeleteUser(false);
      }, 3000);
    }
  }, [isErrorDeleteUser]);

  useEffect(() => {
    if (isSuccessDeleteUser) {
      setTimeout(() => {
        setIsSuccessDeleteUser(false);
      }, 3000);
    }
  }, [isSuccessDeleteUser]);

  const handleOpenDeleteUserDetailsModal = (userDetails = {}) => {
    setUserDetailsDataForDelete(userDetails);
    setOpenDeleteUserDetailsModal(true);
  };
  const handleCloseDeleteUserDetailsModal = () => {
    setOpenDeleteUserDetailsModal(false);
  };

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  const deleteUserDetailsData = async () => {
    let emailId = singleUserDetailsDataForDelete.email;
    let response = await deleteUserDetails(emailId, jwtToken);

    if (response.status) {
      getUsersDetails(jwtToken);
      setIsSuccessDeleteUser(true);

      setTimeout(() => {
        handleCloseDeleteUserDetailsModal();
      }, 2250);
    }
    if (!response.status) {
      setDeleteErrorMsg(response.data.data.message);
      setIsErrorDeleteUser(true);
    }
  };

  return (
    <div>
      <MDBox mt={-8}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {createAccess && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <CreateButton
                    tooltip=" Create User"
                    variant="contained"
                    type="button"
                    onClick={handleCreateUserDetailsModal}
                  >
                    <Icon>add</Icon>
                  </CreateButton>
                </MDBox>
              </MDBox>
            )}
            <MDBox mt={createAccess ? -3 : 0}>
              {!loading ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    marginBottom: "100px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              )}
            </MDBox>
            <Modal
              open={createUserDetailsModal}
              onClose={handleCreateUserDetailsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Create User
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCreateUserDetailsModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Name
                      </MDTypography>

                      <MDInput
                        type="text"
                        name="name"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={
                          formik.touched.name && formik.errors.name && true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <TextError msg={formik.errors.name} />
                      )}
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        User Name
                      </MDTypography>

                      <MDInput
                        type="text"
                        name="username"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                        error={
                          formik.touched.username &&
                          formik.errors.username &&
                          true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formik.touched.username && formik.errors.username && (
                        <TextError msg={formik.errors.username} />
                      )}
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Email
                      </MDTypography>

                      <MDInput
                        type="email"
                        name="email"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={
                          formik.touched.email && formik.errors.email && true
                        }
                      />
                      {formik.touched.email && formik.errors.email && (
                        <TextError msg={formik.errors.email} />
                      )}
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography variant="h6">Role</MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="roleId"
                        options={rolesOptions}
                        value={roleSelected}
                        onChange={(selectedOption) => {
                          handleRoleSelected(selectedOption);
                          formik.setFieldValue("roleId", selectedOption);
                        }}
                      />
                      {formik.errors.roleId && (
                        <TextError msg={formik.errors.roleId} />
                      )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorCreateUserDetails}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorCreateUserDetails(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Please check the details. Server error! */}
                          {createErrorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessCreateUserDetails}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessCreateUserDetails(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          User Details Created Successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={!formik.isValid}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openEditUserDetailsModal}
              onClose={handleCloseEditUserDetailsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={style}>
                <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                  <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                    Update User Details
                  </MDTypography>
                  <MDBox>
                    <Tooltip title="Close">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseEditUserDetailsModal}
                        style={{
                          background: "whitesmoke",
                          color: "black",
                          borderRadius: 5,
                          marginLeft: "-55px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                <MDBox pt={1} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    onSubmit={formikForUpdate.handleSubmit}
                  >
                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        Name
                      </MDTypography>

                      <MDInput
                        type="text"
                        name="name"
                        fullWidth
                        autoComplete="off"
                        onChange={formikForUpdate.handleChange}
                        onBlur={formikForUpdate.handleBlur}
                        value={formikForUpdate.values.name}
                        error={
                          formikForUpdate.touched.name &&
                          formikForUpdate.errors.name &&
                          true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formikForUpdate.touched.name &&
                        formikForUpdate.errors.name && (
                          <TextError msg={formikForUpdate.errors.name} />
                        )}
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        flexGrow={1}
                        style={{ fontWeight: "500" }}
                      >
                        User Name
                      </MDTypography>

                      <MDInput
                        type="text"
                        name="username"
                        fullWidth
                        autoComplete="off"
                        onChange={formikForUpdate.handleChange}
                        onBlur={formikForUpdate.handleBlur}
                        value={formikForUpdate.values.username}
                        error={
                          formikForUpdate.touched.username &&
                          formikForUpdate.errors.username &&
                          true
                        }
                        inputProps={{
                          maxLength: 45,
                        }}
                      />
                      {formikForUpdate.touched.username &&
                        formikForUpdate.errors.username && (
                          <TextError msg={formikForUpdate.errors.username} />
                        )}
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography variant="h6">Role</MDTypography>
                      <Select
                        isClearable
                        className="select-css"
                        maxMenuHeight={130}
                        placeholder="Choose one..."
                        name="roleId"
                        options={rolesOptions}
                        value={roleSelected}
                        onChange={(selectedOption) => {
                          handleRoleSelected(selectedOption);
                          formikForUpdate.setFieldValue(
                            "roleId",
                            selectedOption
                          );
                        }}
                      />
                      {formikForUpdate.errors.roleId && (
                        <TextError msg={formikForUpdate.errors.roleId} />
                      )}
                    </MDBox>

                    <MDBox>
                      <Collapse in={isErrorForUpdate}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {/* Please check the details. Server error! */}
                          {updateErrorMsg}
                        </Alert>
                      </Collapse>
                      <Collapse in={isSuccessForUpdate}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorForUpdate(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          User updated successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        color="success"
                        fullWidth
                        type="submit"
                        disabled={!formikForUpdate.isValid}
                      >
                        Update User Details
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDCard>
            </Modal>
            <Modal
              open={openDeleteUserDetailsModal}
              onClose={handleCloseDeleteUserDetailsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDCard sx={deleteStyle}>
                <MDTypography>
                  Are you sure you want to delete this user?
                </MDTypography>
                <MDBox mt={2} display="flex">
                  <div>
                    <MDButton
                      style={{ width: "100%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteUserDetailsModal}
                    >
                      Cancel
                    </MDButton>
                  </div>
                  <div style={{ float: "right" }}>
                    <MDButton
                      style={{ width: "100%", marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteUserDetailsData}
                    >
                      Delete
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox mt={2}>
                  <Collapse in={isSuccessDeleteUser}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessDeleteUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Deleted successfully!
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorDeleteUser}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorDeleteUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {deleteErrorMsg}
                    </Alert>
                  </Collapse>
                </MDBox>
              </MDCard>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    userDetailsReducer: state.getUsersDetailsReducer,
    getAllRolesReducer: state.getAllRolesReducer,

    userRolesByDepartmentData: state.getUserRolesByDepartment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getUsersDetails: (jwtToken) => dispatch(getUsersDetails(jwtToken)),
    createUserDetails: (payload, jwtToken) =>
      dispatch(createUserDetails(payload, jwtToken)),
    updateUserDetails: (payload, jwtToken) =>
      dispatch(updateUserDetails(payload, jwtToken)),
    deleteUserDetails: (email, jwtToken) =>
      dispatch(deleteUserDetails(email, jwtToken)),
    getAllRoles: (jwtToken) => dispatch(getAllRoles(jwtToken)),

    getUserRolesByDepartment: (departmentId, jwtToken) =>
      dispatch(getUserRolesByDepartment(departmentId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
