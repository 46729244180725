import {
  GET_REJECTIONS_FAILURE,
  GET_REJECTIONS_REQUEST,
  GET_REJECTIONS_SUCCESS,
  GET_ACCEPTED_REJECTIONS_REQUEST,
  GET_ACCEPTED_REJECTIONS_SUCCESS,
  GET_ACCEPTED_REJECTIONS_FAILURE,
  REJECTIONS_BY_SUID_REQUEST,
  REJECTIONS_BY_SUID_SUCCESS,
  REJECTIONS_BY_SUID_FAILURE,
  ACCEPTED_BY_SUID_REQUEST,
  ACCEPTED_BY_SUID_SUCCESS,
  ACCEPTED_BY_SUID_FAILURE,
  CREATE_REJECTION_REQUEST,
  CREATE_REJECTION_SUCCESS,
  CREATE_REJECTION_FAILURE,
  GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_REQUEST,
  GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_SUCCESS,
  GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_FAILURE,
  PUT_RELEASE_REJECTIONS_REQUEST,
  PUT_RELEASE_REJECTIONS_SUCCESS,
  PUT_RELEASE_REJECTIONS_FAILURE,
} from "./rejectionTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getRejectionsRequest = () => {
  return {
    type: GET_REJECTIONS_REQUEST,
  };
};

const getRejectionsSuccess = (payload) => {
  return {
    type: GET_REJECTIONS_SUCCESS,
    payload: payload,
  };
};

const getRejectionsFailure = (error) => {
  return {
    type: GET_REJECTIONS_FAILURE,
    payload: error,
  };
};

const getRejections = () => {
  return (dispatch) => {
    dispatch(getRejectionsRequest());
    let url = `${baseUrl}/rejection/get_rejection_pending_suids`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRejectionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRejectionsFailure(err.message));
      });
  };
};

const getAcceptedRejectionsRequest = () => {
  return {
    type: GET_ACCEPTED_REJECTIONS_REQUEST,
  };
};

const getAcceptedRejectionsSuccess = (payload) => {
  return {
    type: GET_ACCEPTED_REJECTIONS_SUCCESS,
    payload: payload,
  };
};

const getAcceptedRejectionsFaliure = (error) => {
  return {
    type: GET_ACCEPTED_REJECTIONS_FAILURE,
    payload: error,
  };
};

const getAcceptedRejections = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAcceptedRejectionsRequest());
    let url = `${baseUrl}/rejection/get_rejected_suids_grouped`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAcceptedRejectionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAcceptedRejectionsFaliure(err.message));
      });
  };
};

const rejectionsRequesBySuid = () => {
  return {
    type: REJECTIONS_BY_SUID_REQUEST,
  };
};

const rejectionsSuccesBySuid = (payload) => {
  return {
    type: REJECTIONS_BY_SUID_SUCCESS,
    payload: payload,
  };
};

const rejectionsFailurBySuid = (error) => {
  return {
    type: REJECTIONS_BY_SUID_FAILURE,
    payload: error,
  };
};

const rejectionBySuid = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(rejectionsRequesBySuid());
    let url = `${baseUrl}/rejection/reject`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(rejectionsSuccesBySuid(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(rejectionsFailurBySuid(err.response));
        return { status: false, data: err.response };
      });
  };
};

const releaseRejectionsRequest = () => {
  return {
    type: PUT_RELEASE_REJECTIONS_REQUEST,
  };
};

const releaseRejectionsSuccess = (payload) => {
  return {
    type: PUT_RELEASE_REJECTIONS_SUCCESS,
    payload: payload,
  };
};

const releaseRejectionsFailure = (error) => {
  return {
    type: PUT_RELEASE_REJECTIONS_FAILURE,
    payload: error,
  };
};

const releaseRejections = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(releaseRejectionsRequest());
    let url = `${baseUrl}/rejection/picklist`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(releaseRejectionsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(releaseRejectionsFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const acceptedBySuidRequesBySuid = () => {
  return {
    type: ACCEPTED_BY_SUID_REQUEST,
  };
};

const acceptedBySuidSuccesBySuid = (payload) => {
  return {
    type: ACCEPTED_BY_SUID_SUCCESS,
    payload: payload,
  };
};

const acceptedBySuidFailurBySuid = (error) => {
  return {
    type: ACCEPTED_BY_SUID_FAILURE,
    payload: error,
  };
};

const acceptedBySuid = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(acceptedBySuidRequesBySuid());
    let url = `${baseUrl}/rejection/accept`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(acceptedBySuidSuccesBySuid(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(acceptedBySuidFailurBySuid(err.response));
        return { status: false, data: err.response };
      });
  };
};
const createRejectionsForAcceptedListRequest = () => {
  return {
    type: CREATE_REJECTION_REQUEST,
  };
};

const createRejectionsForAcceptedListSuccess = (payload) => {
  return {
    type: CREATE_REJECTION_SUCCESS,
    payload: payload,
  };
};

const createRejectionsForAcceptedListFailure = (error) => {
  return {
    type: CREATE_REJECTION_FAILURE,
    payload: error,
  };
};

const createRejectionsForAcceptedList = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createRejectionsForAcceptedListRequest());
    let url = `${baseUrl}/rejection/direct_reject`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createRejectionsForAcceptedListSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createRejectionsForAcceptedListFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};
const getSkuByDocumentNoForRejectionsRequest = () => {
  return {
    type: GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_REQUEST,
  };
};

const getSkuByDocumentNoForRejectionsSuccess = (payload) => {
  return {
    type: GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_SUCCESS,
    payload: payload,
  };
};

const getSkuByDocumentNoForRejectionsFailure = (error) => {
  return {
    type: GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_FAILURE,
    payload: error,
  };
};

const getSkuByDocumentNoForRejections = (documentTrId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getSkuByDocumentNoForRejectionsRequest());
    let url = `${baseUrl}/rejection/get_suids_by_doc_no?doc_id=${documentTrId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSkuByDocumentNoForRejectionsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSkuByDocumentNoForRejectionsFailure(err.message));
      });
  };
};

export {
  getRejections,
  getAcceptedRejections,
  acceptedBySuid,
  rejectionBySuid,
  createRejectionsForAcceptedList,
  getSkuByDocumentNoForRejections,
  releaseRejections,
};
