import {
  GET_CREATE_MATERIAL_RETURN_ORDER_REQUEST,
  GET_CREATE_MATERIAL_RETURN_ORDER_SUCCESS,
  GET_CREATE_MATERIAL_RETURN_ORDER_FAILURE,
  CREATE_MATERIAL_RETURN_ORDER_REQUEST,
  CREATE_MATERIAL_RETURN_ORDER_SUCCESS,
  CREATE_MATERIAL_RETURN_ORDER_FAILURE,
} from "./createMaterialReturnOrderTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getCreateMaterialReturnOrderRequest = () => {
  return {
    type: GET_CREATE_MATERIAL_RETURN_ORDER_REQUEST,
  };
};

const getCreateMaterialReturnOrderSuccess = (payload) => {
  return {
    type: GET_CREATE_MATERIAL_RETURN_ORDER_SUCCESS,
    payload: payload,
  };
};

const getCreateMaterialReturnOrderFailure = (error) => {
  return {
    type: GET_CREATE_MATERIAL_RETURN_ORDER_FAILURE,
    payload: error,
  };
};

const getCreateMaterialReturnOrder = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getCreateMaterialReturnOrderRequest());
    let url = `${baseUrl}/material_return_order/sku_by_item_code_&_wh_code`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getCreateMaterialReturnOrderSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getCreateMaterialReturnOrderFailure(err.message));
      });
  };
};

const createMaterialReturnOrderRequest = () => {
  return {
    type: CREATE_MATERIAL_RETURN_ORDER_REQUEST,
  };
};

const createMaterialReturnOrderSuccess = (payload) => {
  return {
    type: CREATE_MATERIAL_RETURN_ORDER_SUCCESS,
    payload: payload,
  };
};

const createMaterialReturnOrderFailure = (error) => {
  return {
    type: CREATE_MATERIAL_RETURN_ORDER_FAILURE,
    payload: error,
  };
};

const createMaterialReturnOrder = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createMaterialReturnOrderRequest());
    let url = `${baseUrl}/material_return_order/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createMaterialReturnOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createMaterialReturnOrderFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getCreateMaterialReturnOrder, createMaterialReturnOrder };
