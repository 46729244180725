import {
  GET_FGMASTER_REQUEST,
  GET_FGMASTER_SUCCESS,
  GET_FGMASTER_FAILURE,
} from "./fgMasterTypes";

const initialState = {
  loading: false,
  fgMaster: [],
  error: "",
};

const getFgMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FGMASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FGMASTER_SUCCESS:
      return {
        loading: false,
        fgMaster: action.payload,
        error: "",
      };
    case GET_FGMASTER_FAILURE:
      return {
        loading: false,
        fgMaster: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getFgMasterReducer };
