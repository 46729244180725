import {
  GET_AGEING_PARAMETER_FAILURE,
  GET_AGEING_PARAMETER_REQUEST,
  GET_AGEING_PARAMETER_SUCCESS,
  GET_MVT_BY_DOC_FAILURE,
  GET_MVT_BY_DOC_REQUEST,
  GET_MVT_BY_DOC_SUCCESS,
  GET_PICKLIST_AGEING_FAILURE,
  GET_PICKLIST_AGEING_REQUEST,
  GET_PICKLIST_AGEING_SUCCESS,
} from "./picklistAgeingTypes";

const initialState = {
  loading: false,
  picklistAgeing: [],
  error: "",
};

const getPicklistAgeingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PICKLIST_AGEING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKLIST_AGEING_SUCCESS:
      return {
        loading: false,
        picklistAgeing: action.payload,
        error: "",
      };
    case GET_PICKLIST_AGEING_FAILURE:
      return {
        loading: false,
        picklistAgeing: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForParameter = {
  loading: false,
  ageingParameter: [],
  error: "",
};

const getAgeingParameterReducer = (state = initialStateForParameter, action) => {
  switch (action.type) {
    case GET_AGEING_PARAMETER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AGEING_PARAMETER_SUCCESS:
      return {
        loading: false,
        ageingParameter: action.payload,
        error: "",
      };
    case GET_AGEING_PARAMETER_FAILURE:
      return {
        loading: false,
        ageingParameter: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForMov = {
  loading: false,
  documents: [],
  error: "",
};

const getMovementByDocReducer = (state = initialStateForMov, action) => {
  switch (action.type) {
    case GET_MVT_BY_DOC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MVT_BY_DOC_SUCCESS:
      return {
        loading: false,
        documents: action.payload,
        error: "",
      };
    case GET_MVT_BY_DOC_FAILURE:
      return {
        loading: false,
        documents: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getPicklistAgeingReducer,getAgeingParameterReducer,getMovementByDocReducer};
