import axios from "axios";
import Config from "../../../config/index";
import {
  CREATE_PALLET_FAILURE,
  CREATE_PALLET_REQUEST,
  CREATE_PALLET_SUCCESS,
  CREATE_PALLET_TYPE_FAILURE,
  CREATE_PALLET_TYPE_REQUEST,
  CREATE_PALLET_TYPE_SUCCESS,
  DELETE_PALLET_FAILURE,
  DELETE_PALLET_REQUEST,
  DELETE_PALLET_SUCCESS,
  EDIT_PALLET_FAILURE,
  EDIT_PALLET_REQUEST,
  EDIT_PALLET_SUCCESS,
  GET_PALLETS_FAILURE,
  GET_PALLETS_REQUEST,
  GET_PALLETS_SUCCESS,
  GET_PALLET_TYPES_FAILURE,
  GET_PALLET_TYPES_REQUEST,
  GET_PALLET_TYPES_SUCCESS,
  UPLOAD_CSV_FOR_PALLET_FAILURE,
  UPLOAD_CSV_FOR_PALLET_REQUEST,
  UPLOAD_CSV_FOR_PALLET_SUCCESS,
  PRINT_PALLET_REQUEST,
  PRINT_PALLET_FAILURE,
  PRINT_PALLET_SUCCESS,
} from "./palletMasterTypes";

let baseUrl = Config.baseUrl;

const getPalletTypesRequest = () => {
  return {
    type: GET_PALLET_TYPES_REQUEST,
  };
};

const getPalletTypesSuccess = (payload) => {
  return {
    type: GET_PALLET_TYPES_SUCCESS,
    payload: payload,
  };
};

const getPalletTypesFailure = (error) => {
  return {
    type: GET_PALLET_TYPES_FAILURE,
    payload: error,
  };
};

const getPalletTypes = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPalletTypesRequest());
    let url = `${baseUrl}/pallet_type/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPalletTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPalletTypesFailure(err.message));
      });
  };
};

const createPalletTypeRequest = () => {
  return {
    type: CREATE_PALLET_TYPE_REQUEST,
  };
};

const createPalletTypeSuccess = (payload) => {
  return {
    type: CREATE_PALLET_TYPE_SUCCESS,
    payload: payload,
  };
};

const createPalletTypeFailure = (error) => {
  return {
    type: CREATE_PALLET_TYPE_FAILURE,
    payload: error,
  };
};

const createPalletType = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPalletTypeRequest());
    let url = `${baseUrl}/pallet_type/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPalletTypeSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPalletTypeFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deletePalletTypeReq = () => {
  return {
    type: DELETE_PALLET_REQUEST,
  };
};

const deletePalletTypeSuc = (payload) => {
  return {
    type: DELETE_PALLET_SUCCESS,
    payload: payload,
  };
};

const deletePalletTypeFal = (error) => {
  return {
    type: DELETE_PALLET_FAILURE,
    payload: error,
  };
};

const deletePallet = (palletId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deletePalletTypeReq());
    let url = `${baseUrl}/pallet/delete?palletId=${palletId}`;
    return axios
      .delete(url, {}, headers)
      .then((response) => {
        dispatch(deletePalletTypeSuc(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deletePalletTypeFal(err.response));
        return { status: false, data: err.response };
      });
  };
};

const editPalletRequest = () => {
  return {
    type: EDIT_PALLET_REQUEST,
  };
};

const editPalletSuccess = (payload) => {
  return {
    type: EDIT_PALLET_SUCCESS,
    payload: payload,
  };
};

const editPalletFaliure = (error) => {
  return {
    type: EDIT_PALLET_FAILURE,
    payload: error,
  };
};

const editPallet = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editPalletRequest());
    let url = `${baseUrl}/pallet/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editPalletSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editPalletFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const getPalletsRequest = () => {
  return {
    type: GET_PALLETS_REQUEST,
  };
};

const getPalletsSuccess = (payload) => {
  return {
    type: GET_PALLETS_SUCCESS,
    payload: payload,
  };
};

const getPalletsFailure = (error) => {
  return {
    type: GET_PALLETS_FAILURE,
    payload: error,
  };
};

const getPallets = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPalletsRequest());
    let url = `${baseUrl}/pallet/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPalletsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPalletsFailure(err.message));
      });
  };
};
const createPalletRequest = () => {
  return {
    type: CREATE_PALLET_REQUEST,
  };
};

const createPalletSuccess = (payload) => {
  return {
    type: CREATE_PALLET_SUCCESS,
    payload: payload,
  };
};

const createPalletFailure = (error) => {
  return {
    type: CREATE_PALLET_FAILURE,
    payload: error,
  };
};

const createPallet = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createPalletRequest());
    let url = `${baseUrl}/pallet/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPalletSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPalletFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const uploadPalletMasterRequest = () => {
  return {
    type: UPLOAD_CSV_FOR_PALLET_REQUEST,
  };
};

const uploadPalletMasterSuccess = (payload) => {
  return {
    type: UPLOAD_CSV_FOR_PALLET_SUCCESS,
    payload: payload,
  };
};

const uploadPalletMasterFailure = (error) => {
  return {
    type: UPLOAD_CSV_FOR_PALLET_FAILURE,
    payload: error,
  };
};

const uploadPalletMaster = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(uploadPalletMasterRequest());
    let url = `${baseUrl}/pallet/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(uploadPalletMasterSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadPalletMasterFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const printPalletReq = () => {
  return {
    type: PRINT_PALLET_REQUEST,
  };
};

const printPalletSuc = (payload) => {
  return {
    type: PRINT_PALLET_SUCCESS,
    payload: payload,
  };
};

const printPalletFal = (error) => {
  return {
    type: PRINT_PALLET_FAILURE,
    payload: error,
  };
};

const printPallet = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(printPalletReq());
    let url = `${baseUrl}/pallet/print_pallet`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(printPalletSuc(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printPalletFal(err.response));
        return { status: false, data: err.response };
      });
  };
};

export {
  getPalletTypes,
  createPalletType,
  deletePallet,
  editPallet,
  getPallets,
  createPallet,
  uploadPalletMaster,
  printPallet,
};
