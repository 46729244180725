import {
  GET_AGEING_REPORT_FAILURE,
  GET_AGEING_REPORT_REQUEST,
  GET_AGEING_REPORT_SUCCESS,
  GET_LOCATION_BY_AGEING_FAILURE,
  GET_LOCATION_BY_AGEING_REQUEST,
  GET_LOCATION_BY_AGEING_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
} from "./ageingReportTypes";

const initialState = {
  loading: false,
  ageing: [],
  error: "",
};

const getAgeingReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGEING_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AGEING_REPORT_SUCCESS:
      return {
        loading: false,
        ageing: action.payload,
        error: "",
      };
    case GET_AGEING_REPORT_FAILURE:
      return {
        loading: false,
        ageing: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForLocations = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsReducer = (state = initialStateForLocations, action) => {
  switch (action.type) {
    case GET_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATION_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GET_LOCATION_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForLocationByAgeing = {
  loading: false,
  locations: [],
  error: "",
};

const getLocationsByAgeingReducer = (state = initialStateForLocationByAgeing, action) => {
  switch (action.type) {
    case GET_LOCATION_BY_AGEING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATION_BY_AGEING_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GET_LOCATION_BY_AGEING_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getAgeingReportReducer ,getLocationsReducer,getLocationsByAgeingReducer};
