import {
  GET_UNPLANNED_ISSUE_FAILURE,
  GET_UNPLANNED_ISSUE_REQUEST,
  GET_UNPLANNED_ISSUE_SUCCESS,
  CREATE_UNPLANNED_ISSUE_REQUEST,
  CREATE_UNPLANNED_ISSUE_SUCCESS,
  CREATE_UNPLANNED_ISSUE_FAILURE,
  UPDATE_RELATION_LOCATION_REQUEST,
  UPDATE_RELATION_LOCATION_SUCCESS,
  UPDATE_RELATION_LOCATION_FAILURE,
  GET_ITEMSBYLOCATION_REQUEST,
  GET_ITEMSBYLOCATION_SUCCESS,
  GET_ITEMSBYLOCATION_FAILURE,
  GET_SUIDS_BY_LOCATION_AND_ITEM_REQUEST,
  GET_SUIDS_BY_LOCATION_AND_ITEM_SUCCESS,
  GET_SUIDS_BY_LOCATION_AND_ITEM_FAILURE,
  GET_SUIDS_BY_DOCUMENT_NO_REQUEST,
  GET_SUIDS_BY_DOCUMENT_NO_SUCCESS,
  GET_SUIDS_BY_DOCUMENT_NO_FAILURE,
} from "./unplannedIssueTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getUnplannedIssuesRequest = () => {
  return {
    type: GET_UNPLANNED_ISSUE_REQUEST,
  };
};

const getUnplannedIssuesSuccess = (payload) => {
  return {
    type: GET_UNPLANNED_ISSUE_SUCCESS,
    payload: payload,
  };
};

const getUnplannedIssuesFailure = (error) => {
  return {
    type: GET_UNPLANNED_ISSUE_FAILURE,
    payload: error,
  };
};

const getUnplannedIssues = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUnplannedIssuesRequest());
    let url = `${baseUrl}/unplanned_issue/get_grouped`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUnplannedIssuesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUnplannedIssuesFailure(err.message));
      });
  };
};

const createUnplannedIssueRequest = () => {
  return {
    type: CREATE_UNPLANNED_ISSUE_REQUEST,
  };
};

const createUnplannedIssueSuccess = (payload) => {
  return {
    type: CREATE_UNPLANNED_ISSUE_SUCCESS,
    payload: payload,
  };
};

const createUnplannedIssueFailure = (error) => {
  return {
    type: CREATE_UNPLANNED_ISSUE_FAILURE,
    payload: error,
  };
};

const createUnplannedIssue = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createUnplannedIssueRequest());
    let url = `${baseUrl}/unplanned_issue/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createUnplannedIssueSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createUnplannedIssueFailure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateUnplannedIssueRequest = () => {
  return {
    type: UPDATE_RELATION_LOCATION_REQUEST,
  };
};

const updateUnplannedIssueSuccess = (payload) => {
  return {
    type: UPDATE_RELATION_LOCATION_SUCCESS,
    payload: payload,
  };
};

const updateUnplannedIssueFaliure = (error) => {
  return {
    type: UPDATE_RELATION_LOCATION_FAILURE,
    payload: error,
  };
};

const updateUnplannedIssue = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateUnplannedIssueRequest());
    let url = `${baseUrl}/rlt`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateUnplannedIssueSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUnplannedIssueFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getItemsByLocationRequest = () => {
  return {
    type: GET_ITEMSBYLOCATION_REQUEST,
  };
};

const getItemsByLocationSuccess = (payload) => {
  return {
    type: GET_ITEMSBYLOCATION_SUCCESS,
    payload: payload,
  };
};

const getItemsByLocationFailure = (error) => {
  return {
    type: GET_ITEMSBYLOCATION_FAILURE,
    payload: error,
  };
};

const getItemsByLocation = (locationCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getItemsByLocationRequest());
    let url = `${baseUrl}/item/get_by_loc?location_code=${locationCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getItemsByLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getItemsByLocationFailure(err.message));
      });
  };
};

const getSuidsByLocationAndItemRequest = () => {
  return {
    type: GET_SUIDS_BY_LOCATION_AND_ITEM_REQUEST,
  };
};

const getSuidsByLocationAndItemSuccess = (payload) => {
  return {
    type: GET_SUIDS_BY_LOCATION_AND_ITEM_SUCCESS,
    payload: payload,
  };
};

const getSuidsByLocationAndItemFailure = (error) => {
  return {
    type: GET_SUIDS_BY_LOCATION_AND_ITEM_FAILURE,
    payload: error,
  };
};

const getSuidsByLocationAndItem = (itemId, locationCode, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getSuidsByLocationAndItemRequest());
    let url = `${baseUrl}/get_suid_by_loc_&_item?itemId=${itemId}&locationCode=${locationCode}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSuidsByLocationAndItemSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSuidsByLocationAndItemFailure(err.message));
      });
  };
};

const getSuidsByDocumentNoRequest = () => {
  return {
    type: GET_SUIDS_BY_DOCUMENT_NO_REQUEST,
  };
};

const getSuidsByDocumentNoSuccess = (payload) => {
  return {
    type: GET_SUIDS_BY_DOCUMENT_NO_SUCCESS,
    payload: payload,
  };
};

const getSuidsByDocumentNoFailure = (error) => {
  return {
    type: GET_SUIDS_BY_DOCUMENT_NO_FAILURE,
    payload: error,
  };
};

const getSuidsByDocumentNo = (documentNo, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getSuidsByDocumentNoRequest());
    let url = `${baseUrl}/unplanned_issue/get_suids_by_doc?doc_id=${documentNo}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSuidsByDocumentNoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSuidsByDocumentNoFailure(err.message));
      });
  };
};

export {
  getUnplannedIssues,
  createUnplannedIssue,
  updateUnplannedIssue,
  getItemsByLocation,
  getSuidsByLocationAndItem,
  getSuidsByDocumentNo,
};
