/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const GET_MODULES_REQUEST = "GET_MODULES_REQUEST";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_FAILURE = "GET_MODULES_FAILURE";

export const GET_SUB_MODULES_REQUEST = "GET_SUB_MODULES_REQUEST";
export const GET_SUB_MODULES_SUCCESS = "GET_SUB_MODULES_SUCCESS";
export const GET_SUB_MODULES_FAILURE = "GET_SUB_MODULES_FAILURE";

export const GET_MODULES_FOR_VIEW_REQUEST = "GET_MODULES_FOR_VIEW_REQUEST"; 
export const GET_MODULES_FOR_VIEW_SUCCESS = "GET_MODULES_FOR_VIEW_SUCCESS";
export const GET_MODULES_FOR_VIEW_FAILURE = "GET_MODULES_FOR_VIEW_FAILURE";

export const GET_MODULES_FOR_EDIT_REQUEST = "GET_MODULES_FOR_EDIT_REQUEST"; 
export const GET_MODULES_FOR_EDIT_SUCCESS = "GET_MODULES_FOR_EDIT_SUCCESS";
export const GET_MODULES_FOR_EDIT_FAILURE = "GET_MODULES_FOR_EDIT_FAILURE";

export const GET_SUB_MODULES_FOR_VIEW_REQUEST = "GET_SUB_MODULES_FOR_VIEW_REQUEST";
export const GET_SUB_MODULES_FOR_VIEW_SUCCESS = "GET_SUB_MODULES_FOR_VIEW_SUCCESS";
export const GET_SUB_MODULES_FOR_VIEW_FAILURE = "GET_SUB_MODULES_FOR_VIEW_FAILURE";

export const GET_SUB_MODULES_FOR_CREATE_ROLE_REQUEST = "GET_SUB_MODULES_FOR_CREATE_ROLE_REQUEST";
export const GET_SUB_MODULES_FOR_CREATE_ROLE_SUCCESS = "GET_SUB_MODULES_FOR_CREATE_ROLE_SUCCESS";
export const GET_SUB_MODULES_FOR_CREATE_ROLE_FAILURE = "GET_SUB_MODULES_FOR_CREATE_ROLE_FAILURE";