import {
  GET_MAPPED_ACTIVITY_REQUEST,
  GET_MAPPED_ACTIVITY_SUCCESS,
  GET_MAPPED_ACTIVITY_FAILURE,
  GET_LEVEL1_BY_LEVEL_REQUEST,
  GET_LEVEL1_BY_LEVEL_SUCCESS,
  GET_LEVEL1_BY_LEVEL_FAILURE,
  GET_LEVEL2_BY_LEVEL_REQUEST,
  GET_LEVEL2_BY_LEVEL_SUCCESS,
  GET_LEVEL2_BY_LEVEL_FAILURE,
  GET_LEVEL3_BY_LEVEL_REQUEST,
  GET_LEVEL3_BY_LEVEL_SUCCESS,
  GET_LEVEL3_BY_LEVEL_FAILURE,
  GET_LEVEL4_BY_LEVEL_REQUEST,
  GET_LEVEL4_BY_LEVEL_SUCCESS,
  GET_LEVEL4_BY_LEVEL_FAILURE,
  GET_ACN_REQUEST,
  GET_ACN_SUCCESS,
  GET_ACN_FAILURE,
  GET_LEVEL1_BY_LEVELSRC_REQUEST,
  GET_LEVEL1_BY_LEVELSRC_SUCCESS,
  GET_LEVEL1_BY_LEVELSRC_FAILURE,
  GET_LEVEL2_BY_LEVELSRC_REQUEST,
  GET_LEVEL2_BY_LEVELSRC_SUCCESS,
  GET_LEVEL2_BY_LEVELSRC_FAILURE,
  GET_LEVEL3_BY_LEVELSRC_REQUEST,
  GET_LEVEL3_BY_LEVELSRC_SUCCESS,
  GET_LEVEL3_BY_LEVELSRC_FAILURE,
  GET_LEVEL4_BY_LEVELSRC_REQUEST,
  GET_LEVEL4_BY_LEVELSRC_SUCCESS,
  GET_LEVEL4_BY_LEVELSRC_FAILURE,
} from "./itemLocationMappingTypes";

const initialState = {
  loading: false,
  mappedActivity: [],
  error: "",
};

const getMappedActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAPPED_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MAPPED_ACTIVITY_SUCCESS:
      return {
        loading: false,
        mappedActivity: action.payload,
        error: "",
      };
    case GET_MAPPED_ACTIVITY_FAILURE:
      return {
        loading: false,
        mappedActivity: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel1ByLevel = {
  loading: false,
  level1ByLevel: [],
  error: "",
};

const getLevel1ByLevelReducer = (
  state = initialStateForLevel1ByLevel,
  action
) => {
  switch (action.type) {
    case GET_LEVEL1_BY_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL1_BY_LEVEL_SUCCESS:
      return {
        loading: false,
        level1ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL1_BY_LEVEL_FAILURE:
      return {
        loading: false,
        level1ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel2ByLevel = {
  loading: false,
  level2ByLevel: [],
  error: "",
};

const getLevel2ByLevelReducer = (
  state = initialStateForLevel2ByLevel,
  action
) => {
  switch (action.type) {
    case GET_LEVEL2_BY_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL2_BY_LEVEL_SUCCESS:
      return {
        loading: false,
        level2ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL2_BY_LEVEL_FAILURE:
      return {
        loading: false,
        level2ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel3ByLevel = {
  loading: false,
  level3ByLevel: [],
  error: "",
};

const getLevel3ByLevelReducer = (
  state = initialStateForLevel3ByLevel,
  action
) => {
  switch (action.type) {
    case GET_LEVEL3_BY_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL3_BY_LEVEL_SUCCESS:
      return {
        loading: false,
        level3ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL3_BY_LEVEL_FAILURE:
      return {
        loading: false,
        level3ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel4ByLevel = {
  loading: false,
  level4ByLevel: [],
  error: "",
};

const getLevel4ByLevelReducer = (
  state = initialStateForLevel4ByLevel,
  action
) => {
  switch (action.type) {
    case GET_LEVEL4_BY_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL4_BY_LEVEL_SUCCESS:
      return {
        loading: false,
        level4ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL4_BY_LEVEL_FAILURE:
      return {
        loading: false,
        level4ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForActivity = {
  loading: false,
  acn: [],
  error: "",
};

const getAcnReducer = (state = initialStateForActivity, action) => {
  switch (action.type) {
    case GET_ACN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACN_SUCCESS:
      return {
        loading: false,
        acn: action.payload,
        error: "",
      };
    case GET_ACN_FAILURE:
      return {
        loading: false,
        acn: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel1ByLevelSrc = {
  loading: false,
  level1ByLevel: [],
  error: "",
};

const getLevel1ByLevelSrcReducer = (
  state = initialStateForLevel1ByLevelSrc,
  action
) => {
  switch (action.type) {
    case GET_LEVEL1_BY_LEVELSRC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL1_BY_LEVELSRC_SUCCESS:
      return {
        loading: false,
        level1ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL1_BY_LEVELSRC_FAILURE:
      return {
        loading: false,
        level1ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel2ByLevelSrc = {
  loading: false,
  level2ByLevel: [],
  error: "",
};

const getLevel2ByLevelSrcReducer = (
  state = initialStateForLevel2ByLevelSrc,
  action
) => {
  switch (action.type) {
    case GET_LEVEL2_BY_LEVELSRC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL2_BY_LEVELSRC_SUCCESS:
      return {
        loading: false,
        level2ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL2_BY_LEVELSRC_FAILURE:
      return {
        loading: false,
        level2ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel3ByLevelSrc = {
  loading: false,
  level3ByLevel: [],
  error: "",
};

const getLevel3ByLevelSrcReducer = (
  state = initialStateForLevel3ByLevelSrc,
  action
) => {
  switch (action.type) {
    case GET_LEVEL3_BY_LEVELSRC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL3_BY_LEVELSRC_SUCCESS:
      return {
        loading: false,
        level3ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL3_BY_LEVELSRC_FAILURE:
      return {
        loading: false,
        level3ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLevel4ByLevelSrc = {
  loading: false,
  level4ByLevel: [],
  error: "",
};

const getLevel4ByLevelSrcReducer = (
  state = initialStateForLevel4ByLevelSrc,
  action
) => {
  switch (action.type) {
    case GET_LEVEL4_BY_LEVELSRC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL4_BY_LEVELSRC_SUCCESS:
      return {
        loading: false,
        level4ByLevel: action.payload,
        error: "",
      };
    case GET_LEVEL4_BY_LEVELSRC_FAILURE:
      return {
        loading: false,
        level4ByLevel: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getMappedActivityReducer,
  getLevel1ByLevelReducer,
  getLevel2ByLevelReducer,
  getLevel3ByLevelReducer,
  getLevel4ByLevelReducer,
  getAcnReducer,
  getLevel1ByLevelSrcReducer,
  getLevel2ByLevelSrcReducer,
  getLevel3ByLevelSrcReducer,
  getLevel4ByLevelSrcReducer,
};
