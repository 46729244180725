import {
  GET_REJECTIONS_FAILURE,
  GET_REJECTIONS_REQUEST,
  GET_REJECTIONS_SUCCESS,
  GET_ACCEPTED_REJECTIONS_REQUEST,
  GET_ACCEPTED_REJECTIONS_SUCCESS,
  GET_ACCEPTED_REJECTIONS_FAILURE,
  GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_REQUEST,
  GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_SUCCESS,
  GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_FAILURE,
} from "./rejectionTypes";

const initialState = {
  loading: false,
  rejections: {},
  error: "",
};

const rejectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REJECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REJECTIONS_SUCCESS:
      return {
        loading: false,
        rejections: action.payload,
        error: "",
      };
    case GET_REJECTIONS_FAILURE:
      return {
        loading: false,
        rejections: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForSkuByDocumentNo = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuByDocumentNoforRejectionsReducer = (
  state = initialStateForSkuByDocumentNo,
  action
) => {
  switch (action.type) {
    case GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GET_SKU_BYDOCUMENTNO_FOR_REJECTIONS_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAcceptedRejections = {
  loading: false,
  acceptedRejections: [],
  error: "",
};

const acceptedRejectionsReducer = (
  state = initialStateForAcceptedRejections,
  action
) => {
  switch (action.type) {
    case GET_ACCEPTED_REJECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCEPTED_REJECTIONS_SUCCESS:
      return {
        loading: false,
        acceptedRejections: action.payload,
        error: "",
      };
    case GET_ACCEPTED_REJECTIONS_FAILURE:
      return {
        loading: false,
        acceptedRejections: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { rejectionReducer, acceptedRejectionsReducer,getSkuByDocumentNoforRejectionsReducer };
