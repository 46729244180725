/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
} from "./departmentTypes";

import axios from "axios";
import Config from "../../../config/index";
let userManagementUrl = Config.userManagementUrl;
// let userManagementUrl = `http://13.71.2.248:1338/api/v2/users_management`;

const getDepartmentsRequest = () => {
  return {
    type: GET_DEPARTMENTS_REQUEST,
  };
};

const getDepartmentsSuccess = (payload) => {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    payload: payload,
  };
};

const getDepartmentsFaliure = (error) => {
  return {
    type: GET_DEPARTMENTS_FAILURE,
    payload: error,
  };
};

const getDepartments = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDepartmentsRequest());
    let url = `${userManagementUrl}/department/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDepartmentsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDepartmentsFaliure(err.message));
      });
  };
};

const createDepartmentRequest = () => {
  return {
    type: CREATE_DEPARTMENT_REQUEST,
  };
};

const createDepartmentSuccess = (payload) => {
  return {
    type: CREATE_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};

const createDepartmentFaliure = (error) => {
  return {
    type: CREATE_DEPARTMENT_FAILURE,
    payload: error,
  };
};

const createDepartment = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createDepartmentRequest());
    let url = `${userManagementUrl}/department/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createDepartmentSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createDepartmentFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const updateDepartmentRequest = () => {
  return {
    type: UPDATE_DEPARTMENT_REQUEST,
  };
};

const updateDepartmentSuccess = (payload) => {
  return {
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};

const updateDepartmentFaliure = (error) => {
  return {
    type: UPDATE_DEPARTMENT_FAILURE,
    payload: error,
  };
};

const updateDepartment = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(updateDepartmentRequest());
    let url = `${userManagementUrl}/department/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateDepartmentSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateDepartmentFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

const deleteDepartmentRequest = () => {
  return {
    type: DELETE_DEPARTMENT_REQUEST,
  };
};

const deleteDepartmentSuccess = (payload) => {
  return {
    type: DELETE_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};

const deleteDepartmentFaliure = (error) => {
  return {
    type: DELETE_DEPARTMENT_FAILURE,
    payload: error,
  };
};

const deleteDepartment = (departmentId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteDepartmentRequest());
    let url = `${userManagementUrl}/department/delete?department_id=${departmentId}`;
    return axios
      .put(url, {}, headers)
      .then((response) => {
        dispatch(deleteDepartmentSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteDepartmentFaliure(err.response));
        return { status: false, data: err.response };
      });
  };
};

export { getDepartments, createDepartment, updateDepartment, deleteDepartment };
