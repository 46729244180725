import {
  GET_LOCATIONS_FOR_SHOP_FAILURE,
  GET_LOCATIONS_FOR_SHOP_REQUEST,
  GET_LOCATIONS_FOR_SHOP_SUCCESS,
  GETWAREHOUSE_FOR_SHOP_FAILURE,
  GETWAREHOUSE_FOR_SHOP_REQUEST,
  GETWAREHOUSE_FOR_SHOP_SUCCESS,
} from "./shopWarehouseTypes";

import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseForAssemblyRequest = () => {
  return {
    type: GETWAREHOUSE_FOR_SHOP_REQUEST,
  };
};

const getWarehouseForAssemblySuccess = (payload) => {
  return {
    type: GETWAREHOUSE_FOR_SHOP_SUCCESS,
    payload: payload,
  };
};

const getWarehouseForAssemblyFailure = (error) => {
  return {
    type: GETWAREHOUSE_FOR_SHOP_FAILURE,
    payload: error,
  };
};

const getWarehouseForAssembly = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getWarehouseForAssemblyRequest());
    let url = `${baseUrl}/warehouse/get_by_type?warehouseTypeId=1`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getWarehouseForAssemblySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseForAssemblyFailure(err.message));
      });
  };
};
const getLocationsForAssemblyRequest = () => {
  return {
    type: GET_LOCATIONS_FOR_SHOP_REQUEST,
  };
};

const getLocationsForAssemblySuccess = (payload) => {
  return {
    type: GET_LOCATIONS_FOR_SHOP_SUCCESS,
    payload: payload,
  };
};

const getLocationsForAssemblyFailure = (error) => {
  return {
    type: GET_LOCATIONS_FOR_SHOP_FAILURE,
    payload: error,
  };
};

const getLocationsForAssembly = (warehouseIdSelected,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getLocationsForAssemblyRequest());
    let url = `${baseUrl}/assembly/get_zones?warehouseId=${warehouseIdSelected}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getLocationsForAssemblySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsForAssemblyFailure(err.message));
      });
  };
};

export { getWarehouseForAssembly, getLocationsForAssembly };
