
import {
  GET_ASNDETAILS_REQUEST,
  GET_ASNDETAILS_SUCCESS ,
  GET_ASNDETAILS_FAILURE,
  GET_BINS_REQUEST,
  GET_BINS_SUCCESS,
  GET_BINS_FAILURE,
  GET_LAST_ASN_DETAILS_REQUEST,
  GET_LAST_ASN_DETAILS_SUCCESS,
  GET_LAST_ASN_DETAILS_FAILURE
  } from "./asnDetailsTypes"; 





 const initialState = {
     loading: false,
     asnDetails : {},
     error: "",
 };
 

 const asnDetailsReducer = (state = initialState, action) => {
     switch (action.type) {
         case GET_ASNDETAILS_REQUEST:
             return {
                 ...state,
                 loading: true, 
             }; 
         case  GET_ASNDETAILS_SUCCESS:
             return {
                 loading: false,
                 asnDetails : action.payload,
                 error: "",
             };
         case GET_ASNDETAILS_FAILURE:
             return {
                 loading: false,
                 asnDetails : {},
                 error: action.payload,
             };
         default:
             return state;
     }
 };

 const initialStateForBins = {
    loading: false,
    bins : {},
    error: "",
};


const binsReducer = (state = initialStateForBins, action) => {
    switch (action.type) {
        case GET_BINS_REQUEST:
            return {
                ...state,
                loading: true, 
            }; 
        case  GET_BINS_SUCCESS:
            return {
                loading: false,
                bins : action.payload,
                error: "",
            };
        case GET_BINS_FAILURE:
            return {
                loading: false,
                bins : {},
                error: action.payload,
            };
        default:
            return state;
    }
};

 const initialStateForLastAsn = {
  loading: false,
  lastAsnDetails : {},
  error: "",
};


const lastAsnDetailsReducer = (state = initialStateForLastAsn, action) => {
  switch (action.type) {
      case GET_LAST_ASN_DETAILS_REQUEST:
          return {
              ...state,
              loading: true, 
          }; 
      case  GET_LAST_ASN_DETAILS_SUCCESS:
          return {
              loading: false,
              lastAsnDetails : action.payload,
              error: "",
          };
      case GET_LAST_ASN_DETAILS_FAILURE:
          return {
              loading: false,
              lastAsnDetails : {},
              error: action.payload,
          };
      default:
          return state;
  }
};

 
 export {asnDetailsReducer, binsReducer, lastAsnDetailsReducer};
 