export const GET_TRANSFERORDERS_REQUEST = "GET_TRANSFERORDERS_REQUEST";
export const GET_TRANSFERORDERS_SUCCESS = "GET_TRANSFERORDERS_SUCCESS";
export const GET_TRANSFERORDERS_FAILURE = "GET_TRANSFERORDERS_FAILURE";

export const CREATE_TRANSFER_ORDER_REQUEST = "CREATE_TRANSFER_ORDER_REQUEST";
export const CREATE_TRANSFER_ORDER_SUCCESS = "CREATE_TRANSFER_ORDER_SUCCESS";
export const CREATE_TRANSFER_ORDER_FAILURE = "CREATE_TRANSFER_ORDER_FAILURE";

export const CREATE_TRANSFER_ORDER_FOR_QTY_REQUEST =
  "CREATE_TRANSFER_ORDER_FOR_QTY_REQUEST";
export const CREATE_TRANSFER_ORDER_FOR_QTY_SUCCESS =
  "CREATE_TRANSFER_ORDER_FOR_QTY_SUCCESS";
export const CREATE_TRANSFER_ORDER_FOR_QTY_FAILURE =
  "CREATE_TRANSFER_ORDER_FOR_QTY_FAILURE";

export const GET_SUIDS_REQUEST = "GET_SUIDS_REQUEST";
export const GET_SUIDS_SUCCESS = "GET_SUIDS_SUCCESS";
export const GET_SUIDS_FAILURE = "GET_SUIDS_FAILURE";

export const GET_AVAILABLEQTYFORTO_REQUEST = "GET_AVAILABLEQTYFORTO_REQUEST";
export const GET_AVAILABLEQTYFORTO_SUCCESS = "GET_AVAILABLEQTYFORTO_SUCCESS";
export const GET_AVAILABLEQTYFORTO_FAILURE = "GET_AVAILABLEQTYFORTO_FAILURE";
