import {
  GET_AGEING_PARAMETER_FAILURE,
  GET_AGEING_PARAMETER_REQUEST,
  GET_AGEING_PARAMETER_SUCCESS,
  GET_MVT_BY_DOC_FAILURE,
  GET_MVT_BY_DOC_REQUEST,
  GET_MVT_BY_DOC_SUCCESS,
  GET_PICKLIST_AGEING_FAILURE,
  GET_PICKLIST_AGEING_REQUEST,
  GET_PICKLIST_AGEING_SUCCESS,
} from "./picklistAgeingTypes";
import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getPicklistAgeingRequest = () => {
  return {
    type: GET_PICKLIST_AGEING_REQUEST,
  };
};

const getPicklistAgeingSuccess = (payload) => {
  return {
    type: GET_PICKLIST_AGEING_SUCCESS,
    payload: payload,
  };
};

const getPicklistAgeingFailure = (error) => {
  return {
    type: GET_PICKLIST_AGEING_FAILURE,
    payload: error,
  };
};

const getPicklistAgeing = (documentTypeSelected,movementTypeSelected,parameterSelected,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getPicklistAgeingRequest());
    let url = `${baseUrl}/reports/picklist_ageing?documentId=${documentTypeSelected}&mvtId=${movementTypeSelected}&ageingValue=${parameterSelected}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPicklistAgeingSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getPicklistAgeingFailure(err.message));
      });
  };
};

const getAgeingParameterRequest = () => {
  return {
    type: GET_AGEING_PARAMETER_REQUEST,
  };
};

const getAgeingParameterSuccess = (payload) => {
  return {
    type: GET_AGEING_PARAMETER_SUCCESS,
    payload: payload,
  };
};

const getAgeingParameterFailure = (error) => {
  return {
    type: GET_AGEING_PARAMETER_FAILURE,
    payload: error,
  };
};

const getAgeingParameter = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAgeingParameterRequest());
    let url = `${baseUrl}/reports/ageing/get_all`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAgeingParameterSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAgeingParameterFailure(err.message));
      });
  };
};
const getMovementByDocumentIdRequest = () => {
  return {
    type: GET_MVT_BY_DOC_REQUEST,
  };
};

const getMovementByDocumentIdSuccess = (payload) => {
  return {
    type: GET_MVT_BY_DOC_SUCCESS,
    payload: payload,
  };
};

const getMovementByDocumentIdFailure = (error) => {
  return {
    type: GET_MVT_BY_DOC_FAILURE,
    payload: error,
  };
};

const getMovementByDocumentId = (documentTypeSelected,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getMovementByDocumentIdRequest());
    let url = `${baseUrl}/document_mvt_map/by_doc_id?documentId=${documentTypeSelected}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getMovementByDocumentIdSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getMovementByDocumentIdFailure(err.message));
      });
  };
};


export { getPicklistAgeing ,getAgeingParameter,getMovementByDocumentId};
