import {
  GET_TRANSFERORDERS_REQUEST,
  GET_TRANSFERORDERS_SUCCESS,
  GET_TRANSFERORDERS_FAILURE,
  GET_SUIDS_REQUEST,
  GET_SUIDS_SUCCESS,
  GET_SUIDS_FAILURE,
  GET_AVAILABLEQTYFORTO_REQUEST,
  GET_AVAILABLEQTYFORTO_SUCCESS,
  GET_AVAILABLEQTYFORTO_FAILURE,
} from "./transferOrderTypes";

const initialStateForAllTransferOrders = {
  loading: false,
  transferOrders: [],
  error: "",
};

const getAllTransferOrdersReducer = (
  state = initialStateForAllTransferOrders,
  action
) => {
  switch (action.type) {
    case GET_TRANSFERORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSFERORDERS_SUCCESS:
      return {
        loading: false,
        transferOrders: action.payload,
        error: "",
      };
    case GET_TRANSFERORDERS_FAILURE:
      return {
        loading: false,
        transferOrders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSuids = {
  loading: false,
  suids: [],
  error: "",
};

const getSuidsReducer = (state = initialStateForSuids, action) => {
  switch (action.type) {
    case GET_SUIDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUIDS_SUCCESS:
      return {
        loading: false,
        suids: action.payload,
        error: "",
      };
    case GET_SUIDS_FAILURE:
      return {
        loading: false,
        suids: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAvailableQty = {
  loading: false,
  availableQty: {},
  error: "",
};

const getAvailableQtyForToReducer = (
  state = initialStateForAvailableQty,
  action
) => {
  switch (action.type) {
    case GET_AVAILABLEQTYFORTO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AVAILABLEQTYFORTO_SUCCESS:
      return {
        loading: false,
        availableQty: action.payload,
        error: "",
      };
    case GET_AVAILABLEQTYFORTO_FAILURE:
      return {
        loading: false,
        availableQty: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAllTransferOrdersReducer,
  getSuidsReducer,
  getAvailableQtyForToReducer,
};
