import {
  GETZONES_BYCATEGORYANDWAREHOUSE_REQUEST,
  GETZONES_BYCATEGORYANDWAREHOUSE_SUCCESS,
  GETZONES_BYCATEGORYANDWAREHOUSE_FAILURE,
  GETOPENCATEGORYZONES_BYWAREHOUSE_REQUEST,
  GETOPENCATEGORYZONES_BYWAREHOUSE_SUCCESS,
  GETOPENCATEGORYZONES_BYWAREHOUSE_FAILURE,
  GETALLCATEGORIES_REQUEST,
  GETALLCATEGORIES_SUCCESS,
  GETALLCATEGORIES_FAILURE,
  ADDZONE_TOWAREHOUSE_REQUEST,
  ADDZONE_TOWAREHOUSE_SUCCESS,
  ADDZONE_TOWAREHOUSE_FAILURE,
  GETZONES_BYWAREHOUSE_REQUEST,
  GETZONES_BYWAREHOUSE_SUCCESS,
  GETZONES_BYWAREHOUSE_FAILURE,
  GETOPENBINS_BYWAREHOUSE_REQUEST,
  GETOPENBINS_BYWAREHOUSE_SUCCESS,
  GETOPENBINS_BYWAREHOUSE_FAILURE,
  GETZONES_BYITEMCODE_REQUEST,
  GETZONES_BYITEMCODE_SUCCESS,
  GETZONES_BYITEMCODE_FAILURE,
  GETLOCATIONS_BYITEMCODE_REQUEST,
  GETLOCATIONS_BYITEMCODE_SUCCESS,
  GETLOCATIONS_BYITEMCODE_FAILURE,
} from "./zoneCategoryTypes";

const initialStateForZonesByCategoryAndWarehouse = {
  loading: false,
  zones: [],
  error: "",
};

const getZonesByCategoryAndWarehouseReducer = (
  state = initialStateForZonesByCategoryAndWarehouse,
  action
) => {
  switch (action.type) {
    case GETZONES_BYCATEGORYANDWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETZONES_BYCATEGORYANDWAREHOUSE_SUCCESS:
      return {
        loading: false,
        zones: action.payload,
        error: "",
      };
    case GETZONES_BYCATEGORYANDWAREHOUSE_FAILURE:
      return {
        loading: false,
        zones: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForOpenCategoryZonesByWarehouse = {
  loading: false,
  zones: [],
  error: "",
};

const getOpenCategoryZonesByWarehouseReducer = (
  state = initialStateForOpenCategoryZonesByWarehouse,
  action
) => {
  switch (action.type) {
    case GETOPENCATEGORYZONES_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETOPENCATEGORYZONES_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        zones: action.payload,
        error: "",
      };
    case GETOPENCATEGORYZONES_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        zones: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCategories = {
  loading: false,
  categories: [],
  error: "",
};

const getAllCategoriesReducer = (state = initialStateForCategories, action) => {
  switch (action.type) {
    case GETALLCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALLCATEGORIES_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GETALLCATEGORIES_FAILURE:
      return {
        loading: false,
        categories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddZoneToWarehouse = {
  loading: false,
  zone: [],
  error: "",
};

const addZoneToWarehouseReducer = (
  state = initialStateForAddZoneToWarehouse,
  action
) => {
  switch (action.type) {
    case ADDZONE_TOWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADDZONE_TOWAREHOUSE_SUCCESS:
      return {
        loading: false,
        zone: action.payload,
        error: "",
      };
    case ADDZONE_TOWAREHOUSE_FAILURE:
      return {
        loading: false,
        zone: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

/**********************************************************************************************/

const initialStateForZonesByWarehouse = {
  loading: false,
  zones: [],
  error: "",
};

const getZonesByWarehouseReducer = (
  state = initialStateForZonesByWarehouse,
  action
) => {
  switch (action.type) {
    case GETZONES_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETZONES_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        zones: action.payload,
        error: "",
      };
    case GETZONES_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        zones: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForOpenBinsByWarehouse = {
  loading: false,
  bins: [],
  error: "",
};

const getOpenBinsByWarehouseReducer = (
  state = initialStateForOpenBinsByWarehouse,
  action
) => {
  switch (action.type) {
    case GETOPENBINS_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETOPENBINS_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        bins: action.payload,
        error: "",
      };
    case GETOPENBINS_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        bins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getZonesByCategoryAndWarehouseReducer,
  getOpenCategoryZonesByWarehouseReducer,
  getAllCategoriesReducer,
  addZoneToWarehouseReducer,
  getZonesByWarehouseReducer,
  getOpenBinsByWarehouseReducer,
};
