import {
  GET_QUARANTINE_REQUEST,
  GET_QUARANTINE_SUCCESS,
  GET_QUARANTINE_FAILURE,
  GET_ACCEPTED_QUARANTINES_REQUEST,
  GET_ACCEPTED_QUARANTINES_SUCCESS,
  GET_ACCEPTED_QUARANTINES_FAILURE,
  GET_SKU_BYSKUSTATUS_REQUEST,
  GET_SKU_BYSKUSTATUS_SUCCESS,
  GET_SKU_BYSKUSTATUS_FAILURE,
  GET_ITEMCODE_BYWAREHOUSE_REQUEST,
  GET_ITEMCODE_BYWAREHOUSE_SUCCESS,
  GET_ITEMCODE_BYWAREHOUSE_FAILURE,
  GET_LOCATIONS_FOR_QUARANTINE_REQUEST,
  GET_LOCATIONS_FOR_QUARANTINE_SUCCESS,
  GET_LOCATIONS_FOR_QUARANTINE_FAILURE,
  GET_SKUSTATUS_BYLOCATION_REQUEST,
  GET_SKUSTATUS_BYLOCATION_SUCCESS,
  GET_SKUSTATUS_BYLOCATION_FAILURE,
  GET_SKU_BY_ITEMCODE_REQUEST,
  GET_SKU_BY_ITEMCODE_SUCCESS,
  GET_SKU_BY_ITEMCODE_FAILURE,
  GET_SKU_BYDOCUMENTNO_REQUEST,
  GET_SKU_BYDOCUMENTNO_SUCCESS,
  GET_SKU_BYDOCUMENTNO_FAILURE,
  GET_ACN_TYPES_REQUEST,
  GET_ACN_TYPES_SUCCESS,
  GET_ACN_TYPES_FAILURE,
} from "./quarantineTypes";

const initialStateForQuarantine = {
  loading: false,
  quarantine: [],
  error: "",
};

const quarantineReducer = (state = initialStateForQuarantine, action) => {
  switch (action.type) {
    case GET_QUARANTINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_QUARANTINE_SUCCESS:
      return {
        loading: false,
        quarantine: action.payload,
        error: "",
      };
    case GET_QUARANTINE_FAILURE:
      return {
        loading: false,
        quarantine: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForAcn = {
  loading: false,
  acn: [],
  error: "",
};

const acnTypeReducer = (state = initialStateForAcn, action) => {
  switch (action.type) {
    case GET_ACN_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACN_TYPES_SUCCESS:
      return {
        loading: false,
        acn: action.payload,
        error: "",
      };
    case GET_ACN_TYPES_FAILURE:
      return {
        loading: false,
        acn: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForLocation = {
  loading: false,
  locations: [],
  error: "",
};

const locationReducer = (state = initialStateForLocation, action) => {
  switch (action.type) {
    case GET_LOCATIONS_FOR_QUARANTINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS_FOR_QUARANTINE_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        error: "",
      };
    case GET_LOCATIONS_FOR_QUARANTINE_FAILURE:
      return {
        loading: false,
        locations: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForSkuStatus = {
  loading: false,
  skuStatus: [],
  error: "",
};

const getSkuStatusByLocationAndItemCodeReducer = (
  state = initialStateForSkuStatus,
  action
) => {
  switch (action.type) {
    case GET_SKUSTATUS_BYLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKUSTATUS_BYLOCATION_SUCCESS:
      return {
        loading: false,
        skuStatus: action.payload,
        error: "",
      };
    case GET_SKUSTATUS_BYLOCATION_FAILURE:
      return {
        loading: false,
        skuStatus: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForSkuByItemCode = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuByItemCodeReducer = (
  state = initialStateForSkuByItemCode,
  action
) => {
  switch (action.type) {
    case GET_SKU_BY_ITEMCODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKU_BY_ITEMCODE_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GET_SKU_BY_ITEMCODE_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForSkuByDocumentNo = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuByDocumentNoReducer = (
  state = initialStateForSkuByDocumentNo,
  action
) => {
  switch (action.type) {
    case GET_SKU_BYDOCUMENTNO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKU_BYDOCUMENTNO_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GET_SKU_BYDOCUMENTNO_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSku = {
  loading: false,
  sku: [],
  error: "",
};

const skuBySkuStatusReducer = (state = initialStateForSku, action) => {
  switch (action.type) {
    case GET_SKU_BYSKUSTATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKU_BYSKUSTATUS_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GET_SKU_BYSKUSTATUS_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateForItemCode = {
  loading: false,
  itemCodes: [],
  error: "",
};

const itemCodeByWarehouseReducer = (
  state = initialStateForItemCode,
  action
) => {
  switch (action.type) {
    case GET_ITEMCODE_BYWAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMCODE_BYWAREHOUSE_SUCCESS:
      return {
        loading: false,
        itemCodes: action.payload,
        error: "",
      };
    case GET_ITEMCODE_BYWAREHOUSE_FAILURE:
      return {
        loading: false,
        itemCodes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAcceptedQuarantines = {
  loading: false,
  acceptedQuarantines: [],
  error: "",
};

const acceptedQuarantinesReducer = (
  state = initialStateForAcceptedQuarantines,
  action
) => {
  switch (action.type) {
    case GET_ACCEPTED_QUARANTINES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCEPTED_QUARANTINES_SUCCESS:
      return {
        loading: false,
        acceptedQuarantines: action.payload,
        error: "",
      };
    case GET_ACCEPTED_QUARANTINES_FAILURE:
      return {
        loading: false,
        acceptedQuarantines: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  quarantineReducer,
  acceptedQuarantinesReducer,
  skuBySkuStatusReducer,
  itemCodeByWarehouseReducer,
  locationReducer,
  getSkuStatusByLocationAndItemCodeReducer,
  getSkuByItemCodeReducer,
  getSkuByDocumentNoReducer,
  acnTypeReducer,
};
