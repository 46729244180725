/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

// Functions from store
import { getRejection } from "../../store";

const RejectionHomePageCard = ({ getRejection, rejection }) => {
  useEffect(() => {
    getRejection();
  }, []);

  const [lessThanThirty, setLessThanThirty] = useState(0);
  const [thirtyOneToSixty, setThirtyOneToSixty] = useState(0);
  const [sixtyOneToNinety, setSixtyOneToNinety] = useState(0);
  const [greaterThanNinety, setGreaterThanNinety] = useState(0);
  const [expired, setExpired] = useState(0);

  useEffect(() => {
    setLessThanThirty(rejection.rejection.lessThanThirty);
    setThirtyOneToSixty(rejection.rejection.thirtyOneToSixty);
    setSixtyOneToNinety(rejection.rejection.sixtyOneToNinety);
    setGreaterThanNinety(rejection.rejection.greaterThanNinety);
    setExpired(rejection.rejection.expired);
  }, [rejection]);

  const chartData = {
    labels: ["< 30 *", "31-60 *", "61-90 *", "> 90 *", "Expired"],
    datasets: {
      label: "Rejection",
      data: [
        lessThanThirty,
        thirtyOneToSixty,
        sixtyOneToNinety,
        greaterThanNinety,
        expired,
      ],
    },
  };

  return (
    <ReportsBarChart
      color="dark"
      title="Rejection"
      chart={chartData}
      description="* in days"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    rejection: state.getRejectionHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRejection: () => dispatch(getRejection()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RejectionHomePageCard);
