import {
  GET_DOCUMENT_TYPE_TURN_FAILURE,
  GET_DOCUMENT_TYPE_TURN_REQUEST,
  GET_DOCUMENT_TYPE_TURN_SUCCESS,
} from "./document-typeTurnTypes";
import axios from "axios";
import Config from "../../../config/index";

let baseUrl = Config.baseUrl;

const getDocumentTypeTurnAroundTimeRequest = () => {
  return {
    type: GET_DOCUMENT_TYPE_TURN_REQUEST,
  };
};

const getDocumentTypeTurnAroundTimeSuccess = (payload) => {
  return {
    type: GET_DOCUMENT_TYPE_TURN_SUCCESS,
    payload: payload,
  };
};

const getDocumentTypeTurnAroundTimeFailure = (error) => {
  return {
    type: GET_DOCUMENT_TYPE_TURN_FAILURE,
    payload: error,
  };
};

const getDocumentTypeTurnAroundTime = (documentId,jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getDocumentTypeTurnAroundTimeRequest());
    let url = `${baseUrl}/reports/doc_type_turn_around_time?documentId=${documentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getDocumentTypeTurnAroundTimeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getDocumentTypeTurnAroundTimeFailure(err.message));
      });
  };
};

export { getDocumentTypeTurnAroundTime };
