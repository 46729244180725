/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ALLPRINTERS_REQUEST,
  GET_ALLPRINTERS_SUCCESS,
  GET_ALLPRINTERS_FAILURE,
  PUT_PRINTERCONFIGURATION_REQUEST,
  PUT_PRINTERCONFIGURATION_SUCCESS,
  PUT_PRINTERCONFIGURATION_FAILURE,
} from "./printerConfigTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getAllPrintersRequest = () => {
  return {
    type: GET_ALLPRINTERS_REQUEST,
  };
};

const getAllPrintersSuccess = (payload) => {
  return {
    type: GET_ALLPRINTERS_SUCCESS,
    payload: payload,
  };
};

const getAllPrintersFaliure = (error) => {
  return {
    type: GET_ALLPRINTERS_FAILURE,
    payload: error,
  };
};

const getAllPrinters = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllPrintersRequest());
    let url = `${baseUrl}/printer/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllPrintersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAllPrintersFaliure(err.message));
      });
  };
};

const printerConfigRequest = () => {
  return {
    type: PUT_PRINTERCONFIGURATION_REQUEST,
  };
};

const printerConfigSuccess = (payload) => {
  return {
    type: PUT_PRINTERCONFIGURATION_SUCCESS,
    payload: payload,
  };
};

const printerConfigFailure = (error) => {
  return {
    type: PUT_PRINTERCONFIGURATION_FAILURE,
    payload: error,
  };
};

const printerConfig = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(printerConfigRequest());
    let url = `${baseUrl}/printer/assign`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(printerConfigSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(printerConfigFailure(err.response));
        return { status: false, message: err.response };
      });
  };
};

export { getAllPrinters, printerConfig };
