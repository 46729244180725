/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import {
  GET_ALLPRINTERS_REQUEST,
  GET_ALLPRINTERS_SUCCESS,
  GET_ALLPRINTERS_FAILURE,
  PUT_PRINTERCONFIGURATION_REQUEST,
  PUT_PRINTERCONFIGURATION_SUCCESS,
  PUT_PRINTERCONFIGURATION_FAILURE,
} from "./printerConfigTypes";

const initialStateForAllPrinters = {
  loading: false,
  printers: [],
  error: "",
};

const allPrintersReducer = (state = initialStateForAllPrinters, action) => {
  switch (action.type) {
    case GET_ALLPRINTERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLPRINTERS_SUCCESS:
      return {
        loading: false,
        printers: action.payload,
        error: "",
      };
    case GET_ALLPRINTERS_FAILURE:
      return {
        loading: false,
        printers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPrinterConfig = {
  loading: false,
  printer: {},
  error: "",
};

const printerConfigReducer = (state = initialStateForPrinterConfig, action) => {
  switch (action.type) {
    case PUT_PRINTERCONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_PRINTERCONFIGURATION_SUCCESS:
      return {
        loading: false,
        printer: action.payload,
        error: "",
      };
    case PUT_PRINTERCONFIGURATION_FAILURE:
      return {
        loading: false,
        printer: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { allPrintersReducer, printerConfigReducer };
