export const GET_WAREHOUSEOCCUPANCY_REQUEST = "GET_WAREHOUSEOCCUPANCY_REQUEST";
export const GET_WAREHOUSEOCCUPANCY_SUCCESS = "GET_WAREHOUSEOCCUPANCY_SUCCESS";
export const GET_WAREHOUSEOCCUPANCY_FAILURE = "GET_WAREHOUSEOCCUPANCY_FAILURE";

export const DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST =
  "DOWNLOAD_WAREHOUSEOCCUPANCY_REQUEST";
export const DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS =
  "DOWNLOAD_WAREHOUSEOCCUPANCY_SUCCESS";
export const DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE =
  "DOWNLOAD_WAREHOUSEOCCUPANCY_FAILURE";

export const EDIT_EXPIRY_REQUEST = "EDIT_EXPIRY_REQUEST";
export const EDIT_EXPIRY_SUCCESS = "EDIT_EXPIRY_SUCCESS";
export const EDIT_EXPIRY_FAILURE = "EDIT_EXPIRY_FAILURE";

export const CREATE_DOCUMENT_UPLOAD_REQUEST = "CREATE_DOCUMENT_UPLOAD_REQUEST";
export const CREATE_DOCUMENT_UPLOAD_SUCCESS = "CREATE_DOCUMENT_UPLOAD_SUCCESS";
export const CREATE_DOCUMENT_UPLOAD_FAILURE = "CREATE_DOCUMENT_UPLOAD_FAILURE";
