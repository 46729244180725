/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {  useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import PdiHomePageCard from "layouts/home-cards/pdiHomePageCard";
import FgHomePageCard from "layouts/home-cards/fgHomePageCard";
import RejectionHomePageCard from "layouts/home-cards/rejectionHomePageCard";
import InventoryHomePageCard from "layouts/home-cards/inventoryHomePageCard";
import WarehouseOccupancyHomePageCard from "layouts/home-cards/warehouseOccupancyHomePageCard";
import PickupHomePageCard from "layouts/home-cards/pickupHomePageCard";
import YardOccupancyHomePageCard from "layouts/home-cards/yardOccupancyHomePageCard";

// Functions from store
import { getSubModulesByRoleId } from "../../store";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Home = ({ getSubModulesByRoleId, getSubModulesByRoleIdReducer }) => {
  // let loginDetails = cookies.get("loginDetailsForWMS");
  // let role = loginDetails.data.role;

  // let jwtToken = loginDetails?.jwt;
  // let roleId = loginDetails.data.roleId;

  // useEffect(() => {
  //   getSubModulesByRoleId(roleId, jwtToken);
  // }, [roleId]);

  // const [modulesData, setModulesData] = useState([]);

  // useEffect(() => {
  //   setModulesData(
  //     getSubModulesByRoleIdReducer.subModulesByRoleData.data
  //       ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
  //       : []
  //   );
  // }, [getSubModulesByRoleIdReducer]);

  // let fgModule = modulesData.find(
  //   (moduleId) => moduleId.moduleId == "2f659fff-92ba-4cef-a0d7-2699a30d5248"
  // );

  // let rejectionModule = modulesData
  //   ? modulesData.find(
  //       (moduleId) =>
  //         moduleId.parentModuleId == "5cb29527-adf2-440f-938f-42937a6c9fab"
  //     )
  //   : "";

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 500);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <Grid container spacing={4} mt={5}>
          <Grid item xs={12} md={6} lg={3}>
            <WarehouseOccupancyHomePageCard />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <YardOccupancyHomePageCard />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <PdiHomePageCard />
          </Grid>
         
            <Grid item xs={12} md={6} lg={3}>
              <FgHomePageCard />
            </Grid>
          

         
            <Grid item xs={12} md={6} lg={3} mt={3}>
              <RejectionHomePageCard />
            </Grid>
          
          <Grid item xs={12} md={6} lg={3} mt={3}>
            <InventoryHomePageCard />
          </Grid>
          <Grid item xs={12} md={6} lg={3} mt={3}>
            <PickupHomePageCard />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <CircularProgress color="info" />
        </div>
      )}
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Home);
