import { CREATE_PRODUCTION_PLAN_FAILURE, CREATE_PRODUCTION_PLAN_REQUEST, CREATE_PRODUCTION_PLAN_SUCCESS, DELETE_PRODUCTION_PLAN_FAILURE, DELETE_PRODUCTION_PLAN_REQUEST, DELETE_PRODUCTION_PLAN_SUCCESS, EDIT_REQUIRED_QTY_FAILURE, EDIT_REQUIRED_QTY_REQUEST, EDIT_REQUIRED_QTY_SUCCESS, GET_PRODUCTION_PLAN_FAILURE, GET_PRODUCTION_PLAN_REQUEST, GET_PRODUCTION_PLAN_SUCCESS, PUBLISH_PRODUCTION_PLAN_FAILURE, PUBLISH_PRODUCTION_PLAN_REQUEST, PUBLISH_PRODUCTION_PLAN_SUCCESS } from "./productionPlanTypes";

const initialStateForGetProduction = {
    loading: false,
    production: [],
    error: "",
  };
  
  const getProductionPlanReducer = (
    state = initialStateForGetProduction,
    action
  ) => {
    switch (action.type) {
      case GET_PRODUCTION_PLAN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_PRODUCTION_PLAN_SUCCESS:
        return {
          loading: false,
          production: action.payload,
          error: "",
        };
      case GET_PRODUCTION_PLAN_FAILURE:
        return {
          loading: false,
          production: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

    
  const initialStateForCreate = {
    loading: false,
    createProduction: {},
    error: "",
  };
  
  const createProductionPlanReducer = (
    state = initialStateForCreate,
    action
  ) => {
    switch (action.type) {
      case CREATE_PRODUCTION_PLAN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_PRODUCTION_PLAN_SUCCESS:
        return {
          loading: false,
          createProduction: action.payload,
          error: "",
        };
      case CREATE_PRODUCTION_PLAN_FAILURE:
        return {
          loading: false,
          createProduction: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };

    const initialStateForEdit = {
      loading: false,
      reqEdit: {},
      error: "",
    };
    
    const editRequiredQtyReducer = (state = initialStateForEdit, action) => {
      switch (action.type) {
        case EDIT_REQUIRED_QTY_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case EDIT_REQUIRED_QTY_SUCCESS:
          return {
            loading: true,
            reqEdit: action.payload,
            error: "",
          };
        case EDIT_REQUIRED_QTY_FAILURE:
          return {
            loading: false,
            reqEdit: {},
            error: action.payload,
          };
        default:
          return state;
      }
    };

  
  const initialStateForDelete = {
    loading: false,
    deletePlan: {},
    error: "",
  };
  
  const deleteProductionPlanReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
      case DELETE_PRODUCTION_PLAN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_PRODUCTION_PLAN_SUCCESS:
        return {
          loading: true,
          deletePlan: action.payload,
          error: "",
        };
      case DELETE_PRODUCTION_PLAN_FAILURE:
        return {
          loading: false,
          deletePlan: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const initialStateForPublish = {
    loading: false,
    publish: {},
    error: "",
  };
  
  const publishProductionPlanReducer = (
    state = initialStateForPublish,
    action
  ) => {
    switch (action.type) {
      case PUBLISH_PRODUCTION_PLAN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case PUBLISH_PRODUCTION_PLAN_SUCCESS:
        return {
          loading: false,
          publish: action.payload,
          error: "",
        };
      case PUBLISH_PRODUCTION_PLAN_FAILURE:
        return {
          loading: false,
          publish: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };



  

  export {
    getProductionPlanReducer,
    createProductionPlanReducer,
    editRequiredQtyReducer,
    deleteProductionPlanReducer,
    publishProductionPlanReducer
  };