/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

//date -fns
import { format, addMinutes } from "date-fns";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getItemsForWorkOrder,
  getSubModulesByRoleId,
  getWorkOrder,
} from "../../store";

// Cookies
import Cookies from "universal-cookie";

// config file
import Config from "../../config/index";
import { CircularProgress } from "@mui/material";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  boxShadow: 24,
  p: 0,
  pt: 3,
};

const WorkOrder = ({
  getSubModulesByRoleIdReducer,
  getSubModulesByRoleId,
  getWorkOrder,
  getWorkOrderReducer,
  getItemsForWorkOrder,
  getItemsForWorkOrderReducer,
}) => {
  let loginDetails = cookies.get("loginDetailsForWMS");
  let jwtToken = loginDetails?.jwt;
  let roleId = loginDetails.data.roleId;

  useEffect(() => {
    getSubModulesByRoleId(roleId, jwtToken);
  }, [roleId]);
  useEffect(() => {
    getWorkOrder(jwtToken);
  }, []);

  const [serialNo, setSerialNo] = useState("");
  const [fgCode, setFgCode] = useState("");

  useEffect(() => {
    getItemsForWorkOrder(serialNo, jwtToken);
  }, [serialNo]);

  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    setModulesData(
      getSubModulesByRoleIdReducer.subModulesByRoleData.data
        ? getSubModulesByRoleIdReducer.subModulesByRoleData.data
        : []
    );
  }, [getSubModulesByRoleIdReducer]);

  let workOrderModule = modulesData.find(
    (moduleId) => moduleId.moduleId == "efe908ae-fe35-4fc7-bec7-64368f5adc4e"
  );

  let viewWorkOrder = workOrderModule
    ? workOrderModule.actionId.includes("713b152a-2f32-4f0c-a7ed-bf0c468d3712")
    : null;

  const [state, setState] = useState({
    columns: [
      { Header: "FG Code", accessor: "fgModelCode" },
      { Header: "Work Order No", accessor: "workOrderNumber" },
      { Header: "VIN", accessor: "vinNumber" },
      { Header: "Serial No", accessor: "serialNo" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getWorkOrderReducer.workOrder
      ? getWorkOrderReducer.workOrder
      : [];
    setLoading(getWorkOrderReducer.loading);
    data?.map((finishedGoods) => {
      const finishedGoodsObject = {
        fgModelCode: finishedGoods.fgModelCode,
        workOrderNumber: finishedGoods.workOrderNumber,
        serialNo: finishedGoods.serialNo,
        vinNumber: finishedGoods.vinNumber,
        action: (
          <Tooltip title="View">
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => {
                setSerialNo(finishedGoods.serialNo);
                setFgCode(finishedGoods.fgModelCode);
                handleOpenViewModal();
              }}
              style={{ marginRight: "5px" }}
            >
              <Icon>visibility</Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });

    viewWorkOrder && setState({ ...state, rows: tempFinishedGoods });
  }, [getWorkOrderReducer]);

  const [itemTable, setItemTable] = useState({
    columns: [
      { Header: "Item Code", accessor: "itemCode" },
      { Header: "Item Description", accessor: "itemDesc" },
      { Header: "Control Type", accessor: "controlType" },
      { Header: "Serial / LOT", accessor: "serialLotNo" },
      { Header: "Plant ID", accessor: "plantId" },
      { Header: "Required Qty", accessor: "requiredQty" },
      { Header: "Created Date", accessor: "createdDate" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = itemTable;

  const [loading2, setLoading2] = useState(true);
  useEffect(() => {
    let tempFinishedGoods = [];
    let data = getItemsForWorkOrderReducer.itemsForWorkOrder
      ? getItemsForWorkOrderReducer.itemsForWorkOrder
      : [];
    setLoading2(getItemsForWorkOrderReducer.loading);
    data?.map((finishedGoods) => {
      let des = finishedGoods?.description;
      let len = desLength;
      const time = new Date(finishedGoods.cd);
      const finishedGoodsObject = {
        itemCode: finishedGoods.itemCode,
        itemDesc: finishedGoods.itemDescription,
        controlType:
          (finishedGoods.controlType === 0 && `No Control`) ||
          (finishedGoods.controlType === 1 && `Serial Control`) ||
          (finishedGoods.controlType === 2 && `Lot Control`),
        serialLotNo: finishedGoods.serialLotNo
          ? finishedGoods.serialLotNo
          : "NA / NA",
        plantId: finishedGoods.plantId,
        requiredQty: finishedGoods.requiredQuantity,

        createdDate: format(
          addMinutes(time, time.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        fgDescription: des ? des.substring(0, len) : "",
      };
      tempFinishedGoods.push(finishedGoodsObject);
    });
    // viewFG &&
    setItemTable({ ...itemTable, rows: tempFinishedGoods });
  }, [getItemsForWorkOrderReducer]);

  const [viewModal, setOpenViewModal] = useState(false);

  const handleOpenViewModal = () => {
    setTimeout(() => {
      setOpenViewModal(true);
    }, 200);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    tableSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }}
                  >
                    <CircularProgress color="info" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={viewModal}
        onClose={handleCloseViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDCard sx={style}>
          <MDBox ml={3} mt={-1} display="flex" alignItems="center">
            <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
              {fgCode}
            </MDTypography>
            <MDBox></MDBox>
            <MDBox>
              <span>
                <Tooltip title="Close">
                  <IconButton
                    style={{
                      background: "whitesmoke",
                      color: "black",
                      borderRadius: 5,
                      marginLeft: "-55px",
                      marginBottom: "5px",
                    }}
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseViewModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </MDBox>
          </MDBox>
          <hr style={{ marginBottom: "1rem" }} />
          <MDBox>
            {!loading2 ? (
              <DataTable
                table={{ columns: itemColumns, rows: itemRows }}
                isSorted={true}
                tableSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  marginBottom: "100px",
                }}
              >
                <CircularProgress color="info" />
              </div>
            )}
          </MDBox>
        </MDCard>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getSubModulesByRoleIdReducer: state.getSubModulesByRoleIdReducer,
    getWorkOrderReducer: state.getWorkOrderReducer,
    getItemsForWorkOrderReducer: state.getItemsForWorkOrderReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubModulesByRoleId: (roleId, jwtToken) =>
      dispatch(getSubModulesByRoleId(roleId, jwtToken)),
    getWorkOrder: (jwtToken) => dispatch(getWorkOrder(jwtToken)),
    getItemsForWorkOrder: (serialNo, jwtToken) =>
      dispatch(getItemsForWorkOrder(serialNo, jwtToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);
