const { GET_BIN_TYPES_REQUEST, GET_BIN_TYPES_SUCCESS, GET_BIN_TYPES_FAILURE, CREATE_BIN_TYPE_REQUEST, CREATE_BIN_TYPE_SUCCESS, CREATE_BIN_TYPE_FAILURE, EDIT_BIN_TYPE_REQUEST, EDIT_BIN_TYPE_SUCCESS, EDIT_BIN_TYPE_FAILURE, GET_TYPESBY_BINCODE_REQUEST, GET_TYPESBY_BINCODE_SUCCESS, GET_TYPESBY_BINCODE_FAILURE, DELETE_BIN_REQUEST, DELETE_BIN_SUCCESS, DELETE_BIN_FAILURE, EDIT_BIN_REQUEST, EDIT_BIN_SUCCESS, EDIT_BIN_FAILURE, GET_BINS_REQUEST, GET_BINS_SUCCESS, GET_BINS_FAILURE, CREATE_BIN_REQUEST, CREATE_BIN_SUCCESS, CREATE_BIN_FAILURE } = require("./binMasterTypes");

  
  const initialStateForGetBinTypes = {
    loading: false,
    binTypes: [],
    error: "",
  };
  
  const getBintypesReducer = (
    state = initialStateForGetBinTypes,
    action
  ) => {
    switch (action.type) {
      case GET_BIN_TYPES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_BIN_TYPES_SUCCESS:
        return {
          loading: false,
          binTypes: action.payload,
          error: "",
        };
      case GET_BIN_TYPES_FAILURE:
        return {
          loading: false,
          binTypes: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialStateForCreateBinType = {
    loading: false,
    createBinType: {},
    error: "",
  };
  
  const createBinTypeReducer = (
    state = initialStateForCreateBinType,
    action
  ) => {
    switch (action.type) {
      case CREATE_BIN_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_BIN_TYPE_SUCCESS:
        return {
          loading: false,
          createBinType: action.payload,
          error: "",
        };
      case CREATE_BIN_TYPE_FAILURE:
        return {
          loading: false,
          createBinType: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialStateForDelete = {
    loading: false,
    deleteBinType: {},
    error: "",
  };
  
  const deleteBinReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
      case DELETE_BIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_BIN_SUCCESS:
        return {
          loading: true,
          deleteBinType: action.payload,
          error: "",
        };
      case DELETE_BIN_FAILURE:
        return {
          loading: false,
          deleteBinType: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
const initialStateForEdit = {
    loading: false,
    binEdit: {},
    error: "",
  };
  
  const editBinReducer = (state = initialStateForEdit, action) => {
    switch (action.type) {
      case EDIT_BIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case EDIT_BIN_SUCCESS:
        return {
          loading: true,
          binEdit: action.payload,
          error: "",
        };
      case EDIT_BIN_FAILURE:
        return {
          loading: false,
          binEdit: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  const initialStateForBins = {
    loading: false,
    bins: [],
    error: "",
  };
  
  const getBinsReducer = (state = initialStateForBins, action) => {
    switch (action.type) {
      case GET_BINS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_BINS_SUCCESS:
        return {
          loading: false,
          bins: action.payload,
          error: "",
        };
      case GET_BINS_FAILURE:
        return {
          loading: false,
          bins: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  
  const initialStateForCreateBin = {
    loading: false,
    createBin: {},
    error: "",
  };
  
  const createBinReducer = (
    state = initialStateForCreateBin,
    action
  ) => {
    switch (action.type) {
      case CREATE_BIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_BIN_SUCCESS:
        return {
          loading: false,
          createBin: action.payload,
          error: "",
        };
      case CREATE_BIN_FAILURE:
        return {
          loading: false,
          createBin: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export {
    getBinsReducer,
    getBintypesReducer,
    createBinTypeReducer,
    deleteBinReducer,
    editBinReducer,
    createBinReducer    
  };