export const GET_ACTIVECYCLECOUNT_REQUEST = "GET_ACTIVECYCLECOUNT_REQUEST";
export const GET_ACTIVECYCLECOUNT_SUCCESS = "GET_ACTIVECYCLECOUNT_SUCCESS";
export const GET_ACTIVECYCLECOUNT_FAILURE = "GET_ACTIVECYCLECOUNT_FAILURE";

export const GET_COMPLETEDCYCLECOUNT_REQUEST =
  "GET_COMPLETEDCYCLECOUNT_REQUEST";
export const GET_COMPLETEDCYCLECOUNT_SUCCESS =
  "GET_COMPLETEDCYCLECOUNT_SUCCESS";
export const GET_COMPLETEDCYCLECOUNT_FAILURE =
  "GET_COMPLETEDCYCLECOUNT_FAILURE";

export const GET_CYCLECOUNTTYPE_REQUEST = "GET_CYCLECOUNTTYPE_REQUEST";
export const GET_CYCLECOUNTTYPE_SUCCESS = "GET_CYCLECOUNTTYPE_SUCCESS";
export const GET_CYCLECOUNTTYPE_FAILURE = "GET_CYCLECOUNTTYPE_FAILURE";

export const CREATE_CYCLECOUNT_REQUEST = "CREATE_CYCLECOUNT_REQUEST";
export const CREATE_CYCLECOUNT_SUCCESS = "CREATE_CYCLECOUNT_SUCCESS";
export const CREATE_CYCLECOUNT_FAILURE = "CREATE_CYCLECOUNT_FAILURE";

export const GET_WAREHOUSESBYITEMID_REQUEST = "GET_WAREHOUSESBYITEMID_REQUEST";
export const GET_WAREHOUSESBYITEMID_SUCCESS = "GET_WAREHOUSESBYITEMID_SUCCESS";
export const GET_WAREHOUSESBYITEMID_FAILURE = "GET_WAREHOUSESBYITEMID_FAILURE";
