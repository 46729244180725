/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
} from "./deviceOnboardingTypes";

const initialState = {
  loading: false,
  devices: {},
  error: "",
};

const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEVICES_SUCCESS:
      return {
        loading: false,
        devices: action.payload,
        error: "",
      };
    case GET_DEVICES_FAILURE:
      return {
        loading: false,
        devices: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default devicesReducer;
