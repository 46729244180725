import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

// Utils component
import TextError from "utils/TextError";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// @mui material components
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Dashboard layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Functions from store
import { getOtp, validateOtp, setVendorIdHandler } from "../../store";

// Router Components
import { useNavigate } from "react-router-dom";

import banner from "../../assets/banner.jpg";
// Cookies
import Cookies from "universal-cookie";

const Otp = ({
  getOtp,
  otpReducer,
  validateOtp,
  otpValidateReducer,
  setVendorIdHandler,
}) => {
  const notify = () => {
    toast.error("Invalid OTP", {
      position: "top-center",
    });
  };
  const cookies = new Cookies();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [hash, setHash] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const query = window.location.search.substring(1);
  const uuid = query.split("=")[1];
  const [showGenerateMessage, setShowGenerateMessage] = useState(true);

  const OTPHandler = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);
    setShowGenerateMessage(false);

    const response = await getOtp(uuid);
  };

  useEffect(() => {
    let otpData = otpReducer.otpData;
    setHash(otpData.data?.hash);
    setEmail(otpData.data?.email);
    setVendorIdHandler(otpData.data?.vendorUserId);
  }, [otpReducer]);

  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }

  function clearTimer(endtime) {
    setTimer("03:00");
    if (intervalRef.current) clearInterval(intervalRef.current);
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }
  function getDeadlineTime() {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 180);
    return deadline;
  }
  const initialValues = {
    enteredNumber: "",
  };
  const validationSchema = Yup.object({
    enteredNumber: Yup.string().required("Enter the OTP!"),
  });

  const onSubmit = async (values) => {
    let payload = {
      otp: values.enteredNumber,
      email: email,
      hash: hash,
    };

    let data = await validateOtp(payload);

    if (!data.status) {
      notify();
      setErrorMessage("Enter a valid OTP");
    }

    let validator = data.data.data;

    if (
      validator.verification &&
      validator.token &&
      !validator.isUpdatable &&
      !validator.isFilled
    ) {
      cookies.set(
        "vendor-portal-jwtToken",
        JSON.stringify(data.data.data.token),
        {
          path: "/",
        }
      );
      navigate("/standard-vendor-form");
    }
    if (
      validator.verification &&
      validator.token &&
      validator.isUpdatable === 1 &&
      validator.isFilled === 1
    ) {
      cookies.set(
        "vendor-portal-jwtToken",
        JSON.stringify(data.data.data.token),
        {
          path: "/",
        }
      );

      navigate("/standard-vendor-form");
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const tokenGen = () => {
    cookies.set("jwtTokenForVendorDashboard", {
      status: true,
      token: "123456789",
    });
  };

  return (
    <div>
      <BasicLayout image={banner}>
        <Container
          style={{
            display: "flex",

            flexDirection: "column",
            justifyContent: "center",

            marginLeft: "auto",
            marginRight: "auto",
          }}
          fullWidth
        >
          <MDTypography
            variant="h3"
            gutterBottom
            color="white"
            textAlign="center"
            width="800px"
            marginLeft="-210px"
            marginRight="200px"
            marginBottom="40px"
            marginTop="50px"
          >
            Welcome to the Vendor Registration with Mobillor
          </MDTypography>
        </Container>

        <MDCard align="center">
          <MDBox
            variant="gradient"
            style={{ backgroundColor: "red" }}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={0}>
              Verification
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  label="Enter OTP"
                  type="text"
                  name="enteredNumber"
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.enteredNumber}
                  error={
                    formik.touched.enteredNumber &&
                    formik.errors.enteredNumber &&
                    true
                  }
                />
                {formik.touched.enteredNumber &&
                  formik.errors.enteredNumber && (
                    <TextError msg={formik.errors.enteredNumber} />
                  )}
              </MDBox>
              {showGenerateMessage && (
                <MDTypography fontSize="16px" color="dark">
                  Please click on Generate OTP
                </MDTypography>
              )}
              {showMessage && (
                <MDTypography fontSize="15px">
                  Check your mail inbox for the OTP
                </MDTypography>
              )}
              {showMessage && (
                <MDTypography marginBottom="-40px" fontSize="15px">
                  OTP expires in {timer}
                </MDTypography>
              )}
              {timer === "00:00" ? (
                <MDButton
                  color="white"
                  variant="contained"
                  onClick={OTPHandler}
                  style={{
                    marginTop: "45px",
                    backgroundColor: "#616161",
                    color: "white",
                  }}
                >
                  {btnDisabled ? "Resend OTP" : "Generate OTP"}
                </MDButton>
              ) : (
                <MDButton
                  disabled
                  color="white"
                  variant="contained"
                  style={{
                    marginTop: "45px",
                    backgroundColor: "#616161",
                    color: "white",
                  }}
                >
                  {btnDisabled ? "Resend OTP" : "Generate OTP"}
                </MDButton>
              )}

              <MDBox mt={1}>
                <MDButton
                  // fullWidth
                  style={{ backgroundColor: "red" }}
                  color="info"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  submit
                </MDButton>
              </MDBox>
            </MDBox>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
              </DialogActions>
            </Dialog>
          </MDBox>
        </MDCard>
      </BasicLayout>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    otpReducer: state.otpReducer,
    otpValidateReducer: state.otpValidateReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtp: (uuId) => dispatch(getOtp(uuId)),
    validateOtp: (payload) => dispatch(validateOtp(payload)),
    setVendorIdHandler: (vendorId) => dispatch(setVendorIdHandler(vendorId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
